import { Box, Typography } from '@mui/material'

export const Card = ({
  label,
  text,
  buttons,
}: {
  label: string
  text: string
  buttons: JSX.Element[]
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '8px',
        padding: '12px 16px',
        borderRadius: '4px',
        background: 'var(--light-grayscale-background-secondary, #F5F5F5)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
        }}
      >
        <Typography
          variant="paragraph4"
          color={'var(--light-grayscale-content-3, #6B6F76)'}
        >
          {label}
        </Typography>
        <Typography>{text}</Typography>
      </Box>
      {buttons.length > 0 && <Box>{buttons.map((button) => button)}</Box>}
    </Box>
  )
}
