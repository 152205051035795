import { TableCell, Box, IconButton } from '@mui/material'
import { CustomTooltip } from 'components'
import { ModalDeleteDocumentForever } from 'components/modal-actions/modalActionDocument'
import { TableCompound } from 'components/table/table-compound/TableCompound'
import { useCheckbox } from 'hooks/useCheckbox'
import { useState } from 'react'

import img_restore from 'assets/img/time_revert.svg'
import img_delete from 'assets/img/trash_x.svg'

export const DeletedRecordsTable = ({
  loading,
  rows,
  page,
  hasCheckbox = true,
  actionDelete,
  actionRestore,
  hiddenDocumentIds,
  tableType = 'documents',
}: {
  loading: boolean
  rows: DocumentState[] | any[]
  page: number
  hasCheckbox?: boolean
  actionDelete: (id: string) => Promise<any>
  actionRestore: (id: string) => Promise<any>
  hiddenDocumentIds?: string[]
  tableType?: 'documents' | 'templates'
}) => {
  const { checkboxHeader, checkboxTable } = useCheckbox({ page, rows })

  const [modalDelete, setModalDelete] = useState({
    open: false,
    id: '',
  })

  const closeModalDelete = () => {
    setModalDelete({ ...modalDelete, open: false })
  }

  const openModalDelete = (id) => {
    setModalDelete({ open: true, id })
  }

  return (
    <>
      <ModalDeleteDocumentForever
        tableType={tableType}
        handleClose={closeModalDelete}
        open={modalDelete.open}
        funcDelete={async () => {
          await actionDelete(modalDelete.id)
        }}
      />
      <TableCompound>
        <TableCompound.Head>
          <TableCompound.Row>
            {hasCheckbox && (
              <TableCell padding="checkbox">{checkboxHeader()}</TableCell>
            )}
            {tableType === 'documents' ? (
              <TableCompound.HeadCellDocumentName />
            ) : (
              <TableCompound.HeadCellTemplateName />
            )}
            <TableCompound.Cell />
          </TableCompound.Row>
        </TableCompound.Head>
        {!loading && (
          <TableCompound.Body>
            {rows.map((row, i) => {
              if (row === undefined) return null
              if (row === null) return null

              if (hiddenDocumentIds?.includes(row.id)) return null

              return (
                <TableCompound.RowHoverable key={i}>
                  {hasCheckbox && (
                    <TableCell padding="checkbox">
                      {checkboxTable({ row })}
                    </TableCell>
                  )}
                  <TableCompound.BodyCellDocumentName title={row.name} />
                  <TableCompound.BodyCellDocumentOptions>
                    <Box display={'flex'} justifyContent={'end'}>
                      <CustomTooltip title="Восстановить" placement="left">
                        <IconButton
                          sx={{ p: '6px', borderRadius: '4px', mx: '8px' }}
                          onClick={() => actionRestore(row.id)}
                        >
                          <Box
                            component={'img'}
                            src={img_restore}
                            sx={{ w: '20px', h: '20px' }}
                          />
                        </IconButton>
                      </CustomTooltip>

                      <CustomTooltip
                        title="Удалить безвозвратно"
                        placement="left"
                      >
                        <IconButton
                          sx={{ p: '6px', borderRadius: '4px', mx: '8px' }}
                          onClick={() => openModalDelete(row.id)}
                        >
                          <Box
                            component={'img'}
                            src={img_delete}
                            sx={{ w: '20px', h: '20px' }}
                          />
                        </IconButton>
                      </CustomTooltip>
                    </Box>
                  </TableCompound.BodyCellDocumentOptions>
                </TableCompound.RowHoverable>
              )
            })}
          </TableCompound.Body>
        )}
      </TableCompound>
    </>
  )
}
