import {
  Select,
  Box,
  MenuItem,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material'
import { TextInput } from 'components'
import dayjs from 'dayjs'
import { ESystemVariableType } from 'features/text-editors/lexical/plugins-custom/ParameterPickerPlugin/helpers'
import { FormikDatePicker } from 'features/text-editors/lexical/plugins-custom/VariablePlugin/components/variable-form-fields/date/components/datePicker'
import { IDropdownOption } from 'features/text-editors/lexical/plugins-custom/VariablePlugin/components/variable-form-fields/dropdown'
import { useFormikContext } from 'formik'
import { useFillerFormFormik } from 'pages/document-preview/hooks/useFillerFormFormik'
import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import checkboxIcon from 'assets/img/checkbox/checkbox.svg'
import calendarImg from 'assets/img/variables/calendar_today.svg'

export const VariableInput = ({ variable }) => {
  const { name, id, type } = variable

  const {
    handleTouchStart,
    handleBlur,
    handleChange,
    handleChangeSelect,
    handleChangeDate,
    handleChangeNumber,
    handleChangeCheckbox,
  } = useFillerFormFormik()

  const { values: formikValues, errors } = useFormikContext<any>()

  const { variables } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )
  const renderField = useMemo(() => {
    const currentVariable = variables?.[id]

    if (!currentVariable) return
    const commonProps = {
      id,
      name: id,
      label: name,
      isFillerInput: true,
      helperText: currentVariable?.hint || '',
      required: currentVariable?.is_required || false,
      onFocus: handleTouchStart,
      onBlur: handleBlur,
      onChange: handleChange,
      value: formikValues[id],
      fullWidth: true,
    }

    const renderHint = () => {
      return currentVariable?.hint ? (
        <Typography variant="body2" color={'text.disabled'} mt="4px">
          {currentVariable?.hint}
        </Typography>
      ) : (
        <></>
      )
    }

    const renderLabel = () => {
      return (
        <Typography variant="body1" mb="2px" fontWeight={500}>
          {name}
        </Typography>
      )
    }

    switch (type) {
      case ESystemVariableType.DROPDOWN:
        return (
          <>
            {renderLabel()}
            <Select
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxWidth: '100%',
                    width: '338px !important',
                    minWidth: '338px !important',
                    maxHeight: '100%',
                    marginTop: '2px',
                  },
                },
              }}
              name={id}
              value={formikValues[id]}
              onChange={handleChangeSelect}
              error={!!errors[id]}
              fullWidth
              displayEmpty
              renderValue={
                formikValues[id] !== ''
                  ? undefined
                  : () => (
                      <Box sx={{ display: 'flex', color: 'text.disabled' }}>
                        Выберите вариант
                      </Box>
                    )
              }
            >
              {currentVariable.options?.map((item: IDropdownOption) => (
                <MenuItem
                  value={item.id}
                  key={item.id}
                  sx={{ margin: '2px 12px' }}
                >
                  {item.attrs.text}
                </MenuItem>
              ))}
            </Select>
            {renderHint()}
          </>
        )

      case ESystemVariableType.TEXT_AREA:
        return (
          <TextInput
            size="small"
            {...commonProps}
            sx={{
              '.MuiInputBase-input.MuiOutlinedInput-input': {
                padding: 0,
              },
            }}
            minRows={3}
            multiline
          />
        )

      case ESystemVariableType.INPUT_NUMBER:
        return (
          <TextInput
            {...commonProps}
            type="number"
            onChange={handleChangeNumber}
          />
        )

      case ESystemVariableType.CHECKBOX:
        return (
          <>
            {renderLabel()}
            {renderHint()}

            <FormControlLabel
              sx={{
                '&.MuiFormControlLabel-root': {
                  margin: 0,
                },
              }}
              label={currentVariable.attrs.text}
              control={
                <Checkbox
                  checkedIcon={
                    <img src={checkboxIcon} alt="" width={16} height={16} />
                  }
                  sx={{
                    mr: 1,
                  }}
                  size={'small'}
                  checked={formikValues[id]}
                  value={formikValues[id]}
                  name={id}
                  onChange={handleChangeCheckbox}
                />
              }
            />
          </>
        )

      case ESystemVariableType.INPUT_DATE:
        return (
          <>
            {renderLabel()}
            <FormikDatePicker
              renderInput={(value, openCalendar) => (
                <TextField
                  onBlur={handleBlur}
                  value={value}
                  onFocus={() => {
                    handleTouchStart({
                      target: {
                        name: id,
                        id: id,
                      },
                    })
                    openCalendar()
                  }}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <Box
                        component={'img'}
                        src={calendarImg}
                        width={'16px'}
                        height={'16px'}
                      />
                    ),
                  }}
                />
              )}
              value={dayjs(formikValues[id]).toDate()}
              name={id}
              onChange={(value) => handleChangeDate({ id, value })}
              format={currentVariable.attrs.format}
              minDate={
                currentVariable.attrs.after
                  ? dayjs(currentVariable.attrs.after).format(
                      currentVariable.attrs.format
                    )
                  : ''
              }
              maxDate={
                currentVariable.attrs.before
                  ? dayjs(currentVariable.attrs.before).format(
                      currentVariable.attrs.format
                    )
                  : ''
              }
              mapDays={({ date }) => {
                if (!currentVariable.attrs.specific_dates) return
                const formattedDate = dayjs(date).format('YYYY-MM-DD')
                if (
                  !currentVariable.attrs.specific_dates.includes(formattedDate)
                ) {
                  return {
                    disabled: true,
                    style: { color: '#6B6F76', borderColor: '#EDEDEC' },
                  }
                }
              }}
            />
            {renderHint()}
          </>
        )
      default:
        return <TextInput {...commonProps} />
    }
  }, [variables, id, type, name, formikValues, errors])

  return renderField
}
