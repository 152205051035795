import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  EDocumentStatus,
  EDocumentType,
  ERecipientRole,
  ESignOptionType,
  MAX_ATTEMPTS,
} from 'helper/consts'
import {
  IWfPostman,
  IWfPostmanEmail,
  IWfPostmanOrder,
} from 'helper/wfPostman.types'
import { IServiceWorkflow } from 'helper/workflow.service.types'
import {
  TLaunchWorkflowEventType,
  createWorkflowAnonymous,
  createWorkflowEmails,
  editWorkflow,
  getCurrentRecipientInfo,
  getDocumentInfo,
  getMgovLinks,
  getPdfFileByLink,
  getRecipientByOrigin,
  getRecipientsInfo,
  launchWorkflow,
  queryGetWorkflow,
  restoreWorkflow,
} from 'service'
import { connectAndSign } from 'service/ncaService'
import { fileDataFetch } from 'store/slices/applicationSlice'
import { NCALayerError } from 'utils/NCALayer'
import { convertPdfToBase64 } from 'utils/pdfToBase64'
import { checkCmsSignature } from 'utils/urlToFIle'
import {
  getServiceWorkflowForLink,
  getServiceWorkflowForOnlyMeSign,
} from 'utils/workflow'

// ! TODO: В будущем надо будет разделить, это временное решение что любой воркфлоу запускается тут. (Должен запускаться только воркфлоу для отправки письма)
const rdxNamespace = 'wf-postman'

const getNewOrder = (): IWfPostmanOrder => {
  return {
    letter: {
      emails_approvers: [],
      emails_copy: [],
      emails_signers: [],
      subject: '',
      content: '',
    },
    create: {
      created: false,
      loading: false,
      error: '',
    },
    edit: {
      edited: false,
      loading: false,
      error: '',
    },
  }
}

const initialState: IWfPostman = {
  selected_document_id: '',
  orders: {},
  selected_order: getNewOrder(),
  loading: {
    sign: false,
  },
  sign: {
    mgov: {
      qr: '',
      egov_mobile: '',
      egov_business: '',
    },
    isMeSign: '',
    origin: null,
  },
  errors: {
    sign: '',
  },
}

async function handleSetWorkflowByStatus(
  status: EDocumentStatus,
  id: string,
  data: { workflow?: IServiceWorkflow },
  option: ESignOptionType
) {
  if (
    status === EDocumentStatus.DECLINED ||
    status === EDocumentStatus.REVOKED
  ) {
    await restoreWorkflow({ id })
    await editWorkflow({ id, data })
  } else if (status === EDocumentStatus.DRAFT) {
    try {
      await queryGetWorkflow({ id })
      await editWorkflow({ id, data })
    } catch {
      if (
        option === ESignOptionType.LINK ||
        option === ESignOptionType.ME ||
        option === ESignOptionType.NCA
      ) {
        await createWorkflowAnonymous({ id, data })
      } else {
        await createWorkflowEmails({ id, data })
      }
    }
  }
}

export const signWithNCA = async ({ id, signature, role = 'sign' }) => {
  try {
    const resToken = await getCurrentRecipientInfo({ id })
    await checkCmsSignature({
      base64EncodedSignature: signature,
      id: id,
      token: resToken.data.recipient.token,
      role,
    })
  } catch (error: any) {
    throw new Error(error.message)
  }
}

const ErrorPublish = {
  ['missing-field-for-template']: 'Заполните поля в шаблоне',
}

const sleepUntilDocumentStatusChangeTo = async (
  id: string,
  targetStatus: EDocumentStatus,
  attempts: number
) => {
  for (let i = 0; i < attempts; i++) {
    try {
      const resp = await getDocumentInfo({ id })
      const { status } = resp.data.document
      if (status === targetStatus) return
    } catch (error) {
      console.error(error)
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 1000))
    }
  }
}

export const startWorkflow = createAsyncThunk<
  void,
  {
    id: string
    status: EDocumentStatus
    documentType?: EDocumentType | string
    option: ESignOptionType
    email: string
    limit?: number
    signature?: string
    navigate?: any
    role?: string
    callback?: any
  },
  {}
>(
  `${rdxNamespace}/startWorkflow`,
  async (
    {
      id,
      email,
      status,
      limit = 0,
      option,
      signature = '',
      navigate = {},
      role = 'sign',
      documentType = EDocumentType.PDF,
      callback = () => {
        return
      },
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const prevStatus = status
      // ? Подготовка вокрфлоу локально
      let eventType: TLaunchWorkflowEventType = 'workflow'
      let data: { workflow?: IServiceWorkflow } = {}
      if (option === ESignOptionType.LINK) {
        data = getServiceWorkflowForLink(limit)
        eventType = 'copylink'
      } else if (
        option === ESignOptionType.ME ||
        option === ESignOptionType.NCA
      ) {
        {
          eventType = 'onlyme'
          data = getServiceWorkflowForOnlyMeSign(email)
        }
      }

      // ? Отправка и запуск воркфлоу
      await handleSetWorkflowByStatus(status, id, data, option)
      try {
        await launchWorkflow({ id, eventType: eventType })
      } catch (error: any) {
        if (documentType != EDocumentType.TEMPLATE)
          throw new Error(
            ErrorPublish[error.message] || 'Невозможно запустить воркфлоу.'
          )
      }

      // ? Ожидание пока подготовится документ к подписи
      if (status != EDocumentStatus.SENT) {
        await sleepUntilDocumentStatusChangeTo(id, EDocumentStatus.SENT, 10)
      }

      // ? Действие в зависимости выбора пользователя
      if (option === ESignOptionType.ME) {
        await dispatch(updateMgovLinksLoop({ id, i: 0 }) as any)
      } else if (option === ESignOptionType.NCA) {
        await dispatch(
          signDocumentWithNCA({ id, signature, navigate, role }) as any
        )
        if (prevStatus != EDocumentStatus.SENT) {
          await dispatch(fileDataFetch({ id }))
        }
      } else if (option === ESignOptionType.LINK) {
        await dispatch(anonymousLinkFetch({ id, i: 0 }))
      }

      callback()
    } catch (error: any) {
      rejectWithValue(error)
    }
  }
)

export const mgovLinksUpdate = createAsyncThunk(
  `${rdxNamespace}/updateMgovLinks`,
  async ({ id }: { id: string }) => {
    const resToken = await getCurrentRecipientInfo({ id })
    const { token, role, result } = resToken.data.recipient

    if (!token) {
      return {
        isMeSign: '',
        mgov: {
          qr: '',
          egov_mobile: '',
          egov_business: '',
        },
      }
    }
    const res = await getMgovLinks({ id, token })
    const origin =
      role === ERecipientRole.anonymous_signer_rk
        ? resToken.data
        : {
            recipient: {
              link: '',
              origin_id: resToken.data.recipient.origin_id,
            },
          }

    return {
      mgov: { ...res.data },
      origin,
      isMeSign: result !== 'signed' && result !== 'approved' ? role : '',
    }
  }
)

export const anonymousLinkFetch = createAsyncThunk(
  `${rdxNamespace}/getAnonymousLink`,
  async (
    { id, i, update = true }: { id: string; i: number; update?: boolean },
    { dispatch, rejectWithValue }
  ) => {
    try {
      if (i === 4) return { origin: { recipient: { link: '', origin_id: '' } } }

      const res = await getRecipientsInfo({ id })
      const origin_id = res.data.recipients[0]?.origin_id || null

      if (!origin_id) {
        setTimeout(() => dispatch(anonymousLinkFetch({ id, i: i + 1 })), 1000)
        return
      }

      if (origin_id) {
        const link_res = await getRecipientByOrigin({ id, origin_id })
        const role = res.data.recipients[0]?.role || null
        const origin =
          role === ERecipientRole.anonymous_signer_rk
            ? link_res.data
            : { recipient: { link: '', origin_id: origin_id } }

        if (update)
          await dispatch(
            fileDataFetch({
              id,
            })
          )
        return { origin }
      }
    } catch {
      rejectWithValue('Произошла ошибка при генерации ссылки')
    }
  }
)

export const updateMgovLinksLoop = createAsyncThunk<
  any,
  {
    id: string
    i: number
  },
  {}
>(
  `${rdxNamespace}/updateMgovLinksLoop`,
  async ({ id, i = 0 }, { dispatch, rejectWithValue }) => {
    if (i === MAX_ATTEMPTS) return rejectWithValue('Exceeded retry attempts')

    try {
      const resToken = await getCurrentRecipientInfo({ id })
      const res = await getMgovLinks({
        id,
        token: resToken.data.recipient.token,
      })
      return {
        sign: { mgov: { ...res.data }, origin: { ...resToken.data } },
      }
    } catch (error) {
      if (i === 6) return rejectWithValue('Ошибка при подписании')
      await new Promise((resolve) => setTimeout(resolve, 1000))
      await dispatch(updateMgovLinksLoop({ id, i: i + 1 }))
    }
  }
)

export const signDocumentWithNCA = createAsyncThunk<
  void,
  {
    id: string
    signature: any
    template?: string
    i?: number
    navigate: any
    role: string
  },
  {}
>(
  `${rdxNamespace}/signDocumentWithNCA`,
  async (
    { id, signature, template = '', i = 0, navigate, role = 'sign' },
    { dispatch, rejectWithValue }
  ) => {
    if (i === MAX_ATTEMPTS) return rejectWithValue('Exceeded retry attempts')

    try {
      const resToken = await getCurrentRecipientInfo({ id })
      const res = await getDocumentInfo({ id })

      const { link_pdf } = res.data.document

      const { url } = await getPdfFileByLink({ link: link_pdf })
      const base64File = await convertPdfToBase64(url)
      const cmsSign: any = await connectAndSign(base64File)

      await checkCmsSignature({
        base64EncodedSignature: cmsSign,
        id: id,
        token: resToken.data.recipient.token,
        role: role,
      })

      await dispatch(fileDataFetch({ id }))
    } catch (error: any) {
      if (error.message === 'action.canceled') return rejectWithValue('')
      if (error instanceof NCALayerError) return rejectWithValue(error.message)
      if (i === 6)
        return rejectWithValue(error.message || 'Ошибка при подписании')

      await new Promise((resolve) => setTimeout(resolve, 1000))
      await dispatch(
        signDocumentWithNCA({
          id,
          signature,
          template,
          i: i + 1,
          navigate,
          role,
        })
      )
    }
  }
)

const wfPostmanSlice = createSlice({
  name: rdxNamespace,
  initialState: initialState,
  reducers: {
    select: (state, action: PayloadAction<{ document_id: string }>) => {
      const document_id = action.payload.document_id
      state.selected_document_id = document_id

      if (state.orders[document_id]) {
        state.selected_order = state.orders[document_id]
        return
      }

      state.selected_order = getNewOrder()
      state.orders[document_id] = state.selected_order
    },

    /**
     * reset - cleans `selected_id` and `selected_order` with deleting created order if order value is empty
     */
    reset: (state) => {
      if (
        JSON.stringify(state.orders[state.selected_document_id]) ==
        JSON.stringify(getNewOrder())
      )
        delete state.orders[state.selected_document_id]

      state.selected_document_id = ''
      state.selected_order = getNewOrder()
    },

    remove: (state, action: PayloadAction<{ document_id: string }>) => {
      const document_id = action.payload.document_id
      delete state.orders[document_id]

      if (state.selected_document_id === document_id) {
        state.selected_document_id = ''
        state.selected_order = getNewOrder()
      }
    },

    setEmailsSigners: (state, action: PayloadAction<IWfPostmanEmail[]>) => {
      if (!state.selected_document_id) return

      state.selected_order.letter.emails_signers = action.payload
      state.orders[state.selected_document_id] = state.selected_order
    },
    setEmailsApprovers: (state, action: PayloadAction<IWfPostmanEmail[]>) => {
      if (!state.selected_document_id) return
      state.selected_order.letter.emails_approvers = action.payload
      state.orders[state.selected_document_id] = state.selected_order
    },
    setEmailsCopy: (state, action: PayloadAction<string[]>) => {
      if (!state.selected_document_id) return
      state.selected_order.letter.emails_copy = action.payload
      state.orders[state.selected_document_id] = state.selected_order
    },

    setSubject: (state, action: PayloadAction<string>) => {
      if (!state.selected_document_id) return
      state.selected_order.letter.subject = action.payload
      state.orders[state.selected_document_id] = state.selected_order
    },
    setContent: (state, action: PayloadAction<string>) => {
      if (!state.selected_document_id) return
      state.selected_order.letter.content = action.payload
      state.orders[state.selected_document_id] = state.selected_order
    },

    setCreateLoading: (state, action: PayloadAction<boolean>) => {
      state.selected_order.create.loading = action.payload
      state.orders[state.selected_document_id] = state.selected_order
    },
    setCreateError: (state, action: PayloadAction<string>) => {
      state.selected_order.create.error = action.payload
      state.orders[state.selected_document_id] = state.selected_order
    },
    setCreateCreated: (state, action: PayloadAction<boolean>) => {
      state.selected_order.create.created = action.payload
      state.orders[state.selected_document_id] = state.selected_order
    },
    clearLinks(state) {
      state.sign.origin = { recipient: { link: '' } }
      state.sign.isMeSign = ''
      state.sign.mgov = {
        qr: '',
        egov_mobile: '',
        egov_business: '',
      }
      state.errors.sign = ''
    },
    clearSignError(state) {
      state.errors.sign = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startWorkflow.pending, (state) => {
        state.loading.sign = true
      })
      .addCase(startWorkflow.fulfilled, (state) => {
        state.loading.sign = false
      })
      .addCase(startWorkflow.rejected, (state, action) => {
        state.loading.sign = false
        state.errors.sign = (action.payload as string) || 'Произошла ошибка'
      })
      .addCase(
        mgovLinksUpdate.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.sign.mgov = action.payload.mgov
          state.sign.origin = action.payload.origin
          state.sign.isMeSign = action.payload.isMeSign
        }
      )
      .addCase(anonymousLinkFetch.pending, (state) => {
        state.loading.sign = true
      })
      .addCase(
        anonymousLinkFetch.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.sign.origin = action.payload?.origin
          state.loading.sign = false
        }
      )
      .addCase(anonymousLinkFetch.rejected, (state, action) => {
        state.loading.sign = false
        state.errors.sign = (action.payload as string) || 'Произошла ошибка'
      })
      .addCase(updateMgovLinksLoop.fulfilled, (state, action) => {
        state.sign = action.payload.sign
        state.loading.sign = false
      })
      .addCase(updateMgovLinksLoop.rejected, (state, action) => {
        state.errors.sign = (action.payload as string) || 'Произошла ошибка'
        state.loading.sign = false
      })
      .addCase(signDocumentWithNCA.pending, (state) => {
        state.loading.sign = true
      })
      .addCase(signDocumentWithNCA.fulfilled, (state) => {
        state.loading.sign = false
      })
      .addCase(signDocumentWithNCA.rejected, (state, action) => {
        state.errors.sign = (action.payload as string) || 'Произошла ошибка'
        state.loading.sign = false
      })
  },
})

export const wfPostmanActions = wfPostmanSlice.actions
export const wfPostmanReducer = wfPostmanSlice.reducer
