import { CircularProgress } from '@mui/material'
import img_download from 'assets/img/download.svg'
import { MenuItemButton } from 'components/menu-item/menu-item-button'

import { MessageSnackbar } from 'components/snackbar'
import { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getListOfApprovers } from 'service'

export const DownloadListOfApprovers = ({ id }) => {
  const [loading] = useState(false)
  const [error, setError] = useState('')

  const { document: file } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )

  const downloadPdf = async () => {
    try {
      const link = document.createElement('a')
      const _file = await getListOfApprovers({ id })
      link.href = _file
      link.download = file.name + '-approvers.pdf'
      link.download = file.name + '-approvers.pdf'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error: any) {
      console.error(error.message)
    }
  }

  if (loading) return <CircularProgress />
  return (
    <>
      <MessageSnackbar
        message={error}
        clearMessage={() => setError('')}
        severity="error"
      />
      <MenuItemButton
        title={'Скачать лист согласования'}
        onClick={downloadPdf}
        srcImage={img_download}
      />
    </>
  )
}
