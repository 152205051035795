import { Box } from '@mui/material'
import {
  BreadcrumbsBlock,
  IBreadcrumbsBlockProps,
} from 'components/breadcrumbs/BreadcrumbsBlock'

export const HeaderWithBreadcrumbs = ({
  breadcrumb,
  rightChildren,
}: {
  breadcrumb: IBreadcrumbsBlockProps
  rightChildren?: React.ReactNode
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        width: '100%',
        minHeight: '48px',
        py: '8px',
      }}
    >
      <BreadcrumbsBlock {...breadcrumb} />
      {!!rightChildren && <Box>{rightChildren}</Box>}
    </Box>
  )
}
