import { ESystemVariableType } from 'features/text-editors/lexical/plugins-custom/ParameterPickerPlugin/helpers'
import {
  IManageTemplateDocumentHeader,
  IManageTemplateDocumentRecord,
} from 'store/slices/manageTemplateData'
import { convertDateToLocale } from 'utils/convertDateToLocale'

export const getVariableValueText = (
  record: IManageTemplateDocumentRecord,
  column: IManageTemplateDocumentHeader
) => {
  const res = record.responses?.find(
    (response) => response.variable_id === column.variable_id
  )

  switch (column.variable_type) {
    case ESystemVariableType.INPUT_DATE:
      return res?.value ? convertDateToLocale(res?.value, false) : '-'
    case ESystemVariableType.DROPDOWN:
      return res?.value.join(', ')
    case ESystemVariableType.CHECKBOX:
      return !!res?.attrs?.text ? (res?.value ? '✓ ' : '✕ ') + (res?.attrs?.text) : '-'
    default:
      return res?.value || '-'
  }
}
