import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { wsPlanGetPlan } from 'store/slices/wsPlan/wsPlanGetPlan'

const loadAfterMs = 500

export const LoadWorkspacePlanLayout = ({ children }) => {
  const { pathname } = useLocation()
  const [saveTimer, setSaveTimer] = useState({} as NodeJS.Timeout)
  const dispatch = useDispatch()
  const { plan_name, workspace_id } = useSelector(
    (state: RootState) => state.wsPlan,
    shallowEqual
  )

  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  function loadPlan() {
    selectedWorkspace?.id &&
      dispatch(wsPlanGetPlan({ wsId: selectedWorkspace?.id }) as any)
  }

  function loadPlanSmart() {
    if (selectedWorkspace?.id != workspace_id) {
      loadPlan()
    } else if (plan_name) {
      clearTimeout(saveTimer)
      const timout = setTimeout(function () {
        loadPlan()
      }, loadAfterMs)
      setSaveTimer(timout)
    } else {
      loadPlan()
    }
  }

  useEffect(() => {
    loadPlanSmart()
  }, [selectedWorkspace, pathname])

  return children
}
