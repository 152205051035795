import img_assistant from 'assets/img/assistant.svg'
import img_bug from 'assets/img/bug.svg'
import img_new from 'assets/img/fiber_new.svg'
import img_email from 'assets/img/ic_email.svg'
// import img_roadmap from 'assets/img/map.svg'
import menu_book from 'assets/img/menu_book.svg'
import img_telegram from 'assets/img/socials/telegram.svg'
import img_plan_up from 'assets/img/up_circle_blue.svg'

import { Box, Divider, Link, Menu, Typography } from '@mui/material'
import { MenuItemButton } from 'components/menu-item/menu-item-button'
import { HTMLAttributeAnchorTarget } from 'react'
import { useDispatch } from 'react-redux'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { resetOnboardingAction } from 'store/slices/userSlice'

export const MenuHelp = ({ anchorEl, handleClose, open }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleStartOnboarding = () => {
    dispatch(resetOnboardingAction() as any)
    navigate('/')
  }

  return (
    <Menu
      id="positioned-menu"
      aria-labelledby="positioned-button"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          padding: '0px 4px',
          maxHeight: '320px',
          marginTop: '-4px',
        },
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <MenuElementRouterLink
        onClick={handleClose}
        img_src={img_plan_up}
        text={'Тарифы'}
        href="/plan"
      />
      <MenuItemButton
        onClick={handleStartOnboarding}
        srcImage={menu_book}
        title={'Пройти обучение'}
      />
      <MenuElement
        img_src={img_new}
        text={'Новое в продукте'}
        href="https://doodocskz.notion.site/Changelog-ed4df3a9999546a187967cee282d93a8"
        onClick={handleClose}
      />
      {/* <MenuElement
        img_src={img_roadmap}
        text={'Roadmap'}
        href="https://doodocskz.notion.site/Roadmap-55e2cdf355aa423ebdb00bbc4f372c3e"
        onClick={handleClose}
      /> */}
      <Divider
        sx={{
          mx: '-4px',
          my: '6px',
        }}
      />
      <MenuElement
        img_src={img_assistant}
        href="#tally-open=wbW1g7&tally-layout=modal&tally-width=374"
        text={'Предложить улучшение'}
        onClick={handleClose}
      />
      <MenuElement
        img_src={img_bug}
        text={'Сообщить о проблеме'}
        href="#tally-open=n97AxX&tally-layout=modal&tally-width=374"
        onClick={handleClose}
      />
      <Box sx={{ p: '12px 12px 4px 12px' }}>
        <Typography variant="paragraph4" fontWeight={500} color={'#90959D'}>
          Связаться с поддержкой
        </Typography>
      </Box>
      <MenuElement
        img_src={img_email}
        text={'Email'}
        href="mailto:info@doodocs.kz"
        onClick={handleClose}
      />
      <MenuElement
        img_src={img_telegram}
        text={'Telegram'}
        href="https://t.me/doodocskz"
        onClick={handleClose}
      />
    </Menu>
  )
}

const MenuElement = ({
  img_src,
  text,
  href,
  target,
  onClick,
}: {
  img_src: string
  text: string
  target?: HTMLAttributeAnchorTarget
  href: string
  onClick: any
}) => {
  return (
    <Link
      href={href}
      target={target ?? '_blank'}
      color={'inherit'}
      sx={{
        textDecoration: 'none',
      }}
    >
      <MenuItemButton onClick={onClick} srcImage={img_src} title={text} />
    </Link>
  )
}

const MenuElementRouterLink = ({ img_src, text, href, onClick }) => {
  return (
    <RouterLink
      to={href}
      color={'inherit'}
      style={{
        textDecoration: 'none',
        color: 'inherit',
      }}
    >
      <MenuItemButton onClick={onClick} srcImage={img_src} title={text} />
    </RouterLink>
  )
}
