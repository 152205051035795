import { UrlKeys } from 'helper/consts'
import { useSearchParams, useParams } from 'react-router-dom'

export const useUrlIdParam = (key: UrlKeys) => {
  const params = useParams()

  const [searchParams, setSearchParams] = useSearchParams()

  const getParamsId = () => {
    return params[key] ?? ''
  }

  const hasId = () => {
    return (
      params[key] &&
      params[key] !== '' &&
      params[key] !== 'new' &&
      params[key] !== 'draft'
    )
  }

  const setSearchParamsId = (id: string) => {
    searchParams.set(key, id)
    setSearchParams(searchParams)
  }

  const getSearchParamsId = () => {
    return searchParams.get(key) ?? ''
  }

  const get = () => {
    if (hasId()) {
      return getParamsId()
    }

    return getSearchParamsId()
  }

  const setIfNotExists = (id: string) => {
    if (!get() && id) {
      setSearchParamsId(id)
    }
  }

  const urlId = {
    get: get,
    setIfNotExists: setIfNotExists,
  }

  return { urlId }
}
