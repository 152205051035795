import { EDocumentStatus, EDocumentType } from 'helper/consts'
import { IWorkflow } from 'helper/workflow/IWorkflow'
import {
  createWorkflowEmails,
  editWorkflow,
  queryGetWorkflow,
  restoreWorkflow,
  launchWorkflow as serviceLaunchWorkflow,
} from 'service'

export const launchWorkflow = async ({
  doc,
  workflow,
  onSuccess,
}: {
  doc: {
    id: string
    type: EDocumentType | string
    status: EDocumentStatus
  }
  workflow: IWorkflow
  onSuccess?: () => void
}) => {
  const wqv = {
    workflow: workflow.export(),
  }

  if (doc.status === EDocumentStatus.DRAFT) {
    try {
      await queryGetWorkflow({ id: doc.id })
      await editWorkflow({ id: doc.id, data: wqv })
    } catch {
      try {
        await createWorkflowEmails({ id: doc.id, data: wqv })
      } catch (error) {
        throw new Error('Невозможно создать воркфлоу.')
      }
    }
  } else if (
    doc.status === EDocumentStatus.DECLINED ||
    doc.status === EDocumentStatus.REVOKED
  ) {
    await restoreWorkflow({ id: doc.id })
    await editWorkflow({ id: doc.id, data: wqv })
  } else {
    throw new Error(
      `Нельзя начать workflow для документа со статусом '${doc.status}'`
    )
  }

  try {
    await serviceLaunchWorkflow({ id: doc.id, eventType: 'workflow' })
  } catch (error: any) {
    throw new Error('Невозможно запустить воркфлоу.')
  }
  if (onSuccess) onSuccess()
}
