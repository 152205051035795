import { Box, Grid } from '@mui/material'

export const LoginFormLayout = ({ header, content }) => {
  return (
    <>
      <Box
        display={'flex'}
        justifyContent="center"
        width={'100%'}
        textAlign="center"
        p={'0 !important'}
        mt={'112px'}
      >
        <Box textAlign={'center'} maxWidth="360px">
          <Grid container rowSpacing={'16px'} justifyContent="center">
            {header}
            {content}
          </Grid>
        </Box>
      </Box>
    </>
  )
}
