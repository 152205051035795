import { LexicalNode } from "lexical";
import { $createColumnsListNode, ColumnsListNode } from ".";
import { $createColumnsListItemNode } from "./ColumnsListItemNode";

export function $createColumnsListNodeWithNodes(nodes: LexicalNode[]): ColumnsListNode {
  const container = $createColumnsListNode()
  for (let i = 0; i < nodes.length; i++) {
    container.append(
      $createColumnsListItemNode(100 / nodes.length)
        .append(nodes[i])
    )
  }
  return container
}