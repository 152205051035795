import { Box, Typography } from '@mui/material'
import { AppBarContext } from 'context'
import { DASHBOARD_MODES } from 'layout/dashboard-layout'
import { coloredFilters } from 'layout/dashboard-layout/components/groupForm'
import { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectTeamspace } from 'store/slices/teamspaceSlice'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import img_blue_teamspace from 'assets/img/group_icon/blue.svg'
import { MenuButton } from '../menu-button'
import { OptionsMenu } from '../options-menu'

export const TeamSpaceInfo = ({
  teamspace,
  handleToggleCollapse,
  collapsed,
  setOpen,
}: {
  teamspace: ITeamspace
  handleToggleCollapse: () => void
  collapsed: boolean
  setOpen: (open: boolean) => void
}) => {
  const { id, name, group_name } = teamspace
  const { setMode } = useContext(AppBarContext)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [hover, setHover] = useState(-1)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const handleOpen = () => setOpen(true)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelectItem = ({ value, e }) => {
    e.stopPropagation()
    handleMenuClose()
    switch (value) {
      case 'members':
        setMode(DASHBOARD_MODES.SETTINGS)
        navigate(`/teamspace/${id}/members`)
        break
      case 'settings':
        setMode(DASHBOARD_MODES.SETTINGS)
        navigate(`/teamspace/${id}/settings?name=${name}`)
        break
      case 'invite':
        dispatch(selectTeamspace(teamspace) as any)
        handleOpen()
        break
      default:
        break
    }
  }

  return (
    <Box
      onMouseOver={() => {
        setHover(0)
      }}
      onMouseLeave={() => {
        setHover(-1)
      }}
      p="6px 16px"
      display={'flex'}
      alignItems="center"
      onClick={handleToggleCollapse}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          background: '#EDEDEC',
          color: 'text.primary',
        },
      }}
    >
      <Box
        component={'img'}
        src={img_blue_teamspace}
        mr={1}
        sx={{
          filter: teamspace.attrs
            ? coloredFilters[teamspace.attrs.color.hex]
            : '',
        }}
      />

      <Typography
        maxWidth={'150px'}
        overflow={'hidden'}
        textOverflow={'ellipsis'}
      >
        {name}
      </Typography>
      {!collapsed ? (
        <ArrowRightIcon
          color="secondary"
          sx={{ width: '16px', height: '16px' }}
        />
      ) : (
        <ArrowDropDownIcon
          color="secondary"
          sx={{ width: '16px', height: '16px' }}
        />
      )}
      <Box
        display={'flex'}
        ml="8px"
        alignSelf={'center'}
        position="absolute"
        right={'8px'}
      >
        <MenuButton handleMenuOpen={handleMenuOpen} hover={hover} />
        <OptionsMenu
          anchorEl={anchorEl}
          handleMenuClose={handleMenuClose}
          handleSelectItem={handleSelectItem}
          group_name={group_name}
        />
      </Box>
    </Box>
  )
}
