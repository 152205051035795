import { Button } from '@mui/material'

export const TabButton = ({
  tab,
  index,
  onClick,
  label,
  isDisabled,
  itemsCount = 3,
}) => {
  return (
    <Button
      onClick={onClick}
      disabled={isDisabled}
      color="secondary"
      variant="outlined"
      sx={{
        fontWeight: 400,
        border: 'none',
        boxShadow: 'none',
        borderRight: '1px solid #E7E7E6',
        borderRadius: index === 0 ? '4px 0px 0px 4px' : 0,
        background: tab === index ? '#EDEDEC' : 'white',
        '&:hover': {
          border: 'none',
          borderRight: '1px solid #E7E7E6',
        },
        '&:focus': {
          border: 'none',
          borderRight: '1px solid #E7E7E6',
        },
        '&:disabled': {
          border: 'none',
          background: 'white',
          borderRight: index === itemsCount ? 'none' : '1px solid #E7E7E6',
          borderRadius: index === itemsCount ? 4 : 0,
        },
      }}
    >
      {label}
    </Button>
  )
}

export default TabButton
