import { Box, Typography } from '@mui/material'
import { ButtonCopy } from 'components/button/ButtonCopy'

export const BlockCopyOneSignLink = ({ link }: { link: string }) => {
  return (
    <Box
      sx={{ background: '#F5F5F5' }}
      p={2}
      gap={1}
      display={'flex'}
      flexDirection={'column'}
    >
      <Box gap={'4px'} display={'flex'} flexDirection={'column'}>
        <Typography variant="heading6">
          Одна ссылка для всех подписантов
        </Typography>
        <Typography variant="paragraph4" color={'text.disabled'}>
          Скопируйте эту ссылку, чтобы поделиться документом. У всех у кого
          будет ссылка смогут подписать документ.{' '}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          borderRadius: '4px',
          border: '1px solid #EDEDEC',
          p: '6px 4px 6px 12px',
          alignSelf: 'stretch',
          background: 'white',
        }}
      >
        <Typography
          flex={1}
          whiteSpace={'nowrap'}
          overflow={'hidden'}
          textOverflow={'ellipsis'}
          variant="paragraph3"
          color={'#282A2F'}
        >
          {link}
        </Typography>
        <ButtonCopy
          variant="text"
          size={'small'}
          copyTextValue={link}
          sx={{
            height: '20px !important',
          }}
        />
      </Box>
    </Box>
  )
}
