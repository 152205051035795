import { Box, Grid, Typography } from '@mui/material'
import { HeaderWithLogo, Loading } from 'components'
import { config } from 'config'
import { IconButtonHelp } from 'features/help'
import IframeResizer from 'iframe-resizer-react'
import { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { loadWorkspaces } from 'store/slices/workspace'

const useCheckIfUserExists = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { workspaces, loading: loadingWs } = useSelector(
    (state: RootState) => state.workspace,
    shallowEqual
  )

  useEffect(() => {
    dispatch(loadWorkspaces({}) as any)
  }, [])

  useEffect(() => {
    if (loadingWs.workspaces) return

    if ((workspaces?.length ?? 0) == 0) {
      return
    }

    navigate('/')
  }, [loadingWs.workspaces])
}

export const PageSetupOnboarding = () => {
  useCheckIfUserExists()

  const { loading: loadingWs } = useSelector(
    (state: RootState) => state.workspace,
    shallowEqual
  )

  if (loadingWs.workspaces) return <Loading />

  return (
    <Box minHeight="100%" display="flex">
      <HeaderWithLogo />
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'100%'}
        minHeight={'100vh'}
        flexDirection={'column'}
      >
        <Typography variant="subtitle1">Добро пожаловать в Doodocs</Typography>
        <Typography variant="body2" color={'text.secondary'} mt="8px" mb={4}>
          Расскажите немного о себе
        </Typography>

        <FrameWrapper />
      </Box>
      <Box sx={{ p: '0 24px', position: 'fixed', right: '0', bottom: '24px' }}>
        <Grid container justifyContent="flex-end">
          <IconButtonHelp />
        </Grid>
      </Box>
    </Box>
  )
}

const redirect_uri = `${config.DOMAIN_CABINET_DOODOCS}/login/otp`
const baseURL = `${config.BASE_URL_TALLY}&redirect_uri=${redirect_uri}`

function FrameWrapper() {
  const [searchParams] = useSearchParams()

  const uri = useMemo(() => {
    // ! Текущий URI создается так чтобы правильно передавались UTM метки в tally
    const url = new URL(baseURL)
    const sp = url.searchParams

    for (const [key, value] of searchParams.entries()) {
      sp.set(key, value)
    }
    return url.toString()
  }, [searchParams])

  return (
    <IframeResizer
      id="tallyFrame"
      src={uri}
      width="100%"
      style={{
        maxWidth: 360,
        minHeight: 437,
        width: '100%',
        overflow: 'auto',
        border: 0,
      }}
    />
  )
}
