import { Box, Typography } from '@mui/material'
import { PaymentHistoryTable } from '../PaymentHistoryTable'
import { useEffect } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import { wsPlanGetPaymentsHistory } from 'store/slices/wsPlan/wsPlanGetPaymentsHistory'
import { useAppSelector } from 'store/store'
import { Loading } from 'components'

export const PaymentHistory = () => {
  const dispatch = useDispatch()
  const { selectedWorkspace } = useAppSelector(
    (state) => state.selectedWorkspace,
    shallowEqual
  )

  const { loading, payments } = useAppSelector(
    (state) => state.wsPlan,
    shallowEqual
  )

  useEffect(() => {
    if (!selectedWorkspace?.id) return
    dispatch(wsPlanGetPaymentsHistory({ wsId: selectedWorkspace.id }) as any)
  }, [])

  if (loading.payments_history) return <Loading />
  if (payments && payments.length === 0) return null
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
      <Typography variant="heading6">История оплаты</Typography>
      <PaymentHistoryTable />
    </Box>
  )
}
