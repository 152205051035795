import { EFeature } from "helper/plan.types"
import { EWorkspacePlan } from "helper/plan.types"

export const featureNames = {
  [EFeature.documents_count_per_month]: 'Количество готовых документов /в месяц',
  [EFeature.users_count]: 'Количество пользователей в пространстве',
  [EFeature.teamspaces_count]: 'Количество групп',
  [EFeature.document_send_by_email]: 'Маршрут документов',
  [EFeature.ai_chat]: 'ChatGPT при работе с документами',
  [EFeature.api_access]: 'API',
  [EFeature.mass_send]: 'Массовая рассылка',
}

export enum EPlanPeriod {
  month = 1,
  quarter = 3,
  half_year = 6,
  year = 12,
}

export const planPeriodInDays = {
  [EPlanPeriod.month]: 31,
  [EPlanPeriod.quarter]: 92,
  [EPlanPeriod.half_year]: 183,
  [EPlanPeriod.year]: 365,
}

export const planPeriodNames = {
  [EPlanPeriod.month]: 'месяц',
  [EPlanPeriod.quarter]: 'квартал',
  [EPlanPeriod.half_year]: 'полгода',
  [EPlanPeriod.year]: 'год',
}

export const planPeriodDiscounts = {
  [EPlanPeriod.half_year]: 10,
  [EPlanPeriod.year]: 20,
}

export interface IPlanInfo {
  name: EWorkspacePlan
  serverName: string
  price: {
    [EPlanPeriod.month]: number
    [EPlanPeriod.quarter]: number
    [EPlanPeriod.half_year]: number
    [EPlanPeriod.year]: number
  }
  features: {
    [EFeature.documents_count_per_month]: number,
    [EFeature.users_count]: number,
    [EFeature.teamspaces_count]: number,
    [EFeature.document_send_by_email]: boolean,
    [EFeature.ai_chat]: boolean,
    [EFeature.api_access]: boolean,
    [EFeature.mass_send]: boolean,
  }
  isBuildPaymentInvoiceDisabled?: boolean
}


export const plansInfoMap: {
  [key: string]: IPlanInfo
} = {
  [EWorkspacePlan.Start]: {
    name: EWorkspacePlan.Start,
    serverName: "start:v2",
    price: {
      [EPlanPeriod.month]: 4990,
      [EPlanPeriod.quarter]: 14970,
      [EPlanPeriod.half_year]: 26946,
      [EPlanPeriod.year]: 47904,
    },
    features: {
      [EFeature.documents_count_per_month]: 10,
      [EFeature.users_count]: 5,
      [EFeature.teamspaces_count]: 3,
      [EFeature.document_send_by_email]: false,
      [EFeature.ai_chat]: false,
      [EFeature.api_access]: false,
      [EFeature.mass_send]: false,
    },
    isBuildPaymentInvoiceDisabled: true,
  },
  [EWorkspacePlan.Lite]: {
    name: EWorkspacePlan.Lite,
    serverName: "lite:v2",
    price: {
      [EPlanPeriod.month]: 14990,
      [EPlanPeriod.quarter]: 44970,
      [EPlanPeriod.half_year]: 80946,
      [EPlanPeriod.year]: 143904,
    },
    features: {
      [EFeature.documents_count_per_month]: 100,
      [EFeature.users_count]: 10,
      [EFeature.teamspaces_count]: 5,
      [EFeature.document_send_by_email]: true,
      [EFeature.ai_chat]: true,
      [EFeature.api_access]: false,
      [EFeature.mass_send]: false,
    },
  },
  [EWorkspacePlan.Pro]: {
    name: EWorkspacePlan.Pro,
    serverName: "pro:v2",
    price: {
      [EPlanPeriod.month]: 49990,
      [EPlanPeriod.quarter]: 149970,
      [EPlanPeriod.half_year]: 269946,
      [EPlanPeriod.year]: 479904,
    },
    features: {
      [EFeature.documents_count_per_month]: 1000,
      [EFeature.users_count]: 30,
      [EFeature.teamspaces_count]: -1,
      [EFeature.document_send_by_email]: true,
      [EFeature.ai_chat]: true,
      [EFeature.api_access]: true,
      [EFeature.mass_send]: true,
    },
  },
}

export const plansInfo: IPlanInfo[] = Object.values(plansInfoMap)
