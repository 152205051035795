import img_tracking from 'assets/img/Trakcing.svg'
import img_chat from 'assets/img/auto_awesome.svg'
import img_edit from 'assets/img/data-array.svg'

import { Box, Skeleton, ToggleButtonGroup } from '@mui/material'
import { ERegistryType } from 'components/breadcrumbs/BreadcrumbsBlock'
import { DocumentSignListenerContext } from 'components/contexts/documentSignListener'
import { useDocumentSignListenerContextDefaultValue } from 'components/contexts/useDocumentSignListenerContextDefaultValue'
import { HeaderWithBreadcrumbs } from 'components/header/HeaderWithBreadcrumbs'
import { AppBarCommon } from 'components/header/app-bar-common'
import { ModalPlanLimit } from 'components/modal/ModalPlanLimit'
import { SidebarToggleBtn } from 'components/sidebar-toggle-button'
import { useSidebarModeContext } from 'context'
import { IconButtonShareDocument } from 'features/share-document'
import { EDocumentStatus, EDocumentType } from 'helper/consts'
import { EFeature } from 'helper/plan.types'
import { MoreInfoBtn } from 'pages/document-preview/components/send-sign-button/more-info-btn'
import { SidebarMode } from 'pages/document-preview/hooks/useSideBarState'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { shallowEqual, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isDocumentAvailableToShare } from 'utils/document'
import { getWSPlanFeatureRestriction } from 'utils/plans'
import { ButtonSendDocumentOrSign } from './send-sign-button'

const DocumentStatus = ({ status }) =>
  status !== EDocumentStatus.COMPLETED ? <ButtonSendDocumentOrSign /> : <></>

const AppBar = ({ getToggleButtonGroup, updateSidebar }) => {
  const { tsId } = useParams()
  const { document, loading }: IApplicationState = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )

  const { name, status } = useSelector(
    (state: RootState) => state.template,
    shallowEqual
  )

  return loading.info ? (
    <AppBarCommon
      headerTitle={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',

            width: '100%',
            minHeight: '48px',
            py: '8px',
          }}
        >
          <Skeleton width={'30%'} />
          <Box>
            <Skeleton width={'100px'} />
          </Box>
        </Box>
      }
      secondaryBlock={isMobile && <Skeleton width={'100%'} />}
    />
  ) : (
    <AppBarCommon
      headerTitle={
        <HeaderWithBreadcrumbs
          breadcrumb={{
            registry: {
              value: ERegistryType.Documents,
              linkTo: `/teamspace/${tsId}/documents`,
            },
            document: {
              name: document.name || name,
              status: document.status || status,
              shared: document.is_shared,
            },
          }}
          rightChildren={
            !isMobile && (
              <Box sx={{ marginRight: '-12px', ml: '4px' }}>
                <Box
                  display={'flex'}
                  alignItems="center"
                  sx={{ background: 'white', gap: '4px' }}
                >
                  <Box mr="4px">
                    <DocumentStatus status={document.status} />
                  </Box>
                  {getToggleButtonGroup()}
                  {isDocumentAvailableToShare(
                    document.type as EDocumentType,
                    document.status || status
                  ) && <IconButtonShareDocument />}
                  <MoreInfoBtn updateSidebar={updateSidebar} />
                </Box>
              </Box>
            )
          }
        />
      }
      secondaryBlock={
        isMobile && (
          <MobileAppBar
            getToggleButtonGroup={getToggleButtonGroup}
            updateSidebar={updateSidebar}
          />
        )
      }
    />
  )
}

const MobileAppBar = ({ getToggleButtonGroup, updateSidebar }) => {
  const { document }: IApplicationState = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )

  const { status } = useSelector(
    (state: RootState) => state.template,
    shallowEqual
  )

  return (
    <Box display={'flex'} alignItems="center">
      <Box mr={1}>{getToggleButtonGroup()}</Box>
      {isDocumentAvailableToShare(
        document.type as EDocumentType,
        document.status || status
      ) && (
        <Box mr={1}>
          <IconButtonShareDocument />
        </Box>
      )}
      <Box mr={1}>
        <MoreInfoBtn updateSidebar={updateSidebar} />
      </Box>
      <Box ml={'auto'}>
        <DocumentStatus status={status} />
      </Box>
    </Box>
  )
}

export const DocumentPreviewHeader = () => {
  const { sidebarMode, updateSidebar } = useSidebarModeContext()

  const documentSignListenerContextValue =
    useDocumentSignListenerContextDefaultValue()

  const { document, variables }: IApplicationState = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )

  const [limitOn, setLimitOn] = useState<EFeature | undefined>()
  const { plan_info } = useSelector(
    (state: RootState) => state.wsPlan,
    shallowEqual
  )

  const getToggleButtonGroup = () => {
    return (
      <ToggleButtonGroup
        exclusive
        value={sidebarMode}
        onChange={(e, value) => {
          const aiChatLimit = getWSPlanFeatureRestriction(
            EFeature.ai_chat,
            plan_info
          )
          if (
            value == SidebarMode.chat &&
            (aiChatLimit.exhausted || aiChatLimit.prohibited)
          ) {
            setLimitOn(EFeature.ai_chat)
            updateSidebar(sidebarMode)
            return
          }

          updateSidebar(sidebarMode != value ? value : SidebarMode.close)
        }}
      >
        {SidebarToggleBtn({
          mode: SidebarMode.chat,
          src_img: img_chat,
          tooltip: 'Чат',
        })}
        {(document.status === EDocumentStatus.SENT ||
          document.status === EDocumentStatus.COMPLETED) &&
          SidebarToggleBtn({
            mode: SidebarMode.workflow,
            src_img: img_tracking,
            tooltip: 'Отслеживание документа',
          })}

        {(document.status === EDocumentStatus.DRAFT ||
          document.status === EDocumentStatus.REVOKED) &&
          document.type === EDocumentType.TEMPLATE &&
          variables &&
          SidebarToggleBtn({
            mode: SidebarMode.edit,
            src_img: img_edit,
            tooltip: 'Переменные',
          })}
      </ToggleButtonGroup>
    )
  }

  return (
    <DocumentSignListenerContext.Provider
      value={documentSignListenerContextValue}
    >
      {!!limitOn && (
        <ModalPlanLimit
          open={!!limitOn}
          handleClose={() => setLimitOn(undefined)}
          limitOn={limitOn}
        />
      )}
      <AppBar
        getToggleButtonGroup={getToggleButtonGroup}
        updateSidebar={updateSidebar}
      />
    </DocumentSignListenerContext.Provider>
  )
}
