import img_running from 'assets/img/status_avatar/running.svg'
import img_success from 'assets/img/status_avatar/success.svg'
import img_waiting from 'assets/img/status_avatar/waiting.svg'
// import img_cancel from 'assets/img/status_avatar/cancel.svg'
// import img_failed from 'assets/img/status_avatar/failed.svg'
import img_right from 'assets/img/right.svg'

import { Avatar, Badge, Box, Typography } from '@mui/material'

export enum ERecipientStatus {
  finished = 'finished',
  actual = 'actual',
  waiting = 'waiting',
}

const RecipientStatus = {
  [ERecipientStatus.finished]: img_success,
  [ERecipientStatus.actual]: img_running,
  [ERecipientStatus.waiting]: img_waiting,
}

export const Recipient = ({
  title,
  subtitle1,
  subtitle2,
  status,
  onClick,
}: {
  title: string
  subtitle1?: string
  subtitle2?: string
  status: ERecipientStatus
  onClick?: (e?: any) => any
}) => {
  return (
    <Box
      onClick={onClick}
      tabIndex={1}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',

        padding: '8px 12px',
        borderRadius: '4px',

        cursor: !!onClick ? 'pointer' : 'default',
        userSelect: 'none',

        transition: 'all 0.2s ease',

        '&:hover, &:focus': !!onClick
          ? {
              backgroundColor:
                'var(--light-grayscale-background-secondary, #F5F5F5)',
              '& > .hidden': {
                opacity: 1,
              },
            }
          : undefined,

        '&:active': !!onClick
          ? {
              backgroundColor: 'var(--light-grayscale-border, #EDEDEC)',
            }
          : undefined,
      }}
    >
      <Badge
        sx={{ zIndex: '0' }}
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <Box component={'img'} sizes="16px" src={RecipientStatus[status]} />
        }
      >
        <Avatar
          variant="rounded"
          alt={title}
          sx={{
            bgcolor: '#E7E7E6',
            width: '24px',
            height: '24px',
            fontSize: '16px',
            border: '0.6px solid rgba(0, 0, 0, 0.1)',
            color: '#282A2F',
          }}
          children={title.length > 0 ? title[0].toUpperCase() : 'П'}
        />
      </Badge>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Typography variant="paragraph3">{title}</Typography>
        {!!subtitle1 && (
          <Typography variant="paragraph4" color={'text.disabled'}>
            {subtitle1}
          </Typography>
        )}
        {!!subtitle2 && (
          <Typography variant="paragraph4" color={'text.disabled'}>
            {subtitle2}
          </Typography>
        )}
      </Box>

      <Box
        className={'hidden'}
        sx={{
          opacity: 0,
        }}
        component={'img'}
        sizes="16px"
        src={img_right}
      />
    </Box>
  )
}
