import { Dispatch, SetStateAction, useState } from 'react'
import { queryTemplatesCreateCopy } from 'service/template'

export const useActionTemplateDublicate = () => {
  const [loading, setLoading] = useState(false)

  const run = async ({
    templateId,
    setError,
    onSuccessCallback,
  }: {
    templateId: string
    setError?: Dispatch<SetStateAction<string>>
    onSuccessCallback?: (templateId: string) => Promise<any> | any
  }) => {
    try {
      setLoading(true)
      const res = await queryTemplatesCreateCopy({ templateId })
      if (onSuccessCallback)
        await onSuccessCallback(res?.data?.template_id ?? '')
    } catch (err: any) {
      console.error(err)
      if (setError) setError(err?.message || 'Произошла ошибка')
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    run,
  }
}
