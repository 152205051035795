import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { postDraftVariables } from 'service'
import {
  changeTemplateBody,
  createTemplate,
  getTemplateHtml,
  getTemplateInfo,
  getTemplateVariables,
  getWorkflowTemplateActors,
  patchTemplateInfo,
  patchTemplateVariableInfo,
  postTemplateVariables,
  publishTemplate,
  putTemplateVariables,
} from 'service/template'
import {
  arrVariablesToObject,
  splitVariablesByActors,
  addOptionsField,
} from 'utils/templateVariables'

const initialState: ITemplateState = {
  id: '',
  draftVariables: null,
  is_private: false,
  errors: { updateDraftVariables: '', changeName: '', content_html: '' },
  variables: null,
  name: 'Шаблон',
  content_html: '',
  status: 'draft',
  version: '',
  total_documents: 0,
  loading: {
    info: false,
    publish: false,
    getVariables: false,
    getActors: false,
  },
  prefillFormVariables: null,
  actors: [],
}

export const updateTemplateName = createAsyncThunk(
  'template/updateTemplateName',
  async ({ templateId, name }: any, { rejectWithValue }) => {
    try {
      await patchTemplateInfo({ templateId, name })
      return name
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)
export const fetchActors = createAsyncThunk(
  'template/fetchActors',
  async ({ templateId }: any, { rejectWithValue }) => {
    try {
      const res = await getWorkflowTemplateActors({ templateId })
      return res.data.actors || []
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const createVariables = createAsyncThunk(
  'template/createVariables',
  async ({ templateId, variables }: any, { rejectWithValue }) => {
    try {
      await postTemplateVariables({ templateId, variables })
      return { variables }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const editVariable = createAsyncThunk(
  'template/editVariable',
  async ({ templateId, variable, variableId }: any, { rejectWithValue }) => {
    try {
      await patchTemplateVariableInfo({ templateId, variable, variableId })
      return { variable, variableId }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const putTemplateVariablesAction = createAsyncThunk(
  'template/putTemplateVariablesAction',
  async (
    { templateId, variables }: { templateId: string; variables: IVariable[] },
    { rejectWithValue }
  ) => {
    try {
      await putTemplateVariables({ templateId, variables })
      return { templateId }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const publishTemplateAction = createAsyncThunk(
  'template/publishTemplateAction',
  async (
    { templateId, callback }: { templateId: string; callback?: () => void },
    { rejectWithValue }
  ) => {
    try {
      await publishTemplate({ templateId })
      if (callback) callback()
      return { templateId }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const fetchTemplateInfo = createAsyncThunk(
  'template/fetchTemplateInfo',
  async (
    { templateId }: { templateId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await getTemplateInfo({ id: templateId })
      const res_content = await getTemplateHtml({ id: templateId })
      const content_html = res_content.data

      // TODO: remove
      dispatch(fetchTemplateVariables({ templateId }))
      return { ...res.data.template, content_html }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const fetchTemplateVariables = createAsyncThunk(
  'template/fetchTemplateVariables',
  async ({ templateId }: any, { rejectWithValue }) => {
    try {
      const res = await getTemplateVariables({ templateId })
      const { variables } = res.data

      const objVariables = arrVariablesToObject(variables)
      const groupedData = splitVariablesByActors(variables, objVariables)
      const result = addOptionsField(groupedData, variables)

      return { objVariables, prefillFormVariables: result }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

const createFormData = (content_html, name = '') => {
  const blob = new Blob([content_html || '<p></p>'], { type: 'plain/text' })
  const formData = new FormData()
  formData.append('file', blob, 'template.html')
  name && formData.append('template_name', name)
  return formData
}

export const updateTemplateEditorContent = createAsyncThunk(
  'template/updateTemplateEditorContent',
  async ({ templateId, content_html }: any, { rejectWithValue }) => {
    try {
      const formData = createFormData(content_html)
      await changeTemplateBody({ templateId, formData })
      return { content_html }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const saveTemplateContent = createAsyncThunk(
  'template/saveTemplateContent',
  async (
    { tsId, templateId, name, content_html }: any,
    { rejectWithValue }
  ) => {
    try {
      const formData = createFormData(content_html, name)
      if (!templateId) {
        const resp = await createTemplate({ tsId, formData })
        return {
          id: resp.data.template_id,
          name: name,
          content_html: content_html,
        }
      }
      await changeTemplateBody({ templateId, formData })
      return {
        id: templateId,
        content_html,
        name,
      }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateDraftVariables = createAsyncThunk(
  'template/updateDraftVariables',
  async ({ document_id, values }: { document_id: string; values: any }) => {
    try {
      await postDraftVariables({
        body: {
          values,
        },
        id: document_id,
      })
    } catch (error: any) {
      console.error(error.message)
      if (error.message == 'Произошла ошибка')
        throw new Error('Ошибка при сохранении данных')
      throw error
    }
  }
)

const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    clearData: (state) => {
      state.name = 'Шаблон'
      state.status = 'draft'
      state.id = ''
      state.variables = null
      state.content_html = ''
      state.draftVariables = null
      state.prefillFormVariables = null
      state.is_private = false
    },
    setTemplateId: (state, action) => {
      state.id = action.payload.id
    },
    setTemplateVariables: (state, action) => {
      state.variables = action.payload.variables
    },
    clearErrors: (state) => {
      state.errors.updateDraftVariables = ''
      state.errors.content_html = ''
    },
    actionUpdateDraftVariables: (state, action) => {
      state.draftVariables = action.payload.variables
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveTemplateContent.fulfilled, (state, action) => {
        state.id = action.payload?.id
        state.name = action.payload?.name
        state.content_html = action.payload?.content_html
      })
      .addCase(saveTemplateContent.rejected, (state, action) => {
        state.errors.content_html = action.payload as string
      })
      .addCase(fetchTemplateInfo.pending, (state) => {
        state.loading.info = true
      })
      .addCase(fetchTemplateInfo.rejected, (state) => {
        state.loading.info = false
      })
      .addCase(fetchTemplateInfo.fulfilled, (state, action) => {
        state.loading.info = false
        state.name = action.payload.name
        state.status = action.payload.status
        state.id = action.payload.id
        state.content_html = action.payload.content_html
        state.is_private = action.payload.is_private
        state.total_documents = action.payload.total_documents
        state.version = action.payload.version
      })
      .addCase(updateTemplateEditorContent.fulfilled, (state, action) => {
        state.content_html = action.payload.content_html
      })
      .addCase(publishTemplateAction.pending, (state) => {
        state.loading.publish = true
      })
      .addCase(publishTemplateAction.fulfilled, (state, action) => {
        state.loading.publish = false
        state.id = action.payload.templateId
      })
      .addCase(publishTemplateAction.rejected, (state) => {
        state.loading.publish = false
      })
      .addCase(fetchTemplateVariables.pending, (state) => {
        state.loading.getVariables = true
      })
      .addCase(fetchTemplateVariables.fulfilled, (state, action) => {
        state.variables = action.payload.objVariables
        state.prefillFormVariables = action.payload.prefillFormVariables
        state.loading.getVariables = false
      })
      .addCase(fetchTemplateVariables.rejected, (state) => {
        state.loading.getVariables = false
      })
      .addCase(fetchActors.pending, (state) => {
        state.loading.getActors = true
      })
      .addCase(fetchActors.fulfilled, (state, action) => {
        state.actors = action.payload
        state.loading.getActors = false
      })
      .addCase(fetchActors.rejected, (state) => {
        state.loading.getActors = false
      })
      .addCase(createVariables.fulfilled, (state, action) => {
        state.variables = { ...state.variables, ...action.payload.variables }
      })
      .addCase(editVariable.fulfilled, (state, action) => {
        if (state.variables)
          state.variables = {
            ...state.variables,
            [action.payload.variableId]: {
              ...state.variables[action.payload.variableId],
              ...action.payload.variable,
            },
          }
      })
      .addCase(updateDraftVariables.rejected, (state, action) => {
        state.errors.updateDraftVariables =
          action.error.message || 'Ошибка сохранения значений шаблона'
      })
      // TODO: add tests
      .addCase(updateTemplateName.fulfilled, (state, action) => {
        state.name = action.payload
      })
      .addCase(updateTemplateName.rejected, (state, action) => {
        state.errors.changeName = action.error.message || 'Error change name'
      })
  },
})

export const {
  clearData,
  setTemplateId,
  setTemplateVariables,
  clearErrors,
  actionUpdateDraftVariables,
} = templateSlice.actions
export default templateSlice.reducer
