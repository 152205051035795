import { Box, Button, Typography } from '@mui/material'
import { MessageSnackbar } from 'components'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { clearPlanErrors } from 'store/slices/wsPlan'
import { wsPlanStartTrial } from 'store/slices/wsPlan/wsPlanStartTrial'
import {
  EModalBannerBg,
  EModalBannerIcon,
  ModalSmallWithBanner,
} from '../ModalSmallWithBanner'

export const ModalTrialStarted = () => {
  const dispatch = useDispatch<any>()
  const { plan_info, loading, error, workspace_id } = useSelector(
    (state: RootState) => state.wsPlan
  )
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace
  )
  const [open, setOpen] = useState(plan_info.is_trial_available)

  useEffect(() => {
    setOpen(plan_info.is_trial_available)
  }, [plan_info.is_trial_available])

  const handleClickStartTrial = async () => {
    await dispatch(
      wsPlanStartTrial({
        wsId: selectedWorkspace?.id || '',
        handleClose: () => setOpen(false),
      }) as any
    )
  }

  if (workspace_id != selectedWorkspace?.id) return null
  return (
    <>
      <MessageSnackbar
        message={error.plan}
        clearMessage={() => {
          dispatch(clearPlanErrors())
        }}
        severity={'error'}
      />
      <ModalSmallWithBanner
        open={open}
        banner={{
          icon: EModalBannerIcon.diamond_blue,
          background: EModalBannerBg.doodocs_logos_gray,
        }}
        children={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: '24px 16px',
              textAlign: 'center',
            }}
          >
            <Typography variant="heading4">
              У вас 3 бесплатных документа на 7 дней
            </Typography>
            <Typography mt={'8px'}>
              Бесплатный пробный период начался
            </Typography>

            <Button
              variant="contained"
              color="primary"
              sx={{
                mt: '16px',
              }}
              onClick={handleClickStartTrial}
              disabled={loading.plan}
              fullWidth={isMobile}
            >
              Продолжить
            </Button>
          </Box>
        }
      />
    </>
  )
}
