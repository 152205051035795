import { Button } from '@mui/material'
import { MessageSnackbar } from 'components'
import { useState } from 'react'
import { shallowEqual } from 'react-redux'
import { planApi } from 'service/plan'
import { useAppSelector } from 'store/store'
import { Card } from '../Card'

export const CardPaymentCardChange = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const { selectedWorkspace } = useAppSelector(
    (state) => state.selectedWorkspace,
    shallowEqual
  )
  const { card } = useAppSelector((state) => state.wsPlan, shallowEqual)

  const handleClick = async () => {
    try {
      setLoading(true)
      if (!selectedWorkspace?.id) throw new Error('Нет выбранного пространства')
      const resp = await planApi.getPaymentCardChangeLink({
        workspace_id: selectedWorkspace.id,
      })
      window.location.href = resp.data.redirect_url
    } catch (err: any) {
      console.error(err)
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  const getCardMask = () => {
    if (!card) return ''
    if (card.card_mask.length < 4) return card.card_mask
    return card.card_mask.slice(-4)
  }

  if (!card) return null
  return (
    <>
      <Card
        label="Номер карты:"
        text={`Заканчивается на ${getCardMask()}`}
        buttons={[
          <Button
            key={'change'}
            variant="outlined"
            color="secondary"
            size="small"
            onClick={handleClick}
            disabled={loading || !selectedWorkspace}
          >
            Изменить
          </Button>,
        ]}
      />

      <MessageSnackbar
        severity={'error'}
        message={error}
        clearMessage={() => setError('')}
      />
    </>
  )
}
