import { EServiceWorkflowStepType } from "helper/workflow.service.types"
import { IStep } from "./IStep"
import { IRecipient } from "../recipients/IRecipient"

export abstract class CAbstractStep implements IStep {
  abstract index: number
  abstract type?: EServiceWorkflowStepType
  abstract recipients: IRecipient[]

  export() {
    const result = {
      recipients: this.recipients.map(recipient => recipient.export()),
    }

    Object.getOwnPropertyNames(this).forEach(key => {
      if (result[key]) return
      result[key] = this[key]
    })

    return result
  }
}
