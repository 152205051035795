export enum AnalyticsEventNames {
  setup = 'setup',
  setup_started = 'setup_started',
  page_visit = 'page_visit',
  new_doc_start = 'new_doc_start',
  send_doc_start = 'send_doc_start',
  sign_doc_start = 'sign_doc_start',
  plan_change = 'plan_change',
  invites_sent = 'invites_sent',
  chat_doc = 'chat_doc',
  request_invoice_started = 'request_invoice_started',
}
