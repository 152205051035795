import { Modal, Box, IconButton } from '@mui/material'
import { useState } from 'react'
import img_close from 'assets/img/close-btn.svg'

const modalProps = {
  pX: '16px',
  mX: '16px',
  mY: '12px',
}

export interface IDefaultModalProps {
  open: boolean
  handleClose?: any | (() => void)
  header: {
    noPaddings?: boolean
    children: React.ReactNode
  }
  content?: {
    noPaddings?: boolean
    children: React.ReactNode
  }
  footer?: {
    noPaddings?: boolean
    children: React.ReactNode
  }
}

interface ModalCenterWidhtProps {
  width: string
}

export const ModalCenter = ({
  width,
  open,
  handleClose,
  header,
  content,
  footer,
}: IDefaultModalProps & ModalCenterWidhtProps) => {
  const [headerHeight, setHeaderHeight] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          left: '50%',
          top: {
            xs: `calc(100% - ${modalProps.mY})`,
            sm: '50%',
          },
          transform: {
            xs: 'translate(-50%, -100%)',
            sm: 'translate(-50%, -50%)',
          },
          maxWidth: width,
          width: `calc(100vw - ${modalProps.mX} * 2)`,

          overflow: 'hidden',

          bgcolor: 'white',
          boxShadow:
            '0px 3px 5px rgba(0, 0, 0, 0.1), 0px 1px 18px rgba(0, 0, 0, 0.06), 0px 6px 10px rgba(0, 0, 0, 0.08)',
          borderRadius: '4px',

          maxHeight: `calc(100vh - ${modalProps.mY} * 2)`,
          // ? Rewrite maxHeight property if browser supports it
          '@supports (height: 100dvh)': {
            // ? dvh supports in 88% > than people - https://caniuse.com/?search=dvh
            maxHeight: `calc(100dvh - ${modalProps.mY} * 2)`,
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',

            width: '100%',
            p: header.noPaddings ? undefined : `12px ${modalProps.pX}`,
            backgroundColor: 'inherit',
          }}
          ref={(ref: any) => {
            setHeaderHeight(ref?.clientHeight ?? 0)
          }}
        >
          <Box flex={1}>{header.children}</Box>
          {handleClose && (
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: modalProps.pX,
                top: '12px',
                p: { xs: '4px' },
              }}
            >
              <Box
                component={'img'}
                src={img_close}
                alt="close"
                width={'16px'}
              />
            </IconButton>
          )}
        </Box>

        {content && (
          <Box
            sx={{
              p: content.noPaddings
                ? undefined
                : `8px ${modalProps.pX} ${footer ? '8px' : '16px'}`,
              overflowY: 'auto',

              maxHeight: `calc(100vh - ${modalProps.mY} * 2 - ${headerHeight}px - ${footerHeight}px)`,
              // ? Rewrite maxHeight property if browser supports it
              '@supports (height: 100dvh)': {
                // ? dvh supports in 88% > than people - https://caniuse.com/?search=dvh
                maxHeight: `calc(100dvh - ${modalProps.mY} * 2 - ${headerHeight}px - ${footerHeight}px)`,
              },
            }}
          >
            {content.children}
          </Box>
        )}

        {footer && (
          <Box
            sx={{
              width: '100%',
              p: footer.noPaddings ? undefined : `8px ${modalProps.pX} 16px`,
              backgroundColor: 'inherit',
            }}
            ref={(ref: any) => {
              setFooterHeight(ref?.clientHeight ?? 0)
            }}
          >
            {footer.children}
          </Box>
        )}
      </Box>
    </Modal>
  )
}
