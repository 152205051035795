import img_edit from 'assets/img/edit.svg'
import img_running from 'assets/img/status_avatar/running.svg'
import img_success from 'assets/img/status_avatar/success.svg'
import img_waiting from 'assets/img/status_avatar/waiting.svg'

import { Avatar, Badge, Box, Typography } from '@mui/material'
import img_right from 'assets/img/right.svg'
import { ButtonCopy } from 'components/button/ButtonCopy'
import { usePopupHelpers } from 'features/workflow-builder/components/WorkflowBuilder/BlockRecipients/usePopupHelpers'
import { IRecipient } from 'helper/recipients'
import { useEffect, useState } from 'react'
import { RecipientsSignerLinkMenu } from '../RecipientSignerLinkMenu'
import { ERecipientStatus } from '../Recipient'

const RecipientStatus = {
  [ERecipientStatus.finished]: img_success,
  [ERecipientStatus.actual]: img_running,
  [ERecipientStatus.waiting]: img_waiting,
}

export const RecipientViaLink = ({
  title,
  status,
  onClick,
  recipient,
}: {
  title: string
  status: ERecipientStatus
  onClick?: (e?: any) => any
  recipient: IRecipient
}) => {
  const mhRecipient = usePopupHelpers()
  const [editButtonStyle, setEditButtonStyle] = useState({})

  useEffect(() => {
    setEditButtonStyle(
      mhRecipient.open
        ? {
            opacity: 1,
            backgroundColor:
              'var(--light-grayscale-background-secondary, #F5F5F5)',
          }
        : { opacity: 0 }
    )
  }, [mhRecipient.open])

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'start'}
      gap={'8px'}
      padding={'8px 12px'}
      sx={{
        cursor: !!onClick ? 'pointer' : 'default',
        userSelect: 'none',

        transition: 'all 0.2s ease',
        '&:hover, &:focus': !!onClick
          ? {
              backgroundColor:
                'var(--light-grayscale-background-secondary, #F5F5F5)',
              '& > .hidden': {
                opacity: 1,
              },
            }
          : undefined,

        '&:active': !!onClick
          ? {
              backgroundColor: 'var(--light-grayscale-border, #EDEDEC)',
            }
          : undefined,
      }}
    >
      <Box
        display="flex"
        gap={'8px'}
        sx={{
          alignItems: 'center',
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: '#90959D',
          }}
        >
          {title}
        </Typography>
        <Box
          className={'hidden'}
          sx={{
            ...editButtonStyle,
            p: '4px',
            borderRadius: '4px',
            '&:hover, &:focus': {
              backgroundColor:
                'var(--light-grayscale-background-secondary, #F5F5F5)',
              opacity: 1,
            },
          }}
          component={'img'}
          sizes="20px"
          src={img_edit}
          onClick={(e) => {
            mhRecipient.handleOpen(e)
          }}
        />
      </Box>
      <RecipientsSignerLinkMenu
        anchorEl={mhRecipient.anchorEl}
        onClose={mhRecipient.onClose}
        recipient={recipient}
        open={mhRecipient.open}
      />
      <Box
        onClick={onClick}
        tabIndex={1}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '8px',

          padding: '8px 0px',
          borderRadius: '4px',
        }}
      >
        <Badge
          sx={{ zIndex: '0' }}
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <Box component={'img'} sizes="16px" src={RecipientStatus[status]} />
          }
        >
          <Avatar
            variant="rounded"
            alt={title}
            sx={{
              bgcolor: '#E7E7E6',
              width: '24px',
              height: '24px',
              fontSize: '16px',
              border: '0.6px solid rgba(0, 0, 0, 0.1)',
              color: '#282A2F',
            }}
            children={title.length > 0 ? title[0].toUpperCase() : 'П'}
          />
        </Badge>

        <Box
          sx={{
            display: 'flex',
            borderRadius: '4px',
            border: '1px solid #EDEDEC',
            p: '6px 4px 6px 12px',
            alignSelf: 'stretch',
            background: 'white',
            maxWidth: '280px !important',
          }}
        >
          <Typography
            flex={1}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            variant="paragraph3"
            color={'#282A2F'}
          >
            {`https://link.doodocs.kz/${recipient.attrs.link}`}
          </Typography>
          <ButtonCopy
            variant="text"
            size={'small'}
            copyTextValue={`https://link.doodocs.kz/${recipient.attrs.link}`}
            sx={{
              height: '20px !important',
            }}
          />
        </Box>

        <Box
          className={'hidden'}
          component={'img'}
          sizes="16px"
          src={img_right}
        />
      </Box>
      <Typography variant="paragraph4" color={'text.disabled'}>
        Уникальная ссылка для одного подписанта. Скопируйте и отправьте
        подписанту любым удобным способом или перейдите по ссылке для подписания
      </Typography>
    </Box>
  )
}
