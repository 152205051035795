import { buildFormData } from './formData'
import { uploadPdf } from 'service'
import { addTemplateFromDoc } from 'service/template'

export const uploadFile = async ({ file, name, ts_id }) => {
  const formData = buildFormData({ file, name, ts_id })

  const res = await uploadPdf({ formData })
  const id = res.data.document_id || ''

  return id
}

export const uploadTemplate = async ({ file, name, ts_id }) => {
  const formData = buildFormData({ file, name, ts_id })

  await addTemplateFromDoc({ formData: formData, ts_id })
}

const extensions = {
  pdf: 'pdf',
  doc: 'doc',
  docx: 'docx',
}

export const getDocumentNameWithoutExtension = (name: string) => {
  if (!name) return name
  if (typeof name !== 'string') return name
  const split = name.split('.')
  if (split.length === 1) return name

  const ext = split[split.length - 1]
  if (!extensions[ext]) return name

  const nameWithoutExtension = split.slice(0, split.length - 1).join('.')
  return nameWithoutExtension
}