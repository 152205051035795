import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { StatusBadge } from 'components/status-badge'
import { useNavigate, useParams } from 'react-router-dom'
import { EDocumentStatus, UrlKeys } from 'helper/consts'
import { useCheckbox } from 'hooks/useCheckbox'
import { useMemo } from 'react'
import { Empty } from 'components'

export const RegistryTable = () => {
  const { documents, page, count, filter } = useSelector(
    (state: RootState) => state.campaignDocs,
    shallowEqual
  )

  const { tsId } = useParams()
  const navigate = useNavigate()

  const { checkboxHeader, checkboxTable } = useCheckbox({
    page,
    rows: documents,
  })

  const showEmptyPage = useMemo(() => {
    return <Empty title={'Тут нет документов'} />
  }, [filter])

  if (count === 0) return showEmptyPage

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table" color="secondary">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">{checkboxHeader()}</TableCell>
              <TableCell sx={{ color: 'text.disabled' }}>Документ</TableCell>
              <TableCell sx={{ color: 'text.disabled' }}>Статус</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {documents.map((row, i) => (
              <TableRow key={i}>
                <TableCell padding="checkbox">
                  {checkboxTable({ row })}
                </TableCell>
                <TableCell
                  color="secondary"
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    navigate(
                      row.template_id && row.status === EDocumentStatus.DRAFT
                        ? `/teamspace/${tsId}/documents/${row.id}?${UrlKeys.templateId}=${row.template_id}`
                        : `/teamspace/${tsId}/documents/${row.id}`
                    )
                  }
                >
                  <Typography
                    color="text.primary"
                    fontWeight={500}
                    textOverflow={'ellipsis'}
                    overflow={'hidden'}
                    maxWidth={'240px'}
                    sx={{ textWrap: 'nowrap', wordBreak: 'keep-all' }}
                  >
                    {row.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <StatusBadge
                    status={
                      row.status === EDocumentStatus.DRAFT
                        ? EDocumentStatus.READY_TO_SEND
                        : row.status
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
