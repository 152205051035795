const actorColors = [
  "#BDDFFF",
  "#CFD2FF",
  "#FF6534",
  "#6BD3C2",
  "#FFCC77",
  "#FF9B7A",
  "#90959D",
  "#4FBC4D",
]

export const getFillerColorByActorId = (actorId: number) => {
  if (actorId > 0) actorId = actorId - 1
  if (actorId >= actorColors.length) actorId = actorId % actorColors.length
  return actorColors[actorId]
}