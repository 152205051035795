import { Box } from '@mui/material'
import { FormikTextField } from 'components/using-formik-context/FormikTextField'
import { useFormikContext } from 'formik'
import { ChangeEvent } from 'react'
import * as Yup from 'yup'

export interface IFormikFieldsPaymentRequisites {
  company_name: string
  bin: string
  legal_address: string
}

export interface IFormikFormPaymentRequisitesProps {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const getFormPaymentRequisitesValidationSchema = () => {
  return Yup.object().shape({
    company_name: Yup.string().required('Обязательное поле'),
    bin: Yup.string()
      .required('Обязательное поле')
      .matches(/^\d+$/, 'Поле должно содержать только цифры')
      .length(12, 'Поле должно содержать 12 цифр'),
    legal_address: Yup.string().required('Обязательное поле'),
  })
}

export const FormikFormPaymentRequisites = ({
  onChange,
}: IFormikFormPaymentRequisitesProps) => {
  const formik = useFormikContext<IFormikFieldsPaymentRequisites>()

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
      <Box>
        <FormikTextField
          name="company_name"
          label="Название компании"
          onChange={onChange}
          disabled={formik.isSubmitting}
        />
      </Box>
      <Box>
        <FormikTextField
          name="bin"
          label="БИН / ИИН"
          onChange={onChange}
          disabled={formik.isSubmitting}
        />
      </Box>
      <Box>
        <FormikTextField
          name="legal_address"
          label="Юридический адрес"
          onChange={onChange}
          disabled={formik.isSubmitting}
        />
      </Box>
    </Box>
  )
}
