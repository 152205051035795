import { Button } from '@mui/material'

export const ButtonPurple = ({ ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        p: '6px 12px',
        background: '#9747FF',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        '&:hover': {
          background:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #9747FF',
        },
        '&:active': {
          background:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #9747FF',
        },
        '&:disabled': {
          background: '#9747FF',
          border: '1px solid rgba(0, 0, 0, 0.1)',
        },
        ...props.sx,
      }}
    />
  )
}
