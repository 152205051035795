import { Box } from '@mui/material'
import styles from './styles.module.css'

export const LoadingHorisontalDots = ({ ...props }) => {
  return (
    <Box
      style={{
        ...props.style,
      }}
      className={styles.loading_box}
      sx={{
        ...props.sx,
      }}
    >
      <span className={styles.dot} />
      <span className={styles.dot} />
      <span className={styles.dot} />
    </Box>
  )
}
