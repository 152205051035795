import img_copy from 'assets/img/content_copy.svg'
import img_check from 'assets/img/ic_check.svg'
import img_link from 'assets/img/link_black.svg'

import { Box, TableCell, Typography } from '@mui/material'
import { IconButtonImage } from 'components/button/IconButtonImage'
import { useCopyToClipboard } from 'hooks/useCopyToClipboard'
import { Link } from 'react-router-dom'

const IconButtonCopy = ({ copyText }: { copyText: string }) => {
  const { copied, copyToClipboard } = useCopyToClipboard()
  return (
    <IconButtonImage
      icon={{
        src: copied ? img_check : img_copy,
        size: '16px',
      }}
      tooltip={{
        text: 'Скопировать',
      }}
      onClick={() => copyToClipboard(copyText)}
      size="small"
    />
  )
}

const IconButtonLink = ({ link }: { link: string }) => {
  return (
    <Link to={link}>
      <IconButtonImage
        icon={{
          src: img_link,
          size: '16px',
        }}
        tooltip={{
          text: 'Открыть документ',
        }}
        onClick={() => undefined}
        size="small"
      />
    </Link>
  )
}

export const TableBodyCellVariable = ({
  text,
  action,
}: {
  text: string
  action?:
    | {
        type: 'copy'
      }
    | {
        type: 'link'
        link: string
      }
}) => {
  return (
    <TableCell
      sx={{
        position: 'relative',
        minWidth: '180px',
        verticalAlign: 'top',
        '& .hidden': {
          opacity: 0,
          transition: '0.2s',
        },
        '&:hover .hidden': {
          opacity: 1,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '4px',
        }}
      >
        <Typography variant="paragraph3">{text}</Typography>
      </Box>
      {action && (
        <Box
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
          }}
          className={'hidden'}
        >
          {action.type == 'copy' && <IconButtonCopy copyText={text} />}
          {action.type == 'link' && <IconButtonLink link={action.link} />}
        </Box>
      )}
    </TableCell>
  )
}
