import { getUsersTokens } from 'entity/api-key/api/getUsersTokens'
import { IApiKey } from 'entity/api-key/types'
import { useCallback, useState } from 'react'

export type TUseLoadAPIKeyOnSuccess = (res: IApiKey) => void

export const useLoadAPIKey = ({
  onError,
  onSuccess,
}: {
  onError?: TFuncOnError
  onSuccess: TUseLoadAPIKeyOnSuccess
}) => {
  const [loading, setLoading] = useState(false)

  const load = useCallback(async () => {
    try {
      setLoading(true)
      const res = await getUsersTokens()
      onSuccess(res)
    } catch (error: any) {
      onError && onError(error.message)
    } finally {
      setLoading(false)
    }
  }, [onError, onSuccess])

  return { load, loading }
}
