import { Box, Typography, Link } from '@mui/material'
import { DropZone } from 'components/input'
import { MessageSnackbar } from 'components/snackbar'
import { useCallback, useEffect, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { createCampaignFromExcel } from 'service/template'
import * as multipart from 'parse-multipart-data'
import * as buffer from 'buffer'
import { updateCampaign } from 'store/slices/campaign'
import { DownloadExcel } from './downloadExcel'
import { DownloadExcelWithErrors } from './dowloadExcelWErrs'
import { useParams } from 'react-router-dom'
import { excelBulksendSlugErrors } from 'helper/consts'

window.Buffer = buffer.Buffer

export const Load = ({ setTab }) => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState({
    file: '',
    meta: {
      errors_count: -1,
      success_count: '',
      notification_count: '',
      campaign_id: '',
      variables: '',
    },
  })
  const dispatch = useDispatch()
  const {
    id,
    iin,
    checked_variables,
    campaign_name,
    error: cError,
  } = useSelector((state: RootState) => state.campaign, shallowEqual)

  const { tsId } = useParams()

  useEffect(() => {
    if (response.meta.errors_count === 0) {
      setTab(3)
      dispatch(
        updateCampaign({
          current_step: 3,
          campaign_id: response.meta.campaign_id,
          draftVariables: response.meta.variables,
          success_count: response.meta.success_count,
        }) as any
      )
    }
  }, [response])

  const onDrop = useCallback(async (acceptedFiles) => {
    try {
      setLoading(true)
      const formData = new FormData()

      formData.append('file', acceptedFiles[0])
      formData.append('variables', JSON.stringify(checked_variables))
      formData.append('campaign_name', campaign_name)
      formData.append('iin', iin + '')
      formData.append('template_id', id + '')

      const res = await createCampaignFromExcel({ id: tsId, formData })

      const body = await Buffer.from(res.data as any, 'base64') // The raw body as a Buffer
      const header = res.headers['content-type']
      let boundary = header.split(' ')[1]
      boundary = header.split('=')[1]

      const parts = multipart.parse(body, boundary)
      await parts.forEach((item) => {
        const key = item.name as string
        setResponse((state) => ({
          ...state,
          [key]: key === 'file' ? item.data : JSON.parse(item.data.toString()),
        }))
      })
      setLoading(false)
    } catch (err) {
      const msg = (err as Error).message
      setError(excelBulksendSlugErrors[msg] || msg)
      setLoading(false)
    }
  }, [])

  const onDropRejected = useCallback(() => {
    setError('Можем принять только 1 файл в формате .pdf, весом не более 5MB')
  }, [])

  return (
    <>
      <MessageSnackbar
        message={cError}
        clearMessage={() => setError('')}
        severity="error"
      />
      <Box>
        <Box>
          {response.meta.errors_count > 0 ? (
            <Box p="8px 16px 16px">
              <Typography variant="h6" color={'error'}>
                Количество документов с ошибками: {response.meta.errors_count}
              </Typography>
              <Typography variant="body1" color={'text.disabled'} mt={'2px'}>
                Скорее всего, вы пропустили обязательное поле или внесли
                некорректные данные. Ознакомьтесь с отчетом об ошибках и
                загрузите файл с исправленными ошибками.
                <br /> Нужна помощь?{' '}
                <Link
                  target={'_blank'}
                  href={'https://t.me/doodocskz'}
                  color={'text.disabled'}
                >
                  Напишите нам.{' '}
                </Link>
              </Typography>
              <Box mt={1}>
                <DownloadExcelWithErrors
                  data={response.file}
                  filename={`${campaign_name}.xlsx`}
                  // countErr={response.meta.error_count}
                />
              </Box>
            </Box>
          ) : (
            <Box p="8px 16px 16px">
              <Typography variant="h6" mb="2px">
                Скачайте excel-файл и заполните своими данными
              </Typography>
              <Typography variant="body1" color={'text.disabled'}>
                Для каждой строки в excel-файле будет создан свой документ.
                Нужна помощь?{' '}
                <Link
                  color={'text.disabled'}
                  href={'https://t.me/doodocskz'}
                  target={'_blank'}
                >
                  Напишите нам.{' '}
                </Link>
              </Typography>
              <Box mt={1}>
                <DownloadExcel />
              </Box>
            </Box>
          )}
        </Box>
        <Box p={'8px 16px 16px'}>
          <Box>
            <Typography variant="h6" mb="2px">
              Загрузите Excel-файл с заполненными данными сюда
            </Typography>
            <DropZone
              clearError={() => setError('')}
              error={error}
              loading={loading}
              onDrop={onDrop}
              onDropRejected={onDropRejected}
              maxSizeInBytes={5_000_000}
              accept={{
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  ['.xlsx'],
                'application/vnd.ms-excel': ['.xls'],
              }}
              description={
                'Формат: .xlsx, максимальное количество строк 1000, размер файла 5 МБ'
              }
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
