import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'
import { useMemo } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { skipOnboardingAction } from 'store/slices/userSlice'

export const useOnboardingCreateDoc = ({ btnRef, setWithPulse }) => {
  const { onboarding } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  )

  const dispatch = useDispatch()

  const finishCreateDocStep = () => {
    dispatch(skipOnboardingAction() as any)
    driverObj.destroy()
  }

  const driverObj = useMemo(() => {
    return driver({
      onCloseClick: finishCreateDocStep,
      onDestroyed: () => setWithPulse(false),
      showProgress: true,
      popoverClass: 'onboarding popover',
      progressText: '{{current}} из {{total}}',
      showButtons: ['next', 'close'],
      nextBtnText: 'Далее →',
      doneBtnText: 'Далее →',
      overlayOpacity: 0.3,
      steps: [
        {
          element: '#load-doc-item',
          popover: {
            popoverClass: 'no-overlay-popover',
            title: '👋 \nСоздайте свой первый документ ',
            description: '',
            onNextClick: () => {
              btnRef.current.click()
            },
          },
        },
        {
          element: '#dropzone',
          popover: {
            title: 'Выберите файл или перенесите в выделенную область',
            side: 'right',
            showButtons: [],
          },
        },
      ],
    })
  }, [onboarding.document_create, btnRef])

  return { driverObj }
}
