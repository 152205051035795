import {
  DocumentsChangePageProvider,
  SearchBarContextProvider,
  SelectedDocsIdsProvider,
} from 'context'
import { withDashboardLayout } from 'layout'
import { Header } from './components/header'
import { Footer } from './components/footer'
import { RegistryContent } from './components/registry'

export const Registry = () =>
  withDashboardLayout(RegistryContent)({
    Header: Header,
    Footer: Footer,
    providers: [
      SelectedDocsIdsProvider,
      SearchBarContextProvider,
      DocumentsChangePageProvider,
    ],
  })
