import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
} from '@mui/material'
import { AnalyticsEvent } from 'config/analytics/segment.com/events'
import { EDocumentStatus } from 'helper/consts'
import { useContext, useState, useCallback, useMemo } from 'react'
import { shallowEqual } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { useAppSelector } from 'store/store'
import { canUseTemplate } from 'utils'
import { TemplatesRegistryContext } from '../../hooks/TemplatesRegistryContext'
import { MenuTemplateActionsFromRegistry } from '../MenuTemplateActionsFromRegistry'
import { TableRowComponent } from '../table-row-component'
import { TableRowComponentLoading } from '../table-row-component-loading'
import { Empty, Loading } from 'components'
import { EIllustration } from 'components/empty'
import { TSRolePermissions } from 'helper/roles'

export const StandardTemplatesTable = () => {
  const { tsId } = useParams()
  const { rows } = useContext(TemplatesRegistryContext)
  const { selectedTeamspace } = useAppSelector(
    (state) => state.teamspace,
    shallowEqual
  )
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(Boolean(anchorEl))

  const { loading } = useAppSelector((state) => state.templates, shallowEqual)

  const handleClose = () => {
    setOpen(false)
  }
  const handleClickNavigateTemplate = (e, row) => {
    e.stopPropagation()
    e.preventDefault()
    const { status, id } = row
    AnalyticsEvent.new_doc_start('no')

    const path =
      status === EDocumentStatus.DRAFT
        ? `/teamspace/${tsId}/templates/${id}`
        : `/teamspace/${tsId}/templates/${id}/manage?tab=documents`
    return navigate(path)
  }

  const getAnchorTemplate = () => {
    const selectedTemplate = rows.find((tmpl) => tmpl?.id === anchorEl?.id)
    return selectedTemplate as ITemplate
  }

  const handleRowClick = useCallback(
    (e, row) => {
      if (canUseTemplate(row)) {
        handleClickNavigateTemplate(e, row)
      }
    },
    [handleClickNavigateTemplate]
  )

  const handleClick = () => {
    navigate(`/teamspace/${tsId}/templates/new`)
  }

  const showEmptyPage = useMemo(() => {
    return (
      <Empty
        illustration={EIllustration.empty_box}
        title={'Нет шаблонов'}
        subtitle={
          'Экономьте время, создавая шаблоны — документы с переменными данными, которые можно легко использовать повторно.'
        }
      >
        {selectedTeamspace &&
          TSRolePermissions[selectedTeamspace?.group_name].canManageDocs && (
            <Box>
              <Button
                variant={'outlined'}
                color={'secondary'}
                onClick={handleClick}
              >
                + Новый шаблон
              </Button>
            </Box>
          )}
      </Empty>
    )
  }, [])

  if (loading.private) return <Loading />
  if (rows.length == 0) return showEmptyPage

  return (
    <TableContainer>
      <Table aria-label="simple table" color="secondary">
        <TableHead>
          <TableRow>
            <TableCell sx={{ pl: 0 }}>Шаблон</TableCell>
            <TableCell color="text.secondary" sx={{ pl: 0 }}>
              Документов
            </TableCell>
            <TableCell color="text.secondary" sx={{ pl: 0 }}>
              Статус
            </TableCell>
            <TableCell color="text.secondary" sx={{ pl: 0 }}>
              Изменено
            </TableCell>
            <TableCell sx={{ pl: 0 }}></TableCell>
            <TableCell sx={{ pl: 0 }}></TableCell>
            <TableCell sx={{ pl: 0 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((template, i) => {
            if (template === undefined) return null
            if (template === null)
              return (
                <TableRowComponentLoading isPrivateTemplate={true} key={i} />
              )

            return (
              <TableRowComponent
                key={`${template.id}`}
                template={template}
                handleClickNavigateTemplate={handleRowClick}
                setAnchorEl={setAnchorEl}
                setOpen={setOpen}
                open={open}
                anchorEl={anchorEl}
              />
            )
          })}
        </TableBody>

        {anchorEl && getAnchorTemplate() && (
          <MenuTemplateActionsFromRegistry
            anchorEl={anchorEl}
            handleClose={handleClose}
            template={getAnchorTemplate()}
            open={open}
            teamspaceId={tsId ?? ''}
          />
        )}
      </Table>
    </TableContainer>
  )
}
