import { EDocumentStatus } from "helper/consts";
import img_folder from 'assets/img/folder_open.svg'
import img_archive from 'assets/img/archive.svg'
import img_delete from 'assets/img/ic_delete.svg'


export const toolbarDocumentsStatusList = [
  { key: EDocumentStatus.ALL, value: 'Все', mobile: true, srcImg: img_folder },
  { key: EDocumentStatus.DRAFT, value: 'Черновики', mobile: true, srcImg: img_folder },
  {
    key: EDocumentStatus.ACTIONS_REQUIRED,
    value: 'Ожидают вас',
    mobile: true,
    srcImg: img_folder,
  },
  {
    key: EDocumentStatus.WAITING_OTHERS,
    value: 'Ожидают других',
    mobile: false,
    srcImg: img_folder,
  },
  {
    key: EDocumentStatus.COMPLETED,
    value: 'Завершенные',
    mobile: true,
    srcImg: img_folder,
  },
  { key: EDocumentStatus.ARCHIVED, value: 'Архив', mobile: false, srcImg: img_archive },
  {
    key: EDocumentStatus.DELETED,
    value: 'Корзина',
    mobile: false,
    srcImg: img_delete,
  },
]