import { useSortable } from '@dnd-kit/sortable'
import { Box, IconButton, Typography } from '@mui/material'
import { CSS } from '@dnd-kit/utilities'
import img_more from 'assets/img/more_horiz.svg'
import img_drag from 'assets/img/drag.svg'

interface RecipientBadgeProps {
  /**
   * sortable - if present, the badge will be sortable
   * @field id - unique id of badge, using for sort
   * @field number - visual order number
   */
  sortable?: {
    id: any
    order: number
  }
  imgSrc: string
  text: string
  onClick?: (() => void) | any
  actionBtn?: {
    onClick: (() => void) | any
    src?: string
  }
}

export const RecipientBadge = ({
  imgSrc,
  text,
  onClick,
  sortable,
  actionBtn,
}: RecipientBadgeProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    isDragging,
    transition,
  } = useSortable({ id: sortable?.id })

  return (
    <Box
      sx={{
        display: 'inline-flex',
        padding: '2px 8px 2px 4px',
        alignItems: 'center',
        gap: '4px',
        maxWidth: '200px',

        borderRadius: '32px',
        border: '1px solid var(--light-grayscale-border, #EDEDEC)',
        background: 'var(--light-grayscale-background-secondary, #F5F5F5)',

        cursor: onClick ? 'pointer' : 'default',
        userSelect: 'none',
        zIndex: isDragging ? '1' : 'auto',
        transform: CSS.Transform.toString(transform),
        transition,
      }}
      ref={setNodeRef}
      {...attributes}
      onClick={onClick}
    >
      {!!sortable && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 'inherit',
          }}
        >
          <Box
            component={'img'}
            {...listeners}
            src={img_drag}
            width={'16px'}
            height={'16px'}
            sx={{
              cursor: isDragging ? 'grabbing' : 'grab',
            }}
          />
          <Typography>{sortable?.order}</Typography>
        </Box>
      )}
      <Box
        sx={{
          position: 'relative',
          width: '16px',
          height: '16px',
          minWidth: '16px',
          minHeight: '16px',

          borderRadius: '50%',
          overflow: 'hidden',
        }}
      >
        <Box component={'img'} width={'100%'} height={'100%'} src={imgSrc} />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '50%',
            width: '100%',
            height: '100%',
            border: '1px solid #0000001A',
          }}
        />
      </Box>
      <Typography
        variant="paragraph3"
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {text}
      </Typography>

      {(onClick || actionBtn) && (
        <IconButton
          sx={{
            p: '0px',
            borderRadius: '50%',
          }}
          onClick={actionBtn?.onClick}
        >
          <Box
            component={'img'}
            src={actionBtn?.src ?? img_more}
            width={'16px'}
            height={'16px'}
            sx={{ opacity: 1 }}
          />
        </IconButton>
      )}
    </Box>
  )
}
