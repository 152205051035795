import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { Switcher, TextInput } from 'components'
import { useFormikContext } from 'formik'
import { SingleDatePicker } from '../date/components/singleDatePicker'
import { EFieldType } from 'features/text-editors/lexical/plugins-custom/VariablePlugin/helpers/consts'
import checkboxIcon from 'assets/img/checkbox/checkbox.svg'
import { ICheckboxFiledAttrs } from '..'

const DatePickerField = ({ name }) => (
  <Box p="6px 16px">
    <SingleDatePicker name={name} />
  </Box>
)

const TextAreaField = ({ name, label }) => (
  <Box p="6px 16px">
    <TextInput
      name={name}
      label={label}
      type="text"
      fullWidth
      size="small"
      sx={{ '.MuiInputBase-input.MuiOutlinedInput-input': { padding: 0 } }}
      minRows={3}
      multiline
    />
  </Box>
)

const CheckboxField = ({ name, label, checked, onChange }) => (
  <Box p="6px 16px">
    <FormControlLabel
      sx={{ '&.MuiFormControlLabel-root': { margin: 0 } }}
      label={label}
      control={
        <Checkbox
          checkedIcon={<img src={checkboxIcon} alt="" width={16} height={16} />}
          sx={{ mr: 1 }}
          size="small"
          checked={checked}
          value={checked}
          name={name}
          onChange={onChange}
        />
      }
    />
  </Box>
)

const TextField = ({ name, label, type }) => (
  <Box p="6px 16px">
    <TextInput name={name} label={label} type={type} fullWidth />
  </Box>
)

export const FieldRenderer = ({ field }) => {
  const { values, handleChange } = useFormikContext<
    BaseSettings & ICheckboxFiledAttrs
  >()

  if (field.type === 'switch') {
    return (
      <Box m={'2px 8px'} p="4px 8px" key={field.name}>
        <Switcher name={field.name} label={field.label} />
        {field.description && (
          <Typography variant={'body2'} color={'text.disabled'}>
            {field.description}
          </Typography>
        )}
      </Box>
    )
  }

  if (field.dependsOn && values[field.dependsOn]) {
    switch (field.type) {
      case EFieldType.DATE:
        return <DatePickerField name={field.name} />
      case EFieldType.TEXT_AREA:
        return <TextAreaField name={field.name} label={field.label} />
      case EFieldType.CHECKBOX:
        return (
          <CheckboxField
            name={field.name}
            label={values.text}
            checked={values.default_value}
            onChange={handleChange}
          />
        )
      default:
        return (
          <TextField name={field.name} label={field.label} type={field.type} />
        )
    }
  }
  return null
}
