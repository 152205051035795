import { Box, SxProps, Theme, Typography } from '@mui/material'
import { ReactNode } from 'react'

export const BreadcrumbsElement = ({
  prefixIconSrcImg,
  prefixIconSx,
  title,
  onClick,
  suffixChildren,
}: {
  prefixIconSrcImg?: string
  prefixIconSx?: SxProps<Theme>
  title: string
  suffixChildren?: ReactNode
  onClick?: () => void
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'nowrap',
        p: '0 4px',
        borderRadius: '4px',
        gap: '4px',
        minWidth: '0px',
        cursor: !!onClick ? 'pointer' : 'default',

        '&:hover': {
          background: '#F5F5F5',
        },
        '&:focus,&:active': {
          background: '#EDEDEC',
        },
      }}
      onClick={onClick}
    >
      {!!prefixIconSrcImg && (
        <Box
          component={'img'}
          src={prefixIconSrcImg}
          sx={{
            width: '20px',
            height: '20px',
            mr: '4px',
            ...prefixIconSx,
          }}
        />
      )}
      <Typography
        overflow={'hidden'}
        whiteSpace={'nowrap'}
        textOverflow={'ellipsis'}
        minWidth={'0px'}
        fontSize={'14px'}
        lineHeight={'20px'}
      >
        {title}
      </Typography>
      {suffixChildren}
    </Box>
  )
}
