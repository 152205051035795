import { Box, AppBar, Typography, Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import logo_long from 'assets/img/logo-long.svg'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from 'store/slices/userSlice'

export function HeaderWithLogo() {
  const { user, authenticated } = useSelector((state: RootState) => state.user)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleClick = (e) => {
    e.preventDefault()
    dispatch(logoutUser() as any)
    navigate('/')
  }

  return (
    <AppBar
      position="fixed"
      sx={{ boxShadow: 'none', background: { xs: 'white', md: 'transparent' } }}
    >
      <Box
        m="12px 16px"
        height={'24px'}
        display="flex"
        justifyContent={'space-between'}
      >
        <Box sx={{ display: { xs: 'none', sm: 'flex' } }} width={'100%'}>
          <Link to="/documents">
            <img src={logo_long} width="auto" height="24" alt="" />
          </Link>
        </Box>
        {authenticated && (
          <Box
            display={'flex'}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            justifyContent={{ xs: 'space-between', sm: 'flex-end' }}
            width={'100%'}
          >
            <Box
              display={'flex'}
              sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
            >
              <Typography color="text.secondary" mr={'4px'}>
                Вы авторизованы как:
              </Typography>
              <Typography color="text.primary" mr={'4px'}>
                {user.email}
              </Typography>
            </Box>
            <Button size="small" color="secondary" onClick={handleClick}>
              Выйти
            </Button>
          </Box>
        )}
      </Box>
    </AppBar>
  )
}
