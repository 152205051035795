/**
 * Download file from url
 * @param url
 * @param fullFileName - output file name for client (name.ext) 
 */
export const downloadFileFromURL = (url: string, fullFileName: string) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fullFileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}