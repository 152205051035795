import { $getNearestNodeFromDOMNode, LexicalEditor, LexicalNode } from "lexical"
import { isExcludedToDrop } from "./isExcludedToDrop"

/**
 * getTargetNodeToDrop
 * @returns target `LesicalNode` for drop, otherwise `undefined`
 */
export function getTargetNodeToDrop(event: MouseEvent): LexicalNode | null | undefined {
  const targetEl = event.target
  if (!targetEl || !(targetEl instanceof HTMLElement)) return

  let targetNode = $getNearestNodeFromDOMNode(targetEl)
  while (true) {
    if (!targetNode) return
    if (!isExcludedToDrop(targetNode.getType())) break
    targetNode = targetNode.getParent()
  }

  return targetNode
}
