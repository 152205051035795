import { Box, Skeleton, Typography } from '@mui/material'
import { IOSSwitch } from 'components'
import { MouseEventHandler } from 'react'

export const SettingItemWithToggle = ({
  checked,
  title,
  name,
  description,
  onClick,
  disabled,
  loading,
}: {
  title: {
    text: string
    isHeading?: boolean
  }
  name?: string
  description?: string
  checked: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
  disabled?: boolean
  loading?: boolean
}) => {
  return (
    <Box onClick={!disabled && !loading ? onClick : undefined}>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant={title.isHeading ? 'heading6' : 'paragraph3'}>
          {loading ? (
            <Skeleton variant="text" height={'24px'} width={'100px'} />
          ) : (
            title.text
          )}
        </Typography>
        <IOSSwitch
          name={name}
          checked={checked}
          disabled={disabled || loading}
        />
      </Box>
      {description && (
        <Typography variant="paragraph4" color={'text.disabled'} mt={'2px'}>
          {loading ? <Skeleton variant="text" /> : description}
        </Typography>
      )}
    </Box>
  )
}
