import img_more from 'assets/img/more_horiz.svg'

import { IconButtonImage } from 'components/button/IconButtonImage'
import { MenuTemplateActionsFromTemplate } from 'pages/templates/components/MenuTemplateActionsFromTemplate'
import { useState } from 'react'

export const IconButtonTemplateActions = ({
  teamspaceId,
  template,
  disabled,
}: {
  teamspaceId: string
  template?: ITemplate
  disabled?: boolean
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  return (
    <>
      <IconButtonImage
        icon={{
          size: '16px',
          src: img_more,
        }}
        disabled={disabled || !template}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
      {!!template && (
        <MenuTemplateActionsFromTemplate
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
          open={Boolean(anchorEl)}
          template={template}
          teamspaceId={teamspaceId}
        />
      )}
    </>
  )
}
