import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  Box,
  CircularProgress,
  IconButton,
  TableCell,
  TableContainer,
} from '@mui/material'
import { CustomTooltip } from 'components'
import { TableCompound } from 'components/table/table-compound/TableCompound'
import { useSelectedDocsContext } from 'context'
import { EDocumentType, UrlKeys } from 'helper/consts'
import { useCheckbox } from 'hooks/useCheckbox'
import { DocumentActions } from 'pages/documents/components/document-actions'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

export const DocumentsTable = ({
  page,
  documents,
  loading,
  lastRowRef,
}: {
  page: number
  documents: DocumentState[]
  loading: boolean
  lastRowRef?: any
}) => {
  const { tsId } = useParams()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(Boolean(anchorEl))

  const { hiddenDocumentIds, dubls } = useSelectedDocsContext()

  const visibleDocuments = useMemo(() => {
    const res = documents.filter(
      (doc) => !hiddenDocumentIds.find((h) => h == doc.id)
    )
    const dublicates = dubls.dublicates.filter(
      (d) => !!d && !hiddenDocumentIds.find((h) => h == d.id)
    ) as DocumentState[]
    res.push(...dublicates)
    return res
  }, [documents, dubls.dublicates])

  const { checkboxHeader, checkboxTable } = useCheckbox({
    page,
    rows: visibleDocuments,
  })

  useEffect(() => {
    dubls.clearDublicates()
  }, [page])

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getLink = (document: DocumentState) => {
    if (document.type === EDocumentType.EDITOR)
      return `/teamspace/${tsId}/document-editor/${document.id}`
    if (document.type === EDocumentType.TEMPLATE)
      return `/teamspace/${tsId}/documents/${document.id}?${UrlKeys.templateId}=${document.template_id}`
    return `/teamspace/${tsId}/documents/${document.id}`
  }

  return (
    <TableContainer>
      <TableCompound>
        <TableCompound.Head>
          <TableCompound.Row>
            <TableCell padding="checkbox">{checkboxHeader()}</TableCell>
            <TableCompound.HeadCellDocumentName />
            <TableCompound.HeadCellDocumentStatus />
            <TableCompound.HeadCellDocumentAuthor />
            <TableCompound.HeadCellDocumentLastModified />
            <TableCompound.HeadCellDocumentOptions />
          </TableCompound.Row>
        </TableCompound.Head>

        {!loading && (
          <TableCompound.Body>
            {[...dubls.dublicates].reverse().map((row, i) => {
              if (row == undefined)
                return <TableBodyRowLoading key={`loading-dublicate-${i}`} />
              if (row == null) return null
              if (hiddenDocumentIds && hiddenDocumentIds.includes(row.id))
                return null
              return (
                <TableBodyRowDocument
                  key={row.id}
                  doc={row}
                  linkTo={getLink(row)}
                  checkbox={checkboxTable({ row })}
                  handleClickMore={handleClick}
                />
              )
            })}
            {documents.map((row) => {
              if (hiddenDocumentIds && hiddenDocumentIds.includes(row.id))
                return null
              return (
                <TableBodyRowDocument
                  key={row.id}
                  doc={row}
                  linkTo={getLink(row)}
                  checkbox={checkboxTable({ row })}
                  handleClickMore={handleClick}
                />
              )
            })}
            <Box component={'tr'} ref={lastRowRef} />
          </TableCompound.Body>
        )}
        <DocumentActions
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          document={
            anchorEl && visibleDocuments.find((d) => d.id == anchorEl.id)
          }
        />
      </TableCompound>
    </TableContainer>
  )
}

export const TableBodyRowLoading = () => {
  return (
    <TableCompound.RowHoverable>
      <TableCell>
        <CircularProgress color="inherit" />
      </TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
    </TableCompound.RowHoverable>
  )
}

export const TableBodyRowDocument = ({
  doc,
  linkTo,
  checkbox,
  handleClickMore,
}: {
  doc: DocumentState
  linkTo: string
  checkbox: any
  handleClickMore: (e) => void
}) => {
  return (
    <TableCompound.RowHoverable key={doc.id}>
      <TableCell padding="checkbox">{checkbox}</TableCell>
      <TableCompound.BodyCellDocumentName to={linkTo} title={doc.name} />
      <TableCompound.BodyCellDocumentStatus
        to={linkTo}
        status={doc.status}
        isShared={doc.is_shared}
      />
      <TableCompound.BodyCellDocumentAuthorEmail
        to={linkTo}
        email={doc.author_email}
        image={doc.author_avatar_link || ''}
      />
      <TableCompound.BodyCellDocumentLastModified
        to={linkTo}
        lastActionAt={doc.last_action_at}
      />
      <TableCompound.BodyCellDocumentOptions>
        <Box display={'flex'}>
          <CustomTooltip title="Действия">
            <IconButton
              onClick={handleClickMore}
              id={doc.id}
              children={
                <MoreHorizIcon
                  sx={{
                    width: '20px',
                    height: '20px',
                    color: '#3C4149',
                  }}
                />
              }
            />
          </CustomTooltip>
        </Box>
      </TableCompound.BodyCellDocumentOptions>
    </TableCompound.RowHoverable>
  )
}
