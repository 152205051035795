import { api } from 'config'
import { LocalStorageReserve } from 'helper/consts'
import { handleError } from 'service'

export const getUsersTokens = async () => {
  try {
    const res = await api.get(`/users/tokens`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return res.data
  } catch (e) {
    return handleError(e)
  }
}
