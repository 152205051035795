import { Typography, Box, Button } from '@mui/material'
import { EWorkspacePlan } from 'helper/plan.types'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const formatDate = (date?: string) => {
  if (!date) return ''
  return new Date(date)
    .toLocaleString('ru-RU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
    .replace(' г.', '')
}

const PlanPeriodWithName = ({ name, start_at, end_at }) => {
  const getPlanName = (plan_name: string) => {
    switch (plan_name) {
      case EWorkspacePlan.Free:
        return 'Бесплатный'
      case EWorkspacePlan.Trial:
        return 'Бесплатный пробный период'
      default:
        return plan_name
    }
  }

  const getPeriodText = (startDate: string, endDate: string) => {
    const start = formatDate(startDate),
      end = formatDate(endDate)
    if (start && end) return `${start} – ${end}`
    if (start) return `С ${start} до неопределенного времени`
    if (end) return `До ${end}`
    return ''
  }

  return (
    <Box>
      <Typography variant="heading5">{getPlanName(name)}</Typography>
      {name !== EWorkspacePlan.Free && (
        <Box mt={'8px'}>
          <Typography variant="paragraph3" color={'#90959D'}>
            Период тарифа:
          </Typography>
          <Typography>{getPeriodText(start_at, end_at)}</Typography>
        </Box>
      )}
    </Box>
  )
}

export const SubscriptionPlanBanner = () => {
  const { plan_name, plan_info } = useSelector(
    (state: RootState) => state.wsPlan,
    shallowEqual
  )
  const navigate = useNavigate()

  return (
    <Box>
      <Typography variant="h6">Текущий тариф</Typography>
      <Box
        display={'flex'}
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          background: '#F7F7F7',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06);',
          p: '16px',
          borderRadius: '8px',
          alignItems: { xs: 'flex-start' },
        }}
        mt="8px"
      >
        {plan_name == EWorkspacePlan.Free && !!plan_info.previous_plan ? (
          <PlanPeriodWithName
            name={plan_info.previous_plan.name}
            start_at={plan_info.previous_plan.start_at}
            end_at={plan_info.previous_plan.end_at}
          />
        ) : (
          <PlanPeriodWithName
            name={plan_info.plan_limits.name}
            start_at={plan_info.workspace_limits?.start_at ?? ''}
            end_at={plan_info.workspace_limits?.end_at ?? ''}
          />
        )}
        <Box
          display={'flex'}
          flexDirection={{ xs: 'row-reverse', sm: 'row' }}
          mt={{ xs: 1, sm: 0 }}
        >
          {plan_name === EWorkspacePlan.Trial ||
          plan_name === EWorkspacePlan.Free ? (
            <Button
              color="primary"
              variant="contained"
              onClick={() => navigate('/plan')}
              size="small"
              sx={{
                ml: { xs: 0, sm: 1 },
                mr: { xs: 1, sm: 0 },
              }}
            >
              Выбрать тариф
            </Button>
          ) : (
            <Button
              onClick={() => navigate('/plan')}
              size="small"
              variant="outlined"
              color="secondary"
            >
              Посмотреть тарифы
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}
