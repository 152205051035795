import img_add from 'assets/img/add.svg'

import {
  Box,
  Button,
  Divider,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { TextInput } from 'components'
import { ModalPlanLimit } from 'components/modal/ModalPlanLimit'
import { useFormikContext } from 'formik'
import { EDocumentStatus } from 'helper/consts'
import { EFeature } from 'helper/plan.types'
import { useContext, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateCampaign } from 'store/slices/campaign'
import { FullwidthContext } from '../createCampaign'

export const Begin = () => {
  const fullwidth = useContext(FullwidthContext)

  const { setFieldValue, values, errors, dirty } = useFormikContext<{
    id: string
    campaign_name: string
  }>()

  const { private_templates } = useSelector(
    (state: RootState) => state.templates,
    shallowEqual
  )

  const { campaign_name, id } = useSelector(
    (state: RootState) => state.campaign,
    shallowEqual
  )

  const dispatch = useDispatch()

  useEffect(() => {
    campaign_name && setFieldValue('campaign_name', campaign_name)
    id && setFieldValue('id', id)
  }, [dispatch])

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'id') {
      const newName =
        private_templates.find((item) => item.id === value)?.name +
        ' ' +
        ('0' + new Date().getDate()).slice(-2) +
        '.' +
        (new Date().getMonth() + 1)
      setFieldValue('id', value)
      setFieldValue('campaign_name', newName)
      dispatch(
        updateCampaign({
          id: value,
          campaign_name: newName,
          current_step: 0,
        }) as any
      )
    } else {
      setFieldValue('campaign_name', value)
      dispatch(
        updateCampaign({
          campaign_name: value,
          current_step: 0,
        }) as any
      )
    }
  }

  // const navigate = useNavigate()

  const [openModalLimit, setOpenModalLimit] = useState(false)

  const handleClickFile = () => {
    // TODO: navigate
    // inputRef?.current?.click()
  }

  return (
    <>
      <Box mt={'8px'}>
        <Box p={'8px 16px'}>
          <Typography color="text.secondary" variant="h6" mb="2px">
            Шаблон документа
          </Typography>
          <Select
            MenuProps={{
              PaperProps: {
                style: {
                  maxWidth: '100%',
                  width: '684px',
                  maxHeight: '100%',
                  marginTop: '2px',
                },
              },
            }}
            placeholder={'Выберите шаблон'}
            name={'id'}
            value={values.id}
            onChange={handleChange}
            error={errors.id ? true : false}
            fullWidth
            displayEmpty
            renderValue={
              values.id !== ''
                ? undefined
                : () => (
                    <Box sx={{ display: 'flex', color: 'text.disabled' }}>
                      Выберите шаблон
                    </Box>
                  )
            }
          >
            {private_templates.map(
              (item: ITemplate) =>
                item.status !== EDocumentStatus.PREPARING && (
                  <MenuItem
                    value={item.id}
                    key={item.id}
                    sx={{ margin: '2px 12px' }}
                  >
                    {item.name}
                  </MenuItem>
                )
            )}
            <Divider sx={{ margin: '2px 12px' }} />
            <Button
              fullWidth
              color="secondary"
              sx={{
                color: '#585757',
                justifyContent: 'start',
                margin: '2px 12px',
                padding: '4px 8px',
              }}
              onClick={handleClickFile}
            >
              <>
                <img
                  src={img_add}
                  alt=""
                  style={{ marginRight: '12px', width: '24px' }}
                />
                Новый шаблон
              </>
            </Button>
          </Select>
          <FormHelperText sx={{ color: 'text.error' }}>
            {errors.id}
          </FormHelperText>
          <Box mt={'16px'}>
            <TextInput
              size="small"
              fullWidth
              placeholder="Введите название рассылки.."
              name="campaign_name"
              label={'Название рассылки'}
            ></TextInput>
          </Box>
        </Box>

        <Box
          p={'8px 16px 16px'}
          mt={'12px'}
          display={'flex'}
          justifyContent={'flex-end'}
          width={'100%'}
          sx={{
            position: fullwidth ? 'absolute' : '',
            right: 0,
            bottom: 0,
          }}
        >
          <Button disabled={!dirty} variant={'contained'} type="submit">
            Продолжить
          </Button>
        </Box>
      </Box>
      <ModalPlanLimit
        limitOn={EFeature.template_creation_count}
        handleClose={() => {
          setOpenModalLimit(false)
        }}
        open={openModalLimit}
      />
    </>
  )
}
