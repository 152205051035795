import './index.css'

import {
  COMMAND_PRIORITY_HIGH,
  DOMConversionMap,
  DOMConversionOutput,
  ElementNode,
  LexicalNode,
  NodeKey,
  SerializedElementNode,
  Spread,
} from 'lexical'

const columnListItemNodeType = 'columns-list-item'

export type SerializedColumnsListItemNode = Spread<
  {
    widthPrecent: number
  },
  SerializedElementNode
>

export class ColumnsListItemNode extends ElementNode {
  __widthPrecent: number

  constructor(widthPrecent: number, key?: NodeKey) {
    super(key)
    this.__widthPrecent = widthPrecent
  }

  static getType(): string {
    return columnListItemNodeType
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('div')
    dom.setAttribute('lexical-type', columnListItemNodeType)
    dom.setAttribute('lexical-width-precent', this.__widthPrecent.toString())
    dom.style.width = `calc(${this.__widthPrecent / 100} * 100%)`

    return dom
  }

  updateDOM(prevNode: ColumnsListItemNode): boolean {
    return prevNode.__widthPrecent !== this.__widthPrecent
  }

  updateWidthPrecent(widthPrecent: number): void {
    const self = this.getWritable()
    self.__widthPrecent = widthPrecent
  }

  static clone(node: ColumnsListItemNode): ColumnsListItemNode {
    return new ColumnsListItemNode(node.__widthPrecent, node.__key)
  }

  static importDOM(): DOMConversionMap | null {
    return {
      div: (domNode: HTMLElement) => {
        const tp = domNode.getAttribute('lexical-type')
        if (tp !== columnListItemNodeType) return null

        return {
          conversion: convertColumnListItemNode,
          priority: COMMAND_PRIORITY_HIGH,
        }
      },
    }
  }

  static importJSON(json: SerializedColumnsListItemNode): ColumnsListItemNode {
    return $createColumnsListItemNode(json.widthPrecent)
  }

  exportJSON(): SerializedColumnsListItemNode {
    return {
      ...super.exportJSON(),
      widthPrecent: this.__widthPrecent,
      type: columnListItemNodeType,
      version: 1,
    }
  }

  canBeEmpty(): boolean {
    return false
  }
}

function convertColumnListItemNode(domNode: HTMLElement): DOMConversionOutput {
  const widthPrecent = Number(domNode.getAttribute('lexical-width-precent'))
  return { node: $createColumnsListItemNode(widthPrecent) }
}

export function $createColumnsListItemNode(
  widthPrecent: number
): ColumnsListItemNode {
  return new ColumnsListItemNode(widthPrecent)
}

export function $isColumnsListItemNode(
  node: LexicalNode | null | undefined
): node is ColumnsListItemNode {
  return node instanceof ColumnsListItemNode
}
