import { Container, Typography, Grid } from '@mui/material'
import { containerCenter, gridCenter } from './style'

interface IState {
  message: string
  description: string
  style?: any
}

export const Error404 = ({ message, description, style }: IState) => {
  return (
    <Container
      sx={{ ...containerCenter, bgcolor: '#0085FF', color: 'white', ...style }}
      maxWidth={false}
    >
      <Grid container sx={gridCenter}>
        <Grid item xs={12}>
          <Typography variant="h2">☹️</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2">{message}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h2" sx={{ opacity: '0.5' }}>
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}
