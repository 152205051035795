import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {
  IDropdownOption,
  IDropdownSettings,
} from 'features/text-editors/lexical/plugins-custom/VariablePlugin/components/variable-form-fields/dropdown'
import {
  IInputTextSettings,
  INumberFiledSettings,
  ITextAreaSettings,
} from 'features/text-editors/lexical/plugins-custom/VariablePlugin/components/variable-form-fields'
import { IDateFieldAttrs } from 'features/text-editors/lexical/plugins-custom/VariablePlugin/components/variable-form-fields/date'
import { v4 as uuidv4 } from 'uuid'
import { ESystemVariableType } from 'features/text-editors/lexical/plugins-custom/ParameterPickerPlugin/helpers'
import { ICheckboxFiledAttrs } from 'features/text-editors/lexical/plugins-custom/VariablePlugin/components/variable-form-fields/checkbox'

dayjs.extend(customParseFormat)

export const findIdInOptionsArray = (
  options: IDropdownOption[],
  text: string
): IDropdownOption | undefined => {
  return options.find((optionObj) => optionObj.attrs.text === text)
}

const createBaseVariable = (item: BaseSettings) => ({
  id: item.id,
  type: item.type,
  name: item.name,
  is_required: item.is_required,
  actor_id: item.actor_id,
  has_hint: item.has_hint,
  hint: item.hint,
  has_default: item.has_default,
})

export const transformObjectDropdown = (
  item: BaseSettings & IDropdownSettings
) => {
  const options = item.options.map((option, i) => ({
    id: uuidv4(),
    type: ESystemVariableType.DROPDOWN_OPTION,
    group_id: item.id,
    group_type: ESystemVariableType.DROPDOWN,
    attrs: { index: i, text: option.text },
  }))

  const defaultOption = item.default_value?.length
    ? findIdInOptionsArray(options, item.default_value[0])
    : undefined

  const dropdown = {
    ...createBaseVariable(item),
    group_id: item.group_id,
    attrs: {
      multiple_choice: item.multiple_choice,
      default_value: [defaultOption?.id ?? ''],
    },
  }

  return [dropdown, ...options]
}

export const transformObjectInput = (
  item: BaseSettings & IInputTextSettings
) => {
  return [
    {
      ...createBaseVariable(item),
      type: ESystemVariableType.INPUT_TEXT,
      attrs: {
        has_max: item.has_max,
        max: item.max,
        has_min: item.has_min,
        min: item.min,
        numeric: item.numeric,
        alpha: item.alpha,
        default_value: item.default_value,
      },
    },
  ]
}

export const transformObjectTextArea = (
  item: BaseSettings & ITextAreaSettings
) => {
  return [
    {
      ...createBaseVariable(item),
      type: ESystemVariableType.INPUT_TEXT,
      attrs: {
        has_max: item.has_max,
        max: item.max,
        has_min: item.has_min,
        min: item.min,
        default_value: item.default_value,
      },
    },
  ]
}

export const transformObjectCheckbox = (
  item: BaseSettings & ICheckboxFiledAttrs
) => {
  return [
    {
      ...createBaseVariable(item),
      attrs: {
        text: item.text,
        default_value: item.default_value ? true : false,
      },
    },
  ]
}

export const transformObjectInputNumber = (
  item: BaseSettings & INumberFiledSettings
) => {
  return [
    {
      ...createBaseVariable(item),
      attrs: {
        has_max: item.has_max,
        max: item.max,
        has_min: item.has_min,
        min: item.min,
        default_value: parseInt(item.default_value ?? '0'),
      },
    },
  ]
}

export const transformObjectInputDate = (
  item: BaseSettings & IDateFieldAttrs
) => {
  const { date_range, before, after, specific_dates, default_value } = item

  const formatRange = (range) => range ?? ''

  const attrs = {
    format: item.format,
    has_before: item.has_before || item.has_date_range,
    before: formatRange(item.has_date_range ? date_range[1] : before),
    has_after: item.has_after || item.has_date_range,
    after: formatRange(item.has_date_range ? date_range[0] : after),
    has_specific_dates: item.has_specific_dates,
    specific_dates: item.has_specific_dates ? specific_dates : [],
    default_value: formatRange(default_value),
  }

  return [{ ...createBaseVariable(item), attrs }]
}
