import { TARGET_LINE_DEFAULT_WIDTH } from "../private.conts"

/**
 * setTargetLine - show target line
 */
export function setTargetLine(targetLineElem: HTMLElement, props: {
  width?: number,
  height?: number,
  top: number,
  left: number
}) {
  targetLineElem.style.transform = `translate(${props.left}px, ${props.top}px)`
  targetLineElem.style.height = `${props.height ?? TARGET_LINE_DEFAULT_WIDTH}px`
  targetLineElem.style.width = `${props.width ?? TARGET_LINE_DEFAULT_WIDTH}px`
  targetLineElem.style.opacity = '.4'
}