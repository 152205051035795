import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $createNodeSelection,
  $createTextNode,
  $getSelection,
  $insertNodes,
  $setSelection,
  COMMAND_PRIORITY_LOW,
  COPY_COMMAND,
  createCommand,
  LexicalCommand,
  LexicalEditor,
  LexicalNode,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'
import { useEffect } from 'react'
import {
  $createVariableNode,
  $isVariableNode,
  VariableNode,
} from '../../nodes/VariableNode'

type CommandPayload = string

type UpdateVariablePayload = {
  id: string
  name: string
  additionalProps: any
}
type CopyVariablePayload = {
  node: VariableNode
}

type DeleteVariablePayload = {
  node: VariableNode
}

export const INSERT_VARIABLE_COMMAND: LexicalCommand<CommandPayload> =
  createCommand('INSERT_VARIABLE_COMMAND')

export const UPDATE_VARIABLE_NODE: LexicalCommand<UpdateVariablePayload> =
  createCommand('UPDATE_VARIABLE_NODE')

export const COPY_VARIABLE_NODE: LexicalCommand<CopyVariablePayload> =
  createCommand('COPY_VARIABLE_NODE')

export const DELETE_VARIABLE_NODE: LexicalCommand<DeleteVariablePayload> =
  createCommand('DELETE_VARIABLE_NODE')

export default function VariablePlugin(): JSX.Element {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([VariableNode]))
      throw new Error(
        'VariablePlugin: VariableNode is not registered on editor'
      )

    const removeListener = editor.registerCommand(
      INSERT_VARIABLE_COMMAND,
      ({ props = {} }: { props: any }) => {
        editor.update(() => {
          const insertedNode = $createVariableNode({
            name: props.name,
            id: props.id,
            props,
          })

          $insertNodes([insertedNode])
          $insertNodes([$createTextNode(' ')])

          const prevSibling = insertedNode.getPreviousSibling()

          if (
            prevSibling &&
            prevSibling?.getType() === 'text' &&
            prevSibling.getTextContent().endsWith('=')
          ) {
            const text = prevSibling.getTextContent().slice(0, -1)
            prevSibling.setTextContent(text)
          }
        })
        return true
      },
      0
    )

    const removeUpdateListener = editor.registerCommand(
      UPDATE_VARIABLE_NODE,
      ({ id, name, additionalProps }: UpdateVariablePayload) => {
        editor.registerNodeTransform(VariableNode, (node) => {
          if (node.__id === id) {
            node.setName(name)
            node.setAdditionalProps(additionalProps)
          }
        })
        return true
      },
      0
    )

    const removeCopyListener = editor.registerCommand(
      COPY_VARIABLE_NODE,
      ({ node }: CopyVariablePayload) => {
        const selection = $createNodeSelection()
        selection.add(node.getKey())
        $setSelection(selection)
        editor.dispatchCommand(COPY_COMMAND, null)
        return true
      },
      0
    )

    const removeDeleteListener = editor.registerCommand(
      DELETE_VARIABLE_NODE,
      ({ node }: DeleteVariablePayload) => {
        node.remove()
        return true
      },
      0
    )

    let prevVariableNodes: VariableNode[] = []
    const removeChangeSelectionListener = editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        acitonWithVariableNodeElements(prevVariableNodes, editor, (el) => {
          el.style.outline = 'none'
        })

        const selection = $getSelection()
        if (!selection) {
          prevVariableNodes = []
          return true
        }

        const nodes = selection.getNodes()

        const variableNodes: VariableNode[] = []
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i]
          if (!$isVariableNode(node)) continue
          variableNodes.push(node)
        }

        acitonWithVariableNodeElements(variableNodes, editor, (el) => {
          el.style.outline = '1px solid var(--editor-color-primary, blue)'
        })

        prevVariableNodes = variableNodes

        return false
      },
      COMMAND_PRIORITY_LOW
    )

    return () => {
      removeListener()
      removeUpdateListener()
      removeCopyListener()
      removeDeleteListener()
      removeChangeSelectionListener()
    }
  }, [editor])

  return null as any
}

const acitonWithVariableNodeElements = (
  nodes: VariableNode[],
  editor: LexicalEditor,
  action: (el: HTMLElement) => void
): boolean => {
  if (nodes.length === 0) return false

  let hasChanged = false
  for (let i = 0; i < nodes.length; i++) {
    const selectedEl = editor.getElementByKey(nodes[i].getKey())
    if (!selectedEl) continue

    action(selectedEl)
    hasChanged = true
  }

  return hasChanged
}
