import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { TextInput } from 'components'
import { MenuItemButtonWithToggle } from 'components/menu-item/menu-item-button/MenuItemButtonWithToggle'
import { IRecipientAttrMessage } from 'helper/workflow/recipients/types'

export const SettingMessage = () => {
  const formikCtx = useFormikContext<IRecipientAttrMessage>()
  const [checked, setChecked] = useState(
    formikCtx.values.message === '' ? false : true
  )

  useEffect(() => {
    return () => {
      formikCtx.handleSubmit()
    }
  }, [])

  return (
    <>
      <MenuItemButtonWithToggle
        title={'Персональное сообщение'}
        checked={checked}
        onClick={() => setChecked(!checked)}
      />
      {checked && (
        <Box
          p={'4px 12px'}
          m={'2px 4px'}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TextInput
            size="small"
            InputProps={{
              sx: {
                p: 0,
              },
            }}
            multiline
            rows={3}
            fullWidth
            value={formikCtx.values.message}
            onChange={formikCtx.handleChange}
            placeholder="Введите текст который увидит только этот получатель..."
            name="message"
          />
        </Box>
      )}
    </>
  )
}
