import { Container } from '@mui/material'
import { Loading, MessageSnackbar } from 'components'
import { BottomCenterSnackbar } from 'components/bottom-center-snackbar'
import { useCallback, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { removeDraftMsgCampaign, updateCampaign } from 'store/slices/campaign'
import { EmptyBulkPage } from 'features/bulkSend/emptyBulk'
import { CampaignsTable } from './table'
import { withDashboardLayout } from 'layout'
import { Header } from './header'
import { Footer } from './footer'
import { useChangePageContext } from 'context'
import { CampaignsChangePageProvider } from 'context'
import { useParams } from 'react-router-dom'

const CampaignsContent = () => {
  const { loading, count, page, is_draft_created, error } = useSelector(
    (state: RootState) => state.campaign,
    shallowEqual
  )
  const { changePage } = useChangePageContext()
  const dispatch = useDispatch()
  const { tsId } = useParams()

  useEffect(() => {
    changePage({ page, params: {} })
  }, [page, tsId])

  const handleError = useCallback(
    () => dispatch(updateCampaign({ error: '' }) as any),
    [dispatch]
  )

  const renderContent = () => {
    if (loading) return <Loading />
    if (count > 0) {
      return <CampaignsTable />
    }
    return <EmptyBulkPage />
  }

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          overflow: 'auto',
          pr: {
            xs: '20px',
          },
          pl: { xs: '20px' },
          position: 'relative',
          height: '100%',
        }}
      >
        <MessageSnackbar
          message={error}
          clearMessage={handleError}
          severity={'error'}
        />
        <MessageSnackbar
          message={error}
          clearMessage={handleError}
          severity={'error'}
        />
        <BottomCenterSnackbar
          title={'Мы сохранили вашу рассылку как «Черновик»'}
          open={is_draft_created}
          handleClose={() => dispatch(removeDraftMsgCampaign() as any)}
        />
        {renderContent()}
      </Container>
    </>
  )
}

export const Campaigns = () =>
  withDashboardLayout(CampaignsContent)({
    Header: Header,
    Footer: Footer,
    providers: [CampaignsChangePageProvider],
  })
