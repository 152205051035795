import { Dispatch, SetStateAction, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { unpublishTemplate } from 'service/template'

export const useActionTemplateEdit = () => {
  const navigate = useNavigate()

  const run = useCallback(
    async ({
      templateId,
      teamspaceId,
      setError,
    }: {
      templateId: string
      teamspaceId: string
      setError?: Dispatch<SetStateAction<string>>
    }) => {
      try {
        const resp = await unpublishTemplate({ templateId })
        navigate(`/teamspace/${teamspaceId}/templates/${templateId}`)
        return resp
      } catch (err: any) {
        console.error(err)
        if (setError) setError(err.message)
      }
    },
    [navigate]
  )

  return { run }
}
