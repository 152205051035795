import { UrlKeys } from 'helper/consts'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { clearData, fetchTemplateInfo } from 'store/slices/templateSlice'
import { ManageTemplateContent } from './components/ManageTemplateContent'
import { withDashboardLayout } from 'layout'
import { ManageTemplateHeader } from './components/ManageTemplateHeader'
import { manageTemplateDataSlice } from 'store/slices/manageTemplateData'
import { manageTemplateSettingsSlice } from 'store/slices/manageTemplateSettings'

const ManageTemplate = () => {
  const { urlId: urlTemplateId } = useUrlIdParam(UrlKeys.templateId)

  const dispatch = useDispatch<any>()

  useEffect(() => {
    dispatch(fetchTemplateInfo({ templateId: urlTemplateId.get() }))
    return () => {
      dispatch(clearData())
      dispatch(manageTemplateDataSlice.actions.resetState())
      dispatch(manageTemplateSettingsSlice.actions.resetState())
    }
  }, [urlTemplateId.get()])

  return <ManageTemplateContent />
}

export const ManageTemplatePage = () =>
  withDashboardLayout(ManageTemplate)({
    Header: ManageTemplateHeader,
  })
