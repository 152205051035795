import { api } from 'config'
import { handleError } from 'service'
import { IPayment } from 'store/slices/wsPlan'

interface IDownloadPaymentInfoiceProps {
  workspace_id: string
  data: {
    require_closing_documents: boolean

    total_months: number
    subscription_plan_name: string

    requisites?: {
      company_name: string
      bin: string
      legal_address: string
    }
  }
}

export const planApi = {
  postPaymentInvoiceDownloadLink: async ({
    workspace_id,
    data,
  }: IDownloadPaymentInfoiceProps) => {
    try {
      const resp = await api.post<{
        document_url: string
      }>(`/workspaces/${workspace_id}/subscriptions/purchases/invoice`, data, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        timeout: 10_000,
      })
      return { url: resp.data.document_url }
    } catch (error) {
      return handleError(error)
    }
  },

  postPayWithCardLink: async ({
    workspace_id,
    data,
  }: IDownloadPaymentInfoiceProps) => {
    try {
      const resp = await api.post<{
        payment_url: string
      }>(`/workspaces/${workspace_id}/subscriptions/purchases/card`, data, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        timeout: 10_000,
      })

      return resp
    } catch (error) {
      return handleError(error)
    }
  },

  patchChangePaymentRequisits: async ({
    workspace_id,
    data,
  }: {
    workspace_id: string
    data: {
      require_closing_documents: boolean
      requisites: {
        company_name: string
        bin: string
        legal_address: string
      }
    }
  }) => {
    try {
      const resp = await api.patch(
        `/workspaces/${workspace_id}/subscriptions/payments/requisites`,
        data,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          timeout: 10_000,
        }
      )

      return resp
    } catch (error) {
      return handleError(error)
    }
  },

  getPaymentCardChangeLink: async ({
    workspace_id,
  }: {
    workspace_id: string
  }) => {
    try {
      const resp = await api.put<{
        redirect_url: string
      }>(`/workspaces/${workspace_id}/subscriptions/payments/card`, {}, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        timeout: 10_000,
      })

      return resp
    } catch (error) {
      return handleError(error)
    }
  },

  getSubscriptionsPayments: async ({
    workspace_id,
  }: {
    workspace_id: string
  }) => {
    try {
      const resp = await api.get<{
        payments: IPayment[]
      }>(`/workspaces/${workspace_id}/subscriptions/payments`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        timeout: 10_000,
      })

      return resp
    } catch (error) {
      return handleError(error)
    }
  },

  getPaymentInvoiceToProceedSubscriptionPlan: async ({
    workspace_id,
    payment_id
  }: {
    workspace_id: string
    payment_id: string
  }) => {
    try {
      const resp = await api.get(`/workspaces/${workspace_id}/subscriptions/payments/${payment_id}/invoice`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        timeout: 10_000,
        responseType: 'blob'
      })
      return { url: window.URL.createObjectURL(resp.data), filename: 'Счет на оплату.pdf' }
    } catch (error) {
      return handleError(error)
    }
  }
}
