import { TextField, InputAdornment, Box } from '@mui/material'
import { useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import debounce from 'utils/debounce'
import search_img from 'assets/img/search.svg'
import { EDocumentStatus } from 'helper/consts'
import { useSearchBarContext } from 'context'
import { useSearchParams } from 'react-router-dom'
import { EListQueryParam } from 'helper/queryParams'

export const SearchField = ({ filter }: { filter: EDocumentStatus }) => {
  const { setQueryString, placeholder, queryString } = useSearchBarContext()

  const [searchParams, setSearchParams] = useSearchParams()

  const { completed } = useSelector(
    (state: RootState) => state.inbox,
    shallowEqual
  )

  const handleChange = (e) => {
    const { value } = e.target
    if (value.length < 3 && value.length > 0) return
    setQueryString(value)

    searchParams.set(EListQueryParam.Query, value)
    setSearchParams(searchParams)
  }

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 300, null)
  }, [filter, completed, searchParams])

  return (
    <TextField
      defaultValue={queryString}
      variant="outlined"
      placeholder={placeholder ?? 'Поиск по названию документа...'}
      name={'search'}
      onChange={debouncedResults}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ mr: '-6px' }}>
            <Box
              component={'img'}
              src={search_img}
              width={'20px'}
              height={'20px'}
            />
          </InputAdornment>
        ),
        sx: {
          minWidth: '268px',
          pl: '6px',
        },
      }}
    />
  )
}
