import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { AppBarSettings } from 'components/header/app-bar-common'

export const TSSettingsHeader = () => {
  return (
    <AppBarSettings
      headerTitle={
        <Box display={'flex'}>
          <Typography variant="subtitle2">Основное</Typography>
        </Box>
      }
    />
  )
}
