import { Grid, Typography, Link } from '@mui/material'

export const TermsAndPrivacyLinks = () => {
  return (
    <Grid item xs={12}>
      <Typography variant="body1" color="text.disabled" mt={'32px'}>
        Нажимая «Продолжить с Google/email» выше, вы подтверждаете, что
        прочитали и соглашаетесь с
        <Link
          color={'text.disabled'}
          href={'https://doodocs.kz/terms-of-service'}
          target={'_blank'}
        >
          {' '}
          Условиями использования
        </Link>{' '}
        и{' '}
        <Link
          color={'text.disabled'}
          href={'https://doodocs.kz/terms-of-service'}
          target={'_blank'}
        >
          Политикой конфиденциальности
        </Link>{' '}
        Doodocs.kz.
      </Typography>
    </Grid>
  )
}
