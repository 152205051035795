import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Loading } from 'components'
import { Error404 } from '../errors/error404'
import { RegistryTable } from './registry/list/table'
import { Container } from '@mui/material'
import { withDashboardLayout } from 'layout'
import { Header } from './header'
import { Footer } from './footer'
import { SelectedDocsIdsProvider, useChangePageContext } from 'context'
import { CampaignDocumentsChangePageProvider } from 'context'
import { updateCampaignInfo } from 'store/slices/campaignDocuments'

export const CampaignDocumentsContent = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { tsId } = useParams()
  const { changePage } = useChangePageContext()

  const { page, filter, loading, error }: ICamapignDocsTableState = useSelector(
    (state: RootState) => state.campaignDocs,
    shallowEqual
  )

  const { selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )

  useEffect(() => {
    id && dispatch(updateCampaignInfo({ id }) as any)
    selectedTeamspace?.id === tsId && changePage({ page, params: { filter } })
  }, [id, selectedTeamspace?.id])

  if (loading.info) return <Loading />
  if (error.registry)
    return <Error404 message={error.registry} description={''} />
  return (
    <Container
      maxWidth={false}
      sx={{
        overflow: 'auto',
        pr: {
          xs: '20px',
        },
        pl: { xs: '20px' },
        position: 'relative',
        height: '100%',
      }}
    >
      {loading.registry ? <Loading /> : <RegistryTable />}
    </Container>
  )
}

export const CampaignDocuments = () =>
  withDashboardLayout(CampaignDocumentsContent)({
    Header: Header,
    Footer: Footer,
    providers: [SelectedDocsIdsProvider, CampaignDocumentsChangePageProvider],
  })
