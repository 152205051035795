import { useFormikContext } from 'formik'
import { FormikDatePicker } from '../datePicker'
import { formatDefaultDate } from 'utils/formatDate'
import dayjs from 'dayjs'

export const SingleDatePicker = ({ name }: { name: string }) => {
  const { values, setFieldValue } = useFormikContext<{ date_range: any[] }>()

  const handleDateChange = (date: string | Date) => {
    setFieldValue(name, formatDefaultDate(date))
  }

  return (
    <FormikDatePicker
      name={name}
      format={values['format']}
      value={dayjs(values[name]).toDate()}
      onChange={handleDateChange}
    />
  )
}
