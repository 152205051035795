import { Box, Button } from '@mui/material'
import { FormikTextField } from 'components/using-formik-context/FormikTextField'
import { FormikProvider, useFormik } from 'formik'
import { useContext, useEffect } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { updateWorkspace } from 'store/slices/workspace'
import { useAppSelector } from 'store/store'
import * as Yup from 'yup'
import { WSPreviewContext } from '../..'

interface IFormikFieldWorkspaceName {
  ws_name: string
}

export const FormWorkspaceName = () => {
  const { loading } = useAppSelector((state) => state.workspace, shallowEqual)
  const { selectedWorkspace } = useAppSelector(
    (state) => state.selectedWorkspace,
    shallowEqual
  )

  const { setOrgName, setOrgDescription } = useContext(WSPreviewContext)

  const validationSchema = Yup.object<IFormikFieldWorkspaceName, {}>().shape({
    ws_name: Yup.string()
      .required('Обязательное поле')
      .max(50, 'Максимум 50 символов'),
  })

  const handleSubmit = async (values: IFormikFieldWorkspaceName) => {
    await dispatch(
      updateWorkspace({
        wsId: selectedWorkspace?.id || '',
        workspace: {
          ...selectedWorkspace,
          name: values.ws_name,
        },
      }) as any
    )
  }

  const formik = useFormik<IFormikFieldWorkspaceName>({
    initialValues: {
      ws_name: selectedWorkspace?.name ?? '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })

  const dispatch = useDispatch()

  const { tsId } = useParams()

  useEffect(() => {
    formik.setValues({
      ws_name: selectedWorkspace?.name ?? '',
    })

    setOrgDescription(selectedWorkspace?.public_description || '')
    setOrgName(selectedWorkspace?.public_name || '')
  }, [tsId, selectedWorkspace])

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <FormikTextField
            name="ws_name"
            label="Название пространства"
            placeholder="Название пространства..."
            autoFocus
          />
        </Box>

        <Box p={'24px 0'}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!formik.dirty || loading.update}
          >
            Сохранить
          </Button>
        </Box>
      </form>
    </FormikProvider>
  )
}
