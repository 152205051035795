import img_delete from 'assets/img/menu-actions/delete_red.svg'
import img_more from 'assets/img/more_horiz.svg'

import { Box, Menu } from '@mui/material'
import { IconButtonImage } from 'components/button/IconButtonImage'
import { MenuItemButton } from 'components/menu-item/menu-item-button'
import { useState } from 'react'

export const RowActionsButton = ({
  handleDeleteIntegration,
  loading,
}: {
  handleDeleteIntegration: () => void
  loading?: boolean
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box p={'4px 8px'} display={'flex'} alignContent={'flex-start'}>
      <IconButtonImage
        icon={{
          src: img_more,
          size: '20px',
        }}
        disabled={loading}
        onClick={handleClick}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItemButton
          srcImage={img_delete}
          title="Удалить"
          onClick={handleDeleteIntegration}
        />
      </Menu>
    </Box>
  )
}
