import { ERecipientRole } from "helper/consts"
import { ERecipientAttr } from "./types"
import { IRecipient } from "./IRecipient"
import { IStep } from "../steps/IStep"
import { EWorkflowMode } from "../CWorkflow"
import { RecipientHelperNS } from "./RecipientHelperNS"
import { getExportableObject } from "utils/getExportableObject"
import { getStructClone } from "utils/getStructClone"

export abstract class CAbstractRecipient implements IRecipient {
  abstract actor_id?: number
  abstract role: ERecipientRole
  abstract attrs?: unknown
  _visible_step_number = -1

  export() {
    return getStructClone(getExportableObject(this))
  }

  attach(steps: IStep[], mode: EWorkflowMode): boolean {
    if (mode === EWorkflowMode.default)
      return this.attachOnModeDefault(steps)
    else if (mode === EWorkflowMode.ordered)
      return this.attachOnModeOrdered(steps)
    return false
  }

  abstract attachOnModeDefault(steps: IStep[]): boolean
  abstract attachOnModeOrdered(steps: IStep[]): boolean

  detach(steps: IStep[]): boolean {
    return RecipientHelperNS.RemoveRecipientFromSteps(steps, this)
  }

  abstract isSame(recipient: IRecipient): boolean

  mayContainAttr(_: ERecipientAttr): boolean {
    return false
  }
}