import { Box, Typography, Button } from '@mui/material'
import { TextInput } from 'components'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { Formik } from 'formik'
import { useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { deleteTs } from 'store/slices/teamspaceSlice'
import * as Yup from 'yup'

export const DeleteGroup = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const { tsId } = useParams()
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const { loading } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )
  const handleClickDelete = () => {
    dispatch(
      deleteTs({
        tsId,
        callback: () => navigate('/settings'),
        wsId: selectedWorkspace?.id || '',
      }) as any
    )
  }

  const validationSchema = Yup.object().shape({
    ts_name: Yup.string()
      .required('Обязательное поле')
      .test(
        'name-match',
        'Значение должно совпадать с названием группы',
        function (value) {
          const params = new URLSearchParams(location.search)
          const name = params.get('name')
          return value === name
        }
      ),
  })

  return (
    <>
      <ModalCenterSelectableWithTitle
        open={open}
        handleClose={handleClose}
        title={'Вы уверены, что хотите удалить эту группу?'}
        modalType="small"
        contentChild={
          <Typography variant="body1">
            Действие не может быть отменено. Это навсегда удалит группу, включая
            все документы всех участников. Введите название группы для
            подтверждения
          </Typography>
        }
        footerChild={
          <Formik
            initialValues={{
              ts_name: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleClickDelete}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box pb={'8px'}>
                  <TextInput
                    autoFocus
                    size="small"
                    fullWidth
                    placeholder="Название группы..."
                    name="ts_name"
                    label={'Название группы'}
                  ></TextInput>
                </Box>
                <Box justifyContent="flex-end" display={'flex'}>
                  <Button
                    sx={{ mr: '8px' }}
                    color="secondary"
                    onClick={() => setOpen(false)}
                    variant="outlined"
                  >
                    Отменить
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    type="submit"
                    disabled={loading.delete_ts ? true : false}
                  >
                    Удалить
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        }
      />

      <Typography variant="body1" fontWeight={500}>
        Удалить группу
      </Typography>
      <Typography
        variant="caption"
        color={'text.secondary'}
        mt="2px"
        fontWeight={400}
      >
        Это навсегда удалит группу, включая все документы, шаблоны, рассылки
        всех участников этой группы
      </Typography>
      <Box mt="8px">
        <Button
          color="error"
          variant="outlined"
          size="small"
          onClick={handleOpen}
        >
          Удалить этy группу
        </Button>
      </Box>
    </>
  )
}
