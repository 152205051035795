import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { Switcher } from 'components'
import { useFormikContext } from 'formik'
import dayjs from 'dayjs'
import { RangeDatePicker } from './components/rangePicker'
import { MultiDatePicker } from './components/multiDatePicker'
import { SingleDatePicker } from './components/singleDatePicker'

export interface IDateFieldAttrs {
  default_value: string
  format: string
  has_before: boolean
  before: string
  has_after: boolean
  after: string
  has_specific_dates: boolean
  specific_dates: string[]
  has_date_range: boolean
  date_range: any[]
}

export interface IDateFieldSettings {
  attrs?: IDateFieldAttrs
}

type FieldName = keyof IDateFieldAttrs

const configArray: Array<{
  name: FieldName
  label?: string
  type: string
  dependsOn?: FieldName
  description?: string
  options?: Array<{ label: string; description?: string }>
}> = [
  { name: 'has_before', label: 'До определенной даты', type: 'switch' },
  { name: 'before', type: 'date', dependsOn: 'has_before' },
  { name: 'has_after', label: 'После определенной даты', type: 'switch' },
  { name: 'after', type: 'date', dependsOn: 'has_after' },
  { name: 'has_date_range', label: 'Промежуток дат', type: 'switch' },
  { name: 'date_range', type: 'date', dependsOn: 'has_date_range' },
  { name: 'has_specific_dates', label: 'Определенные дни', type: 'switch' },
  { name: 'specific_dates', type: 'date', dependsOn: 'has_specific_dates' },
]

const configArrayLimits: Array<{
  name: FieldName
  label?: string
  type: string
  dependsOn?: FieldName
  description?: string
  options?: Array<{ label: string; description?: string; value: string }>
}> = [
  {
    name: 'format',
    label: 'Формат даты',
    type: 'select',
    options: [
      {
        label: 'День.Месяц.Год',
        description: 'Пример: 12.03.2023',
        value: 'D.MM.YYYY',
      },
      {
        label: 'Текстом',
        description: 'Пример: 12 июля, 2023 года',
        value: 'D MMMM, YYYY года',
      },
    ],
  },
]

export const dateFieldinitialValues: IDateFieldAttrs = {
  format: 'D.MM.YYYY',
  has_before: false,
  before: '',
  has_after: false,
  after: '',
  default_value: '',
  has_specific_dates: false,
  specific_dates: [],
  has_date_range: false,
  date_range: [dayjs(new Date()), dayjs(new Date())],
}

export const DateForm = () => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      {configArrayLimits.map((field) => (
        <FieldRenderer key={field.name} field={field} />
      ))}
      <Box p="6px 16px 4px">
        <Typography variant="caption" color={'#90959D'}>
          Ограничения
        </Typography>
      </Box>
      {configArray.map((field) => (
        <FieldRenderer key={field.name} field={field} />
      ))}
    </Box>
  )
}

const FieldRenderer = ({ field }) => {
  const { values, setFieldValue } = useFormikContext<IDateFieldAttrs>()

  const handleSwitchChange = (currentSwitchName) => {
    configArray.forEach(({ name, type }) => {
      if (name === currentSwitchName || type !== 'switch') return
      setFieldValue(name, name === currentSwitchName)
    })
  }

  if (field.type === 'switch') {
    return (
      <Box p="6px 16px">
        <Switcher
          name={field.name}
          label={field.label}
          onChange={handleSwitchChange}
        />
        {field.description && (
          <Typography variant={'body2'} color={'text.disabled'}>
            {field.description}
          </Typography>
        )}
      </Box>
    )
  }
  if (field.type === 'select') {
    return (
      <Box p="6px 16px">
        <FormikSelect
          label={field.label}
          name={field.name}
          options={field.options}
        />
      </Box>
    )
  }

  if (field.dependsOn && values[field.dependsOn]) {
    if (field.name === 'date_range') {
      return (
        <Box p="6px 16px">
          <RangeDatePicker name={field.name} />
        </Box>
      )
    }
    if (field.name === 'specific_dates') {
      return (
        <Box p="6px 16px">
          <MultiDatePicker name={field.name} />
        </Box>
      )
    }
    return (
      <Box p="6px 16px">
        <SingleDatePicker name={field.name} />
      </Box>
    )
  }
  return null
}

export const FormikSelect = ({ label, name, options }: any) => {
  const { values, setFieldValue } = useFormikContext<any>()

  const handleChange = (event: SelectChangeEvent<string>) => {
    setFieldValue(name, event.target.value)
  }

  return (
    <Box>
      <Typography variant="h6" color={'text.primary'} mb="4px">
        {label}
      </Typography>
      <Select
        fullWidth
        name={name}
        value={values[name]}
        onChange={handleChange}
        renderValue={(selected) => {
          const selectedOption = options.find(
            (option) => option.value === selected
          )
          return <>{selectedOption?.label}</>
        }}
        sx={{ p: 0 }}
      >
        {options.map((option, i) => (
          <MenuItem key={i} value={option.value} sx={{ m: '2px 4px' }}>
            <Box display={'flex'} flexDirection={'column'}>
              <Typography variant="body1">{option.label}</Typography>
              {option.description && (
                <Typography variant="body2" color={'text.secondary'}>
                  {option.description}
                </Typography>
              )}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
