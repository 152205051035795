import { deleteUsersTokens } from 'features/delete-api-secret-key/api/deleteUsersTokens'
import { useCallback, useState } from 'react'

export const useActionDeleteAPISecretKey = ({
  onError,
  onSuccess,
}: {
  onError?: TFuncOnError
  onSuccess?: () => void
}) => {
  const [loading, setLoading] = useState(false)

  const run = useCallback(async () => {
    try {
      setLoading(true)
      await deleteUsersTokens()
      onSuccess && onSuccess()
    } catch (e: any) {
      onError && onError(e.message)
    } finally {
      setLoading(false)
    }
  }, [onError, onSuccess])

  return { loading, run }
}
