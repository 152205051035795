import { Box } from '@mui/material'
import { ChipOutlinedSmall } from 'components/chip/ChipSmall'
import { useRef, useState, useEffect } from 'react'
import { IDropdownOption, IDropdownOptionAttrs } from '..'

const CHIP_WIDTH = 80
const GAP_SIZE = 4

export const ChipList = ({
  selected,
}: {
  selected: IDropdownOptionAttrs[]
}) => {
  const containerRef = useRef<any>(null)
  const [visibleCount, setVisibleCount] = useState<number>(selected.length)

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width
      const maxVisibleChips = Math.floor(
        (containerWidth + GAP_SIZE) / (CHIP_WIDTH + GAP_SIZE)
      )

      setVisibleCount(maxVisibleChips)
    }
  }, [containerRef, selected])

  return (
    <Box
      ref={containerRef}
      style={{
        display: 'flex',
        gap: '4px',
        alignContent: 'center',
        overflow: 'hidden',
      }}
    >
      {selected.slice(0, visibleCount).map((value) => (
        <ChipOutlinedSmall key={value.index} label={value.text} />
      ))}
      {selected.length > visibleCount && (
        <ChipOutlinedSmall label={`+${selected.length - visibleCount}`} />
      )}
    </Box>
  )
}
