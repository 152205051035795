import { Box, Button, IconButton } from '@mui/material'
import close from 'assets/img/close.svg'
import img_restore from 'assets/img/time_revert.svg'
import img_delete from 'assets/img/trash_x.svg'
import { useSelectedDocsContext } from 'context'
import { useState } from 'react'
import { deleteDocument, restoreDocument } from 'service'
import { ModalDeleteDocumentForever } from 'components/modal-actions/modalActionDocument'

export const MassActionsDelete = () => {
  const {
    selectedDocsIds,
    setSelectedDocsIds,
    setHiddenDocumentIds,
    showSnackbar,
    closeSnackbar,
  } = useSelectedDocsContext()

  const [modalDelete, setModalDelete] = useState(false)

  const handleDeselectAll = () => {
    setSelectedDocsIds(new Map())
  }

  const deleteDoc = async (value) => {
    try {
      await deleteDocument({ id: value.id })
      return 1
    } catch (err) {
      return 0
    }
  }

  const restore = async (docs) => {
    try {
      for (const value of docs.values()) {
        await restoreDocument({ id: value.id })
        setHiddenDocumentIds((state) => [...state, value.id])
      }
      setSelectedDocsIds(new Map())
      showSnackbar({
        message: 'Документы восстановлены',
        severity: 'info',
        close: closeSnackbar,
        buttons: [],
      })
    } catch (error: any) {
      if (error instanceof Error) {
        showSnackbar({
          message: error.message,
          severity: 'error',
          buttons: [],
          close: closeSnackbar,
        })
      }
    }
  }

  const openModalDelete = () => {
    setModalDelete(true)
  }

  const closeModalDelete = () => {
    setModalDelete(false)
  }

  const actionDeleteDocuments = async () => {
    const docs = selectedDocsIds
    const btns = [
      <Button
        size="small"
        color="secondary"
        variant="outlined"
        sx={{ height: '24px' }}
        onClick={async () => {
          await restore(docs)
        }}
      >
        Отменить
      </Button>,
    ]
    try {
      let count = 0
      let countErr = 0

      for (const value of docs.values()) {
        const result = await deleteDoc(value)

        if (result === 1) {
          count++
          setHiddenDocumentIds((state) => [...state, value.id])
        } else {
          countErr++
        }
      }
      setSelectedDocsIds(new Map())
      showSnackbar({
        message:
          count > 0
            ? 'Документы удалены'
            : `Не удалось удалить ${countErr} документов`,
        severity: 'info',
        buttons: btns,
        close: closeSnackbar,
      })
      closeModalDelete()
    } catch (error) {
      if (error instanceof Error) {
        showSnackbar({ message: error.message, severity: 'error' })
      }
    }
  }

  return (
    <>
      <ModalDeleteDocumentForever
        handleClose={closeModalDelete}
        open={modalDelete}
        funcDelete={actionDeleteDocuments}
        count={selectedDocsIds.size}
        tableType={'documents'}
      />
      <Box display={'flex'} alignItems={'center'}>
        <Box mr={1}>
          <Button
            color="secondary"
            variant="outlined"
            startIcon={
              <Box component={'img'} src={img_restore} width={'20px'} />
            }
            onClick={() => restore(selectedDocsIds)}
          >
            Восстановить
          </Button>
        </Box>

        <Box mr={1}>
          <Button
            color="secondary"
            variant="outlined"
            startIcon={
              <Box component={'img'} src={img_delete} width={'20px'} />
            }
            onClick={openModalDelete}
          >
            Удалить безвозвратно
          </Button>
        </Box>

        <Box mr={1}>
          <IconButton sx={{ borderRadius: '4px' }} onClick={handleDeselectAll}>
            <Box component={'img'} src={close} width={'20px'} height={'20px'} />
          </IconButton>
        </Box>
      </Box>
    </>
  )
}
