import { createContext, useState } from "react"
import { v4 as uuidv4 } from 'uuid'

export enum EModalCreateDocumentStep {
  default = 'default',
  join_files = 'join_files',
}


export type FileSortable = (File & { id: string })

/** 
 * @returns files with id. Important, function returns given files, just with id. It means, given files will be modified.
 */
function convertFilesToFileSortable(files: File[]) {
  files.forEach(f => {
    (f as any).id = uuidv4()
  })
  return (files as FileSortable[])
}

export const useJoinFiles = () => {
  const [files, setFiles] = useState<FileSortable[]>([])

  const addFiles = (files: File[]) => {
    setFiles((v) => {
      return [
        ...v,
        ...convertFilesToFileSortable(files),
      ]
    })
  }

  const resetFiles = (files?: File[]) => {
    if (!files || files.length == 0) {
      setFiles([])
      return
    }

    setFiles(convertFilesToFileSortable(files))
  }

  const removeFile = (id: string) => {
    setFiles((v) => {
      return v.filter(f => f.id !== id)
    })
  }

  return { files, addFiles, resetFiles, removeFile }
}


interface IModalCreateDocumentContext {
  joinFiles: ReturnType<typeof useJoinFiles>
  switchToStepDefault: () => void
  switchToStepJoinFiles: (files: FileSortable[]) => void
  step: EModalCreateDocumentStep
}

export const ModalCreateDocumentContext = createContext<IModalCreateDocumentContext>({} as any)

export const useModalCreateDocumentContextDefaultValue = () => {
  const [step, setStep] = useState(EModalCreateDocumentStep.default)
  const joinFiles = useJoinFiles()

  const switchToStepJoinFiles = (files: File[]) => {
    joinFiles.resetFiles(files)

    setStep(EModalCreateDocumentStep.join_files)
  }

  const switchToStepDefault = () => {
    setStep(EModalCreateDocumentStep.default)
    joinFiles.resetFiles()
  }

  return {
    switchToStepDefault,
    switchToStepJoinFiles,
    step,
    joinFiles,
  }
}