import { ESystemVariableType } from 'features/text-editors/lexical/plugins-custom/ParameterPickerPlugin/helpers'
import { LocalStorageReserve } from 'helper/consts'
import {
  transformObjectDropdown,
  transformObjectInput,
  transformObjectInputNumber,
  transformObjectInputDate,
  transformObjectCheckbox,
  transformObjectTextArea,
} from 'pages/template-editor/components/header/utils/transformVariables'
import { getLocalStorageData } from 'utils/getLocalStorageData'

export const convertVariablesAsServerFormat = (): any[] => {
  const convertedVariables: any[] = []

  const savedLocalStorageData = getLocalStorageData(
    LocalStorageReserve.variableProps
  )

  savedLocalStorageData &&
    Object.keys(savedLocalStorageData).forEach((key) => {
      const variable = savedLocalStorageData[key]
      switch (variable.type) {
        case ESystemVariableType.DROPDOWN: {
          const transformedDropdown = transformObjectDropdown(variable)
          convertedVariables.push(...transformedDropdown)
          break
        }

        case ESystemVariableType.INPUT_TEXT:
        case ESystemVariableType.INPUT_PHONE_NUMBER: {
          const transformedInput = transformObjectInput(variable)
          convertedVariables.push(...transformedInput)
          break
        }

        case ESystemVariableType.INPUT_NUMBER: {
          const transformedInputNumber = transformObjectInputNumber(variable)
          convertedVariables.push(...transformedInputNumber)
          break
        }

        case ESystemVariableType.INPUT_DATE: {
          const transformedInputDate = transformObjectInputDate(variable)
          convertedVariables.push(...transformedInputDate)
          break
        }
        case ESystemVariableType.CHECKBOX: {
          const transformedCheckbox = transformObjectCheckbox(variable)
          convertedVariables.push(...transformedCheckbox)
          break
        }

        case ESystemVariableType.TEXT_AREA: {
          const transformedTextArea = transformObjectTextArea(variable)
          convertedVariables.push(...transformedTextArea)
          break
        }

        default:
          convertedVariables.push(variable)
          break
      }
    })
  return convertedVariables
}
