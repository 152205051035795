import img_webhook from 'assets/img/integrations/Logo=webhook.svg'

import { ModalIntegrationWebhook } from 'features/integration-webhook/components/ModalIntegrationWebhook'
import { UrlKeys } from 'helper/consts'
import { useModal } from 'hooks/useModal'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { IntegrationCard } from '../IntegrationCard'

export const IntegrationCardWebhook = ({
  onCreateIntegrationCallback,
}: {
  onCreateIntegrationCallback?: () => Promise<any> | any
}) => {
  const { urlId: urlTemplateId } = useUrlIdParam(UrlKeys.templateId)

  const [modalOpen, openModal, closeModal] = useModal()

  return (
    <>
      {modalOpen && (
        <ModalIntegrationWebhook
          open={modalOpen}
          handleClose={closeModal}
          templateId={urlTemplateId.get()}
          onSuccessCallback={onCreateIntegrationCallback}
        />
      )}
      <IntegrationCard
        icon={img_webhook}
        title={'Webhook'}
        description={' Отправка статусов и данных по документам'}
        isAvailable={true}
        onClickCallback={openModal}
      />
    </>
  )
}
