export const formatPrice = (price: number) => {
  if (!price) return '0'

  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 0,
    useGrouping: true,
  })
    .format(price)
    .replace(/,/g, ' ')
}
