import {
  IFormikFieldsPaymentRequisites,
  getFormPaymentRequisitesValidationSchema,
} from 'components/forms/FormikFormPaymentRequisites'
import { useFormik } from 'formik'
import { EWorkspacePlan } from 'helper/plan.types'
import { plansInfoMap } from 'pages/plan/types'
import { useContext, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { planApi } from 'service/plan'
import { wsPlanSlice } from 'store/slices/wsPlan'
import { TRootState, useAppSelector } from 'store/store'
import { SelectPlanContext } from '../SelectPlanProvider'
import { ModalPlanPaymentMethod } from './ModalPlanPaymentMethod'
import { PlanPaymentGenerateInvoiceModals } from './PlanPaymentGenerateInvoiceModals'
import {
  EModalPaymentRequisitesFormType,
  ModalPaymentRequisitesForm,
} from './PlanPaymentGenerateInvoiceModals/ModalPaymentInvoiceForm'

enum EStepState {
  SelectPaymentMethod = 'SelectPaymentMethod',

  PaymentGenerateInvoice = 'PaymentGenerateInvoice',
  PaymentWithInvoice = 'PaymentWithInvoice',
}

export const PlanModals = () => {
  const {
    selectedPeriod,
    selectedPlan,
    setPlan,
    step,
    setStep,
    formik,
    payWithCard,
    requireClosingDocuments,
    setRequireClosingDocuments,
  } = usePlanModals()

  return (
    <>
      <ModalPlanPaymentMethod
        open={step === EStepState.SelectPaymentMethod}
        handleClose={() => {
          setPlan(undefined)
        }}
        content={{
          monthCount: selectedPeriod,
          planName: selectedPlan ?? EWorkspacePlan.Free,
          requireClosingDocuments,
          setRequireClosingDocuments,
          handleClickGenerateInvoice: () => {
            setStep(EStepState.PaymentGenerateInvoice)
          },
          handleClickPayWithCard: async () => {
            if (requireClosingDocuments) {
              setStep(EStepState.PaymentWithInvoice)
              return
            }
            await payWithCard()
          },
        }}
      />

      <PlanPaymentGenerateInvoiceModals
        open={step === EStepState.PaymentGenerateInvoice}
        handleClose={() => {
          setPlan(undefined)
        }}
        handleClickBack={() => {
          setStep(EStepState.SelectPaymentMethod)
        }}
      />

      <ModalPaymentRequisitesForm
        open={step == EStepState.PaymentWithInvoice}
        handleClose={() => {
          setPlan(undefined)
        }}
        handleClickBack={() => {
          setStep(EStepState.SelectPaymentMethod)
        }}
        formik={formik}
        formType={EModalPaymentRequisitesFormType.InputInvoiceToPay}
      />
    </>
  )
}

const usePlanModals = () => {
  const {
    selectedPeriod,
    selectedPlan,
    setPlan,
    requireClosingDocuments,
    setRequireClosingDocuments,
  } = useContext(SelectPlanContext)

  const { selectedWorkspace } = useSelector(
    (state: TRootState) => state.selectedWorkspace,
    shallowEqual
  )
  const { requisites } = useAppSelector((state) => state.wsPlan, shallowEqual)
  const dispatch = useDispatch<any>()

  const [step, setStep] = useState<EStepState | undefined>()
  const [error, setError] = useState('')

  const onSubmitPayWithCard = async (
    fields?: IFormikFieldsPaymentRequisites
  ) => {
    try {
      if (!selectedPlan) throw new Error('Не выбран тариф')
      if (!selectedWorkspace?.id)
        throw new Error('Не выбрана рабочее пространство')
      if (requireClosingDocuments && !fields)
        throw new Error('Данные реквизитов не заполнены')

      const resp = await planApi.postPayWithCardLink({
        workspace_id: selectedWorkspace.id,
        data: {
          total_months: selectedPeriod,
          subscription_plan_name: plansInfoMap[selectedPlan].serverName,

          require_closing_documents: requireClosingDocuments,
          requisites: requireClosingDocuments ? fields : undefined,
        },
      })

      if (requireClosingDocuments)
        dispatch(wsPlanSlice.actions.setRequisites(fields))

      dispatch(
        wsPlanSlice.actions.setRequireClosingDocuments(requireClosingDocuments)
      )

      window.location.href = resp.data.payment_url
      setPlan(undefined)
    } catch (err: any) {
      console.error(err)
      setError(err.message)
    }
  }

  const formik = useFormik<IFormikFieldsPaymentRequisites>({
    initialValues: {
      bin: requisites?.bin ?? '',
      company_name: requisites?.company_name ?? '',
      legal_address: requisites?.legal_address ?? '',
    },
    validationSchema: getFormPaymentRequisitesValidationSchema(),
    onSubmit: onSubmitPayWithCard,
    enableReinitialize: true,
  })

  useEffect(() => {
    if (!selectedPlan) setStep(undefined)
    else setStep(EStepState.SelectPaymentMethod)
  }, [selectedPlan])

  return {
    selectedPeriod,
    selectedPlan,
    setPlan,
    step,
    setStep,
    error,
    setError,

    requireClosingDocuments,
    setRequireClosingDocuments,
    formik,

    payWithCard: onSubmitPayWithCard,
  }
}
