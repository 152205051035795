import short_text from 'assets/img/variables/short_text.svg'
import notes from 'assets/img/variables/notes.svg'
import arrow_drop_down_circle from 'assets/img/variables/arrow_drop_down_circle.svg'
import addressImg from 'assets/img/variables/address.svg'
import emailImg from 'assets/img/variables/email.svg'
import name from 'assets/img/variables/name.svg'
import bin from 'assets/img/variables/bin.svg'
import numbersImg from 'assets/img/variables/numbers.svg'
import phoneImg from 'assets/img/variables/phone.svg'
import calendarImg from 'assets/img/variables/calendar.svg'
import checkboxImg from 'assets/img/variables/check_box.svg'

import {
  InitialVariableTypeTitles,
  VariableTypeTitles,
} from '../variableConfig'
import { ESystemVariableType, ECustomVariableType } from '../variableTypes'

const defaultIINSettings = {
  has_max: true,
  max: 12,
  has_min: true,
  min: 12,
}

const BIN = {
  data: {
    type: ESystemVariableType.INPUT_TEXT,
    title: VariableTypeTitles[ECustomVariableType.INPUT_TEXT_BIN],
    initialTitle: InitialVariableTypeTitles[ESystemVariableType.INPUT_TEXT],
    ...defaultIINSettings,
  },
  meta: {
    keyWords: ['ИИН', 'bin', 'бизнес', 'идентификационный', 'номер', 'number'],
    icon: bin,
  },
}

const firstName = {
  data: {
    type: ESystemVariableType.INPUT_TEXT,
    title: VariableTypeTitles[ECustomVariableType.INPUT_TEXT_NAME],
    initialTitle:
      InitialVariableTypeTitles[ECustomVariableType.INPUT_TEXT_NAME],
  },
  meta: {
    keyWords: ['имя', 'name', 'first'],
    icon: name,
  },
}
const lastName = {
  data: {
    type: ESystemVariableType.INPUT_TEXT,
    title: VariableTypeTitles[ECustomVariableType.INPUT_TEXT_LAST_NAME],
    initialTitle:
      InitialVariableTypeTitles[ECustomVariableType.INPUT_TEXT_LAST_NAME],
  },
  meta: {
    keyWords: ['фамилия', 'last', 'surname', 'lastname'],
    icon: name,
  },
}
const fatherName = {
  data: {
    type: ESystemVariableType.INPUT_TEXT,
    title: VariableTypeTitles[ECustomVariableType.INPUT_TEXT_FATHER_NAME],
    initialTitle:
      InitialVariableTypeTitles[ECustomVariableType.INPUT_TEXT_FATHER_NAME],
  },
  meta: {
    keyWords: ['отчество', 'father', 'patronymic', 'middle'],
    icon: name,
  },
}

const IIN = {
  data: {
    type: ESystemVariableType.INPUT_TEXT,
    title: VariableTypeTitles[ECustomVariableType.INPUT_TEXT_IIN],
    initialTitle: InitialVariableTypeTitles[ECustomVariableType.INPUT_TEXT_IIN],
    ...defaultIINSettings,
  },
  meta: {
    keyWords: ['ИИН', 'идентификационный', 'номер', 'iin', 'number'],
    icon: bin,
  },
}

const address = {
  data: {
    type: ESystemVariableType.INPUT_TEXT,
    title: VariableTypeTitles[ECustomVariableType.INPUT_TEXT_ADDRESS],
    initialTitle:
      InitialVariableTypeTitles[ECustomVariableType.INPUT_TEXT_ADDRESS],
  },
  meta: {
    keyWords: ['адрес'],
    icon: addressImg,
  },
}

const inputText = {
  data: {
    type: ESystemVariableType.INPUT_TEXT,
    title: VariableTypeTitles[ESystemVariableType.INPUT_TEXT],
    initialTitle: InitialVariableTypeTitles[ESystemVariableType.INPUT_TEXT],
  },
  meta: {
    keyWords: ['text', 'текст', 'короткий', 'short'],
    icon: short_text,
  },
}

const textArea = {
  data: {
    type: ESystemVariableType.TEXT_AREA,
    title: VariableTypeTitles[ESystemVariableType.TEXT_AREA],
    initialTitle: InitialVariableTypeTitles[ESystemVariableType.TEXT_AREA],
  },
  meta: {
    keyWords: ['текст', 'длинный', 'text', 'long', 'notes'],
    icon: notes,
  },
}

const dropDown = {
  data: {
    type: ESystemVariableType.DROPDOWN,
    title: VariableTypeTitles[ESystemVariableType.DROPDOWN],
    initialTitle: InitialVariableTypeTitles[ESystemVariableType.DROPDOWN],
  },
  meta: {
    keyWords: ['список', 'Раскрывающийся', 'выпадающий'],
    icon: arrow_drop_down_circle,
  },
}

const email = {
  data: {
    type: ESystemVariableType.INPUT_EMAIL,
    title: VariableTypeTitles[ESystemVariableType.INPUT_EMAIL],
    initialTitle: InitialVariableTypeTitles[ESystemVariableType.INPUT_EMAIL],
  },
  meta: {
    keyWords: ['email', 'электронный адрес', 'mail'],
    icon: emailImg,
  },
}

const phoneNumber = {
  data: {
    type: ESystemVariableType.INPUT_PHONE_NUMBER,
    title: VariableTypeTitles[ESystemVariableType.INPUT_PHONE_NUMBER],
    initialTitle:
      InitialVariableTypeTitles[ESystemVariableType.INPUT_PHONE_NUMBER],
  },
  meta: {
    keyWords: ['телефон', 'номер', 'phone'],
    icon: phoneImg,
  },
}

const numberField = {
  data: {
    type: ESystemVariableType.INPUT_NUMBER,
    title: VariableTypeTitles[ESystemVariableType.INPUT_NUMBER],
    initialTitle: InitialVariableTypeTitles[ESystemVariableType.INPUT_NUMBER],
  },
  meta: {
    keyWords: ['число', 'цифры', 'number'],
    icon: numbersImg,
  },
}

const date = {
  data: {
    type: ESystemVariableType.INPUT_DATE,
    title: VariableTypeTitles[ESystemVariableType.INPUT_DATE],
    initialTitle: InitialVariableTypeTitles[ESystemVariableType.INPUT_DATE],
  },
  meta: {
    keyWords: ['дата', 'date', 'calendar', 'календарь'],
    icon: calendarImg,
  },
}

const checkbox = {
  data: {
    type: ESystemVariableType.CHECKBOX,
    title: VariableTypeTitles[ESystemVariableType.CHECKBOX],
    initialTitle: InitialVariableTypeTitles[ESystemVariableType.CHECKBOX],
  },
  meta: {
    keyWords: ['checkbox', 'чекбокс', 'check', 'box'],
    icon: checkboxImg,
  },
}

export const variablesList: Array<Variable> = [
  inputText,
  textArea,
  numberField,
  dropDown,
  date,
  checkbox,
  phoneNumber,
  email,
  IIN,
  BIN,
  lastName,
  firstName,
  fatherName,
  address,
]
