import { Box, Modal, CircularProgress, Typography } from '@mui/material'

export const styleLoading = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 4,
  textAlign: 'center',
}

export const LoadingWithMessage = ({ loading }) => {
  return (
    <Modal
      open={loading.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleLoading}>
        <CircularProgress />
        <Typography id="modal-modal-title" variant="body2" color="#525252">
          {loading.message}
        </Typography>
      </Box>
    </Modal>
  )
}
