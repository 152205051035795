import { IApiKey } from 'entity/api-key/types'
import { postUsersTokens } from 'features/create-api-secret-key/api/postUsersTokens'
import { useCallback, useState } from 'react'

export type TUseActionLoadAPISecretKeyOnSuccess = (res: IApiKey) => void

export const useActionLoadAPISecretKey = ({
  onError,
  onSuccess,
}: {
  onError?: TFuncOnError
  onSuccess?: TUseActionLoadAPISecretKeyOnSuccess
}) => {
  const [loading, setLoading] = useState(false)

  const run = useCallback(async () => {
    try {
      setLoading(true)
      const res = await postUsersTokens()
      onSuccess && onSuccess(res)
    } catch (e: any) {
      onError && onError(e.message)
    } finally {
      setLoading(false)
    }
  }, [onError, onSuccess])

  return { loading, run }
}
