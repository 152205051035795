import { TSRolePermissions, TS_ROLES, WS_ROLES } from 'helper/roles'

export const findByValue = (value) => {
  return tsRoleOptions.find((item) => item.value === value)
}

export const generateAvailableListTs = (group_name) => {
  const availableList: any = []

  TSRolePermissions[group_name].canManageMemberGroups.forEach((group) => {
    const item = findByValue(group)
    item && availableList.push(item)
  })

  return availableList
}

export const tsRoleOptions = [
  {
    value: TS_ROLES.owner,
    label: 'Владелец группы',
    desc: 'Может менять настройки, управлять участниками, имеет доступ ко всем документам этой группы',
  },
  {
    value: TS_ROLES.member,
    label: 'Участник группы',
    desc: 'Может приглашать новых участников, имеет доступ ко всем документам этой группы',
  },
]

export const wsRoleOptions = [
  {
    value: WS_ROLES.owner,
    label: 'Владелец пространства',
    desc: 'Может менять настройки, управлять участниками, имеет доступ ко всем документам этого пространства',
  },
  {
    value: WS_ROLES.member,
    label: 'Участник пространства',
    desc: 'Имеет доступ к группам в которых состоит',
  },
]
