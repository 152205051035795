import { ColumnsListItemNode } from "features/text-editors/lexical/custom/nodes/ColumnsListNode/ColumnsListItemNode"
import { TextNode } from "lexical"

const excludedToDrop: { [key: string]: boolean } = {
  [ColumnsListItemNode.getType()]: true,
  [TextNode.getType()]: true,
  // TODO: NumericListItemNode, BulletListItemNode
}

/**
 * isExcludedToDrop - using for determining whether the can be dropped to this node or not.
 * @param nodeType 
 * @returns `true` if node can be dropped this nodeType, otherwise `false`
 */
export function isExcludedToDrop(nodeType: string) {
  return !!excludedToDrop[nodeType]
}