import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { LoadingFullHeight } from 'components'
import { useEffect } from 'react'
import { SidebarLayout } from 'layout/sidebar-layout'
import { FillerFormFields } from './fillterFormFields'
import { UrlKeys } from 'helper/consts'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { useAppSelector } from 'store/store'
import { fetchActorsDocuments } from 'store/slices/applicationSlice'

export const FormCreateDoc = ({ handleClose }) => {
  const { urlId } = useUrlIdParam(UrlKeys.documentId)
  const { urlId: templateId } = useUrlIdParam(UrlKeys.templateId)

  const dispatch = useDispatch()

  const { prefillFormVariables, variables, loading } = useAppSelector(
    (state) => state.application,
    shallowEqual
  )

  const { document } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )

  useEffect(() => {
    document.id && urlId.setIfNotExists(document.id)
  }, [document.id])

  useEffect(() => {
    dispatch(
      fetchActorsDocuments({
        documentId: urlId.get(),
        templateId: templateId.get(),
      }) as any
    )
  }, [prefillFormVariables])

  if (loading.info || (loading as any).getVariables)
    return <LoadingFullHeight />

  return (
    <>
      <SidebarLayout
        header={{
          handleClose,
          title: 'Переменные данные',
          subtitle: 'Заполните обязательные поля перед отправкой документа',
        }}
      >
        {variables && <FillerFormFields />}
      </SidebarLayout>
    </>
  )
}
