import { AnalyticsIdentifyUser } from '../AnalyticsIdentifyUser-layout'
import { AnalyticsIdentifyWorkspace } from '../AnalyticsIdentifyWorkspace-layout'
import { AnalyticsPathLayout } from '../AnalyticsPathLayout-layout'

export const AnalyticsLayout = ({ children }) => {
  return (
    <AnalyticsIdentifyUser>
      <AnalyticsIdentifyWorkspace>
        <AnalyticsPathLayout>{children}</AnalyticsPathLayout>
      </AnalyticsIdentifyWorkspace>
    </AnalyticsIdentifyUser>
  )
}
