import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Divider, IconButton, Menu } from '@mui/material'
import edit from 'assets/img/edit.svg'
import img_info from 'assets/img/ic_document.svg'
import img_mvfolder from 'assets/img/move_folder.svg'
import img_template from 'assets/img/template_dark.svg'
import { MenuItemButton } from 'components/menu-item/menu-item-button'
import { ModalActionDocument } from 'components/modal-actions/modalActionDocument'
import { CustomTooltip } from 'components/tooltip'
import { MenuItemButtonDublicateDocument } from 'features/copy-document/ui/MenuItemButtonDublicateDocument'
import { DownloadListOfApprovers } from 'features/download'
import { MenuItemButtonDocumentDownload } from 'features/document-download/components/MenuItemButtonDocumentDownload'
import { ModalMoveDocumentsBetweenTeamspaces } from 'features/move-documents-between-teamspaces/components/ModalMoveDocumentsBetweenTeamspaces'
import { EDocumentStatus } from 'helper/consts'
import { useModal } from 'hooks/useModal'
import { useTemplateActions } from 'hooks/useTemplateActions'
import { ArchiveMenu } from 'pages/document-preview/components/archive'
import { SidebarMode } from 'pages/document-preview/hooks/useSideBarState'
import { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { restrictionsTemplate } from 'utils/restrictions/template'

export const MoreInfoBtn = ({ updateSidebar }) => {
  const navigate = useNavigate()
  const { selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { document, template } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )
  const [modalEditTemplateOpen, openModalEditTemplate, closeModalEditTemplate] =
    useModal()
  const [modalMoveOpen, openModalMove, closeModalMove] = useModal()

  const onSuccessMoveDocument = (movedTeamspace: string) => {
    if (!selectedTeamspace?.id) return

    const path = `${
      window.location.pathname + window.location.search
    }`.replaceAll(selectedTeamspace.id, movedTeamspace)
    navigate(path)
  }
  const templateId = document.template_id || template?.id || ''
  const { actionEditTemplate } = useTemplateActions({
    templateId: templateId,
  })

  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <ModalActionDocument
        key={'edit-template-modal'}
        handleClose={closeModalEditTemplate}
        open={modalEditTemplateOpen}
        action={actionEditTemplate}
        title={'Изменить шаблон'}
        description={`Вы уверены? Если вы это сделаете: \n\n Любые внесенные изменения в шаблоне будут применены у всех последующих документов, созданных с использованием этого шаблона.`}
        btnTitle={'Изменить шаблон'}
        btnColor={'primary'}
        btnVariant={'contained'}
      />
      <CustomTooltip title="Действия">
        <IconButton
          onClick={handleClick}
          id="demo-positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          children={
            <MoreHorizIcon
              sx={{
                color: '#3C4149',
                width: '20px',
                height: '20px',
              }}
            />
          }
        />
      </CustomTooltip>

      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          mt: '2px',
        }}
        slotProps={{
          paper: {
            style: {
              width: '240px',
              maxHeight: '100%',
            },
          },
        }}
      >
        <MenuItemButton
          srcImage={img_info}
          title="Детали документа"
          onClick={() => {
            updateSidebar(SidebarMode.info)
            handleClose()
          }}
        />
        {template && templateId && restrictionsTemplate.canEdit(template) && (
          <MenuItemButton
            srcImage={edit}
            title="Изменить шаблон"
            onClick={() => {
              handleClose()
              openModalEditTemplate()
            }}
          />
        )}
        <MenuItemButtonDocumentDownload />
        {document.approver_rk_ddcard_size > 0 && (
          <DownloadListOfApprovers id={document.id} key={1} />
        )}
        {templateId && (
          <>
            <Divider sx={{ margin: '7px -12px' }}></Divider>
            <MenuItemButton
              srcImage={img_template}
              title="Перейти к шаблону"
              onClick={() => {
                navigate(
                  `/teamspace/${selectedTeamspace?.id}/templates/${templateId}/manage`
                )
              }}
            />
          </>
        )}

        {document.id && (
          <>
            <MenuItemButtonDublicateDocument
              documentId={document.id}
              onSuccessCallback={(newDocumentId: string) => {
                const path = `${
                  window.location.pathname + window.location.search
                }`.replaceAll(document.id, newDocumentId)
                navigate(path)
              }}
            />

            <MenuItemButton
              srcImage={img_mvfolder}
              title="Переместить"
              onClick={() => {
                openModalMove()
                handleClose()
              }}
            />
          </>
        )}

        {!document.is_archived && document.status !== EDocumentStatus.DRAFT && (
          <div key={3}>
            <Divider sx={{ margin: '7px -12px' }}></Divider>
            <ArchiveMenu id={document.id} />
          </div>
        )}
      </Menu>

      <ModalMoveDocumentsBetweenTeamspaces
        open={modalMoveOpen}
        handleClose={closeModalMove}
        documentIds={[document.id]}
        onSuccessCallback={onSuccessMoveDocument}
      />
    </>
  )
}
