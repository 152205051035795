import { ESystemVariableType } from 'features/text-editors/lexical/plugins-custom/ParameterPickerPlugin/helpers'
import {
  EFieldName,
  EFieldType,
} from 'features/text-editors/lexical/plugins-custom/VariablePlugin/helpers/consts'

const commonConfigurations = [
  {
    name: EFieldName.IS_REQUIRED,
    label: 'Обязательное поле',
    type: EFieldType.SWITCH,
  },
  {
    name: EFieldName.HAS_DEFAULT,
    label: 'Предварительно заполнено',
    type: EFieldType.SWITCH,
    description: 'При создании документа поле будет заполнено этими данными',
  },
]

const createDefaultValueConfig = (type: EFieldType) => ({
  name: EFieldName.DEFAULT_VALUE,
  type,
  dependsOn: EFieldName.HAS_DEFAULT,
})

const createHasHintConfig = () => ({
  name: EFieldName.HAS_HINT,
  label: 'Подсказка',
  type: EFieldType.SWITCH,
})

const createHintConfig = () => ({
  name: EFieldName.HINT,
  type: EFieldType.STRING,
  dependsOn: EFieldName.HAS_HINT,
})

const configArrayDate = [
  ...commonConfigurations,
  createDefaultValueConfig(EFieldType.DATE),
  createHasHintConfig(),
  createHintConfig(),
]

const configArrayTextArea = [
  ...commonConfigurations,
  createDefaultValueConfig(EFieldType.TEXT_AREA),
  createHasHintConfig(),
  createHintConfig(),
]

const configArrayDefault = [
  ...commonConfigurations,
  createDefaultValueConfig(EFieldType.STRING),
  createHasHintConfig(),
  createHintConfig(),
]

const configArrayCheckbox = [
  ...commonConfigurations,
  createDefaultValueConfig(EFieldType.CHECKBOX),
  createHasHintConfig(),
  createHintConfig(),
]

const configArrayDropdown = [
  ...commonConfigurations.slice(0, 1), // is_required and has_default
  createHasHintConfig(),
  createHintConfig(),
]

export const configFields = {
  [ESystemVariableType.DROPDOWN]: configArrayDropdown,
  [ESystemVariableType.INPUT_TEXT]: configArrayDefault,
  [ESystemVariableType.INPUT_DATE]: configArrayDate,
  [ESystemVariableType.TEXT_AREA]: configArrayTextArea,
  [ESystemVariableType.CHECKBOX]: configArrayCheckbox,
}
