import { hls } from "@diaskappassov/hungry-local-storage"
import { ERecipientRole } from "helper/consts"
import { EServiceWorkflowStepType } from "helper/workflow.service.types"
import { CWorkflow, EWorkflowMode, IWorkflowStepsMode, workflowStepsModeFor } from "./CWorkflow"
import { CRecipientAnonymousApproverRK } from "./recipients/CRecipientAnonymousApproverRK"
import { CRecipientAnonymousSignerRK } from "./recipients/CRecipientAnonymousSignerRK"
import { CRecipientApproverRK } from "./recipients/CRecipientApproverRK"
import { CRecipientFiller } from "./recipients/CRecipientFiller"
import { CRecipientSignerRK } from "./recipients/CRecipientSignerRK"
import { IRecipient } from "./recipients/IRecipient"
import { RecipientPrirority } from "./recipients/const"
import { CStepForm } from "./steps/CStepForm"
import { CStepSign } from "./steps/CStepSign"
import { IStep } from "./steps/IStep"
import { CWorkflowHelper } from "./CWorkflowHelper"

const RecipientsClassesByRole = {
  [ERecipientRole.filler]: CRecipientFiller,
  [ERecipientRole.approver_rk]: CRecipientApproverRK,
  [ERecipientRole.anonymous_approver_rk]: CRecipientAnonymousApproverRK,
  [ERecipientRole.signer_rk]: CRecipientSignerRK,
  [ERecipientRole.anonymous_signer_rk]: CRecipientAnonymousSignerRK,
}


const StepsClassesByType = {
  [EServiceWorkflowStepType.sign]: CStepSign,
  [EServiceWorkflowStepType.form]: CStepForm,
}

export class CWorkflowBuilder {
  /**
   * BuildWorkflow - копирует `obj` и собирает CWorkflow
   * @param obj 
   * @param flush - промывает workflow удаляя не правильно созданные елементы
   * @returns 
   */
  static BuildWorkflow(obj: any, flush?: boolean): CWorkflow {
    const copiedObj = JSON.parse(JSON.stringify(obj))

    const steps = this.BuildSteps(copiedObj.steps ?? [])
    const _stepsMode = this.RecognizeWorkflowModeBySteps(steps)

    const workflow = new CWorkflow({
      actors: copiedObj.actors,
      _stepsMode: _stepsMode,
      steps: steps,
      meta: copiedObj.meta,
      cc: copiedObj.cc,
    })

    if (flush) CWorkflowHelper.FlushByActualActors(workflow)
    return workflow
  }


  static BuildSteps(obj): IStep[] {
    const steps: IStep[] = []
    for (let i = 0; i < obj.length; i++) {
      const step = this.BuildStep(obj[i])
      if (step) steps.push(step)
    }
    return steps
  }


  static BuildStep(obj): IStep | undefined {
    obj.recipients = this.BuildRecipients(obj.recipients)

    if (obj.type === undefined) return new CStepSign(obj)
    if (!StepsClassesByType[obj.type]) return undefined
    return new StepsClassesByType[obj.type](obj)
  }


  static BuildRecipients(obj): IRecipient[] {
    const recipients: IRecipient[] = []
    for (let i = 0; i < obj.length; i++) {
      const recipient = this.BuildRecipient(obj[i])
      if (recipient) recipients.push(recipient)
    }
    return recipients
  }


  static BuildRecipient(obj): IRecipient | undefined {
    if (!RecipientsClassesByRole[obj.role]) return undefined
    return new RecipientsClassesByRole[obj.role](obj)
  }


  static RecognizeWorkflowModeBySteps(steps: IStep[]): IWorkflowStepsMode {
    const result = {
      approvers: EWorkflowMode.default,
      signers: EWorkflowMode.default,
    }
    if (steps.length <= 1) return result

    for (let i = 0; i < steps.length - 1; i++) {
      const curR = steps[i].recipients[0].role
      const nextR = steps[i + 1].recipients[0].role
      if (workflowStepsModeFor[curR] != workflowStepsModeFor[nextR]) continue

      const lastPriority = RecipientPrirority[steps[i].recipients[0].role]
      const nextPriority = RecipientPrirority[steps[i + 1].recipients[0].role]

      if (lastPriority === nextPriority) result[workflowStepsModeFor[curR]] = EWorkflowMode.ordered
    }
    return result
  }


  static GenerateWorkflowLocalStorageKey = (id: string) => `/documents/${id}/workflow`

  static SaveWorkflowToLocalStorage(id: string, data: CWorkflow) {
    const exported: any = data.restorableExport()
    hls.set(this.GenerateWorkflowLocalStorageKey(id), exported, {
      weeks: 2,
    })
  }

  static GetWorkflowFromLocalStorage = (id: string) => {
    const data = hls.get(this.GenerateWorkflowLocalStorageKey(id))
    if (!data) return null
    return CWorkflowBuilder.BuildWorkflow(data, true)
  }

  static RemoveWorkflowFromLocalStorage = (id: string) => {
    hls.remove(this.GenerateWorkflowLocalStorageKey(id))
  }
}