import { TSRolePermissions } from 'helper/roles'
import { useCallback } from 'react'
import { useAppSelector } from 'store/store'

export const usePermissionDocumentRevokeInTeamspace = () => {
  const { user } = useAppSelector((store) => store.user)
  const { selectedTeamspace } = useAppSelector((s) => s.teamspace)

  const canDoOne = useCallback(
    (document: any) => {
      if (!document) return false
      if (document.author_email == user.email) return true
      if (
        !!selectedTeamspace &&
        TSRolePermissions[selectedTeamspace?.group_name]
          .canRevokeStrangerDocuments
      )
        return true
      return false
    },
    [document, user?.email, selectedTeamspace]
  )

  const canDoAll = useCallback(
    (documents: any[]) => {
      for (let i = 0; i < documents.length; i++) {
        const doc = documents[i]
        if (!canDoOne(doc)) {
          return false
        }
      }
      return true
    },
    [canDoOne]
  )

  return { canDoOne, canDoAll }
}
