import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getCampaignInfo, getCampaigns } from 'service'
import { getTemplateVariables } from 'service/template'

export const getCampaignTemplateVariables = createAsyncThunk(
  'campaign/getCampaignTemplateVariables',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const responseVariables = await getTemplateVariables({ templateId: id })
      const { variables } = responseVariables.data
      const checkedVariables = Object.keys(variables).reduce((acc, item) => {
        acc[item] = true
        return acc
      }, {})

      return {
        variables,
        checkedVariables,
      }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const getExampleCampaign = createAsyncThunk(
  'campaign/getExampleCampaign',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const res = await getCampaignInfo({ id })
      const { documents_count, unique_emails_count } = res.data
      return { documents_count, unique_emails_count }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateCampaigns = createAsyncThunk(
  'campaign/updateCampaigns',
  async (
    {
      page,
      ts_id,
    }: {
      page: number
      ts_id: string
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await getCampaigns({
        ts_id,
        page,
      })
      const { campaigns, count } = res.data
      return { campaigns, count, page }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

const initialState: ICampaignState = {
  id: '',
  file: null,
  variables: null,
  campaign_name: '',
  error: '',
  isPrivateTemplate: false,
  loading: false,
  checked_variables: null,
  current_step: 0,
  iin: true,
  success_count: '0',
  campaign_id: '',
  campaigns: [],
  count: 0,
  page: 1,
  is_draft_created: false,
  unique_emails_count: 0,
  documents_count: 0,
}
// Slice for campaign
const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    updateCampaign: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },

    removeDraftMsgCampaign: (state) => {
      state.is_draft_created = false
      state.loading = false
    },
    createDraftCampaign: (state) => {
      state.is_draft_created = true
      state.loading = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaignTemplateVariables.pending, (state) => {
        state.loading = true
      })
      .addCase(getCampaignTemplateVariables.fulfilled, (state, action) => {
        state.variables = action.payload.variables
        state.checked_variables = action.payload.checkedVariables
        state.loading = false
      })
      .addCase(getCampaignTemplateVariables.rejected, (state, action) => {
        state.error = action.error.message || 'Ошибка'
        state.loading = false
      })
      .addCase(getExampleCampaign.pending, (state) => {
        state.loading = true
      })
      .addCase(getExampleCampaign.fulfilled, (state, action) => {
        state.unique_emails_count = action.payload.unique_emails_count
        state.documents_count = action.payload.documents_count
        state.loading = false
      })
      .addCase(getExampleCampaign.rejected, (state, action) => {
        state.error = action.error.message || 'Ошибка'
        state.loading = false
      })
      .addCase(updateCampaigns.pending, (state) => {
        state.loading = true
      })
      .addCase(updateCampaigns.fulfilled, (state, action) => {
        state.campaigns = action.payload.campaigns
        state.count = action.payload.count
        state.page = action.payload.page
        state.loading = false
      })
      .addCase(updateCampaigns.rejected, (state, action) => {
        state.error = action.error.message || 'Ошибка'
        state.campaigns = []
        state.count = 0
        state.page = 0
        state.loading = false
      })
  },
})

export const { removeDraftMsgCampaign, createDraftCampaign, updateCampaign } =
  campaignSlice.actions

export default campaignSlice.reducer
