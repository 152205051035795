import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { EOnboardingAction } from 'helper/consts'
import {
  changeAvatar,
  changeSurvey,
  changeUserInfo,
  getMe,
  resetOnboarding,
  skipOnboarding,
  updateOnboardingStep,
} from 'service/user'
import { clearTeamspaces, selectTeamspace } from 'store/slices/teamspaceSlice'
import { clearWorkspaces } from 'store/slices/workspace'
import { selectedWorkspaceSlice } from './selectedWorkspace'

export const resetOnboardingAction = createAsyncThunk(
  'user/resetOnboardingAction',
  async (_, { rejectWithValue }) => {
    try {
      await resetOnboarding()
      const res = await getMe()

      return {
        onboarding: {
          ...res.data.onboarding,
          completed_steps: 0,
        },
      }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const skipOnboardingAction = createAsyncThunk(
  'user/skipOnboardingAction',
  async (_, { rejectWithValue }) => {
    try {
      await skipOnboarding()
      const res = await getMe()

      return {
        onboarding: {
          ...res.data.onboarding,
          completed_steps: 3,
        },
      }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateOnboardingAction = createAsyncThunk(
  'user/updateOnboardingAction',
  async ({ name }: { name: EOnboardingAction }, { rejectWithValue }) => {
    try {
      await updateOnboardingStep({ name })
      const res = await getMe()
      const completed_steps = res.data.onboarding
        ? countCompletedSteps(res.data.onboarding)
        : 0

      return {
        onboarding: {
          ...res.data.onboarding,
          completed_steps,
        },
      }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateAvatar = createAsyncThunk(
  'user/updateAvatar',
  async (data: any, { rejectWithValue }) => {
    try {
      const formData = new FormData()

      for (const key in data) {
        formData.append(key, data[key])
      }

      await changeAvatar(data)
    } catch (error: any) {
      rejectWithValue(error.message)
    }
  }
)

export const updateUserInfo = createAsyncThunk(
  'user/updateUserInfo',
  async (
    data: { first_name: string; last_name: string },
    { rejectWithValue }
  ) => {
    try {
      await changeUserInfo({ personal: data })
      return data
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const sendSurvey = createAsyncThunk(
  'user/sendSurvey',
  async (data: any, { rejectWithValue }) => {
    try {
      await changeSurvey(data)
    } catch (error: any) {
      rejectWithValue(error.message)
    }
  }
)

const countCompletedSteps = (onboarding) => {
  let completedSteps = 0
  if (onboarding) {
    completedSteps = !onboarding.skip
      ? Object.values(onboarding).filter((step: any) => step && step.finished)
        .length
      : 3
  }
  return completedSteps
}

export const getProfileInfo = createAsyncThunk(
  'user/getProfileInfo',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getMe()
      const completed_steps = res.data.onboarding
        ? countCompletedSteps(res.data.onboarding)
        : 0
      return {
        personal: res.data.personal,
        onboarding: {
          ...res.data.onboarding,
          completed_steps,
        },
      }
    } catch (error: any) {
      rejectWithValue(error.message)
    }
  }
)

export const checkLogin = createAsyncThunk(
  'user/checkLogin',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getMe()

      const completed_steps = res.data.onboarding
        ? countCompletedSteps(res.data.onboarding)
        : 0

      return {
        onboarding: {
          ...res.data.onboarding,
          completed_steps,
        },
        user: res.data.user,
        personal: res.data.personal,
      }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getMe()

      return {
        user: res.data.user,
        personal: res.data.personal,
      }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async (_, { dispatch }) => {
    localStorage.removeItem('token')
    dispatch(clearWorkspaces() as any)
    dispatch(clearTeamspaces() as any)
    dispatch(selectedWorkspaceSlice.actions.setSelectedWorkspace(undefined) as any)
    dispatch(selectTeamspace(null) as any)
  }
)

const initialState: IUserState = {
  authenticated: false,
  loading: true,
  error: '',
  avatar: {
    error: '',
    loading: false,
    src: '',
  },
  user: {
    email: '',
    id: '',
  },
  personal: {
    first_name: '',
    last_name: '',
  },
  onboarding: {
    skip: false,
    completed_steps: 0,
    document_create: {
      finished: false,
    },
    document_send: {
      finished: false,
    },
    document_sign: {
      finished: false,
    },
    feature_template: {
      finished: false,
    }
  },
  profile: {
    error: '',
    loading: false,
  },
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    clearErrorsLogin: (state) => {
      state.error = ''
      state.loading = false
    },
    clearErrorsProfile: (state) => {
      state.profile.error = ''
    },
    authError: (state, action) => {
      state.error = action.payload
      state.loading = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetOnboardingAction.fulfilled, (state, action) => {
        state.onboarding = action.payload.onboarding
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.authenticated = false
        state.loading = false
      })
      .addCase(checkLogin.fulfilled, (state, action) => {
        state.authenticated = true
        state.loading = false
        state.user = action.payload?.user || { email: '', id: '' }
        state.personal = action.payload?.personal || {
          first_name: '',
          last_name: '',
          name: '',
        }
        state.onboarding = action.payload?.onboarding
      })
      .addCase(checkLogin.rejected, (state) => {
        state.authenticated = false
        state.loading = false
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.authenticated = true
        state.loading = false
        state.user = action.payload?.user || { email: '', id: '' }
        state.personal = action.payload?.personal || {
          first_name: '',
          last_name: '',
          name: '',
        }
      })
      .addCase(loginUser.rejected, (state) => {
        state.loading = false
        state.authenticated = false
      })
      .addCase(getProfileInfo.pending, (state) => {
        state.profile.loading = true
      })
      .addCase(getProfileInfo.fulfilled, (state, action) => {
        state.personal = action.payload?.personal || {
          first_name: '',
          last_name: '',
          name: '',
        }
        state.onboarding = action.payload?.onboarding
        state.profile.loading = false
      })
      .addCase(getProfileInfo.rejected, (state, action) => {
        state.profile.error = action.error.message || 'Произошла ошибка'
        state.profile.loading = false
      })
      .addCase(sendSurvey.rejected, (state, action) => {
        state.profile.error = action.error.message || 'Произошла ошибка'
      })
      .addCase(updateUserInfo.fulfilled, (state, action) => {
        state.personal.first_name = action.payload?.first_name || ''
        state.personal.last_name = action.payload?.last_name || ''
      })
      .addCase(updateUserInfo.rejected, (state, action) => {
        state.profile.error = action.error.message || 'Произошла ошибка'
      })
      .addCase(updateAvatar.pending, (state) => {
        state.avatar.loading = true
      })
      .addCase(updateAvatar.rejected, (state, action) => {
        state.avatar.error = action.error.message || 'Ошибка загрузки аватара'
      })
      .addCase(updateOnboardingAction.fulfilled, (state, action) => {
        state.onboarding = action.payload.onboarding
      })
      .addCase(skipOnboardingAction.fulfilled, (state, action) => {
        state.onboarding = action.payload.onboarding
      })
  },
})

export const { authError, clearErrorsLogin, clearErrorsProfile } =
  userSlice.actions

export default userSlice.reducer
