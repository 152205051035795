import { useSnackbar } from 'components/snackbar/hook'
import { useCallback, useState } from 'react'

/**
 * Hook which provides a function to download files
 * @param downloadFunc - download function which returns { url: string, filename: string }
 * @returns hooks thats required to download file
 */
export function useDownloader<T>(
  downloadFunc: (props: T) => Promise<{ url: string; filename: string }>
) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { showSnackbar, closeSnackbar, snackbar } = useSnackbar()

  const download = useCallback(
    async (props: T, name?: string) => {
      let downloadLink: string = ''

      try {
        setLoading(true)
        const { url, filename } = await downloadFunc(props)
        downloadLink = url
        const link = document.createElement('a')
        link.href = url
        link.download = name ?? filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (err: any) {
        setError(err?.message || 'Ошибка')
        showSnackbar({
          message: err.message,
          severity: 'error',
          close: closeSnackbar,
        })
      } finally {
        setLoading(false)
      }

      return downloadLink
    },
    [setLoading, setError, downloadFunc]
  )

  return { download, loading, error, setError, snackbar }
}
