import { Pagination } from 'components'
import { useChangePageContext } from 'context'
import { useSelector, shallowEqual } from 'react-redux'

export const Footer = () => {
  const { changePage } = useChangePageContext()
  const { page, count, filter } = useSelector(
    (state: RootState) => state.campaignDocs,
    shallowEqual
  )

  return (
    <Pagination
      changePage={changePage}
      page={page}
      count={count}
      filter={filter}
    />
  )
}
