import { TableRow, TableCell, CircularProgress } from '@mui/material'
import { TableCompound } from 'components/table/table-compound/TableCompound'

export const TableRowComponentLoading = ({
  isPrivateTemplate,
}: {
  isPrivateTemplate: boolean
}) => {
  return (
    <TableRow>
      <TableCell>{<CircularProgress color="secondary" />}</TableCell>
      <TableCell></TableCell>
      {isPrivateTemplate && <TableCell></TableCell>}
      <TableCell></TableCell>
      <TableCompound.BodyCellDocumentOptions></TableCompound.BodyCellDocumentOptions>
    </TableRow>
  )
}
