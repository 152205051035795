import img_copy from 'assets/img/content_copy.svg'

import { MessageSnackbar } from 'components'
import { MenuItemButton } from 'components/menu-item/menu-item-button'
import { useState } from 'react'
import { queryDocumentsCreateCopy } from 'service/documents'

export const MenuItemButtonDublicateDocument = ({
  documentId,
  onSuccessCallback,
}: {
  documentId: string
  onSuccessCallback?: (documentId: string) => Promise<any> | any
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleClick = async () => {
    try {
      setLoading(true)
      const res = await queryDocumentsCreateCopy({ documentId })
      if (onSuccessCallback) await onSuccessCallback(res.data.document_id)
    } catch (err: any) {
      setError(err?.message || 'Произошла ошибка')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <MenuItemButton
        onClick={handleClick}
        title={loading ? 'Идет загрузка...' : 'Дубликат'}
        srcImage={img_copy}
        disabled={loading}
      />
      <MessageSnackbar
        severity={'error'}
        message={error}
        clearMessage={() => setError('')}
      />
    </>
  )
}
