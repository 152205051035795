import { EDocumentStatus, UrlKeys } from 'helper/consts'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useActionTemplateUse = () => {
  const navigate = useNavigate()

  const run = useCallback(
    ({
      status,
      teamspaceId,
      templateId,
    }: {
      status: string
      teamspaceId: string
      templateId: string
    }) => {
      if (status == EDocumentStatus.DRAFT) {
        navigate(`/teamspace/${teamspaceId}/templates/${templateId}`)
      } else {
        navigate(
          `/teamspace/${teamspaceId}/documents/draft?${UrlKeys.templateId}=${templateId}`
        )
      }
    },
    [navigate]
  )

  return { run }
}
