import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { ModalCenterSmall } from 'components/modal'
import { FormikContext, useFormik } from 'formik'
import { isMobile } from 'react-device-detect'
import * as yup from 'yup'
import { FormikFormWebhook, IFormikFieldWebhookURL } from '../FormikFormWebhook'
import { IFormikFieldWebhookHeaders } from '../FormikFormWebhookAddHeaders'
import { IFormikFieldWebhookSecret } from '../FormikFormWebhookSecretKey'
import { integrationsApi } from 'service/integrations'
import { useState } from 'react'
import { MessageSnackbar } from 'components'
import { IIntegrationWebhookAttrs } from 'pages/manage-template/components/ManageTemplateTabContent/TabContentIntegrations/types'

const getInitalValues = (
  values?: IIntegrationWebhookAttrs
): TFormikFormIntegrationWebhook => {
  if (!values) {
    return {
      url: '',
    }
  }

  return {
    url: values.webhook_url,
    secret: !values.webhook_secret ? undefined : values.webhook_secret,
    headers: values.webhook_headers
      ? Object.keys(values.webhook_headers).map((key) => {
          return {
            name: key,
            value: values.webhook_headers?.[key] ?? '',
          }
        })
      : undefined,
  }
}

export type TFormikFormIntegrationWebhook = IFormikFieldWebhookURL &
  IFormikFieldWebhookSecret &
  IFormikFieldWebhookHeaders

export const ModalIntegrationWebhook = ({
  open,
  handleClose,

  templateId,
  values,

  onSuccessCallback,
}: {
  open: boolean
  handleClose: any

  templateId: string
  values?: IIntegrationWebhookAttrs & {
    integrationId: string
  }

  onSuccessCallback?: () => Promise<any> | any
}) => {
  const [error, setError] = useState('')

  const formik = useFormik<TFormikFormIntegrationWebhook>({
    initialValues: getInitalValues(values),
    validationSchema: yup.object().shape({
      url: yup
        .string()
        .url('Введите корректный адрес')
        .required('Обьязательное поле'),
      headers: yup.array().of(
        yup.object().shape({
          name: yup.string().required('Поле не может быть пустым'),
        })
      ),
    }),
    onSubmit: async (submittingValues) => {
      try {
        const data = {
          webhook_url: submittingValues.url,
          webhook_secret: submittingValues.secret,
          webhook_headers: submittingValues.headers?.reduce(
            (fields, header) => {
              return { ...fields, [header.name]: header.value }
            },
            {}
          ),
        }

        if (values) {
          await integrationsApi.putIntegrationsWebhook({
            integrationId: values.integrationId,
            data: data,
          })
        } else {
          await integrationsApi.postTemplatesIntegrationsWebhook({
            templateId: templateId,
            data: data,
          })
        }

        handleClose()
        if (onSuccessCallback) await onSuccessCallback()
      } catch (err: any) {
        console.error(err)
        setError(err.message ?? 'Ошибка с сохранением интергации')
      }
    },
    enableReinitialize: true,
  })

  return (
    <>
      <MessageSnackbar
        message={error}
        clearMessage={() => setError('')}
        severity={'error'}
      />
      <ModalCenterSmall
        open={open}
        handleClose={handleClose}
        header={{
          children: (
            <Typography variant="heading5">
              {values ? 'Изменить' : 'Новый'} Webhook
            </Typography>
          ),
        }}
        content={{
          children: (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              {!values && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <Typography>
                    Webhook позволяет вам подписаться на события, которые
                    происходят с документами из этого шаблона.
                  </Typography>
                  {/* TODO: Али должен дать ссылку */}
                  {/* <Link href={'TODO'} target="_blank" color={'inherit'}>
                <Typography>Подробнее про Webhook</Typography>
              </Link> */}
                </Box>
              )}
              <FormikContext.Provider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                  <FormikFormWebhook />
                </form>
              </FormikContext.Provider>
            </Box>
          ),
        }}
        footer={{
          children: (
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'end',
                  gap: '8px',
                }}
              >
                <Button variant="text" color="secondary" onClick={handleClose}>
                  Отменить
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={formik.isSubmitting}
                  sx={{
                    gap: '4px',
                  }}
                >
                  {formik.isSubmitting && <CircularProgress color="inherit" />}
                  {values ? 'Сохранить' : 'Создать'}
                </Button>
              </Box>
            </form>
          ),
        }}
      />
    </>
  )
}
