/**
 * @param link string like `https://yourdomain.com/home#id?param=123`
 * @returns link without scheme, example: `yourdomain.com/home#id?param=123`
 * https://stackoverflow.com/questions/42534419/examples-of-uri-url-and-urn
 */
export const getLinkURN = (link: string) => {
  if (!link) return link
  const res = link.split('://')
  if (res.length == 0) return link
  else if (res.length == 1) return res[0]
  return res[1]
}
