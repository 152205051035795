import img_global from 'assets/img/ic_global.svg'

import { Box } from '@mui/material'
import { MenuItemButtonWithToggle } from 'components/menu-item/menu-item-button/MenuItemButtonWithToggle'
import { TextFieldCopyLink } from 'components/text-fields/TextFieldCopyLink'
import { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { shareDocument, unshareDocument } from 'store/slices/applicationSlice'

export const MenuItemShareDocument = () => {
  const { document } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )

  const dispatch = useDispatch<any>()
  const [checked, setChecked] = useState(document.is_shared ?? false)
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    const st = !checked
    setLoading(true)
    try {
      if (st) {
        await dispatch(shareDocument({ id: document.id }))
      } else {
        await dispatch(unshareDocument({ id: document.id }))
      }
      setChecked(st)
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }

  return (
    <>
      <MenuItemButtonWithToggle
        srcImage={img_global}
        title={'Поделиться документом'}
        description={'У всех у кого есть ссылка смогут просматривать документ'}
        onClick={handleClick}
        checked={checked}
        disabled={loading}
      />
      {checked && (
        <Box
          sx={{
            p: '2px 16px',
          }}
        >
          <TextFieldCopyLink
            value={`https://link.doodocs.kz/${document.share_link}`}
            placehoder="Загрузка..."
            disabled={loading}
          />
        </Box>
      )}
    </>
  )
}
