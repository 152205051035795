import { TableRow, TableCell, Box, InputBase } from '@mui/material'
import { CustomSelect } from 'pages/members'
import { useSelector, shallowEqual } from 'react-redux'
import { DeleteInvitee } from './deleteInvitee'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { workspaceRoleOptions } from '../members/membersTable'

export const InvitesTableWs = () => {
  const { invites } = useSelector(
    (state: RootState) => state.workspace,
    shallowEqual
  )

  // const handleChange = (event, row) => {
  //   // change role invite
  // }

  return (
    <>
      {invites &&
        invites.map((member) => (
          <TableRow key={member.id}>
            <TableCell>{member.email}</TableCell>
            <TableCell>
              <Box
                p={'0 4px'}
                border={'1px solid #EDEDEC'}
                width="fit-content"
                borderRadius={'4px'}
              >
                Приглашен
              </Box>
            </TableCell>
            <TableCell>
              <CustomSelect
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxWidth: '320px',
                      maxHeight: '100%',
                      marginTop: '2px',
                    },
                  },
                }}
                input={<InputBase />}
                inputProps={{
                  sx: {
                    fontWeight: 500,
                    pr: '4px !important',
                  },
                }}
                value={member.group_name}
                // onChange={(event) => handleChange(event, member)}
                renderValue={(value: any) => {
                  return workspaceRoleOptions[value]
                    ? (workspaceRoleOptions[value].label as any)
                    : 'Удалить из пространства'
                }}
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    sx={{ width: '16px', height: '16px' }}
                  />
                )}
              >
                {/* {groups.map((group) => (
                    <MenuItem value={group.name} key={group.name}>
                      <Box
                        display={'flex'}
                        alignItems="flex-start"
                        flexDirection={'column'}
                      >
                        <Typography variant='body1'>{workspaceRoleOptions[group.name].label}</Typography>
                        <Typography
                          color={'text.secondary'}
                          whiteSpace="break-spaces"
                        >
                          {workspaceRoleOptions[group.name].desc}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))} */}
                <DeleteInvitee id={member.id} />
              </CustomSelect>
            </TableCell>
          </TableRow>
        ))}
    </>
  )
}
