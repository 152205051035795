import { Box, Divider, Typography } from '@mui/material'
import { Loading, MessageSnackbar } from 'components'
import { WSRolePermissions } from 'helper/roles'
import { withDashboardLayout } from 'layout'
import { createContext, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { clearNotifications } from 'store/slices/workspace'
import { WSSettingsHeader } from './components/header'
import { DeleteWorkspace } from './deleteWs'
import { FormWorkspaceName } from './forms/FormWorkspaceName'
import { FormOrgNameDescription } from './forms/formOrgNameDescription'
import { BlockWorkspaceLeave } from './BlockWorkspaceLeave'
import { UploadLogo } from './uploadLogo'
import { WSPreview } from './ws-preview'

export const WSPreviewContext = createContext<{
  orgName: string
  orgDescription: string
  avatarFile?: any
  setAvatarFile?: any
  setOrgName?: any
  setOrgDescription?: any
}>({
  orgName: '',
  orgDescription: '',
})

const WSSettingsContent = () => {
  const { notification } = useSelector(
    (state: RootState) => state.workspace,
    shallowEqual
  )
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const [orgName, setOrgName] = useState(selectedWorkspace?.public_name || '')
  const [orgDescription, setOrgDescription] = useState(
    selectedWorkspace?.public_description || ''
  )
  const [avatarFile, setAvatarFile] = useState(selectedWorkspace?.picture_link)

  const { tsId } = useParams()

  const dispatch = useDispatch()

  const getPermissions = useMemo(
    () =>
      (selectedWorkspace &&
        selectedWorkspace.group_name &&
        WSRolePermissions[selectedWorkspace.group_name]) ||
      {},
    []
  )

  useEffect(() => {
    setOrgDescription(selectedWorkspace?.public_description || '')
    setOrgName(selectedWorkspace?.public_name || '')
    setAvatarFile(selectedWorkspace?.picture_link || '')
    return () => dispatch(clearNotifications() as any)
  }, [tsId, selectedWorkspace])

  if (!selectedWorkspace) return <Loading />
  return (
    <>
      <MessageSnackbar
        message={notification.update?.message}
        clearMessage={() => dispatch(clearNotifications() as any)}
        severity={notification.update?.severity}
      />
      <MessageSnackbar
        message={notification.leaveWs?.message}
        clearMessage={() => dispatch(clearNotifications() as any)}
        severity={notification.leaveWs?.severity}
      />
      <MessageSnackbar
        message={notification.deleteWs?.message}
        clearMessage={() => dispatch(clearNotifications() as any)}
        severity={notification.deleteWs?.severity}
      />
      <WSPreviewContext.Provider
        value={{
          orgName,
          orgDescription,
          avatarFile,
          setAvatarFile,
          setOrgName,
          setOrgDescription,
        }}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'row' }}
          overflow={{ sm: 'hidden' }}
          height={{ sm: '100vh' }}
        >
          <Box
            p={{ xs: '16px 0 64px 16px', sm: '0 40px 64px' }}
            height="100vh"
            overflow={'scroll'}
            width={'100%'}
          >
            {getPermissions.manageWorkspaceInfo && (
              <Box mb="24px">
                <UploadLogo />
              </Box>
            )}
            {getPermissions.manageWorkspaceInfo && (
              <>
                <FormWorkspaceName />
                <Divider />
                <Box p={'24px 0'}>
                  <Typography variant="body1">
                    Данные отображаются на странице подписания для контрагентов
                  </Typography>
                </Box>
                <FormOrgNameDescription />
              </>
            )}

            {getPermissions.manageWorkspaceInfo && (
              <Box
                display={{ xs: 'flex', sm: 'none' }}
                alignItems="center"
                justifyContent={'center'}
                overflow={'none'}
              >
                <Box p={'0 0 16px'}>
                  <Typography color={'text.secondary'} mb="12px">
                    Предпросмотр профиля на странице подписания:
                  </Typography>
                  <WSPreview />
                </Box>
              </Box>
            )}

            <Divider />

            <Box mt="24px">
              <BlockWorkspaceLeave />
            </Box>
            {getPermissions.manageWorkspaceInfo && (
              <Box mt="24px">
                <DeleteWorkspace />
              </Box>
            )}
          </Box>
          {getPermissions.manageWorkspaceInfo && (
            <Box
              display={{ xs: 'none', sm: 'flex' }}
              alignItems="center"
              justifyContent={'center'}
              borderLeft={'1px solid #EDEDEC'}
              height="100vh"
              overflow={'none'}
              minWidth={{ xs: '100%', sm: '480px' }}
            >
              <Box p={'40px'}>
                <Typography color={'text.secondary'} mb="12px">
                  Предпросмотр профиля на странице подписания:
                </Typography>
                <WSPreview />
              </Box>
            </Box>
          )}
        </Box>
      </WSPreviewContext.Provider>
    </>
  )
}

export const WSSettings = () => {
  return withDashboardLayout(WSSettingsContent)({
    Header: WSSettingsHeader,
  })
}
