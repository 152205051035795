import { useRef } from 'react'

export const useDebounce = () => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>()

  const debounce = (func: () => void, timeout: number) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(func, timeout)
  }

  return debounce
}
