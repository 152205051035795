import { createContext, useCallback, useMemo, useState } from "react"
import { getTemplateInfo, queryTemplatesCreateCopy } from "service/template"

type TemplateDublicate = ITemplate | null | undefined

interface ITemplatesRegistryDublicateTemplateContext {
  /**
   * Массив значения может хранить 3 разных состояний:
   * на значении undfined - пусто, значит была ошибка;
   * на значении null - идет загрузка, пока значение не было проставлено;
   * на значении ITemplate - дубликат документа создан;
   */
  dublicates: TemplateDublicate[]
  clearDublicates: () => any
  createDublicates: ({ templateIds }: { templateIds: string[] }) => Promise<any>
  error: string
  clearError: () => any
}

/**
 * TemplatesRegistryDublicateTemplateContext - Контекст с необходимыми данными для дублирования документов в реестре
 */
export const TemplatesRegistryDublicateTemplateContext = createContext<ITemplatesRegistryDublicateTemplateContext>(
  {} as any
)

/**
 * useTemplatesRegistryDublicateTemplateContextDefaultValue - дает дефолтное значение для контекста дублипования докуемнтов
 * @returns 
 */
export function useTemplatesRegistryDublicateTemplateContextDefaultValue(): ITemplatesRegistryDublicateTemplateContext {
  const [dublicates, setDublicates] = useState<
    TemplateDublicate[]
  >([])

  const [error, setError] = useState('')
  const clearError = useCallback(() => setError(''), [setError])

  const countDublicates = useMemo(() => {
    let count = dublicates?.length || 0
    return {
      getCount: () => count,
      setCount: (n: number) => {
        count = n
      },
      addCount: (n: number) => {
        count += n
      },
    }
  }, [])

  /**
   * controller - используется чтобы после сброса дубликатов, отложенные функции перестали взаимодействовать с хуком для дубликатов
   */
  const controller = useMemo(() => {
    let controlNumber: number = 0

    return {
      clearDublicates: () => {
        controlNumber++
        setDublicates([])
        countDublicates.setCount(0)
      },
      getControl: () => controlNumber,
      canUpdateDublicate: (n: number) => n == controlNumber,
    }
  }, [])

  const createDublicates = async ({
    templateIds,
  }: {
    templateIds: string[]
  }) => {
    const countDublicatesBefore = countDublicates.getCount()
    countDublicates.addCount(templateIds.length)
    const dublicatesCountAfter = countDublicates.getCount()
    const controlNumber = controller.getControl()
    const stillActual = () => controller.canUpdateDublicate(controlNumber)
    try {
      const promises: any[] = []

      const createDocumentDublicate = async (id, i) => {
        const curIdx = dublicatesCountAfter - (i + 1)
        if (stillActual())
          setDublicates((values) => {
            return [...values, null]
          })

        const resCopy = await queryTemplatesCreateCopy({ templateId: id })

        if (!stillActual()) return

        const resDoc = await getTemplateInfo({ id: resCopy.data.template_id })

        if (!stillActual()) return

        setDublicates((values) => {
          values[curIdx] = resDoc.data.template as any
          return [...values]
        })
      }

      for (let i = 0; i < templateIds.length; i++) {
        const docId = templateIds[i];
        promises.push(createDocumentDublicate(docId, i))
      }

      await Promise.allSettled(promises).then(values => {
        const valsRej = values.filter(v => v.status == 'rejected')
        if (valsRej.length == 0) return
        if (promises.length > valsRej.length) {
          throw new Error("Не удалось создать дубликат для некоторых шаблонов")
        } else if (valsRej.length == 1) {
          throw new Error("Не удалось создать дубликат шаблона")
        } else if (promises.length == valsRej.length) {
          throw new Error("Не удалось создать дубликат шаблонов")
        }
      })

    } catch (err: any) {
      console.error(err)
      if (!stillActual()) return
      setError(err?.message || 'Не удалось дублировать некоторые шаблоны')
      setDublicates((values) => {
        return values.map((value, i) => {
          if (i < countDublicatesBefore) return value
          if (i >= dublicatesCountAfter) return value
          if (value != null) return value
          return undefined
        })
      })
    }
  }

  return {
    dublicates,
    clearDublicates: controller.clearDublicates,
    createDublicates,
    error,
    clearError,
  }
}