import { Grid, TextField, Button, CircularProgress } from '@mui/material'
import { useFormik } from 'formik'
import { useSearchParams } from 'react-router-dom'
import { getTokenEmail } from 'service/auth'
import { validationSchemas } from '../loginForm'
import { useSendCode } from 'pages/login/hooks/useSendCode'
import { getUtmParams } from 'utils/get-utm-params'

export const VerificationCodeForm = ({ email }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { handleSendCode, loadingCode } = useSendCode()

  const handleEmailCode = async () => {
    const params = {
      email: email,
      code: formikCode.values.code,
      ...getUtmParams(searchParams),
    }
    searchParams.delete('otp')
    setSearchParams(searchParams)
    await handleSendCode(getTokenEmail, { ...params, login_source: 'email' })
  }

  const formikCode = useFormik({
    initialValues: {
      code: searchParams.get('otp') || '',
    },
    validationSchema: validationSchemas[1],
    onSubmit: handleEmailCode,
  })

  return (
    <form onSubmit={formikCode.handleSubmit}>
      <Grid item xs={12}>
        <TextField
          value={formikCode.values.code}
          name="code"
          onChange={formikCode.handleChange}
          fullWidth
          placeholder="Введите код из письма..."
        ></TextField>
      </Grid>
      <Grid item xs={12} mt={'12px'}>
        <Button
          type="submit"
          fullWidth
          color="secondary"
          variant="outlined"
          disabled={loadingCode ? true : false}
        >
          {loadingCode ? <CircularProgress /> : null} Продолжить
        </Button>
      </Grid>
    </form>
  )
}

// todo merge this 2 components
export const VerificationCodeFormWithSendTally = ({ email }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { handleSendCode, loadingCode } = useSendCode()

  const handleEmailCode = async () => {
    const params = {
      email: email,
      code: formikCode.values.code,
      full_name: searchParams.get('user_name') || '',
      phone: searchParams.get('phone') || '',
      company_name: searchParams.get('company_name') || '',
      ...getUtmParams(searchParams),
    }
    searchParams.delete('otp')
    setSearchParams(searchParams)
    await handleSendCode(getTokenEmail, { ...params, login_source: 'email' })
  }

  const formikCode = useFormik({
    initialValues: {
      code: searchParams.get('otp') || '',
    },
    validationSchema: validationSchemas[1],
    onSubmit: handleEmailCode,
  })

  return (
    <form onSubmit={formikCode.handleSubmit}>
      <Grid item xs={12}>
        <TextField
          value={formikCode.values.code}
          name="code"
          onChange={formikCode.handleChange}
          fullWidth
          placeholder="Введите код из письма..."
        ></TextField>
      </Grid>
      <Grid item xs={12} mt={'12px'}>
        <Button
          type="submit"
          fullWidth
          color="secondary"
          variant="outlined"
          disabled={loadingCode ? true : false}
        >
          {loadingCode ? <CircularProgress /> : null} Продолжить
        </Button>
      </Grid>
    </form>
  )
}
