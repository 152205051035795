import { Box, Button, Typography } from '@mui/material'
import img_delete from 'assets/img/delete_outline.svg'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { useActionDeleteAPISecretKey } from 'features/delete-api-secret-key/lib/useActionDeleteAPISecretKey'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { ButtonCustom } from 'shared/components/buttons/ButtonCustom'

export const ButtonOpenModalDeleteApiSecretKey = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void
  onError?: TFuncOnError
}) => {
  const action = useActionDeleteAPISecretKey({
    onSuccess,
    onError,
  })

  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <ModalCenterSelectableWithTitle
        open={open}
        handleClose={handleClose}
        modalType="small"
        title={'Вы уверены, что хотите удалить доступ?'}
        contentChild={
          <>
            <Typography variant="body1">
              {
                'Любым сервисам, использующим этот ключ, больше не будет пердоставлен доступ. Эту операцию невозможно отменить.'
              }
            </Typography>
            <Box
              mt={1}
              justifyContent="flex-end"
              display={'flex'}
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <Button
                sx={{ mr: '8px', mb: isMobile ? 1 : 0 }}
                color="secondary"
                onClick={handleClose}
                variant="outlined"
              >
                Отменить
              </Button>
              <ButtonCustom
                variant="outlined"
                color="error"
                type="submit"
                onClick={action.run}
                loading={action.loading} //here check the disabled
              >
                Удалить
              </ButtonCustom>
            </Box>
          </>
        }
      />
      <ButtonCustom
        variant="outlined"
        color="secondary"
        onClick={handleOpen}
        sx={{ padding: '2px 4px' }}
        prefixIcon={
          <Box
            component={'img'}
            src={img_delete}
            alt={'Delete Icon'}
            style={{ width: '16px', height: '16px' }}
          />
        }
      >
        <Typography variant="heading6">Удалить</Typography>
      </ButtonCustom>
    </>
  )
}
