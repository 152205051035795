import img_add from 'assets/img/add.svg'
import img_check from 'assets/img/check.svg'

import { Box, IconButton } from '@mui/material'
import { MessageSnackbar } from 'components'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { ModalPlanLimit } from 'components/modal/ModalPlanLimit'
import { CustomTooltip } from 'components/tooltip'
import { useFormikContext } from 'formik'
import { EFeature } from 'helper/plan.types'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  clearNotificationsTs,
  fetchTeamspaces,
} from 'store/slices/teamspaceSlice'
import { getWSPlanFeatureRestriction } from 'utils/plans'
import { InviteToGroupForm, InviteToWSForm } from './inviteToForms'
import { NewGroupForm } from './groupForm'

export const InviteModalWS = ({ open, handleClose, name }) => {
  return (
    <ModalCenterSelectableWithTitle
      open={open}
      handleClose={handleClose}
      modalType="small"
      title={`Пригласите участников в «${name}»`}
      contentChild={<InviteToWSForm handleClose={handleClose} cancel={false} />}
    />
  )
}

export const InviteModal = ({
  open,
  handleClose,
  name,
}: {
  open: boolean
  handleClose: () => void
  name: string
}) => {
  return (
    <ModalCenterSelectableWithTitle
      open={open}
      handleClose={handleClose}
      modalType="small"
      title={`Пригласите участников в «${name}»`}
      contentChild={
        <InviteToGroupForm handleClose={handleClose} cancel={false} />
      }
    />
  )
}

export const CreateGroup = () => {
  const [open, setOpen] = useState(false)
  const [hasLimit, setHasLimit] = useState(false)
  const [step, setStep] = useState(0)
  const { notification } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const { plan_info } = useSelector((state: RootState) => state.wsPlan)
  const dispatch = useDispatch()

  const handleClick = () => setOpen(true)

  const handleClose = () => {
    setOpen(false)
    setStep(0)
    dispatch(fetchTeamspaces({ id: selectedWorkspace?.id || '' }) as any)
  }

  const [name, setName] = useState('')

  useEffect(() => {
    const { prohibited } = getWSPlanFeatureRestriction(
      EFeature.teamspaces_count,
      plan_info
    )
    setHasLimit(prohibited)
  }, [plan_info])

  return (
    <>
      <MessageSnackbar
        message={notification.create?.message}
        clearMessage={() => dispatch(clearNotificationsTs() as any)}
        severity={notification.create?.severity}
      />
      <MessageSnackbar
        message={notification.create_invite?.message}
        clearMessage={() => dispatch(clearNotificationsTs() as any)}
        severity={notification.create_invite?.severity}
      />

      {hasLimit ? (
        <ModalPlanLimit
          open={open}
          handleClose={handleClose}
          limitOn={EFeature.teamspaces_count}
        />
      ) : (
        <ModalCenterSelectableWithTitle
          open={open}
          handleClose={handleClose}
          modalType="small"
          title={
            step === 0 ? 'Новая группа' : `Пригласите участников в «${name}» `
          }
          contentChild={
            <>
              {step === 0 ? (
                <NewGroupForm setName={setName} setStep={setStep} />
              ) : (
                <InviteToGroupForm handleClose={handleClose} />
              )}
            </>
          }
        />
      )}

      <CustomTooltip title={'Новая группа'} placement="right-start">
        <IconButton
          onClick={handleClick}
          sx={{ borderRadius: '4px' }}
          children={<img src={img_add} alt="" width={'16px'} height={'16px'} />}
        ></IconButton>
      </CustomTooltip>
    </>
  )
}

export const ColorButton = ({ color = 'green', ...props }) => {
  const { setFieldValue } = useFormikContext()

  const handleClickSwitchColor = () => {
    setFieldValue('color', color)
  }

  return (
    <Box
      onClick={handleClickSwitchColor}
      width={'28px'}
      height={'28px'}
      borderRadius={'4px'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        background: color,
        cursor: 'pointer',
        ...(props.selected && {
          border: '1px solid white',
          boxShadow: '0px 0px 0px 2px #DCF1FF',
        }),
        '&:hover': {
          border: '1px solid white',
          boxShadow: '0px 0px 0px 2px #DCF1FF',
        },
      }}
    >
      {props.selected && (
        <Box
          component={'img'}
          src={img_check}
          width={'16px'}
          height={'16px'}
          sx={{ filter: 'brightness(6.5)' }}
        />
      )}
    </Box>
  )
}
