import { Box, Skeleton } from '@mui/material'
import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
// TODO: ОПТИМИЗАЦИЯ РЕНДЕРИНГА PDF
export const RenderPDF = ({ src }: { src: string }) => {
  const [pages, setPages] = useState<number[]>([])

  function onDocumentLoadSuccess({ numPages }) {
    const p: number[] = []
    for (let i = 0; i < numPages; i++) {
      p.push(i + 1)
      if (i === numPages - 1) setPages(p)
    }
  }

  return (
    <Box>
      {!src ? (
        <DocumentSkeleton />
      ) : (
        <Document
          file={src}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
          loading={<DocumentSkeleton />}
        >
          {pages.map((i) => (
            <Page
              loading={<DocumentSkeleton />}
              width={650} // ? Исправляет проблему с размытием на некоторых девайсах (Временное решение)
              key={i}
              pageNumber={i}
              className="document"
            />
          ))}
        </Document>
      )}
    </Box>
  )
}

const DocumentSkeleton = () => {
  return (
    <Skeleton
      animation="wave"
      variant="rectangular"
      width={'100%'}
      height={'90vh'}
      sx={{
        bgcolor: '#F7F7F7',
        borderRadius: '8px',
        '::after': {
          background:
            'linear-gradient(90deg, transparent, #ffffff, transparent)',
        },
      }}
    />
  )
}
