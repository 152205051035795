import { EWorkspacePlan } from 'helper/plan.types'
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { sessionUtils } from 'utils/session'
import { ModalPlanLimitPlanEnded } from '../ModalPlanLimitPlanEnded'

export const ModalPlanEnded = () => {
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )
  const { plan_name, plan_info, workspace_id } = useSelector(
    (state: RootState) => state.wsPlan,
    shallowEqual
  )

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!selectedWorkspace?.id) return
    const wsId = selectedWorkspace.id
    setOpen(
      plan_name == EWorkspacePlan.Free &&
        !sessionUtils.getModalPlanEndedHasShownValue(wsId)
    )
  }, [plan_name, selectedWorkspace])

  const handleClose = () => {
    sessionUtils.setModalPlanEndedHasShown(selectedWorkspace?.id ?? '')
    setOpen(false)
  }

  if (workspace_id != selectedWorkspace?.id) return null
  if (plan_info.is_trial_available) return null
  if (!plan_info.previous_plan) return null
  return <ModalPlanLimitPlanEnded open={open} handleClose={handleClose} />
}
