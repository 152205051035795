import { Box, Button, Typography } from '@mui/material'
import { Loading, MessageSnackbar } from 'components'
import { ModalCenterMedium } from 'components/modal'
import { useWorkflowLauncherContextDefaultValue } from 'features/workflow-builder/providers/WorkflowLauncherContext'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { fileDataFetch } from 'store/slices/applicationSlice'
import { useWorkflowBuilderUIContextDefaultValue } from '../../providers/WorkflowBuilderUIContext'
import { WorkflowBuilder } from '../WorkflowBuilder'

export const ModalWorkflowLauncher = ({ open, handleClose }) => {
  const { document } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )
  const dispatch = useDispatch<any>()
  const workflowBuilderUIContextDefaultValue =
    useWorkflowBuilderUIContextDefaultValue()
  const workflowLauncherContextDefaultValue =
    useWorkflowLauncherContextDefaultValue(workflowBuilderUIContextDefaultValue)

  useEffect(() => {
    if (open) return
    if (workflowLauncherContextDefaultValue.launched)
      dispatch(fileDataFetch({ id: document.id }))
  }, [open, workflowLauncherContextDefaultValue.launched])

  return (
    <ModalCenterMedium
      open={open}
      handleClose={handleClose}
      header={{
        children: !workflowLauncherContextDefaultValue.launched &&
          !workflowLauncherContextDefaultValue.loading && (
            <Typography variant="heading5">Отправить документ</Typography>
          ),
      }}
      content={{
        children: (
          <>
            <MessageSnackbar
              message={workflowLauncherContextDefaultValue.error}
              clearMessage={() =>
                workflowLauncherContextDefaultValue.setError('')
              }
              severity={'error'}
            />
            <WorkflowLauncherModalContent
              workflowLauncherContextDefaultValue={
                workflowLauncherContextDefaultValue
              }
            />
          </>
        ),
      }}
      footer={{
        children: (
          <Box
            sx={{
              minHeight: '32px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
            }}
          >
            {!workflowLauncherContextDefaultValue.launched &&
              !workflowLauncherContextDefaultValue.loading && (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={workflowLauncherContextDefaultValue.launch}
                  disabled={!workflowLauncherContextDefaultValue.canLaunch()}
                >
                  Отправить
                </Button>
              )}
          </Box>
        ),
      }}
    />
  )
}

const WorkflowLauncherModalContent = ({
  workflowLauncherContextDefaultValue,
}: {
  workflowLauncherContextDefaultValue: ReturnType<
    typeof useWorkflowLauncherContextDefaultValue
  >
}) => {
  const minHeight = '360px'

  if (workflowLauncherContextDefaultValue.launched)
    return (
      <Box
        display={'flex'}
        flexDirection="column"
        justifyContent={'center'}
        alignItems="center"
        sx={{
          width: '100%',
          height: '100%',
          minHeight: { xs: 'auto', sm: minHeight },
        }}
      >
        <Box
          height={'80px'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography
            sx={{
              fontSize: {
                xs: '64px',
              },
            }}
          >
            🎉
          </Typography>
        </Box>
        <Box textAlign={'center'} width={'300px'}>
          <Typography variant="body1" fontWeight={500} mb="4px">
            Ваш документ отправлен!
          </Typography>{' '}
          <Typography variant="body1" color={'text.disabled'}>
            Процесс и все изменения можно отслеживать на странице документа
          </Typography>
        </Box>
      </Box>
    )

  if (workflowLauncherContextDefaultValue.loading)
    return (
      <Box
        sx={{
          minHeight: minHeight,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading />
      </Box>
    )

  return (
    <Box
      sx={{
        minHeight: minHeight,
      }}
    >
      <WorkflowBuilder
        workflowBuilderUI={
          workflowLauncherContextDefaultValue.workflowBuilderUI
        }
      />
    </Box>
  )
}
