import img_doc from 'assets/img/doc_dark.svg'
import img_email from 'assets/img/email_bold.svg'
import img_ts from 'assets/img/group_icon/blue.svg'
import img_locked from 'assets/img/private.svg'
import img_templates from 'assets/img/space_dashboard_bold.svg'

import { Box, Breadcrumbs } from '@mui/material'
import { CustomTooltip, StatusBadge } from 'components'
import { ChipGlobal } from 'components/chip/ChipGlobal'
import { EDocumentStatus } from 'helper/consts'
import { coloredFilters } from 'layout/dashboard-layout/components/groupForm'
import { isMobile } from 'react-device-detect'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { BreadcrumbsElement } from './BreadcrumbsElement'

export enum ERegistryType {
  Documents = 'documents',
  Templates = 'templates',
  MassSend = 'mass_send',
}

const RegistryTypeProps = {
  [ERegistryType.Documents]: {
    icon: img_doc,
    title: 'Документы',
  },
  [ERegistryType.Templates]: {
    icon: img_templates,
    title: 'Шаблоны',
  },
  [ERegistryType.MassSend]: {
    icon: img_email,
    title: 'Рассылки',
  },
}

export interface IBreadcrumbsBlockProps {
  registry?: {
    value: ERegistryType
    linkTo?: string
  }
  document?: {
    name: string
    status?: EDocumentStatus | string
    shared?: boolean
  }
}

export const BreadcrumbsBlock = ({
  registry,
  document,
}: IBreadcrumbsBlockProps) => {
  const { selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )
  const navigate = useNavigate()

  const isTeamspaceVisible = () => {
    if (isMobile && document) return false
    return !selectedTeamspace?.is_personal
  }

  const isRegistryVisible = () => {
    if (isMobile && document) return false
    return !!registry
  }

  return (
    <Breadcrumbs
      sx={{
        flexWrap: 'nowrap',
        minWidth: '0px',
        '& .MuiBreadcrumbs-ol': {
          flexWrap: 'nowrap',
          flexGrow: 0,
          minWidth: '0px',
        },
        '& .MuiBreadcrumbs-li': {
          minWidth: '0px',
        },
        '& .MuiBreadcrumbs-separator': {
          mx: '4px',
        },
      }}
    >
      {isTeamspaceVisible() && (
        <BreadcrumbsElement
          title={selectedTeamspace?.name ?? ''}
          prefixIconSrcImg={img_ts}
          prefixIconSx={{
            filter:
              selectedTeamspace?.attrs && selectedTeamspace?.attrs.color
                ? coloredFilters[selectedTeamspace?.attrs.color.hex]
                : '',
          }}
        />
      )}
      {isRegistryVisible() && !!registry && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <BreadcrumbsElement
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            title={RegistryTypeProps[registry.value].title ?? registry.value!}
            prefixIconSrcImg={
              (!isTeamspaceVisible() &&
                RegistryTypeProps[registry.value].icon) ||
              ''
            }
            onClick={
              !!registry.linkTo
                ? () => {
                    navigate(registry.linkTo ?? '')
                  }
                : undefined
            }
          />
          {!isTeamspaceVisible() && !document && (
            <Box
              component={'img'}
              src={img_locked}
              width={'16px'}
              height={'16px'}
              sx={{
                ml: '4px',
              }}
            />
          )}
        </Box>
      )}
      {!!document && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '0px',
          }}
        >
          <BreadcrumbsElement title={document.name} />
          {!!document.status && (
            <Box
              sx={{
                ml: '4px',
              }}
            >
              <StatusBadge status={document.status} />
            </Box>
          )}
          {!!document.shared && (
            <CustomTooltip title="Документ опубликован">
              <Box
                sx={{
                  ml: '4px',
                }}
              >
                <ChipGlobal />
              </Box>
            </CustomTooltip>
          )}
        </Box>
      )}
    </Breadcrumbs>
  )
}
