import { NCALayerClient, NCALayerError } from './NCALayer'

export class NCADoodocs extends NCALayerClient {
  async auth(password: string, storagePath: string) {
    const request = {
      module: 'kz.doodocs.moduleservice.SampleBundle',
      method: 'auth',
      args: {
        password: password,
        storagePath: storagePath,
      },
    }

    super.sendRequest(request)

    return new Promise((resolve, reject) => {
      this.setHandlersDoodocs(resolve, reject)
    })
  }

  async getKeys(
    storageName: string,
    storagePath: string,
    password: string,
    type: string
  ) {
    super.sendRequest({
      method: 'getKeys',
      args: [storageName, storagePath, password, type],
    })

    return new Promise((resolve, reject) => {
      this.setHandlersFilePath(resolve, reject)
    })
  }

  async browseKeyStore(
    storageName: string,
    fileExtension: string,
    currentDirectory: string
  ) {
    super.sendRequest({
      module: 'kz.gov.pki.knca.applet.Applet',
      method: 'browseKeyStore',
      args: [storageName, fileExtension, currentDirectory],
    })

    return new Promise((resolve, reject) => {
      this.setHandlersFilePath(resolve, reject)
    })
  }

  async sign(base64ToSign) {
    const request = {
      module: 'kz.doodocs.moduleservice.SampleBundle',
      method: 'createCAdESFromBase64',
      args: {
        keyType: 'SIGN',
        base64ToSign: base64ToSign,
        attached: false,
      },
    }
    super.sendRequest(request)

    return new Promise((resolve, reject) => {
      this.setHandlersDoodocs(resolve, reject)
    })
  }

  async logOut() {
    const request = {
      module: 'kz.doodocs.moduleservice.SampleBundle',
      method: 'logOut',
    }
    super.sendRequest(request)

    return new Promise((resolve, reject) => {
      this.setHandlersDoodocs(resolve, reject)
    })
  }

  async getBundles() {
    const request = {
      module: 'kz.gov.pki.ncalayerservices.accessory',
      method: 'getBundles',
    }
    super.sendRequest(request)

    return new Promise((resolve, reject) => {
      this.setHandlersDoodocs(resolve, reject)
    })
  }

  async getDoodocsModule() {
    const request = {
      module: 'kz.gov.pki.ncalayerservices.accessory',
      method: 'installBundle',
      symname: 'kz.doodocs.moduleservice',
    }
    super.sendRequest(request)

    return new Promise((resolve, reject) => {
      this.setHandlersDoodocs(resolve, reject)
    })
  }

  async isAuthorized() {
    const request = {
      module: 'kz.doodocs.moduleservice.SampleBundle',
      method: 'isAuthorized',
    }
    super.sendRequest(request)

    return new Promise((resolve, reject) => {
      this.setHandlersDoodocs(resolve, reject)
    })
  }

  setHandlersDoodocs(resolve, reject) {
    this.responseProcessed = false

    this.wsConnection.onerror = () => {
      if (this.responseProcessed) {
        return
      }
      this.responseProcessed = true

      reject(
        new NCALayerError(
          'Ошибка взаимодействия с NCALayer. В том случае, если на вашем компьютере не установлен NCALayer, пожалуйста установите его c портала НУЦ РК (https://pki.gov.kz/ncalayer/). Если же NCALayer установлен, но портал выдает ошибку, свяжитесь, пожалуйста, с нашей технической поддержкой.'
        )
      )
    }

    this.wsConnection.onclose = () => {
      if (this.responseProcessed) {
        return
      }
      this.responseProcessed = true

      reject(new NCALayerError('NCALayer закрыл соединение.'))
    }

    this.wsConnection.onmessage = (msg) => {
      if (this.responseProcessed) {
        return
      }
      this.responseProcessed = true

      if (this.onResponseReady) {
        this.onResponseReady(msg.data)
      }

      const response = JSON.parse(msg.data)
      if (response.body !== 'success' && !response.status) {
        reject(new Error(response.code || response.errorCode))
        return
      }
      resolve(response)
    }
  }

  setHandlersFilePath(resolve, reject) {
    this.responseProcessed = false

    this.wsConnection.onerror = () => {
      if (this.responseProcessed) {
        return
      }
      this.responseProcessed = true

      reject(
        new NCALayerError(
          'Ошибка взаимодействия с NCALayer. В том случае, если на вашем компьютере не установлен NCALayer, пожалуйста установите его c портала НУЦ РК (https://pki.gov.kz/ncalayer/). Если же NCALayer установлен, но портал выдает ошибку, свяжитесь, пожалуйста, с нашей технической поддержкой.'
        )
      )
    }

    this.wsConnection.onclose = () => {
      if (this.responseProcessed) {
        return
      }
      this.responseProcessed = true

      reject(new NCALayerError('NCALayer закрыл соединение.'))
    }

    this.wsConnection.onmessage = (msg) => {
      if (this.responseProcessed) {
        return
      }
      this.responseProcessed = true

      if (this.onResponseReady) {
        this.onResponseReady(msg.data)
      }

      const response = JSON.parse(msg.data)
      if (response.code !== '200' && response.errorCode !== 'NONE') {
        reject(new NCALayerError(`${response.errorCode}`))
        return
      }
      resolve(response)
    }
  }
}
