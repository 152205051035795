import { useCallback, useState } from 'react'
import { useDebounce } from './useDebounce'

export const useCopyToClipboard = () => {
  const debounce = useDebounce()
  const [copied, setCopied] = useState(false)

  const copyToClipboard = useCallback(
    (text: string) => {
      navigator.clipboard.writeText(text)
      setCopied(true)
      debounce(() => setCopied(false), 1000)
    },
    [setCopied, debounce]
  )

  return { copied, copyToClipboard }
}
