import { CircularProgress, Button } from '@mui/material'
import { MessageSnackbar } from 'components/snackbar'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { downloadExcelFile } from 'service/template'
import downloadImg from 'assets/img/download.svg'

export const DownloadExcel = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { id, campaign_name } = useSelector(
    (state: RootState) => state.campaign
  )

  const download = async () => {
    setLoading(true)
    try {
      const { url: href } = await downloadExcelFile({ id })
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', campaign_name + '.xlsx') //or any other extension
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      setLoading(false)
      return href
    } catch (error: any) {
      if (error instanceof Error) setError(error.message)
      else setError('Ошибка')
      setLoading(false)
    }
  }

  if (loading) return <CircularProgress />
  return (
    <>
      <MessageSnackbar
        message={error}
        clearMessage={() => setError('')}
        severity="error"
      />
      <Button onClick={download} variant={'outlined'} color={'secondary'}>
        <img src={downloadImg} style={{ marginRight: 8 }} />
        Скачать Excel-файл
      </Button>
    </>
  )
}
