import { Box, IconButton } from '@mui/material'
import img_add from 'assets/img/add_circle.svg'
import { CustomTooltip } from 'components'

export const IconButtonCirclePlus = ({
  className,
  onClick,
  tooltip,
}: {
  className?: string
  onClick?: (() => void) | any
  tooltip?: string
}) => {
  const getButton = () => {
    return (
      <IconButton
        className={className}
        onClick={onClick}
        sx={{
          p: '4px',
        }}
      >
        <Box component={'img'} src={img_add} width={'16px'} height={'16px'} />
      </IconButton>
    )
  }

  if (tooltip)
    return <CustomTooltip title={tooltip}>{getButton()}</CustomTooltip>
  return getButton()
}
