import { TableRow } from '@mui/material'
import {
  IManageTemplateDocumentHeader,
  IManageTemplateDocumentRecord,
} from 'store/slices/manageTemplateData'
import { TableBodyCellVariable } from '../TableBodyCellVariable'
import { convertDateToLocale } from 'utils/convertDateToLocale'
import { getVariableValueText } from '../../utils/getVariableValueText'

export const TableVariablesBodyRow = ({
  templateId,
  teamspaceId,
  record,
  header,
}: {
  templateId: string
  header: IManageTemplateDocumentHeader[]
  teamspaceId: string
  record: IManageTemplateDocumentRecord
}) => {
  return (
    <TableRow
      sx={{
        transition: '0.2s',
        '&:hover': {
          background: 'var(--light-grayscale-border, #EDEDEC)',
        },
      }}
    >
      <TableBodyCellVariable
        text={convertDateToLocale(record.created_at)}
        action={{
          type: 'link',
          link: `/teamspace/${teamspaceId}/documents/${record.document_id}?templateId=${templateId}`,
        }}
      />
      {header.map((column) => {
        const text = getVariableValueText(record, column)
        return (
          <TableBodyCellVariable
            text={text}
            action={{
              type: 'copy',
            }}
            key={column.variable_id}
          />
        )
      })}
    </TableRow>
  )
}
