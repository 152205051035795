import { useFormikContext } from 'formik'
import { useState, useRef } from 'react'

export const useImageUploader = (initialImage) => {
  const [image, setImage] = useState<string | null>(initialImage)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { setFieldValue } = useFormikContext<any>()
  const inputRef = useRef<HTMLInputElement>(null)

  const handleImageChange = (e, fieldName) => {
    setLoading(true)
    const file = e.target.files[0]
    const fileSize = file.size

    if (fileSize > 3 * 1024 * 1024) {
      setErrorMessage('Слишком большой файл, выберите файл меньше 3мб')
      setLoading(false)
      return
    } else {
      setErrorMessage('')
    }

    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        const image = reader.result as string
        setImage(image)
        setFieldValue(fieldName + '_string', image)
      }
      reader.readAsDataURL(file)
      setFieldValue(fieldName, file)
    }
    setLoading(false)
  }

  const handleClick = () => {
    inputRef?.current?.click()
  }

  return {
    image,
    loading,
    errorMessage,
    inputRef,
    handleImageChange,
    handleClick,
  }
}
