import img_tick from 'assets/img/check.svg'

import { Box, MenuItem, Select, Typography } from '@mui/material'
import { useContext } from 'react'
import { SelectPlanContext } from '../SelectPlanProvider'
import { planPeriodDiscounts, planPeriodNames } from 'pages/plan/types'

export const PlanPeriodSelection = () => {
  const { selectedPeriod, setPeriod } = useContext(SelectPlanContext)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Typography variant="heading6" mr={'16px'}>
        Период подписки
      </Typography>
      <Select
        sx={{
          mt: '4px',
          minWidth: '268px',
          '& .MuiSelect-select>.hidden': {
            display: 'none',
          },
        }}
        defaultValue={selectedPeriod}
        onChange={(e) => {
          setPeriod(Number(e.target.value))
        }}
      >
        {Object.keys(planPeriodNames).map((key) => {
          return (
            <MenuItem
              value={key}
              key={key}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box display={'flex'} gap={'4px'}>
                <Typography textTransform={'capitalize'}>
                  {planPeriodNames[key]}
                </Typography>
                {!!planPeriodDiscounts[key] && (
                  <Box
                    sx={{
                      borderRadius: '4px',
                      border: '1px solid var(--secondary-aqua-200, #BEE4DE)',
                      background: 'var(--secondary-aqua-100, #E8F7F0)',
                      px: '4px',
                      height: '20px',
                    }}
                  >
                    <Typography>-{planPeriodDiscounts[key]}%</Typography>
                  </Box>
                )}
              </Box>

              {selectedPeriod === Number(key) && (
                <Box
                  component={'img'}
                  src={img_tick}
                  width={'16px'}
                  className="hidden"
                />
              )}
            </MenuItem>
          )
        })}
      </Select>
    </Box>
  )
}
