import { Avatar, Box, MenuItem, Typography } from '@mui/material'
import { stringAvatar } from 'utils/stringAvatar'

export const EmailFieldMenuItem = ({
  srcImg,
  email,
  optionalText,
  onClick,
  ...props
}: {
  srcImg?: string
  email: string
  optionalText?: string
  onClick?: () => void
}) => {
  return (
    <MenuItem onClick={onClick} {...props}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Avatar
          {...(!!srcImg ? { src: srcImg } : stringAvatar(email))}
          sx={{
            width: 16,
            height: 16,
            backgroundColor: '#E7E7E6',
            border: '0.6px solid rgba(0, 0, 0, 0.1)',
            color: 'text.primary',
            fontSize: '14px',
            mr: '8px',
          }}
        />
        <Typography>{email}</Typography>
        {!!optionalText ? (
          <Typography color={'text.disabled'}>{' ' + optionalText}</Typography>
        ) : null}
      </Box>
    </MenuItem>
  )
}
