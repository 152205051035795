export enum EIntegrationType {
  google_sheets = 'GOOGLE_SHEETS',
  webhook = 'WEBHOOK',
}

export interface IIntegration {
  id: string
  created_at: string
  template_id: string
  event_type: string
  name: string
  enabled: boolean
  integration_type: EIntegrationType
  attrs: any
}

// ! GOOGLE_SHEETS
export interface IIntegrationGoogleSheets extends IIntegration {
  integration_type: EIntegrationType.google_sheets,
  attrs: {
    spreadsheet_id: string
  }
}

export interface IIntegrationWebhookAttrs {
  webhook_headers?: {
    [key: string]: string
  },
  webhook_secret?: string
  webhook_url: string
}


// ! WEBHOOK
export interface IIntegrationWebhook extends IIntegration {
  integration_type: EIntegrationType.webhook,
  attrs: IIntegrationWebhookAttrs
}

export interface IIntegrationCard {
  icon: string
  title: string
  description: string
  isAvailable?: boolean
  onClickCallback?: () => void
}
