import { $generateHtmlFromNodes } from '@lexical/html'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { EditorState, LexicalEditor } from 'lexical'
import { useContext } from 'react'
import { DocumentEditorContext } from 'hooks/useDocumentEditorContextDefaultValue'
import { useDebounce } from 'hooks/useDebounce'

export default function SaveAfterChangePlugin() {
  const { setContentHtmlWithLazySave } = useContext(DocumentEditorContext)
  const debounce = useDebounce()

  function onChange(editorState: EditorState, editor: LexicalEditor) {
    debounce(() => {
      editorState.read(async () => {
        // const textNodes = $getRoot().getAllTextNodes()
        // if (!textNodes.length) return
        const htmlString = $generateHtmlFromNodes(editor, null)
        setContentHtmlWithLazySave(htmlString)
      })
    }, 200)
  }

  return <OnChangePlugin onChange={onChange} ignoreSelectionChange={true} />
}
