import {
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
} from '@mui/material'
import { Radio } from 'components/radio'
import { useFormikContext } from 'formik'

export const FormikSelectActors = ({ label, name, options }: any) => {
  const { values, setFieldValue } = useFormikContext<any>()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, parseInt(event.target.value))
  }

  return (
    <Box>
      <Typography variant="h6" color={'text.primary'} mb="4px">
        {label}
      </Typography>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={values[name]}
          onChange={handleChange}
        >
          <Box display={'flex'}>
            {options.map((option: IActor, index) => (
              <FormControlLabel
                sx={{ ml: 0 }}
                value={option.id}
                control={<Radio sx={{ mr: 1 }} />}
                label={option.title}
                key={`${option.id}-${index}`}
              />
            ))}
          </Box>
        </RadioGroup>
      </FormControl>
    </Box>
  )
}
