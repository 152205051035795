import { api } from "config";
import { handleError } from "service";

/**
 * queryDocumentsJoinToPDF - Join multiple documents into one
 * @param files 
 * @returns bob
 */
export async function queryDocumentsJoinToPDF(files: File[], setProgress?: (progress: number) => void) {
  const formData = new FormData();

  Array.from(files).forEach(file => {
    formData.append('files[]', file);
  });

  try {
    const onUploadProgress = !!setProgress ? (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent?.total ?? 1));
      setProgress(percentCompleted / 2);
    } : undefined
    const onDownloadProgress = !!setProgress ? (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent?.total ?? 1));
      setProgress(50 + percentCompleted / 2);
    } : undefined

    const response = await api.post('/pdftools/merge', formData, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data',
        // 'Accept': 'application/pdf',
      },
      responseType: 'blob',
      onUploadProgress: onUploadProgress,
      onDownloadProgress: onDownloadProgress,
    });
    return response
  } catch (error) {
    throw handleError(error)
  }
}

/**
 * queryDocumentsMoveDocumentsToTeamspace - Move documents to another teamspace
 */
export async function queryDocumentsMoveDocumentsToTeamspace({
  documentIds,
  teamspaceId,
  setProgress,
}: {
  documentIds: string[],
  teamspaceId: string,
  setProgress?: (progress: number) => void
}) {
  try {
    const response = await api.put(
      `/teamspaces/${teamspaceId}/documents`,
      {
        document_ids: documentIds,
      },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        onDownloadProgress: !!setProgress ? (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent?.total ?? 1));
          setProgress(percentCompleted / 2);
        } : undefined,
        onUploadProgress: !!setProgress ? (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent?.total ?? 1));
          setProgress(50 + percentCompleted / 2);
        } : undefined
      },
    );
    return response
  } catch (error) {
    throw handleError(error)
  }
}

/**
 * queryDocumentsCreateCopy - Create copy of document and returns new document id
 */
export async function queryDocumentsCreateCopy({
  documentId
}: {
  documentId: string
}) {
  try {
    const response = await api.post(
      `/documents/${documentId}/copy`,
      null,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    return response
  } catch (err) {
    throw handleError(err)
  }
}