import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { featureNames, plansInfo } from 'pages/plan/types'
import { useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { ButtonPlan } from '../ButtonPlan'
import { SelectPlanContext } from '../SelectPlanProvider'
import { TextActivePlanUntilDate } from '../TextActivePlanUntilDate'
import { PriceBlock } from './PriceBlock'
import { FeatureLimit } from './FeatureLimit'

export const PlanTable = () => {
  const { selectedPeriod, setPlan } = useContext(SelectPlanContext)
  const { plan_name } = useSelector(
    (state: RootState) => state.wsPlan,
    shallowEqual
  )

  return (
    <Table>
      <TableHead>
        <TableRow sx={{ verticalAlign: 'top' }}>
          <TableCell></TableCell>
          {plansInfo.map((plan, idx) => {
            return (
              <TableCell
                key={plan.name}
                sx={{
                  backgroundColor: idx % 2 == 1 ? '#F5F5F5' : 'inherit',
                  borderRadius: '4px',
                }}
              >
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  color={'text.primary'}
                >
                  <Typography variant="heading5">{plan.name}</Typography>
                  <PriceBlock plan={plan} selectedPeriod={selectedPeriod} />
                  <Box py={'2px'}>
                    <ButtonPlan
                      planType={plan.name}
                      isSelected={plan_name === plan.name}
                      onClick={() => {
                        setPlan(plan.name)
                      }}
                    />
                  </Box>
                  {plan_name === plan.name && (
                    <Box
                      sx={{
                        mt: '4px',
                        mx: 'auto',
                        textAlign: 'center',
                      }}
                    >
                      <TextActivePlanUntilDate />
                    </Box>
                  )}
                </Box>
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>

      <TableBody>
        {Object.keys(featureNames).map((featKey) => (
          <TableRow key={featKey}>
            <TableCell sx={{ minWidth: '224px' }}>
              {featureNames[featKey]}
            </TableCell>

            {plansInfo.map((plan, idx) => {
              return (
                <TableCell
                  sx={{
                    backgroundColor: idx % 2 == 1 ? '#F5F5F5' : 'inherit',
                  }}
                  key={idx}
                >
                  <FeatureLimit plan={plan.features[featKey]} />
                </TableCell>
              )
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
