import { useGoogleLogin, CodeResponse } from '@react-oauth/google'
import { UrlKeys } from 'helper/consts'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { integrationsApi } from 'service/integrations'
import img_api from 'assets/img/integrations/api.svg'
import img_1c from 'assets/img/integrations/Logo=1c.svg'
import img_drive from 'assets/img/integrations/Logo=Drive.svg'
import img_enbek from 'assets/img/integrations/Logo=Enbek.svg'
import img_sheets from 'assets/img/integrations/Logo=Sheets.svg'
import img_webhook from 'assets/img/integrations/Logo=webhook.svg'
import img_amo from 'assets/img/integrations/Logo=AmoCrm.svg'
import img_bitrix from 'assets/img/integrations/Logo=Bitrix.svg'
import { IIntegrationCard } from '../types'

// import img_biometric from 'assets/img/integrations/Logo=Biometric.svg'
// import img_airba from 'assets/img/integrations/Logo=Airba.svg'

export const useIntegrations = (callback?: () => void) => {
  const { urlId: urlTemplateId } = useUrlIdParam(UrlKeys.templateId)

  const login = useGoogleLogin({
    onSuccess: async (codeResponse: CodeResponse) => {
      await integrationsApi.postIntegrationsGoogleSheets({
        code: codeResponse.code,
        template_id: urlTemplateId.get(),
      })
      if (callback) {
        callback()
      }
    },
    scope: 'email https://www.googleapis.com/auth/drive.file',
    flow: 'auth-code',
  })

  const integrations: IIntegrationCard[] = [
    {
      icon: img_sheets,
      title: 'Google Таблицы',
      description: ' Для передачи данных из отправленных документов ',
      isAvailable: true,
      onClickCallback: login,
    },
    {
      icon: img_webhook,
      title: 'Webhook',
      description: ' Отправка статусов и данных по документам',
      isAvailable: false,
    },
    {
      icon: img_drive,
      title: 'Google Диск',
      description: 'Для передачи и хранения готовых документов',
      isAvailable: false,
    },
    {
      icon: img_api,
      title: 'API',
      description: ' Для интеграции шаблона с другими сервисами',
      isAvailable: false,
    },

    // {
    //   icon: img_biometric,
    //   title: 'Biometric',
    //   description: 'Подписание биометрией лица, цифровые документы ',
    // },
    // {
    //   icon: img_airba,
    //   title: 'Airba Pay',
    //   description: 'Для приема онлайн платежей после подписания документов',
    // },
    // {
    //   icon: img_airba,
    //   title: 'Airba FinTech',
    //   description: 'Для продаж в рассрочку от ведущих банков Казахстана',
    // },
    {
      icon: img_1c,
      title: '1С Бухгалтерия',
      description: 'Работы с документами из 1С',
    },
    {
      icon: img_1c,
      title: '1С ЗУП',
      description: 'Работы с документами из 1С',
    },

    {
      icon: img_bitrix,
      title: 'Bitrix24',
      description: 'Для работы с документами',
    },
    {
      icon: img_amo,
      title: 'amoCRM',
      description: 'Для работы с документами',
    },
    {
      icon: img_enbek,
      title: 'Enbek.kz',
      description: 'Отправка уведомлений по работникам',
    },
  ]
  return { integrations }
}
