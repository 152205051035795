import { UrlKeys } from 'helper/consts'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export const useDocumentParams = () => {
  const { documentId } = useParams()

  const document_id = useMemo(() => {
    const params = new URLSearchParams(location.search)
    const document_id = params.get(UrlKeys.documentId)
    return document_id
  }, [location])

  const template_id = new URLSearchParams(location.search).get(
    UrlKeys.templateId
  )

  return { id: document_id ? document_id : documentId, template_id }
}
