import { CircularProgress, Button } from '@mui/material'
import { MessageSnackbar } from 'components/snackbar'
import downloadImg from 'assets/img/download.svg'
import { useDownloader } from 'hooks/useDownloader'

const getFileNameAndUrl = async ({ data, filename }) => {
  const blob = new Blob([data])
  const url = window.URL.createObjectURL(blob)
  return { filename, url }
}

export const DownloadExcelWithErrors = ({ data, filename }) => {
  const { download, loading, error, setError } =
    useDownloader(getFileNameAndUrl)

  if (loading) return <CircularProgress />
  return (
    <>
      <MessageSnackbar
        message={error}
        clearMessage={() => setError('')}
        severity="error"
      />
      <Button
        onClick={() => download({ data, filename })}
        variant={'outlined'}
        color={'secondary'}
      >
        <img src={downloadImg} style={{ marginRight: 8 }} />
        Скачать Excel-файл с замечаниями{' '}
      </Button>
    </>
  )
}
