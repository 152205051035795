import { useSnackbar } from 'components/snackbar/hook'
import { UrlKeys } from 'helper/consts'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { useState } from 'react'
import { integrationsApi } from 'service/integrations'
import { IIntegration } from '../types'

export const useActiveIntegrations = () => {
  const [loading, setLoading] = useState(false)
  const { urlId: urlTemplateId } = useUrlIdParam(UrlKeys.templateId)
  const [activeIntegrations, setActiveIntegrations] = useState<IIntegration[]>(
    []
  )
  const { snackbar, showSnackbar, closeSnackbar } = useSnackbar()

  const fetchIntegrations = async () => {
    try {
      setLoading(true)
      const res = await integrationsApi.getIntegrations({
        template_id: urlTemplateId.get(),
      })
      const { integrations } = res.data
      setActiveIntegrations(integrations)
    } catch (error: any) {
      showSnackbar({
        message: 'Мы не смогли найти интеграции',
        severity: 'error',
        close: closeSnackbar,
      })
    } finally {
      setLoading(false)
    }
  }

  return { activeIntegrations, snackbar, loading, fetchIntegrations }
}
