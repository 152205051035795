import { Box, Typography, Button } from '@mui/material'
import { TextInput } from 'components'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { Formik } from 'formik'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deleteWs } from 'store/slices/workspace'
import * as Yup from 'yup'

const title = 'Вы уверены, что хотите удалить это пространство?'

export const DeleteWorkspace = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const navigate = useNavigate()

  const { loading } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )
  const handleClickDelete = () => {
    dispatch(deleteWs({ wsId: selectedWorkspace?.id || '', navigate }) as any)
  }

  const validationSchema = Yup.object().shape({
    ws_name: Yup.string()
      .required('Обязательное поле')
      .test(
        'name-match',
        'Значение должно совпадать с названием пространства',
        (value) => value === selectedWorkspace?.name
      ),
  })

  const description = (
    <Formik
      initialValues={{ ws_name: '' }}
      validationSchema={validationSchema}
      onSubmit={handleClickDelete}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Typography variant="body1">
              Действие не может быть отменено. Это навсегда удалит пространство,
              включая все документы всех участников. Введите имя рабочей области
              для подтверждения
            </Typography>
          </Box>

          <Box mt="16px">
            <TextInput
              autoFocus
              size="small"
              fullWidth
              placeholder="Введите название пространства..."
              name="ws_name"
            />
          </Box>
          <Box
            mt={1}
            justifyContent="flex-end"
            display={'flex'}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Button
              sx={{ mr: '8px', mb: isMobile ? 1 : 0 }}
              color="secondary"
              onClick={() => setOpen(false)}
              variant="outlined"
            >
              Отменить
            </Button>
            <Button
              variant="outlined"
              color="error"
              type="submit"
              disabled={loading.delete_ts ? true : false}
            >
              Удалить пространство
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  )

  return (
    <>
      <ModalCenterSelectableWithTitle
        open={open}
        handleClose={handleClose}
        modalType="small"
        title={title}
        contentChild={description}
      />
      <Typography variant="body1" fontWeight={500}>
        Удалить пространство
      </Typography>
      <Typography
        variant="caption"
        color={'text.secondary'}
        mt="2px"
        fontWeight={400}
      >
        Это навсегда удалит пространство, включая все документы, шаблоны,
        рассылки всех участников
      </Typography>
      <Box mt="8px">
        <Button color="error" variant="outlined" onClick={handleOpen}>
          Удалить это пространство
        </Button>
      </Box>
    </>
  )
}
