import img_cancel from 'assets/img/ic_cancel.svg'
import img_revoke from 'assets/img/revoke.svg'

import { Box, Button, Typography } from '@mui/material'
import { Loading } from 'components/loading'
import { MenuItemButton } from 'components/menu-item/menu-item-button'
import { ModalCenterSmall } from 'components/modal'
import { MessageSnackbar } from 'components/snackbar'
import { EDocumentStatus } from 'helper/consts'
import { usePermissionDocumentRevokeInTeamspace } from 'pages/documents/hooks/usePermissionDocumentRevokeInTeamspace'
import { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { revokeFile } from 'service'
import { fileDataFetch } from 'store/slices/applicationSlice'
import { wfPostmanActions } from 'store/slices/wfPostmanSlice'
import { SignOptions } from '../SignOptions'
import { ShowOrGenerateLink } from '../send-link'

export const DocumentSentMenu = ({
  handleClose,
  setOption,
  option,
  formik,
  driverObj,
}) => {
  const { document } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )
  const { sign, loading: loadingPostman } = useSelector(
    (state: RootState) => state.wfPostman,
    shallowEqual
  )

  const permRevoke = usePermissionDocumentRevokeInTeamspace()

  const [error, setError] = useState('')
  const [open, setOpen] = useState(false)

  const dispatch = useDispatch()

  const revoke = async () => {
    try {
      await revokeFile({ id: document.id })
      dispatch(fileDataFetch({ id: document.id }) as any)
      dispatch(wfPostmanActions.clearLinks() as any)
      handleClose()
    } catch (error: any) {
      setError(error.message)
    }
  }

  if (loadingPostman.sign) return <Loading />

  return (
    <>
      <ModalCenterSmall
        open={open}
        handleClose={() => setOpen(false)}
        header={{
          children: (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Box
                component={'img'}
                src={img_cancel}
                width={'16px'}
                mr={'4px'}
              />
              <Typography variant="body1">
                Отозвать или изменить документ
              </Typography>
            </Box>
          ),
        }}
        content={{
          children: (
            <>
              <Typography variant="body1">
                Вы уверены? Если вы это сделаете: <br /> <br /> &#x2022; текущий
                прогресс документа будет удален;
              </Typography>
              <Typography variant="body1">
                &#x2022; документу будет присвоен статус “Отозван”;
              </Typography>
              <Typography variant="body1">
                &#x2022; все кто получил документ получат уведомления об отзыве
                документа;
              </Typography>
            </>
          ),
        }}
        footer={{
          children: (
            <Box justifyContent="flex-end" display={'flex'}>
              <Button
                size="small"
                color="secondary"
                onClick={() => setOpen(false)}
                sx={{
                  mr: '4px',
                }}
              >
                Отменить
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={() => {
                  revoke()
                  handleClose()
                  setOpen(false)
                }}
              >
                Отозвать
              </Button>
            </Box>
          ),
        }}
      />
      <MessageSnackbar
        message={error}
        clearMessage={() => setError('')}
        severity={'error'}
      />
      {document.status === EDocumentStatus.SENT ? null : (
        <Typography
          mb="10px"
          fontSize={'12px'}
          fontWeight={500}
          color={'text.disabled'}
          key={'send-doc'}
        >
          Документ подписан всеми сторонами
        </Typography>
      )}

      {!sign.isMeSign && (
        <ShowOrGenerateLink formik={formik} driverObj={driverObj} />
      )}
      {sign.isMeSign && <SignOptions setOption={setOption} option={option} />}
      {document.status !== EDocumentStatus.COMPLETED &&
        permRevoke.canDoOne(document) && (
          <>
            <MenuItemButton
              onClick={() => setOpen(true)}
              srcImage={img_revoke}
              title={'Отозвать документ'}
            />
          </>
        )}
    </>
  )
}
