import { EDocumentStatus, ETemplateFilter } from 'helper/consts'
import { EListQueryParam } from 'helper/queryParams'
import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useSearchQuery = () => {
  const [searchParams] = useSearchParams()

  const [queryString, setQueryString] = useState(
    searchParams.get(EListQueryParam.Query) || ''
  )
  const [authorQuery, setAuthorQuery] = useState(
    searchParams.get(EListQueryParam.Author) || ''
  )
  const [statusQuery, setStatusQuery] = useState(
    searchParams.get(EListQueryParam.Status) || ''
  )

  return {
    queryString,
    setQueryString,
    authorQuery,
    setAuthorQuery,
    statusQuery,
    setStatusQuery,
  }
}

const isNumber = (n: any) => !isNaN(parseInt(n)) && isFinite(n)

interface IQueryParams {
  page: number
  filter: EDocumentStatus | ETemplateFilter
  query: string
  status: string
  author: string
}

// TODO create context to manipulate with search params
export const useSearchQueryURLParams = ({
  totalDocumentsCount,
}: {
  totalDocumentsCount: number
}): IQueryParams => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [params, setParams] = useState<IQueryParams>({
    page: 1,
    filter: EDocumentStatus.ALL,
    query: '',
    status: '',
    author: '',
  })

  const validatePageNumber = useCallback(
    (page: string): number => {
      const pageNumber = parseInt(page, 10)
      if (!isNumber(pageNumber) || pageNumber < 1) {
        searchParams.set(EListQueryParam.Page, '1')
        setSearchParams(searchParams)
        return 0
      }

      return pageNumber
    },
    [totalDocumentsCount]
  )

  const updateSearchParam = useCallback(
    (key: string, defaultValue: string | EDocumentStatus | any) => {
      let value = searchParams.get(key)
      if (!value) {
        value = defaultValue
        searchParams.set(key, defaultValue)
        setSearchParams(searchParams)
      }
      return value || defaultValue
    },
    [searchParams, setSearchParams]
  )

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      page: validatePageNumber(updateSearchParam('page', '1')),
      filter: updateSearchParam(EListQueryParam.Filter, EDocumentStatus.ALL),
      query: updateSearchParam(EListQueryParam.Query, ''),
      status: updateSearchParam(EListQueryParam.Status, ''),
      author: updateSearchParam(EListQueryParam.Author, ''),
    }))
  }, [searchParams])

  return params
}
