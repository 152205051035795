import { Box, Typography } from '@mui/material'
import { EDocumentStatus } from 'helper/consts'
import { useSelectedDocsContext } from 'context'
import { MassActionsStandard } from './standart'
import { MassActionsArchive } from './archive'
import { MassActionsDelete } from './delete'

const showMassActions = (filter: EDocumentStatus) => {
  switch (filter) {
    case EDocumentStatus.ARCHIVED:
      return <MassActionsArchive />
    case EDocumentStatus.DELETED:
      return <MassActionsDelete />
    default:
      return <MassActionsStandard />
  }
}

export const MassActions = ({ filter }: { filter: EDocumentStatus }) => {
  const { selectedDocsIds } = useSelectedDocsContext()

  return (
    <Box
      display={'flex'}
      flexDirection={{ xs: 'column', sm: 'row' }}
      alignItems={'flex-start'}
    >
      <Box display={'flex'}>
        <Box
          p={'6px 0'}
          mb={{ xs: 1, sm: 0 }}
          mr={1}
          width={'fit-content'}
          display={'flex'}
          alignItems={'center'}
        >
          <Typography color={'text.disabled'} mr={'4px'}>
            Выбрано:{' '}
          </Typography>
          <Typography variant="body1">{selectedDocsIds.size}</Typography>
        </Box>
        <Box mr={1}>
          {/* <Button color="secondary"> Выбрать все, на всех страницах</Button> */}
        </Box>
      </Box>

      {showMassActions(filter)}
    </Box>
  )
}
