import { Box, Typography, Button } from '@mui/material'
import { ReactNode, useContext, useEffect, useState } from 'react'
import {
  EModalBannerBg,
  EModalBannerIcon,
  ModalSmallWithBanner,
} from '../ModalSmallWithBanner'
import { EFeature } from 'helper/plan.types'
import { shallowEqual, useSelector } from 'react-redux'
import { getWSPlanFeatureRestriction, isMaxAvailablePlan } from 'utils/plans'
import { EWorkspacePlan } from 'helper/plan.types'
import { Link } from 'react-router-dom'
import { ButtonTelegram } from 'components/button/Support/ButtonTelegram'
import { ButtonWhatsApp } from 'components/button/Support/ButtonWhatsApp'
import { ButtonEmail } from 'components/button/Support/ButtonEmail'
import { isWorkspaceOwner } from 'utils/workspace/isWorkspaceOwner'
import { DASHBOARD_MODES } from 'layout/dashboard-layout'
import { AppBarContext } from 'context'
import { isMobile } from 'react-device-detect'
import { ModalPlanLimitPlanEnded } from '../ModalPlanLimitPlanEnded'

const ModalPlanRestriction = ({
  open,
  handleClose,
  title,
  text,
  footer,
}: {
  open: boolean
  handleClose?: () => void
  title?: string
  text?: string
  footer?: ReactNode
}) => {
  return (
    <ModalSmallWithBanner
      open={open}
      handleClose={handleClose}
      banner={{
        icon: EModalBannerIcon.circle_blue_up_white,
        background: EModalBannerBg.doodocs_logos_gray,
      }}
      children={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: '16px',
          }}
        >
          {!!title && <Typography variant="heading5">{title}</Typography>}
          {!!text && <Typography mt={'8px'}>{text}</Typography>}
          {!!footer && (
            <Box
              sx={{
                mt: '16px',
              }}
            >
              {footer}
            </Box>
          )}
        </Box>
      }
    />
  )
}
const getTitle = (limitOn: EFeature, plan_info: IPlanInfo) => {
  const { plan_limits, workspace_limits } = plan_info
  const { name: planName } = plan_limits
  const { prohibited, exhausted } = getWSPlanFeatureRestriction(
    limitOn,
    plan_info
  )

  if (
    planName === EWorkspacePlan.Trial &&
    limitOn === EFeature.documents_count_per_month
  ) {
    return `Пространство достигло лимита готовых документов бесплатного периода (Использовано ${plan_limits.documents_count} из ${workspace_limits.documents_count})`
  }

  if (prohibited) {
    const fname = {
      [EFeature.users_count]: 'приглашать новых пользователей',
      [EFeature.teamspaces_count]: 'создавать группы',
      [EFeature.document_send_by_email]: 'отправить документ по маршруту',
      [EFeature.ai_chat]: 'использовать Doodocs AI',
      [EFeature.mass_send]: 'использовать массовую рассылку документов',
    }

    const switchToPlan = {
      [EFeature.users_count]: '«Start» или выше',
      [EFeature.teamspaces_count]: '«Start» или выше',
      [EFeature.document_send_by_email]: '«Lite» или выше',
      [EFeature.ai_chat]: '«Lite» или выше',
      [EFeature.mass_send]: '«Pro»',
    }

    const md = fname[limitOn]
    const end = switchToPlan[limitOn]

    if (!md || !end)
      return `Текущий тариф «${planName}» не позволяет использовать эту функцию`
    return `Чтобы ${md}, пространство должно иметь тариф ${end}`
  } else if (exhausted) {
    const fname = {
      [EFeature.documents_count_per_month]: 'готовых документов',
      [EFeature.users_count]: 'количества пользователей',
      [EFeature.teamspaces_count]: 'количества групп',
    }
    const used = {
      [EFeature.documents_count_per_month]: `${plan_limits.documents_count} из ${workspace_limits.documents_count}`,
      [EFeature.users_count]: `${plan_limits.workspace_users_count} из ${workspace_limits.workspace_users_count}`,
      [EFeature.teamspaces_count]: `${plan_limits.teamspaces_count} из ${workspace_limits.teamspaces_count}`,
      [EFeature.template_creation_count]: `${plan_limits.templates_count} из ${workspace_limits.templates_count}`,
    }

    const md = fname[limitOn] ?? 'этой функции'
    const end = used[limitOn] ? ` (Использовано ${used[limitOn]})` : ''

    return `Пространство достигло лимита ${md} тарифа «${planName}»${end}`
  }

  return ''
}

const getText = (limitOn: EFeature, plan_info: IPlanInfo) => {
  const planName = plan_info.plan_limits.name

  if (isMaxAvailablePlan(planName)) {
    if (limitOn === EFeature.documents_count_per_month)
      return `Чтобы получить доступ к этой функции дождитесь окончания текущего месяца или свяжитесь с нами`
    return `Чтобы получить доступ к этой функции свяжитесь с нами`
  }
  if (limitOn === EFeature.documents_count_per_month)
    return `Чтобы получить доступ к этой функции улучшите тариф или дождитесь окончания текущего месяца.`
  return `Чтобы получить доступ к этой функции улучшите тариф`
}

export const ModalPlanLimit = ({
  open,
  handleClose,
  limitOn,
}: {
  open: boolean
  handleClose?: () => void
  limitOn: EFeature
}) => {
  const { plan_info } = useSelector(
    (state: RootState) => state.wsPlan,
    shallowEqual
  )
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )
  const { setMode } = useContext(AppBarContext)

  const [prohibited, setProhibited] = useState(false)
  const [exhausted, setExhausted] = useState(false)

  const message = `Здравствуйте! Я достиг лимита в тарифе ${plan_info.plan_limits.name}. Хотел бы узнать, как можно увеличить лимиты?`

  useEffect(() => {
    const res = getWSPlanFeatureRestriction(limitOn, plan_info)
    setProhibited(res.prohibited)
    setExhausted(res.exhausted)
  }, [plan_info])

  if (
    (prohibited || exhausted) &&
    plan_info.plan_limits.name == EWorkspacePlan.Free
  )
    return <ModalPlanLimitPlanEnded open={open} handleClose={handleClose} />

  if (prohibited || exhausted)
    return (
      <ModalPlanRestriction
        open={open}
        handleClose={handleClose}
        title={getTitle(limitOn, plan_info)}
        text={
          isWorkspaceOwner(selectedWorkspace?.group_name)
            ? getText(limitOn, plan_info)
            : 'Пожалуйста, свяжитесь с владельцем пространства для выбора и оплаты тарифа'
        }
        footer={
          isWorkspaceOwner(selectedWorkspace?.group_name) ? (
            isMaxAvailablePlan(plan_info.plan_limits.name) ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  flexWrap: 'wrap',
                }}
              >
                <ButtonEmail message={message} fullWidth={isMobile} />
                <ButtonTelegram fullWidth={isMobile} />
                <ButtonWhatsApp message={message} fullWidth={isMobile} />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <Link
                  to={'/plan'}
                  onClick={() => {
                    setMode(DASHBOARD_MODES.SETTINGS)
                    if (handleClose) handleClose()
                  }}
                  style={{
                    width: isMobile ? '100%' : 'auto',
                  }}
                >
                  <Button variant="contained" color="success" fullWidth>
                    Выбрать тариф
                  </Button>
                </Link>
              </Box>
            )
          ) : undefined
        }
      />
    )

  return null
}
