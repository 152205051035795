import Cookies from 'js-cookie'

export const setOnboardingCookie = () => {
  const token = localStorage.getItem('token')
  Cookies.set('onboardingToken', token, {
    domain: '.doodocs.kz',
    path: '/',
    sameSite: 'strict',
  })
}
