import { CircularProgress, Button } from '@mui/material'

export const PaymentButton = ({
  onClick,
  loading,
  buttonText,
}: {
  onClick: () => void
  loading: boolean
  buttonText: string
}) => (
  <Button
    variant="outlined"
    color="secondary"
    size="small"
    onClick={onClick}
    disabled={loading}
    sx={{
      minWidth: '100px',
    }}
  >
    {loading ? <CircularProgress size={24} thickness={4} /> : buttonText}
  </Button>
)
