import img_account from 'assets/img/account.svg'
import img_link from 'assets/img/hyperlink.svg'
import img_delete from 'assets/img/close.svg'

import { useContext, useMemo } from 'react'
import { WorfklowBuilderUIContext } from '../../../../providers/WorkflowBuilderUIContext'
import { RecipientsPartition } from '../RecipientsPartition'
import { ButtonsOnHoverAddNewRecipientFiller } from './ButtonsOnHoverAddNewRecipientFiller'
import { RecipientBadge } from '../RecipientBadge'
import { EServiceWorkflowStepType } from 'helper/workflow.service.types'
import { CRecipientFiller } from 'helper/workflow/recipients/CRecipientFiller'
import { IActor } from 'helper/workflow/IWorkflow'
import { getFillerColorByActorId } from 'utils/workflow/getFillerColorByActorId'
import { getObjectClone } from 'utils/getObjectClone'
import { RecipientHelperNS } from 'helper/workflow/recipients/RecipientHelperNS'

export const RecipientsFiller = ({ actor }: { actor: IActor }) => {
  const workflowBuilderUI = useContext(WorfklowBuilderUIContext)

  const filler = useMemo(() => {
    const state = workflowBuilderUI.workflow
    const step = state.steps.find(
      (step) => step.type === EServiceWorkflowStepType.form
    )

    if (!step) return null
    const filler = step.recipients.find(
      (recipient) => recipient.actor_id === actor.id
    )
    if (!filler) return null

    return filler as CRecipientFiller
  }, [workflowBuilderUI])

  const deleteRecipient = (recipient: CRecipientFiller) => {
    const state = getObjectClone(workflowBuilderUI.workflow)
    RecipientHelperNS.RemoveRecipientsWithActorIdFromSteps(
      state.steps,
      recipient.actor_id
    )
    workflowBuilderUI.setWorkflow(state)
  }

  const getFillerBadge = () => {
    if (!filler) return null

    if (filler.attrs.must_send_email) {
      return (
        <>
          <RecipientBadge
            text={filler.attrs.email ?? ''}
            imgSrc={img_account}
            actionBtn={{
              onClick: () => deleteRecipient(filler),
              src: img_delete,
            }}
          />
        </>
      )
    }

    return (
      <>
        <RecipientBadge
          text={'link.doodocs.kz/xxxxxxx'}
          imgSrc={img_link}
          actionBtn={{
            onClick: () => deleteRecipient(filler),
            src: img_delete,
          }}
        />
      </>
    )
  }

  return (
    <>
      <RecipientsPartition
        title={{
          text: `${actor.title}:`,
          backgroundColor: getFillerColorByActorId(actor.id),
        }}
        children={
          <>
            {!!filler && getFillerBadge()}

            {!filler && (
              <ButtonsOnHoverAddNewRecipientFiller actorId={actor.id} />
            )}
          </>
        }
      />
    </>
  )
}
