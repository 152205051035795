import { Box } from '@mui/material'
import { useContext } from 'react'
import { WorfklowBuilderUIContext } from '../../../providers/WorkflowBuilderUIContext'
import { RecipientsApprovers } from './RecipientsApprovers'
import { RecipientsCopy } from './RecipientsCopy'
import { RecipientsFiller } from './RecipientsFiller'
import { RecipientsSigners } from './RecipientsSigners'

export const BlockRecipients = () => {
  const workflowBuilderUI = useContext(WorfklowBuilderUIContext)

  const getRecipientsFillers = () => {
    const state = workflowBuilderUI.workflow

    return state.actors?.map((actor) => {
      if (actor.id == 1) return null
      return <RecipientsFiller key={actor.id} actor={actor} />
    })
  }

  return (
    <Box>
      {getRecipientsFillers()}
      <RecipientsApprovers />
      <RecipientsSigners />
      <RecipientsCopy />
    </Box>
  )
}
