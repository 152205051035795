import {
  TUseActionLoadAPISecretKeyOnSuccess,
  useActionLoadAPISecretKey,
} from 'features/create-api-secret-key/lib/useActionLoadAPISecretKey'
import { ButtonCustom } from 'shared/components/buttons/ButtonCustom'

export const ButtonCreateAPISecretKey = ({
  onError,
  onSuccess,
}: {
  onError?: TFuncOnError
  onSuccess?: TUseActionLoadAPISecretKeyOnSuccess
}) => {
  const action = useActionLoadAPISecretKey({
    onError,
    onSuccess,
  })

  return (
    <ButtonCustom
      variant="contained"
      color="primary"
      onClick={action.run}
      loading={action.loading}
      children={'Создать новый API ключ'}
    />
  )
}
