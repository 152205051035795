import { IWorkflowBuilderUIContext } from 'features/workflow-builder/providers/WorkflowBuilderUIContext'
import { CWorkflowBuilder } from 'helper/workflow/CWorkflowBuilder'
import { useDebounce } from 'hooks/useDebounce'
import { useCallback, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { manageTemplateSettingsSwitchWorkflowEnabled } from 'store/slices/manageTemplateSettings/manageTemplateSettingsSwitchWorkflowEnabled'
import { manageTemplateSettingsUpdateWorkflow } from 'store/slices/manageTemplateSettings/manageTemplateSettingsUpdateWorkflow'
import { TRootState } from 'store/store'

export const useManageTemplateSettingsPreFilledWorkflow = ({
  workflowBuilderUI,
}: {
  workflowBuilderUI: IWorkflowBuilderUIContext
}) => {
  const debounce = useDebounce()
  const dispatch = useDispatch<any>()
  const { id } = useSelector((state: RootState) => state.template, shallowEqual)
  const { preFilledWorkflow, status, template_id } = useSelector(
    (state: TRootState) =>
      state.templateSettings,
    shallowEqual
  )

  const isWorkflowSaved = useCallback(() => {
    const wf = CWorkflowBuilder.BuildWorkflow(preFilledWorkflow.workflow)
    return (
      JSON.stringify(workflowBuilderUI.workflow.restorableExport()) ==
      JSON.stringify(wf.restorableExport())
    )
  }, [preFilledWorkflow.workflow, workflowBuilderUI.workflow])

  const isFetching =
    id != template_id || status == 'idle' || status == 'loading'

  useEffect(() => {
    if (isFetching) return
    const wf = CWorkflowBuilder.BuildWorkflow(preFilledWorkflow.workflow)
    workflowBuilderUI.setWorkflow(wf)
  }, [id, status])

  useEffect(() => {
    // ? AutoSave Workflow
    if (isFetching) return
    debounce(async () => {
      if (isWorkflowSaved()) return

      await dispatch(
        manageTemplateSettingsUpdateWorkflow({
          workflow: workflowBuilderUI.workflow.export(),
        })
      )
    }, 1000)
  }, [workflowBuilderUI.workflow])

  const handleSwitch = () => {
    dispatch(
      manageTemplateSettingsSwitchWorkflowEnabled({
        value: !preFilledWorkflow.isOn,
      })
    )
  }

  return {
    isFetching,
    templateId: id,
    isOn: preFilledWorkflow.isOn,
    loading: preFilledWorkflow.loading,
    handleSwitch: handleSwitch,
  }
}