import { RadioProps, Radio as Rd } from '@mui/material'
import { styled } from '@mui/material/styles'

const BpIcon = styled('span')(() => ({
  width: '100%',
  height: '100%',

  background: '#fff',

  border: '1px solid var(--light-grayscale-border, #ededec)',
  borderRadius: '50%',

  transition: '0.2s ease-out',

  'input:hover ~ &': {
    border: '1px solid var(--light-grayscale-border-hover, #e7e7e6)',
    background: 'var(--light-grayscale-background-secondary, #f5f5f5)',
  },
  '.Mui-focusVisible &': {
    border: '1px solid var(--light-blue-border, #a1daff)',
    background: 'var(--Color, #fff)',
    outline: '2px solid var(--light-blue-background, #dcf1ff)',
  },
  'input:disabled ~ &': {
    cursor: 'not-allowed',

    border: '1px solid var(--light-grayscale-border-hover, #e7e7e6)',
    opacity: '0.4',
    background: 'var(--light-grayscale-background-secondary, #f5f5f5)',
  },

  'input:focus ~ &': {
    border: '1px solid var(--light-blue-border, #a1daff)',
    background: 'var(--Color, #fff)',
    outline: '2px solid var(--light-blue-background, #dcf1ff)',
  },

  'input:checked ~ &': {
    border: '5px solid var(--light-blue-base, #0085ff)',
  },

  'input:checked:hover ~ &': {
    border: '5px solid var(--light-blue-base, #339dff)',
  },

  'input:checked:disabled ~ &': {
    opacity: '0.4',
    border: '5px solid var(--light-blue-base, #0085ff)',
  },
}))

// ! Не удалять
const BpCheckedIcon = styled(BpIcon)({})

// Inspired by blueprintjs
export const Radio = (props: RadioProps & { sizePx?: number }) => {
  return (
    <Rd
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
      sx={{
        width: `${props.sizePx ?? 16}px`,
        height: `${props.sizePx ?? 16}px`,
        ...props.sx,
      }}
    />
  )
}
