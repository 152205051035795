import { Box, IconButton, InputBase, Link, Paper } from '@mui/material'
import { useCopyToClipboard } from 'hooks/useCopyToClipboard'
import img_copy from 'assets/img/content_copy.svg'
import img_check from 'assets/img/ic_check.svg'
import { useMemo } from 'react'
import { getLinkURN } from 'utils/links/getLinkURN'

const styleProps = {
  xs: {
    tbPaddingRight: '4px',
    tbPaddingLeft: '12px',
    p: '9px',
  },
  sm: {
    tbPaddingRight: '4px',
    tbPaddingLeft: '12px',
    p: '5px',
  },
  contentHeight: '20px',
}

export const TextFieldCopyLink = ({
  value,
  placehoder,
  disabled,
}: {
  value: string
  placehoder?: string
  disabled?: boolean
}) => {
  const { copied, copyToClipboard } = useCopyToClipboard()

  const urn = useMemo(() => {
    return getLinkURN(value)
  }, [value])

  return (
    <Paper
      component="form"
      sx={{
        p: '0',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid var(--light-grayscale-border, #EDEDEC)',
        background: 'var(--light-grayscale-background-primary, #FFF)',
        borderRadius: '4px',
        overflow: 'hidden',
        boxShadow: 'none',
      }}
    >
      <Link
        href={`${value}`}
        target={'_blank'}
        color={'inherit'}
        width={'100%'}
      >
        <InputBase
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
            flex: 1,
            p: {
              xs: `${styleProps.xs.p} ${styleProps.xs.tbPaddingRight} ${styleProps.xs.p} ${styleProps.xs.tbPaddingLeft}`,
              sm: `${styleProps.sm.p} ${styleProps.sm.tbPaddingRight} ${styleProps.sm.p} ${styleProps.sm.tbPaddingLeft}`,
            },
            color: 'inherit',
            borderRight: '1px solid var(--light-grayscale-border, #EDEDEC)',
            '& .MuiInputBase-input': {
              cursor: 'pointer',
              p: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
          value={urn}
          readOnly={true}
          placeholder={placehoder}
          disabled={disabled}
          fullWidth
        />
      </Link>
      <IconButton
        type="button"
        sx={{
          p: { xs: `${styleProps.xs.p}`, sm: `${styleProps.sm.p}` },
          borderRadius: '0px',
        }}
        onClick={() => {
          copyToClipboard(value)
        }}
        disabled={disabled}
      >
        <Box
          component={'img'}
          src={copied ? img_check : img_copy}
          width={styleProps.contentHeight}
          height={styleProps.contentHeight}
        />
      </IconButton>
    </Paper>
  )
}
