import { api } from 'config'
import { LocalStorageReserve } from 'helper/consts'
import { IIntegrationWebhookAttrs } from 'pages/manage-template/components/ManageTemplateTabContent/TabContentIntegrations/types'
import { handleError } from 'service'

export const integrationsApi = {
  postTemplatesIntegrationsWebhook: async ({
    templateId,
    data,
  }: {
    templateId: string
    data: IIntegrationWebhookAttrs
  }) => {
    try {
      const resp = await api.post(
        `/templates/${templateId}/integrations/webhook`,
        data,
        {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${localStorage.getItem(
              LocalStorageReserve.token
            )}`,
          },
        }
      )
      return resp
    } catch (error) {
      return handleError(error)
    }
  },
  // TODO: 
  // 1. ATLEKBAI
  // 2. Split Components
  putIntegrationsWebhook: async ({
    integrationId,
    data,
  }: {
    integrationId: string
    data: IIntegrationWebhookAttrs
  }) => {
    try {
      const resp = await api.put(
        `/integrations/${integrationId}/webhook`,
        data,
        {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${localStorage.getItem(
              LocalStorageReserve.token
            )}`,
          },
        }
      )
      return resp
    } catch (error) {
      return handleError(error)
    }
  },

  postIntegrationsGoogleSheets: async ({
    code,
    template_id,
  }: {
    code: string
    template_id: string
  }) => {
    try {
      const resp = await api.post(
        `/templates/${template_id}/integrations/google-sheets`,
        { code },
        {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${localStorage.getItem(
              LocalStorageReserve.token
            )}`,
          },
        }
      )
      return resp
    } catch (error) {
      return handleError(error)
    }
  },

  getIntegrations: async ({ template_id }: { template_id: string }) => {
    try {
      const resp = await api.get(`/templates/${template_id}/integrations`, {
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
        timeout: 10000,
      })
      return resp
    } catch (error) {
      return handleError(error)
    }
  },

  postIntegrationsEnable: async ({
    integration_id,
  }: {
    integration_id: string
  }) => {
    try {
      const resp = await api.post(
        `/integrations/${integration_id}/enable`,
        {},
        {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${localStorage.getItem(
              LocalStorageReserve.token
            )}`,
          },
        }
      )
      return resp
    } catch (error) {
      return handleError(error)
    }
  },

  deleteIntegrationsEnable: async ({
    integration_id,
  }: {
    integration_id: string
  }) => {
    try {
      const resp = await api.delete(`/integrations/${integration_id}/enable`, {
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return resp
    } catch (error) {
      return handleError(error)
    }
  },

  deleteIntegration: async ({ integration_id }: { integration_id: string }) => {
    try {
      const resp = await api.delete(`/integrations/${integration_id}`, {
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return resp
    } catch (error) {
      return handleError(error)
    }
  },
}
