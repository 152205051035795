import img_download from 'assets/img/download.svg'

import { MenuItemButton } from 'components/menu-item/menu-item-button'
import { MessageSnackbar } from 'components/snackbar'
import { useDownloader } from 'hooks/useDownloader'
import { getPdfFileByLink } from 'service'

export const MenuItemButtonDocumentDownloadOriginal = ({
  linkPDF,
  filename,
}: {
  linkPDF: string
  filename: string
}) => {
  const { download, loading, error, setError } = useDownloader(getPdfFileByLink)

  if (!linkPDF) return null
  return (
    <>
      <MessageSnackbar
        message={error}
        clearMessage={() => setError('')}
        severity="error"
      />
      <MenuItemButton
        srcImage={img_download}
        title={loading ? 'Идет скачивание...' : 'Скачать документ'}
        disabled={loading}
        onClick={async () => {
          await download({ link: linkPDF }, filename)
        }}
      />
    </>
  )
}
