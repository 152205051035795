import { createAsyncThunk } from '@reduxjs/toolkit'
import { queryTemplateManageGetDocumentsRecords } from 'service/template'
import { TRootState } from 'store/store'
import { EManageTemplateDataStatus, manageTemplateDataSlice } from '..'

export const loadManageTemplateDataDocumentsValues = createAsyncThunk(
  'manageTemplateData/loadManageTemplateDataDocumentsValues',
  async (
    {
      limit,
    }: {
      limit?: number
    },
    thunkApi
  ) => {
    const { dispatch, getState } = thunkApi
    const { template_id, records } = (getState() as TRootState).templateData
    if (!template_id) return
    const limitValue = limit ?? 50

    dispatch(
      manageTemplateDataSlice.actions.setStatus(
        EManageTemplateDataStatus.infinite_loading
      )
    )
    try {
      const rRecords = await queryTemplateManageGetDocumentsRecords({
        templateId: template_id,
        offset: records.length,
        limit: limitValue,
      })

      dispatch(
        manageTemplateDataSlice.actions.appendRecords(rRecords.data.records)
      )
      if (rRecords.data.records.length < limitValue) {
        dispatch(manageTemplateDataSlice.actions.setIsFullyLoaded(true))
      }

      dispatch(
        manageTemplateDataSlice.actions.setStatus(
          EManageTemplateDataStatus.success
        )
      )
    } catch (error: any) {
      dispatch(manageTemplateDataSlice.actions.setError(error.message))
      dispatch(
        manageTemplateDataSlice.actions.setStatus(
          EManageTemplateDataStatus.failed
        )
      )
    }
  }
)
