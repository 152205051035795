import Avatar from 'boring-avatars'
import { renderToString } from 'react-dom/server'

const colors = ['#70BAFF', '#9499E8', '#6CD3C2', '#FF9B7A', '#FFCC76']

export const getWorkspaceAvatarString = (name?: string) => {
  return renderToString(
    <Avatar
      size={'120px'}
      name={name}
      variant={'marble'}
      colors={colors}
      square={true}
    />
  )
}

export const getWorkspaceAvatar = (name: string) => {
  const svg = getWorkspaceAvatarString(name)
  const blob = new Blob([svg], {
    type: 'image/svg+xml',
  })
  return blob
}

export const getWorkspaceAvatarUrl = (name: string) => {
  const blob = getWorkspaceAvatar(name)
  const url = URL.createObjectURL(blob)
  return url
}
