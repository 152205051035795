import { Box } from '@mui/material'
import { FieldRenderer } from '..'

export interface INumberFiledSettings {
  has_max: boolean
  max: number
  has_min: boolean
  min: number
  default_value: string
}

type FieldName = keyof INumberFiledSettings | BaseSettings

const configArray: Array<{
  name: FieldName
  label?: string
  type: string
  dependsOn?: FieldName
  description?: string
}> = [
  { name: 'has_max', label: 'Максимальное число', type: 'switch' },
  { name: 'max', type: 'number', dependsOn: 'has_max' },
  { name: 'has_min', label: 'Минимальное число', type: 'switch' },
  { name: 'min', type: 'number', dependsOn: 'has_min' },
]

export const numberFieldinitialValues: INumberFiledSettings = {
  default_value: '0',
  has_max: false,
  max: 1,
  has_min: false,
  min: 0,
}

export const NumberFieldForm = () => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      {configArray.map((field) => (
        <FieldRenderer key={field.name as string} field={field} />
      ))}
    </Box>
  )
}
