import { createAsyncThunk } from "@reduxjs/toolkit";
import { manageTemplateSettingsSlice } from "..";
import { queryGetTemplateManageSettings } from "service/template";

export const loadManageTemplateSettings = createAsyncThunk(
  "manageTemplateSettings/loadManageTemplateSettings",
  async ({
    templateId,
  }: {
    templateId: string
  }, thunkApi) => {
    if (!templateId) return
    const { dispatch } = thunkApi

    dispatch(manageTemplateSettingsSlice.actions.setTemplateId(templateId))
    dispatch(manageTemplateSettingsSlice.actions.setStatus("loading"))

    try {
      const resp = await queryGetTemplateManageSettings({ templateId })
      const { settings, workflow } = resp.data

      dispatch(manageTemplateSettingsSlice.actions.setSettings({
        eGovMobile: settings.is_sign_egov_enabled,
        eGovMobileBusiness: settings.is_sign_egovbusiness_enabled,
        ncaLayer: settings.is_sign_nca_enabled,
        preFilledWorkflow: {
          isOn: settings.is_workflow_enabled,
          workflow: workflow
        }
      }))

      dispatch(manageTemplateSettingsSlice.actions.setStatus("success"))
    } catch (error: any) {
      dispatch(manageTemplateSettingsSlice.actions.setError(error.message))
      dispatch(manageTemplateSettingsSlice.actions.setStatus("failed"))
    }
  }
)