import { useMemo } from 'react'
import { driver } from 'driver.js'
import { EDocumentStatus } from 'helper/consts'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { skipOnboardingAction } from 'store/slices/userSlice'

export const useDocumentSendDriver = ({
  sendDocButtonRef,
  formik,
  setCreateLink,
  setWithPulse,
}) => {
  const dispatch = useDispatch()

  const { status } = useSelector(
    (state: RootState) => state.application.document,
    shallowEqual
  )
  const { onboarding } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  )

  const finishSendDocStep = () => {
    dispatch(skipOnboardingAction() as any)
    driverObj?.destroy()
  }

  const steps = useMemo(
    () => [
      {
        element: '#send-doc-button',
        popover: {
          title: 'Отправьте документ на подписание',
          description: '',
          onNextClick: () => {
            sendDocButtonRef.current.click()
          },
        },
      },
      {
        element: '#create-link',
        popover: {
          title: 'Отправьте документ на подпись по ссылке для подписания',
          side: 'left' as any,
          onNextClick: () => {
            setCreateLink(true)
            setTimeout(() => {
              driverObj?.moveNext()
            }, 200)
          },
        },
      },
      {
        element: '#create-link-form',
        popover: {
          title: 'Введите количество подписантов для этого документа',
          side: 'left' as any,
        },
      },
      {
        element: '#create-link-button',
        popover: {
          title: 'Создайте ссылку для подписания',
          side: 'left' as any,
          onNextClick: () => {
            formik.handleSubmit()
          },
        },
      },
    ],
    [sendDocButtonRef.current]
  )

  const driverObj = useMemo(() => {
    if (onboarding.skip ||
      onboarding.document_send?.finished ||
      !onboarding.document_create?.finished ||
      status !== EDocumentStatus.DRAFT
    )
      return null
    return driver({
      showProgress: true,
      popoverClass: 'onboarding popover',
      progressText: '{{current}} из {{total}}',
      nextBtnText: 'Далее →',
      doneBtnText: 'Далее →',
      showButtons: ['next', 'close'],
      onCloseClick: finishSendDocStep,
      onDestroyed: () => setWithPulse(false),
      steps: steps,
    })
  }, [
    onboarding.document_send?.finished,
    onboarding.document_create?.finished,
    status,
  ])

  return { driverObj }
}
