import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { deleteTemplate } from 'service/template'

export const useActionTemplateDelete = () => {
  const [loading, setLoading] = useState(false)

  const run = useCallback(
    async ({
      templateId,
      onSuccessCallback,
      setError,
    }: {
      templateId: string
      setError?: Dispatch<SetStateAction<string>>
      onSuccessCallback: () => any
    }) => {
      try {
        setLoading(true)
        await deleteTemplate({ templateId })
        if (onSuccessCallback) onSuccessCallback()
      } catch (err: any) {
        if (setError) setError(err.message)
      } finally {
        setLoading(false)
      }
    },
    [setLoading]
  )

  return { loading, run }
}
