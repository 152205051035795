import img_copy from 'assets/img/content_copy.svg'
import img_archive from 'assets/img/menu-actions/archive.svg'
import img_backspace from 'assets/img/menu-actions/backspace.svg'
import img_delete from 'assets/img/menu-actions/delete_red.svg'
import img_eye from 'assets/img/menu-actions/eye.svg'
import img_mvfolder from 'assets/img/move_folder.svg'

import { Button } from '@mui/material'
import { ActionMenu } from 'components/action-menu/actionMenu'
import { ModalActionDocument } from 'components/modal-actions/modalActionDocument'
import { MessageSnackbar } from 'components/snackbar'
import { useSnackbar } from 'components/snackbar/hook'
import { useSelectedDocsContext } from 'context'
import { ModalMoveDocumentsBetweenTeamspaces } from 'features/move-documents-between-teamspaces/components/ModalMoveDocumentsBetweenTeamspaces'
import { EDocumentStatus, UrlKeys } from 'helper/consts'
import { useModal } from 'hooks/useModal'
import { usePermissionDocumentDeleteInTeamspace } from 'pages/documents/hooks/usePermissionDocumentDeleteInTeamspace'
import { usePermissionDocumentRevokeInTeamspace } from 'pages/documents/hooks/usePermissionDocumentRevokeInTeamspace'
import { useNavigate, useParams } from 'react-router-dom'
import {
  archiveFile,
  deleteDocument,
  restoreDocument,
  revokeFile,
  unarchiveDocument,
} from 'service'
import { queryDocumentsMoveDocumentsToTeamspace } from 'service/documents'
import { canArchive, canDelete, canRevoke } from 'utils'

export const DocumentActions = ({ anchorEl, handleClose, document, open }) => {
  const { setHiddenDocumentIds, dubls } = useSelectedDocsContext()

  const { tsId } = useParams()
  const navigate = useNavigate()

  const [modalDeleteOpen, openModalDelete, closeModalDelete] = useModal()
  const [modalArchiveOpen, openModalArchive, closeModalArchive] = useModal()
  const [modalRevokeOpen, openModalRevoke, closeModalRevoke] = useModal()
  const [modalMoveOpen, openModalMove, closeModalMove] = useModal()

  const { snackbar, showSnackbar, closeSnackbar } = useSnackbar()

  const permDelete = usePermissionDocumentDeleteInTeamspace()
  const permRevoke = usePermissionDocumentRevokeInTeamspace()

  if (!document) return null
  const { status, id } = document

  const openDocument = (document) => {
    const { template_id, status, id } = document
    navigate(
      status === EDocumentStatus.DRAFT && template_id
        ? `/teamspace/${tsId}/documents/${id}?${UrlKeys.templateId}=${template_id}`
        : `/teamspace/${tsId}/documents/${id}`
    )
  }

  const actionArchiveDocument = async () => {
    const btns = [
      <Button
        size="small"
        color="secondary"
        variant="outlined"
        sx={{ height: '24px' }}
        onClick={unarchive}
      >
        Отменить
      </Button>,
    ]

    try {
      await archiveFile({ id })
      setHiddenDocumentIds((state) => [...state, id])
      showSnackbar({
        message: 'Документ архивирован',
        severity: 'success',
        close: closeSnackbar,
        buttons: btns,
      })
      handleClose()
    } catch (error) {
      if (error instanceof Error) {
        showSnackbar({ message: error.message, severity: 'error' })
      }
    }
  }

  const restore = async () => {
    try {
      const res = await restoreDocument({ id })
      setHiddenDocumentIds((state) => state.filter((_id) => _id !== id))
      showSnackbar({ message: 'Отменено', severity: 'info' })
      return res
    } catch (error: any) {
      if (error instanceof Error) {
        showSnackbar({
          message: error.message,
          severity: 'error',
          buttons: [],
          close: closeSnackbar,
        })
      }
    }
  }

  const unarchive = async () => {
    try {
      await unarchiveDocument({ id })
      setHiddenDocumentIds((state) => state.filter((_id) => _id !== id))
      showSnackbar({ message: 'Отменено', severity: 'info' })
    } catch (error: any) {
      if (error instanceof Error) {
        showSnackbar({
          message: error.message,
          severity: 'error',
          buttons: [],
          close: closeSnackbar,
        })
      }
    }
  }

  const actionDeleteDocument = async () => {
    const btns = [
      <Button
        size="small"
        color="secondary"
        variant="outlined"
        sx={{ height: '24px' }}
        onClick={restore}
      >
        Отменить
      </Button>,
    ]
    try {
      const resp = await deleteDocument({ id })
      setHiddenDocumentIds((state) => [...state, id])
      showSnackbar({
        message: 'Документ удален',
        severity: 'info',
        buttons: btns,
        close: closeSnackbar,
      })
      handleClose()
      return resp
    } catch (error) {
      if (error instanceof Error) {
        showSnackbar({ message: error.message, severity: 'error' })
      }
    }
  }

  const actionRevokeDocument = async () => {
    try {
      const resp = await revokeFile({ id })
      showSnackbar({
        message: 'Документ отозван',
        severity: 'info',
        buttons: [],
        close: closeSnackbar,
      })
      handleClose()
      return resp
    } catch (error) {
      if (error instanceof Error) {
        showSnackbar({ message: error.message, severity: 'error' })
      }
    }
  }

  const onSuccessMoveDocument = async () => {
    const cancelMove = async () => {
      closeSnackbar()

      try {
        await queryDocumentsMoveDocumentsToTeamspace({
          documentIds: [id],
          teamspaceId: tsId ?? '',
        })
        setHiddenDocumentIds((state) => state.filter((_id) => _id !== id))
        showSnackbar({ message: 'Восстановлено', severity: 'info' })
      } catch (error) {
        console.error(error)
        if (error instanceof Error) {
          showSnackbar({
            message: error.message,
            severity: 'error',
            close: closeSnackbar,
          })
        }
      }
    }

    const btns = [
      <Button
        size="small"
        color="secondary"
        variant="outlined"
        onClick={cancelMove}
      >
        Отменить
      </Button>,
    ]
    setHiddenDocumentIds((state) => [...state, id])
    showSnackbar({
      message: 'Документ перемещен',
      severity: 'info',
      buttons: btns,
      close: closeSnackbar,
    })
    handleClose()
  }

  const modalConfigs = [
    {
      key: 'revoke-modal',
      handleClose: closeModalRevoke,
      open: modalRevokeOpen,
      action: actionRevokeDocument,
      title: 'Отозвать документ?',
      description:
        'Вы уверены? Если вы это сделаете текущий прогресс документа будет удален',
      btnTitle: 'Отозвать',
    },
    {
      key: 'delete-modal',
      handleClose: closeModalDelete,
      open: modalDeleteOpen,
      action: actionDeleteDocument,
      title: 'Удалить документ?',
      description:
        'Документ можно будет восстановить или удалить безвозвратно в “Корзине”',
      btnTitle: 'Удалить',
    },
    {
      key: 'archive-modal',
      handleClose: closeModalArchive,
      open: modalArchiveOpen,
      action: actionArchiveDocument,
      title: 'Архивировать документ?',
      description:
        'Доступ к документу закроется. Документ можно разархивировать на странице “Архив”.',
      btnTitle: 'Архивировать',
    },
  ]

  return (
    <>
      <MessageSnackbar
        message={snackbar.message}
        clearMessage={snackbar.close}
        severity={snackbar.severity}
        buttons={snackbar.buttons}
      />
      <ActionMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        actions={[
          [
            {
              src: img_eye,
              title: 'Открыть',
              disabled: false,
              action: () => openDocument(document),
            },
            {
              src: img_copy,
              title: 'Дубликат',
              action: () => {
                handleClose()
                dubls.createDublicates({
                  documentIds: [document.id],
                })
              },
            },
            {
              src: img_mvfolder,
              title: 'Переместить',
              action: () => {
                handleClose()
                openModalMove()
              },
            },
          ],
          [
            {
              src: img_archive,
              title: 'Архивировать',
              disabled: !canArchive(),
              action: () => {
                handleClose()
                openModalArchive()
              },
            },
            {
              src: img_backspace,
              title: 'Отозвать',
              disabled: !canRevoke(status) || !permRevoke.canDoOne(document),
              action: () => {
                handleClose()
                openModalRevoke()
              },
            },
            {
              src: img_delete,
              title: 'Удалить',
              disabled: !canDelete(status) || !permDelete.canDoOne(document),
              color: '#E92C2C',
              action: () => {
                handleClose()
                openModalDelete()
              },
            },
          ],
        ]}
      />

      <ModalMoveDocumentsBetweenTeamspaces
        open={modalMoveOpen}
        handleClose={closeModalMove}
        documentIds={[id]}
        onSuccessCallback={onSuccessMoveDocument}
      />

      {modalConfigs.map((config) => (
        <ModalActionDocument
          key={config.key}
          handleClose={config.handleClose}
          open={config.open}
          action={config.action}
          title={config.title}
          description={config.description}
          btnTitle={config.btnTitle}
        />
      ))}
    </>
  )
}
