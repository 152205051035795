/**
 * EToolsElement - Хранит все елементы toolbar редактора.
 * Используется в качестве:
 * 
 * - Названия класса для кнопки
 * - Перевод елемента для кнопки
 * - Значений типов разных елементов инструментов
 */
export enum EToolsElement {
  P = 'paragraph',
  H1 = 'heading-1',
  H2 = 'heading-2',
  H3 = 'heading-3',
  H4 = 'heading-4',
  H5 = 'heading-5',
  H6 = 'heading-6',
  Quote = 'quote',
  CodeBlock = 'codeblock',
  ListNum = 'list-numbered',
  ListBul = 'list-bulleted',

  Bold = 'bold',
  Italic = 'italic',
  Underline = 'underline',
  Strikethrough = 'strikethrough',
  Code = 'code',
  Superscript = 'superscript',
  Subscript = 'subscript',
  TextFormatClear = 'text-format-clear',

  AlignLeft = 'align-left',
  AlignCenter = 'align-center',
  AlignRight = 'align-right',
  AlignJustify = 'align-justify',
  Indent = 'indent',
  Outdent = 'outdent',

  Link = 'link',
  Table = 'table',
  Image = 'image',
  Divider = 'horizontal-rule',
  Pagebreak = 'page-break',

  ColumnsList = 'columns-list',
  ColumnsList2Columns = 'columns-list-2-columns',
  ColumnsList3Columns = 'columns-list-3-columns',
  ColumnsList4Columns = 'columns-list-4-columns',
}


export type TTextType =
  | EToolsElement.P
  | EToolsElement.H1
  | EToolsElement.H2
  | EToolsElement.H3
  | EToolsElement.H4
  | EToolsElement.H5
  | EToolsElement.H6
  | EToolsElement.ListNum
  | EToolsElement.ListBul
  | EToolsElement.Quote
  | EToolsElement.CodeBlock;

export type TTextAlignment =
  | EToolsElement.AlignLeft
  | EToolsElement.AlignCenter
  | EToolsElement.AlignRight
  | EToolsElement.AlignJustify;

export type TTextFormat =
  | EToolsElement.Bold
  | EToolsElement.Italic
  | EToolsElement.Underline
  | EToolsElement.Strikethrough
  | EToolsElement.Code
  | EToolsElement.Superscript
  | EToolsElement.Subscript
  | EToolsElement.TextFormatClear;

export const AlignmentCommand = {
  [EToolsElement.AlignLeft]: 'left',
  [EToolsElement.AlignCenter]: 'center',
  [EToolsElement.AlignRight]: 'right',
  [EToolsElement.AlignJustify]: 'justify',
}

export const ToolsTranslation = {
  [EToolsElement.P]: 'Обычный текст',
  [EToolsElement.H1]: 'Заголовок 1',
  [EToolsElement.H2]: 'Заголовок 2',
  [EToolsElement.H3]: 'Заголовок 3',
  [EToolsElement.H4]: 'Заголовок 4',
  [EToolsElement.H5]: 'Заголовок 5',
  [EToolsElement.H6]: 'Заголовок 6',
  [EToolsElement.ListNum]: 'Нумерованный список',
  [EToolsElement.ListBul]: 'Маркированный список',
  [EToolsElement.Quote]: 'Цитата',
  [EToolsElement.CodeBlock]: 'Блок кода',

  [EToolsElement.Bold]: 'Жирный',
  [EToolsElement.Italic]: 'Курсив',
  [EToolsElement.Underline]: 'Подчеркнутый',
  [EToolsElement.Strikethrough]: 'Зачеркнутый',
  [EToolsElement.Code]: 'Код',
  [EToolsElement.Superscript]: 'Верхний индекс',
  [EToolsElement.Subscript]: 'Нижний индекс',
  [EToolsElement.TextFormatClear]: 'Очистить форматирование',

  [EToolsElement.AlignLeft]: 'Выравнивание по левому краю',
  [EToolsElement.AlignCenter]: 'Выравнивание по центру',
  [EToolsElement.AlignRight]: 'Выравнивание по правому краю',
  [EToolsElement.AlignJustify]: 'Выравнивание по ширине',
  [EToolsElement.Indent]: 'Добавить отступ',
  [EToolsElement.Outdent]: 'Убрать отступ',

  [EToolsElement.Link]: 'Ссылка',
  [EToolsElement.Image]: 'Изображение',
  [EToolsElement.Table]: 'Таблица',
  [EToolsElement.Divider]: 'Разделитель',
  [EToolsElement.Pagebreak]: 'Разделить страницу',

  [EToolsElement.ColumnsList]: 'Список колонок',
  [EToolsElement.ColumnsList2Columns]: '2 колонки',
  [EToolsElement.ColumnsList3Columns]: '3 колонки',
  [EToolsElement.ColumnsList4Columns]: '4 колонки',
}