import { Box } from '@mui/material'
import { FieldRenderer } from '..'

export interface IEmailFieldSettings {
  default_value: string
}

type FieldName = keyof IEmailFieldSettings

const configArray: Array<{
  name: FieldName
  label?: string
  type: string
  dependsOn?: FieldName
  description?: string
}> = []

export const emailFiledinitialValues: IEmailFieldSettings = {
  default_value: '',
}

export const EmailFieldForm = () => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      {configArray.map((field) => (
        <FieldRenderer field={field} />
      ))}
    </Box>
  )
}
