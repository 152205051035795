import { CAbstractRecipient } from "./CAbstractRecipient"
import { IStep } from "../steps/IStep"
import { CStepForm } from "../steps/CStepForm"
import { ERecipientRole } from "helper/consts"
import { RecipientPrirority } from "./const"
import { IRecipient } from "./IRecipient"
import { RecipientHelperNS } from "./RecipientHelperNS"

export interface IRecipientFiller {
  actor_id: number
  attrs: {
    email?: string,
    must_send_email: boolean
  }
}

export class CRecipientFiller extends CAbstractRecipient {
  actor_id!: number
  role: ERecipientRole.filler = ERecipientRole.filler
  attrs!: {
    email?: string,
    must_send_email: boolean
  }

  constructor(obj: IRecipientFiller) {
    super()
    this.actor_id = obj.actor_id
    this.attrs = obj.attrs
  }

  isSame(recipient: IRecipient): boolean {
    return RecipientPrirority[recipient.role] === RecipientPrirority[this.role]
      && recipient?.actor_id === this.actor_id
  }

  attachOnModeDefault(steps: IStep[]): boolean {
    const stepIndex = steps
      .findIndex(step => RecipientPrirority[step.recipients[0].role] === RecipientPrirority[this.role])

    if (stepIndex === -1) {
      const newStep = new CStepForm({ index: 1, recipients: [this] })
      RecipientHelperNS.InsertStepWithRecipientToSteps(steps, newStep)
    } else {
      if (steps[stepIndex].recipients.findIndex(recipient => this.isSame(recipient)) !== -1) return false

      steps[stepIndex].recipients.push(this)
    }
    return true
  }

  attachOnModeOrdered(steps: IStep[]): boolean {
    return this.attachOnModeDefault(steps)
  }
}