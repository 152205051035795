import { Box, Button, Typography } from '@mui/material'
import {
  EModalBannerBg,
  EModalBannerIcon,
  ModalSmallWithBanner,
} from '../ModalSmallWithBanner'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { isWorkspaceOwner } from 'utils/workspace/isWorkspaceOwner'
import { shallowEqual, useSelector } from 'react-redux'
import { useContext, useEffect, useState } from 'react'
import { EFeature, EWorkspacePlan } from 'helper/plan.types'
import { getWSPlanFeatureRestriction } from 'utils/plans'
import { AppBarContext } from 'context'
import { DASHBOARD_MODES } from 'layout/dashboard-layout'
import { sessionUtils } from 'utils/session'

export const ModalPlanDocumentCountLimitExhausted = () => {
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )
  const { plan_name, plan_info, workspace_id } = useSelector(
    (state: RootState) => state.wsPlan,
    shallowEqual
  )
  const { setMode } = useContext(AppBarContext)
  const [open, setOpen] = useState(false)

  const isOwner = isWorkspaceOwner(selectedWorkspace?.group_name)

  const handleClose = () => {
    sessionUtils.setModalPlanDocumentsCountLimitExhaustedHasShown(
      selectedWorkspace?.id ?? ''
    )
    setOpen(false)
  }

  useEffect(() => {
    const wsId = selectedWorkspace?.id
    if (!wsId || wsId != workspace_id || plan_name != EWorkspacePlan.Trial) {
      setOpen(false)
      return
    }
    const { exhausted } = getWSPlanFeatureRestriction(
      EFeature.documents_count_per_month,
      plan_info
    )
    setOpen(
      exhausted &&
        !sessionUtils.getModalPlanDocumentsCountLimitExhaustedHasShownValue(
          wsId
        )
    )
  }, [selectedWorkspace, workspace_id, plan_info, plan_name])

  if (selectedWorkspace?.id != workspace_id) return null
  return (
    <ModalSmallWithBanner
      open={open}
      handleClose={handleClose}
      banner={{
        background: EModalBannerBg.doodocs_logos_gray,
        icon: EModalBannerIcon.circle_orange_exclamation_mark,
      }}
      children={
        <Box p={'16px'}>
          <Typography variant="heading5">
            Пространство достигло лимита готовых документов бесплатного периода
            (Использовано 3 из 3)
          </Typography>
          <Typography mt={'8px'}>
            Не переживайте - ваши документы, надёжно хранятся у нас.
          </Typography>
          <Typography mt={'8px'}>
            {isOwner
              ? 'Чтобы продолжить подписывать документы выберите тариф.'
              : 'Пожалуйста, свяжитесь с владельцем пространства для выбора и оплаты тарифа'}
          </Typography>
          {isOwner && (
            <Box
              sx={{
                mt: '16px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <Link
                to={'/plan'}
                style={{
                  width: isMobile ? '100%' : 'auto',
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => {
                    setMode(DASHBOARD_MODES.SETTINGS)
                    handleClose()
                  }}
                  fullWidth
                >
                  Выбрать тариф
                </Button>
              </Link>
            </Box>
          )}
        </Box>
      }
    />
  )
}
