import { Box, Typography } from '@mui/material'
import { formatPrice } from 'pages/plan/lib/formatPrice'
import { EPlanPeriod, IPlanInfo } from 'pages/plan/types'

export const PriceBlock = ({
  plan,
  selectedPeriod,
}: {
  plan: IPlanInfo
  selectedPeriod: EPlanPeriod
}) => {
  const priceWithDiscount = formatPrice(
    plan.price[selectedPeriod] / selectedPeriod
  )
  const pricePerMonth = formatPrice(plan.price[EPlanPeriod.month])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
        }}
      >
        <Typography variant="heading3">{priceWithDiscount}₸</Typography>
        <Typography variant="paragraph3" color={'#6B6F76'}>
          /в месяц
        </Typography>
      </Box>
      {priceWithDiscount != pricePerMonth && (
        <Box
          sx={{
            width: 'fit-content',
            borderRadius: '4px',
            border: '1px solid var(--light-grayscale-border, #EDEDEC)',
            background: 'var(--light-grayscale-background-primary, #FFF)',
            px: '4px',
          }}
        >
          <Typography
            sx={{
              textDecoration: 'line-through',
            }}
          >
            {pricePerMonth}₸
          </Typography>
        </Box>
      )}
    </>
  )
}
