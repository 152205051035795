import { Typography } from '@mui/material'
import { ModalCenterSmall } from './centerSmall'
import { ModalCenterMedium } from './centerMedium'
import { ModalCenterLarge } from './centerLarge'
import { useMemo } from 'react'

const ModalComponents = {
  small: ModalCenterSmall,
  medium: ModalCenterMedium,
  large: ModalCenterLarge,
}

export const ModalCenterSelectableWithTitle = ({
  open,
  handleClose,
  modalType,
  title,
  subtitle,
  contentChild,
  footerChild,
}: {
  open: boolean
  handleClose: any | (() => void)
  modalType: 'small' | 'medium' | 'large'
  title: string
  subtitle?: string
  contentChild?: React.ReactNode
  footerChild?: React.ReactNode
}) => {
  const ModalComponent = useMemo(() => ModalComponents[modalType], [modalType])

  const headerChild = useMemo(() => {
    return (
      <>
        <Typography
          fontSize={'16px'}
          lineHeight={'24px'}
          fontWeight={'500'}
          sx={{
            width: `calc(100% - ${!!handleClose ? '20px' : '0px'})`,
          }}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="body2" mt={'2px'}>
            {subtitle}
          </Typography>
        )}
      </>
    )
  }, [title, subtitle])

  if (!ModalComponent) return null
  return (
    <ModalComponent
      open={open}
      handleClose={handleClose}
      header={{
        children: headerChild,
      }}
      content={
        contentChild
          ? {
              children: contentChild,
            }
          : undefined
      }
      footer={
        footerChild
          ? {
              children: footerChild,
            }
          : undefined
      }
    />
  )
}
