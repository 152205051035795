import { EDocumentStatus } from 'helper/consts'
import { shallowEqual } from 'react-redux'
import { useAppSelector } from 'store/store'
import { MenuItemButtonDocumentDownloadDDCard } from '../MenuItemButtonDocumentDownloadDDCard'
import { MenuItemButtonDocumentDownloadOriginal } from '../MenuItemButtonDocumentDownloadOriginal'

export const MenuItemButtonDocumentDownload = () => {
  const { document: doc } = useAppSelector(
    (state) => state.application,
    shallowEqual
  )

  if (!doc.id || doc.status == EDocumentStatus.DRAFT) {
    return null
  }
  if (doc.status == EDocumentStatus.COMPLETED) {
    return (
      <MenuItemButtonDocumentDownloadDDCard
        documentId={doc.id}
        filename={`${doc.name}-DDC.pdf`}
        linkDDC={doc.link_ddcard}
      />
    )
  }
  if (doc.link_pdf) {
    return (
      <MenuItemButtonDocumentDownloadOriginal
        linkPDF={doc.link_pdf}
        filename={`${doc.name}.pdf`}
      />
    )
  }
  return null
}
