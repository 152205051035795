import { Typography, Box } from '@mui/material'
import { useContext } from 'react'
import { WSPreviewContext } from '.'
import { AvatarWorkspace } from 'components/avatars/AvatarWorkspace'

export const WSPreview = () => {
  const { orgName, orgDescription, avatarFile } = useContext(WSPreviewContext)

  const showInfo = () => {
    return (
      <>
        <Typography variant={'body2'} color="text.disabled" fontWeight={500}>
          Документ на подписание от:
        </Typography>
        <Box display={'flex'}>
          <Box mr="8px" display={'flex'} alignItems="flex-start">
            <AvatarWorkspace name={orgName} src={avatarFile} size={40} />
          </Box>
          <Box width={'100%'} overflow={'hidden'}>
            <Box sx={{ display: 'flex' }}>
              {orgName ? (
                <Typography
                  variant="subtitle2"
                  textOverflow={'ellipsis'}
                  overflow="hidden"
                  whiteSpace={'nowrap'}
                >
                  {' '}
                  {orgName}
                </Typography>
              ) : (
                <Box
                  width={'40%'}
                  m="2px 0"
                  sx={{
                    background: '#EDEDEC',
                    borderRadius: '8px',
                    height: '20px',
                  }}
                ></Box>
              )}
            </Box>
            <Box
              display={'flex'}
              alignItems="center"
              width={'100%'}
              overflow={'hidden'}
            >
              {orgDescription ? (
                <Typography
                  color={'text.secondary'}
                  width={'100%'}
                  sx={{ wordBreak: 'break-word' }}
                >
                  {orgDescription}
                </Typography>
              ) : (
                <Box
                  width={'100%'}
                  m="2px 0"
                  sx={{
                    background: '#EDEDEC',
                    borderRadius: '8px',
                    height: '16px',
                  }}
                ></Box>
              )}
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  return (
    <Box
      sx={{
        borderRadius: '4px',
        padding: '16px',
        background: '#F5F5F5',
        border: '1px solid #EDEDEC;',
        width: { xs: '100%', sm: '400px' },
        maxHeight: 'calc(100vh - 48px)',
        overflow: 'hidden',
      }}
    >
      {showInfo()}
    </Box>
  )
}
