export enum EAiChatMessageStatus {
  fetching = 'fetching',
  generating = 'generating',
  completed = 'completed',
  error = 'error',
}

export enum EAiChatSystemCommand {
  none = 'none',
  init_legal_assistant = 'INIT_LEGAL_ASSISTANT',
  init_document_assistant = 'INIT_DOC_ASSISTANT',
}

/**
 * EAiChatAiType - Using for recoginze ai in load history  response
 */
export enum EAiChatAiType {
  document_assistant = 'document_assistant',
  legal_assistant = 'legal_assistant',
}

export enum EAiChatMessageAuthor {
  user = 'user',
  system = 'system',
  ai = 'ai',
  aiConsultant = `ai-document_assistant`,
  aiDocument = `ai-legal_assistant`,
}

export interface IAiChatMessage {
  author: EAiChatMessageAuthor
  text: string
  status: EAiChatMessageStatus
}

export interface IAiChatMessages {
  value: IAiChatMessage[]
  loading: boolean
  error: string
}

export interface IAiChatResponseMessage {
  value: IAiChatMessage | null
  loading: boolean
  error: string
}

export interface IAiChat {
  id: string
  messages: IAiChatMessages
  response_message: IAiChatResponseMessage
}
