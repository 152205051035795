import { LoadingHorisontalDots } from 'components'
import { Box, Typography } from '@mui/material'

export const ChatLoading = () => {
  return (
    <Box
      sx={{
        p: '12px 16px',
        background: '#F5F5F5',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <LoadingHorisontalDots sx={{ mr: '8px' }} />
        <Typography variant="body1" color={'#90959D'}>
          Doodocs AI анализирует документ...
        </Typography>
      </Box>
    </Box>
  )
}
