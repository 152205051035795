import { EDocumentStatus, UrlKeys } from 'helper/consts'
import { useParams, useNavigate } from 'react-router-dom'
import { unpublishTemplate } from 'service/template'

export const useTemplateActions = ({
  showSnackbar = null,
  templateId,
  status,
}: {
  showSnackbar?: any
  templateId: string
  status?: EDocumentStatus | string
}) => {
  const { tsId } = useParams()
  const navigate = useNavigate()

  const handleNavigateTemplate = () => {
    const path =
      status == EDocumentStatus.DRAFT
        ? `/teamspace/${tsId}/templates/${templateId}`
        : `/teamspace/${tsId}/documents/draft?${UrlKeys.templateId}=${templateId}`
    return navigate(path)
  }

  const actionEditTemplate = async () => {
    try {
      const resp = await unpublishTemplate({ templateId })
      navigate(`/teamspace/${tsId}/templates/${templateId}`)
      return resp
    } catch (error) {
      if (error instanceof Error) {
        showSnackbar &&
          showSnackbar({ message: error.message, severity: 'error' })
      }
    }
  }

  const actionDeleteTemplate = async () => {
    try {
      // const resp = await deleteTemplate({ templateId })
      // navigate(`/teamspace/${tsId}/templates/${templateId}`)
      return true
    } catch (error) {
      if (error instanceof Error) {
        showSnackbar &&
          showSnackbar({ message: error.message, severity: 'error' })
      }
    }
  }

  return {
    actionEditTemplate,
    actionDeleteTemplate,
    handleNavigateTemplate,
  }
}
