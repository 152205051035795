import { Typography } from '@mui/material'
import { formatDatePlan } from 'pages/plan/lib/formatDatePlan'
import { shallowEqual, useSelector } from 'react-redux'

export const TextActivePlanUntilDate = () => {
  const { plan_info } = useSelector(
    (state: RootState) => state.wsPlan,
    shallowEqual
  )

  return (
    <Typography variant="paragraph4" color={'#6B6F76'}>
      Действует до:{' '}
      {formatDatePlan(new Date(plan_info?.workspace_limits?.end_at ?? ''))}
    </Typography>
  )
}
