import { EServiceWorkflowStepType } from "helper/workflow.service.types"
import { CRecipientFiller } from "../recipients/CRecipientFiller"
import { CAbstractStep } from "./CAbstractStep"

export class CStepForm extends CAbstractStep {
  index: number
  type: EServiceWorkflowStepType.form = EServiceWorkflowStepType.form
  recipients: CRecipientFiller[]

  constructor(obj: {
    index: number,
    recipients: CRecipientFiller[]
  }) {
    super()

    this.index = obj.index
    this.recipients = obj.recipients
  }
}
