import { Box, Typography } from '@mui/material'
import { SettingItemWithToggle } from 'components/menu-item/SettingItemWithToggle'
import { useCallback } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import { manageTemplateSettingsSwitchSignMethodEGovMobile } from 'store/slices/manageTemplateSettings/manageTemplateSettingsSwitchSignMethodEGovMobile'
import { manageTemplateSettingsSwitchSignMethodEGovMobileBusiness } from 'store/slices/manageTemplateSettings/manageTemplateSettingsSwitchSignMethodEGovMobileBusiness'
import { manageTemplateSettingsSwitchSignMethodNCALayer } from 'store/slices/manageTemplateSettings/manageTemplateSettingsSwitchSignMethodNCALayer'
import { useAppSelector } from 'store/store'

export const TabContentSettingsSignMethods = () => {
  const dispatch = useDispatch<any>()
  const { id } = useAppSelector((state) => state.template, shallowEqual)
  const { signMethods, status, template_id } = useAppSelector(
    (state) => state.templateSettings,
    shallowEqual
  )

  const isFetching =
    id != template_id || status == 'idle' || status == 'loading'

  const canDisable = useCallback(
    () => Object.values(signMethods).filter((m) => m.isOn == true).length > 1,
    [signMethods]
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        paddingY: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="heading6">Доступные способы подписания</Typography>
        <Typography variant="paragraph4" color={'text.disabled'}>
          Выберите из списка возможные способы подписания для документов из
          этого шаблона
        </Typography>
      </Box>

      <SettingItemWithToggle
        title={{
          text: 'eGov mobile',
        }}
        description="Для физических лиц, подписание с мобильного приложения eGov mobile"
        onClick={() => {
          dispatch(
            manageTemplateSettingsSwitchSignMethodEGovMobile({
              value: !signMethods.eGovMobile.isOn,
            })
          )
        }}
        checked={signMethods.eGovMobile.isOn}
        disabled={
          signMethods.eGovMobile.loading ||
          (!canDisable() && signMethods.eGovMobile.isOn)
        }
        loading={isFetching}
      />

      <SettingItemWithToggle
        title={{
          text: 'eGov mobile Business',
        }}
        description="Для юридических лиц, подписание с мобильного приложения eGov mobile Business"
        onClick={() =>
          dispatch(
            manageTemplateSettingsSwitchSignMethodEGovMobileBusiness({
              value: !signMethods.eGovMobileBusiness.isOn,
            })
          )
        }
        checked={signMethods.eGovMobileBusiness.isOn}
        disabled={
          signMethods.eGovMobileBusiness.loading ||
          (!canDisable() && signMethods.eGovMobileBusiness.isOn)
        }
        loading={isFetching}
      />

      <SettingItemWithToggle
        title={{
          text: 'NCALayer',
        }}
        description="Для физических и юридических лиц, подписание возможно только с компьютера"
        onClick={() =>
          dispatch(
            manageTemplateSettingsSwitchSignMethodNCALayer({
              value: !signMethods.ncaLayer.isOn,
            })
          )
        }
        checked={signMethods.ncaLayer.isOn}
        disabled={
          signMethods.ncaLayer.loading ||
          (!canDisable() && signMethods.ncaLayer.isOn)
        }
        loading={isFetching}
      />
    </Box>
  )
}
