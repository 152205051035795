import img_check from 'assets/img/check_blue.svg'
import img_close from 'assets/img/close_gray.svg'

import { Box, Typography } from '@mui/material'

export const FeatureLimit = ({ plan }: { plan: number | boolean }) => {
  if (plan === -1) return <Typography>Без ограничений</Typography>
  if (plan === false)
    return <Box component={'img'} src={img_close} width={16} />
  if (plan === true) return <Box component={'img'} src={img_check} width={16} />
  return <Typography>{plan}</Typography>
}
