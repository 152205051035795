import { canDelete } from 'utils/allowed-actions-document/canDelete'
import { canDownload, canSign } from 'utils/index'

export const canDeleteDocuments = (documents) => {
  for (const document of documents.values()) {
    if (!canDelete(document.status)) return false
  }
  return true
}

export const canArchiveDocuments = () => {
  return true
}

export const canDownloadDocuments = (documents) => {
  for (const document of documents.values()) {
    if (!canDownload(document.status)) return false
  }
  return true
}

export const canSignDocuments = (documents) => {
  for (const document of documents.values()) {
    if (!canSign(document.status)) return false
  }
  return true
}
