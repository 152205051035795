import { EDocumentStatus, EDocumentType } from 'helper/consts'
import { useCallback, useEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { isMobile } from 'react-device-detect'

export enum SidebarMode {
  edit = 'edit',
  info = 'info',
  workflow = 'workflow',
  chat = 'chat',
  close = 'close',
}

const isRevokedOrDraft = (status: EDocumentStatus) =>
  status === EDocumentStatus.REVOKED || status === EDocumentStatus.DRAFT

const shouldCloseSidebar = (
  variables: any,
  status: EDocumentStatus,
  document_type: string
) =>
  document_type === EDocumentType.TEMPLATE &&
  isRevokedOrDraft(status) &&
  !variables

const shouldOpenEditSidebar = (
  variables: any,
  status: EDocumentStatus,
  document_type: string
) =>
  document_type === EDocumentType.TEMPLATE &&
  isRevokedOrDraft(status) &&
  variables

const shouldShowInfoSidebar = (status: EDocumentStatus) =>
  status === EDocumentStatus.DRAFT ||
  status === EDocumentStatus.REVOKED ||
  status === EDocumentStatus.DECLINED

export const useSidebarStateTemplate = () => {
  const template = useSelector(
    (state: RootState) => state.template,
    shallowEqual
  )

  const [sidebarMode, setSidebarMode] = useState(
    isMobile ? SidebarMode.close : SidebarMode.info
  )

  const updateSidebar = useCallback(
    (mode: SidebarMode) => {
      if (
        mode == SidebarMode.chat ||
        mode == SidebarMode.edit ||
        mode == SidebarMode.workflow ||
        mode == SidebarMode.info ||
        mode == SidebarMode.close
      )
        setSidebarMode(mode)
      else setSidebarMode(SidebarMode.close)
    },
    [setSidebarMode]
  )

  const sidebarIsOpen = () => {
    return sidebarMode != SidebarMode.close
  }
  const closeSidebar = () => {
    updateSidebar(SidebarMode.close)
  }

  useEffect(() => {
    if (isMobile) return

    switch (template.status) {
      case 'ready':
        updateSidebar(SidebarMode.edit)
        return
      default:
        updateSidebar(SidebarMode.close)
        return
    }
  }, [template.status])

  return {
    updateSidebar,
    sidebarMode,
    sidebarIsOpen,
    closeSidebar,
  }
}

export const useSidebarState = () => {
  const { document, variables } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )

  const [sidebarMode, setSidebarMode] = useState(
    isMobile ? SidebarMode.close : SidebarMode.info
  )

  const updateSidebar = useCallback(
    (mode: SidebarMode) => {
      if (
        mode == SidebarMode.chat ||
        mode == SidebarMode.edit ||
        mode == SidebarMode.workflow ||
        mode == SidebarMode.info ||
        mode == SidebarMode.close
      )
        setSidebarMode(mode)
      else setSidebarMode(SidebarMode.close)
    },
    [setSidebarMode]
  )

  const sidebarIsOpen = () => {
    return sidebarMode != SidebarMode.close
  }
  const closeSidebar = () => {
    updateSidebar(SidebarMode.close)
  }

  useEffect(() => {
    if (isMobile) return

    const { type: document_type, status } = document
    if (shouldCloseSidebar(variables, status, document_type)) {
      updateSidebar(SidebarMode.close)
      return
    }

    if (shouldOpenEditSidebar(variables, status, document_type)) {
      updateSidebar(SidebarMode.edit)
      return
    }

    if (shouldShowInfoSidebar(status)) {
      updateSidebar(SidebarMode.info)
      return
    }

    updateSidebar(SidebarMode.workflow)
  }, [document.status, variables, isMobile])
  return {
    updateSidebar,
    sidebarMode,
    sidebarIsOpen,
    closeSidebar,
  }
}
