import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { EDocumentStatus } from 'helper/consts'
import { TSRolePermissions } from 'helper/roles'
import { Empty } from 'components'
import { LoadDocument } from 'features'
import img_inbox from 'assets/img/inbox.svg'
import { DocumentsTable } from 'components'

const titleAndDecriptionEmptyPage = {
  [EDocumentStatus.ALL]: {
    description: 'Здесь будут храниться все документы этой группы',
  },
  [EDocumentStatus.DRAFT]: {
    description:
      'Здесь будут храниться документы, которые еще не готовы к отправке',
  },
  [EDocumentStatus.ACTIONS_REQUIRED]: {
    description:
      'Здесь будут отображаться документы, требующие вашего внимания',
  },
  [EDocumentStatus.WAITING_OTHERS]: {
    description:
      'Здесь будут отображаться документы, требующие действия других согласующих или подписантов',
  },
  [EDocumentStatus.COMPLETED]: {
    description: 'Здесь будут храниться все готовые документы',
  },
}

export const StandardDocumentsTable = () => {
  const { tsId } = useParams()

  const { count, documents, loading, filter, page }: IDocTableState =
    useSelector((state: RootState) => state.documents, shallowEqual)

  const { selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )

  const showEmptyPage = useMemo(() => {
    return (
      <Empty
        src={img_inbox}
        title={'Пока тут нет документов'}
        subtitle={titleAndDecriptionEmptyPage[filter].description}
      >
        {selectedTeamspace &&
          TSRolePermissions[selectedTeamspace?.group_name].canManageDocs && (
            <LoadDocument title={'Новый документ'} tsId={tsId} />
          )}
      </Empty>
    )
  }, [filter])

  if (count === 0) return showEmptyPage

  return <DocumentsTable page={page} documents={documents} loading={loading} />
}
