import { useFormik } from 'formik'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { buildValidationSchema } from 'utils/buildValidationSchema'
import { updateDraftVariables } from 'store/slices/templateSlice'
import { removeEmptyFromObject } from 'utils/remove-empty'

// const shouldInitialize = isEditableDraft({ template_id, id, document })

export function useConditionalFormik() {
  const { document, variables } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )

  const [initialValues, setInitialValues] = useState({})
  const [validationSchema, setValidationSchema] = useState({})

  const dispatch = useDispatch()

  const handleSubmit = async (values) => {
    const valuesWithoutEmpty = removeEmptyFromObject(values)

    document.id &&
      dispatch(
        updateDraftVariables({
          values: valuesWithoutEmpty,
          document_id: document.id,
        }) as any
      )
  }

  const defaultFormik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values)
    },
    validationSchema: validationSchema,
  })

  useEffect(() => {
    document.payload?.variables && setInitialValues(document.payload?.variables)
  }, [document.payload?.variables])

  useEffect(() => {
    variables && setValidationSchema(buildValidationSchema(variables))
  }, [variables])

  return defaultFormik
}
