import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as ReactDOM from 'react-dom'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { TextNode } from 'lexical'
import {
  LexicalTypeaheadMenuPlugin,
  useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin'
import { Box } from '@mui/material'
import { ComponentPickerOption } from 'features/text-editors/lexical/plugins/ComponentPickerPlugin'
import { InsertNewVariableMenu } from '../VariablePlugin/components/insert-new-variable-menu'
import { IMenuFormRef } from '../VariablePlugin/components/insert-new-variable-menu/helpers/types'
import { ListOfVariableOptions } from './components/list-of-variable-options'
import { variablesList } from './helpers'

enum VariableMenuMode {
  PICKER = 'PICKER',
  EDIT = 'EDIT',
}

export default function ParameterPickerPlugin({
  hasTemplateVariables,
}: {
  hasTemplateVariables?: boolean
}): JSX.Element {
  const menuFormRef = useRef<IMenuFormRef | null>(null)

  const [editor] = useLexicalComposerContext()
  const [queryString, setQueryString] = useState<string | null>(null)
  const [mode, setMode] = useState(VariableMenuMode.PICKER)
  const [variable, setVariable] = useState<any>(null)
  const [nodeToRemove, setNodeToRemove] = useState<TextNode | null>(null)

  const checkForTriggerMatch = useBasicTypeaheadTriggerMatch('=', {
    minLength: 0,
  })

  const cleanUp = () => {
    setMode(VariableMenuMode.PICKER)
    setNodeToRemove(null)
  }

  useEffect(() => {
    return () => {
      cleanUp()
      if (menuFormRef.current) {
        menuFormRef.current.submitForm()
      }
    }
  }, [editor])

  const getDynamicOptions = useCallback(() => {
    const options: Array<ComponentPickerOption> = []

    if (queryString == null) {
      return options
    }

    return options
  }, [editor, queryString])

  const options = useMemo(() => {
    const baseOptions = hasTemplateVariables
      ? variablesList.map((val) => {
          return new ComponentPickerOption(val.data.title, {
            icon: <Box component={'img'} src={val.meta.icon} />,
            keywords: val.meta.keyWords,
            onSelect: () => {
              setMode(VariableMenuMode.EDIT)
              setVariable(val)
            },
          })
        })
      : []

    const dynamicOptions = getDynamicOptions()

    return queryString
      ? [
          ...dynamicOptions,
          ...baseOptions.filter((option) => {
            return new RegExp(queryString, 'gi').exec(option.title) ||
              option.keywords != null
              ? option.keywords.some((keyword) =>
                  new RegExp(queryString, 'gi').exec(keyword)
                )
              : false
          }),
        ]
      : baseOptions
  }, [editor, getDynamicOptions, queryString])

  const onSelectOption = useCallback(
    (
      selectedOption: ComponentPickerOption,
      nodeToRemove: TextNode | null,
      closeMenu: () => void,
      matchingString: string
    ) => {
      selectedOption.onSelect(matchingString)
      nodeToRemove && setNodeToRemove(nodeToRemove)
    },
    [editor]
  )

  return (
    <>
      <LexicalTypeaheadMenuPlugin<ComponentPickerOption>
        onQueryChange={setQueryString}
        onSelectOption={onSelectOption}
        triggerFn={checkForTriggerMatch}
        options={options}
        onClose={cleanUp}
        menuRenderFn={(
          anchorElementRef,
          { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
        ) =>
          anchorElementRef.current && options.length
            ? ReactDOM.createPortal(
                <div className="typeahead-popover component-picker-menu">
                  <>
                    {mode === VariableMenuMode.EDIT &&
                      variable &&
                      hasTemplateVariables && (
                        <InsertNewVariableMenu
                          ref={menuFormRef}
                          variable={variable}
                          nodeToRemove={nodeToRemove}
                        />
                      )}
                    {mode === VariableMenuMode.PICKER && (
                      <ListOfVariableOptions
                        selectedIndex={selectedIndex}
                        setHighlightedIndex={setHighlightedIndex}
                        selectOptionAndCleanUp={selectOptionAndCleanUp}
                        options={options}
                      />
                    )}
                  </>
                </div>,
                anchorElementRef.current
              )
            : null
        }
      />
    </>
  )
}
