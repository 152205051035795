export enum EWorkspacePlan {
  Free = 'Free',
  Trial = 'Trial',
  Start = 'Start',
  Lite = 'Lite',
  Pro = 'Pro',
  Enterprise = 'Enterprise',
}


export enum EFeature {
  documents_count_per_month = 'documents_count_per_month',
  users_count = 'users_count',
  teamspaces_count = 'teamspaces_count',
  template_creation_count = 'template_creation_count',
  document_send_by_email = 'document_send_by_email',
  ai_chat = 'ai_chat',
  api_access = 'api_access',
  mass_send = 'mass_send',
}