import { Typography, Box } from '@mui/material'
import { useFormikContext } from 'formik'
import { TextFieldEmails } from 'components/text-fields'

export const SendInvite = () => {
  const { values, setFieldValue } = useFormikContext<{
    invites: any
  }>()
  return (
    <>
      <Typography variant="body1" mb="4px" fontWeight={500} mt="24px">
        Отправить приглашение на email
      </Typography>
      <TextFieldEmails
        emails={values.invites}
        setEmails={(emails) => {
          setFieldValue('invites', emails)
        }}
        placeholder="email@example.com email2@example.com..."
      />
    </>
  )
}

export const SetupInvitesStep = () => {
  return (
    <>
      <Box mt="32px">
        <SendInvite />
      </Box>
    </>
  )
}
