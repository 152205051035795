import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material'
import img_egov_b from 'assets/img/egov-b.svg'
import img_egov from 'assets/img/egov.svg'
import draw from 'assets/img/menu-send/draw.svg'
import { DocumentSignListenerContext } from 'components/contexts/documentSignListener'
import { MenuItemButton } from 'components/menu-item/menu-item-button'
import {
  EDocumentStatus,
  ERecipientRole,
  ESignOptionType,
  EgovMobileTypes,
} from 'helper/consts'
import { useContext, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateDocumentStatusAfterSign } from 'store/slices/applicationSlice'
import { startWorkflow } from 'store/slices/wfPostmanSlice'

export const SignMobile = ({ disabled, isMenu = false }) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <ModalSign open={open} handleClose={handleClose}></ModalSign>
      {isMenu ? (
        <MenuItemButton
          onClick={handleClick}
          srcImage={draw}
          title={'Подписываю только я'}
        />
      ) : (
        <Button variant="contained" onClick={handleClick} disabled={disabled}>
          Подписать
        </Button>
      )}
    </>
  )
}

const ModalSign = ({ handleClose, open }) => {
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('')

  const { document, sign_settings } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )
  const { user }: IUserState = useSelector(
    (state: any) => state.user,
    shallowEqual
  )

  const { sign } = useSelector(
    (state: RootState) => state.wfPostman,
    shallowEqual
  )

  const dispatch = useDispatch<any>()
  const { listenDocumentSignStatus } = useContext(DocumentSignListenerContext)

  useEffect(() => {
    if (type) {
      setLoading(true)
      if (!sign.mgov.egov_business && !sign.mgov.egov_mobile)
        dispatch(
          startWorkflow({
            id: document.id,
            documentType: document.type,
            status: document.status as EDocumentStatus,
            email: user.email,
            option: ESignOptionType.ME,
            callback: openEgov,
          }) as any
        )
      else if (sign.mgov.egov_business || sign.mgov.egov_mobile) openEgov()
    }
  }, [type, sign.mgov.egov_business, sign.mgov.egov_mobile])

  const listenSign = () => {
    listenDocumentSignStatus({
      fields: {
        documentId: document.id,
        originId: sign.origin?.recipient.origin_id || '',
        isRunning: true,
      },
      onResponseData: (data: string) => {
        if (data === 'signed') {
          dispatch(
            updateDocumentStatusAfterSign({
              id: document.id,
              logEvent: () => {
                return
              },
            })
          )
        }
      },
    })
  }

  const openEgov = () => {
    if (!sign.mgov.egov_mobile || !sign.mgov.egov_business) return
    listenSign()
    setLoading(false)
    const win: Window = window
    win.location =
      type == EgovMobileTypes.regular
        ? sign.mgov.egov_mobile
        : sign.mgov.egov_business
  }

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'fixed',
            bottom: '16px',
            left: '16px',
            bgcolor: 'white',
            boxShadow:
              '0px 3px 5px rgba(0, 0, 0, 0.1), 0px 1px 18px rgba(0, 0, 0, 0.06), 0px 6px 10px rgba(0, 0, 0, 0.08)',
            borderRadius: '4px',
            marginRight: '16px',
            width: 'calc(100% - 32px)',
          }}
        >
          <Box p={'12px 16px 4px'}>
            <Typography color={'text.disabled'} fontWeight={500}>
              Выберите способ{' '}
              {document.role === ERecipientRole.approver_rk
                ? 'согласования'
                : 'подписания'}
            </Typography>
          </Box>

          {sign_settings.is_sign_egov_enabled && (
            <Box>
              <ButtonSignEgov
                title={EgovMobileTypes.regular}
                description={'Для физических лиц'}
                loading={type === EgovMobileTypes.regular ? loading : false}
                disabled={false}
                logo={img_egov}
                event={() => setType(EgovMobileTypes.regular)}
              />
            </Box>
          )}

          {sign_settings.is_sign_egovbusiness_enabled && (
            <Box>
              <ButtonSignEgov
                title={EgovMobileTypes.business}
                description={'Для юридических лиц'}
                loading={type === EgovMobileTypes.business ? loading : false}
                disabled={false}
                logo={img_egov_b}
                event={() => setType(EgovMobileTypes.business)}
              />
            </Box>
          )}
        </Box>
      </Modal>
    </>
  )
}

export const ButtonSignEgov = ({
  title,
  description,
  event,
  loading,
  disabled,
  logo,
}) => {
  return (
    <Button
      disabled={disabled || loading ? true : false}
      fullWidth
      onClick={event}
      color={'secondary'}
      sx={{ justifyContent: 'flex-start', p: '8px 16px' }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Box display={'flex'} alignItems={'center'}>
          {logo && (
            <Box
              component={'img'}
              width={'32px'}
              height={'32px'}
              src={logo}
              mr={1}
            />
          )}
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="subtitle2">{title}</Typography>
            <Typography fontWeight={400} color={'#6B6F76'}>
              {description}
            </Typography>{' '}
          </Box>
        </Box>
      )}
    </Button>
  )
}
