import img_add from 'assets/img/plus_gray.svg'
import img_settings from 'assets/img/settings.svg'

import { Box, MenuItem, styled } from '@mui/material'
import { MessageSnackbar } from 'components'
import { ModalPlanLimit } from 'components/modal/ModalPlanLimit'
import { AppBarContext } from 'context'
import { SidebarMenuItemHelp } from 'features/help'
import { EFeature } from 'helper/plan.types'
import { WSRolePermissions } from 'helper/roles'
import { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearNotifications } from 'store/slices/workspace'
import { getWSPlanFeatureRestriction } from 'utils/plans'
import { DASHBOARD_MODES } from '.'
import { MenuItemOnboarding } from './components/MenuItemOnboarding'
import { InviteModalWS } from './components/create-group'

export const MenuItemGray = styled(MenuItem)(() => ({
  justifyContent: 'start',
  whiteSpace: 'nowrap',
  padding: '6px 16px',
  margin: 0,
  fontWeight: 400,
  borderRadius: 0,
  '&.Mui-selected': {
    backgroundColor: '#E8E8E8',
    // fontWeight: 500,
    '&:hover': {
      backgroundColor: '#E8E8E8',
    },
  },
  // '&.Mui-selected > img': {
  //   filter: 'invert()',
  // },
  '&:hover': {
    backgroundColor: '#E8E8E8',
  },
}))

export const SidebarBottomMenu = () => {
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )
  const { onboarding } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  )

  return (
    <>
      <SidebarMenuItemHelp />
      {selectedWorkspace &&
        WSRolePermissions[selectedWorkspace.group_name]?.inviteToWs && (
          <InviteToWsBtn />
        )}
      <MenuItemSettings />
      {onboarding.completed_steps < 3 && <MenuItemOnboarding />}
    </>
  )
}

export const InviteToWsBtn = () => {
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const { plan_info, loading } = useSelector(
    (state: RootState) => state.wsPlan,
    shallowEqual
  )

  const { notification } = useSelector(
    (state: RootState) => state.workspace,
    shallowEqual
  )

  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [hasLimit, setHasLimit] = useState(false)

  useEffect(() => {
    const { prohibited, exhausted } = getWSPlanFeatureRestriction(
      EFeature.users_count,
      plan_info
    )
    setHasLimit(prohibited || exhausted)
  }, [plan_info])

  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  return (
    <>
      {hasLimit ? (
        <ModalPlanLimit
          open={open}
          handleClose={handleClose}
          limitOn={EFeature.users_count}
        />
      ) : (
        <InviteModalWS
          open={open}
          handleClose={handleClose}
          name={selectedWorkspace?.name}
        />
      )}
      <MessageSnackbar
        message={notification.create_invite?.message}
        clearMessage={() => dispatch(clearNotifications() as any)}
        severity={notification.create_invite?.severity}
      />
      <MenuItemGray
        id="positioned-button"
        onClick={handleOpen}
        disabled={loading.plan}
      >
        <img
          src={img_add}
          alt=""
          width={'16px'}
          height={'16px'}
          style={{
            marginRight: '8px',
          }}
        />
        Пригласить участников
      </MenuItemGray>
    </>
  )
}

export const MenuItemSettings = () => {
  const { setMode, setOpenDrawer } = useContext(AppBarContext)
  const navigate = useNavigate()
  const handleClick = () => {
    if (isMobile) setOpenDrawer(false)
    navigate('/settings')
    setMode(DASHBOARD_MODES.SETTINGS)
  }

  return (
    <MenuItemGray onClick={handleClick}>
      <Box
        component={'img'}
        src={img_settings}
        width={'16px'}
        height={'16px'}
        style={{
          marginRight: '8px',
        }}
      />
      Настройки
    </MenuItemGray>
  )
}
