import { Box } from '@mui/material'
import { FieldRenderer } from '..'

export interface IInputTextSettings {
  has_max: boolean
  max: number
  has_min: boolean
  min: number
  numeric: boolean
  alpha: boolean
  default_value: string
}

type FieldName = keyof IInputTextSettings | BaseSettings

const configArray: Array<{
  name: FieldName
  label?: string
  type: string
  dependsOn?: FieldName
  description?: string
}> = [
  { name: 'has_min', label: 'Минимально символов', type: 'switch' },
  { name: 'min', type: 'number', dependsOn: 'has_min' },
  { name: 'has_max', label: 'Максимально символов', type: 'switch' },
  { name: 'max', type: 'number', dependsOn: 'has_max' },
  { name: 'numeric', label: 'Только цифры', type: 'switch' },
  { name: 'alpha', label: 'Только буквы', type: 'switch' },
]

export const inputTextinitialValues: IInputTextSettings = {
  default_value: '',
  has_max: false,
  max: 1,
  has_min: false,
  min: 0,
  numeric: false,
  alpha: false,
}

export const InputTextForm = () => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      {configArray.map((field, index) => (
        <FieldRenderer field={field} key={`${field.name}-index`} />
      ))}
    </Box>
  )
}
