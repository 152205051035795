import {
  FormControl,
  Select,
  Box,
  MenuItem,
  Divider,
  Typography,
  IconButton,
} from '@mui/material'
import { useState, useRef, useEffect } from 'react'
import img_plus from 'assets/img/add.svg'
import { ChipList, EditableInput } from '.'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import drag_img from 'assets/img/drag_indicator_black.svg'
import { useFormikContext } from 'formik'
import { IDropdownOptionAttrs } from '..'

export const useDraggable = (options, setOptions, setFieldValue) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 100, tolerance: 0 },
    }),
    useSensor(KeyboardSensor)
  )

  const handleDragEnd = (event) => {
    const { active, over } = event

    if (!over) return

    const activeIndex = options.findIndex((opt) => opt.text === active.id)
    const overIndex = options.findIndex((opt) => opt.text === over.id)

    if (activeIndex !== overIndex) {
      const reorderedOptions = [...options]
      const [removed] = reorderedOptions.splice(activeIndex, 1)
      reorderedOptions.splice(overIndex, 0, removed)
      setOptions(reorderedOptions)
      setFieldValue('options', reorderedOptions)
    }
  }

  return { sensors, handleDragEnd }
}

export const DropdownWithChips = () => {
  const { setFieldValue, values } = useFormikContext<{
    options: IDropdownOptionAttrs[]
  }>()
  const [options, setOptions] = useState<IDropdownOptionAttrs[]>([])
  const [inputValue, setInputValue] = useState('')
  const [showInput, setShowInput] = useState(true)
  const inputRef = useRef<any>(null)

  const { sensors, handleDragEnd } = useDraggable(
    options,
    setOptions,
    setFieldValue
  )

  useEffect(() => {
    setOptions(values.options)
  }, [values.options])

  const handleAddOption = () => {
    if (inputValue && !options.some((opt) => opt.text === inputValue)) {
      const newOption: IDropdownOptionAttrs = {
        index: options.length,
        text: inputValue,
      }
      setOptions((prev) => [...prev, newOption])
      setFieldValue('options', [...options, newOption])
      setInputValue('')
      setShowInput(false)
    }
  }

  const handleRemoveOption = (index: number) => {
    const updatedOptions = options.filter((opt) => opt.index !== index)
    setOptions(updatedOptions)
    setFieldValue('options', updatedOptions)
  }

  const handleInputChange = (
    value: string,
    originalOption: IDropdownOptionAttrs
  ) => {
    const updatedOptions = options.map((option) =>
      option.index === originalOption.index
        ? { ...option, text: value }
        : option
    )
    setOptions(updatedOptions)
    setFieldValue('options', updatedOptions)
  }

  const handleAddOptionClick = () => setShowInput(true)

  const handleKeyDownBlur = (e) => {
    if (e.key === 'Enter') {
      ;(e.target as HTMLInputElement).blur()
    }
  }

  const handleKeyDownAddOption = (e) => {
    e.stopPropagation()

    if (e.key === 'Enter') {
      handleAddOption()
      setShowInput(true)
    }
  }

  useEffect(() => {
    if (showInput) {
      inputRef.current?.focus()
    }
  }, [showInput])

  return (
    <FormControl variant="outlined" fullWidth>
      <Select
        multiple
        value={options}
        displayEmpty
        onChange={() => {}}
        renderValue={(selected) => {
          if (options.length === 0)
            return (
              <Typography color={'text.disabled'}>
                Добавьте пункты меню
              </Typography>
            )
          return (
            <Box sx={{ width: '100%' }}>
              <ChipList selected={selected} />
            </Box>
          )
        }}
        sx={{
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={options.map((opt) => opt.text)}
            strategy={verticalListSortingStrategy}
          >
            {options.map((option) => (
              <SortableItem key={option.text} id={option.text}>
                <MenuItem>
                  <Box>
                    <IconButton size="small" sx={{ mr: '4px', p: '4px' }}>
                      <Box
                        sx={{ width: '16px', height: '16px' }}
                        component={'img'}
                        src={drag_img}
                      ></Box>
                    </IconButton>
                  </Box>
                  <EditableInput
                    defaultValue={option.text}
                    onBlur={(value) => handleInputChange(value, option)}
                    onKeyDown={handleKeyDownBlur}
                    handleClose={(event) => {
                      event.stopPropagation()
                      handleRemoveOption(option.index)
                    }}
                  />
                </MenuItem>
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
        {showInput ? (
          <MenuItem>
            <EditableInput
              value={inputValue}
              onChange={setInputValue}
              onKeyDown={handleKeyDownAddOption}
              autoFocus
              handleClose={(event) => {
                event.stopPropagation()
                setShowInput(false)
                setInputValue('')
              }}
            />
          </MenuItem>
        ) : null}
        <Divider sx={{ m: '5px 0 6px 0' }} />
        <MenuItem onClick={handleAddOptionClick}>
          <Box
            component={'img'}
            width={'16px'}
            height={'16px'}
            m={'2px 8px 2px 0'}
            src={img_plus}
          />
          Добавить пункт
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {props.children}
    </div>
  )
}
