import { MessageSnackbar } from 'components'
import {
  IFormikFieldsPaymentRequisites,
  getFormPaymentRequisitesValidationSchema,
} from 'components/forms/FormikFormPaymentRequisites'
import { useFormik } from 'formik'
import { useContext, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import { planApi } from 'service/plan'
import { wsPlanSlice } from 'store/slices/wsPlan'
import { useAppSelector } from 'store/store'
import { downloadFileFromURL } from 'utils/downloadFileFromURL'
import { SelectPlanContext } from '../../SelectPlanProvider'
import { ModalPaymentInvoiceDownload } from './ModalPaymentInvoiceDownload'
import {
  EModalPaymentRequisitesFormType,
  ModalPaymentRequisitesForm,
} from './ModalPaymentInvoiceForm'
import { plansInfoMap } from 'pages/plan/types'

enum StepStates {
  Form = 'Form',
  DownloadInvoice = 'DownloadInvoice',
}

const usePlanPaymentGenerateInvoiceModals = () => {
  const [step, setStep] = useState<StepStates | undefined>()
  const [error, setError] = useState<string>()
  const { selectedPlan, selectedPeriod, requireClosingDocuments } =
    useContext(SelectPlanContext)
  const { selectedWorkspace } = useAppSelector(
    (state) => state.selectedWorkspace,
    shallowEqual
  )
  const { requisites } = useAppSelector((state) => state.wsPlan, shallowEqual)
  const dispatch = useDispatch<any>()

  const paymentInvoiceDownloadLink = useMemo(() => {
    let downloadLink: string
    const isEmpty = () => !downloadLink
    return {
      get: () => downloadLink,
      set: (link: string) => {
        downloadLink = link
      },
      isEmpty,
      clear: () => {
        downloadLink = ''
      },
      download: async () => {
        if (isEmpty()) return false
        downloadFileFromURL(downloadLink, 'Счет на оплату.pdf')
        return true
      },
    }
  }, [])

  const formik = useFormik<IFormikFieldsPaymentRequisites>({
    initialValues: {
      company_name: requisites?.company_name ?? '',
      bin: requisites?.bin ?? '',
      legal_address: requisites?.legal_address ?? '',
    },
    validationSchema: getFormPaymentRequisitesValidationSchema(),
    onSubmit: async (values) => {
      try {
        if (!selectedPlan) throw new Error('Не выбран тариф')
        if (!selectedWorkspace)
          throw new Error(
            'Не удалось получить информацию о текущем рабочем пространстве'
          )

        const { url } = await planApi.postPaymentInvoiceDownloadLink({
          workspace_id: selectedWorkspace.id,
          data: {
            require_closing_documents: requireClosingDocuments,

            requisites: values,

            total_months: selectedPeriod,
            subscription_plan_name: plansInfoMap[selectedPlan].serverName,
          },
        })

        dispatch(wsPlanSlice.actions.setRequisites(values))
        dispatch(
          wsPlanSlice.actions.setRequireClosingDocuments(
            requireClosingDocuments
          )
        )

        paymentInvoiceDownloadLink.set(url)
        setStep(StepStates.DownloadInvoice)
      } catch (err: any) {
        console.error(err)
        setError(err.message)
      }
    },
    enableReinitialize: true,
  })

  return {
    step,
    setStep,
    error,
    setError,
    formik,
    paymentInvoiceDownloadLink,
  }
}

export const PlanPaymentGenerateInvoiceModals = ({
  open,
  handleClose,
  handleClickBack,
}) => {
  const { formik, step, setStep, paymentInvoiceDownloadLink, error, setError } =
    usePlanPaymentGenerateInvoiceModals()

  useEffect(() => {
    if (!open) setStep(undefined)
    else setStep(StepStates.Form)
  }, [open])

  return (
    <>
      <MessageSnackbar
        severity={'error'}
        message={error}
        clearMessage={() => setError('')}
      />

      <ModalPaymentRequisitesForm
        open={step == StepStates.Form}
        handleClose={handleClose}
        formik={formik}
        handleClickBack={handleClickBack}
        formType={EModalPaymentRequisitesFormType.RequestInvoice}
      />

      <ModalPaymentInvoiceDownload
        open={step == StepStates.DownloadInvoice}
        handleClose={handleClose}
        handleClickBack={() => {
          paymentInvoiceDownloadLink.clear()
          setStep(StepStates.Form)
        }}
        handleClickDownload={() => {
          paymentInvoiceDownloadLink.download()
        }}
      />
    </>
  )
}
