import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { archiveFile } from 'service'
import { fileDataFetch } from 'store/slices/applicationSlice'

export const useArchive = (id) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const dispatch = useDispatch()

  const archive = async () => {
    setLoading(true)
    try {
      const res = await archiveFile({ id })
      setLoading(false)
      dispatch(fileDataFetch({ id }) as any)

      return res
    } catch (error: any) {
      if (error instanceof Error) {
        setError(error.message)
      } else setError('Ошибка')
      setLoading(false)
    }
  }

  return { loading, archive, error, setError }
}
