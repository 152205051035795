import { Box, Button, Typography } from '@mui/material'
import { useAiChatContext } from 'context'

export const ChatLoadHistoryError = () => {
  const aiChat = useAiChatContext()
  const handleClick = () => {
    aiChat.init(aiChat.state.id)
  }

  return (
    <Box
      sx={{
        p: '12px 16px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography sx={{ fontWeight: 500 }}>
          Ошибка загрузки, попробуйте снова
        </Typography>
        <Button
          disabled={aiChat.state.messages.loading}
          sx={{
            mt: '8px',
          }}
          color="secondary"
          variant="outlined"
          size="small"
          onClick={handleClick}
        >
          Обновить
        </Button>
      </Box>
    </Box>
  )
}
