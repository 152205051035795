import { Box, Button, Divider, Typography } from '@mui/material'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { TextInput, FormikSelectActors } from 'components'
import { Formik } from 'formik'
import {
  ESystemVariableType,
  VariableTypeTitles,
} from '../../../ParameterPickerPlugin/helpers'
import {
  InputTextForm,
  TextAreaForm,
  DropdownForm,
  NumberFieldForm,
  EmailFieldForm,
  FieldRenderer,
  CheckboxForm,
} from '../variable-form-fields'
import { $getNodeByKey } from 'lexical'
import { shallowEqual, useSelector } from 'react-redux'
import content_copy from 'assets/img/content_copy.svg'
import delete_outline from 'assets/img/delete_outline.svg'
import {
  IMenuFormRef,
  InsertVariableMenuMode,
  configFields,
  initialValues,
  validationSchemas,
} from './helpers/consts'
import { useVariableAction } from './hooks/useVariableContext'
import { forwardRef, useImperativeHandle, useMemo } from 'react'
import { DateForm } from '../variable-form-fields/date'

export const InsertNewVariableMenu = forwardRef<IMenuFormRef, any>(
  (props: any, ref) => {
    const {
      variable,
      nodeToRemove = null,
      mode = InsertVariableMenuMode.create,
      handleClose = null,
      context = null,
    } = props
    const { actors } = useSelector(
      (state: RootState) => state.template,
      shallowEqual
    )
    const [editor] = useLexicalComposerContext()
    const {
      handleDeleteVariable,
      handleCopyVariable,
      saveToLocalStorageAndUpdateVariable,
      insertNewVariable,
    } = useVariableAction(context, handleClose)

    const handleSubmit = (values) => {
      if (!variable.data.type) return
      switch (mode) {
        case InsertVariableMenuMode.create:
          const { id, propsWithId } = insertNewVariable(values)
          saveToLocalStorageAndUpdateVariable(id, propsWithId)
          break
        case InsertVariableMenuMode.edit:
          saveToLocalStorageAndUpdateVariable(variable.data.id, {
            ...variable.data,
            ...values,
          })
          handleClose && handleClose()
          break
      }
      removeInsertMenuNode()
    }

    const removeInsertMenuNode = () => {
      editor.update(() => {
        if (!nodeToRemove) return
        const node = $getNodeByKey(nodeToRemove.__key)
        if (node) {
          node.remove()
        }
      })
    }

    const renderedField = useMemo(() => {
      switch (variable.data.type) {
        case ESystemVariableType.INPUT_TEXT:
          return <InputTextForm />
        case ESystemVariableType.TEXT_AREA:
          return <TextAreaForm />
        case ESystemVariableType.DROPDOWN:
          return <DropdownForm />
        case ESystemVariableType.INPUT_EMAIL:
          return <EmailFieldForm />
        case ESystemVariableType.INPUT_NUMBER:
          return <NumberFieldForm />
        case ESystemVariableType.INPUT_DATE:
          return <DateForm />
        case ESystemVariableType.CHECKBOX:
          return <CheckboxForm />
        default:
          return <></>
      }
    }, [variable.data.type])

    return (
      <>
        <Box>
          <Box p="6px 16px 4px">
            <Typography variant="caption" color={'#90959D'}>
              {VariableTypeTitles[variable.data.type]}
            </Typography>
          </Box>
          <Formik
            initialValues={{
              name: variable.data.initialTitle ?? '',
              id: '',
              type: '',
              group_id: '',
              group_type: '',
              has_hint: false,
              hint: '',
              has_default: false,
              actor_id: 1,
              is_required: true,
              ...(initialValues[variable.data.type] || {}),
              ...variable.data,
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchemas[variable.data.type] || null}
          >
            {({ handleSubmit, submitForm, handleChange }) => {
              useImperativeHandle(ref, () => ({
                submitForm: submitForm,
              }))

              return (
                <form onSubmit={handleSubmit}>
                  <button type="submit" style={{ display: 'none' }} />
                  <Box p="6px 16px">
                    <TextInput
                      size="small"
                      fullWidth
                      placeholder="Название поля"
                      name="name"
                      autoFocus={true}
                    ></TextInput>
                  </Box>
                  <Box p="6px 16px">
                    <FormikSelectActors
                      label="Кто будет заполнять это поле?"
                      name="actor_id"
                      options={actors}
                      onChange={handleChange}
                    />
                  </Box>
                  {variable.data.type !==
                    ESystemVariableType.INPUT_PHONE_NUMBER &&
                    variable.data.type !== ESystemVariableType.INPUT_EMAIL && (
                      <Divider sx={{ mt: '5px', mb: '6px' }} />
                    )}
                  {renderedField}
                  <Divider sx={{ mt: '5px', mb: '6px' }} />
                  <Box display={'flex'} flexDirection={'column'}>
                    {(
                      configFields[variable.data.type] ??
                      configFields[ESystemVariableType.INPUT_TEXT]
                    ).map((field, index) => (
                      <FieldRenderer
                        field={field}
                        key={`${field.name}-${index}`}
                      />
                    ))}
                  </Box>
                  {mode === InsertVariableMenuMode.edit && (
                    <ShowVariableActions
                      handleDeleteVariable={handleDeleteVariable}
                      handleCopyVariable={handleCopyVariable}
                    />
                  )}
                </form>
              )
            }}
          </Formik>
        </Box>
      </>
    )
  }
)

const ShowVariableActions = ({ handleDeleteVariable, handleCopyVariable }) => {
  return (
    <>
      <Divider sx={{ mt: '5px', mb: '6px' }} />
      <Box m={'2px 4px'}>
        <Button
          onClick={handleCopyVariable}
          startIcon={
            <Box
              component={'img'}
              src={content_copy}
              width={'16px'}
              height={'16px'}
            />
          }
          fullWidth
          color="secondary"
          sx={{
            justifyContent: 'flex-start',
            p: '4px 12px!important',
          }}
        >
          Скопировать
        </Button>
      </Box>
      <Box m={'2px 4px'}>
        <Button
          fullWidth
          sx={{
            justifyContent: 'flex-start',
            p: '4px 12px!important',
          }}
          startIcon={
            <Box
              component={'img'}
              src={delete_outline}
              width={'16px'}
              height={'16px'}
            />
          }
          color="secondary"
          onClick={handleDeleteVariable}
        >
          Удалить
        </Button>
      </Box>
    </>
  )
}
