import { MessageSnackbar } from 'components'
import { MenuTemplateActions } from 'components/menu/MenuTemplateActions'
import { ModalActionDocument } from 'components/modal-actions/modalActionDocument'
import { useActionTemplateDublicate } from 'features/copy-template/hooks/useActionTemplateDublicate'
import { ModalMoveTemplatesBetweenTeamspaces } from 'features/move-templates-between-teamspaces/components/ModalMoveTemplatesBetweenTeamspaces'
import { useActionTemplateMove } from 'features/move-templates-between-teamspaces/hooks/useActionTemplateMove'
import { useActionsTemplateFromTemplate } from 'hooks/useActionsTemplateFromTemplate'
import { useModal } from 'hooks/useModal'
import { createPortal } from 'react-dom'
import { useNavigate } from 'react-router-dom'
import { getWindowLocationPathnameWithGetParams } from 'utils/getWindowLocationPathnameWithGetParams'
import { restrictionsTemplate } from 'utils/restrictions/template'

interface IMenutemplateActionsFromTemplateProps {
  open: boolean
  handleClose: () => any
  anchorEl: any
  teamspaceId: string
  template: ITemplate
}

export const MenuTemplateActionsFromTemplate = ({
  open,
  handleClose,
  anchorEl,
  teamspaceId,
  template,
}: IMenutemplateActionsFromTemplateProps) => {
  const navigate = useNavigate()

  const {
    error,
    setError,
    handleClickEdit,
    handleClickUse,
    handleClickDelete,
    loadingDelete,
  } = useActionsTemplateFromTemplate({
    teamspaceId,
    template,
  })
  const actionMove = useActionTemplateMove()
  const actionDublicate = useActionTemplateDublicate()

  const [modalDeleteOpen, openModalDelete, closeModalDelete] = useModal()
  const [modalEditOpen, openModalEdit, closeModalEdit] = useModal()
  const [modalMoveOpen, openModalMove, closeModalMove] = useModal()

  const handleClickDublicate = async () => {
    await actionDublicate.run({
      templateId: template.id,
      onSuccessCallback: (createdTemplateId) => {
        const redirectPath =
          getWindowLocationPathnameWithGetParams().replaceAll(
            template.id,
            createdTemplateId
          )
        navigate(redirectPath)
      },
      setError,
    })
  }

  const modalConfigs = [
    {
      key: 'edit-template-modal',
      handleClose: closeModalEdit,
      open: modalEditOpen,
      action: handleClickEdit,
      title: 'Изменить шаблон',
      description: `Вы уверены? Если вы это сделаете: \n\n Любые внесенные изменения в шаблоне будут применены у всех последующих документов, созданных с использованием этого шаблона.`,
      btnTitle: 'Изменить шаблон',
    },
    {
      key: 'delete-template-modal',
      handleClose: closeModalDelete,
      open: modalDeleteOpen,
      action: handleClickDelete,
      title: 'Удалить шаблон?',
      description: `Шаблон можно будет восстановить или удалить безвозвратно в “Корзине”.

      Документы, созданные из этого шаблона, сохранятся и будут доступны в этом пространстве.`,
      btnTitle: 'Удалить',
    },
  ]

  return (
    <>
      <MenuTemplateActions
        open={open}
        handleClose={handleClose}
        anchorEl={anchorEl}
        actions={{
          dublicate: {
            action: handleClickDublicate,
            disabled: actionDublicate.loading,
            hidden: !restrictionsTemplate.canDublicate(template),
          },
          use: {
            action: handleClickUse,
            hidden: !restrictionsTemplate.canUse(template),
          },
          edit: {
            action: openModalEdit,
            hidden: !restrictionsTemplate.canEdit(template),
          },
          move: {
            action: openModalMove,
            disabled: actionMove.loading,
          },
          delete: {
            action: openModalDelete,
            disabled: loadingDelete,
          },
        }}
      />

      {modalConfigs.map((config) => (
        <ModalActionDocument
          key={config.key}
          handleClose={config.handleClose}
          open={config.open}
          action={config.action}
          title={config.title}
          description={config.description}
          btnTitle={config.btnTitle}
          btnColor={
            config.key === 'delete-template-modal' ? 'error' : 'primary'
          }
          btnVariant={
            config.key === 'delete-template-modal' ? 'outlined' : 'contained'
          }
        />
      ))}

      <ModalMoveTemplatesBetweenTeamspaces
        open={modalMoveOpen}
        handleClose={closeModalMove}
        templateIds={[template.id]}
        actionMoveTemplates={async (props) => {
          await actionMove.run({
            teamspaceId: props.teamspaceId,
            templateIds: props.templateIds,
            setError,
            onSuccessCallback: (movedTeamspaceId) => {
              closeModalMove()
              const redirectPath =
                getWindowLocationPathnameWithGetParams().replaceAll(
                  teamspaceId,
                  movedTeamspaceId
                )
              navigate(redirectPath)
            },
          })
        }}
      />

      {createPortal(
        <MessageSnackbar
          clearMessage={() => setError('')}
          message={error}
          severity={'error'}
        />,
        document.body
      )}
    </>
  )
}
