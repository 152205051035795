import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Divider,
} from '@mui/material'
import { EDocumentStatus } from 'helper/consts'
import { CopyToClipboard } from 'pages/document-preview/components'
import { useSelector, shallowEqual } from 'react-redux'
import { config } from 'config'
import { useCallback } from 'react'
import { setOnboardingCookie } from 'utils/setOnboardingCookie'

export const ShowOrGenerateLink = ({ formik, driverObj }) => {
  const { document }: IApplicationState = useSelector(
    (state: any) => state.application,
    shallowEqual
  )
  const { sign } = useSelector(
    (state: RootState) => state.wfPostman,
    shallowEqual
  )

  return (
    <>
      {(!sign.origin?.recipient.link ||
        document.status === EDocumentStatus.REVOKED) &&
        document.status !== EDocumentStatus.SENT && (
          <GenerateLinkForm formik={formik} />
        )}

      {sign.origin?.recipient.link &&
        document.status !== EDocumentStatus.REVOKED && (
          <ShowLink formik={formik} driverObj={driverObj} />
        )}
    </>
  )
}

const GenerateLinkForm = ({ formik }) => {
  const { loading } = useSelector(
    (state: RootState) => state.wfPostman,
    shallowEqual
  )

  return (
    <>
      <Box p="4px 12px 12px">
        <form onSubmit={formik.handleSubmit}>
          <Typography
            mb="18px"
            sx={{
              fontSize: { xs: '14px' },
              lineHeight: { xs: '20px' },
              fontWeight: 500,
              color: '#90959D',
            }}
          >
            Создать ссылку для подписания
          </Typography>
          <Typography
            mb="4px"
            color="text.secondary"
            variant="body1"
            fontWeight={500}
          >
            Количество подписантов
          </Typography>
          <TextField
            label={''}
            id={'create-link-form'}
            name="number"
            onChange={formik.handleChange}
            placeholder={'1'}
            defaultValue={1}
            fullWidth
            size="small"
            type={'number'}
            error={
              formik.touched['number'] && formik.errors['number'] ? true : false
            }
          ></TextField>
          <Typography color={'text.disabled'} mt="4px" mb="8px">
            При достижении этого количества уникальных подписей документ будет
            считаться готовым
          </Typography>
          <Button
            id={'create-link-button'}
            type="submit"
            variant="outlined"
            color="secondary"
            fullWidth
            disabled={loading.sign ? true : false}
          >
            {loading.sign ? <CircularProgress /> : 'Создать ссылку'}
          </Button>
        </form>
      </Box>
      <Box
        sx={{ background: '#F7F7F7' }}
        // mt="10px"
        p={'12px 16px'}
        mb="-8px"
        borderTop={'1px solid #E8E8E8'}
      >
        <Typography color={'text.secondary'}>
          После создания ссылки документ перейдет на статус «Отправлен»
        </Typography>
      </Box>
    </>
  )
}

const ShowLink = ({ driverObj, formik }) => {
  const { sign } = useSelector(
    (state: RootState) => state.wfPostman,
    shallowEqual
  )

  const handleClickCopy = useCallback(
    (copy) => {
      const link =
        sign.origin?.recipient.link === '/'
          ? `${config.DOMAIN_SIGN_DOODOCS}${sign.origin?.recipient.link}`
          : `${'https://link.doodocs.kz/'}${sign.origin?.recipient.link}`
      copy(link)

      if (driverObj) {
        setTimeout(() => {
          driverObj?.moveNext()
        }, 200)
        window.open(link, '_blank')
        setOnboardingCookie()
      }
    },
    [formik]
  )

  return (
    <>
      <Box p="4px 12px 12px">
        <Typography mb="4px" color={'text.secondary'}>
          Ссылка для подписания
        </Typography>

        <TextField
          label={''}
          value={
            sign.origin?.recipient.link === '/'
              ? `${config.DOMAIN_SIGN_DOODOCS}${sign.origin?.recipient.link}`
              : `${'https://link.doodocs.kz/'}${sign.origin?.recipient.link}`
          }
          fullWidth
          size="small"
        ></TextField>

        <Typography color={'text.disabled'} mt="4px" mb="8px">
          У всех у кого будет ссылка смогут подписать документ. Скопируйте эту
          ссылку, чтобы поделиться документом.
        </Typography>

        <div id="copy-link">
          <CopyToClipboard>
            {({ copy }) => (
              <Button
                type="submit"
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={() => handleClickCopy(copy)}
              >
                Скопировать ссылку
              </Button>
            )}
          </CopyToClipboard>
        </div>
      </Box>
      <Divider sx={{ mb: '6px' }} />
    </>
  )
}
