import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { Fragment, useState } from 'react'

export const ModalActionDocument = ({
  handleClose,
  open,
  action,
  title,
  description,
  btnTitle,
  btnColor = 'error',
  btnVariant = 'outlined',
}: {
  handleClose: (e) => void
  open: boolean
  action: () => {}
  title: string
  description: string
  btnTitle: string
  btnColor?: any
  btnVariant?: any
}) => {
  const [loading, setLoading] = useState(false)

  const handleClick = async (e) => {
    setLoading(true)
    await action()
    setLoading(false)
    handleClose(e)
  }

  return (
    <ModalCenterSelectableWithTitle
      handleClose={handleClose}
      title={title}
      open={open}
      modalType="small"
      contentChild={
        <Typography variant="body1">
          {description.split('\n').map((line, index) => (
            <Fragment key={index}>
              {line}
              <br />
            </Fragment>
          ))}
        </Typography>
      }
      footerChild={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            sx={{ mr: '4px' }}
            onClick={handleClose}
          >
            Отменить
          </Button>
          <Button
            color={btnColor}
            variant={btnVariant}
            disabled={loading}
            onClick={handleClick}
          >
            {loading ? (
              <CircularProgress
                thickness={5}
                color="inherit"
                sx={{ mr: '4px' }}
              />
            ) : null}
            <Typography variant="body1">{btnTitle}</Typography>
          </Button>
        </Box>
      }
    />
  )
}

export const ModalDeleteDocumentForever = ({
  handleClose,
  open,
  funcDelete,
  count = 1,
  tableType,
}: {
  handleClose: (e) => void
  open: boolean
  funcDelete: () => Promise<void>
  count?: number
  tableType: 'documents' | 'templates'
}) => {
  const [deleting, setDeleting] = useState(false)

  const handleAction = async (e) => {
    setDeleting(true)
    await funcDelete()
    setDeleting(false)
    handleClose(e)
  }

  return (
    <ModalCenterSelectableWithTitle
      handleClose={handleClose}
      title={`Удалить ${
        tableType === 'documents' ? 'документ' : 'шаблон'
      } безвозвратно?`}
      modalType="small"
      open={open}
      footerChild={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            sx={{ mr: '4px' }}
            onClick={handleClose}
          >
            Отменить
          </Button>
          <Button
            color="error"
            variant="outlined"
            disabled={deleting}
            onClick={handleAction}
          >
            {deleting ? (
              <CircularProgress
                thickness={5}
                color="inherit"
                sx={{ mr: '4px' }}
              />
            ) : null}
            <Typography variant="body1">
              Удалить безвозвратно {count > 1 ? `(${count})` : ''}
            </Typography>
          </Button>
        </Box>
      }
    />
  )
}
