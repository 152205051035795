import img_check from 'assets/img/check_blue.svg'

import { Box, Typography } from '@mui/material'
import { EFeature } from 'helper/plan.types'
import { IPlanInfo } from 'pages/plan/types'

const getTextForFeature = {
  [EFeature.documents_count_per_month]: (val) => {
    if (val == -1) {
      return 'Документы без ограничений'
    }
    if (val == 1) {
      return 'готовый документ'
    } else if (val > 1 && val < 5) {
      return 'готовых документа'
    } else {
      return 'готовых документов'
    }
  },
  [EFeature.users_count]: (val) => {
    if (val == -1) {
      return 'Пользователи без ограничений'
    }
    if (val == 1) {
      return 'пользователь'
    } else if (val > 1 && val < 5) {
      return 'пользователя'
    } else {
      return 'пользователей'
    }
  },
  [EFeature.teamspaces_count]: (val) => {
    if (val == -1) {
      return 'Группы без ограничений'
    }
    if (val == 1) {
      return 'группа'
    } else if (val > 1 && val < 5) {
      return 'группы'
    } else {
      return 'групп'
    }
  },
  [EFeature.template_creation_count]: (val) => {
    if (val == -1) {
      return 'Шаблоны без ограничений'
    }
    if (val == 1) {
      return 'шаблон'
    } else if (val > 1 && val < 5) {
      return 'шаблона'
    } else {
      return 'шаблонов'
    }
  },
  [EFeature.document_send_by_email]: () => 'Маршрут документов',
  [EFeature.ai_chat]: () => 'ChatGPT при работе с документами',
  [EFeature.api_access]: () => 'API',
  [EFeature.mass_send]: () => 'Массовая рассылка',
}

const monthlyFeatures = {
  [EFeature.documents_count_per_month]: true,
}

export const FeatureLimit = ({
  plan,
  feature,
}: {
  plan: IPlanInfo
  feature: EFeature | string
}) => {
  if (!plan.features[feature]) {
    return null
  }

  let cnt = plan.features[feature]
  if (plan.features[feature] == -1) {
    cnt = undefined
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
      }}
    >
      <Box component={'img'} src={img_check} width={'16px'} />
      <Typography>
        {cnt}{' '}
        {!!getTextForFeature[feature]
          ? getTextForFeature[feature](plan.features[feature])
          : feature}
      </Typography>
      {monthlyFeatures[feature] && (
        <Typography variant="paragraph3" color={'text.disabled'}>
          /в месяц
        </Typography>
      )}
    </Box>
  )
}
