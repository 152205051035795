import { Box, Typography } from '@mui/material'
import { ButtonQuestion } from './buttonQuestion'

// const questions = [
//   'Что умеет чат Doodocs AI?',
//   'Что должен содержать трудовой договор согласно трудовому законодательству? ',
//   'Можно ли подписывать документы электронной подписью?',
//   'Какие документы требуется для оформления нового сотрудника?',
// ]

export const ChatQuestionsBlock = ({
  questions,
  disabled,
  ask,
}: {
  questions: string[]
  disabled: boolean
  ask: (data: { text: string }) => void
}) => {
  return (
    <>
      <Box
        sx={{
          p: '12px 16px',
        }}
      >
        <Typography variant="body2" sx={{ mb: '8px' }} color={'text.disabled'}>
          Примеры вопросов
        </Typography>
        {questions.map((q) => {
          return (
            <ButtonQuestion
              disabled={disabled}
              key={`question-example-${q}`}
              fullWidth
              onClick={() => {
                ask({ text: q })
              }}
            >
              {q}
            </ButtonQuestion>
          )
        })}
      </Box>
    </>
  )
}
