import { useCallback, useEffect, useState } from 'react'
import { Error404, Loading } from 'components'
import { getTemplateHtml } from 'service/template'
import { shallowEqual } from 'react-redux'
import { Box } from '@mui/material'
import { RenderHTML } from 'features/renderDocument'
import { ESystemVariableType } from 'features/text-editors/lexical/plugins-custom/ParameterPickerPlugin/helpers'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { useAppSelector } from 'store/store'
dayjs.locale('ru')

const getVariableValue = (
  filledValue: any,
  currentVariableInfo: IVariable,
  variables: IVariables
): string => {
  const { name, type, id } = currentVariableInfo

  switch (type) {
    case ESystemVariableType.DROPDOWN:
      if (Array.isArray(filledValue)) {
        return filledValue
          .map((optionId: string) => variables[optionId]?.attrs?.text)
          .join('')
      }
      return name || ''
    case ESystemVariableType.CHECKBOX:
      return (
        (filledValue as string) ??
        (variables[id] ? variables[id].attrs.text : '')
      )
    case ESystemVariableType.INPUT_DATE: {
      const formattedDate = filledValue
        ? dayjs(filledValue).format(variables[id].attrs.format)
        : filledValue
      return formattedDate || name || ''
    }
    default: {
      return filledValue || name || ''
    }
  }
}

export const DocumentHtml = ({
  template,
  stateVariables,
}: {
  template?: string
  stateVariables: any
}) => {
  const [html, setHtml] = useState('')
  const [err, setErr] = useState('')
  const [loading, setLoading] = useState(false)

  const { variables: templateVariables } = useAppSelector(
    (state) => state.template,
    shallowEqual
  )

  const {
    variables: documentVariables,
    document: applicationDocument,
    loading: loadingDocument,
    template: applicationTemplate,
  } = useAppSelector((state) => state.application, shallowEqual)

  const fillHtmlVariables = useCallback(() => {
    if (!stateVariables) return
    const _variables = template ? templateVariables : documentVariables

    Object.entries(stateVariables).forEach(([key, filledValue]) => {
      const nodes: HTMLCollectionOf<Element> = document.getElementsByClassName(
        `form-variable ${key}`
      )

      Array.from(nodes).forEach((node: any) => {
        const variableValue = _variables
          ? getVariableValue(filledValue, _variables[key], _variables)
          : ''

        if (
          _variables &&
          _variables[key].type === ESystemVariableType.CHECKBOX
        ) {
          if (variableValue) node?.setAttribute('checked', 'true')
          else node?.removeAttribute('checked')
        } else node.innerText = variableValue
      })
    })
  }, [stateVariables, templateVariables, documentVariables])

  const getHtml = async () => {
    try {
      setLoading(true)
      const res = await getTemplateHtml({ id: template })
      setHtml(res.data)
      setLoading(false)
    } catch (err: any) {
      setLoading(false)
      setErr(err.message)
    }
  }

  useEffect(() => {
    template && getHtml()
  }, [template])

  if (loading || loadingDocument.source) return <Loading />
  if (err) return <Error404 description={''} message={err} />
  return (
    <Box style={{ padding: '64px 40px' }}>
      <RenderHTML
        html={
          html ||
          applicationDocument?.content_html ||
          applicationTemplate?.content_html
        }
        callback={fillHtmlVariables}
      />
    </Box>
  )
}
