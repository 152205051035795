import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { MessageSnackbar } from 'components'
import { MenuItemButtonSelectTeamspace } from 'components/menu-item/MenuItemButtonSelectTeamspace'
import { ModalCenterSmall } from 'components/modal'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { queryDocumentsMoveDocumentsToTeamspace } from 'service/documents'

const getDocumentsCountText = (count: number) => {
  if (count === 1) return 'документ'
  if (count < 5) {
    return `${count} документа`
  }
  if (count % 100 > 20) {
    return getDocumentsCountText(count % 10)
  }
  return `${count} документов`
}

export const ModalMoveDocumentsBetweenTeamspaces = ({
  open,
  handleClose,
  documentIds,
  onSuccessCallback,
}: {
  open: boolean
  handleClose?: () => Promise<any> | any
  documentIds: string[]
  onSuccessCallback?: (selectedTeamspaceId: string) => Promise<any> | any
}) => {
  const { teamspaces, selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )
  const [loading, setLoading] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const [error, setError] = useState('')

  const handleClick = async () => {
    try {
      setLoading(true)
      await queryDocumentsMoveDocumentsToTeamspace({
        documentIds: documentIds,
        teamspaceId: selectedId,
      })
      if (handleClose) await handleClose()
      if (onSuccessCallback) await onSuccessCallback(selectedId)
    } catch (err: any) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <ModalCenterSmall
        open={open}
        handleClose={handleClose}
        header={{
          children: (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="heading5">
                Переместить {getDocumentsCountText(documentIds.length)}
              </Typography>
              <Typography variant="paragraph4">
                Выберите группу в которую хотите переместить документ
              </Typography>
            </Box>
          ),
        }}
        content={{
          noPaddings: true,
          children: (
            <Box my={'8px'}>
              {teamspaces.map(
                (el) =>
                  el.id != selectedTeamspace?.id && (
                    <MenuItemButtonSelectTeamspace
                      key={el.id}
                      teamspace={el}
                      onClick={() => setSelectedId(el.id)}
                      checked={el.id == selectedId}
                      disabled={loading}
                    />
                  )
              )}
            </Box>
          ),
        }}
        footer={{
          children: (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
              }}
            >
              <Button
                variant="contained"
                disabled={
                  !selectedId || selectedId == selectedTeamspace?.id || loading
                }
                sx={{
                  gap: '4px',
                }}
                onClick={handleClick}
              >
                {loading && (
                  <CircularProgress
                    sx={{
                      color: 'text.disabled',
                    }}
                  />
                )}
                Переместить
              </Button>
            </Box>
          ),
        }}
      />
      {createPortal(
        <MessageSnackbar
          clearMessage={() => setError('')}
          message={error}
          severity={'error'}
        />,
        document.body
      )}
    </>
  )
}
