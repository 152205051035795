import img_webhook from 'assets/img/integrations/Logo=webhook.svg'

import { ModalIntegrationWebhook } from 'features/integration-webhook/components/ModalIntegrationWebhook'
import { useModal } from 'hooks/useModal'
import { EIntegrationType, IIntegrationWebhook } from '../../../types'
import { ActiveIntegrationRowBase } from '../ActiveIntegrationRowBase'
import { IActiveIntegrationRowProps } from '../types'

export interface IActiveIntegrationRowWebhookProps
  extends IActiveIntegrationRowProps {
  integration: IIntegrationWebhook
  onEditSuccessCallback?: () => Promise<any> | any
}

export const isIActiveIntegrationRowWebhookProps = (
  prop: IActiveIntegrationRowProps
): prop is IActiveIntegrationRowWebhookProps => {
  return prop.integration.integration_type == EIntegrationType.webhook
}
export const ActiveIntegrationRowWebhook = ({
  integration,
  onDeleteCallback,
  onEditSuccessCallback,
  loading,
}: IActiveIntegrationRowWebhookProps) => {
  const [modalEditOpen, openModalEdit, closeModalEdit] = useModal()

  return (
    <>
      <ActiveIntegrationRowBase
        integrationId={integration.id}
        icon={img_webhook}
        title="Webhook"
        description={integration.attrs.webhook_url}
        isEnabled={integration.enabled}
        onDeleteCallback={onDeleteCallback}
        onClickEdit={openModalEdit}
        loading={loading}
      />
      {!loading && (
        <ModalIntegrationWebhook
          templateId={integration.template_id}
          handleClose={closeModalEdit}
          open={modalEditOpen}
          values={{ ...integration.attrs, integrationId: integration.id }}
          onSuccessCallback={onEditSuccessCallback}
        />
      )}
    </>
  )
}
