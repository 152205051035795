import { InputProps, TextField, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { HTMLInputTypeAttribute } from 'react'

export const FormikTextField = ({
  name, // keyName is the name of the key in the formik values object
  label,
  placeholder,
  autoFocus,
  disabled,
  type,
  onChange,
  InputProps,
}: {
  label?: string
  placeholder?: string
  name: string
  autoFocus?: boolean
  disabled?: boolean
  type?: HTMLInputTypeAttribute
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  InputProps?: Partial<InputProps>
}) => {
  const { values, touched, handleChange, errors } = useFormikContext<{
    [key: string]: string
  }>()

  return (
    <>
      {!!label && (
        <Typography variant="heading6" mb={'4px'}>
          {label}
        </Typography>
      )}
      <TextField
        type={type}
        value={values[name] ?? ''}
        autoFocus={autoFocus}
        onChange={onChange || handleChange}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        variant="outlined"
        fullWidth
        error={errors[name] && touched[name] ? true : false}
        helperText={errors[name] && touched[name] ? errors[name] : ''}
        InputProps={InputProps}
      />
    </>
  )
}
