import img_plus from 'assets/img/add.svg'
import img_delete from 'assets/img/delete_outline.svg'

import { Box, Button, TextField, Typography } from '@mui/material'
import { IconButtonImage } from 'components/button/IconButtonImage'
import { FormikContextType, getIn, useFormikContext } from 'formik'

interface IFormikFieldWebhookHeader {
  name: string
  value: string
}

export interface IFormikFieldWebhookHeaders {
  headers?: IFormikFieldWebhookHeader[]
}

export const FormikFormWebhookAddHeaders = () => {
  const formik = useFormikContext<IFormikFieldWebhookHeaders>()

  const getHeaderErrorMsg = (formik: FormikContextType<any>, name: string) => {
    const error = getIn(formik.errors, name)
    const touch = getIn(formik.touched, name)
    return touch && error ? error : null
  }

  if (!formik.values.headers)
    return (
      <Box>
        <Button
          onClick={() => {
            formik.setFieldValue('headers', [
              {
                name: '',
                value: '',
              },
            ])
          }}
          variant="text"
          color="secondary"
          startIcon={<Box component={'img'} src={img_plus} sizes="16px" />}
        >
          HTTP заголовки
        </Button>
      </Box>
    )

  return (
    <Box>
      <Typography variant="heading6" mb={'8px'}>
        HTTP заголовки
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
        }}
      >
        {formik.values.headers.map((header, i) => {
          return (
            <Box
              sx={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
              }}
              key={i}
            >
              <TextField
                value={header.name}
                onChange={formik.handleChange}
                name={`headers[${i}].name`}
                placeholder={'Название'}
                variant="outlined"
                fullWidth
                error={
                  getHeaderErrorMsg(formik, `headers[${i}].name`) ? true : false
                }
              />
              <TextField
                value={header.value}
                onChange={formik.handleChange}
                name={`headers.${i}.value`}
                placeholder={'Значение'}
                variant="outlined"
                fullWidth
                error={
                  getHeaderErrorMsg(formik, `headers.${i}.value`) ? true : false
                }
              />
              <IconButtonImage
                icon={{ src: img_delete, size: '16px' }}
                onClick={() => {
                  const headers = formik.values.headers?.filter(
                    (_, hi) => i != hi
                  )
                  if (headers?.length == 0)
                    formik.setFieldValue('headers', undefined)
                  else formik.setFieldValue('headers', headers)
                }}
                size="small"
              />
            </Box>
          )
        })}
        <Box>
          <Button
            onClick={() => {
              const headers = [...(formik.values.headers ?? [])]
              headers.push({
                name: '',
                value: '',
              })
              formik.setFieldValue('headers', headers)
            }}
            variant="text"
            color="secondary"
            startIcon={<Box component={'img'} src={img_plus} sizes="16px" />}
          >
            Добавить
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
