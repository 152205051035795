import { Box, Typography, IconButton, Link } from '@mui/material'
import { useState, useEffect } from 'react'
import img_info from 'assets/img/info-out.svg'
import right from 'assets/img/right-bold.svg'
import img_close from 'assets/img/close-btn.svg'

export const TemplateInfoBox = () => {
  const [showBox, setShowBox] = useState(false)

  useEffect(() => {
    const boxState = localStorage.getItem('boxState')
    if (boxState !== null) {
      try {
        setShowBox(JSON.parse(boxState))
      } catch {
        setShowBox(false)
      }
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('boxState', JSON.stringify(showBox))
  }, [showBox])

  const closeBox = () => {
    setShowBox(false)
  }

  return (
    <>
      {showBox && (
        <Box
          border={'1px solid #EDEDEC'}
          borderRadius={'4px'}
          p="8px 12px"
          display={'flex'}
        >
          <Box mr={1}>
            <Box
              component={'img'}
              src={img_info}
              height={'16px'}
              width={'16px'}
              m={'2px 0'}
            />
          </Box>
          <Box width={'100%'}>
            <Typography fontWeight={500} color={'text.primary'}>
              Что такое шаблоны?
            </Typography>
            <Typography mt={'4px'} color={'text.disabled'} mb={1}>
              Шаблоны — это повторно используемые документы, которые вы часто
              отправляете. Создайте шаблон один раз, чтобы отправлять документы
              быстрее.
            </Typography>
            <Link
              target={'_blank'}
              href="https://doodocskz.notion.site/832bfb7cde754601a916e3a54dcbf1e6"
              sx={{ textDecoration: 'none', cursor: 'pointer' }}
            >
              <Box display={'flex'} alignItems={'center'}>
                <Typography fontWeight={500} color={'text.primary'}>
                  Подробнее
                </Typography>
                <Box
                  component={'img'}
                  src={right}
                  height={'16px'}
                  width={'16px'}
                />
              </Box>
            </Link>
          </Box>
          <Box>
            <IconButton sx={{ borderRadius: '4px' }} onClick={closeBox}>
              <Box
                component={'img'}
                src={img_close}
                alt="close"
                width={'16px'}
                height={'16px'}
              />
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  )
}
