import { Box, Button, Divider } from '@mui/material'
import { MessageSnackbar } from 'components'
import { Formik } from 'formik'
import { TSRolePermissions } from 'helper/roles'
import {
  NameColor,
  iconColors,
} from 'layout/dashboard-layout/components/groupForm'
import { useEffect, useMemo, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import {
  clearNotificationsTs,
  updateTeamspaceInfo,
} from 'store/slices/teamspaceSlice'
import * as Yup from 'yup'
import { DeleteGroup } from './delete'
import { BlockTeamspaceLeave } from './BlockTeamspaceLeave'
import { isTeamspaceDeleteRestricted } from 'utils/teamspace'
import { TSSettingsHeader } from './header'
import { withDashboardLayout } from 'layout'

const TSSettingsContent = () => {
  const location = useLocation()
  const formikRef = useRef<any>(null)

  const params_name = useMemo(() => {
    const params = new URLSearchParams(location.search)
    const name = params.get('name')
    return name
  }, [location.pathname])

  const [searchParams, setSearchParams] = useSearchParams()

  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const { selectedTeamspace, loading, notification } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )

  const addSearchParams = (key, value) => {
    searchParams.set(key, value)
    setSearchParams(searchParams)
  }

  const validationSchema = Yup.object().shape({
    ts_name: Yup.string()
      .required('Обязательное поле')
      .max(50, 'Максимум 50 символов'),
  })

  const { tsId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    const formik = formikRef.current
    formik &&
      formik.setValues({
        ts_name: selectedTeamspace?.name || params_name || '',
        color: selectedTeamspace?.attrs?.color?.hex || '',
      })
    return () => {
      dispatch(clearNotificationsTs() as any)
    }
  }, [tsId, selectedTeamspace?.name])

  return (
    <Box>
      <MessageSnackbar
        message={notification.delete_ts?.message}
        clearMessage={() => dispatch(clearNotificationsTs() as any)}
        severity={notification.delete_ts?.severity}
      />
      <MessageSnackbar
        message={notification.leave_ts?.message}
        clearMessage={() => dispatch(clearNotificationsTs() as any)}
        severity={notification.leave_ts?.severity}
      />

      <Box p={{ xs: '16px', sm: '16px 40px' }} overflow={'auto'}>
        {selectedTeamspace &&
          TSRolePermissions[selectedTeamspace.group_name]
            .manageTeamspaceInfo && (
            <Formik
              innerRef={formikRef}
              initialValues={{
                ts_name: '',
                color: iconColors[0],
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                dispatch(
                  updateTeamspaceInfo({
                    tsId,
                    name: values.ts_name,
                    color: values.color,
                    wsId: selectedWorkspace?.id || '',
                  }) as any
                )
                addSearchParams('name', values.ts_name)
              }}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Box pb={'8px'}>
                    <NameColor />
                  </Box>
                  <Box p={'24px 0'} id="footer" display={'flex'}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={loading.update_ts ? true : false}
                    >
                      Сохранить
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          )}
        <Divider />
        <Box mt="24px">
          <BlockTeamspaceLeave />
        </Box>
        {selectedTeamspace &&
          TSRolePermissions[selectedTeamspace.group_name].manageTeamspaceInfo &&
          !isTeamspaceDeleteRestricted(selectedTeamspace) && (
            <Box mt="24px">
              <DeleteGroup />
            </Box>
          )}
      </Box>
    </Box>
  )
}

export const TSSettings = () =>
  withDashboardLayout(TSSettingsContent)({
    Header: TSSettingsHeader,
  })
