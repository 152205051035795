import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $getSelection,
  COMMAND_PRIORITY_NORMAL,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'
import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

import './index.css'

function hidePlaceholderElem(placeholder: HTMLElement) {
  placeholder.style.display = 'none'
}

function showPlaceholderElem(
  element: HTMLElement,
  placeholder: HTMLElement,
  anchorElem: HTMLElement
) {
  const elemStyle = getComputedStyle(element)
  // TODO: Пока не решеено как показывать placeholder для позиционированного текста
  if (elemStyle.textAlign != 'left') {
    hidePlaceholderElem(placeholder)
    return
  }
  const anchorRect = anchorElem.getBoundingClientRect()
  const elRect = element.getBoundingClientRect()

  placeholder.style.position = 'absolute'
  placeholder.style.display = 'block'
  placeholder.style.top = `${elRect.top - anchorRect.top}px`
  placeholder.style.left = `${elRect.left - anchorRect.left}px`
  placeholder.style.width = `${elRect.width}px`
  placeholder.style.height = `${elRect.height}px`

  placeholder.style.fontSize = elemStyle.fontSize
  placeholder.style.fontWeight = elemStyle.fontWeight
  placeholder.style.fontFamily = elemStyle.fontFamily
  placeholder.style.textAlign = elemStyle.textAlign
}

function handleOnSelectionChange(
  editor: LexicalEditor,
  placeholder: HTMLElement,
  anchorElem: HTMLElement
) {
  editor.update(() => {
    const selection = $getSelection()
    if (!selection) return

    if (selection.getNodes().length != 1) return
    const node = selection.getNodes()[0]

    if (node.getTextContent()) {
      hidePlaceholderElem(placeholder)
      return
    }

    const element = editor.getElementByKey(node.getKey())
    if (!element || !(element instanceof HTMLParagraphElement)) {
      hidePlaceholderElem(placeholder)
      return
    }
    showPlaceholderElem(element, placeholder, anchorElem)
  })
}

/**
 * PlaceholderPlugin - Используется для отображения placeholder в пустых текстовых елементах в котором находится курсор печати.
 *
 * Props:
 * - text: Показывает текст по умолчанию на месте где находится placeholder
 * @returns
 */
export function PlaceholderPlugin({
  text,
  anchorElem = document.body,
}: {
  text: string
  anchorElem?: HTMLElement
}) {
  const [editor] = useLexicalComposerContext()

  const placeholderRef = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        if (!placeholderRef.current) return false
        handleOnSelectionChange(newEditor, placeholderRef.current, anchorElem)
        return false
      },
      COMMAND_PRIORITY_NORMAL
    )
  }, [editor])
  editor._blockCursorElement
  return (
    <>
      {createPortal(
        <p ref={placeholderRef} className="selection-placeholder">
          {text}
        </p>,
        anchorElem
      )}
    </>
  )
}
