import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { workspaceApi } from 'service/workspaces'
import { MessageSnackbar } from 'components'
import {
  createInviteAction,
  fetchTeamspaceInvites,
  fetchTeamspacesMembers,
} from 'store/slices/teamspaceSlice'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { fetchTeamspaceGroups } from 'service/teampspaces'
import { AnalyticsEvent } from 'config/analytics/segment.com/events'
import { generateAvailableListTs, tsRoleOptions } from './inviteToHelpers'
import { TextFieldEmails } from 'components/text-fields/TextFieldEmails'
import { ModalPlanLimit } from 'components/modal/ModalPlanLimit'
import { EFeature } from 'helper/plan.types'

export const InviteToGroupForm = ({ handleClose, cancel = true }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [isEmailsFieldValid, setIsEmailsFieldValid] = useState(true)

  const { selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )

  const [availableList, setAvailableList] = useState<any>([])

  useEffect(() => {
    selectedTeamspace &&
      setAvailableList(generateAvailableListTs(selectedTeamspace?.group_name))
  }, [selectedTeamspace])

  const getGroupId = ({ groups, option }) => {
    return groups.find((group) => group.name === option).id || ''
  }

  const dispatch = useDispatch()

  const handleSubmit = async (values) => {
    const { invites, option } = values

    setLoading(true)
    try {
      const res = await fetchTeamspaceGroups({ id: selectedTeamspace?.id })
      const { groups } = res.data

      const requests = invites.map((invite) =>
        workspaceApi.createInvite({
          invite: {
            email: invite,
            group_id: getGroupId({ groups, option }),
          },
        })
      )

      Promise.all(requests)
        .then(() => {
          handleClose()
          dispatch(createInviteAction() as any)
          dispatch(fetchTeamspaceInvites({ id: selectedTeamspace?.id }) as any)
          dispatch(fetchTeamspacesMembers({ id: selectedTeamspace?.id }) as any)
        })
        .catch((error: any) => {
          setError(error.message)
        })

      if (invites.length)
        AnalyticsEvent.invites_sent(invites.length, 'settings')
    } catch (error: any) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const formikContent = ({
    handleSubmit,
    setFieldValue,
    values,
    handleChange,
    handleBlur,
  }: any) => {
    return (
      <form onSubmit={handleSubmit}>
        <Box p="0 0 8px 0">
          <Typography variant="body1" mb="4px" fontWeight={500}>
            Отправить приглашение на email
          </Typography>

          <TextFieldEmails
            emails={values.invites}
            setEmails={(emails) => {
              setFieldValue('invites', emails)
            }}
            placeholder="email@example.com email2@example.com..."
            setIsValuesValid={setIsEmailsFieldValid}
          />

          <Typography variant="body1" mb="4px" fontWeight={500} mt={'16px'}>
            Роль
          </Typography>
          <FormControl fullWidth>
            <Select
              MenuProps={{
                PaperProps: {
                  style: {
                    width: '480px',
                    maxHeight: '100%',
                    marginTop: '2px',
                  },
                },
              }}
              fullWidth
              name="option"
              value={values.option}
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ p: 0 }}
              renderValue={(value) => {
                const option = tsRoleOptions.find((o) => o.value === value)
                return option ? option.label : ''
              }}
            >
              {availableList.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{ m: '2px 4px' }}
                >
                  <Box
                    p={'4px 12px'}
                    display={'flex'}
                    alignItems="flex-start"
                    flexDirection={'column'}
                  >
                    <Typography variant="body1">{option.label}</Typography>
                    <Typography
                      color={'text.secondary'}
                      whiteSpace="break-spaces"
                    >
                      {option.desc}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          p={'8px 0 16px'}
          id="footer"
          display={'flex'}
          justifyContent={'flex-end'}
        >
          {cancel && (
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              Сделать это позже
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading || !isEmailsFieldValid ? true : false}
          >
            Пригласить участников
          </Button>
        </Box>
      </form>
    )
  }

  return (
    <>
      {error == 'workspace users count exceeded' ? (
        <ModalPlanLimit
          limitOn={EFeature.users_count}
          open={true}
          handleClose={() => {
            setError('')
          }}
        />
      ) : (
        <MessageSnackbar
          message={error}
          clearMessage={() => setError('')}
          severity={'error'}
        />
      )}
      {availableList.length > 0 && (
        <Formik
          initialValues={{
            invites: [],
            option: availableList[0].value,
          }}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {formikContent}
        </Formik>
      )}
    </>
  )
}
