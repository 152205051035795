import { EditorModes, UrlKeys } from 'helper/consts'
import { useDebounce } from 'hooks/useDebounce'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { createContext, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  saveDocumentEditor,
  updateDocumentEditorContent,
} from 'store/slices/applicationSlice'
import {
  saveTemplateContent,
  updateTemplateEditorContent,
} from 'store/slices/templateSlice'

interface DocumentEditorFields {
  contentHtml: string
}

interface DocumentContext {
  fields: DocumentEditorFields
  setContentHtmlWithLazySave: (contentHtml: string) => void
  saveToServer: () => Promise<void>
}

export const DocumentEditorContext = createContext<DocumentContext>(
  {} as DocumentContext
)

const SaveAfterMs = 1000

export const useDocumentEditorContextDefaultValue = (
  mode: EditorModes
): DocumentContext => {
  const { tsId } = useParams()

  const { urlId } = useUrlIdParam(
    mode === EditorModes.document ? UrlKeys.documentId : UrlKeys.templateId
  )
  const debounce = useDebounce()

  const dispatch = useDispatch<any>()
  const { document } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )

  const template = useSelector(
    (state: RootState) => state.template,
    shallowEqual
  )

  const cached = useMemo<DocumentEditorFields>(() => {
    return {
      contentHtml: '',
    }
  }, [])

  useEffect(() => {
    switch (mode) {
      case EditorModes.template:
        cached.contentHtml = template.content_html
        return
      default:
        cached.contentHtml = document.content_html
        return
    }
  }, [document.content_html, template.content_html])

  const saveToServer = async () => {
    const id = urlId.get()
    switch (mode) {
      case EditorModes.template:
        if (!id) {
          dispatch(
            saveTemplateContent({
              tsId: tsId ?? '',
              templateId: id || template.id,
              name: template.name,
              content_html: cached.contentHtml,
            })
          )
          return
        }
        if (
          template.content_html &&
          template.content_html !== cached.contentHtml
        ) {
          dispatch(
            updateTemplateEditorContent({
              templateId: id,
              content_html: cached.contentHtml,
            })
          )
        }
        return
      default:
        if (!id) {
          dispatch(
            saveDocumentEditor({
              teamspace_id: tsId ?? '',
              id: urlId.get(),
              name: document.name,
              content_html: cached.contentHtml,
            })
          )
          return
        }
        if (document.content_html !== cached.contentHtml) {
          dispatch(
            updateDocumentEditorContent({
              document_id: id,
              content_html: cached.contentHtml,
            })
          )
        }
    }
  }

  const lazySaveToServer = () => {
    debounce(async function () {
      await saveToServer()
    }, SaveAfterMs)
  }

  const setContentHtmlWithLazySave = (contentHtml: string) => {
    cached.contentHtml = contentHtml
    lazySaveToServer()
  }

  return {
    fields: cached,
    setContentHtmlWithLazySave,
    saveToServer,
  }
}
