import { Typography, Link } from '@mui/material'
import { AppBarSettings } from 'components/header/app-bar-common'

export const PlansAppbar = () => {
  return (
    <AppBarSettings
      headerTitle={
        <>
          <Typography variant="subtitle2">Тарифы</Typography>
          <Typography mt={'4px'} color={'text.disabled'}>
            Просматривайте информацию о тарифах. Если у вас есть вопросы
            свяжитесь с нами{' '}
            <Link color={'text.disabled'} href={'mailto:info@doodocs.kz'}>
              info@doodocs.kz
            </Link>
          </Typography>
        </>
      }
    />
  )
}
