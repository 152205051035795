import { ERecipientRole } from "helper/consts";
import { EServiceWorkflowStepType } from "helper/workflow.service.types";
import { getObjectClone } from "utils/getObjectClone";
import { CWorkflow } from "./CWorkflow";
import { IActor, IWorkflow } from "./IWorkflow";
import { CRecipientAnonymousSignerRK } from "./recipients/CRecipientAnonymousSignerRK";
import { CRecipientFiller } from "./recipients/CRecipientFiller";
import { CRecipientSignerRK } from "./recipients/CRecipientSignerRK";
import { IRecipient } from "./recipients/IRecipient";
import { RecipientHelperNS } from "./recipients/RecipientHelperNS";

export class CWorkflowHelper {
  static HasCopy(workflow: IWorkflow): boolean {
    return !workflow.cc ? false : workflow.cc.length > 0
  }

  static HasRecipientWithRole(workflow: IWorkflow, roles: ERecipientRole[]): boolean {
    const rMap = new Map<ERecipientRole, {}>()
    roles.forEach(role => rMap.set(role, {}))

    return !!workflow.steps.find(
      step => !!step.recipients.find(recipient => rMap.has(recipient.role))
    )
  }


  static GetRecipientsWithRole(workflow: IWorkflow, roles: ERecipientRole[]): IRecipient[] {
    const rMap = new Map<ERecipientRole, {}>()
    roles.forEach(role => rMap.set(role, {}))

    const recipients: IRecipient[] = []
    workflow.steps.forEach(st => {
      const rc = st.recipients.filter(recipient => {
        recipient._visible_step_number = st.index
        return rMap.has(recipient.role)
      })
      recipients.push(...rc)
    }
    )
    return recipients
  }

  static ConvertRecipientSignerRKToAnonymousSignerRK(r: CRecipientSignerRK): CRecipientAnonymousSignerRK {
    const recipient = getObjectClone(r)
    return new CRecipientAnonymousSignerRK({
      actor_id: recipient.actor_id,
      attrs: recipient.attrs ? {
        filter: recipient.attrs.filter,
        limit: 1,
      } : undefined,
    })
  }


  static SwitchFillerRecipientToLink(actorId: number, workflow: IWorkflow): boolean {
    const step = workflow.steps.find(step => step.type === EServiceWorkflowStepType.form)
    if (!step) return false
    const recipient = step.recipients.find(recipient => recipient.actor_id === actorId) as CRecipientFiller
    if (!recipient) return false
    recipient.attrs = {
      email: undefined,
      must_send_email: false,
    }


    for (let i = 0; i < workflow.steps.length; i++) {
      const st = workflow.steps[i];

      for (let j = 0; j < st.recipients.length; j++) {
        const rc = st.recipients[j];
        if (rc.actor_id !== actorId || rc.role == ERecipientRole.filler) continue

        if (rc.role === ERecipientRole.signer_rk) {
          workflow.steps[i].recipients[j] = this.ConvertRecipientSignerRKToAnonymousSignerRK(rc as CRecipientSignerRK)
        }

      }
    }
    return true
  }

  static ReplaceRecipients(workflow: IWorkflow, target: IRecipient, value: IRecipient) {
    let isReplaced = false
    for (let i = 0; i < workflow.steps.length; i++) {
      for (let j = 0; j < workflow.steps[i].recipients.length; j++) {
        if (!workflow.steps[i].recipients[j].isSame(target)) continue

        workflow.steps[i].recipients[j] = value
        isReplaced = true
      }
    }
    return isReplaced
  }

  /**
   * Возвращяет неодстающих акторов которые были заполнены в форме
   * @param workflow 
   * @param roles - Список ролей которым рекомендуется актор
   * @param canRecommend - Функция которая проверяет можно ли рекомендовать актора
   * @returns 
   */
  static GetRecomendedActors(workflow: CWorkflow, roles: ERecipientRole[], canRecommend?: ((rec: CRecipientFiller) => boolean)): IActor[] {
    if (!workflow.actors || workflow.actors.length == 0) return []

    const actorsMap = {}
    workflow.actors.forEach((actor) => {
      actorsMap[actor.id] = actor
    })

    const recWithActorIds = CWorkflowHelper.GetRecipientsWithRole(
      workflow,
      roles
    ).filter((recipient) => !!recipient.actor_id)

    const ignoredActors = {}
    recWithActorIds.forEach((rec) => {
      if (rec.actor_id) ignoredActors[rec.actor_id] = true
    })

    const recomendedActors = {}
    // ? Рекомендуем Отправителя он не был заполнен
    if (!ignoredActors[1] && actorsMap[1]) recomendedActors[1] = actorsMap[1]

    const formStep = workflow.steps.find(
      (step) => step.type === EServiceWorkflowStepType.form
    )

    if (!formStep) return Object.values(recomendedActors)
    formStep.recipients.forEach((recipient) => {
      if (!recipient.actor_id) return
      if (canRecommend && !canRecommend(recipient as CRecipientFiller)) return
      if (ignoredActors[recipient.actor_id]) return
      recomendedActors[recipient.actor_id] = actorsMap[recipient.actor_id]
    })

    return Object.values(recomendedActors)
  }

  /**
   * Промывает workflow удаляя recipients которые ссылаются к несуществующим actors
   * @param workflow 
   */
  static FlushByActualActors(workflow: CWorkflow) {
    const actorsMap: {
      [key: number]: boolean
    } = {}

    workflow.actors?.forEach((actor) => {
      actorsMap[actor.id] = true
    })

    const illegalActorsMap: {
      [key: number]: boolean
    } = {}
    for (let i = 0; i < workflow.steps.length; i++) {
      const step = workflow.steps[i];
      step.recipients.forEach((r) => {
        if (typeof r?.actor_id != 'number' || actorsMap[r.actor_id]) return
        illegalActorsMap[r.actor_id] = true
      })
    }

    Object.keys(illegalActorsMap).forEach((actorId) => RecipientHelperNS.RemoveRecipientsWithActorIdFromSteps(workflow.steps, Number(actorId)))
  }
}