import { Pagination } from 'components'
import { useSelector, shallowEqual } from 'react-redux'
import { useChangePageContext } from 'context'

export const Footer = () => {
  const { changePage } = useChangePageContext()
  const { count, page } = useSelector(
    (state: RootState) => state.campaign,
    shallowEqual
  )

  return (
    <Pagination changePage={changePage} page={page} count={count} filter={''} />
  )
}
