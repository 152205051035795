import { config } from 'config'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { LocalStorageReserve } from 'helper/consts'

export const queryListenDocumentSignStatus = async ({
  fields,
  onResponseData,
  onResponseError,
  onFinished,
}: {
  fields: {
    documentId: string
    originId: string
    isRunning: boolean
  }
  onResponseData: (chunkMsg: string) => void
  onResponseError?: (errMsg: string) => void
  onFinished?: () => void
}): Promise<any> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    let eventSource: EventSourcePolyfill
    const logDebugPrint = (msg: string) =>
      console.debug(
        `queryListenDocumentSignStatus(documentId: ${fields.documentId}): ${msg}`
      )

    const breakEventSource = () => {
      logDebugPrint('breakEventSource')
      fields.isRunning = false
      eventSource.close()
      resolve(null)
      if (onFinished) onFinished()
    }

    try {
      const { documentId, originId } = fields
      eventSource = new EventSourcePolyfill(
        `${config.API_BASE}/documents/${documentId}/recipient/${originId}/sse`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem(
              LocalStorageReserve.token
            )}`,
          },
        }
      )

      eventSource.addEventListener('data', (event) => {
        logDebugPrint(`handleEvent(data): '${event.data}'`)
        if (!fields.isRunning) breakEventSource()
        onResponseData(event.data)
        if (event.data === 'signed') breakEventSource()
      })

      eventSource.addEventListener('error', () => {
        logDebugPrint(`handleEvent(error)`)
        if (onResponseError) onResponseError('NETWORK ERROR')
        breakEventSource()
      })

      // ? Check Status of Page, should listen
      const prevWindowLocation = window.location.href
      // eslint-disable-next-line no-constant-condition
      while (true) {
        logDebugPrint(`status checker running`)
        // eslint-disable-next-line no-async-promise-executor
        await new Promise(async (resolve) => {
          setTimeout(() => {
            resolve(null)
          }, 1000)
        })

        if (prevWindowLocation !== window.location.href || !fields.isRunning) {
          logDebugPrint(`status checker break`)
          breakEventSource()
          return
        }
      }
    } catch (err) {
      logDebugPrint(`catch error: ${err}`)
      console.error(err)
      if (onResponseError) onResponseError('REQUEST ERROR')
      breakEventSource()
    }
  })
}
