import { Box, Grid, Typography } from '@mui/material'
import { HeaderWithLogo } from 'components'
import { IconButtonHelp } from 'features/help'
import { createContext, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { auth } from 'utils/auth'
import { LoginForm } from './components/loginForm'
import { useLogin } from './hooks/useLogin'

export const OnboardingContext = createContext<{
  step: number
  setStep?: any
  withInvites: boolean
  setWithInvites?: any
}>({
  step: 1,
  withInvites: false,
})

export const titlesAndDesc = [
  { title: 'Добро пожаловать в Doodocs', desc: 'Расскажите немного о себе' },
  {
    title: 'Создайте новое пространство',
    desc: 'Пространства —  место в которых команды могут работать вместе',
  },
  {
    title: 'Пригласите участников',
    desc: 'Добавьте участников, с которыми регулярно работаете',
  },
]

export const Login = ({
  redirectToAfterAuth,
  header,
}: {
  redirectToAfterAuth?: string
  header?: any
}) => {
  useLogin()
  const { authenticated, user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  )
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (!authenticated) return
    const redirectTo = auth.popPathRedirectAfterLogin()

    if (redirectToAfterAuth) {
      navigate(redirectToAfterAuth)
    } else if (redirectTo) {
      navigate(redirectTo)
    } else {
      searchParams.set('email', user.email)
      navigate(`/setup/onboarding?${searchParams.toString()}`)
    }
  }, [authenticated])

  return (
    <Box minHeight="100%" display="flex">
      <HeaderWithLogo />
      <LoginForm
        header={
          header ?? (
            <>
              <Typography variant="subtitle1">Авторизация Doodocs</Typography>
              <Typography variant="body1" mt="8px" mb="16px">
                Войдите или зарегистрируйтесь
              </Typography>
            </>
          )
        }
      />
      <Box sx={{ p: '0 24px', position: 'fixed', right: '0', bottom: '24px' }}>
        <Grid container justifyContent="flex-end">
          <IconButtonHelp />
        </Grid>
      </Box>
    </Box>
  )
}
