import { apiAiChat, config } from 'config'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { LocalStorageReserve } from 'helper/consts'

export const queryAiChatSendMessage = async ({
  id,
  message,
  onResponseChunk,
  onResponseDone,
  onResponseError,
}: {
  id: string
  message: string
  onResponseChunk: (chunkMsg: string) => void
  onResponseDone: () => void
  onResponseError: (errMsg: string) => void
}): Promise<any> => {
  return new Promise((resolve) => {
    try {
      const eventSource = new EventSourcePolyfill(
        `${config.API_AICHAT_URL}/${id}/ask?request=${message}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem(
              LocalStorageReserve.token
            )}`,
          },
        }
      )

      eventSource.addEventListener('message_chunk', (event) => {
        onResponseChunk(event.data)
      })

      eventSource.addEventListener('message_done', () => {
        eventSource.close()
        onResponseDone()
        resolve(null)
      })

      eventSource.addEventListener('error', () => {
        eventSource.close()
        onResponseError('NETWORK ERROR')
        resolve(null)
      })
    } catch (err) {
      console.error(err)
      // Maybe must change logic
      onResponseError('REQUEST ERROR')
      resolve(null)
    }
  })
}

export const queryAiChatGetHistory = async ({
  id,
}: {
  id: string
}): Promise<any> => {
  const resp = await apiAiChat.get(`/${id}/history`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem(
        LocalStorageReserve.token
      )}`,
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  return resp
}

export const queryAiChatGetSummary = async ({
  id,
  onResponseChunk,
  onResponseDone,
  onResponseError,
}: {
  id: string
  onResponseChunk: (chunkMsg: string) => void
  onResponseDone: () => void
  onResponseError: (errMsg: string) => void
}): Promise<any> => {
  return new Promise((resolve) => {
    try {
      const eventSource = new EventSourcePolyfill(
        `${config.API_AICHAT_URL}/${id}/summary`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem(
              LocalStorageReserve.token
            )}`,
          },
        }
      )

      eventSource.addEventListener('message_chunk', (event) => {
        onResponseChunk(event.data)
      })

      eventSource.addEventListener('message_done', () => {
        eventSource.close()
        onResponseDone()
        resolve(null)
      })

      eventSource.addEventListener('error', () => {
        eventSource.close()
        onResponseError('NETWORK ERROR')
        resolve(null)
      })
    } catch (err) {
      console.error(err)
      // Maybe must change logic
      onResponseError('REQUEST ERROR')
      resolve(null)
    }
  })
}

export const queryAiInitQuestions = async ({
  id,
  onResponseChunk,
  onResponseDone,
  onResponseError,
}: {
  id: string
  onResponseChunk: (chunkMsg: string) => void
  onResponseDone: () => void
  onResponseError: (errMsg: string) => void
}): Promise<any> => {
  return new Promise((resolve) => {
    try {
      const eventSource = new EventSourcePolyfill(
        `${config.API_AICHAT_URL}/${id}/init/questions`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem(
              LocalStorageReserve.token
            )}`,
          },
        }
      )

      eventSource.addEventListener('message_chunk', (event) => {
        onResponseChunk(event.data)
      })

      eventSource.addEventListener('message_done', () => {
        eventSource.close()
        onResponseDone()
        resolve(null)
      })

      eventSource.addEventListener('error', () => {
        eventSource.close()
        onResponseError('NETWORK ERROR')
        resolve(null)
      })
    } catch (err) {
      console.error(err)
      // Maybe must change logic
      onResponseError('REQUEST ERROR')
      resolve(null)
    }
  })
}

export const queryAiInitDocument = async ({
  id,
}: {
  id: string
}): Promise<any> => {
  const resp = await apiAiChat.get(`/${id}/init/document`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem(
        LocalStorageReserve.token
      )}`,
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  return resp
}
