export const weekDays = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']

export const months = [
  ['Января', 'янв'], // January
  ['Февраля', 'фев'], // February
  ['Марта', 'мар'], // March
  ['Апреля', 'апр'], // April
  ['Мая', 'май'], // May
  ['Июня', 'июн'], // June
  ['Июля', 'июл'], // July
  ['Августа', 'авг'], // August
  ['Сентября', 'сен'], // September
  ['Октября', 'окт'], // October
  ['Ноября', 'ноя'], // November
  ['Декабря', 'дек'], // December
]
