import img_egov_b from 'assets/img/egov-b.svg'
import img_egov from 'assets/img/egov.svg'
import img_key from 'assets/img/massSign/key.svg'

import { Box, CircularProgress, Modal, Typography } from '@mui/material'
import { MessageSnackbar, styleLoading } from 'components'
import { EgovQR } from 'components/egov/egov-sign'
import { MenuItemButton } from 'components/menu-item/menu-item-button'
import {
  EDocumentStatus,
  ERecipientRole,
  ESignOptionType,
  EgovMobileTypes,
} from 'helper/consts'
import { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { startWorkflow } from 'store/slices/wfPostmanSlice'

const ModalLoadingNCA = ({ open }: { open: boolean }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleLoading}>
        <CircularProgress />
        <Typography
          id="modal-modal-title"
          variant="body2"
          color="text.secondary"
        >
          Запуск NCALayer...
        </Typography>
      </Box>
    </Modal>
  )
}

export const SignOptions = ({
  setOption,
  option,
}: {
  setOption: (option: string) => void
  option: string
}) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [error, setError] = useState('')

  const { document, sign_settings }: IApplicationState = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )

  const { loading: wfLoading, sign } = useSelector(
    (state: RootState) => state.wfPostman,
    shallowEqual
  )

  const { user }: IUserState = useSelector(
    (state: any) => state.user,
    shallowEqual
  )

  const signWithNuc = async () => {
    setLoading(true)
    await dispatch(
      startWorkflow({
        id: document.id,
        documentType: document.type,
        status: document.status as EDocumentStatus,
        email: user.email,
        option: ESignOptionType.NCA,
        navigate,
      }) as any
    )
    setLoading(false)
  }

  return (
    <>
      <ModalLoadingNCA
        open={loading || (wfLoading.sign && option === '') ? true : false}
      />

      <MessageSnackbar
        message={error}
        clearMessage={() => setError('')}
        severity="error"
      />

      {option === EgovMobileTypes.business ||
      option === EgovMobileTypes.regular ? (
        <EgovQR handleClose={() => setOption('')} type={option} />
      ) : (
        <>
          <Typography
            sx={{
              p: '4px 8px',
            }}
            fontSize={'12px'}
            fontWeight={500}
            color={'text.disabled'}
          >
            {`Каким способом хотите ${
              sign.isMeSign === ERecipientRole.approver_rk
                ? 'согласовать'
                : 'подписать'
            }`}
          </Typography>

          {sign_settings.is_sign_egov_enabled && (
            <MenuItemButton
              onClick={() => setOption(EgovMobileTypes.regular)}
              srcImage={img_egov}
              title={EgovMobileTypes.regular}
              description={`В мобильном приложении ${
                EgovMobileTypes.regular
              } отсканируйте QR-код для ${
                sign.isMeSign === ERecipientRole.approver_rk
                  ? 'согласования'
                  : 'подписания'
              }`}
            />
          )}

          {sign_settings.is_sign_egovbusiness_enabled && (
            <MenuItemButton
              onClick={() => setOption(EgovMobileTypes.business)}
              srcImage={img_egov_b}
              title={EgovMobileTypes.business}
              description={`В мобильном приложении ${
                EgovMobileTypes.business
              } отсканируйте QR-код для ${
                sign.isMeSign === ERecipientRole.approver_rk
                  ? 'согласования'
                  : 'подписания'
              }`}
            />
          )}

          {sign_settings.is_sign_nca_enabled && (
            <MenuItemButton
              onClick={() => {
                signWithNuc()
              }}
              srcImage={img_key}
              title={'ЭЦП НУЦ'}
              description={`Воспользуйтесь RSA ключом для ${
                sign.isMeSign === ERecipientRole.approver_rk
                  ? 'согласования'
                  : 'подписания'
              }`}
            />
          )}
        </>
      )}
    </>
  )
}
