import { useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {
  ESidebarDocumentTrackingViewMode,
  SidebarDocumentTrackingContext,
} from '../../provider/SidebarDocumentTrackingContext'
import { SignerInfo } from '../RecipientSignerInfo'
import { Box } from '@mui/material'
import { WorkflowRecipientsList } from '../WorkflowRecipientsList'
import { Loading } from 'components'
import { RecipientEdit } from '../RecipientEdit'

export const DocumentTrackingContent = () => {
  const { loading } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )
  const { mode } = useContext(SidebarDocumentTrackingContext)

  if (loading.recipients) return <Loading />
  if (mode === ESidebarDocumentTrackingViewMode.SignerInfo)
    return <SignerInfo />
  if (mode === ESidebarDocumentTrackingViewMode.EditRecipient)
    return <RecipientEdit />

  return (
    <Box
      sx={{
        mt: '16px',
      }}
    >
      <WorkflowRecipientsList />
    </Box>
  )
}
