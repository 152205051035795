import { Menu, Divider } from '@mui/material'
import { ActionButtons } from './actionButtons'

const isLastInArray = (index: number, array: any[]) => {
  if (!array.length) return false
  return index === array.length - 1
}

export const ActionMenu = ({ anchorEl, open, handleClose, actions }) => {
  return (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        marginTop: '2px',
      }}
    >
      {actions.map((_actions, i) => {
        const items: any[] = []
        items.push(
          <ActionButtons key={`doc-actions-button-${i}`} items={_actions} />
        )
        if (!isLastInArray(i, actions)) {
          items.push(<Divider key={`doc-actions-divider-${i}`} />)
        }
        return items
      })}
    </Menu>
  )
}
