import { Box, Button } from '@mui/material'
import { DocumentsTable, Empty, Loading } from 'components'
import { EIllustration } from 'components/empty'
import {
  SearchBarContextProvider,
  SelectedDocsIdsProvider,
  useSearchBarContext,
  useSelectedDocsContext,
} from 'context'
import { SearchBar } from 'features/search-bar'
import { EDocumentStatus, UrlKeys } from 'helper/consts'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { MassActions } from 'pages/documents/components/mass-actions'
import { useEffect } from 'react'
import { shallowEqual } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getRegistry } from 'service'
import { useAppSelector } from 'store/store'

export const TabContentDocuments = () => {
  return (
    <SearchBarContextProvider>
      <SelectedDocsIdsProvider>
        <Registry />
      </SelectedDocsIdsProvider>
    </SearchBarContextProvider>
  )
}

const Registry = () => {
  const { tsId } = useParams()
  const { urlId: urlTemplateId } = useUrlIdParam(UrlKeys.templateId)
  const { queryString, authorQuery, statusQuery } = useSearchBarContext() // Get the query string from context
  const { selectedDocsIds } = useSelectedDocsContext()

  const {
    items,
    loadMoreRef,
    page,
    loading,
    setItems,
    setPage,
    loadItems,
    count,
  } = useInfiniteScroll({
    getItems: async (page: number) =>
      getRegistry({
        page,
        teamspaceId: tsId || '',
        template_id: urlTemplateId.get(),
        limit: 50,
        query: queryString,
        author_id: authorQuery,
        filter: statusQuery || EDocumentStatus.ALL,
        onlyOwnDocuments: false,
      }),
    parseResponse: (response) => {
      return {
        documents: response.data.list.documents,
        count: response.data.list.count,
      }
    },
  })

  useEffect(() => {
    setItems([])
    setPage(1)
    loadItems()
  }, [queryString, authorQuery, statusQuery])

  return (
    <>
      <Box padding={'20px 16px 48px'}>
        <Box mb={'12px'}>
          {selectedDocsIds.size > 0 ? (
            <MassActions filter={EDocumentStatus.ALL} />
          ) : (
            (count > 0 || authorQuery || statusQuery || queryString) && (
              <SearchBar filter={EDocumentStatus.ALL} />
            )
          )}
        </Box>
        {!loading && items.length == 0 ? (
          <DocumentsTableEmpty />
        ) : (
          <DocumentsTable
            page={page}
            documents={items}
            loading={false}
            lastRowRef={loadMoreRef}
          />
        )}
        {loading && <Loading />}
      </Box>
    </>
  )
}

const DocumentsTableEmpty = () => {
  const navigate = useNavigate()

  const { urlId: urlTemplateId } = useUrlIdParam(UrlKeys.templateId)
  const { selectedTeamspace } = useAppSelector(
    (state) => state.teamspace,
    shallowEqual
  )

  return (
    <Empty
      illustration={EIllustration.empty_box}
      title={'Нет документов'}
      subtitle={'Здесь будут хранится все документы созданные из этого шаблона'}
    >
      <Button
        variant="outlined"
        color="secondary"
        onClick={() =>
          navigate(
            `/teamspace/${
              selectedTeamspace?.id
            }/documents/draft?templateId=${urlTemplateId.get()}`
          )
        }
      >
        Новый документ
      </Button>
    </Empty>
  )
}
