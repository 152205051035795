import { Box, Button } from '@mui/material'
import { useSearchBarContext } from 'context'
import { EListQueryParam } from 'helper/queryParams'
import { useSearchParams } from 'react-router-dom'

export const ResetFilter = () => {
  const { setAuthorQuery, setStatusQuery, setQueryString } =
    useSearchBarContext()

  const [searchParams, setSearchParams] = useSearchParams()

  const handleClick = () => {
    setAuthorQuery('')
    setStatusQuery('')
    setQueryString('')

    searchParams.delete(EListQueryParam.Author)
    searchParams.delete(EListQueryParam.Status)
    searchParams.delete(EListQueryParam.Query)

    setSearchParams(searchParams)
  }

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Button
        onClick={handleClick}
        size="small"
        color="secondary"
        sx={{ width: 'max-content' }}
      >
        Сбросить фильтр
      </Button>
    </Box>
  )
}
