import img_arrow_left from 'assets/img/id_arrow-right.svg'

import { Box, IconButton } from '@mui/material'
import { UrlKeys } from 'helper/consts'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { SidebarLayout } from 'layout/sidebar-layout'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { reset } from 'store/slices/workflow'
import { getRecipients } from 'store/slices/applicationSlice'
import { DocumentTrackingContent } from '../DocumentTrackingContent'
import { convertDateToLocale } from 'utils/convertDateToLocale'
import {
  ESidebarDocumentTrackingViewMode,
  SidebarDocumentTrackingContext,
} from 'features/document-tracking/provider/SidebarDocumentTrackingContext'

export function SidebarDocumentTracking({ handleClose }) {
  const { document } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )

  const dispatch = useDispatch()
  const { urlId } = useUrlIdParam(UrlKeys.documentId)
  const [mode, setMode] = useState(
    ESidebarDocumentTrackingViewMode.RecipientList
  )
  const [currentRecipientProps, setCurrentRecipientProps] = useState({
    origin_id: '',
  })

  useEffect(() => {
    dispatch(getRecipients({ id: urlId.get() }) as any)
  }, [urlId.get(), dispatch])

  return (
    <SidebarLayout
      header={{
        titlePrefixBtn: mode !==
          ESidebarDocumentTrackingViewMode.RecipientList && (
          <IconButton
            onClick={() => {
              setMode(ESidebarDocumentTrackingViewMode.RecipientList)
              dispatch(reset() as any)
            }}
            sx={{ borderRadius: '4px', p: '4px' }}
          >
            <Box
              component={'img'}
              sx={{
                width: '16px',
                height: '16px',
                transform: 'rotate(180deg)',
              }}
              src={img_arrow_left}
            />
          </IconButton>
        ),
        handleClose,
        title:
          mode === ESidebarDocumentTrackingViewMode.RecipientList
            ? 'Отслеживание документа'
            : 'Получатель',
        subtitle:
          mode === ESidebarDocumentTrackingViewMode.RecipientList &&
          document.history &&
          document.history.length > 0 &&
          document.history[0].created_at
            ? 'Документ создан ' +
              convertDateToLocale(document.history[0].created_at)
            : undefined,
      }}
    >
      <SidebarDocumentTrackingContext.Provider
        value={{
          mode,
          setMode,
          currentRecipientProps,
          setCurrentRecipientProps,
        }}
      >
        <DocumentTrackingContent />
      </SidebarDocumentTrackingContext.Provider>
    </SidebarLayout>
  )
}
