import { Typography, Box, Button } from '@mui/material'
import { MessageSnackbar } from 'components'
import { TextInputIcon } from 'components/input/text-input'
import { Formik, useFormikContext } from 'formik'
import { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { createNewTeamspace } from 'store/slices/teamspaceSlice'
import { ColorButton } from './create-group'
import * as Yup from 'yup'

export const NewGroupForm = ({ setName, setStep }) => {
  const dispatch = useDispatch()

  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const { loading } = useSelector((state: RootState) => state.teamspace)

  const [error, setError] = useState('')

  const handleSubmit = async (values) => {
    const { color, ts_name } = values
    setName(ts_name)

    dispatch(
      createNewTeamspace({
        id: selectedWorkspace?.id,
        name: ts_name,
        color: color,
        setStep,
      }) as any
    )
  }

  const validationSchema = Yup.object().shape({
    ts_name: Yup.string().required('Обязательное поле'),
  })

  return (
    <>
      <MessageSnackbar
        message={error}
        clearMessage={() => setError('')}
        severity={'error'}
      />
      <Typography variant="body1">
        Используйте группы для разделения пространства между участниками для
        совместной работы
      </Typography>
      <Box mt={'16px'}>
        <Formik
          validateOnBlur={false}
          initialValues={{
            ts_name: '',
            color: iconColors[0],
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Box pb={'8px'}>
                <NameColor />
              </Box>
              <Box
                p={'8px 0 0'}
                id="footer"
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading.create || !values.ts_name ? true : false}
                >
                  Создать группу
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </>
  )
}

export const iconColors = [
  '#6FBAFF',
  '#9499E8',
  '#FF6534',
  '#FF9B7A',
  '#FFCC77',
  '#6BD3C2',
  '#6B6F76',
  '#282A2F',
]

export const coloredFilters = {
  '#6FBAFF': '',
  '#9499E8': 'hue-rotate(22deg)',
  '#FF6534': 'hue-rotate(173deg) saturate(5.5)',
  '#FF9B7A': 'hue-rotate(157deg)',
  '#FFCC77': 'sepia(100%) saturate(2) hue-rotate(6deg)',
  '#6BD3C2': 'hue-rotate(300deg) saturate(0.8) brightness(1.1)',
  '#6B6F76': 'sepia(0%) brightness(0.7) saturate(0%) ',
  '#282A2F': 'sepia(0%) brightness(0.3) saturate(0%)',
}

export const NameColor = () => {
  const { values } = useFormikContext<{ color: string; ts_name: string }>()

  return (
    <>
      <TextInputIcon
        autoFocus
        size="small"
        fullWidth
        placeholder="Название группы..."
        name={'ts_name'}
        label={'Название группы'}
        filter={coloredFilters[values.color] || ''}
      ></TextInputIcon>
      <Box mt={1} display={'flex'}>
        {iconColors.map((color) => (
          <Box mr="6px" key={`color-box-${color}`}>
            <ColorButton
              color={color}
              selected={
                values.color ? color === values.color : color === iconColors[0]
              }
            />
          </Box>
        ))}
      </Box>
    </>
  )
}
