import { Box } from '@mui/material'
import { Loading } from 'components'
import { withDashboardLayout } from 'layout'
import { useEffect } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import { wsPlanGetPlan } from 'store/slices/wsPlan/wsPlanGetPlan'
import { useAppSelector } from 'store/store'
import { PaymentLimitsAppBar } from './components/PaymentLimitsAppBar'
import { PaymentRequisites } from './components/PaymentRequisites'
import { SubscriptionPlanBanner } from './components/SubscriptionPlanBanner'
import { SubscriptionPlanLimits } from './components/SubscriptionPlanLimits'
import { PaymentHistory } from './components/PaymentHistory'

export const PaymentContent = () => {
  const { workspace_id } = useAppSelector((state) => state.wsPlan, shallowEqual)
  const { selectedWorkspace } = useAppSelector(
    (state) => state.selectedWorkspace,
    shallowEqual
  )
  const dispatch = useDispatch()

  useEffect(() => {
    selectedWorkspace?.id &&
      dispatch(wsPlanGetPlan({ wsId: selectedWorkspace?.id }) as any)
  }, [selectedWorkspace])

  if (workspace_id != selectedWorkspace?.id) return <Loading />
  return (
    <>
      <Box p={{ xs: '16px', sm: '16px 40px' }} overflow={'auto'}>
        <Box
          maxWidth={'720px'}
          gap={'32px'}
          display={'flex'}
          flexDirection={'column'}
        >
          <SubscriptionPlanBanner />
          <SubscriptionPlanLimits />
          <PaymentRequisites />
          <PaymentHistory />
        </Box>
      </Box>
    </>
  )
}

export const Payment = () =>
  withDashboardLayout(PaymentContent)({ Header: PaymentLimitsAppBar })
