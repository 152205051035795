import { Box } from '@mui/material'
import { ManageTemplateNameWithActions } from '../ManageTemplateNameWithActions'
import { ManageTemplateTabs } from '../ManageTemplateTabs'
import {
  EManageTemplateTab,
  ManageTemplateTabsContext,
  useManageTemplateTabsContextDefaultValue,
} from 'pages/manage-template/contexts/ManageTemplateTabsContext'
import { ManageTemplateTabContent } from '../ManageTemplateTabContent'

export const ManageTemplateContent = () => {
  const manageTemplateTabsContextDefaultValue =
    useManageTemplateTabsContextDefaultValue(EManageTemplateTab.Documents)

  return (
    <ManageTemplateTabsContext.Provider
      value={manageTemplateTabsContextDefaultValue}
    >
      <Box
        sx={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: '808px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            padding: '48px 20px 16px',
          }}
        >
          <ManageTemplateNameWithActions />
          <ManageTemplateTabs />
        </Box>
        <Box width={'100%'}>
          <ManageTemplateTabContent />
        </Box>
      </Box>
    </ManageTemplateTabsContext.Provider>
  )
}
