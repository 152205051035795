import { useCallback, useState } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import { canSend } from 'utils'
import { ButtonSendDocumentOnlyByWorkflow } from '../ButtonSendDocumentOnlyByWorkflow'
import { SendButtonDropdown } from './button-dropdown'
import { EDocumentStatus, UrlKeys } from 'helper/consts'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { useParams } from 'react-router-dom'
import { createNewDocument } from 'store/slices/applicationSlice'
import { useFormikContext } from 'formik'
import { useAppSelector } from 'store/store'
import { MessageSnackbar } from 'components'

const isFormAvailableToCheck = (
  values: any,
  status: EDocumentStatus
): boolean =>
  values &&
  Object.keys(values).length > 0 &&
  (status === EDocumentStatus.DRAFT || status === EDocumentStatus.REVOKED)

export const ButtonSendDocumentOrSign = () => {
  const dispatch = useDispatch()
  const [error, setError] = useState('')

  const { urlId } = useUrlIdParam(UrlKeys.documentId)
  const { tsId } = useParams()

  const { document, template: applicationTemplate }: IApplicationState =
    useAppSelector((state) => state.application, shallowEqual)

  const template = useAppSelector((state) => state.template, shallowEqual)

  const { values, validateForm } = useFormikContext()

  const isTemplateAvailableToSend = useCallback(() => {
    return document.type === 'TEMPLATE' && canSend(document.status)
  }, [document.type, document.status])

  const createDocument = async () => {
    const name = document.name || applicationTemplate?.name || template.name
    const template_id = applicationTemplate?.id || template.id

    await dispatch(
      createNewDocument({
        name,
        template_id,
        tsId,
        urlId,
      }) as any
    )
  }

  const isErrorsFormik = async (): Promise<boolean> => {
    const validationErrors = await validateForm()
    if (Object.keys(validationErrors).length > 0) {
      return true
    }
    return false
  }

  const isDocumentAvailableToSend = async () => {
    if (isFormAvailableToCheck(values, document.status)) {
      const hasErrors = await isErrorsFormik()
      if (hasErrors) {
        setError('Заполните все обязательные поля')
        return false
      }
    }

    if (!urlId.get()) {
      try {
        await createDocument()
      } catch {
        return false
      }
    }
    return true
  }

  if (isTemplateAvailableToSend())
    return (
      <>
        <ButtonSendDocumentOnlyByWorkflow
          onClickValidateCallback={isDocumentAvailableToSend}
        />
        <MessageSnackbar
          message={error}
          clearMessage={() => setError('')}
          severity={'error'}
        />
      </>
    )

  return (
    <>
      <SendButtonDropdown />
      <MessageSnackbar
        message={error}
        clearMessage={() => setError('')}
        severity={'error'}
      />
    </>
  )
}
