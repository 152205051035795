import { createTheme } from '@mui/material'
const { palette } = createTheme()
const { augmentColor } = palette

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    google: true
  }
}

declare module '@mui/material/styles/createPalette' {
  interface CustomPalette {
    google: PaletteColorOptions
  }

  export interface PaletteOptions extends CustomPalette {
    neutral: {
      100: string
      200: string
      300: string
      400: string
      500: string
      600: string
      700: string
      800: string
      900: string
    }
  }
}

export const light = {
  neutral: {
    100: '#F3F4F6',
    200: '#E5E7EB',
    300: '#D1D5DB',
    400: '#9CA3AF',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#111827',
  },
  action: {
    active: '#6B7280',
    focus: 'none',
    hover: '#C3E6CD',
    selected: 'rgba(55, 65, 81, 0.08)',
    disabledBackground: '#F5F5F5',
  },
  background: {
    default: '#FFFFFF',
    paper: '#F7F7F7',
  },
  divider: '#E6E8F0',
  primary: {
    main: '#0085FF',
    light: '#339DFF',
    dark: '#1272CC',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#282A2F',
    light: '#868686',
    dark: '#525252',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#000000',
    light: '#E8E8E8',
    dark: '#0B79D0',
    contrastText: '#000000',
  },
  warning: {
    main: '#FFF4E8',
    light: '#FFBF4C',
    dark: '#B27B16',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#E92C2C',
    light: '#FFEBEB',
    // dark: '#585757',
    contrastText: '#F74141',
  },
  text: {
    primary: '#282A2F',
    secondary: '#3C4149',
    disabled: '#6B6F76',
  },
  google: augmentColor({ color: { main: '#0085FF' } }),
}
