import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useContext, useEffect } from 'react'

import { $generateNodesFromDOM } from '@lexical/html'
import { $getRoot, $insertNodes, LexicalEditor } from 'lexical'
import { DocumentEditorContext } from 'hooks/useDocumentEditorContextDefaultValue'

function lexicalSetHtmlContent(editor: LexicalEditor, htmlContent: string) {
  editor.update(() => {
    const parser = new DOMParser()
    const parsed = parser.parseFromString(htmlContent, 'text/html')
    // Once you have the DOM instance it's easy to generate LexicalNodes.
    const nodes = $generateNodesFromDOM(editor, parsed)
    // Insert them at a selection.
    $insertNodes(nodes)
    // Select the root
    $getRoot().selectEnd()
  })
}

export default function LoadHTMLContentPlugin() {
  const [editor] = useLexicalComposerContext()
  const { fields } = useContext(DocumentEditorContext)
  useEffect(() => {
    lexicalSetHtmlContent(editor, fields.contentHtml)
  }, [])

  return null
}
