import { Box, Typography } from '@mui/material'
import { ComponentPickerMenuItem } from 'features/text-editors/lexical/plugins/ComponentPickerPlugin'

export const ListOfVariableOptions = (props) => {
  const {
    options,
    selectedIndex,
    setHighlightedIndex,
    selectOptionAndCleanUp,
  } = props
  return (
    <>
      <Box p="6px 16px 4px">
        <Typography variant="caption" color={'#90959D'}>
          Переменные
        </Typography>
      </Box>
      <ul>
        {options.map((option, i: number) => (
          <ComponentPickerMenuItem
            index={i}
            isSelected={selectedIndex === i}
            onClick={() => {
              setHighlightedIndex(i)
              selectOptionAndCleanUp(option)
            }}
            onMouseEnter={() => {
              setHighlightedIndex(i)
            }}
            key={option.key}
            option={option}
          />
        ))}
      </ul>
    </>
  )
}
