import { useState, useMemo, createContext } from 'react'
import { shallowEqual } from 'react-redux'
import { useAppSelector } from 'store/store'

export const useTemplatesBucketRegistryContextDefaultValue = () => {
  const [hiddenIds, setHiddenIds] = useState<{
    [key: string]: boolean
  }>({})

  const { private_templates, filter } = useAppSelector(
    (state) => state.templates,
    shallowEqual
  )

  const rows = useMemo(() => {
    const result = private_templates.filter((el) => !el || !hiddenIds[el.id])
    return result
  }, [filter, private_templates, hiddenIds])

  const hideTemplateFromRegistry = (templateId: string) => {
    setHiddenIds({ ...hiddenIds, [templateId]: true })
  }

  const unhideTemplateFromRegistry = (templateId: string) => {
    const keys = Object.keys(hiddenIds)
    const result = {}
    for (let i = 0; i < keys.length; i++) {
      const id = keys[i]
      if (id == templateId) continue
      result[id] = hiddenIds[id]
    }
    setHiddenIds(result)
  }

  return {
    rows,
    hideTemplateFromRegistry,
    unhideTemplateFromRegistry,
    hiddenIds,
  }
}

export const TemplatesBucketRegistryContext = createContext<
  ReturnType<typeof useTemplatesBucketRegistryContextDefaultValue>
>({} as any)
export const TemplatesBucketRegistryProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const templatesRegistryContext =
    useTemplatesBucketRegistryContextDefaultValue()

  return (
    <TemplatesBucketRegistryContext.Provider value={templatesRegistryContext}>
      {children}
    </TemplatesBucketRegistryContext.Provider>
  )
}
