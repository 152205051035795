import { Button, TableContainer } from '@mui/material'
import { Loading, MessageSnackbar } from 'components'
import { shallowEqual, useSelector } from 'react-redux'
import { useSnackbar } from 'components/snackbar/hook'
import { deleteDocument, restoreDocument } from 'service'
import { Empty } from 'components/empty'

import img_delete_gray from 'assets/img/delete.svg'
import { useSelectedDocsContext } from 'context'
import { DeletedRecordsTable } from 'components/deleted-records-table'

export const DeletedDocumentsTable = () => {
  const { hiddenDocumentIds, setHiddenDocumentIds } = useSelectedDocsContext()
  const { snackbar, showSnackbar, closeSnackbar } = useSnackbar()

  const { count, documents, loading, page }: IDocTableState = useSelector(
    (state: RootState) => state.documents,
    shallowEqual
  )

  const cancelActionRestore = async (id) => {
    try {
      const res = await deleteDocument({ id })
      setHiddenDocumentIds((state) => state.filter((_id) => _id !== id))
      showSnackbar({ message: 'Отменено', severity: 'info' })
      return res
    } catch (error: any) {
      if (error instanceof Error) {
        showSnackbar({
          message: error.message,
          severity: 'error',
          close: closeSnackbar,
        })
      }
    }
  }

  const actionRestore = async (id: string) => {
    const btns = [
      <Button
        size="small"
        color="secondary"
        variant="outlined"
        sx={{ height: '24px' }}
        onClick={async () => {
          await cancelActionRestore(id)
        }}
      >
        Отменить
      </Button>,
    ]
    try {
      const res = await restoreDocument({ id })
      setHiddenDocumentIds((state) => [...state, id])
      showSnackbar({
        message: 'Документ восстановлен',
        severity: 'info',
        buttons: btns,
        close: closeSnackbar,
      })
      return res
    } catch (error: any) {
      if (error instanceof Error) {
        showSnackbar({
          message: error.message,
          severity: 'error',
          close: closeSnackbar,
        })
      }
    }
  }

  const actionDelete = async (id: string) => {
    try {
      const res = await deleteDocument({ id })
      setHiddenDocumentIds((state) => [...state, id])
      showSnackbar({
        message: 'Документ удален безвозвратно',
        severity: 'info',
        close: closeSnackbar,
      })
      return res
    } catch (error: any) {
      if (error instanceof Error) {
        showSnackbar({
          message: error.message,
          severity: 'error',
          close: closeSnackbar,
        })
      }
    }
  }

  if (count === 0)
    return (
      <Empty
        src={img_delete_gray}
        title={'В корзине еще нет документов'}
        subtitle={'Здесь будут храниться удаленные документы'}
      />
    )
  return (
    <>
      <MessageSnackbar
        message={snackbar.message}
        clearMessage={snackbar.close}
        severity={snackbar.severity}
        buttons={snackbar.buttons}
      />
      <TableContainer>
        <DeletedRecordsTable
          actionRestore={actionRestore}
          loading={loading}
          rows={documents}
          page={page}
          actionDelete={actionDelete}
          hiddenDocumentIds={hiddenDocumentIds}
        />
        {loading && <Loading />}
      </TableContainer>
    </>
  )
}
