import {
  $getNearestNodeFromDOMNode,
  $getNodeByKey,
  LexicalEditor,
  NodeKey,
} from 'lexical'
import { useEffect, useMemo, useRef } from 'react'
import { createPortal } from 'react-dom'
import {
  $isColumnsListNode,
  ColumnsListNode,
} from '../../../nodes/ColumnsListNode'
import {
  $isColumnsListItemNode,
  ColumnsListItemNode,
} from '../../../nodes/ColumnsListNode/ColumnsListItemNode'
import { $findNodeByTypeFromParents } from '../../../utils/findNodeByTypeFromParents'
import './index.css'

function getSelfColumnsListItemNodeByKey(
  nodeKey: NodeKey
): ColumnsListItemNode | null | undefined {
  const selfNode = $getNodeByKey(nodeKey)
  if (!selfNode) return null

  return $findNodeByTypeFromParents(selfNode, $isColumnsListItemNode)
}

function getColumnsListNodeByTarget(
  editor: LexicalEditor,
  target: HTMLElement
): ColumnsListNode | null {
  if (!target) return null
  let columnsListNode: ColumnsListNode | null = null

  editor.update(() => {
    const targetNode = $getNearestNodeFromDOMNode(target)
    if (!targetNode) return
    const foundNode = $findNodeByTypeFromParents(targetNode, $isColumnsListNode)

    if (!foundNode) return
    columnsListNode = foundNode
  })

  if (!columnsListNode) return null
  columnsListNode = columnsListNode as ColumnsListNode

  return columnsListNode
}

interface IResizingColumnsListItemNodes {
  columnsListItemNode: ColumnsListItemNode
  nextColumnsListItemNode: ColumnsListItemNode
}

function getResizingColumnsListItemNodesByColumnListNode({
  editor,
  columnsListNode,
  mousePageX,
}: {
  editor: LexicalEditor
  columnsListNode: ColumnsListNode
  mousePageX: number
}) {
  const result: IResizingColumnsListItemNodes = {} as any
  editor.update(() => {
    const columnsListItemNodes = columnsListNode
      .getChildren()
      .filter($isColumnsListItemNode)
    if (columnsListItemNodes.length < 2) return

    const columnsListElem = editor.getElementByKey(
      columnsListNode.getKey()
    ) as HTMLDivElement
    if (!columnsListElem) return

    for (let i = 0; i < columnsListItemNodes.length; i++) {
      result.columnsListItemNode = result.nextColumnsListItemNode
      result.nextColumnsListItemNode = columnsListItemNodes[i]

      const columnsListItemNode = columnsListItemNodes[i]
      const columnsListItemElem = editor.getElementByKey(
        columnsListItemNode.getKey()
      ) as HTMLDivElement
      if (!columnsListItemElem) return

      const { left, width } = columnsListItemElem.getBoundingClientRect()
      const leftEdge = left + width

      if (leftEdge >= mousePageX) break
    }
  })

  if (!result.columnsListItemNode || !result.nextColumnsListItemNode)
    return null
  return result
}

function getResizingColumnsListItemNodesByKey(
  editor: LexicalEditor,
  columnsListItemNodeKey: NodeKey
): IResizingColumnsListItemNodes | null {
  const result: IResizingColumnsListItemNodes = {} as any
  editor.update(() => {
    const columnsListItemNode = getSelfColumnsListItemNodeByKey(
      columnsListItemNodeKey
    )
    if (!columnsListItemNode) return null
    result.columnsListItemNode = columnsListItemNode

    const nextColumnsListItemNode = columnsListItemNode
      .getNextSiblings()
      ?.find((ch) => $isColumnsListItemNode(ch)) as ColumnsListItemNode
    if (!nextColumnsListItemNode) return null
    result.nextColumnsListItemNode = nextColumnsListItemNode
  })

  if (!result.columnsListItemNode || !result.nextColumnsListItemNode)
    return null

  return result
}

function calculateColumnsListItemsWidthPrecents(
  columnsListItemOnStart: {
    node: ColumnsListItemNode
    rect: DOMRect
  },
  nextColumnsListItemOnStart: {
    node: ColumnsListItemNode
    rect: DOMRect
  },
  cursor: {
    pageXOnStart: number
    pageXOnEnd: number
  }
) {
  const cir = columnsListItemOnStart.rect
  const ncir = nextColumnsListItemOnStart.rect

  const rangeWidth = ncir.right - cir.left - (ncir.left - cir.right)
  const delta = cursor.pageXOnEnd - cursor.pageXOnStart
  const sumOfPrecent =
    columnsListItemOnStart.node.__widthPrecent +
    nextColumnsListItemOnStart.node.__widthPrecent

  let newWidth = cir.width + delta
  const columnsListItemMaxWidth = rangeWidth - columnsListItemMinWidth
  if (rangeWidth <= columnsListItemMinWidth * 2) {
    newWidth = cir.width
  } else if (newWidth < columnsListItemMinWidth) {
    newWidth = columnsListItemMinWidth
  } else if (newWidth > columnsListItemMaxWidth) {
    newWidth = columnsListItemMaxWidth
  }
  const nPrecent = newWidth / rangeWidth

  return {
    sumOfPrecent: sumOfPrecent,
    nPrecent: nPrecent,
    columnsListItemNewPrecentWidth: sumOfPrecent * nPrecent,
    nextColumnsListItemNewPrecentWidth: sumOfPrecent * (1 - nPrecent),
  }
}

function hideKnobElem(knobElem: HTMLDivElement) {
  knobElem.style.display = 'none'
}

function showKnobElem(
  knobElem: HTMLDivElement,
  targetArea: {
    pageX: number
    pageY: number
    width: number
    height: number
  }
) {
  knobElem.style.display = 'block'
  knobElem.style.top = `${targetArea.pageY}px`
  knobElem.style.left = `${targetArea.pageX}px`
  knobElem.style.width = `${targetArea.width}px`
  knobElem.style.height = `${targetArea.height}px`
}

function showKnobElemBetwenRects(
  knobElem: HTMLDivElement,
  {
    columnsListItemRect,
    nextColumnsListItemRect,
  }: {
    columnsListItemRect: DOMRect
    nextColumnsListItemRect: DOMRect
  },
  anchorElem: HTMLElement
) {
  const anchorRect = anchorElem.getBoundingClientRect()

  showKnobElem(knobElem, {
    height: columnsListItemRect.height,
    width:
      nextColumnsListItemRect.left -
      (columnsListItemRect.left + columnsListItemRect.width),
    pageX:
      columnsListItemRect.left + columnsListItemRect.width - anchorRect.left,
    pageY: columnsListItemRect.top - anchorRect.top,
  })
}

const columnsListItemMinWidth = 10

export function ColumnsListItemsResizerPlugin({
  editor,
  anchorElem = document.body,
}: {
  editor: LexicalEditor
  anchorElem?: HTMLElement
}): JSX.Element | null {
  const docBodyElem = document.body
  const resizerKnobRef = useRef<HTMLDivElement>(null)

  const cached = useMemo(() => {
    let pageXOnStart = 0
    let pageXOnEnd = 0
    let columnsListItemNodeKey: NodeKey = ''
    let columnsListItemElemPropsOnStart: DOMRect = {} as any
    let nextColumnsListItemElemPropsOnStart: DOMRect = {} as any

    return {
      setPageXOnStart: (pageX: number) => {
        pageXOnStart = pageX
      },
      getPageXOnStart: () => pageXOnStart,

      setPageXOnEnd: (pageX: number) => {
        pageXOnEnd = pageX
      },
      getPageXOnEnd: () => pageXOnEnd,

      setColumnsListItemNodeKey: (nodeKey: NodeKey) => {
        columnsListItemNodeKey = nodeKey
      },
      getColumnsListItemNodeKey: () => columnsListItemNodeKey,

      setColumnsListItemElemPropsOnStart: (props: DOMRect) => {
        columnsListItemElemPropsOnStart = props
      },
      getColumnsListItemElemPropsOnStart: () => columnsListItemElemPropsOnStart,

      setNextColumnsListItemElemPropsOnStart: (props: DOMRect) => {
        nextColumnsListItemElemPropsOnStart = props
      },
      getNextColumnsListItemElemPropsOnStart: () =>
        nextColumnsListItemElemPropsOnStart,
    }
  }, [])

  useEffect(() => {
    if (!editor.hasNodes([ColumnsListNode, ColumnsListItemNode])) {
      throw new Error(
        'ColumnsListItemsResizerPlugin: [ColumnsListNode, ColumnsListItemNode] not registered on editor'
      )
    }

    function onMouseMove(event: MouseEvent) {
      if (!resizerKnobRef.current) return
      if (!event.target || !(event.target instanceof HTMLDivElement)) return

      const target = event.target
      const columnsListNode = getColumnsListNodeByTarget(editor, target)
      if (!columnsListNode) {
        if (!target.classList.contains('columns-list-resize-knob'))
          hideKnobElem(resizerKnobRef.current)
        return
      }

      const resizingColumnsListItemNodes =
        getResizingColumnsListItemNodesByColumnListNode({
          editor,
          columnsListNode,
          mousePageX: event.pageX,
        })
      if (!resizingColumnsListItemNodes) return
      const { columnsListItemNode, nextColumnsListItemNode } =
        resizingColumnsListItemNodes

      const columnsListItemNodeElement = editor.getElementByKey(
        columnsListItemNode.getKey()
      )
      const nextColumnsListItemNodeElement = editor.getElementByKey(
        nextColumnsListItemNode.getKey()
      )
      if (!columnsListItemNodeElement || !nextColumnsListItemNodeElement) return

      cached.setColumnsListItemNodeKey(columnsListItemNode.getKey())

      showKnobElemBetwenRects(
        resizerKnobRef.current,
        {
          columnsListItemRect:
            columnsListItemNodeElement.getBoundingClientRect(),
          nextColumnsListItemRect:
            nextColumnsListItemNodeElement.getBoundingClientRect(),
        },
        anchorElem
      )
    }

    docBodyElem?.addEventListener('mousemove', onMouseMove)
    return () => {
      docBodyElem?.removeEventListener('mousemove', onMouseMove)
    }
  }, [editor, anchorElem])

  function onDragStart(event: React.DragEvent<HTMLElement>) {
    event.dataTransfer.setDragImage(new Image(), 0, 0)
    cached.setPageXOnStart(event.pageX)

    const resizingColumnsListItemNodes = getResizingColumnsListItemNodesByKey(
      editor,
      cached.getColumnsListItemNodeKey()
    )
    if (!resizingColumnsListItemNodes) return
    const { columnsListItemNode, nextColumnsListItemNode } =
      resizingColumnsListItemNodes

    editor.update(() => {
      const columnsListItemNodeElement = editor.getElementByKey(
        columnsListItemNode.getKey()
      )
      const nextColumnsListItemNodeElement = editor.getElementByKey(
        nextColumnsListItemNode.getKey()
      )
      if (!columnsListItemNodeElement || !nextColumnsListItemNodeElement) return

      cached.setColumnsListItemElemPropsOnStart(
        columnsListItemNodeElement.getBoundingClientRect()
      )
      cached.setNextColumnsListItemElemPropsOnStart(
        nextColumnsListItemNodeElement.getBoundingClientRect()
      )
    })
  }

  function onDrag(event: React.DragEvent<HTMLElement>) {
    cached.setPageXOnEnd(event.pageX)

    const resizingColumnsListItemNodes = getResizingColumnsListItemNodesByKey(
      editor,
      cached.getColumnsListItemNodeKey()
    )
    if (!resizingColumnsListItemNodes) return
    const { columnsListItemNode, nextColumnsListItemNode } =
      resizingColumnsListItemNodes
    editor.update(() => {
      const columnsListItemNodeElement = editor.getElementByKey(
        columnsListItemNode.getKey()
      )
      const nextColumnsListItemNodeElement = editor.getElementByKey(
        nextColumnsListItemNode.getKey()
      )
      if (!columnsListItemNodeElement || !nextColumnsListItemNodeElement) return

      const calculated = calculateColumnsListItemsWidthPrecents(
        {
          node: columnsListItemNode,
          rect: cached.getColumnsListItemElemPropsOnStart(),
        },
        {
          node: nextColumnsListItemNode,
          rect: cached.getNextColumnsListItemElemPropsOnStart(),
        },
        {
          pageXOnStart: cached.getPageXOnStart(),
          pageXOnEnd: cached.getPageXOnEnd(),
        }
      )

      columnsListItemNodeElement.style.width = `calc(${calculated.columnsListItemNewPrecentWidth}%)`
      nextColumnsListItemNodeElement.style.width = `calc(${calculated.nextColumnsListItemNewPrecentWidth}%)`

      if (resizerKnobRef.current)
        showKnobElemBetwenRects(
          resizerKnobRef.current,
          {
            columnsListItemRect:
              columnsListItemNodeElement.getBoundingClientRect(),
            nextColumnsListItemRect:
              nextColumnsListItemNodeElement.getBoundingClientRect(),
          },
          anchorElem
        )
    })
  }

  function onDragEnd(event: React.DragEvent<HTMLElement>) {
    cached.setPageXOnEnd(event.pageX)

    const resizingColumnsListItemNodes = getResizingColumnsListItemNodesByKey(
      editor,
      cached.getColumnsListItemNodeKey()
    )
    if (!resizingColumnsListItemNodes) return
    const { columnsListItemNode, nextColumnsListItemNode } =
      resizingColumnsListItemNodes

    editor.update(() => {
      const columnsListItemNodeElement = editor.getElementByKey(
        columnsListItemNode.getKey()
      )
      const nextColumnsListItemNodeElement = editor.getElementByKey(
        nextColumnsListItemNode.getKey()
      )
      if (!columnsListItemNodeElement || !nextColumnsListItemNodeElement) return

      const calculated = calculateColumnsListItemsWidthPrecents(
        {
          node: columnsListItemNode,
          rect: cached.getColumnsListItemElemPropsOnStart(),
        },
        {
          node: nextColumnsListItemNode,
          rect: cached.getNextColumnsListItemElemPropsOnStart(),
        },
        {
          pageXOnStart: cached.getPageXOnStart(),
          pageXOnEnd: cached.getPageXOnEnd(),
        }
      )

      columnsListItemNode.updateWidthPrecent(
        calculated.columnsListItemNewPrecentWidth
      )
      nextColumnsListItemNode.updateWidthPrecent(
        calculated.nextColumnsListItemNewPrecentWidth
      )
    })
  }

  return createPortal(
    <>
      <div
        className="columns-list-resize-knob"
        ref={resizerKnobRef}
        draggable={true}
        onDragStart={onDragStart}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
      />
    </>,
    anchorElem
  )
}
