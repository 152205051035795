import { Box } from '@mui/material'
import { useContext } from 'react'
import { WorfklowBuilderUIContext } from '../../../providers/WorkflowBuilderUIContext'
import { GhostButtonSmall } from './GhostButtonSmall'
import img_plus from 'assets/img/add.svg'

export const BlockButtons = () => {
  const workflowBuilderUI = useContext(WorfklowBuilderUIContext)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        gap: '4px',
      }}
    >
      {!workflowBuilderUI.copy.open && (
        <GhostButtonSmall
          prefixImgSrc={img_plus}
          onClick={() => workflowBuilderUI.copy.setOpen(true)}
          tooltip="Получатели смогут просматривать документ и его историю"
          text={'Копия'}
        />
      )}
      {!workflowBuilderUI.approvers.open && (
        <GhostButtonSmall
          prefixImgSrc={img_plus}
          onClick={() => workflowBuilderUI.approvers.setOpen(true)}
          tooltip="Получатели первыми получат документ и смогут согласовать перед отправкой на подпись."
          text="Согласование"
        />
      )}
    </Box>
  )
}
