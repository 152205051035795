import { Box, Collapse, IconButton, Typography } from '@mui/material'
import { MenuItemGray } from '../helper-menu'
import { useContext, useEffect, useState } from 'react'
import { DASHBOARD_MODES } from '..'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { MenuItemGrayNested } from '../menuNavigation'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { isMobile } from 'react-device-detect'

import img_api from 'assets/img/api.svg'
import img_settings from 'assets/img/settings.svg'
import img_people from 'assets/img/people.svg'
import img_tarif from 'assets/img/tarif.svg'
import img_billing from 'assets/img/billing.svg'
import img_avatar from 'assets/img/account_circle.svg'
import img_plus_icon from 'assets/img/add.svg'
import img_back_nav from 'assets/img/back-nav-grey.svg'
import img_blue_teamspace from 'assets/img/group_icon/blue.svg'
import img_menu from 'assets/img/menu.svg'

import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { WSRolePermissions } from 'helper/roles'
import { CustomTooltip, MessageSnackbar } from 'components'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { clearNotificationsTs } from 'store/slices/teamspaceSlice'
import { coloredFilters, NewGroupForm } from './groupForm'
import { InviteToGroupForm } from './inviteToForms'
import { getWSPlanFeatureRestriction } from 'utils/plans'
import { EFeature } from 'helper/plan.types'
import { AppBarContext } from 'context'
import { ModalPlanLimit } from 'components/modal/ModalPlanLimit'

export const SettingsMenu = () => {
  const { setMode } = useContext(AppBarContext)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [showIconButton, setShowIconButton] = useState(false)

  const { user } = useSelector((state: RootState) => state.user, shallowEqual)
  const { teamspaces } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )
  const { setOpenDrawer, openDrawer } = useContext(AppBarContext)

  const handleClick = () => {
    setMode(DASHBOARD_MODES.NORMAL)
    navigate('/')
  }

  const menuItems = [
    {
      src: img_settings,
      text: 'Основное',
      value: 'settings',
      link: `/settings`,
      key: 0,
      allowed: true,
    },
    {
      src: img_people,
      text: 'Участники',
      value: 'members',
      link: `/members`,
      key: 1,
      allowed:
        selectedWorkspace &&
        selectedWorkspace?.group_name &&
        WSRolePermissions[selectedWorkspace?.group_name].manageMembers,
    },
    {
      src: img_tarif,
      text: 'Тарифы',
      value: 'plan',
      link: `/plan`,
      key: 2,
      allowed:
        selectedWorkspace &&
        selectedWorkspace?.group_name &&
        WSRolePermissions[selectedWorkspace?.group_name].managePlan,
    },
    {
      src: img_billing,
      text: 'Оплата и лимиты',
      value: 'payment',
      link: `/payment`,
      key: 2,
      allowed:
        selectedWorkspace &&
        selectedWorkspace?.group_name &&
        WSRolePermissions[selectedWorkspace?.group_name].managePlan,
    },
    {
      src: img_api,
      text: 'API',
      value: 'api',
      link: '/api',
      key: 2,
      allowed: true,
      // selectedWorkspace &&
      // selectedWorkspace?.group_name &&
      // WSRolePermissions[selectedWorkspace?.group_name].managePlan,
    },
  ]

  return (
    <>
      <Box
        id="sidebar_head"
        p="8px 0"
        onMouseOver={() => setShowIconButton(true)}
        onMouseLeave={() => setShowIconButton(false)}
      >
        <Box
          p="4px 8px 4px 16px"
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} alignItems={'center'}>
            <IconButton
              sx={{
                mr: '8px',
                borderRadius: '4px',
                padding: '4px 2px !important',
              }}
              onClick={handleClick}
            >
              <Box
                component={'img'}
                width={'16px'}
                height={'16px'}
                src={img_back_nav}
              />
            </IconButton>
            <Typography variant="body1">Настройки</Typography>
          </Box>
          {/* TODO: fix icon size */}
          {showIconButton && (
            <CustomTooltip title="Скрыть меню" placement="right-start">
              <IconButton
                sx={{ borderRadius: '4px', padding: '4px' }}
                onClick={(e) => {
                  e.stopPropagation()
                  setOpenDrawer(!openDrawer)
                }}
                children={<img src={img_menu} width="16" height="16" alt="" />}
              />
            </CustomTooltip>
          )}
        </Box>
      </Box>
      <Box p="8px 0">
        <Box p="4px 16px">
          <Typography variant="caption" color={'text.disabled'}>
            {user.email}
          </Typography>
        </Box>
        <MenuItemGrayNested
          key={'profile-0'}
          id="positioned-button"
          component={Link}
          to={'/profile'}
          onClick={() => {
            isMobile && setOpenDrawer(false)
          }}
          padding={'6px 8px 6px 16px'}
          selected={pathname === '/profile'}
        >
          <img
            src={img_avatar}
            alt=""
            width={'16px'}
            height={'16px'}
            style={{
              marginRight: '8px',
            }}
          />
          Аккаунт
        </MenuItemGrayNested>
      </Box>
      <Box p="8px 0">
        <Box p="4px 16px">
          <Typography variant="caption" color={'text.disabled'}>
            {selectedWorkspace?.name}
          </Typography>
        </Box>
        {menuItems
          .filter((item) => item.allowed)
          .map((item, key) => {
            return (
              <MenuItemGrayNested
                onClick={() => {
                  isMobile && setOpenDrawer(false)
                }}
                padding={'6px 8px 6px 16px'}
                id="positioned-button"
                key={`positioned-button-${key}`}
                selected={pathname === item.link}
                component={Link}
                to={item.link}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  whiteSpace={'nowrap'}
                >
                  <img
                    src={item.src}
                    alt=""
                    width={'16px'}
                    height={'16px'}
                    style={{
                      marginRight: '8px',
                    }}
                  />
                  {item.text}
                </Box>
              </MenuItemGrayNested>
            )
          })}
      </Box>
      <Box p="8px 0">
        <Box p="4px 16px">
          <Typography variant="caption" color={'text.disabled'}>
            Группы
          </Typography>
        </Box>
        <Box p="2px 0">
          {teamspaces &&
            teamspaces.map(
              (teamspace, index) =>
                !teamspace.is_personal && (
                  <CollapsableMenuSettings
                    teamspace={teamspace}
                    key={`teamspace-${index}`}
                  />
                )
            )}
        </Box>

        {selectedWorkspace &&
          WSRolePermissions[selectedWorkspace.group_name]?.createTeamspace && (
            <CreateGroupMenuItem />
          )}
      </Box>
    </>
  )
}

export const CreateGroupMenuItem = () => {
  const [open, setOpen] = useState(false)
  const [prohibited, setProhibited] = useState(false)
  const [exhausted, setExhausted] = useState(false)

  const [step, setStep] = useState(0)

  const { plan_info } = useSelector((state: RootState) => state.wsPlan)

  useEffect(() => {
    const { prohibited, exhausted } = getWSPlanFeatureRestriction(
      EFeature.teamspaces_count,
      plan_info
    )
    setProhibited(prohibited)
    setExhausted(exhausted)
  }, [plan_info])

  const handleClick = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setStep(0)
  }

  const [name, setName] = useState('')
  const { notification } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )
  const dispatch = useDispatch()

  return (
    <>
      {prohibited || exhausted ? (
        <ModalPlanLimit
          open={open}
          handleClose={handleClose}
          limitOn={EFeature.teamspaces_count}
        />
      ) : (
        <ModalCenterSelectableWithTitle
          open={open}
          modalType="small"
          handleClose={handleClose}
          title={
            step === 0 ? 'Новая группа' : `Пригласите участников в «${name}» `
          }
          contentChild={
            <>
              {step === 0 ? (
                <NewGroupForm setName={setName} setStep={setStep} />
              ) : (
                <InviteToGroupForm handleClose={handleClose} />
              )}
              <MessageSnackbar
                message={notification.create?.message}
                clearMessage={() => dispatch(clearNotificationsTs() as any)}
                severity={notification.create?.severity}
              />
            </>
          }
        />
      )}
      <MenuItemGray id="positioned-button" onClick={handleClick}>
        <img
          src={img_plus_icon}
          alt=""
          width={'16px'}
          height={'16px'}
          style={{
            marginRight: '8px',
          }}
        />
        Новая группа
      </MenuItemGray>
    </>
  )
}

export const CollapsableMenuSettings = ({ teamspace }) => {
  const [collapsed, setCollapsed] = useState(false)

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed)
  }

  return (
    <Box p="2px 0">
      <Box
        p="6px 16px"
        display={'flex'}
        alignItems="center"
        onClick={handleToggleCollapse}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            background: '#EDEDEC',
            color: 'text.primary',
          },
        }}
      >
        <Box
          component={'img'}
          src={img_blue_teamspace}
          mr={1}
          sx={{
            filter:
              teamspace.attrs && teamspace.attrs.color
                ? coloredFilters[teamspace.attrs.color.hex]
                : '',
          }}
        />
        <Typography variant="body1">{teamspace.name}</Typography>
        {collapsed ? (
          <ArrowRightIcon
            color="secondary"
            sx={{ width: '16px', height: '16px' }}
          />
        ) : (
          <ArrowDropDownIcon
            color="secondary"
            sx={{ width: '16px', height: '16px' }}
          />
        )}
      </Box>
      <Collapse in={!collapsed}>
        <MenuNavigationSettings isNested={true} teamspace={teamspace} />
      </Collapse>
    </Box>
  )
}

export const MenuNavigationSettings = ({ isNested = false, teamspace }) => {
  const { pathname } = useLocation()
  const { setOpenDrawer } = useContext(AppBarContext)
  const { id, name } = teamspace

  const handleClick = () => {
    isMobile && setOpenDrawer(false)
  }

  return (
    <>
      {[
        {
          src: img_settings,
          text: 'Основное',
          value: 'settings',
          link: `/teamspace/${id}/settings`,
          key: 0,
        },
        {
          src: img_people,
          text: 'Участники',
          value: 'members',
          link: `/teamspace/${id}/members`,
          key: 1,
        },
      ].map((item) => [
        <MenuItemGrayNested
          component={Link}
          to={`${item.link}?name=${name}`}
          padding={isNested ? '6px 8px 6px 40px' : '6px 8px 6px 16px'}
          key={`sidebar-menu-${item.key}`}
          selected={pathname === item.link}
          onClick={() => handleClick()}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box display={'flex'} alignItems={'center'}>
            <img
              src={item.src}
              alt=""
              width={'16px'}
              height={'16px'}
              style={{
                marginRight: '8px',
              }}
            />
            {item.text}
          </Box>
        </MenuItemGrayNested>,
      ])}
    </>
  )
}
