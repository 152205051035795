import { api } from 'config'
import { LocalStorageReserve } from 'helper/consts'
import { handleError } from 'service'
import { ICard, IPaymentNotification, IRequisites } from 'store/slices/wsPlan'

export const workspaceApi = {
  getWorkspacePlan: async ({ wsId }: { wsId: string }) => {
    try {
      const resp = await api.get<{
        workspace_limits: {
          documents_count: number,
          documents_size: number,
          templates_count: number,
          campaigns_count: number,
          teamspaces_count: number,
          workspace_users_count: number,
          current_subscription_end_at: string,
          current_subscription_ends_in_secs: number,
          start_at: string,
          end_at: string
        },
        plan_limits: {
          name: string,
          documents_count: number,
          documents_size: number,
          templates_count: number,
          campaigns_count: number,
          workflow_email_send_access: boolean,
          teamspaces_count: number,
          workspace_users_count: number,
          document_ai_access: boolean,
          face_sign_access: boolean
        },
        is_trial_available: boolean,
        previous_plan: {
          name: string,
          start_at: string,
          end_at: string
        },
        require_closing_documents: boolean,
        requisites?: IRequisites,
        card?: ICard,
        payment_notification: IPaymentNotification
      }>(`/workspaces/${wsId}/plan`, {
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return resp
    } catch (error) {
      return handleError(error)
    }
  },

  startTrialPlan: async ({ wsId }: { wsId: string }) => {
    try {
      const resp = await api.post(
        `/workspaces/${wsId}/plan/trial`,
        {},
        {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${localStorage.getItem(
              LocalStorageReserve.token
            )}`,
          },
        }
      )
      return resp
    } catch (error) {
      return handleError(error)
    }
  },

  postWorkspace: async ({ data }: { data: FormData }) => {
    try {
      const response = await api.post(`/workspaces`, data, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  updateWorkspaceLogo: async ({
    data,
    wsId,
  }: {
    data: FormData
    wsId: string
  }) => {
    try {
      const response = await api.post(`/workspaces/${wsId}/logo`, data, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  getWorkspaces: async () => {
    try {
      const response = await api.get(`/workspaces`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  getWorkspaceInfo: async ({ id }: { id: string }) => {
    try {
      const response = await api.get(`/workspaces/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  updateWorkspaceInfo: async ({
    id,
    workspace,
  }: {
    id: string
    workspace: any
  }) => {
    try {
      const response = await api.patch(
        `/workspaces/${id}`,
        { workspace },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem(
              LocalStorageReserve.token
            )}`,
          },
        }
      )
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  deleteWorkspace: async ({ id }: { id: string }) => {
    try {
      const response = await api.delete(`/workspaces/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  changeWorkspaceLogo: async ({ id, logo }: { id: string; logo: any }) => {
    try {
      const response = await api.post(
        `/workspaces/${id}/logo`,
        { logo },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem(
              LocalStorageReserve.token
            )}`,
          },
        }
      )
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  getWorkspaceLogo: async ({ id }: { id: string }) => {
    try {
      const response = await api.get(`/workspaces/${id}/logo`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  createWorkspaceTeamspace: async ({
    id,
    teamspace,
  }: {
    id: string
    teamspace: any
  }) => {
    try {
      const response = await api.post(
        `/workspaces/${id}/teamspaces`,
        { teamspace },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem(
              LocalStorageReserve.token
            )}`,
          },
        }
      )
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  createInvite: async (data: {
    invite: {
      email: string
      group_id: string
    }
  }) => {
    try {
      const response = await api.post(`/invites`, data, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  fetchWorkspaceGroups: async ({ id }: { id: string }) => {
    try {
      const response = await api.get(`/workspaces/${id}/groups`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  fetchWorkspaceUserGroups: async ({ id }: { id: string }) => {
    try {
      const response = await api.get(`/workspaces/${id}/users/groups`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  getWorkspaceMembers: async ({ id }: { id: string }) => {
    try {
      const response = await api.get(`/workspaces/${id}/members`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  getWorkspaceGroups: async ({ id }: { id: string }) => {
    try {
      const response = await api.get(`/workspaces/${id}/groups`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  getWorkspaceInvites: async ({ id }: { id: string }) => {
    try {
      const response = await api.get(`/workspaces/${id}/invites`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  getWorkspaceUserRoles: async ({ id }: { id: string }) => {
    try {
      const response = await api.get(`/workspaces/${id}/users/groups`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  getInviteInfo: async ({ id }: { id: string }) => {
    try {
      const response = await api.get(`/invites/${id}/public`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  activateInvite: async ({ id }: { id: string }) => {
    try {
      const response = await api.post(
        `/invites/${id}/activate`,
        {},
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem(
              LocalStorageReserve.token
            )}`,
          },
        }
      )
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  fetchUserTeamspaces: async ({
    wsId,
    userId,
  }: {
    wsId: string
    userId: string
  }) => {
    try {
      const response = await api.get(
        `/workspaces/${wsId}/users/${userId}/teamspaces`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem(
              LocalStorageReserve.token
            )}`,
          },
        }
      )
      return response
    } catch (error) {
      return handleError(error)
    }
  },

  leaveWorkspace: async ({ id }: { id: string }) => {
    try {
      const response = await api.delete(`/workspaces/${id}/users/membership`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      })
      return response
    } catch (error) {
      return handleError(error)
    }
  },
}
