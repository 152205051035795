import { LexicalCommand, LexicalNode, NodeKey, createCommand } from "lexical"

export type TColumnsListItemInsertProp = 'before' | 'after' | 'end'

/**
 * INSERT_COLUMNS_LIST_COMMAND - applies column count for creating ColumnsListNode
 */
export const INSERT_COLUMNS_LIST_COMMAND: LexicalCommand<number> =
  createCommand<number>()


interface ICreateSiblingColumnsListItemCommandProps {
  key: NodeKey
  insert: TColumnsListItemInsertProp
}

/**
 * CREATE_SIBLING_COLUMNS_LIST_ITEM_COMMAND - add new `ColumnsListItemNode` near current `ColumnsListItemNode`. Every new column size is equal to (100 / (`ColumnsListNode` childrens count + 1)) precent
 */
export const CREATE_SIBLING_COLUMNS_LIST_ITEM_COMMAND: LexicalCommand<ICreateSiblingColumnsListItemCommandProps> =
  createCommand<ICreateSiblingColumnsListItemCommandProps>()

interface ICreateSiblingColumnsListItemWithChildNodeCommandProps {
  key: NodeKey
  insert: TColumnsListItemInsertProp
  childNode: LexicalNode
}

/**
 * CREATE_SIBLING_COLUMNS_LIST_ITEM_WITH_CHILD_NODE_COMMAND - works like `CREATE_SIBLING_COLUMNS_LIST_ITEM_COMMAND` but inserted child
 */
export const CREATE_SIBLING_COLUMNS_LIST_ITEM_WITH_CHILD_NODE_COMMAND: LexicalCommand<ICreateSiblingColumnsListItemWithChildNodeCommandProps> =
  createCommand<ICreateSiblingColumnsListItemWithChildNodeCommandProps>()


interface IDeleteColumnsListItemCommandProps { key: NodeKey, unwrapSingleColumnsListItem?: boolean }

/**
 * DELETE_COLUMNS_LIST_ITEM_COMMAND - deletes `ColumnsListItemNode` by NodeKey. After delete, it corrects columns width. Set `unwrapSingleColumnsListItem` `true` if you want to unwrap `ColumnsListNode` if it has only one `ColumnsListItemNode` after deleting one. 
 */
export const DELETE_COLUMNS_LIST_ITEM_COMMAND: LexicalCommand<IDeleteColumnsListItemCommandProps> =
  createCommand<IDeleteColumnsListItemCommandProps>()
