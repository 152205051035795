import img_add_circle from 'assets/img/add_circle.svg'
import img_check from 'assets/img/check.svg'
import img_logout from 'assets/img/logout.svg'
import img_menu from 'assets/img/menu.svg'
import img_settings from 'assets/img/settings_bl.svg'

import { Box, Divider, IconButton, Menu, Typography } from '@mui/material'
import { AvatarWorkspace } from 'components/avatars/AvatarWorkspace'
import { MenuItemButton } from 'components/menu-item/menu-item-button'
import { ModalCreateWorkspace } from 'components/modal/ModalCreateWorkspace'
import { CustomTooltip } from 'components/tooltip'
import { AppBarContext } from 'context'
import { WS_ROLES } from 'helper/roles'
import { useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectedWorkspaceSlice } from 'store/slices/selectedWorkspace'
import { clearTeamspaces } from 'store/slices/teamspaceSlice'
import { logoutUser } from 'store/slices/userSlice'
import { DASHBOARD_MODES } from '..'

export const SwitchWorkspace = () => {
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const { user } = useSelector((state: RootState) => state.user, shallowEqual)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [openCreateWorkspace, setOpenCreateWorkspace] = useState(false)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { setOpenDrawer, openDrawer } = useContext(AppBarContext)

  return (
    <>
      <Box
        p={'6px 8px 6px 16px'}
        display="flex"
        justifyContent={'space-between'}
        alignItems="center"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          cursor: 'pointer',
          '& .hidden': {
            opacity: isMobile ? 1 : 0,
          },
          '&:hover .hidden': {
            opacity: 1,
          },
        }}
      >
        <Box
          display={'flex'}
          width="100%"
          overflow={'hidden'}
          alignItems={'center'}
        >
          <Box mr="8px">
            <AvatarWorkspace
              src={selectedWorkspace?.picture_link}
              name={selectedWorkspace?.public_name ?? ''}
              size={16}
            />
          </Box>
          <Typography
            variant="subtitle2"
            textOverflow={'ellipsis'}
            overflow={'hidden'}
          >
            {selectedWorkspace?.name}
          </Typography>
        </Box>

        <Box position={'absolute'} right={'8px'} className={'hidden'}>
          <CustomTooltip title="Скрыть меню" placement="right-start">
            <IconButton
              sx={{ borderRadius: '4px' }}
              onClick={(e) => {
                e.stopPropagation()
                setOpenDrawer(!openDrawer)
              }}
              children={<img src={img_menu} width="16" height="16" alt="" />}
            />
          </CustomTooltip>
        </Box>
      </Box>

      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            py: 0,
          },
        }}
        PaperProps={{
          style: {
            width: '280px',
            maxHeight: 'calc(100vh - 32px)',
          },
        }}
      >
        <Box
          p="6px 16px 4px"
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            background: '#fff',
          }}
        >
          <Typography variant="caption" color={'text.secondary'}>
            {user.email}
          </Typography>
        </Box>
        <ListWorkspaces handleClose={handleClose} />
        <Box
          sx={{
            position: 'sticky',
            background: '#fff',
            bottom: 0,
            pt: '4px',
            pb: '6px',
          }}
        >
          <MenuItemButton
            onClick={() => {
              handleClose()
              setOpenCreateWorkspace(true)
            }}
            title="Новое пространство"
            srcImage={img_add_circle}
          />
          <Box p={'4px 0'}>
            <Divider />
          </Box>
          <WorkspaceMenuActions handleClose={handleClose} />
        </Box>
      </Menu>

      <ModalCreateWorkspace
        open={openCreateWorkspace}
        handleClose={() => setOpenCreateWorkspace(false)}
      />
    </>
  )
}

const wsRolesDict = {
  [WS_ROLES.owner]: 'Владелец',
  [WS_ROLES.member]: 'Участник',
}

export const ListWorkspaces = ({ handleClose }) => {
  const { workspaces } = useSelector(
    (state: RootState) => state.workspace,
    shallowEqual
  )

  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectWorkspace = (item: Workspace) => {
    dispatch(selectedWorkspaceSlice.actions.setSelectedWorkspace(item) as any)
    dispatch(clearTeamspaces() as any)
    handleClose()
    // TODO: Correct Navigate to TS
    navigate('/')
  }

  return (
    <>
      {workspaces?.map((item) => (
        <MenuItemButton
          key={item.id}
          onClick={() => selectWorkspace(item)}
          renderTitlePrefix={
            <Box mt={'2px'}>
              <AvatarWorkspace
                name={item.public_name}
                src={item.picture_link}
                size={16}
              />
            </Box>
          }
          title={item.name}
          description={wsRolesDict[item?.group_name || '']}
          renderTitleSuffix={
            selectedWorkspace &&
            selectedWorkspace.id === item.id && (
              <Box component={'img'} src={img_check} sizes={'16px'} />
            )
          }
        />
      ))}
    </>
  )
}

export const WorkspaceMenuActions = ({ handleClose }) => {
  const dispatch = useDispatch()
  const { setMode, setOpenDrawer } = useContext(AppBarContext)

  const navigate = useNavigate()

  const handleClick = ({ key }) => {
    switch (key) {
      case 0:
        handleClose()
        navigate(isMobile ? '/profile' : '/settings')
        if (isMobile) setOpenDrawer(false)
        setMode(DASHBOARD_MODES.SETTINGS)
        return
      case 2:
        dispatch(logoutUser() as any)
        return
      default:
        return
    }
  }

  return (
    <>
      {[
        {
          src: img_settings,
          text: 'Настройки',
          key: 0,
        },
        // {
        //   src: person_add,
        //   text: 'Добавить аккаунт',
        //   key: 1,
        // },
        {
          src: img_logout,
          text: 'Выйти',
          key: 2,
        },
      ].map((item, i) => (
        <MenuItemButton
          key={`${i}-actions`}
          onClick={() => handleClick({ key: item.key })}
          srcImage={item.src}
          title={item.text}
        />
      ))}
    </>
  )
}
