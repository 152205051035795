// import img_help from 'assets/img/ic_help.svg'
import img_avatar from 'assets/img/account.svg'
import img_ask from 'assets/img/ask.svg'
import img_chat from 'assets/img/auto_awesome.svg'

import { Box, Typography } from '@mui/material'
import { LoadingHorisontalDots } from 'components'
import { ButtonCopy } from 'components/button/ButtonCopy'
import { AnalyticsEvent } from 'config/analytics/segment.com/events'
import {
  EAiChatMessageAuthor,
  EAiChatMessageStatus,
  IAiChatMessage,
} from 'helper/aiChat.types'
import { EDocumentType } from 'helper/consts'
import { useMemo } from 'react'
import reactStringReplace from 'react-string-replace'
import {
  getChatAiMessageAuthorName,
  getRegexAiQuestions,
  isChatMessageAuthorAI,
  isMessageTextContainQuestions,
} from 'utils/aichat'
import { ButtonQuestion } from './buttonQuestion'

export const ChatMessage = ({
  msg,
  name,
  funcAsk,
  isAskDisabled,
  document_type = EDocumentType.EDITOR,
}: {
  msg: IAiChatMessage
  name: string
  funcAsk: any
  isAskDisabled: boolean
  document_type: EDocumentType
}) => {
  const compProps = useMemo(() => {
    return getProps({ msg, name })
  }, [msg, name])
  const isTextContainQuestions = useMemo(() => {
    return isMessageTextContainQuestions(msg.text)
  }, [msg.text])

  if (
    isChatMessageAuthorAI(msg.author) &&
    msg.status == EAiChatMessageStatus.fetching
  ) {
    return (
      <Box
        sx={{
          p: '12px 16px',
          background: '#F5F5F5',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LoadingHorisontalDots sx={{ mr: '8px' }} />
          <Typography variant="body1" color={'#90959D'}>
            {msg.text == '' ? 'Doodocs AI пишет ответ...' : msg.text}
          </Typography>
        </Box>
      </Box>
    )
  }

  const getButtonsGroup = () => {
    if (
      isChatMessageAuthorAI(msg.author) &&
      msg.status == EAiChatMessageStatus.completed &&
      !isTextContainQuestions
    ) {
      return (
        <Box sx={{ mt: '12px' }}>
          {
            <ButtonCopy
              size="small"
              copyTextValue={msg.text}
              onCopyCallback={() => {
                AnalyticsEvent.chat_doc('copy_message', 'no', document_type)
              }}
            />
          }
        </Box>
      )
    }
    return null
  }

  return (
    <Box
      sx={{
        p: '12px 16px',
        background: compProps.style.backgroundColor,
      }}
    >
      <Box sx={{ mb: '10px', display: 'flex', alignItems: 'center' }}>
        <Box
          component={'img'}
          src={compProps.srcImgAuthor}
          sx={{ width: '16px', height: '16px', mr: '4px' }}
        />
        <Typography variant="body2" color={'#6B6F76'}>
          {compProps.content.name}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="body1"
          sx={{
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            color: compProps.style.messageColor,
          }}
        >
          {isChatMessageAuthorAI(msg.author) && isTextContainQuestions
            ? getMessageWithQuestionsIfExists(msg.text, funcAsk, isAskDisabled)
            : msg.text}
        </Typography>
      </Box>
      {getButtonsGroup()}
    </Box>
  )
}

function getProps({ msg, name }: { msg: IAiChatMessage; name: string }): {
  style: {
    backgroundColor: string
    messageColor: string
  }
  content: {
    name: string
  }
  srcImgAuthor: string
} {
  if (isChatMessageAuthorAI(msg.author))
    return {
      style: {
        backgroundColor: '#F5F5F5',
        messageColor: 'inherit',
      },
      content: {
        name: getChatAiMessageAuthorName(msg.author),
      },
      srcImgAuthor: img_chat,
    }

  if (msg.author === EAiChatMessageAuthor.user)
    return {
      style: {
        backgroundColor: 'none',
        messageColor: 'inherit',
      },
      content: {
        name: name,
      },
      srcImgAuthor: img_avatar,
    }

  return {
    style: {
      backgroundColor: '#F5F5F5',
      messageColor: 'inherit',
    },
    content: {
      name: name,
    },
    srcImgAuthor: img_ask,
  }
}

function getMessageWithQuestionsIfExists(
  text: string,
  funcAsk: any,
  disabled: boolean
) {
  return reactStringReplace(text, getRegexAiQuestions(), (match, i) => (
    <ButtonQuestion
      disabled={disabled}
      key={`question-example-${match}-${i}`}
      fullWidth
      onClick={() => {
        funcAsk({ text: match })
      }}
    >
      {match}
    </ButtonQuestion>
  ))
}
