import { Typography, Box, Link } from '@mui/material'
import { AppBarSettings } from 'components/header/app-bar-common'

export const APIAppBar = () => {
  return (
    <AppBarSettings
      headerTitle={
        <>
          <Box>
            <Typography variant="heading5">API</Typography>
            <Typography mt={'4px'} color={'text.disabled'}>
              Это уникальный идентификатор, который используется для
              аутентификации запросов, связанных с этим пространством.{' '}
              <Link
                href="https://swagger.doodocs.kz/"
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
              >
                Руководство по использованию API.
              </Link>
            </Typography>
          </Box>
        </>
      }
    />
  )
}
