import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loginUser } from 'store/slices/userSlice'

export const useLogin = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = 'Login - Doodocs'
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('token')
    token && dispatch(loginUser() as any)
  }, [dispatch])
}
