import img_ts_blue from 'assets/img/group_icon/blue.svg'
import img_lock from 'assets/img/private_black.svg'

import { Box } from '@mui/material'
import { coloredFilters } from 'layout/dashboard-layout/components/groupForm'
import { MenuItemButtonWithRadio } from '../MenuItemButtonWithRadio'

export const MenuItemButtonSelectTeamspace = ({
  teamspace,
  onClick,
  disabled,
  checked,
}: {
  teamspace: ITeamspace
  onClick: any
  disabled?: boolean
  checked: boolean
}) => {
  if (!teamspace) return null

  return (
    <MenuItemButtonWithRadio
      disabled={disabled}
      onClick={onClick}
      renderTitlePrefix={
        teamspace.is_personal ? (
          <Box mt={'2px'} component={'img'} src={img_lock} width={'16px'} />
        ) : (
          <Box
            width={'16px'}
            mt={'2px'}
            component={'img'}
            src={img_ts_blue}
            sx={{
              filter: teamspace.attrs
                ? coloredFilters[teamspace.attrs.color.hex]
                : '',
            }}
          />
        )
      }
      title={teamspace.is_personal ? 'Приватное' : teamspace.name}
      checked={checked}
    />
  )
}
