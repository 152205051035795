import { Grid, Button, CircularProgress, Box } from '@mui/material'
import { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MessageSnackbar } from 'components/snackbar'
import template from 'assets/img/space_dashboard.svg'
import add from 'assets/img/add.svg'
import { EFeature } from 'helper/plan.types'
import { clearErrors } from 'store/slices/templateSlice'
import dood_circle from 'assets/img/dood_circle.svg'
import { ModalPlanLimit } from 'components/modal/ModalPlanLimit'
import { EDocumentStatus, UrlKeys } from 'helper/consts'

export const SystemTemplatesUpload = ({ handleClose, tsId }) => {
  const { loading, error, private_templates } = useSelector(
    (state: RootState) => state.templates,
    shallowEqual
  )

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [openModalLimit, setOpenModalLimit] = useState(false)
  const handleClickFile = (e) => {
    e.preventDefault()
    navigate(`/teamspace/${tsId}/templates/new`)
  }

  return (
    <>
      <MessageSnackbar
        message={error.uploadNewTemplate}
        clearMessage={() => dispatch(clearErrors())}
        severity="error"
      />
      <Grid container rowSpacing={'8px'}>
        {private_templates.map((item, index) =>
          item.status === EDocumentStatus.READY ? (
            <TemplateButton
              item={item}
              handleClose={handleClose}
              tsId={tsId}
              key={`private-template-${item.document_id}-${index}`}
              isPrivate={true}
            />
          ) : null
        )}

        <Grid item xs={12}>
          <Button
            fullWidth
            color="secondary"
            variant="outlined"
            sx={{ justifyContent: 'start', p: '6px 12px' }}
            onClick={handleClickFile}
          >
            <>
              {loading.uploadNewTemplate && <CircularProgress />}
              <img
                src={add}
                alt=""
                style={{ marginRight: '8px', width: '20px' }}
              />
              Новый шаблон
            </>
          </Button>
        </Grid>
      </Grid>
      <ModalPlanLimit
        limitOn={EFeature.template_creation_count}
        open={openModalLimit}
        handleClose={() => {
          setOpenModalLimit(false)
        }}
      />
    </>
  )
}

export const TemplateButton = (props) => {
  const { item, tsId, handleClose, isPrivate = false, disabled = false } = props
  const navigate = useNavigate()

  const handleClick = (e) => {
    e.stopPropagation()
    navigate(
      `/teamspace/${tsId}/documents/draft?${UrlKeys.templateId}=${item.id}`
    )
    handleClose()
  }

  return (
    <Grid key={item.id} item xs={12}>
      <Button
        fullWidth
        color="secondary"
        variant="outlined"
        sx={{
          justifyContent: 'space-between',
          p: '6px 12px',
        }}
        disabled={disabled}
        onClick={handleClick}
      >
        <Box display={'flex'} alignItems={'center'} textAlign={'start'}>
          <img
            src={template}
            alt=""
            style={{ marginRight: '8px', width: '20px' }}
          />
          {item.name}
        </Box>
        {!isPrivate && (
          <Box
            component={'img'}
            src={dood_circle}
            width={'20px'}
            height={'20px'}
          ></Box>
        )}
      </Button>
    </Grid>
  )
}
