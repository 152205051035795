import { useDebounce } from 'hooks/useDebounce'
import { useCallback } from 'react'
import { useInView } from 'react-cool-inview'
import { shallowEqual, useDispatch } from 'react-redux'
import { EManageTemplateDataStatus } from 'store/slices/manageTemplateData'
import { loadManageTemplateDataDocumentsValues } from 'store/slices/manageTemplateData/loadManageTemplateDataDocumentsValues'
import { useAppSelector } from 'store/store'

export const useAutoLoadTemplateDocumentsVariablesOnScroll = () => {
  const dispatch = useDispatch<any>()
  const debounce = useDebounce()

  const { status, isFullyLoaded } = useAppSelector(
    (state) => state.templateData,
    shallowEqual
  )

  const loadRows = useCallback(async () => {
    if (
      status == EManageTemplateDataStatus.initial_loading ||
      status == EManageTemplateDataStatus.infinite_loading ||
      isFullyLoaded
    )
      return

    const height = document.body.offsetHeight
    const screenHeight = window.innerHeight
    const scrolled = window.scrollY
    const trigger = height - screenHeight / 4
    const position = scrolled + screenHeight

    if (position >= trigger) {
      await dispatch(loadManageTemplateDataDocumentsValues({}))
    }
  }, [status, isFullyLoaded])

  const debouncedLoadRows = useCallback(
    () => debounce(loadRows, 250),
    [loadRows, debounce]
  )


  const { observe } = useInView({
    onEnter: () => {
      debouncedLoadRows()
    },
  })

  return {
    observerRef: observe
  }
}
