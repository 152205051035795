import { Box, Divider, Typography } from '@mui/material'
import { IconButtonSettings } from './IconButtonSettings'

export const RecipientsPartition = ({
  title,
  children,
  settings,
}: {
  title: {
    text: string
    backgroundColor?: string
  }
  children: React.ReactNode
  settings?: {
    onClick?: (() => void) | any
    tooltip?: string
  }
}) => {
  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          py: '8px',

          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '8px',

          '& .hidden-element': {
            opacity: 0,
            transition: '0.2s',
          },
          '& .hide-element': {
            opacity: 1,
            transition: '0.2s',
          },
          '&:hover': {
            '& .hidden-element': {
              opacity: 1,
            },
            '& .hide-element': {
              opacity: 0,
            },
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              backgroundColor: title.backgroundColor ?? 'inherit',
              my: '2px',
            }}
          >
            {title.text}
          </Typography>
        </Box>

        {children}

        {!!settings && (
          <Box
            sx={{
              ml: 'auto',
            }}
          >
            <IconButtonSettings
              className="hidden-element"
              onClick={settings.onClick}
              tooltip={settings.tooltip ?? 'Настройки'}
            />
          </Box>
        )}
      </Box>
      <Divider
        sx={{
          ml: '-16px',
          width: 'calc(100% + 32px)',
        }}
      />
    </Box>
  )
}
