import { AxiosError } from 'axios'
import { api } from 'config'
import { ESystemVariableType } from 'features/text-editors/lexical/plugins-custom/ParameterPickerPlugin/helpers/consts/variableTypes'
import {
  EDocumentStatus,
  ETemplateFilter,
  LocalStorageReserve,
  slugErrors,
} from 'helper/consts'
import { handleError } from 'service'
import utf8 from 'utf8'

export const patchTemplateInfo = async ({ templateId, name }) => {
  try {
    const response = await api.patch(
      `/templates/${templateId}`,
      {
        name,
      },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function getWorkflowTemplateActors({
  templateId,
}: {
  templateId: string
}) {
  try {
    const response = await api.get(`/templates/${templateId}/workflow/actors`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function getTemplateVariables({ templateId }) {
  try {
    const response = await api.get<{
      variables: IVariable[]
    }>(`/templates/${templateId}/variables`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function postTemplateVariables({ templateId, variables }) {
  try {
    const response = await api.post(
      `/templates/${templateId}/variables`,
      { variables },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function putTemplateVariables({ templateId, variables }) {
  try {
    const response = await api.put(
      `/templates/${templateId}/variables`,
      { variables },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function getTemplateVariableInfo({
  templateId,

  variable_id,
}) {
  try {
    const response = await api.get(
      `/templates/${templateId}/variables/${variable_id}`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function patchTemplateVariableInfo({
  templateId,
  variable,
  variableId,
}) {
  try {
    const response = await api.patch(
      `/templates/${templateId}/variables/${variableId}`,
      { ...variable },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function deleteTemplateVariableInfo({ templateId, variable_id }) {
  try {
    const response = await api.delete(
      `/templates/${templateId}/variables/${variable_id}`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function restoreTemplate({ templateId }: { templateId: string }) {
  try {
    const response = await api.post(
      `/templates/${templateId}/restore`,
      {},
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function deleteTemplate({ templateId }: { templateId: string }) {
  try {
    const response = await api.delete(`/templates/${templateId}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function downloadExcelFile({ id }) {
  try {
    const resp = await api.get(`/templates/${id}/example`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
      timeout: 10000,
      responseType: 'blob',
    })

    let filename
    const disposition = resp.headers['content-disposition']

    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '')
        filename = utf8.decode(filename)
      }
    }

    const blob = new Blob([resp.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    return { url, filename }
  } catch (error) {
    return handleError(error)
  }
}

export async function getTemplateHtml({ id }) {
  try {
    const resp = await api.get(`/templates/${id}/file`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export async function getTemplates({
  ts_id,
  template_name,
  status,
  filter,
}: {
  ts_id: string
  template_name?: string
  status?: EDocumentStatus | string
  filter: ETemplateFilter
}) {
  try {
    const params = new URLSearchParams()

    template_name && params.append('template_name', template_name)
    status && params.append('status', status)
    filter === ETemplateFilter.DELETED && params.append('is_deleted', 'true')

    const queryString = `teamspaces/${ts_id}/templates?${params.toString()}`

    const response = await api.get(queryString, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function addTemplateFromDoc({ formData, ts_id }) {
  try {
    const response = await api.post(
      `/teamspaces/${ts_id}/templates`,
      formData,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,

          accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function getTemplateInfo({ id }) {
  try {
    const response = await api.get<{
      template: {
        id: string
        name: string
        status: string
        is_private: boolean
        total_documents: number
        version: string
      }
    }>(`/templates/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function publishTemplate({ templateId }) {
  try {
    const response = await api.post(
      `/templates/${templateId}/publish`,
      {},
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function publishDocFromTemplate({
  id,
  eventType,
}: {
  id: string
  eventType: 'copylink' | 'workflow' | 'onlyme'
}) {
  try {
    const response = await api.post(
      `/documents/${id}/publish?event_type=${eventType}`,
      {},
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function unpublishTemplate({ templateId }) {
  try {
    const response = await api.delete(`/templates/${templateId}/publish`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const createCampaignFromExcel = async ({ formData, id }) => {
  try {
    const resp = await api.post(`/teamspaces/${id}/campaign`, formData, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,

        'Content-Type': 'multipart/form-data',
        accept: 'multipart/form-data',
      },
      responseType: 'arraybuffer',
    })
    return resp
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const decodedString = String.fromCharCode.apply(
        null,
        new Uint8Array(error.response.data) as any
      )
      const obj = JSON.parse(decodedString)
      throw new Error(slugErrors[obj.message] || obj.message)
    }
    throw new Error('Ошибка подключения к серверу')
  }
}

export async function createTemplate({ tsId, formData }) {
  try {
    const response = await api.post(
      `/teamspaces/${tsId}/templates/draft`,
      formData,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
          'Content-Type': 'multipart/form-data',
          // accept: 'multipart/form-data',
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function changeTemplateBody({ templateId, formData }) {
  try {
    const response = await api.put(`/templates/${templateId}/body`, formData, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
        'Content-Type': 'multipart/form-data',
        // accept: 'multipart/form-data',
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function queryTemplatesCreateCopy({
  templateId,
}: {
  templateId: string
}) {
  try {
    const resp = await api.post(
      `/templates/${templateId}/copy`,
      {},
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return resp
  } catch (err) {
    throw handleError(err)
  }
}

export async function queryGetTemplateManageSettings({
  templateId,
}: {
  templateId: string
}) {
  try {
    const resp = await api.get<{
      settings: {
        is_workflow_enabled: boolean
        is_sign_egov_enabled: boolean
        is_sign_egovbusiness_enabled: boolean
        is_sign_nca_enabled: boolean
      }
      workflow?: {
        actors?: [
          {
            id: number
            title: string
          }
        ]
        steps?: any[]
        meta: any
        cc?: any[]
      }
    }>(`/templates/${templateId}/settings`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return resp
  } catch (err) {
    throw handleError(err)
  }
}

export async function queryTemplateManageUpdateSettings({
  templateId,
  settings,
}: {
  templateId: string
  settings: {
    is_sign_nca_enabled?: boolean
    is_sign_egov_enabled?: boolean
    is_sign_egovbusiness_enabled?: boolean
    is_workflow_enabled?: boolean
  }
}) {
  try {
    const resp = await api.patch(
      `/templates/${templateId}/settings`,
      settings,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return resp
  } catch (err) {
    throw handleError(err)
  }
}

export async function queryTemplateManageSettingsUpdateWorkflow({
  templateId,
  workflow,
}: {
  templateId: string
  workflow: any
}) {
  try {
    const resp = await api.patch(
      `/templates/${templateId}/workflow`,
      {
        workflow,
      },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return resp
  } catch (err) {
    throw handleError(err)
  }
}

export async function queryTemplateManageGetDocumentsRecords({
  templateId,
  limit,
  offset,
}: {
  templateId: string
  limit: number
  offset: number
}) {
  try {
    const resp = await api.get<{
      records: [
        {
          document_id: string
          created_at: string
          responses: {
            variable_id: string
            value: any
          }[]
        }
      ]
    }>(`/templates/${templateId}/records?offset=${offset}&limit=${limit}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return resp
  } catch (err) {
    throw handleError(err)
  }
}

export async function getTemplateManageGetDocumentsHeader({
  templateId,
}: {
  templateId: string
}) {
  try {
    const resp = await api.get<{
      header: {
        variable_id: string
        variable_type: ESystemVariableType
        variable_name: string
      }[]
    }>(`/templates/${templateId}/header`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return resp
  } catch (err) {
    throw handleError(err)
  }
}

export const queryDownloadTemplateValuesCSV = async ({
  templateId,
  filename,
}: {
  templateId: string
  filename?: string
}) => {
  try {
    const resp = await api.get(`/templates/${templateId}/records/csv`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
      timeout: 60_000,
      responseType: 'blob',
    })
    const url = window.URL.createObjectURL(resp.data)
    return { filename: filename ?? `${templateId}.csv`, url: url }
  } catch (err) {
    throw handleError(err)
  }
}

export const templatesApi = {
  /**
   * putMoveTemplatesToTeamspace - Move templates to another teamspace
   */
  putMoveTemplatesToTeamspace: async ({
    templateIds,
    teamspaceId,
    setProgress,
  }: {
    templateIds: string[]
    teamspaceId: string
    setProgress?: (progress: number) => void
  }) => {
    try {
      const response = await api.put(
        `/teamspaces/${teamspaceId}/templates`,
        {
          template_ids: templateIds,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          onDownloadProgress: !!setProgress
            ? (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / (progressEvent?.total ?? 1)
                )
                setProgress(percentCompleted / 2)
              }
            : undefined,
          onUploadProgress: !!setProgress
            ? (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / (progressEvent?.total ?? 1)
                )
                setProgress(50 + percentCompleted / 2)
              }
            : undefined,
        }
      )
      return response
    } catch (error) {
      throw handleError(error)
    }
  },
}
