import { TextField } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateTemplateName } from 'store/slices/templateSlice'
import * as Yup from 'yup'

export const EditTemplateName = ({
  onBlurCallback,
  fullWidth,
  autoFocus,
}: {
  onBlurCallback?: () => any
  fullWidth?: boolean
  autoFocus?: boolean
}) => {
  const template = useSelector(
    (state: RootState) => state.template,
    shallowEqual
  )

  // const { tsId } = useParams()
  const nameRef = useRef<HTMLInputElement>(null)

  const dispatch = useDispatch()

  const handleSubmit = async (values) => {
    template.id &&
      dispatch(
        updateTemplateName({
          templateId: template.id,
          name: values.name,
        }) as any
      )
  }

  useEffect(() => {
    formik.setFieldValue('name', template.name)
  }, [template.name])

  const formik = useFormik({
    initialValues: {
      name: template.name,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Обязательное поле'),
    }),
    onSubmit: async () => {
      nameRef?.current?.blur()
    },
  })

  const handleTouchStart = (e: any) => {
    e.preventDefault()
    if (!template.id) {
      //TODO:  create new template
    }
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          inputRef={nameRef}
          onFocus={handleTouchStart}
          variant="standard"
          inputProps={{
            style: {
              padding: 0,
              height: 'auto',
            },
          }}
          InputProps={{
            disableUnderline: true,
            sx: {
              fontWeight: 500,
              fontSize: '24px',
              lineHeight: '32px',
            },
          }}
          onBlur={(e) => {
            e.preventDefault()
            handleSubmit(formik.values)
            if (onBlurCallback) onBlurCallback()
          }}
          onChange={formik.handleChange}
          placeholder={template.name}
          value={formik.values.name}
          fullWidth={fullWidth}
          name="name"
          autoFocus={autoFocus}
        />
      </form>
    </>
  )
}
