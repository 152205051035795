export const sessionUtils = {
  getModalPlanEndedHasShownKey: (workspaceId: string) => `/ws/${workspaceId}/modal-plan-ended-shown`,
  getModalPlanEndedHasShownValue: (workspaceId: string) => sessionStorage.getItem(sessionUtils.getModalPlanEndedHasShownKey(workspaceId)) == 'true',
  setModalPlanEndedHasShown: (workspaceId: string) => sessionStorage.setItem(sessionUtils.getModalPlanEndedHasShownKey(workspaceId), 'true'),

  getModalPlanDocumentsCountLimitExhaustedHasShownKey: (workspaceId: string) => `/ws/${workspaceId}/modal-plan-documents-count-limit-exhausted-shown`,
  getModalPlanDocumentsCountLimitExhaustedHasShownValue: (workspaceId: string) => sessionStorage.getItem(sessionUtils.getModalPlanDocumentsCountLimitExhaustedHasShownKey(workspaceId)) == 'true',
  setModalPlanDocumentsCountLimitExhaustedHasShown: (workspaceId: string) => sessionStorage.setItem(sessionUtils.getModalPlanDocumentsCountLimitExhaustedHasShownKey(workspaceId), 'true'),

  getBannerSubscriptionPlanEndingHasShownKey: (workspaceId: string) => `/ws/${workspaceId}/banner-subscription-plan-ending-shown`,
  getBannerSubscriptionPlanEndingHasShownValue: (workspaceId: string) => sessionStorage.getItem(sessionUtils.getBannerSubscriptionPlanEndingHasShownKey(workspaceId)) == 'true',
  setBannerSubscriptionPlanEndingHasShown: (workspaceId: string) => sessionStorage.setItem(sessionUtils.getBannerSubscriptionPlanEndingHasShownKey(workspaceId), 'true'),
}
