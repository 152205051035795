import { RecipientPrirority } from "./const"
import { IStep } from "../steps/IStep"
import { ERecipientAttrsFilterSubjectType, IRecipientAttrsFilter } from "./types"

export namespace RecipientHelperNS {
  export function ResetStepsIndexes(steps: IStep[]) {
    for (let i = 0; i < steps.length; i++) {
      steps[i].index = i + 1
    }
  }

  export function InsertStepWithRecipientToSteps(steps: IStep[], step: IStep): boolean {
    if (steps.length === 0) {
      step.index = 1
      steps.push(step as IStep)
      return true
    }

    for (let i = 0; i < steps.length; i++) {
      const recipient = steps[i].recipients[0]
      if (RecipientPrirority[step.recipients[0].role] < RecipientPrirority[recipient.role]) {
        step.index = i + 1
        steps.splice(i, 0, step as IStep)
        RecipientHelperNS.ResetStepsIndexes(steps)
        return true
      }
    }

    step.index = steps.length + 1
    steps.push(step as IStep)
    return true
  }

  export function RemoveRecipientFromSteps(steps: IStep[], recipient: IStep["recipients"][0]): boolean {
    const idx = steps.findIndex(step => step.recipients.includes(recipient))
    steps[idx].recipients = steps[idx].recipients.filter(_recipient => _recipient !== recipient)
    if (steps[idx].recipients.length === 0) {
      steps.splice(idx, 1)
      RecipientHelperNS.ResetStepsIndexes(steps)
      return true
    }
    return false
  }

  export function RemoveRecipientsWithActorIdFromSteps(steps: IStep[], actorId: number): boolean {
    const stepIDs: number[] = []

    steps.forEach((step, i) => {
      if (!step.recipients.find(recipient => recipient.actor_id === actorId)) return
      stepIDs.push(i)
    })

    if (stepIDs.length === 0) return false

    let spliced = 0
    for (let i = 0; i < stepIDs.length; i++) {
      const id = stepIDs[i] - spliced;
      steps[id].recipients = steps[id].recipients.filter(recipient => recipient.actor_id !== actorId)
      if (steps[id].recipients.length !== 0) continue

      steps.splice(id, 1)
      spliced++
    }
    RecipientHelperNS.ResetStepsIndexes(steps)
    return true
  }

  export function BuildIRecipientAttrsFilter(obj: IRecipientAttrsFilter | any): IRecipientAttrsFilter {
    if (obj._subjectType === undefined) {
      obj._subjectType = !!obj.bin ? ERecipientAttrsFilterSubjectType.company : ERecipientAttrsFilterSubjectType.person;
    }
    return {
      ...obj,
      _mustCheckSign: obj._mustCheckSign || !!obj.iin || !!obj.bin,
      _subjectType: obj._subjectType,

      iin: obj.iin,
      bin: obj.bin,
      role: obj.role,
    }
  }
}