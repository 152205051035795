import { api } from 'config'
import { LocalStorageReserve, ROWS_PER_PAGE } from 'helper/consts'
import { handleError } from 'service'

export async function getInboxRegistry({
  page,
  completed,
  author_id = '',
  queryString = '',
  filter = 'all',
}) {
  const limit = ROWS_PER_PAGE
  const offset = (page - 1) * limit

  try {
    const resp = await api.get(
      `documents?&limit=${limit}&offset=${offset}&q=${queryString}&completed=${completed}&author_id=${author_id}&filter=${filter}`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
          accept: 'application/json',
        },
      }
    )
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export async function getInboxRegistryCount() {
  try {
    const resp = await api.get(`documents/count`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
        accept: 'application/json',
      },
    })
    return resp
  } catch (error) {
    return handleError(error)
  }
}
