import { Box, MenuItem, Typography } from '@mui/material'
import { isMobile } from 'react-device-detect'

export interface MenuItemButtonProps {
  title: string
  renderTitlePrefix?: any
  renderTitleSuffix?: any
  description?: string
  srcImage?: string
  imgSize?: '20px' | '16px'
  onClick: (e?: any) => Promise<any> | any
  disabled?: boolean
}

const defaultImgSize = isMobile ? '20px' : '16px'

export const MenuItemButton = ({
  title,
  renderTitlePrefix,
  renderTitleSuffix,
  description,
  imgSize,
  srcImage,
  onClick,
  disabled,
}: MenuItemButtonProps) => {
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        p: '4px 12px',
      }}
      disabled={disabled}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          width: '100%',
        }}
      >
        {srcImage && (
          <Box
            component={'img'}
            src={srcImage}
            width={imgSize ?? defaultImgSize}
            height={imgSize ?? defaultImgSize}
            mt={!imgSize || imgSize == defaultImgSize ? '2px' : '0px'}
          />
        )}
        {renderTitlePrefix}

        <Box
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Typography>{title}</Typography>
            {renderTitleSuffix}
          </Box>
          {!!description && (
            <Typography variant="body2" mt={'2px'} color={'text.disabled'}>
              {description}
            </Typography>
          )}
        </Box>
      </Box>
    </MenuItem>
  )
}
