export const addOptionsField = (obj1: any, arr: any[]): any => {
  const modifiedObject = { ...obj1 }

  arr.forEach((item) => {
    const groupId = item.group_id

    for (const actorId in modifiedObject) {
      if (modifiedObject[actorId][groupId]) {
        if (!modifiedObject[actorId][groupId].options) {
          modifiedObject[actorId][groupId].options = []
        }

        modifiedObject[actorId][groupId].options.push(item)
      }
    }
  })

  return modifiedObject
}

export const arrVariablesToObject = (variables) => {
  const objVariables = variables.reduce((acc, obj) => {
    acc[obj.id] = obj
    return acc
  }, {})
  return objVariables
}

export const splitVariablesByActors = (variables, objVariables) => {
  const groupedData = {}

  variables.forEach((element) => {
    if (element['actor_id'] === 0) return
    groupedData[element['actor_id']] = {
      ...groupedData[element['actor_id']],
      [element.id]: objVariables[element.id],
    }
  })
  return groupedData
}
