import { hls } from '@diaskappassov/hungry-local-storage'
import axios from 'axios'
import { NCADoodocs } from '../utils/NCADoodocs'

hls.setAutoFlush(60 * 20)

interface IConfig {
  DOMAIN_CABINET_DOODOCS: string
  DOMAIN_SIGN_DOODOCS: string
  API_BASE: string
  GOOGLE_SECRET_KEY: string
  GOOGLE_REDIRECT_URI: string
  API_KEY_AMPLITUDE: string
  API_AICHAT_URL: string
  API_HOST_SEGMENT_ANALYTICS: string
  API_KEY_SEGMENT_ANALYTICS: string
  API_URL_CDN_SEGMENT_ANALYTICS: string
  BASE_URL_TALLY: string
}

const google_uri = (prefix: string, domain_name: string) =>
  `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${prefix}%3A%2F%2F${domain_name}%2Flogin&prompt=consent&response_type=code&client_id=634179104425-o4ide28rd3hq23nq7aj74v33qg6bsbef.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&access_type=online`

const google__test_key =
  '829890806165-llmbnb4o63h658dslqapcjrtl4gv5hq2.apps.googleusercontent.com'

const google__prod_key =
  '156713146555-357i9dn56as940kfeaj3sthh66m4ugjk.apps.googleusercontent.com'

const config_main: {
  [k in 'localhost' | 'development' | 'production']: IConfig
} = {
  localhost: {
    DOMAIN_CABINET_DOODOCS: 'http://localhost:3000',
    DOMAIN_SIGN_DOODOCS: 'https://test.doodocs.kz',

    GOOGLE_SECRET_KEY: google__test_key,
    GOOGLE_REDIRECT_URI: google_uri('http', 'localhost%3A3000'),

    API_BASE: 'https://api.test.doodocs.kz/api/v1',
    API_KEY_AMPLITUDE: '055be994b8f0a3769c960e69ac057805',
    API_AICHAT_URL: 'https://api.test.doodocs.kz/chat',

    BASE_URL_TALLY: `https://tally.so/embed/wkeL1r?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1`,

    API_HOST_SEGMENT_ANALYTICS: 'api.doodocs.kz/segment/v1',
    API_KEY_SEGMENT_ANALYTICS: '83oPoQZJ6YYoXdCIrcUwkjP4QO0Prgid',
    API_URL_CDN_SEGMENT_ANALYTICS: 'https://api.doodocs.kz/segmentcdn',
  },

  development: {
    DOMAIN_CABINET_DOODOCS: 'https://cabinet.test.doodocs.kz',
    DOMAIN_SIGN_DOODOCS: 'https://test.doodocs.kz',

    GOOGLE_SECRET_KEY: google__test_key,
    GOOGLE_REDIRECT_URI: google_uri('https', 'cabinet.test.doodocs.kz'),

    API_BASE: 'https://api.test.doodocs.kz/api/v1',
    API_KEY_AMPLITUDE: '055be994b8f0a3769c960e69ac057805',
    API_AICHAT_URL: 'https://api.test.doodocs.kz/chat',

    BASE_URL_TALLY: `https://tally.so/embed/wkeL1r?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1`,

    API_HOST_SEGMENT_ANALYTICS: 'api.doodocs.kz/segment/v1',
    API_KEY_SEGMENT_ANALYTICS: '83oPoQZJ6YYoXdCIrcUwkjP4QO0Prgid',
    API_URL_CDN_SEGMENT_ANALYTICS: 'https://api.doodocs.kz/segmentcdn',
  },

  production: {
    DOMAIN_CABINET_DOODOCS: 'https://cabinet.doodocs.kz',
    DOMAIN_SIGN_DOODOCS: 'https://sign.doodocs.kz',

    GOOGLE_SECRET_KEY: google__prod_key,
    GOOGLE_REDIRECT_URI: google_uri('https', 'cabinet.doodocs.kz'),

    API_BASE: 'https://api.doodocs.kz/api/v1',
    API_KEY_AMPLITUDE: '4bfae8a80899b2ab1fc88b7365601a69',
    API_AICHAT_URL: 'https://api.doodocs.kz/chat',

    BASE_URL_TALLY: `https://tally.so/embed/nP14QV?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1`,

    API_HOST_SEGMENT_ANALYTICS: 'api.doodocs.kz/segment/v1',
    API_KEY_SEGMENT_ANALYTICS: 'cyAiMX8cn6XtcPsTbu9govR778TNq5WY',
    API_URL_CDN_SEGMENT_ANALYTICS: 'https://api.doodocs.kz/segmentcdn',
  },
}

const mode = import.meta.env.MODE

export const config: IConfig = config_main[mode] ?? config_main['development']

console.debug(`mode:${mode}`)

export const ncaDoodocsClient = new NCADoodocs()

const api = axios.create({ baseURL: config.API_BASE })
const apiAiChat = axios.create({ baseURL: config.API_AICHAT_URL })

export { api, apiAiChat }
