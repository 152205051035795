import { Menu, MenuItem } from '@mui/material'
import { TSRolePermissions } from 'helper/roles'

import img_settings from 'assets/img/settings.svg'
import img_plus_icon from 'assets/img/add.svg'
import img_people from 'assets/img/people.svg'

const optionsList = ({ group_name }: { group_name: string }) => [
  {
    title: 'Настройки',
    value: 'settings',
    src: img_settings,
    allowed: true,
  },
  {
    title: 'Участники',
    value: 'members',
    src: img_people,
    allowed: true,
  },
  {
    title: 'Пригласить участников',
    value: 'invite',
    src: img_plus_icon,
    allowed: TSRolePermissions[group_name].canManageMemberGroups.length > 0,
  },
]

export const OptionsMenu = ({
  anchorEl,
  handleMenuClose,
  handleSelectItem,
  group_name,
}: {
  anchorEl: HTMLElement | null
  handleMenuClose: () => void
  handleSelectItem: ({ value, e }: { value: string; e: any }) => void
  group_name: string
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      slotProps={{
        paper: {
          style: {
            marginTop: '2px',
            width: '240px',
            maxHeight: '100%',
          },
        },
      }}
    >
      {optionsList({ group_name }).map((item) => {
        if (!item.allowed) return
        return (
          <MenuItem
            key={`CollapsableMenu-MenuItem-${item.title}-${item.value}`}
            sx={{ m: '2px 4px' }}
            value={item.value}
            onClick={(e) => handleSelectItem({ value: item.value, e })}
          >
            <img src={item.src} alt="" style={{ marginRight: '8px' }} />
            {item.title}
          </MenuItem>
        )
      })}
    </Menu>
  )
}
