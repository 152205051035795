import { HeadingNode } from '@lexical/rich-text'
import { $isColumnsListNode, ColumnsListNode } from "features/text-editors/lexical/custom/nodes/ColumnsListNode"
import { ColumnsListItemNode } from "features/text-editors/lexical/custom/nodes/ColumnsListNode/ColumnsListItemNode"
import { $createColumnsListNodeWithNodes } from "features/text-editors/lexical/custom/nodes/ColumnsListNode/utils"
import { CREATE_SIBLING_COLUMNS_LIST_ITEM_WITH_CHILD_NODE_COMMAND } from "features/text-editors/lexical/custom/plugins/ColumnsListPlugin/commands"
import { $copyNode, LexicalEditor, LexicalNode, ParagraphNode } from "lexical"
import { isExcludedToDrop } from "./isExcludedToDrop"

const handleDroppingToTextNodeForCreateColumnsList = (editor: LexicalEditor, targetNode: LexicalNode, insertingNode: LexicalNode, mouse: MouseEvent): boolean => {
  const targetEl = editor.getElementByKey(targetNode.getKey())
  if (!targetEl) return false

  const targetRect = targetEl.getBoundingClientRect()
  if (mouse.pageX < targetRect.left + targetRect.width - 10) return false

  let targetParentNode: LexicalNode | null | undefined = targetNode
  while (!!targetParentNode) {
    targetParentNode = targetParentNode.getParent()
    if (!targetParentNode) break
    if (!isExcludedToDrop(targetNode.getType())) break
  }
  if (!targetParentNode) return false

  const copyTargetNode = $copyNode(targetNode)
  targetNode.insertAfter(copyTargetNode)

  const container = $createColumnsListNodeWithNodes([
    targetNode,
    insertingNode,
  ])

  copyTargetNode.insertAfter(container)
  copyTargetNode.remove()

  return true
}

const droppingToParent: {
  [key: string]: (editor: LexicalEditor, parentNode: LexicalNode, insertingNode: LexicalNode, mouse: MouseEvent) => boolean
} = {
  [ColumnsListNode.getType()]: (editor, parentNode, insertingNode, mouse) => {
    if (!$isColumnsListNode(parentNode)) return false
    // TODO: Баг с перетаскиванием елеменов из ColumnsListItem

    const children = parentNode.getChildren<ColumnsListItemNode>()
    if (children.length == 0) return false

    let prevColumnsListItemNode: ColumnsListItemNode | null = null
    for (let i = 0; i < children.length; i++) {
      const columnsListItemNode = children[i];
      const columnsListItemEl = editor.getElementByKey(columnsListItemNode.getKey())
      if (!columnsListItemEl) continue

      const columnsListItemRect = columnsListItemEl.getBoundingClientRect()
      if (mouse.pageX < columnsListItemRect.left + columnsListItemRect.width / 2) {
        return editor.dispatchCommand(CREATE_SIBLING_COLUMNS_LIST_ITEM_WITH_CHILD_NODE_COMMAND, {
          key: columnsListItemNode.getKey(),
          insert: "before",
          childNode: insertingNode
        })
      }
      prevColumnsListItemNode = columnsListItemNode
    }

    if (!prevColumnsListItemNode) return false
    return editor.dispatchCommand(CREATE_SIBLING_COLUMNS_LIST_ITEM_WITH_CHILD_NODE_COMMAND, {
      key: prevColumnsListItemNode.getKey(),
      insert: "after",
      childNode: insertingNode
    })
  },
  [ParagraphNode.getType()]: handleDroppingToTextNodeForCreateColumnsList,
  [HeadingNode.getType()]: handleDroppingToTextNodeForCreateColumnsList,
}

/**
 * handleDroppingToParent - handling dropping to parent (target). Using special algorithm to drop depend on `parentNode`.
 * @param editor 
 * @param parentNode 
 * @param insertingNode 
 * @param mouse 
 * @returns `true` if handleDroppingToParent handled correct, otherwise `false`
 */
export function handleDroppingToParent(
  editor: LexicalEditor,
  parentNode: LexicalNode,
  insertingNode: LexicalNode,
  mouse: MouseEvent,
) {
  const onDropToParent = droppingToParent[parentNode.getType()]
  if (!onDropToParent) return false
  return onDropToParent(editor, parentNode, insertingNode, mouse)
}

