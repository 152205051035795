import { Button, CircularProgress, SxProps, Theme } from '@mui/material'
import { RefObject } from 'react'

export const ButtonCustom = ({
  loading,
  children,
  prefixIcon,
  disabled,
  variant,
  color,
  size,
  type,
  sx,
  fullWidth,
  onClick,
  endIcon,
  startIcon,
  ref,
}: {
  prefixIcon?: JSX.Element
  children: any
  loading?: boolean
  disabled?: boolean
  variant?: 'text' | 'outlined' | 'contained'
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
  size?: 'small' | 'medium' | 'large'
  type?: 'button' | 'reset' | 'submit'
  sx?: SxProps<Theme>
  fullWidth?: boolean
  endIcon?: JSX.Element
  startIcon?: JSX.Element
  onClick?: React.MouseEventHandler<HTMLButtonElement> | any
  ref?:
    | ((instance: HTMLButtonElement | null) => void)
    | RefObject<HTMLButtonElement>
    | null
    | undefined
}) => {
  return (
    <Button
      sx={{
        gap: '6px',
        whiteSpace: 'nowrap',
        ...sx,
      }}
      type={type}
      variant={variant}
      color={color}
      size={size}
      disabled={disabled || loading}
      fullWidth={fullWidth}
      onClick={onClick}
      endIcon={endIcon}
      startIcon={startIcon}
      ref={ref}
    >
      {!loading && prefixIcon}
      {loading && <CircularProgress color="inherit" />}
      {children}
    </Button>
  )
}
