import { Box, Button, Link, Typography } from '@mui/material'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { useContext, useTransition } from 'react'
import { SelectPlanContext } from '../../../SelectPlanProvider'
import { getTextMonths } from 'pages/plan/lib/getTextMonths'

export const ModalPaymentInvoiceDownload = ({
  open,
  handleClose,
  handleClickBack,
  handleClickDownload,
}) => {
  const { selectedPlan, selectedPeriod } = useContext(SelectPlanContext)
  const [loading, startTransition] = useTransition()
  const message = encodeURIComponent(
    `Здравствуйте! Хочу подключить тариф ${selectedPlan} на ${getTextMonths(
      selectedPeriod
    )}.`
  )
  const phoneNumber = '+77064169682'

  return (
    <ModalCenterSelectableWithTitle
      open={open}
      handleClose={handleClose}
      modalType="small"
      title={'Скачать счет на оплату'}
      contentChild={
        <>
          <Box>
            <Typography>
              1. Скачайте и отправьте счет на оплату бухгалтеру;
            </Typography>
            <Typography>
              2. Оплатите по реквизиту указанному в счете на оплату;
            </Typography>
            <Typography>
              3. После поступления денег в течении 1 раб. дня ваш тариф
              активируется.
            </Typography>

            <Typography mt={'16px'}>
              Если у вас возникнут вопросы свяжитесь с нами{' '}
              <Link
                href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`}
                target="_blank"
              >
                WhatsApp
              </Link>
              ,{' '}
              <Link href={`https://telegram.me/doodocskz`} target="_blank">
                Telegram
              </Link>
            </Typography>
          </Box>
        </>
      }
      footerChild={
        <Box
          display={'flex'}
          justifyContent={'end'}
          alignItems={'center'}
          gap={'8px'}
        >
          <Button onClick={handleClickBack} color="secondary">
            Назад
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => startTransition(handleClickDownload)}
            disabled={loading}
          >
            Скачать счет на оплату
          </Button>
        </Box>
      }
    />
  )
}
