import img_check from 'assets/img/check.svg'
import img_copy from 'assets/img/content_copy.svg'
import img_delete from 'assets/img/delete_outline.svg'

import { Box, Typography } from '@mui/material'
import { IconButtonImage } from 'components/button/IconButtonImage'
import { FormikTextField } from 'components/using-formik-context/FormikTextField'
import { useFormikContext } from 'formik'
import { useCopyToClipboard } from 'hooks/useCopyToClipboard'

export interface IFormikFieldWebhookSecret {
  secret?: string
}
export const FormikFormWebhookSecretKey = ({
  autoFocus,
  disabled,
}: {
  autoFocus?: boolean
  disabled?: boolean
}) => {
  const formik = useFormikContext<IFormikFieldWebhookSecret>()
  const { copyToClipboard, copied } = useCopyToClipboard()
  return (
    <Box>
      <Typography variant="heading6" mb={'4px'}>
        Секретный ключ
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '4px',
          alignItems: 'center',
        }}
      >
        <FormikTextField
          name={'secret'}
          placeholder={'5eCrEt-kEy...'}
          autoFocus={autoFocus}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <IconButtonImage
                icon={{ src: copied ? img_check : img_copy, size: '16px' }}
                onClick={() => copyToClipboard(formik.values?.secret ?? '')}
                size="small"
              />
            ),
            sx: {
              paddingRight: '4px',
            },
          }}
        />
        <IconButtonImage
          icon={{ src: img_delete, size: '16px' }}
          onClick={() => {
            formik.setFieldValue('secret', undefined)
          }}
          size="small"
        />
      </Box>
    </Box>
  )
}
