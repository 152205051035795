import { Box } from '@mui/material'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Loading } from 'components'
import {
  fetchGroupsWs,
  fetchWorkspaceInvites,
  fetchWorkspaceMembers,
} from 'store/slices/workspace'
import { MembersTable } from './members/membersTable'
import { withDashboardLayout } from 'layout'
import { WSMembersHeader } from '../components/header'

const WSMembersContent = () => {
  const { loading } = useSelector(
    (state: RootState) => state.workspace,
    shallowEqual
  )
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchWorkspaceMembers({ id: selectedWorkspace?.id || '' }) as any)
    dispatch(fetchGroupsWs({ id: selectedWorkspace?.id || '' }) as any)
    dispatch(fetchWorkspaceInvites({ id: selectedWorkspace?.id || '' }) as any)
  }, [selectedWorkspace?.id])

  return (
    <>
      <Box p={{ xs: '0 16px', sm: '0 40px 16px' }} overflow={'auto'}>
        <Box>
          <Box mt="24px">
            {loading.members || loading.groups || loading.invites ? (
              <Loading />
            ) : (
              <MembersTable />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export const WSMembers = () =>
  withDashboardLayout(WSMembersContent)({ Header: WSMembersHeader })
