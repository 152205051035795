import img_trash from 'assets/img/ic_delete.svg'
import img_account from 'assets/img/account.svg'

import { Box, Menu } from '@mui/material'
import { RecipientsPartition } from '../RecipientsPartition'
import { WorfklowBuilderUIContext } from '../../../../providers/WorkflowBuilderUIContext'
import React, { useContext, useMemo, useState } from 'react'
import { RecipientBadge } from '../RecipientBadge'
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core'
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable'
import { ICC } from 'helper/workflow/IWorkflow'
import { getObjectClone } from 'utils/getObjectClone'
import { MenuItemButton } from 'components/menu-item/menu-item-button'
import { EmailFieldWithMenu } from 'components/text-fields/EmailFieldWithMenu'
import { shallowEqual, useSelector } from 'react-redux'
import { IconButtonCirclePlus } from '../IconButtonCirclePlus'
import { isValidEmail } from 'utils/isValidEmail'

export const RecipientsCopy = () => {
  const workflowBuilderUI = useContext(WorfklowBuilderUIContext)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const selection = useMemo(() => {
    return {
      email: '',
    }
  }, [])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const deleteEmail = (email: string) => {
    const state = workflowBuilderUI.workflow
    const removed = state.removeCC({ email: email })
    if (removed) workflowBuilderUI.setWorkflow(getObjectClone(state))
    return removed
  }

  const getEmails = () => workflowBuilderUI.workflow.cc ?? []

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (!over || active.id === over.id) return
    const items = getEmails()
    const activeIndex = items.findIndex((item) => item.email == active.id)
    const overIndex = items.findIndex((item) => item.email == over.id)
    const reorderedItems = arrayMove<ICC>(items, activeIndex, overIndex)

    const state = getObjectClone(workflowBuilderUI.workflow)
    state.cc = reorderedItems
    workflowBuilderUI.setWorkflow(state)
  }

  if (!workflowBuilderUI.copy.open) return null
  return (
    <>
      <RecipientsPartition title={{ text: 'Копия:' }}>
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={getEmails().map((el) => el.email)}
            strategy={rectSortingStrategy}
          >
            {getEmails().map((el, idx) => {
              return (
                <RecipientBadge
                  // sortable={{
                  //   id: el.email,
                  //   order: idx + 1,
                  // }}
                  key={idx}
                  text={el.email}
                  imgSrc={img_account}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    setAnchorEl(event.currentTarget)
                    selection.email = el.email
                  }}
                />
              )
            })}
          </SortableContext>
        </DndContext>

        <BlockFieldOrButton
          isInputShownByDefault={workflowBuilderUI.copy.isInputShownByDefault}
        />
      </RecipientsPartition>

      <Menu
        sx={{ mt: '2px' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItemButton
          title="Удалить"
          srcImage={img_trash}
          onClick={() => {
            deleteEmail(selection.email)
            handleClose()
          }}
        />
      </Menu>
    </>
  )
}

const BlockFieldOrButton = ({
  isInputShownByDefault,
}: {
  isInputShownByDefault?: boolean
}) => {
  const [visible, setVisible] = useState(isInputShownByDefault ?? false)
  const [inputValue, setInputValue] = useState('')

  return visible ? (
    <Box>
      <EmailFiledWithMenuForCopy
        setVisible={setVisible}
        inputValue={inputValue}
        setInputValue={setInputValue}
      />
    </Box>
  ) : (
    <IconButtonCirclePlus
      className="hidden-element"
      onClick={() => setVisible(true)}
    />
  )
}

const EmailFiledWithMenuForCopy = ({
  setVisible,
  setInputValue,
  inputValue,
}: {
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>
  setInputValue: React.Dispatch<React.SetStateAction<string>>
  inputValue: string
}) => {
  const workflowBuilderUI = useContext(WorfklowBuilderUIContext)
  const { members } = useSelector(
    (state: RootState) => state.workspace,
    shallowEqual
  )

  const optionEmails = useMemo(() => {
    const mEmails = members.map((member) => member.email)
    const state = workflowBuilderUI.workflow

    return mEmails.filter((addr) => {
      return !state.cc?.find((t) => t.email == addr)
    })
  }, [workflowBuilderUI.workflow, members])

  const addEmailToList = (email) => {
    if (!email) return false
    if (!isValidEmail(email)) return false
    const state = workflowBuilderUI.workflow
    const inserted = state.insertCC({ email: email })
    if (inserted) workflowBuilderUI.setWorkflow(getObjectClone(state))

    return inserted
  }

  return (
    <EmailFieldWithMenu
      input={{
        inputValue,
        setInputValue,
      }}
      placeholder="Введите email"
      addEmailToList={addEmailToList}
      options={optionEmails}
      onBlurCallback={() => setVisible && setVisible(false)}
    />
  )
}
