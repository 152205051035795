import { Typography, Box, IconButton } from '@mui/material'
import { EDocumentStatus, ROWS_PER_PAGE } from 'helper/consts'
import left from 'assets/img/left.svg'
import right from 'assets/img/right.svg'
import { useSearchBarContext } from 'context'
import { useSearchParams } from 'react-router-dom'
import { EListQueryParam } from 'helper/queryParams'
import { IChangePageArgs } from './types'

export const Pagination = ({
  changePage,
  page,
  count,
  filter,
  completed = false,
  shouldChangePage = true,
}: {
  changePage: (args: IChangePageArgs) => void
  page: number
  count: number
  filter: EDocumentStatus | string
  completed?: boolean
  shouldChangePage?: boolean
}) => {
  const { queryString, authorQuery } = useSearchBarContext()
  const [searchParams, setSearchParams] = useSearchParams()

  const handleChangePage = (page) => {
    searchParams.set(EListQueryParam.Page, page)
    setSearchParams(searchParams)

    shouldChangePage &&
      changePage({
        page,
        params: {
          filter,
          completed,
          query: queryString,
          author_id: authorQuery,
        },
      })
  }

  return (
    <Box
      component={'footer'}
      id="footer"
      sx={{ background: 'white' }}
      display={'flex'}
      p="10px 20px"
      alignItems={'center'}
      borderTop={'1px solid #EDEDEC'}
      width={'100%'}
      height={'40px'}
    >
      <Box>
        <Box mr="8px">
          <Typography variant="body1">
            {count !== 0 ? page : 0} из {Math.ceil(count / ROWS_PER_PAGE)}
          </Typography>
        </Box>
      </Box>
      <Box mr="8px">
        <IconButton
          sx={{ borderRadius: '4px' }}
          children={<img src={left} alt="" />}
          color="secondary"
          disabled={page <= 1}
          onClick={() => handleChangePage(page - 1)}
        />
      </Box>
      <Box>
        <IconButton
          sx={{ borderRadius: '4px' }}
          children={<img src={right} alt="" />}
          color="secondary"
          disabled={page >= Math.ceil(count / ROWS_PER_PAGE)}
          onClick={() => handleChangePage(page + 1)}
        />
      </Box>
    </Box>
  )
}
