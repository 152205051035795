import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { MessageSnackbar } from 'components'
import { FormikTextField } from 'components/using-formik-context/FormikTextField'
import { AnalyticsEvent } from 'config/analytics/segment.com/events'
import { FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'
import { workspaceApi } from 'service/workspaces'
import { selectedWorkspaceSlice } from 'store/slices/selectedWorkspace'
import { updateWorkspaces } from 'store/slices/workspace'
import { getWorkspaceAvatar } from 'utils/workspace/workspaceAvatar'
import * as Yup from 'yup'
import { ModalCenterSmall } from '..'

const useCreateWorkspace = ({
  callbackOnSuccess,
}: {
  callbackOnSuccess: () => void
}) => {
  const dispatch = useDispatch<any>()
  const [error, setError] = useState('')

  const createWorkspace = async ({ name }: { name: string }) => {
    const formData = new FormData()
    formData.append(
      'workspace',
      JSON.stringify({
        name: name,
        public_name: name,
      })
    )
    const avatar = getWorkspaceAvatar(name)
    formData.set('logo', avatar, 'avatar')

    await workspaceApi.postWorkspace({
      data: formData,
    })
    AnalyticsEvent.setup('company', 'no', 0)
  }

  const loadAndSwitchToLastWorkspace = async () => {
    const response = await workspaceApi.getWorkspaces()
    const { data } = response
    dispatch(updateWorkspaces(data.workspaces) as any)
    dispatch(
      selectedWorkspaceSlice.actions.setSelectedWorkspace(
        data.workspaces[data.workspaces.length - 1]
      )
    )
  }

  const formik = useFormik({
    initialValues: {
      workspace_name: '',
    },
    validationSchema: Yup.object().shape({
      workspace_name: Yup.string().required('Обязательное поле'),
    }),
    onSubmit: async (values) => {
      try {
        const { workspace_name } = values
        await createWorkspace({ name: workspace_name })
        await loadAndSwitchToLastWorkspace()
        callbackOnSuccess()
      } catch (err: any) {
        console.error(err)
        setError(err.message)
      }
    },
  })

  return {
    formik,
    error,
    setError,
  }
}

export const ModalCreateWorkspace = ({
  open,
  handleClose,
}: {
  open: boolean
  handleClose: () => void
}) => {
  const { formik, error, setError } = useCreateWorkspace({
    callbackOnSuccess: () => {
      handleClose()
    },
  })

  return (
    <>
      <ModalCenterSmall
        open={open}
        handleClose={handleClose}
        header={{
          children: (
            <Typography variant={'heading5'}>Новое пространство</Typography>
          ),
        }}
        content={{
          children: (
            <Box>
              <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                  <Box>
                    <Typography variant="paragraph3">
                      Пространства — место в которых команды могут работать
                      вместе
                    </Typography>
                  </Box>
                  <Box mt={'16px'}>
                    <FormikTextField
                      name="workspace_name"
                      label="Название компании"
                      placeholder="Название компании"
                    />
                  </Box>
                </form>
              </FormikProvider>
            </Box>
          ),
        }}
        footer={{
          children: (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <Button
                  fullWidth={isMobile}
                  variant="contained"
                  color="primary"
                  disabled={formik.isSubmitting}
                  type="submit"
                >
                  {formik.isSubmitting && (
                    <CircularProgress
                      sx={{ mr: '4px', color: 'text.disabled' }}
                    />
                  )}
                  <Typography>Создать</Typography>
                </Button>
              </form>
            </Box>
          ),
        }}
      />

      {createPortal(
        <MessageSnackbar
          severity={'error'}
          message={error}
          clearMessage={() => setError('')}
        />,
        document.body
      )}
    </>
  )
}
