import { Box, Container } from '@mui/material'
import { Loading, MessageSnackbar } from 'components'
import { useSnackbar } from 'components/snackbar/hook'
import { useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { InboxRegistryTable } from './table/inbox'
import {
  SearchBarContextProvider,
  SelectedDocsIdsProvider,
  useChangePageContext,
  useSearchBarContext,
  useSelectedDocsContext,
} from 'context'
import { withDashboardLayout } from 'layout'
import { InboxHeader } from './header'
import { InboxFooter } from './footer'
import { InboxChangePageProvider } from 'context'

const InboxContent = () => {
  const { snackbar } = useSnackbar()
  const { setSelectedDocsIds, setHiddenDocumentIds } = useSelectedDocsContext()
  const { queryString, authorQuery } = useSearchBarContext()
  const { changePage } = useChangePageContext()
  const { loading, page, filter, completed } = useSelector(
    (state: RootState) => state.inbox,
    shallowEqual
  )

  useEffect(() => {
    changePage({
      page,
      params: {
        filter,
        completed,
        query: queryString,
        author_id: authorQuery,
      },
    })
  }, [queryString, authorQuery])

  useEffect(() => {
    setSelectedDocsIds(new Map())
    setHiddenDocumentIds([])
  }, [filter])

  return (
    <Container
      maxWidth={false}
      sx={{
        overflow: 'auto',
        pr: {
          xs: '20px',
        },
        pl: { xs: '20px' },
        position: 'relative',
      }}
    >
      <MessageSnackbar
        message={snackbar.message}
        clearMessage={snackbar.close}
        severity={snackbar.severity}
        buttons={snackbar.buttons}
      />
      <Box>{loading ? <Loading /> : <InboxRegistryTable />}</Box>
    </Container>
  )
}

export const Inbox = () =>
  withDashboardLayout(InboxContent)({
    Header: InboxHeader,
    Footer: InboxFooter,
    providers: [
      SelectedDocsIdsProvider,
      SearchBarContextProvider,
      InboxChangePageProvider,
    ],
  })
