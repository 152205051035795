import { TableCheckbox } from 'components'
import { useSelectedDocsContext } from 'context'
import { useState, useCallback, useEffect } from 'react'

export const useCheckbox = ({ page, rows }: { page: number; rows: any[] }) => {
  const [checked, setChecked] = useState(false)
  const { selectedDocsIds, setSelectedDocsIds } = useSelectedDocsContext()

  useEffect(() => {
    setChecked(false)
  }, [page])

  useEffect(() => {
    selectedDocsIds.size === 0 && setChecked(false)
  }, [selectedDocsIds])

  const checkAllSelected = useCallback(() => {
    if (selectedDocsIds.size === 0) return false
    return rows.every((row) => selectedDocsIds.has(row.id))
  }, [selectedDocsIds, rows])

  const handleSelectOne = useCallback(
    (id: string, row) => {
      const newSelectedDocsIds = new Map(selectedDocsIds)
      if (newSelectedDocsIds.get(id)) {
        newSelectedDocsIds.delete(id)
      } else {
        newSelectedDocsIds.set(id, row)
      }
      setSelectedDocsIds(newSelectedDocsIds)
    },
    [selectedDocsIds, setSelectedDocsIds]
  )

  const handleSelectAll = useCallback(
    (event) => {
      const newSelectedDocsIds = new Map(selectedDocsIds)
      if (event.target.checked && !checkIntermediate()) {
        rows.forEach((row) => {
          newSelectedDocsIds.set(row.id, row)
        })
        setChecked(true)
      } else {
        rows.forEach((row) => {
          newSelectedDocsIds.delete(row.id)
        })
        setChecked(false)
      }
      setSelectedDocsIds(newSelectedDocsIds)
    },
    [selectedDocsIds, setSelectedDocsIds, rows]
  )

  const checkIntermediate = useCallback((): boolean => {
    return rows.some((row) => selectedDocsIds.has(row.id))
  }, [selectedDocsIds, rows])

  const checkboxHeader = useCallback(
    () => (
      <TableCheckbox
        checked={checkAllSelected() || checked}
        indeterminate={
          selectedDocsIds.size < rows.length && checkIntermediate()
        }
        onChange={handleSelectAll}
      />
    ),
    [
      selectedDocsIds,
      rows,
      checked,
      handleSelectAll,
      checkIntermediate,
      checkAllSelected,
    ]
  )

  const checkboxTable = useCallback(
    ({ row }) => (
      <TableCheckbox
        disabled={false}
        checked={selectedDocsIds.get(row.id) ? true : false}
        onChange={() => handleSelectOne(row.id, row)}
        value="true"
      />
    ),
    [selectedDocsIds, handleSelectOne]
  )

  return {
    handleSelectOne,
    handleSelectAll,
    checkboxHeader,
    checkboxTable,
  }
}
