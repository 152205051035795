import { createContext, useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export enum EManageTemplateTab {
  Documents = 'documents',
  Data = 'data',
  Integrations = 'integrations',
  Settings = 'settings',
}

export type TManageTemplateTabsContext = ReturnType<
  typeof useManageTemplateTabsContextDefaultValue
>

export const ManageTemplateTabsContext =
  createContext<TManageTemplateTabsContext>({} as any)

export const useManageTemplateTabsContextDefaultValue = (
  defaultTab: EManageTemplateTab
) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const getUrlParamTab = useCallback(
    () => searchParams.get('tab'),
    [searchParams]
  )

  const [activeTab, setActiveTab] = useState<EManageTemplateTab | string>(
    getUrlParamTab() || defaultTab
  )

  useEffect(() => {
    if (activeTab != getUrlParamTab()) setActiveTabValue(activeTab)
  }, [])

  const setActiveTabValue = useCallback(
    (tab: EManageTemplateTab | string) => {
      setActiveTab(tab)
      searchParams.set('tab', tab)
      setSearchParams(searchParams)
    },
    [setActiveTab, searchParams, setSearchParams]
  )

  return {
    activeTab,
    setActiveTab: setActiveTabValue,
  }
}
