import { IconButton } from '@mui/material'
import { BoxImg, IBoxImgProps } from 'components/img/BoxImg'
import { ITooltipProp, TooltipLayout } from 'components/tooltip/TooltipLayout'
import { MouseEventHandler } from 'react'

export const IconButtonImage = ({
  tooltip,
  icon,
  onClick,
  disabled,
  size,
}: {
  tooltip?: ITooltipProp
  icon: IBoxImgProps & { size: '16px' | '20px' }
  size?: 'small' | 'medium' | 'large'
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}) => {
  return (
    <TooltipLayout tooltip={tooltip}>
      <IconButton onClick={onClick} disabled={disabled} size={size}>
        <BoxImg src={icon.src} size={icon.size ?? '20px'} />
      </IconButton>
    </TooltipLayout>
  )
}
