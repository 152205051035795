import { TableHead, TableRow } from '@mui/material'
import { TableHeaderCellVariable } from '../TableHeaderCellVariable'
import { ESystemVariableType } from 'features/text-editors/lexical/plugins-custom/ParameterPickerPlugin/helpers'
import { IManageTemplateDocumentHeader } from 'store/slices/manageTemplateData'

export const TableVariablesHeader = ({
  header,
}: {
  header: IManageTemplateDocumentHeader[]
}) => {
  return (
    <TableHead>
      <TableRow>
        <TableHeaderCellVariable
          name="Дата заполнения"
          variableType={ESystemVariableType.INPUT_DATE}
        />
        {header.map((column) => {
          return (
            <TableHeaderCellVariable
              name={column.variable_name}
              variableType={column.variable_type}
              key={column.variable_id}
            />
          )
        })}
      </TableRow>
    </TableHead>
  )
}
