import { AnalyticsBrowser } from '@segment/analytics-next'
import { config } from 'config'

const analytics = AnalyticsBrowser.load(
  {
    writeKey: config.API_KEY_SEGMENT_ANALYTICS ?? '',
    cdnURL: config.API_URL_CDN_SEGMENT_ANALYTICS,
  },
  // ? Proxy https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/custom-proxy/
  {
    integrations: {
      'Segment.io': { apiHost: config.API_HOST_SEGMENT_ANALYTICS },
    },
  }
)

export { analytics }
