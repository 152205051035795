import './index.css'

import {
  COMMAND_PRIORITY_HIGH,
  DOMConversionMap,
  DOMConversionOutput,
  ElementNode,
  LexicalNode,
  SerializedElementNode,
} from 'lexical'

const columnListNodeType = 'columns-list'

export type SerializedColumnsListNode = SerializedElementNode

export class ColumnsListNode extends ElementNode {
  static getType(): string {
    return columnListNodeType
  }

  static clone(node: ColumnsListNode): ColumnsListNode {
    return new ColumnsListNode(node.__key)
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('div')
    dom.setAttribute('lexical-type', columnListNodeType)
    dom.style.display = 'flex'
    dom.style.gap = '24px'

    dom.style.paddingTop = `8px`
    dom.style.paddingBottom = `8px`
    return dom
  }

  updateDOM(): boolean {
    return false
  }

  static importDOM(): DOMConversionMap | null {
    return {
      div: (domNode: HTMLElement) => {
        const tp = domNode.getAttribute('lexical-type')
        if (tp !== columnListNodeType) return null

        return {
          conversion: convertColumnListNode,
          priority: COMMAND_PRIORITY_HIGH,
        }
      },
    }
  }

  static importJSON(): ColumnsListNode {
    return $createColumnsListNode()
  }

  exportJSON(): SerializedColumnsListNode {
    return {
      ...super.exportJSON(),
      type: columnListNodeType,
      version: 1,
    }
  }

  canBeEmpty(): boolean {
    return false
  }
}

function convertColumnListNode(): DOMConversionOutput {
  return { node: $createColumnsListNode() }
}

export function $createColumnsListNode(): ColumnsListNode {
  return new ColumnsListNode()
}

export function $isColumnsListNode(
  node: LexicalNode | null | undefined
): node is ColumnsListNode {
  return node instanceof ColumnsListNode
}
