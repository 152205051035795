import { IPayment } from 'store/slices/wsPlan'
import { PaymentButton } from '../../PaymentButton'
import { useDownloader } from 'hooks'
import { getPdfFileByLink } from 'service'
import { MessageSnackbar } from 'components'

export const PaymentsTableCellContent = ({
  payment,
}: {
  payment: IPayment
}) => {
  const { loading, download, snackbar } = useDownloader(getPdfFileByLink)

  const handleDownload = () => {
    download({ link: payment.document_url ?? '' }, 'Счет на оплату')
  }

  const handlePayment = () => {
    if (!payment.payment_url) return
    window.location.href = payment.payment_url
  }

  const isCardPayment = payment.payment_method_type === 'CARD'

  return (
    <>
      <MessageSnackbar
        message={snackbar.message}
        clearMessage={snackbar.close}
        severity={snackbar.severity}
        buttons={snackbar.buttons}
      />
      {payment.document_url ? (
        payment.document_url && (
          <PaymentButton
            onClick={handleDownload}
            loading={loading}
            buttonText="Скачать счет"
          />
        )
      ) : payment.payment_url &&
        payment.status === 'ISSUED' &&
        isCardPayment ? (
        <PaymentButton
          onClick={handlePayment}
          loading={loading}
          buttonText="Оплатить сейчас"
        />
      ) : null}
    </>
  )
}
