import { Table, TableBody } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { TRootState, useAppSelector } from 'store/store'
import { TableVariablesBodyRow } from './components/TableVariablesBodyRow'
import { TableVariablesBodyRowLoading } from './components/TableVariablesBodyRowLoading'
import { TableVariablesHeader } from './components/TableVariablesHeader'
import { useAutoLoadTemplateDocumentsVariablesOnScroll } from './hooks/useAutoLoadTemplateDocumentsVariablesOnScroll'

export const TableVariables = () => {
  const { selectedTeamspace } = useSelector(
    (state: TRootState) => state.teamspace,
    shallowEqual
  )
  const { records, template_id, isFullyLoaded, header } = useAppSelector(
    (state) => state.templateData,
    shallowEqual
  )
  const { observerRef } = useAutoLoadTemplateDocumentsVariablesOnScroll()

  if (!records) return null
  return (
    <>
      <Table>
        <TableVariablesHeader header={header} />
        <TableBody>
          {records.map((record) => (
            <TableVariablesBodyRow
              key={record.document_id}
              templateId={template_id}
              record={record}
              teamspaceId={selectedTeamspace?.id ?? ''}
              header={header}
            />
          ))}

          {!isFullyLoaded && (
            <TableVariablesBodyRowLoading
              header={header}
              anchorRef={observerRef}
            />
          )}
        </TableBody>
      </Table>
    </>
  )
}
