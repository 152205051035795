import { createAsyncThunk } from '@reduxjs/toolkit'
import { workspaceApi } from 'service/workspaces'

const getPlanNameInfo = (plan_name: string) => {
  const splittedName = plan_name.split(':')
  const name = splittedName[0],
    version = splittedName.length >= 1 ? splittedName[1] : ''
  return { name, version }
}

export const wsPlanGetPlan = createAsyncThunk(
  'wsPlan/get',
  async ({ wsId }: { wsId: string }, { rejectWithValue }) => {
    try {
      const { data } = await workspaceApi.getWorkspacePlan({ wsId })
      const cpnInfo = getPlanNameInfo(data.plan_limits?.name ?? '')
      return {
        data,
        planName: cpnInfo.name,
        wsId: wsId,
      }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)
