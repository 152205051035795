import { Chip } from '@mui/material'

export const ChipOutlinedSmall = ({ label }) => {
  return (
    <>
      <Chip
        variant={'outlined'}
        size="small"
        label={label}
        sx={{
          maxWidth: '80px',
          height: '16px',
          fontSize: '12px',
          borderRadius: '4px',
          lineHeight: '16px',
          '& .MuiChip-label': {
            p: '0 4px',
          },
        }}
      />
    </>
  )
}
