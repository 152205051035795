import img_copy from 'assets/img/content_copy.svg'
import img_check from 'assets/img/ic_check.svg'

import { Box, Button, SxProps } from '@mui/material'
import { useCopyToClipboard } from 'hooks/useCopyToClipboard'

export const ButtonCopy = ({
  fullWidth,
  size,
  title,
  variant = 'outlined',
  copyTextValue,
  onCopyCallback,
  sx,
}: {
  fullWidth?: boolean
  size: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  title?: {
    copy?: string
    copied?: string
  }
  copyTextValue: string
  onCopyCallback?: () => void
  sx?: SxProps
}) => {
  const { copied, copyToClipboard } = useCopyToClipboard()

  return (
    <Button
      color="secondary"
      variant={variant}
      size={size}
      onClick={(e) => {
        e.stopPropagation()
        copyToClipboard(copyTextValue)
        if (onCopyCallback) onCopyCallback()
      }}
      fullWidth={fullWidth}
      startIcon={
        <Box
          component={'img'}
          src={copied ? img_check : img_copy}
          sx={{
            width: {
              sm: '16px',
              xs: '20px',
            },
            height: {
              sm: '16px',
              xs: '20px',
            },
          }}
          mr={'4px'}
        />
      }
      sx={sx}
    >
      {copied ? title?.copied ?? 'Скопировано' : title?.copy ?? 'Скопировать'}
    </Button>
  )
}
