import { useState, useMemo } from 'react'
import { queryListenDocumentSignStatus } from 'service/documentStatus'

export const useDocumentSignListenerContextDefaultValue = () => {
  const [isRunning, setIsRunning] = useState(false)
  const memo = useMemo(() => {
    return {
      fields: {
        documentId: '',
        originId: '',
      },
    }
  }, [])

  const listenDocumentSignStatus = async ({
    fields,
    onResponseData,
    onResponseError,
  }: {
    fields: {
      documentId: string
      originId: string
      isRunning: boolean
    }
    onResponseData: (data: string) => void
    onResponseError?: (errMsg: string) => void
  }) => {
    if (
      memo.fields.documentId === fields.documentId &&
      memo.fields.originId === fields.originId &&
      isRunning
    )
      return

    memo.fields.documentId = fields.documentId
    memo.fields.originId = fields.originId

    setIsRunning(true)
    await queryListenDocumentSignStatus({
      fields,
      onResponseData,
      onResponseError,
      onFinished: () => {
        setIsRunning(false)
      },
    })
  }

  return {
    listenDocumentSignStatus,
  }
}
