import { useTemplatesRegistryDublicateTemplateContextDefaultValue } from 'features/copy-template/provider/TemplatesRegistryDublicateTemplateContext'
import { createContext, useMemo, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { useAppSelector } from 'store/store'

export const useTemplatesRegistryContextDefaultValue = () => {
  const [hiddenIds, setHiddenIds] = useState<{
    [key: string]: boolean
  }>({})

  const { private_templates, filter } = useAppSelector(
    (state) => state.templates,
    shallowEqual
  )

  const actionDublicate =
    useTemplatesRegistryDublicateTemplateContextDefaultValue()

  const rows = useMemo(() => {
    const filtered = private_templates

    const dublicates = [...actionDublicate.dublicates].reverse()
    const result = [...dublicates, ...filtered].filter(
      (el) => !el || !hiddenIds[el.id]
    )
    return result
  }, [filter, private_templates, actionDublicate.dublicates, hiddenIds])

  const hideTemplateFromRegistry = (templateId: string) => {
    setHiddenIds({ ...hiddenIds, [templateId]: true })
  }

  const unhideTemplateFromRegistry = (templateId: string) => {
    const keys = Object.keys(hiddenIds)
    const result = {}
    for (let i = 0; i < keys.length; i++) {
      const id = keys[i]
      if (id == templateId) continue
      result[id] = hiddenIds[id]
    }
    setHiddenIds(result)
  }

  return {
    actionDublicate,
    rows,
    hideTemplateFromRegistry,
    unhideTemplateFromRegistry,
    hiddenIds,
  }
}

export const TemplatesRegistryContext = createContext<
  ReturnType<typeof useTemplatesRegistryContextDefaultValue>
>({} as any)

export const TemplatesRegistryProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const templatesRegistryContext = useTemplatesRegistryContextDefaultValue()

  return (
    <TemplatesRegistryContext.Provider value={templatesRegistryContext}>
      {children}
    </TemplatesRegistryContext.Provider>
  )
}
