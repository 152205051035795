import { EWorkspacePlan } from 'helper/plan.types'
import { useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

export const useWorkspacePlanLimits = () => {
  const { plan_name, plan_info } = useSelector(
    (state: RootState) => state.wsPlan,
    shallowEqual
  )

  const getWorkspacePlanLimits = useMemo(
    () => [
      {
        title: `Количество готовых документов ${
          plan_name == EWorkspacePlan.Trial ? '' : 'в месяц'
        }`,
        from: plan_info.workspace_limits.documents_count,
        to: plan_info.plan_limits.documents_count,
        limitMsg: `Вы достигли лимита этого месяца в тарифе ${plan_name}`,
      },
      plan_info.plan_limits.workspace_users_count != 1 && {
        title: 'Количество пользователей в пространстве',
        from: plan_info.workspace_limits.workspace_users_count,
        to: plan_info.plan_limits.workspace_users_count,
        limitMsg: `Вы достигли лимита ${plan_name} тарифа`,
      },
      plan_info.plan_limits.teamspaces_count != 1 && {
        title: 'Количество групп',
        from: plan_info.workspace_limits.teamspaces_count,
        to: plan_info.plan_limits.teamspaces_count,
        limitMsg: `Вы достигли лимита ${plan_name} тарифа`,
      },
    ],
    [plan_info, plan_name]
  )

  return {
    workspacePlanLimits: getWorkspacePlanLimits,
  }
}
