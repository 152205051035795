import img_delete from 'assets/img/delete.svg'
import img_edit from 'assets/img/edit.svg'
import img_sticky_note_2 from 'assets/img/sticky_note_2.svg'

import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { Loading, MessageSnackbar, TextInput } from 'components'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { Formik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRecipientByOrigin } from 'service'
import { reset } from 'store/slices/workflow'
import * as Yup from 'yup'
import { MenuItemButtonWithToggle } from 'components/menu-item/menu-item-button/MenuItemButtonWithToggle'
import { CustomTooltip } from 'components/tooltip'
import { SettingCheckSign } from 'components/using-formik-context/SettingCheckSign'
import { ERecipientRole } from 'helper/consts'
import { EWfPostmanRecipientSubjectType } from 'helper/wfPostman.types'
import {
  ESidebarDocumentTrackingViewMode,
  SidebarDocumentTrackingContext,
} from '../../provider/SidebarDocumentTrackingContext'
import { CopyToClipboard } from 'pages/document-preview/components'
import { deleteWorkflowRecipient } from 'store/slices/workflow/deleteWorkflowRecipient'
import { updateWorkflowRecipients } from 'store/slices/workflow/updateWorkflowRecipients'
import { getRecipients } from 'store/slices/applicationSlice'

export const RecipientEdit = () => {
  const { document, recipients } = useSelector(
    (state: RootState) => state.application
  )

  const { loading, errors, resultAction } = useSelector(
    (state: RootState) => state.workflow
  )

  const { currentRecipientProps, setMode, setCurrentRecipientProps } =
    useContext(SidebarDocumentTrackingContext)
  const { origin_id, index, attrs, isCopy, role } = currentRecipientProps
  const { message, email, filter } = attrs || {}
  const [open, setOpen] = useState(false)
  const [editEmail, setEditEmail] = useState(false)
  const [addMessage, setAddMessage] = useState(message ? true : false)
  const [signLink, setSignLink] = useState('')

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Некорректный Email')
      .required('Обязательное поле'),
    _mustCheckSign: Yup.boolean(),
    _subjectType: Yup.string(),
    iin: Yup.string()
      .matches(/^[0-9]+$/, 'Только цифры')
      .min(12, 'Должно быть 12 символов')
      .max(12, 'Должно быть 12 символов'),
    bin: Yup.string()
      .matches(/^[0-9]+$/, 'Только цифры')
      .min(12, 'Должно быть 12 символов')
      .max(12, 'Должно быть 12 символов'),
    role: Yup.string(),
    message: Yup.string(),
  })

  const handleClickDelete = () => {
    setOpen(true)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    getRecipientByOrigin({ id: document.id, origin_id })
      .then((res) => {
        setCurrentRecipientProps({
          ...currentRecipientProps,
          attrs: {
            ...currentRecipientProps.attrs,
            ...res.data.recipient.attrs,
          },
        })
        setSignLink(`https://link.doodocs.kz/${res.data.recipient.link}`)
      })
      .catch((err) => {
        // show popup
        console.error(err)
      })

    return () => {
      dispatch(reset() as any)
    }
  }, [])

  const getRoleName = (role): string => {
    if (
      role === ERecipientRole.signer_rk ||
      role === ERecipientRole.anonymous_signer_rk
    ) {
      return 'Подписант'
    } else if (role === ERecipientRole.approver_rk) {
      return 'Согласант'
    }
    return '-'
  }

  const getLinkBlock = () => {
    return (
      <>
        <Typography mb="4px">Ссылка для подписания</Typography>
        <TextField
          value={signLink}
          placeholder={'loading...'}
          disabled
          fullWidth
          size="small"
          sx={{
            bgcolor: '#F7F7F7',
            mb: '4px',
          }}
        />
        <CopyToClipboard>
          {({ copy }) => (
            <Button
              // type="submit"
              color="secondary"
              size="small"
              fullWidth
              style={{
                border: '1px solid #E8E8E8',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06)',
              }}
              disabled={signLink === '' ? true : false}
              onClick={() => {
                copy(signLink)
              }}
            >
              <Typography variant="body1">Скопировать ссылку</Typography>
            </Button>
          )}
        </CopyToClipboard>
      </>
    )
  }

  return (
    <>
      <MessageSnackbar
        message={resultAction.editWorkflow ? 'Данные успешно cохранены 👍🏻' : ''}
        clearMessage={() => dispatch(reset() as any)}
        severity={'success'}
      />
      <MessageSnackbar
        message={errors.editWorkflow}
        clearMessage={() => dispatch(reset() as any)}
        severity={'error'}
      />
      <ModalCenterSelectableWithTitle
        open={open}
        handleClose={() => setOpen(false)}
        modalType="small"
        title={'Удалить получателя'}
        contentChild={
          <Typography fontWeight={500}>
            Вы уверены что хотите удалить получателя?
          </Typography>
        }
        footerChild={
          <Box justifyContent="flex-end" display={'flex'}>
            <Button
              size="small"
              color="secondary"
              onClick={() => setOpen(false)}
            >
              Отменить
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="error"
              disabled={loading.editWorkflow ? true : false}
              onClick={async () => {
                await dispatch(
                  deleteWorkflowRecipient({
                    id: document.id,
                    origin_id,
                    index,
                    isCopy,
                  }) as any
                )
                await dispatch(getRecipients({ id: document.id }) as any)
                setOpen(false)
                setMode(ESidebarDocumentTrackingViewMode.RecipientList)
              }}
            >
              Удалить
            </Button>
          </Box>
        }
      />
      {signLink !== '' ? (
        <Formik
          initialValues={{
            email: email || '',
            message: message || '',
            iin: (filter && filter.length > 0 && filter[0].iin) || '',
            bin: (filter && filter.length > 0 && filter[0].bin) || '',
            role: (filter && filter.length > 0 && filter[0].role) || '',
            _mustCheckSign:
              filter && filter.length > 0 && (filter[0].iin || filter[0].bin)
                ? true
                : false,
            _subjectType:
              filter && filter.length > 0 && filter[0].iin
                ? EWfPostmanRecipientSubjectType.person
                : EWfPostmanRecipientSubjectType.company,
            address: '',
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await dispatch(
              updateWorkflowRecipients({
                id: document.id,
                origin_id,
                index,
                values: {
                  ...values,
                  message: addMessage ? values.message : '',
                },
                isCopy,
                setMode,
              }) as any
            )
            await dispatch(getRecipients({ id: document.id }) as any)
          }}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Box p={'16px 0px'}>
                <Box display={'flex'} p="6px 16px" alignItems={'center'}>
                  <Typography color={'text.secondary'} mr="8px">
                    Получатель
                  </Typography>
                  <Box width={'100%'}>
                    {editEmail ? (
                      <TextInput
                        disabled={
                          recipients.state.index === index ? true : false
                        }
                        size="small"
                        fullWidth
                        placeholder="email"
                        name="email"
                      ></TextInput>
                    ) : (
                      <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems="center"
                      >
                        <Typography mr="8px">{values.email}</Typography>
                        {recipients.state.index === index ? (
                          <></>
                        ) : (
                          <CustomTooltip title="Изменить получателя">
                            <IconButton
                              disabled={
                                recipients.state.index === index ? true : false
                              }
                              onClick={() => {
                                setEditEmail(true)
                              }}
                              id="demo-positioned-button"
                              sx={{
                                borderRadius: '4px',
                                background: editEmail ? '#E8E8E8' : '',
                              }}
                              children={
                                <img
                                  src={img_edit}
                                  width="18px"
                                  height="18px"
                                  alt=""
                                />
                              }
                            />
                          </CustomTooltip>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box display={'flex'} p="6px 16px">
                  <Typography color={'text.secondary'} mr="8px">
                    Роль
                  </Typography>
                  <Typography sx={{ wordBreak: 'break-word' }}>
                    {getRoleName(role)}
                  </Typography>
                </Box>
              </Box>
              <Box p={'4px 16px'}>
                {recipients.state.index === index ? (
                  <Box p={'2px 0px'}>{getLinkBlock()}</Box>
                ) : null}
                {/* <Box p={'2px 0px'} mt={'4px'}>{getNotifyBtn()}</Box> */}
              </Box>

              {!isCopy && (
                <>
                  <Box p="6px 16px">
                    <Typography variant="body1" fontWeight={500}>
                      Настройки
                    </Typography>
                  </Box>

                  <SettingCheckSign />

                  <MenuItemButtonWithToggle
                    disabled={recipients.state.index === index ? true : false}
                    srcImage={img_sticky_note_2}
                    title={'Персональное сообщение'}
                    description={'Текст который увидит только этот получатель'}
                    checked={addMessage}
                    onClick={() =>
                      recipients.state.index !== index &&
                      setAddMessage(!addMessage)
                    }
                  />

                  {addMessage && (
                    <Box p="2px 16px">
                      <TextInput
                        disabled={
                          recipients.state.index === index ? true : false
                        }
                        size="small"
                        multiline
                        rows={3}
                        fullWidth
                        placeholder="Сообщение..."
                        name="message"
                      ></TextInput>
                    </Box>
                  )}
                </>
              )}

              <Box p="12px 16px">
                <Button
                  sx={{
                    justifyContent: 'flex-start',
                    fontWeight: 400,
                    p: '4px 8px',
                  }}
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={loading.editWorkflow ? true : false}
                >
                  Сохранить
                </Button>
              </Box>
              <Divider></Divider>
              <Box p="12px 8px">
                <Button
                  sx={{
                    justifyContent: 'flex-start',
                    fontWeight: 400,
                    p: '4px 8px',
                  }}
                  color="secondary"
                  fullWidth
                  onClick={handleClickDelete}
                  startIcon={<img src={img_delete} />}
                >
                  Удалить получателя
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        <Loading color="secondary" />
      )}
    </>
  )
}
