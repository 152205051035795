import { IChangePageArgs } from 'components/pagination/types'
import { EDocumentStatus } from 'helper/consts'
import { TSRolePermissions } from 'helper/roles'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router'
import { updateDocuments } from 'store/slices/documentsSlice'

export const useDocumentsChangePage = () => {
  const dispatch = useDispatch()

  const { tsId } = useParams()
  const { selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )

  const changePage = ({ page, params }: IChangePageArgs) => {
    const { filter, query, author_id, status } = params

    dispatch(
      updateDocuments({
        filter: filter ?? EDocumentStatus.ALL,
        page,
        query,
        teamspaceId: tsId || '',
        onlyOwnDocuments: !(
          selectedTeamspace &&
          TSRolePermissions[selectedTeamspace?.group_name].canManageDocs
        ),
        author_id,
        filter_to_search: filter === EDocumentStatus.ALL ? status : '',
      }) as any
    )
  }

  return { changePage }
}
