import plus_icon from 'assets/img/add.svg'
import bulk from 'assets/img/bulk.svg'
import doc from 'assets/img/doc.svg'
import template from 'assets/img/template.svg'

import { Box, MenuItem, Typography, styled } from '@mui/material'
import { ChipBeta } from 'components/chip/ChipBeta'
import { AppBarContext } from 'context'
import { LoadDocumentIcon } from 'features/load-doc-or-template'
import { TSRolePermissions } from 'helper/roles'
import { useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Link, useLocation } from 'react-router-dom'

export interface MenuItemGrayNestedProps {
  padding?: string
  component?: any
  to?: any
}

export const MenuItemGrayNested = styled(MenuItem)<MenuItemGrayNestedProps>(
  ({ padding }) => ({
    justifyContent: 'start',
    cursor: 'pointer',
    padding,
    fontWeight: 400,
    borderRadius: 0,
    margin: 0,
    '&.Mui-selected': {
      backgroundColor: '#E8E8E8',
      // fontWeight: 500,
      '&:hover': {
        backgroundColor: '#E8E8E8',
      },
    },
    '&.Mui-selected > img': {
      // filter: 'invert()',
    },
    '&:hover': {
      backgroundColor: '#E8E8E8',
    },
  })
)

export const MenuNavigation = ({ isNested = false, teamspace }) => {
  const { pathname } = useLocation()
  const { setOpenDrawer } = useContext(AppBarContext)
  const [hover, setHover] = useState(-1)

  const routes = [
    {
      src: doc,
      text: 'Документы',
      link: `/teamspace/${teamspace.id}/documents`,
      key: 0,
      allowed: true,
    },
    {
      src: template,
      text: 'Шаблоны',
      link: `/teamspace/${teamspace.id}/templates`,
      key: 1,
      allowed: true,
      isBeta: true,
    },
    {
      src: bulk,
      text: 'Рассылки',
      link: `/teamspace/${teamspace.id}/campaigns`,
      key: 3,
      allowed: teamspace.is_personal ? false : true,
    },
  ]
  return (
    <>
      {routes
        .filter((item) => item.allowed)
        .map((item) => [
          <MenuItemGrayNested
            component={Link}
            to={item.link}
            onMouseOver={() => {
              item.key === 0 && setHover(0)
            }}
            onMouseLeave={() => {
              item.key === 0 && setHover(-1)
            }}
            padding={isNested ? '6px 8px 6px 40px' : '6px 8px 6px 16px'}
            key={item.key}
            selected={pathname === item.link}
            onClick={() => {
              isMobile && setOpenDrawer(false)
            }}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={'8px'}
              whiteSpace={'nowrap'}
            >
              <Box
                component={'img'}
                src={item.src}
                width={'16px'}
                height={'16px'}
              />
              <Typography>{item.text}</Typography>
              {item.isBeta && <ChipBeta />}
            </Box>

            {item.key === 0 &&
              teamspace &&
              TSRolePermissions[teamspace?.group_name].canManageDocs && (
                <LoadDocumentIcon
                  tsId={teamspace.id}
                  src={<img src={plus_icon} alt="" />}
                  sx={{
                    borderRadius: '4px',
                    position: 'absolute',
                    right: '8px',
                    display: hover === 0 ? 'flex' : 'none',
                  }}
                />
              )}
          </MenuItemGrayNested>,
        ])}
    </>
  )
}
