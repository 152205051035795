import { Chip } from '@mui/material'

type TChipBetaPropType = 'default' | 'gray'

const sxProps: {
  [K in TChipBetaPropType]: any
} = {
  default: {
    color: 'var(--light-grayscale-content-1, #282A2F)',
    backgroundColor: '#fff',
  },
  gray: {
    fontWeight: 500,
    color: 'var(--light-grayscale-content-3, #6B6F76)',
    backgroundColor: 'var(--light-grayscale-background-secondary, #F5F5F5)',
  },
}

export const ChipBeta = (props: { type?: TChipBetaPropType }) => {
  return (
    <Chip
      label={'Beta'}
      variant={'outlined'}
      size="small"
      sx={{
        height: '16px',
        borderRadius: '4px',
        borderColor: 'var(--light-grayscale-border-hover, #E7E7E6)',

        fontSize: '12px',
        lineHeight: '16px',

        ...sxProps[props.type ?? 'default'],
        '& .MuiChip-label': {
          px: '3px',
        },
      }}
    />
  )
}
