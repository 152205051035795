import { Box, Button, Typography } from '@mui/material'
import { AppBarContext } from 'context'
import { ButtonProceedSubscriptionPlan } from 'features/proceed-subscription-plan/components/ButtonProceedSubscriptionPlan'
import { EWorkspacePlan } from 'helper/plan.types'
import { DASHBOARD_MODES } from 'layout/dashboard-layout'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'store/store'
import { isWorkspaceOwner } from 'utils/workspace/isWorkspaceOwner'
import {
  EModalBannerBg,
  EModalBannerIcon,
  ModalSmallWithBanner,
} from '../ModalSmallWithBanner'

export const ModalPlanLimitPlanEnded = ({
  open,
  handleClose,
}: {
  open: boolean
  handleClose?: any
}) => {
  const { selectedWorkspace } = useAppSelector(
    (state) => state.selectedWorkspace,
    shallowEqual
  )
  const { setMode } = useContext(AppBarContext)
  const { plan_info, payment_notification } = useAppSelector(
    (state) => state.wsPlan,
    shallowEqual
  )
  const isOwner = isWorkspaceOwner(selectedWorkspace?.group_name)

  return (
    <ModalSmallWithBanner
      open={open}
      handleClose={handleClose}
      banner={{
        icon: EModalBannerIcon.circle_orange_exclamation_mark,
        background: EModalBannerBg.doodocs_logos_gray,
      }}
      children={
        <Box p={'16px'}>
          <Typography variant="heading5">
            {plan_info?.previous_plan?.name == EWorkspacePlan.Trial
              ? 'Бесплатный период закончился'
              : 'Срок действия вашей подписки истек'}
          </Typography>
          <Typography mt={'8px'}>
            Не переживайте - ваши документы, надёжно хранятся у нас.
          </Typography>
          <Typography mt={'8px'}>
            {isOwner
              ? 'Чтобы продолжить использование, выберите тариф и оплатите подписку.'
              : 'Пожалуйста, свяжитесь с владельцем пространства для выбора и оплаты тарифа'}
          </Typography>
          {isOwner && (
            <Box
              mt={'16px'}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-end'}
            >
              {payment_notification.must_pay ? (
                <ButtonProceedSubscriptionPlan fullWidth={isMobile} />
              ) : (
                <Link
                  to={'/plan'}
                  style={{
                    width: isMobile ? '100%' : 'auto',
                  }}
                >
                  <Button
                    fullWidth
                    variant={'contained'}
                    color={'success'}
                    onClick={() => {
                      setMode(DASHBOARD_MODES.SETTINGS)
                      handleClose()
                    }}
                  >
                    Выбрать тариф
                  </Button>
                </Link>
              )}
            </Box>
          )}
        </Box>
      }
    />
  )
}
