import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'

export const Loading = (props: CircularProgressProps = {}) => {
  return (
    <Container
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '60px',
      }}
    >
      <CircularProgress {...props} />
    </Container>
  )
}

export const LoadingFullHeight = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <CircularProgress />
    </Container>
  )
}
