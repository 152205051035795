import img_background_doodocs_logos_gray from 'assets/img/backgrounds/doodocs_marks_gray.svg'
import img_background_doodocs_logos_blue from 'assets/img/backgrounds/doodocs_marks_blue.svg'
import img_icon_diamond_blue from 'assets/img/diamond_blue.svg'
import img_icon_circle_blue_up_white from 'assets/img/circle_blue_up_white.svg'
import img_icon_circle_orange_exclamation_mark from 'assets/img/circle_orange_exclamation_mark.svg'

import { Box } from '@mui/material'
import { ModalCenterSmall } from '..'

export enum EModalBannerBg {
  doodocs_logos_gray = 'doodocs_logos_gray',
  doodocs_logos_blue = 'doodocs_logos_blue',
}

export enum EModalBannerIcon {
  diamond_blue = 'diamond_blue',
  circle_blue_up_white = 'circle_blue_up_white',
  circle_orange_exclamation_mark = 'circle_orange_exclamation_mark',
}

const modalBannerBgs = {
  [EModalBannerBg.doodocs_logos_gray]: img_background_doodocs_logos_gray,
  [EModalBannerBg.doodocs_logos_blue]: img_background_doodocs_logos_blue,
}

const modalBannerIcons = {
  [EModalBannerIcon.diamond_blue]: img_icon_diamond_blue,
  [EModalBannerIcon.circle_blue_up_white]: img_icon_circle_blue_up_white,
  [EModalBannerIcon.circle_orange_exclamation_mark]:
    img_icon_circle_orange_exclamation_mark,
}

export const ModalSmallWithBanner = ({
  open,
  handleClose,
  banner,
  children,
}: {
  open: boolean
  handleClose?: (() => void) | any
  banner: {
    icon: EModalBannerIcon
    background: EModalBannerBg
  }
  children: React.ReactNode
}) => {
  return (
    <>
      <ModalCenterSmall
        open={open}
        handleClose={handleClose}
        header={{
          noPaddings: true,
          children: (
            <Box
              sx={{
                position: 'relative',
                overflow: 'hidden',
                width: '100%',
                height: '88px',
                padding: '16px 24px',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                component={'img'}
                position={'absolute'}
                sx={{
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  zIndex: 0,
                }}
                src={modalBannerBgs[banner.background]}
              />
              <Box
                component={'img'}
                width={'40px'}
                src={modalBannerIcons[banner.icon]}
                sx={{
                  zIndex: 1,
                }}
              />
            </Box>
          ),
        }}
        content={{
          noPaddings: true,
          children: children,
        }}
      />
    </>
  )
}
