import { Box, Button, Typography } from '@mui/material'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { shallowEqual, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { leaveWs } from 'store/slices/workspace'
import { useAppSelector } from 'store/store'

const title = 'Вы уверены, что хотите покинуть это пространство?'
const description =
  'Вы потеряете доступ к этому пространство и все ваши документы в приватной группе будут удалены'

export const BlockWorkspaceLeave = () => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { loading } = useAppSelector((state) => state.workspace, shallowEqual)

  const { selectedWorkspace } = useAppSelector(
    (state) => state.selectedWorkspace
  )

  const handleLeaveWs = () => {
    dispatch(leaveWs({ wsId: selectedWorkspace?.id || '', navigate }) as any)
  }

  if (selectedWorkspace && selectedWorkspace?.members_count > 1)
    return (
      <>
        <ModalCenterSelectableWithTitle
          open={open}
          handleClose={handleClose}
          modalType="small"
          title={title}
          contentChild={
            <>
              <Typography variant="body1">{description}</Typography>
              <Box
                mt={1}
                justifyContent="flex-end"
                display={'flex'}
                flexDirection={isMobile ? 'column' : 'row'}
              >
                <Button
                  sx={{ mr: '8px', mb: isMobile ? 1 : 0 }}
                  color="secondary"
                  onClick={() => setOpen(false)}
                  variant="outlined"
                >
                  Отменить
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  type="submit"
                  onClick={handleLeaveWs}
                  disabled={loading.leaveWs ? true : false}
                >
                  Покинуть пространство
                </Button>
              </Box>
            </>
          }
        />

        <Typography variant="body1" fontWeight={500}>
          Покинуть пространство
        </Typography>

        <Box mt="8px">
          <Button color="error" variant="outlined" onClick={handleOpen}>
            Покинуть пространство
          </Button>
        </Box>
      </>
    )
  return null
}
