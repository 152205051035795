import { LocalStorageReserve, UrlKeys } from 'helper/consts'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { useEffect, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import {
  clearData,
  fetchActors,
  fetchTemplateInfo,
  putTemplateVariablesAction,
} from 'store/slices/templateSlice'
import { convertVariablesAsServerFormat } from 'utils'

export const useTemplateEditorContentLoad = () => {
  const { urlId } = useUrlIdParam(UrlKeys.templateId)
  const dispatch = useDispatch()
  const [isTemplateLoaded, setIsTemplateLoaded] = useState(false)

  const template = useSelector(
    (state: RootState) => state.template,
    shallowEqual
  )

  useEffect(() => {
    const templateId = urlId.get()
    !templateId && template.id && urlId.setIfNotExists(template.id)
  }, [template.id, urlId.get()])

  useEffect(() => {
    const templateId = urlId.get()

    templateId && dispatch(fetchActors({ templateId: templateId }) as any)
  }, [urlId.get()])

  const loadTemplateInfo = async () => {
    const templateId = urlId.get()
    // TODO: if (templateId && templateId != template.id) чтоб при дублировании
    if (templateId) await dispatch(fetchTemplateInfo({ templateId }) as any)
  }

  const publishVariables = async () => {
    const convertedVariables = convertVariablesAsServerFormat()
    await dispatch(
      putTemplateVariablesAction({
        templateId: urlId.get(),
        variables: convertedVariables,
      }) as any
    )
  }

  const cleanAndSaveVariables = async () => {
    await publishVariables()
    localStorage.removeItem(LocalStorageReserve.variableProps)
  }

  useEffect(() => {
    return () => {
      cleanAndSaveVariables()
    }
  }, [])

  useEffect(() => {
    loadTemplateInfo()
    return () => {
      dispatch(clearData() as any)
    }
    // [urlId.get()]
  }, [])

  useEffect(() => {
    if (isTemplateLoaded) return
    setIsTemplateLoaded(!template.loading.info)
  }, [template.loading.info])

  return {
    isTemplateLoaded,
  }
}
