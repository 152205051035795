import { Box, Typography } from '@mui/material'
import { AppBarCommon } from 'components/header/app-bar-common'
import { StyledTab } from 'components/tabs/tab'
import { useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { a11yProps } from 'utils/a11yprops'
import { SearchBar } from 'features/search-bar'
import inbox from 'assets/img/inbox_black.svg'
import {
  useChangePageContext,
  useSearchBarContext,
  useSelectedDocsContext,
} from 'context'
import { MassActions } from 'pages/documents/components/mass-actions'
import { CustomTabs } from 'components'

const listOfStatus = [
  { key: false, value: 'Назначенные на меня' },
  { key: true, value: 'Выполненные' },
]

export const InboxHeader = () => {
  const { completed, filter, count }: IInboxState = useSelector(
    (state: RootState) => state.inbox,
    shallowEqual
  )

  const { changePage } = useChangePageContext()
  const { selectedDocsIds } = useSelectedDocsContext()
  const { queryString, authorQuery } = useSearchBarContext()

  const [status, setStatus] = useState(
    listOfStatus.findIndex((object) => {
      return object.key === completed
    }) || 0
  )

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setStatus(newValue)
    changePage({
      page: 0,
      params: {
        filter,
        completed: listOfStatus[newValue].key,
        query: queryString,
        author_id: authorQuery,
      },
    })
  }

  return (
    <AppBarCommon
      headerTitle={
        <>
          <Box
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              py: '8px',
              minHeight: '48px',
            }}
          >
            <Box display={'flex'} alignItems="center">
              <Box
                component={'img'}
                mr="4px"
                width={'20px'}
                height={'20px'}
                src={inbox}
              />

              <Typography style={{ marginRight: '4px' }}>Входящие</Typography>
            </Box>
          </Box>
        </>
      }
      secondaryBlock={
        <>
          <Box sx={{ background: 'white' }}>
            <CustomTabs value={status} handleChange={handleChange}>
              {listOfStatus.map((item, key) => (
                <StyledTab
                  key={item.value}
                  label={
                    <Box display={'flex'}>
                      {item.value}
                      {/* <Typography ml={'4px'}>{count}</Typography> */}
                    </Box>
                  }
                  {...a11yProps(key)}
                />
              ))}
            </CustomTabs>
          </Box>

          <Box padding={'12px 0'}>
            {selectedDocsIds.size > 0 ? (
              <MassActions filter={filter} />
            ) : (
              (count > 0 || authorQuery || queryString) && (
                <SearchBar filter={filter} showStatusFilter={false} />
              )
            )}
          </Box>
        </>
      }
    ></AppBarCommon>
  )
}
