import { Box, Typography } from '@mui/material'
import { ERegistryType } from 'components/breadcrumbs/BreadcrumbsBlock'
import { HeaderWithBreadcrumbs } from 'components/header/HeaderWithBreadcrumbs'
import { AppBarCommon } from 'components/header/app-bar-common'
import { StyledTab, StyledTabs } from 'components/tabs/tab'
import { useChangePageContext, useSelectedDocsContext } from 'context'
import { MassSend, MassSign } from 'features'
import { EDocumentStatus } from 'helper/consts'
import { TSRolePermissions } from 'helper/roles'
import { CreateCampaignButton } from 'pages/campaigns/header'
import { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const listOfStatus = [
  { key: EDocumentStatus.ALL, value: 'Все' },
  { key: EDocumentStatus.DRAFT, value: 'Готов к отправке' },
  { key: EDocumentStatus.SENT, value: 'Отправленные' },
  { key: EDocumentStatus.COMPLETED, value: 'Готовы' },
  { key: EDocumentStatus.REVOKED, value: 'Отозванные' },
  { key: EDocumentStatus.ACTIONS_REQUIRED, value: 'Ожидают вас' },
]

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const Toolbar = () => {
  const { tsId } = useParams()
  const { filter, name, stats, count_all }: ICamapignDocsTableState =
    useSelector((state: RootState) => state.campaignDocs, shallowEqual)

  const { changePage } = useChangePageContext()
  const { selectedDocsIds, setSelectedDocsIds } = useSelectedDocsContext()

  const { selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )
  const [status, setStatus] = useState(
    listOfStatus.findIndex((object) => {
      return object.key === filter
    }) || 0
  )

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setStatus(newValue)
    changePage({
      page: 0,
      params: {
        filter: listOfStatus[newValue].key,
      },
    })
  }

  return (
    <>
      <AppBarCommon
        headerTitle={
          <HeaderWithBreadcrumbs
            breadcrumb={{
              registry: {
                value: ERegistryType.MassSend,
                linkTo: `/teamspace/${tsId}/campaigns`,
              },
              document: {
                name: name,
              },
            }}
            rightChildren={
              <Box display={'flex'}>
                {filter !== EDocumentStatus.DRAFT &&
                  filter !== EDocumentStatus.ACTIONS_REQUIRED && (
                    <Box>
                      {selectedTeamspace &&
                        TSRolePermissions[selectedTeamspace?.group_name]
                          .canManageDocs && <CreateCampaignButton />}
                    </Box>
                  )}
                {selectedTeamspace &&
                  TSRolePermissions[selectedTeamspace?.group_name]
                    .canManageDocs &&
                  filter === EDocumentStatus.DRAFT && (
                    <Box mr="8px">
                      <MassSend
                        selectedDocsIds={selectedDocsIds}
                        changePage={changePage}
                        setSelectedDocsIds={setSelectedDocsIds}
                        disabled={!selectedDocsIds.size}
                      />
                    </Box>
                  )}
                {selectedTeamspace &&
                  TSRolePermissions[selectedTeamspace?.group_name]
                    .canManageDocs &&
                  filter === EDocumentStatus.ACTIONS_REQUIRED && (
                    <MassSign disabled={!selectedDocsIds.size} />
                  )}
              </Box>
            }
          />
        }
        children={
          <StyledTabs
            value={status}
            onChange={handleChange}
            sx={{ borderBottom: '1px solid #EDEDEC', minHeight: '32px' }}
            TabIndicatorProps={{
              sx: {
                background: '#1C1C1C',
              },
            }}
          >
            {listOfStatus.map((item, key) => (
              <StyledTab
                key={item.value}
                label={
                  <Box display={'flex'}>
                    {item.value}
                    <Typography ml={'4px'}>
                      {item.key === EDocumentStatus.ALL
                        ? count_all
                        : stats[item.key]}
                    </Typography>
                  </Box>
                }
                {...a11yProps(key)}
              />
            ))}
          </StyledTabs>
        }
      />
    </>
  )
}

export const Header = () => {
  return <Toolbar />
}
