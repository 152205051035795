import { useContext, useMemo } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { ERecipientRole } from 'helper/consts'
import { SidebarDocumentTrackingContext } from '../../provider/SidebarDocumentTrackingContext'
import { getCapitalizedText } from 'utils/strings'

export const CompanyRoles = [
  {
    label: 'Первый руководитель юридического лица',
    value: 'ceo',
  },
  {
    label: 'Лицо, наделенное правом подписи',
    value: 'employee-can-sign',
  },
  {
    label: 'Лицо, наделенное правом подписи финансовых документов',
    value: 'employee-can-sign-finance',
  },
  {
    label: 'Сотрудник отдела кадров',
    value: 'hr',
  },
  {
    label: 'Сотрудник организации',
    value: 'employee',
  },
]

export const CompanyRoleValuesLabels = (() => {
  const result = {}
  CompanyRoles.forEach((item) => {
    result[item.value] = item.label
  })
  return result
})()

export const RecipientRoleName = {
  [ERecipientRole.anonymous_signer_rk]: 'Подписант',
  [ERecipientRole.signer_rk]: 'Подписант',
  [ERecipientRole.approver_rk]: 'Согласант',
}

const formatDate = (date: string) => {
  if (!date) return ''
  return new Date(date)
    .toLocaleString('ru-RU', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    .replace(',', '')
}

const StructuredInfo = ({
  title,
  values,
}: {
  title?: string
  values: { key: string; value: string }[]
}) => {
  return (
    <Box padding={'12px 0'}>
      {!!title && (
        <Box sx={{ p: '4px 16px' }}>
          <Typography fontWeight={'500'}>{title}</Typography>
        </Box>
      )}
      {values.map((item) => (
        <Grid
          key={item.key}
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ p: '6px 16px' }}
        >
          <Grid xs={4.4} item>
            <Typography color={'text.disabled'}>{item.key}</Typography>
          </Grid>
          <Grid xs={7.6} item>
            <Typography>{item.value}</Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  )
}

export const SignerInfo = () => {
  const { currentRecipientProps } = useContext(SidebarDocumentTrackingContext)

  const recipientValues = [
    {
      key: 'Получатель',
      value:
        currentRecipientProps.role === ERecipientRole.anonymous_signer_rk
          ? 'Ссылка'
          : currentRecipientProps.email,
    },
    {
      key: 'Роль',
      value: RecipientRoleName[currentRecipientProps.role],
    },
  ]
  const signValues = useMemo(() => {
    const result: { key: string; value: string }[] = [
      currentRecipientProps?.signature?.org_name && {
        key: 'Организация',
        value: currentRecipientProps.signature.org_name,
      },
      currentRecipientProps?.signature?.bin && {
        key: 'БИН',
        value: currentRecipientProps.signature.bin,
      },
      currentRecipientProps?.signature?.name && {
        key: 'ФИО',
        value: getCapitalizedText(currentRecipientProps.signature.name),
      },
      currentRecipientProps?.signature?.iin && {
        key: 'ИИН',
        value: currentRecipientProps.signature.iin,
      },
      currentRecipientProps?.signature?.role && {
        key: 'Должность',
        value: CompanyRoleValuesLabels[currentRecipientProps.signature.role],
      },
      currentRecipientProps?.result_at && {
        key: 'Время подписи',
        value: formatDate(currentRecipientProps.result_at),
      },
    ]

    return result.filter((item) => !!item)
  }, [currentRecipientProps])

  return (
    <Box>
      <StructuredInfo values={recipientValues} />
      <StructuredInfo title="Данные подписи" values={signValues} />
    </Box>
  )
}
