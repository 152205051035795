import { ESystemVariableType } from 'features/text-editors/lexical/plugins-custom/ParameterPickerPlugin/helpers'
import {
  validationSchemaDropdown,
  validationSchema,
  validationSchemaCheckbox,
  validationSchemaNumber,
  validationSchemaPhoneNumber,
  validationSchemaEmail,
} from '..'

export const validationSchemas = {
  [ESystemVariableType.INPUT_TEXT]: validationSchema,
  [ESystemVariableType.TEXT_AREA]: validationSchema,
  [ESystemVariableType.DROPDOWN]: validationSchemaDropdown,
  [ESystemVariableType.INPUT_NUMBER]: validationSchemaNumber,
  [ESystemVariableType.INPUT_PHONE_NUMBER]: validationSchemaPhoneNumber,
  [ESystemVariableType.INPUT_EMAIL]: validationSchemaEmail,
  [ESystemVariableType.CHECKBOX]: validationSchemaCheckbox,
}
