import { AnalyticsEvent, IUTMParams } from 'config/analytics/segment.com/events'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { loginUser, authError } from 'store/slices/userSlice'
import { parseJWT } from 'utils/jwt'

interface ILoginType {
  login_source: 'google' | 'email'
}

interface IParams {
  email?: string
  code?: string
  full_name?: string
  phone?: string
  company_name?: string
}

type HandleSendCodeProps = ILoginType & IParams & IUTMParams

const getUserIdFromToken = () => {
  const token = localStorage.getItem('token')
  if (!token) return null
  const parsed = parseJWT(token)
  return parsed?.ut?.i
}

export const sendEventUserLogin = async (
  data: HandleSendCodeProps & { new_user?: boolean; inviteId?: string }
) => {
  const userId = getUserIdFromToken()
  const utm = {
    utm_campaign: data?.utm_campaign,
    utm_content: data?.utm_campaign,
    utm_medium: data?.utm_medium,
    utm_source: data?.utm_source,
    utm_term: data?.utm_term,
  }

  await AnalyticsEvent.user_identify(userId, utm)
}

export const useSendCode = () => {
  const { inviteId } = useParams()
  const dispatch = useDispatch()
  const [loadingCode, setLoadingCode] = useState(false)

  const loginSuccess = async (data: any) => {
    const { access_token } = data
    localStorage.setItem('token', `${access_token}`)
    dispatch(loginUser() as any)
  }

  const handleSendCode = async (method, params: HandleSendCodeProps) => {
    try {
      setLoadingCode(true)
      const resp = await method(params)
      await loginSuccess(resp.data)
      sendEventUserLogin({
        ...params,
        new_user: resp.data?.new_user,
        inviteId,
      })
      setLoadingCode(false)
    } catch (error: any) {
      dispatch(authError(error.message) as any)
      setLoadingCode(false)
      throw error
    }
  }

  return {
    handleSendCode,
    loadingCode,
  }
}
