import { Box } from '@mui/material'
import { Loading, MessageSnackbar } from 'components'
import { FieldWithButtonCopy } from 'entity/api-key'
import { useLoadAPIKey } from 'entity/api-key/model/useLoadAPIKey'
import { IApiKey } from 'entity/api-key/types'
import { ButtonCreateAPISecretKey } from 'features/create-api-secret-key/ui/ButtonCreateAPISecretKey'
import { ButtonOpenModalDeleteApiSecretKey } from 'features/delete-api-secret-key/ui/ButtonOpenModalDeleteApiSecretKey'
import { withDashboardLayout } from 'layout'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import { wsPlanGetPlan } from 'store/slices/wsPlan/wsPlanGetPlan'
import { useAppSelector } from 'store/store'
import { APIAppBar } from './APIAppBar'

const APIContent = () => {
  const [data, setData] = useState<IApiKey | undefined>(undefined)
  const [error, setError] = useState('')

  const { load } = useLoadAPIKey({
    onSuccess: (res) => setData(res),
    onError: (msg) => setError(msg),
  })

  useEffect(() => {
    load()
  }, [])

  const { workspace_id } = useAppSelector((state) => state.wsPlan, shallowEqual)
  const { selectedWorkspace } = useAppSelector(
    (state) => state.selectedWorkspace,
    shallowEqual
  )
  const dispatch = useDispatch()

  useEffect(() => {
    selectedWorkspace?.id &&
      dispatch(wsPlanGetPlan({ wsId: selectedWorkspace?.id }) as any)
  }, [selectedWorkspace])

  if (workspace_id != selectedWorkspace?.id) return <Loading />
  return (
    <>
      <MessageSnackbar
        clearMessage={() => setError('')}
        message={error}
        severity={'error'}
      />
      <Box p={{ xs: '16px', sm: '16px 40px' }} overflow={'auto'}>
        <Box
          maxWidth={'720px'}
          gap={'32px'}
          display={'flex'}
          flexDirection={'column'}
        />
        {data && data.client_secret.length > 0 && (
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'16px'}
            maxWidth={'640px'}
          >
            <FieldWithButtonCopy title={'Клиент ID'} value={data?.client_id} />
            <FieldWithButtonCopy
              title={'Клиент Secret'}
              value={data?.client_secret}
            />
            <Box>
              <ButtonOpenModalDeleteApiSecretKey
                onSuccess={() => setData(undefined)}
                onError={(msg) => setError(msg)}
              />
            </Box>
          </Box>
        )}

        {(!data || data.client_secret.length === 0) && (
          <ButtonCreateAPISecretKey
            onError={(e) => setError(e)}
            onSuccess={(res) => {
              setData(res)
            }}
          />
        )}
      </Box>
    </>
  )
}

export const PageAPI = () =>
  withDashboardLayout(APIContent)({ Header: APIAppBar })
