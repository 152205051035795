import { Box } from '@mui/material'
import { Loading } from 'components/loading'
import { ERecipientRole } from 'helper/consts'
import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {
  RecipientsCopy,
  RecipientsFillers,
  RecipientsSigners,
} from '../Recipients'

export const WorkflowRecipientsList = () => {
  const { recipients, loading } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )
  const filtered = useMemo(() => {
    if (!recipients?.recipients) {
      return {
        fillers: [],
        approvers: [],
        signers: [],
      }
    }
    const fillers = recipients.recipients.filter(
      (r) => r.role == ERecipientRole.filler
    )
    const approvers = recipients.recipients.filter(
      (r) =>
        r.role == ERecipientRole.anonymous_approver_rk ||
        r.role == ERecipientRole.approver_rk
    )
    const signers = recipients.recipients.filter(
      (r) =>
        r.role == ERecipientRole.anonymous_signer_rk ||
        r.role == ERecipientRole.signer_rk
    )
    return {
      fillers,
      approvers,
      signers,
    }
  }, [recipients?.recipients])

  if (loading.recipients) return <Loading />
  if (!recipients) return null
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      {
        <RecipientsFillers
          recipients={filtered.fillers}
          flowIndex={recipients?.state.index ?? 0}
        />
      }
      {
        <RecipientsSigners
          recipients={filtered.approvers}
          flowIndex={recipients.state.index ?? 0}
          rType="approvers"
          isSingleAnonymousLink={
            recipients.attrs?.is_single_anonymous_link ?? false
          }
        />
      }
      {
        <RecipientsSigners
          recipients={filtered.signers}
          flowIndex={recipients.state.index ?? 0}
          rType="signers"
          isSingleAnonymousLink={
            recipients.attrs?.is_single_anonymous_link ?? false
          }
        />
      }
      {
        <RecipientsCopy
          isSent={recipients.state.status == 'completed'}
          ccs={recipients.ccs}
        />
      }
    </Box>
  )
}
