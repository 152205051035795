import img_trash from 'assets/img/ic_delete.svg'

import { Box, Button, Divider, Menu, Typography } from '@mui/material'
import { MenuItemButton } from 'components/menu-item/menu-item-button'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { FormikTextField } from 'components/using-formik-context/FormikTextField'
import { FormikProvider, useFormik } from 'formik'
import { IRecipient } from 'helper/recipients'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { patchRecipientsId } from 'service'
import { getRecipients } from 'store/slices/applicationSlice'
import { deleteWorkflowRecipient } from 'store/slices/workflow/deleteWorkflowRecipient'
import * as Yup from 'yup'

export const RecipientsSignerLinkMenu = ({
  open,
  anchorEl,
  onClose,
  recipient,
}: {
  open: boolean
  anchorEl: HTMLElement | null
  onClose: (() => void) | any
  recipient: IRecipient
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const dispatch = useDispatch()
  const { document } = useSelector((state: RootState) => state.application)

  const validationSchema = Yup.object().shape({
    link_name: Yup.string().required('Please fill out this field').max(100),
  })

  const formik = useFormik({
    initialValues: {
      link_name: recipient.attrs.link_name ?? '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const newLinkName = values.link_name.trim()
      const oldLinkName = recipient.attrs.link_name?.trim()

      if (newLinkName !== oldLinkName) {
        await patchRecipientsId({
          id: recipient.recipient_id,
          data: { link_name: newLinkName },
        })

        await dispatch(getRecipients({ id: document.id }) as any)
      }
    },
  })

  const handleDelete = () => {
    setOpenDeleteModal(true)
    onClose()
  }

  useEffect(() => {
    if (!open) return
    return () => {
      formik.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          formik.handleSubmit()
        }
      })
    }
  }, [open])

  return (
    <>
      <ModalCenterSelectableWithTitle
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        modalType="small"
        title={'Удалить получателя'}
        contentChild={
          <Typography fontWeight={500}>
            Вы уверены что хотите удалить получателя?
          </Typography>
        }
        footerChild={
          <Box justifyContent="flex-end" display={'flex'}>
            <Button
              size="small"
              color="secondary"
              onClick={() => setOpenDeleteModal(false)}
            >
              Отменить
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="error"
              onClick={async () => {
                await dispatch(
                  deleteWorkflowRecipient({
                    id: document.id,
                    origin_id: recipient.origin_id,
                    index: recipient.index,
                    isCopy: false,
                  }) as any
                )
                await dispatch(getRecipients({ id: document.id }) as any)
                setOpenDeleteModal(false)
              }}
            >
              Удалить
            </Button>
          </Box>
        }
      />
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        sx={{
          mt: '2px',
        }}
        PaperProps={{
          style: {
            maxHeight: '100%',
          },
        }}
      >
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Box p={'6px 16px'}>
              <FormikTextField name="link_name" />
              <Typography variant="paragraph4" color={'text.disabled'}>
                Название ссылки, чтобы отличить эту ссылку от других в списке
                отслеживания
              </Typography>
            </Box>
          </form>
        </FormikProvider>
        <Divider />
        <MenuItemButton
          srcImage={img_trash}
          title="Удалить"
          onClick={() => {
            handleDelete()
          }}
        />
      </Menu>
    </>
  )
}
