import { withDashboardLayout } from 'layout'
import { TemplatesContent } from './components/content'
import { TemplatesHeader } from './components/header'

import {
  SelectedDocsIdsProvider,
  TemplatesSearchBarContextProvider,
} from 'context'
import {
  TemplatesBucketRegistryProvider,
  TemplatesRegistryProvider,
} from './registry/hooks'

export const Templates = () =>
  withDashboardLayout(TemplatesContent)({
    providers: [
      TemplatesRegistryProvider,
      TemplatesBucketRegistryProvider,
      TemplatesSearchBarContextProvider,
      SelectedDocsIdsProvider,
    ],
    Header: TemplatesHeader,
  })
