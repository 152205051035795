import { Box, Button } from '@mui/material'
import { TextInput } from 'components'
import { Formik } from 'formik'
import { useRef, useContext, useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { updateWorkspace } from 'store/slices/workspace'
import { WSPreviewContext } from '..'
import * as Yup from 'yup'

export const FormOrgNameDescription = () => {
  const formikRefDescription = useRef<any>(null)

  const { setOrgName, setOrgDescription } = useContext(WSPreviewContext)

  const { loading } = useSelector(
    (state: RootState) => state.workspace,
    shallowEqual
  )
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const { tsId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    const formikDesc = formikRefDescription.current
    formikDesc &&
      formikDesc.setValues({
        org_name: selectedWorkspace?.public_name,
        org_description: selectedWorkspace?.public_description,
      })
  }, [tsId, selectedWorkspace])

  const validationSchemaDescription = Yup.object().shape({
    org_name: Yup.string()
      .required('Обязательное поле')
      .max(50, 'Максимум 50 символов'),
    org_description: Yup.string().max(150, 'Максимум 150 символов'),
  })

  const handleSubmit = (values) => {
    dispatch(
      updateWorkspace({
        wsId: selectedWorkspace?.id || '',
        workspace: {
          ...selectedWorkspace,
          public_name: values.org_name,
          public_description: values.org_description,
        },
      }) as any
    )
  }

  return (
    <Formik
      innerRef={formikRefDescription}
      initialValues={{
        org_name: selectedWorkspace?.public_name,
        org_description: selectedWorkspace?.public_description,
      }}
      validationSchema={validationSchemaDescription}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, handleChange, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Box mb="24px">
              <TextInput
                autoFocus
                size="small"
                fullWidth
                placeholder="Название организации..."
                name="org_name"
                label={'Название организации'}
                onChange={(e) => {
                  handleChange(e)
                  setOrgName(e.target.value)
                }}
              ></TextInput>
            </Box>

            <TextInput
              multiline
              rows={4}
              InputProps={{
                style: { padding: 0 },
              }}
              sx={{ p: 0 }}
              autoFocus
              size="small"
              fullWidth
              placeholder="Описание организации..."
              name="org_description"
              label={'Описание организации'}
              onChange={(e) => {
                handleChange(e)
                setOrgDescription(e.target.value)
              }}
            ></TextInput>
          </Box>

          <Box p={'24px 0'}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!dirty || loading.update}
            >
              Сохранить
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  )
}
