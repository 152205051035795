import { EDocumentStatus } from 'helper/consts'
import { createContext, useContext } from 'react'
import { useSearchQuery } from './hooks'

interface SearchBarContextProps {
  queryString?: string
  setQueryString: (query: string) => void
  authorQuery?: string
  setAuthorQuery: (query: string) => void
  statusQuery?: string
  setStatusQuery: (query: string) => void
  placeholder?: string
  availableListOfStatus?: { key: EDocumentStatus; value: string }[]
}

const availableListOfStatusDocs = [
  { key: EDocumentStatus.DRAFT, value: 'Черновик' },
  { key: EDocumentStatus.REVOKED, value: 'Отозван' },
  { key: EDocumentStatus.SENT, value: 'Отправлен' },
  { key: EDocumentStatus.COMPLETED, value: 'Готов' },
]

const availableListOfStatusTemplates = [
  { key: EDocumentStatus.DRAFT, value: 'Черновик' },
  { key: EDocumentStatus.READY, value: 'Опубликован' },
]

export const SearchBarContext = createContext<SearchBarContextProps>({
  setQueryString: () => {},
  setAuthorQuery: () => {},
  setStatusQuery: () => {},
})

export const SearchBarContextProvider = ({ children }) => {
  const data = useSearchQuery()
  const contextValues = {
    ...data,
    availableListOfStatus: availableListOfStatusDocs,
    placeholder: 'Поиск по названию документа...',
  }

  return (
    <SearchBarContext.Provider value={contextValues}>
      {children}
    </SearchBarContext.Provider>
  )
}

export const TemplatesSearchBarContextProvider = ({ children }) => {
  const data = useSearchQuery()
  const contextValues = {
    ...data,
    availableListOfStatus: availableListOfStatusTemplates,
    placeholder: 'Поиск по названию шаблона...',
  }

  return (
    <SearchBarContext.Provider value={contextValues}>
      {children}
    </SearchBarContext.Provider>
  )
}

export const useSearchBarContext = () => {
  const context = useContext(SearchBarContext)
  if (context === undefined || context === null) {
    throw new Error(
      `useSelectedDocsProvider must be called within SelectedDocsContext`
    )
  }
  return context
}
