import { IconButton } from '@mui/material'

import img_add from 'assets/img/more_horiz.svg'

export const MenuButton = ({
  handleMenuOpen,
  hover,
}: {
  handleMenuOpen: (e: any) => void
  hover: number
}) => {
  const handleClick = (e) => {
    e.stopPropagation()
    handleMenuOpen(e)
  }

  return (
    <IconButton
      onClick={handleClick}
      sx={{ borderRadius: '4px' }}
      children={
        <img
          src={img_add}
          alt=""
          style={{ display: hover === 0 ? 'flex' : 'none' }}
        />
      }
    ></IconButton>
  )
}
