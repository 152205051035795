export const ROWS_PER_PAGE = 15

export const LocalStorageReserve = {
  variableProps: 'variableProps',
  token: 'token',
}

export const PhoneOS = {
  android: 'android',
  ios: 'ios',
}

export enum EDocumentType {
  PDF = 'PDF',
  TEMPLATE = 'TEMPLATE',
  EDITOR = 'EDITOR',
}

export const MAX_ATTEMPTS = 5

export enum EDocumentStatus {
  PREPARING = 'preparing',
  SENT = 'sent',
  DECLINED = 'declined',
  DRAFT = 'draft',
  COMPLETED = 'completed',
  ALL = 'all',
  REVOKED = 'revoked',
  ARCHIVED = 'archived',
  ACTIONS_REQUIRED = 'actions_required',
  WAITING_OTHERS = 'waiting_others',
  IN_PROGRESS = 'in–progress',
  READY_TO_SEND = 'ready_to_send',
  DELETED = 'deleted',
  READY = 'ready',
}

export enum ETemplateFilter {
  ALL = 'all',
  DELETED = 'deleted',
}

export enum ERecipientRole {
  signer_rk = 'signer_rk',
  approver_rk = 'approver_rk',
  anonymous_signer_rk = 'anonymous_signer_rk',
  anonymous_approver_rk = 'anonymous_approver_rk',
  filler = 'filler',
  // viewer = 'viewer',
}

export enum ESignOptionType {
  LINK = 'link',
  ME = 'me',
  NCA = 'nca',
}

export const CampaignStatus = {
  ALL: 'all',
  DRAFT: 'draft',
  PREPARING: 'preparing',
  READY_TO_SEND: 'ready_to_send',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
}

export const HistoryNames = {
  EMAIL_SENT: 'email-sent',
  DOCUMENT_CREATE: 'document-create',
  DOCUMENT_REVOKE: 'document-revoke',
  DOCUMENT_ARCHIVE: 'document-archive',
  DOCUMENT_UNARCHIVE: 'document-unarchive',
  EMAIL_DOCUMENT_REVOKED: 'email-document-revoked',
  OPEN_DOCUMENT_CLIENT: 'open-document-client',
  OPEN_DOCUMENT_USER: 'open-document-user',
  SIGN_CLIENT: 'sign-client',
  SIGN_USER: 'sign-user',
  DOWNLOAD_DOC_USER: 'download-doc-user',
  EMAIL_DOCUMENT_WITH_SIGNATURES: 'email-document-with-signatures',
  DOWNLOAD_DDC_USER: 'download-ddc-user',
}

export const HistoryDict = {
  [HistoryNames.EMAIL_SENT]: 'Клиенту отправлен E-Mail для подписания',
  [HistoryNames.DOCUMENT_CREATE]: 'создал(-a) документ',
  [HistoryNames.DOCUMENT_REVOKE]: 'Отозван',
  [HistoryNames.DOCUMENT_ARCHIVE]: 'Архивирован',
  [HistoryNames.DOCUMENT_UNARCHIVE]: 'Разархивирован',
  [HistoryNames.EMAIL_DOCUMENT_REVOKED]: 'Отправлен email об отзыва документа',
  [HistoryNames.OPEN_DOCUMENT_CLIENT]: 'Открыт клиентом',
  [HistoryNames.OPEN_DOCUMENT_USER]: ' Открыт автором документа',
  [HistoryNames.SIGN_CLIENT]: 'Подписан клиентом через',
  [HistoryNames.SIGN_USER]: 'Подписан автором через',
  [HistoryNames.EMAIL_DOCUMENT_WITH_SIGNATURES]:
    'Автору/Клиенту отправлен E-Mail с подписями и документом',
  [HistoryNames.DOWNLOAD_DDC_USER]:
    'Автор документа скачал документ с подписями',
}

export const StatusDict = {
  [EDocumentStatus.ALL]: '',
  [EDocumentStatus.PREPARING]: 'Готовится',
  [EDocumentStatus.REVOKED]: 'Отозван',
  [EDocumentStatus.ARCHIVED]: 'Архивирован',
  [EDocumentStatus.DRAFT]: 'Черновик',
  [EDocumentStatus.SENT]: 'Отправлен',
  [EDocumentStatus.COMPLETED]: 'Готов',
  [EDocumentStatus.READY]: 'Опубликован',
  [CampaignStatus.READY_TO_SEND]: 'Готова к рассылке',
  [CampaignStatus.IN_PROGRESS]: 'В работе',
  [EDocumentStatus.READY_TO_SEND]: 'Готов к отправке',
}

export const TemplateFilters = {
  MY: 'my',
  PUBLIC: 'public',
}

export const KeyType = {
  SIGN: 'SIGN',
  AUTH: 'AUTHENTICATION',
}

export const languages = [{ id: 'kz' }, { id: 'ru' }]

export const SlugVerify = {
  CERT_ORG_REQ: 'certificate-organization-required',
  CERT_AUTH_REQ: 'certificate-auth-required',
  SIGN_VERIFY_FAILED: 'signature-verification-failed',
  ORG_NOT_FOUND: 'organization-not-found',
  INVALID_SIGN: 'invalid-signature-method',
  TOKEN_EXP: 'token-expired',
  TOKEN_INVALID: 'token-invalid',
  TOKEN_MALOFORMED: 'token-malformed',
  EMPTY_TOKEN: 'empty-bearer-token',
  UNABLE_VERIFY_JWT: 'unable-to-verify-jwt',
  TS_NOT_FOUND: 'teamspace not found',
}

export const slugErrors = {
  [SlugVerify.CERT_ORG_REQ]: 'Требуется ЭЦП юр. лица',
  [SlugVerify.CERT_AUTH_REQ]: 'Требуется ЭЦП для авторизации',
  [SlugVerify.SIGN_VERIFY_FAILED]: 'ЭЦП невалидный',
  [SlugVerify.ORG_NOT_FOUND]: 'Организация не зарегистрирована',
  [SlugVerify.INVALID_SIGN]: 'Невалидный токен',
  [SlugVerify.TOKEN_EXP]: 'Токен истек',
  [SlugVerify.TOKEN_INVALID]: 'Токен невалидный',
  [SlugVerify.TOKEN_MALOFORMED]: 'Токен дефармирован',
  'invalid-code': 'Неправильный код',
  'code-not-found': 'Код не найден',
  'invalid-role': 'Неправильная роль',
  'internal-server-error': 'Внутреняя ошибка сервера',
  'too-many-failed-attempts': 'Слишком много попыток',
  'failed-to-send-email': 'Неправильный email',
  'sign-document-did-download-signature-failed': 'Ошибка входа',
  'document-is-signed-by-user': 'Вы уже подписали документ',
  'document-revoked': 'Документ отозван',
  'document-already-archived': 'Документ уже архивирован',
  'empty-excel': 'Пустой Excel - файл',
}

export const massSignSlugErrors = {
  MODULE_NOT_FOUND: 'Модуль не найден',
  BUNDLE_NOT_FOUND_OR_ALREADY_INSTALLED: 'Модуль не найден или уже установлен',
}

export const excelBulksendSlugErrors = {
  'excel cannot be empty': 'Загружен пустой Excel файл',
}

export enum UrlKeys {
  documentId = 'documentId',
  isInvite = 'isInvite',
  templateId = 'templateId',
}

export enum EgovMobileTypes {
  regular = 'eGov mobile',
  business = 'eGov mobile Business',
}

export enum EditorModes {
  template = 'template',
  document = 'document',
}

export enum EOnboardingAction {
  DOCUMENT_CREATE = 'document-create',
  DOCUMENT_SEND = 'document-send',
  DOCUMENT_SIGN = 'document-sign',
  FEATURE_TEMPLATE = 'feature-template',
}

export enum TallySearchParams {
  hide_company_name = 'hide_company_name',
}
