import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import { CssBaseline } from '@mui/material'
import AppRoutes from './routes'
import { Provider } from 'react-redux'
import store, { persistor } from './store/store'
import { PersistGate } from 'redux-persist/integration/react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { config } from 'config'

function App() {
  return (
    <GoogleOAuthProvider clientId={config.GOOGLE_SECRET_KEY}>
      <Provider store={store}>
        <ThemeProvider theme={theme({ mode: 'light' })}>
          <PersistGate loading={null} persistor={persistor}>
            <CssBaseline />
            <AppRoutes />
          </PersistGate>
        </ThemeProvider>
      </Provider>
    </GoogleOAuthProvider>
  )
}

export default App
