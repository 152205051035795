import { LocalStorageReserve } from 'helper/consts'
import { handleError } from 'service'
import { api } from 'config'

export const deleteUsersTokens = async () => {
  try {
    const resp = await api.delete(`/users/tokens`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return resp
  } catch (error) {
    return handleError(error)
  }
}
