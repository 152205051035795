import { useContext } from 'react'
import { getObjectClone } from 'utils/getObjectClone'
import { IRecipient } from 'helper/workflow/recipients/IRecipient'
import { WorfklowBuilderUIContext } from '../../../../../providers/WorkflowBuilderUIContext'
import {
  IRecipientAttrsFilter,
  ERecipientAttrsFilterSubjectType,
} from 'helper/workflow/recipients/types'
import { SettingCheckSign } from 'components/using-formik-context/SettingCheckSign'
import { Formik } from 'formik'
import * as Yup from 'yup'

export const RecipientSettingCheckSign = ({
  recipient,
}: {
  recipient: IRecipient
}) => {
  const workflowBuilderUI = useContext(WorfklowBuilderUIContext)

  const validationSchema = Yup.object().shape({
    _mustCheckSign: Yup.boolean(),
    _subjectType: Yup.string(),
    iin: Yup.string()
      .matches(/^[0-9]+$/, 'Только цифры')
      .min(12, 'Должно быть 12 символов')
      .max(12, 'Должно быть 12 символов'),
    bin: Yup.string()
      .matches(/^[0-9]+$/, 'Только цифры')
      .min(12, 'Должно быть 12 символов')
      .max(12, 'Должно быть 12 символов'),
    role: Yup.string(),
    message: Yup.string(),
  })

  const defaultValue = {
    _mustCheckSign: false,
    _subjectType: ERecipientAttrsFilterSubjectType.person,
    iin: '',
    bin: '',
    role: '',
  }

  const getInitialValues = () => {
    const filter: any[] = recipient.attrs?.filter
    if (!filter || filter.length < 1) return defaultValue
    return {
      _mustCheckSign: filter[0]._mustCheckSign ?? defaultValue._mustCheckSign,
      _subjectType: filter[0]._subjectType ?? defaultValue._subjectType,
      iin: filter[0].iin ?? defaultValue.iin,
      bin: filter[0].bin ?? defaultValue.bin,
      role: filter[0].role ?? defaultValue.role,
    } as IRecipientAttrsFilter
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const state = workflowBuilderUI.workflow

        const filter: IRecipientAttrsFilter = {
          _mustCheckSign: values._mustCheckSign,
          _subjectType: values._subjectType,
        }

        if (values._mustCheckSign) {
          if (values._subjectType === ERecipientAttrsFilterSubjectType.person) {
            filter.iin = values.iin
            filter.bin = ''
            filter.role = ''
          } else if (
            values._subjectType === ERecipientAttrsFilterSubjectType.company
          ) {
            filter.bin = values.bin
            filter.role = values.role
            filter.iin = ''
          }
        } else {
          filter.iin = ''
          filter.bin = ''
          filter.role = ''
        }

        if (!recipient.attrs) recipient.attrs = {} as any
        if (!recipient.attrs.filter) recipient.attrs.filter = [filter]
        recipient.attrs.filter[0] = {
          ...recipient.attrs.filter[0],
          ...filter,
        }
        workflowBuilderUI.setWorkflow(getObjectClone(state))
      }}
    >
      {<SettingCheckSign mustSubmitOnUnmout={true} />}
    </Formik>
  )
}
