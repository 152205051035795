import { Avatar, Box, Button, CircularProgress } from '@mui/material'
import img_add from 'assets/img/add_white.svg'
import img_account from 'assets/img/avatar.svg'
import imageCompression from 'browser-image-compression'
import { ErrorHelper, TextInput } from 'components/input/text-input'
import { useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateAvatar } from 'store/slices/userSlice'
import { stringAvatar } from 'utils/stringAvatar'

export const ProfileForm = ({ avatarFile }) => {
  const { user }: IUserState = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  )

  return (
    <>
      <UploadAvatar avatar={avatarFile} />
      <Box sx={{ mt: '24px' }}>
        <TextInput
          label={'Фамилия'}
          required={false}
          // onChange={handleChange}
          placeholder=""
          color="secondary"
          name={'lastName'}
          fullWidth
          variant={'outlined'}
        />
      </Box>
      <Box sx={{ mt: '24px' }}>
        <TextInput
          label={'Имя'}
          required={false}
          placeholder=""
          color="secondary"
          name={'firstName'}
          fullWidth
          variant={'outlined'}
        />
      </Box>
      <Box sx={{ mt: '24px' }}>
        <TextInput
          label={'Email'}
          disabled={true}
          color="secondary"
          defaultValue={user.email}
          name={'email'}
          fullWidth
          variant={'outlined'}
        />
      </Box>
    </>
  )
}

export const UploadAvatar = ({ avatar }) => {
  const dispatch = useDispatch()

  const [loadingAvatar, setLoadingAvatar] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const [avatarFile, setAvatarFile] = useState('')
  const { avatar: userAvatar }: IUserState = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  )

  useEffect(() => {
    avatar && setAvatarFile(avatar)
  }, [avatar])

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }

    event.target.value = null

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    setLoadingAvatar(true)
    const compressedFile = await imageCompression(fileObj, options)
    const objectUrl = URL.createObjectURL(compressedFile)
    setAvatarFile(objectUrl)

    dispatch(
      updateAvatar({
        avatar: compressedFile,
      }) as any
    )
    setLoadingAvatar(false)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const removeAvatar = () => {
    console.error('! TODO: Here is no route to remove photo')
  }

  const handleClick = () => {
    inputRef?.current?.click()
  }

  return (
    <Box sx={{ display: 'flex', mt: '24px' }}>
      <Box sx={{ mr: '8px' }}>
        <CustomAvatar
          src={avatarFile || img_account}
          handleClick={handleClick}
          loading={loadingAvatar}
          editable={true}
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection="row"
        alignItems={'center'}
        justifyContent="start"
      >
        <input
          style={{ display: 'none' }}
          ref={inputRef}
          type="file"
          onChange={handleFileChange}
          accept=".png,.jpg,.jpeg"
        />
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          disabled={userAvatar.loading ? true : false}
          onClick={handleClick}
          sx={{
            mr: '8px',
          }}
        >
          {userAvatar.loading ? <CircularProgress /> : 'Загрузить фото'}
        </Button>
        {/* {avatarFile ? (
          <CustomTooltip title={'Удалить'} placement="right">
            <IconButton
              sx={{
                p: '4px',
                borderRadius: '4px',
              }}
              onClick={removeAvatar}
            >
              <Box
                sx={{ width: '16px', height: '16px' }}
                component={'img'}
                src={img_delete}
              />
            </IconButton>
          </CustomTooltip>
        ) : null} */}
        {userAvatar.error && <ErrorHelper error={userAvatar.error} />}
      </Box>
    </Box>
  )
}

export const CustomAvatar = ({
  src,
  handleClick,
  loading = false,
  editable = false,
  rounded = false,
}) => {
  const [hover, setHover] = useState(false)

  const subStyle = {
    position: 'absolute',
    zIndex: '1',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    backgroundColor: '#282A2F33',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const getHoverComponent = () => (
    <Box
      sx={{
        ...subStyle,
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={handleClick}
    >
      <Box
        component={'img'}
        src={img_add}
        sx={{
          width: '20px',
          height: '20px',
        }}
      />
    </Box>
  )

  const getLoadingComponent = () => (
    <Box
      sx={{
        ...subStyle,
      }}
    >
      <CircularProgress sx={{ color: '#3C4149' }} />
    </Box>
  )

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          width: '40px',
          height: '40px',
          borderRadius: rounded ? '4px' : '50%',
          overflow: 'hidden',
        }}
        onMouseEnter={() => {
          if (!editable) {
            return
          }
          setHover(true)
        }}
        onMouseLeave={() => {
          if (!editable) {
            return
          }
          setHover(false)
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          <Avatar
            variant={rounded ? 'rounded' : 'circular'}
            {...(rounded ? stringAvatar('Workspace Name') : {})}
            sx={{
              width: 40,
              height: 40,
              backgroundColor: '#E7E7E6',
              border: '0.6px solid rgba(0, 0, 0, 0.1)',
              color: 'text.primary',
              borderRadius: rounded ? '4px' : '50%',
            }}
            src={src}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              borderRadius: rounded ? '4px' : '50%',
              width: '100%',
              height: '100%',
              // border: '1px solid #0000001A',
            }}
          />
        </Box>
        {loading ? getLoadingComponent() : hover ? getHoverComponent() : null}
      </Box>
    </>
  )
}
