import { useActionTemplateDelete } from 'hooks/useActionTemplateDelete'
import { useActionTemplateEdit } from 'hooks/useActionTemplateEdit'
import { useActionTemplateUse } from 'hooks/useActionTemplateUse'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const useActionsTemplateFromTemplate = ({
  teamspaceId,
  template,
}: {
  teamspaceId: string
  template: ITemplate
}) => {
  const navigate = useNavigate()

  const [error, setError] = useState('')

  const actionUse = useActionTemplateUse()
  const actionEdit = useActionTemplateEdit()
  const actionDelete = useActionTemplateDelete()

  const handleClickUse = async () => {
    actionUse.run({
      templateId: template.id,
      teamspaceId: teamspaceId,
      status: template.status,
    })
  }

  const handleClickEdit = async () => {
    await actionEdit.run({
      teamspaceId: teamspaceId,
      templateId: template.id,
      setError,
    })
  }

  const handleClickDelete = async () => {
    await actionDelete.run({
      templateId: template.id,
      onSuccessCallback: () => {
        navigate(`/teamspace/${teamspaceId}/templates`)
      },
      setError,
    })
  }

  return {
    error,
    setError,

    handleClickUse,

    handleClickEdit,

    loadingDelete: actionDelete.loading,
    handleClickDelete,
  }
}
