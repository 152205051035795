import { Typography, LinearProgress } from '@mui/material'
import { Box } from '@mui/system'

export const WhiteBoxProgress = ({ title, from, to, limitMsg }) => {
  return (
    <Box>
      <Typography mb={'4px'} variant="paragraph3">
        {title}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={
          (from * 100) / to <= 100 && (from * 100) / to >= 0
            ? (from * 100) / to
            : 100
        }
        sx={{
          height: '4px',
          borderRadius: '8px',
          mb: '4px',
          backgroundColor: '#EDEDEC',
        }}
      />
      <Box display={'flex'} alignItems={'baseline'}>
        <Box
          sx={{
            maxHeight: '20px',
            background: from >= to ? '#FFF1F2' : '#FFFFFF',
            border: `1px solid ${from >= to ? '#FFCCCE' : '#E8E8E8'}`,
            borderRadius: '4px',
            p: '0 4px',
            mr: '4px',
          }}
        >
          <Typography variant="paragraph3" fontWeight={'500'}>
            {from}
          </Typography>
        </Box>
        <Typography variant="paragraph3">/ {to}</Typography>
      </Box>
      {from >= to && from !== 0 && (
        <Typography color="error" mt="4px">
          {limitMsg}
        </Typography>
      )}
    </Box>
  )
}
