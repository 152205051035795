import { Button } from '@mui/material'
import { EWorkspacePlan } from 'helper/plan.types'

const getButtonPlanText = (isSelected: boolean) => {
  if (isSelected) return 'Текущий тариф / Оплатить'
  return 'Выбрать этот тариф'
}

export const ButtonPlan = ({
  planType,
  isSelected,
  onClick,
}: {
  planType: EWorkspacePlan
  isSelected: boolean
  onClick: any
}) => {
  return (
    <Button
      variant={
        planType === EWorkspacePlan.Start || isSelected
          ? 'outlined'
          : 'contained'
      }
      color={
        planType === EWorkspacePlan.Start || isSelected
          ? 'secondary'
          : 'primary'
      }
      onClick={onClick}
      fullWidth
    >
      {getButtonPlanText(isSelected)}
    </Button>
  )
}
