import { createContext, useEffect, useMemo, useState } from 'react'
import {
  queryAiChatGetHistory,
  queryAiChatGetSummary,
  queryAiChatSendMessage,
  queryAiInitDocument,
  queryAiInitQuestions,
} from 'service/ai-chat'
import {
  EAiChatMessageAuthor,
  EAiChatMessageStatus,
  EAiChatSystemCommand,
  IAiChat,
  IAiChatMessage,
  IAiChatMessages,
  IAiChatResponseMessage,
} from 'helper/aiChat.types'
import {
  getChatMessageAuthorByAiType,
  getChatSystemCommandByName,
  isChatMessageAuthorAI,
} from 'utils/aichat'
import { AnalyticsEvent } from 'config/analytics/segment.com/events'
import { shallowEqual, useSelector } from 'react-redux'
import { EDocumentType } from 'helper/consts'

const errMessageOnSseEmptyResponse =
  'К сожалению, Doodocs AI не смог ответить на ваш вопрос. Скорее всего, ваш вопрос слишком длинный, попробуйте переформулировать его более кратко.'

const getDefaultAiChat = (): IAiChat => ({
  id: '',
  messages: {
    value: [],
    loading: false,
    error: '',
  },
  response_message: {
    value: null,
    loading: false,
    error: '',
  },
})

export interface IAiChatContext {
  state: IAiChat
  init: (documentId: string) => Promise<void>
  sendMessage: (message: string) => Promise<void>
}

export const getAnalyticsDataForChat = () => {
  let isFirstUserMessage = false
  let isFirstAiMessage = false
  let documentType: EDocumentType = EDocumentType.PDF

  const getIsFirstUserMessage = () => isFirstUserMessage
  const getIsFirstAiMessage = () => isFirstAiMessage
  const getDocumentType = () => documentType

  const setIsFirstUserMessage = (value: boolean) => {
    isFirstUserMessage = value
  }
  const setIsFirstAiMessage = (value: boolean) => {
    isFirstAiMessage = value
  }
  const setDocumentType = (value: EDocumentType) => {
    documentType = value
  }

  return {
    getIsFirstUserMessage,
    getIsFirstAiMessage,
    getDocumentType,
    setIsFirstUserMessage,
    setIsFirstAiMessage,
    setDocumentType,
  }
}

export const AiChatContext = createContext<IAiChatContext>({} as IAiChatContext)

export const useAiChatContextDefaultValue = () => {
  const [state, setState] = useState<IAiChat>(getDefaultAiChat())
  const analyticsData = useMemo(() => getAnalyticsDataForChat(), [])

  const { document } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )

  useEffect(() => {
    analyticsData.setDocumentType(document.type as EDocumentType)
  }, [document.type])

  // ? SafeUpdateState Functions
  const setId = (id: string) => {
    setState((pv) => {
      return { ...pv, id }
    })
  }
  const setMessages = (messages: IAiChatMessages) => {
    setState((pv) => {
      return { ...pv, messages }
    })
  }
  const setResponseMessage = (response_message: IAiChatResponseMessage) => {
    setState((pv) => {
      return { ...pv, response_message }
    })
  }
  const insertMessagesValue = (message: IAiChatMessage) => {
    setState((pv) => {
      return {
        ...pv,
        messages: {
          ...pv.messages,
          value: [...pv.messages.value, message],
        },
      }
    })
  }

  // ? Analytics Logic
  const analyticsLogSendMessage = () => {
    AnalyticsEvent.chat_doc(
      'send_message',
      analyticsData.getIsFirstUserMessage() ? 'yes' : 'no',
      analyticsData.getDocumentType()
    )
    analyticsData.setIsFirstUserMessage(false)
  }
  const analyticsLogReceiveMessage = () => {
    AnalyticsEvent.chat_doc(
      'receive_message',
      analyticsData.getIsFirstAiMessage() ? 'yes' : 'no',
      analyticsData.getDocumentType()
    )
    analyticsData.setIsFirstAiMessage(false)
  }

  // ? Private Logic
  const loadMessagesAndGetCommand = async (
    id: string
  ): Promise<EAiChatSystemCommand> => {
    setMessages({ value: [], loading: true, error: '' })
    try {
      const resp = await queryAiChatGetHistory({ id })
      const messages: IAiChatMessage[] = []
      let command: EAiChatSystemCommand = EAiChatSystemCommand.none

      const respMsgs: any[] = resp.data
      for (let i = 0; i < respMsgs.length; i++) {
        const element = respMsgs[i]

        let author = EAiChatMessageAuthor.user
        if (element.type == 'ai') {
          author = getChatMessageAuthorByAiType(
            `${element.data.additional_kwargs.type}`
          )
        } else if (element.type == 'system') {
          if (respMsgs.length - 1 == i) {
            command = getChatSystemCommandByName(
              `${element.data.additional_kwargs.command}`
            )
          }
          continue
        }

        messages.push({
          author: author,
          text: element.data.content,
          status: EAiChatMessageStatus.completed,
        })
      }
      analyticsData.setIsFirstAiMessage(
        messages.find((m) => isChatMessageAuthorAI(m.author)) == null
      )
      analyticsData.setIsFirstUserMessage(
        messages.find((m) => m.author == EAiChatMessageAuthor.user) == null
      )
      setMessages({ value: messages, loading: false, error: '' })
      return command
    } catch (err) {
      if (err instanceof Error) {
        setMessages({ value: [], loading: false, error: err.message })
      } else {
        setMessages({ value: [], loading: false, error: `${err}` })
      }
      throw err
    }
  }

  const initDocumentWithQuestions = async (id) => {
    const responseMessage: IAiChatMessage = {
      author: EAiChatMessageAuthor.ai,
      status: EAiChatMessageStatus.fetching,
      text: '',
    }
    setResponseMessage({
      value: responseMessage,
      error: '',
      loading: true,
    })

    const onResponseChunk = (chunkMessage: string) => {
      if (responseMessage.status == EAiChatMessageStatus.fetching)
        responseMessage.text = ''

      responseMessage.status = EAiChatMessageStatus.generating
      responseMessage.text = (responseMessage.text + chunkMessage).replaceAll(
        '\\n',
        '\n'
      )
      setResponseMessage({
        value: responseMessage,
        error: '',
        loading: true,
      })
    }
    const onResponseDone = () => {
      analyticsLogReceiveMessage()
      const nmsg: IAiChatMessage = {
        author: EAiChatMessageAuthor.ai,
        status: EAiChatMessageStatus.completed,
        text: responseMessage.text,
      }
      setResponseMessage({
        value: null,
        error: '',
        loading: false,
      })
      insertMessagesValue(nmsg)
    }
    const onResponseError = (errMessage: string) => {
      if (responseMessage.status == EAiChatMessageStatus.fetching)
        responseMessage.text = ''

      const nmsg: IAiChatMessage = {
        author: EAiChatMessageAuthor.ai,
        status: EAiChatMessageStatus.error,
        text:
          responseMessage.text +
          (responseMessage.text == '' ? '' : '\n') +
          errMessage,
      }
      setResponseMessage({
        value: null,
        error: '',
        loading: false,
      })
      insertMessagesValue(nmsg)
    }

    await queryAiInitQuestions({
      id,
      onResponseChunk,
      onResponseDone,
      onResponseError,
    })
  }

  const initDocumentWithSummary = async (id) => {
    const responseMessage: IAiChatMessage = {
      author: EAiChatMessageAuthor.ai,
      status: EAiChatMessageStatus.fetching,
      text: 'Doodocs AI анализирует документ...',
    }
    setResponseMessage({
      value: responseMessage,
      error: '',
      loading: true,
    })

    const onResponseChunk = (chunkMessage: string) => {
      if (responseMessage.status == EAiChatMessageStatus.fetching)
        responseMessage.text = ''

      responseMessage.status = EAiChatMessageStatus.generating
      responseMessage.text = (responseMessage.text + chunkMessage).replaceAll(
        '\\n',
        '\n'
      )
      setResponseMessage({
        value: responseMessage,
        error: '',
        loading: true,
      })
    }
    const onResponseDone = () => {
      analyticsLogReceiveMessage()
      const nmsg: IAiChatMessage = {
        author: EAiChatMessageAuthor.ai,
        status: EAiChatMessageStatus.completed,
        text: responseMessage.text,
      }
      setResponseMessage({
        value: null,
        error: '',
        loading: false,
      })
      insertMessagesValue(nmsg)
    }
    const onResponseError = (errMessage: string) => {
      if (responseMessage.status == EAiChatMessageStatus.fetching)
        responseMessage.text = ''

      const nmsg: IAiChatMessage = {
        author: EAiChatMessageAuthor.ai,
        status: EAiChatMessageStatus.error,
        text:
          responseMessage.text +
          (responseMessage.text == '' ? '' : '\n') +
          errMessage,
      }
      setResponseMessage({
        value: null,
        error: '',
        loading: false,
      })
      insertMessagesValue(nmsg)
    }

    queryAiInitDocument({ id })
    await queryAiChatGetSummary({
      id,
      onResponseChunk,
      onResponseDone,
      onResponseError,
    })
  }

  // ? Main Logic

  /**
   * Inits chat with setting Chat ID and loading history
   * ! You cant init chat while `loading messages` or `without Chat ID`
   * @param documentId - Chat ID
   */
  const init = async (documentId: string) => {
    if (!documentId || state.messages.loading) return

    setId(documentId)
    setMessages({ value: [], loading: true, error: '' })

    let systemCommand: EAiChatSystemCommand = EAiChatSystemCommand.none
    let hasError = false

    try {
      systemCommand = await loadMessagesAndGetCommand(documentId)
    } catch (err) {
      hasError = true
      console.error(err)
    }
    if (hasError) return

    try {
      if (systemCommand == EAiChatSystemCommand.init_legal_assistant)
        await initDocumentWithQuestions(documentId)
      else if (systemCommand == EAiChatSystemCommand.init_document_assistant)
        await initDocumentWithSummary(documentId)
    } catch (err) {
      hasError = true
      console.error(err)
    }
  }

  const sendMessage = async (message: string) => {
    insertMessagesValue({
      author: EAiChatMessageAuthor.user,
      status: EAiChatMessageStatus.completed,
      text: message,
    })

    const responseMessage: IAiChatMessage = {
      author: EAiChatMessageAuthor.ai,
      status: EAiChatMessageStatus.fetching,
      text: '',
    }
    setResponseMessage({
      value: responseMessage,
      error: '',
      loading: true,
    })

    const onResponseChunk = (chunkMessage: string) => {
      if (responseMessage.status == EAiChatMessageStatus.fetching) {
        responseMessage.text = ''
        analyticsLogSendMessage()
      }

      responseMessage.status = EAiChatMessageStatus.generating
      responseMessage.text = (responseMessage.text + chunkMessage).replaceAll(
        '\\n',
        '\n'
      )
      setResponseMessage({
        value: responseMessage,
        error: '',
        loading: true,
      })
    }
    const onResponseDone = () => {
      analyticsLogReceiveMessage()
      const nmsg: IAiChatMessage = {
        author: EAiChatMessageAuthor.ai,
        status: EAiChatMessageStatus.completed,
        text: responseMessage.text,
      }
      setResponseMessage({
        value: null,
        error: '',
        loading: false,
      })
      insertMessagesValue(nmsg)
    }
    const onResponseError = (errMessage: string) => {
      if (responseMessage.status == EAiChatMessageStatus.fetching)
        responseMessage.text = ''

      errMessage =
        responseMessage.text == ''
          ? errMessageOnSseEmptyResponse
          : responseMessage.text
      const nmsg: IAiChatMessage = {
        author: EAiChatMessageAuthor.ai,
        status: EAiChatMessageStatus.error,
        text: errMessage,
      }
      setResponseMessage({
        value: null,
        error: '',
        loading: false,
      })
      insertMessagesValue(nmsg)
    }

    try {
      await queryAiChatSendMessage({
        id: state.id,
        message: message,
        onResponseChunk: onResponseChunk,
        onResponseDone: onResponseDone,
        onResponseError: onResponseError,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return {
    state,
    init,
    sendMessage,
  }
}
