import { Typography, Button, Box } from '@mui/material'
import { AppBarSettings } from 'components/header/app-bar-common'
import { TSRolePermissions } from 'helper/roles'
import { InviteModal } from 'layout/dashboard-layout/components/create-group'
import { useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

export const TSMembersHeader = () => {
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  const handleOpen = () => setOpen(true)

  const { members, selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )
  return (
    <>
      <InviteModal
        open={open}
        handleClose={handleClose}
        name={selectedTeamspace?.name || ''}
      />
      <AppBarSettings
        headerTitle={
          <>
            <Box>
              <Box display={'flex'}>
                <Typography variant="subtitle2">Участники</Typography>
                <Typography variant="subtitle2" color={'text.secondary'}>
                  &nbsp;{members.length}
                </Typography>
              </Box>

              {selectedTeamspace &&
                TSRolePermissions[selectedTeamspace?.group_name]
                  .canManageMemberGroups.length > 0 && (
                  <Box mt="12px">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpen}
                    >
                      Пригласить участников
                    </Button>
                  </Box>
                )}
            </Box>
          </>
        }
      />
    </>
  )
}
