import { Box, Button, CircularProgress, ToggleButtonGroup } from '@mui/material'
import { ERegistryType } from 'components/breadcrumbs/BreadcrumbsBlock'
import { IconButtonImage } from 'components/button/IconButtonImage'
import { AppBarCommon } from 'components/header/app-bar-common'
import { SidebarToggleBtn } from 'components/sidebar-toggle-button'
import { EDocumentStatus, UrlKeys } from 'helper/consts'
import { useNavigateToManageTemplate } from 'hooks/useNavigateToManageTemplate'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { MoreInfoBtn } from 'pages/document-preview/components/send-sign-button/more-info-btn'
import { SidebarMode } from 'pages/document-preview/hooks/useSideBarState'
import { EManageTemplateTab } from 'pages/manage-template/contexts/ManageTemplateTabsContext'
import { isMobile } from 'react-device-detect'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  publishTemplateAction,
  putTemplateVariablesAction,
} from 'store/slices/templateSlice'
import { convertVariablesAsServerFormat } from 'utils'

import img_chat from 'assets/img/auto_awesome.svg'
import img_gear from 'assets/img/gear.svg'
import { HeaderWithBreadcrumbs } from 'components/header/HeaderWithBreadcrumbs'
import { useSidebarModeContext } from 'context'
import { IconButtonTemplateActions } from 'pages/manage-template/components/IconButtonTemplateActions'

const TemplateStatus = ({ status }: { status: EDocumentStatus }) => {
  const dispatch = useDispatch()
  const { loading, id } = useSelector(
    (state: RootState) => state.template,
    shallowEqual
  )
  const { tsId } = useParams()
  const { urlId } = useUrlIdParam(UrlKeys.templateId)
  const navigate = useNavigate()

  const callback = () => {
    navigate(
      `/teamspace/${tsId}/documents/draft?${UrlKeys.templateId}=${
        urlId.get() || id
      }`
    )
  }

  const publishVariables = async () => {
    const convertedVariables = convertVariablesAsServerFormat()
    const actionResult = await dispatch(
      putTemplateVariablesAction({
        templateId: urlId.get() || id,
        variables: convertedVariables,
      }) as any
    )

    if (putTemplateVariablesAction.fulfilled.match(actionResult)) {
      return true
    } else {
      return false
    }
  }

  const handleClick = async () => {
    const success = await publishVariables()

    if (success) {
      dispatch(
        publishTemplateAction({
          templateId: urlId.get() || id,
          callback,
        }) as any
      )
    }
  }

  return status !== EDocumentStatus.COMPLETED ? (
    <Button
      color={'primary'}
      variant={'contained'}
      onClick={handleClick}
      disabled={loading.publish}
    >
      {loading.publish && <CircularProgress />}
      Опубликовать
    </Button>
  ) : (
    <></>
  )
}

const AppBarTemplate = ({ getToggleButtonGroup }) => {
  const { tsId } = useParams()

  const template = useSelector(
    (state: RootState) => state.template,
    shallowEqual
  )

  const { navigateToManageTemplate } = useNavigateToManageTemplate()

  return (
    <AppBarCommon
      headerTitle={
        <HeaderWithBreadcrumbs
          breadcrumb={{
            registry: {
              value: ERegistryType.Templates,
              linkTo: `/teamspace/${tsId}/templates`,
            },
            document: {
              name: template.name,
              status: 'draft',
            },
          }}
          rightChildren={
            !isMobile && (
              <Box sx={{ marginRight: '-12px', ml: '4px' }}>
                <Box
                  display={'flex'}
                  alignItems="center"
                  sx={{ background: 'white', gap: '8px' }}
                >
                  <IconButtonImage
                    tooltip={{
                      text: 'Настройки',
                    }}
                    icon={{
                      src: img_gear,
                      size: '20px',
                    }}
                    onClick={() =>
                      navigateToManageTemplate({
                        teamspaceId: tsId ?? '',
                        templateId: template.id,
                        activeTab: EManageTemplateTab.Settings,
                      })
                    }
                  />
                  <Box>
                    <TemplateStatus status={EDocumentStatus.DRAFT} />
                  </Box>
                  {getToggleButtonGroup()}

                  <IconButtonTemplateActions
                    teamspaceId={tsId ?? ''}
                    template={template as any}
                    disabled={!tsId || !template}
                  />
                </Box>
              </Box>
            )
          }
        />
      }
      secondaryBlock={
        isMobile && (
          <TemplateMobileAppBar getToggleButtonGroup={getToggleButtonGroup} />
        )
      }
    />
  )
}

const TemplateMobileAppBar = ({ getToggleButtonGroup }) => {
  const { updateSidebar } = useSidebarModeContext()

  return (
    <Box width={'100%'}>
      <Box display={'flex'} alignItems="center">
        <Box mr={1}>{getToggleButtonGroup()}</Box>
        <Box mr={1}>
          <MoreInfoBtn updateSidebar={updateSidebar} />
        </Box>
        <Box ml={'auto'}>
          <TemplateStatus status={EDocumentStatus.DRAFT} />
        </Box>
      </Box>
    </Box>
  )
}

export const TemplatePreviewHeader = () => {
  const { sidebarMode, updateSidebar } = useSidebarModeContext()

  const getToggleButtonGroup = () => {
    return (
      <ToggleButtonGroup
        exclusive
        value={sidebarMode}
        onChange={(e, value) => {
          updateSidebar(sidebarMode != value ? value : SidebarMode.close)
        }}
      >
        {SidebarToggleBtn({
          mode: SidebarMode.chat,
          src_img: img_chat,
          tooltip: 'Чат',
        })}
      </ToggleButtonGroup>
    )
  }

  return <AppBarTemplate getToggleButtonGroup={getToggleButtonGroup} />
}
