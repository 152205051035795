import { ERecipientRole } from "helper/consts"
import { IActor, ICC, IMeta, IWorkflow } from "./IWorkflow"
import { IRecipient } from "./recipients/IRecipient"
import { IStep } from "./steps/IStep"
import { getStructClone } from "utils/getStructClone"

export enum EWorkflowMode {
  default = "default",
  ordered = "ordered",
}

export interface IWorkflowStepsMode {
  approvers: EWorkflowMode
  signers: EWorkflowMode
}

export const workflowStepsModeFor = {
  [ERecipientRole.filler]: "",
  [ERecipientRole.approver_rk]: "approvers",
  [ERecipientRole.anonymous_approver_rk]: "approvers",
  [ERecipientRole.signer_rk]: "signers",
  [ERecipientRole.anonymous_signer_rk]: "signers",
}

export class CWorkflow implements IWorkflow {
  actors?: IActor[]
  cc?: ICC[]
  meta?: IMeta
  steps: IStep[] = []

  // ? Поля которые не будут экспортироваться начинаются с нижнего подчеркивания
  /**
   * @description Режим работы workflow, а именно порядок отправки
   */
  _stepsMode: IWorkflowStepsMode = {
    approvers: EWorkflowMode.default,
    signers: EWorkflowMode.default,
  }

  constructor(obj: {
    actors?: IActor[]
    cc?: ICC[]
    meta?: IMeta
    steps: IStep[]

    _stepsMode?: IWorkflowStepsMode
  }) {
    this.actors = obj.actors
    this._stepsMode = obj._stepsMode || this._stepsMode
    this.cc = obj.cc
    this.meta = obj.meta
    this.steps = obj.steps
  }

  getStepsModeForRecipient(role: ERecipientRole) {
    return this._stepsMode[workflowStepsModeFor[role]] ?? EWorkflowMode.default
  }

  switchOrderedMode(stepsMode: IWorkflowStepsMode) {
    this._stepsMode = stepsMode

    const recipients = this.steps.map(step => step.recipients).flat()
    const steps: IStep[] = []
    for (let i = 0; i < recipients.length; i++) {
      const mode = this.getStepsModeForRecipient(recipients[i].role)
      recipients[i].attach(steps, mode)
    }
    this.steps = steps
  }


  insertRecipient(recipient: IRecipient): boolean {
    const mode = this.getStepsModeForRecipient(recipient.role)
    return recipient.attach(this.steps, mode)
  }

  removeRecipient(recipient: IRecipient): boolean {
    return recipient.detach(this.steps)
  }

  /**
   * Inserts cc into workflow
   * @param cc 
   * @returns true if cc was inserted, false if cc already exists
   */
  insertCC(cc: ICC): boolean {
    if (!this.cc) {
      this.cc = [cc]
      return true
    }
    const idx = this.cc.findIndex(item => item.email === cc.email)
    if (idx === -1) {
      this.cc.push(cc)
      return true
    }
    return false
  }

  /**
   * Removes cc from workflow
   * @param cc 
   * @returns true if cc was removed, false if cc was not found
   */
  removeCC(cc: ICC): boolean {
    if (!this.cc) return false
    const idx = this.cc.findIndex(item => item.email === cc.email)
    if (idx !== -1) {
      this.cc.splice(idx, 1)
      return true
    }
    return false
  }


  export() {
    return {
      cc: getStructClone(this.cc),
      meta: getStructClone(this.meta),
      steps: this.steps.map(step => step.export()),
    }
  }

  /**
   * Works like export() but also exports fields that required to build workflow
   * @returns 
   */
  restorableExport() {
    return {
      actors: getStructClone(this.actors),
      cc: getStructClone(this.cc),
      meta: getStructClone(this.meta),
      steps: this.steps.map(step => step.export()),
    }
  }
}