import { Autocomplete, TextField } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { TextFieldEmailsTag } from './TextFieldEmailsTag'

const splitSymbols = [' ', ',', ';', '\n', '\t']

export const TextFieldEmails = ({
  menuOptions,
  setEmails,
  emails,
  defaultEmails,
  placeholder,
  disabled,
  setIsValuesValid,
}: {
  menuOptions?: string[]
  setEmails: (emails: string[]) => void
  emails: string[]
  defaultEmails?: string[]
  placeholder?: string
  disabled?: boolean
  setIsValuesValid?: (isValid: boolean) => void
}) => {
  const [inputValue, setInputValue] = useState('')

  const isValid = useMemo(() => {
    if (inputValue) return Yup.string().email().isValidSync(inputValue)
    if (!emails.length) return false
    return true
  }, [inputValue, emails])

  const addToEmails = (value: string) => {
    if (!value) return

    const result = [...emails]
    for (let i = 0; i < splitSymbols.length; i++) {
      const symbol = splitSymbols[i]
      if (symbol == ' ') continue
      value = value.replaceAll(symbol, ' ')
    }

    const splitEmails = value.split(' ')
    const notAdded: string[] = []
    for (let i = 0; i < splitEmails.length; i++) {
      const email = splitEmails[i]
      if (!email) continue
      if (result.includes(email)) continue

      const isValidEmail = Yup.string().email().isValidSync(email)
      if (isValidEmail) {
        result.push(email)
      } else {
        notAdded.push(email)
      }
    }

    if (JSON.stringify(result) !== JSON.stringify(emails)) setEmails(result)
    setInputValue(notAdded.join(' '))
  }

  const deleteEmail = (email: string) => {
    const idx = emails.findIndex((v) => v === email)
    if (idx < 0) return
    const newValues = [...emails]
    newValues.splice(idx, 1)
    setEmails(newValues)
  }

  const autocompleteOnBlur = () => {
    addToEmails(inputValue)
  }

  const autocompleteOnInputChange = (e: any) => {
    setInputValue(e.target.value)
    let isIncludesSplitSymbol = false

    for (let i = 0; i < splitSymbols.length; i++) {
      if (e.target.value.includes(splitSymbols[i])) {
        isIncludesSplitSymbol = true
        break
      }
    }

    if (isIncludesSplitSymbol) addToEmails(e.target.value)
  }

  const onKeyDown = (e: any) => {
    if (e.key === 'Backspace' && !inputValue && emails.length > 0) {
      const email = emails[emails.length - 1]
      setInputValue((pv) => {
        return email + '+' + pv
      })
      deleteEmail(email)
      return
    }
  }

  const onKeyUp = (e: any) => {
    if (e.key === 'Enter') {
      addToEmails(inputValue)
      return
    }
  }

  useEffect(() => {
    if (setIsValuesValid) setIsValuesValid(isValid)
  }, [isValid])

  return (
    <Autocomplete
      sx={{
        '& .MuiOutlinedInput-root': {
          p: '6px 12px',
        },
      }}
      id="tags-filled"
      multiple
      disableClearable
      disabled={disabled}
      freeSolo
      inputValue={inputValue}
      options={menuOptions ?? []}
      defaultValue={defaultEmails}
      value={emails}
      onBlur={autocompleteOnBlur}
      onInputChange={autocompleteOnInputChange}
      renderTags={(_values: readonly string[]) =>
        _values.map((email: string) => (
          <TextFieldEmailsTag
            key={email}
            label={email}
            onDelete={() => deleteEmail(email)}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          variant="outlined"
          error={inputValue && !isValid ? true : false}
          placeholder={placeholder}
          size="small"
          fullWidth
          sx={{
            p: 0,
            '& .MuiInputBase-root': {
              p: '4px 10px !important',
            },
            '& .MuiInputBase-input': {
              m: '2px',
              paddingLeft: '0 !important',
            },
          }}
        />
      )}
    />
  )
}
