import img_link from 'assets/img/link_black.svg'

import { Box, Button, Typography } from '@mui/material'
import { ChipBeta } from 'components/chip/ChipBeta'
import { EOnboardingAction } from 'helper/consts'
import { EFeature, EWorkspacePlan } from 'helper/plan.types'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { shallowEqual, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateOnboardingAction } from 'store/slices/userSlice'
import { useAppSelector } from 'store/store'
import { getWSPlanFeatureRestriction } from 'utils/plans'
import { sessionUtils } from 'utils/session'
import { ModalCenterSmall } from '..'

export const ModalNewFeatureTemplates = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<any>()

  const { selectedTeamspace } = useAppSelector(
    (state) => state.teamspace,
    shallowEqual
  )
  const { plan_name, loading, plan_info } = useAppSelector(
    (state) => state.wsPlan,
    shallowEqual
  )
  const { selectedWorkspace } = useAppSelector(
    (state) => state.selectedWorkspace,
    shallowEqual
  )
  const { onboarding } = useAppSelector((state) => state.user, shallowEqual)

  const [open, setOpen] = useState(false)

  const handleClose = async () => {
    await dispatch(
      updateOnboardingAction({ name: EOnboardingAction.FEATURE_TEMPLATE })
    )
    setOpen(false)
  }

  // TODO: Создать контекст или редакс в котором будет хранится состояния модалок (Чтобы узнавать есть ли открытая модалка)
  useEffect(() => {
    if (window.location.pathname == '/plan') return
    if (!selectedWorkspace?.id) return
    const wsId = selectedWorkspace.id

    if (plan_info.is_trial_available) return

    const isOnboardingFinished =
      onboarding.skip || onboarding.completed_steps == 3
    if (!isOnboardingFinished) return

    if (loading.plan) return
    if (
      !sessionUtils.getModalPlanEndedHasShownValue(wsId) &&
      plan_name == EWorkspacePlan.Free
    )
      return

    const { exhausted } = getWSPlanFeatureRestriction(
      EFeature.documents_count_per_month,
      plan_info
    )

    if (
      plan_name != EWorkspacePlan.Free &&
      exhausted &&
      !sessionUtils.getModalPlanDocumentsCountLimitExhaustedHasShownValue(wsId)
    )
      return

    setOpen(!onboarding.feature_template?.finished)
  }, [onboarding, plan_name, loading, selectedWorkspace?.id, plan_info])

  if (!selectedTeamspace?.id) return null
  return (
    <ModalCenterSmall
      open={open}
      handleClose={handleClose}
      header={{
        noPaddings: true,
        children: (
          <>
            <Box
              mt={'40px'}
              component={'img'}
              width={'100%'}
              borderRadius={'4px'}
              src={
                'https://raw.githubusercontent.com/doodocs/doodocs-assets/main/templates/create_template.gif'
              }
            />
          </>
        ),
      }}
      content={{
        children: (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Typography variant="heading4">Конструктор шаблонов</Typography>
              <ChipBeta />
            </Box>

            <Typography>
              Автоматизируйте создание повторяющихся документов.
              <br />
              Отправляйте документы на заполнение клиенту.
              <br />
              Собирайте структурированные данные.
              <br />
              Подключайте интеграции.
            </Typography>
          </Box>
        ),
      }}
      footer={{
        children: (
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: '8px',
              justifyContent: isMobile ? 'initial' : 'end',
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              href="https://doodocskz.notion.site/4a45ae898fc04471bcaf30118bca516c?pvs=4"
              target="_blank"
              startIcon={<Box component={'img'} src={img_link} width={20} />}
            >
              Подробнее про шаблоны
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                await handleClose()
                navigate(`/teamspace/${selectedTeamspace.id}/templates/new`)
              }}
            >
              Создать свой шаблон
            </Button>
          </Box>
        ),
      }}
    />
  )
}
