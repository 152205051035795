import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { getRegistry } from 'service'
import { EDocumentStatus, SlugVerify } from 'helper/consts'
import { logoutUser } from '../slices/userSlice'

export interface IDocTableState {
  page: number
  filter: string
  documents: any[]
  count: number
  loading: boolean
  error: string
  is_draft_created: boolean
}

const initialState: IDocTableState = {
  page: 1,
  filter: 'all',
  documents: [],
  count: 0,
  loading: true,
  error: '',
  is_draft_created: false,
}

export const updateDocuments = createAsyncThunk(
  'documents/updateDocuments',
  async (
    {
      filter,
      page,
      teamspaceId,
      onlyOwnDocuments,
      query = '',
      author_id = '',
      filter_to_search = '',
    }: {
      filter: EDocumentStatus | string
      page: number
      teamspaceId: string
      onlyOwnDocuments: boolean
      query?: string
      author_id?: string
      filter_to_search?: string
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await getRegistry({
        page,
        filter: filter_to_search ? filter_to_search : filter,
        teamspaceId,
        onlyOwnDocuments,
        query,
        author_id,
      })
      const { documents, count } = res.data.list
      return {
        documents,
        filter,
        count,
        page,
      }
    } catch (error) {
      if (error instanceof Error) {
        if (
          error.message === SlugVerify.UNABLE_VERIFY_JWT ||
          error.message === SlugVerify.EMPTY_TOKEN
        )
          dispatch(logoutUser() as any)
        else rejectWithValue(error.message)
      }
    }
  }
)

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    clearErrors(state) {
      state.error = ''
      state.loading = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateDocuments.pending, (state) => {
        state.loading = true
      })
      .addCase(
        updateDocuments.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.documents = action.payload.documents
          state.filter = action.payload.filter
          state.count = action.payload.count
          state.page = action.payload.page
          state.loading = false
        }
      )
      .addCase(updateDocuments.rejected, (state, action) => {
        state.error = action.error.message || 'Ошибка при загрузке документов'
        state.loading = false
        state.documents = []
        state.count = 0
        state.page = 0
      })
  },
})

export const { clearErrors } = documentsSlice.actions

export const documentsReducer = documentsSlice.reducer
