import { api } from 'config'
import { LocalStorageReserve } from 'helper/consts'
import { EOnboardingAction } from 'helper/consts'
import { handleError } from 'service'

export const updateOnboardingStep = async ({
  name,
}: {
  name: EOnboardingAction
}) => {
  try {
    const resp = await api.post(
      `/users/onboarding/${name}`,
      {},
      {
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        timeout: 10000,
      }
    )
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export const resetOnboarding = async () => {
  try {
    const resp = await api.post(
      `/users/onboarding/reset`,
      {},
      {
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        timeout: 10000,
      }
    )
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export const skipOnboarding = async () => {
  try {
    const resp = await api.post(
      `/users/onboarding/skip`,
      {},
      {
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        timeout: 10000,
      }
    )
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export const getMe = async () => {
  try {
    const resp = await api.get(`/users/me`, {
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
      timeout: 10000,
    })
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export async function changeUserInfo(data: {
  personal: { first_name: string; last_name: string } | any
}) {
  try {
    const resp = await api.put(`/users/personal`, data, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
        accept: 'application/json',
      },
    })
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export const changeUserPlan = async ({ id, body }) => {
  try {
    const resp = await api.post(
      `/users/${id}/plan `,
      { body },
      {
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export async function changeSurvey(data) {
  try {
    const response = await api.put(`/users/survey`, data, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
      responseType: 'blob',
    })
    const blob = new Blob([response.data])
    const url = window.URL.createObjectURL(blob)
    return url
  } catch (error) {
    handleError(error)
  }
}

export async function changeAvatar(body) {
  try {
    const response = await api.put(`/users/avatar`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
      responseType: 'blob',
    })
    return response
  } catch (error) {
    handleError(error)
  }
}

export async function getAvatar({ id }) {
  try {
    const response = await api.get(`/users/${id}/avatar`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
      responseType: 'blob',
    })
    const blob = new Blob([response.data])
    const url = window.URL.createObjectURL(blob)
    return url
  } catch (error) {
    handleError(error)
  }
}
