import {
  EAiChatAiType,
  EAiChatMessageAuthor,
  EAiChatSystemCommand,
} from 'helper/aiChat.types'

export const getRegexAiQuestions = () => /<question>(.*?)<\/question>[ \n]?/g

export const isChatMessageAuthorAI = (
  author: EAiChatMessageAuthor | string
) => {
  return (
    author === EAiChatMessageAuthor.ai ||
    author === EAiChatMessageAuthor.aiConsultant ||
    author === EAiChatMessageAuthor.aiDocument
  )
}

export const getChatMessageAuthorByAiType = (t: EAiChatAiType | string) => {
  switch (t) {
    case EAiChatAiType.document_assistant:
      return EAiChatMessageAuthor.aiDocument
    case EAiChatAiType.legal_assistant:
      return EAiChatMessageAuthor.aiConsultant
    default:
      return EAiChatMessageAuthor.ai
  }
}

export const getChatAiMessageAuthorName = (
  t: EAiChatMessageAuthor | string
) => {
  switch (t) {
    case EAiChatAiType.document_assistant:
      return EAiChatMessageAuthor.aiDocument
    case EAiChatAiType.legal_assistant:
      return EAiChatMessageAuthor.aiConsultant
    default:
      return 'Doodocs AI'
  }
}

export const getQuestionsFromAiChatMessage = (text: string) => {
  let m: RegExpExecArray | null
  const result: string[] = []
  const rgx = getRegexAiQuestions()
  while ((m = rgx.exec(text)) !== null) {
    result.push(m[1])
  }
  return result
}

export const getRenderedMessageResponseTextWithQuestionTags = (
  text: string
) => {
  const idxOpn = text.lastIndexOf('<question>')
  const idxCls = text.lastIndexOf('</question>')

  if (idxOpn === -1 && idxCls === -1) return text
  if (idxOpn > idxCls) return text + '</question>'
  return text
}

export const isMessageTextContainQuestions = (text: string) => {
  const rgx = getRegexAiQuestions()
  const match = rgx.exec(text)
  return match != null
}

export const getChatSystemCommandByName = (
  name: string
): EAiChatSystemCommand => {
  if (name == EAiChatSystemCommand.init_document_assistant)
    return EAiChatSystemCommand.init_document_assistant

  if (name == EAiChatSystemCommand.init_legal_assistant)
    return EAiChatSystemCommand.init_legal_assistant

  return EAiChatSystemCommand.none
}

export const isRecomendedQuestionByText = (text: string) => {
  return (
    (text.startsWith('   ') || text.startsWith('\n')) &&
    isMessageTextContainQuestions(text)
  )
}
