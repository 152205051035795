import { TableCell } from '@mui/material'

export const TemplateTableCell = ({ children, ...props }: any) => (
  <TableCell
    sx={{
      height: '48px',
    }}
    {...props}
  >
    {children}
  </TableCell>
)
