import img_plus from 'assets/img/add.svg'

import { Box, Button } from '@mui/material'
import { useFormikContext } from 'formik'
import { nanoid } from 'nanoid'
import {
  FormikFormWebhookSecretKey,
  IFormikFieldWebhookSecret,
} from '../FormikFormWebhookSecretKey'

export const FormikFormWebhookAddSecretKey = () => {
  const formik = useFormikContext<IFormikFieldWebhookSecret>()

  if (formik.values.secret == null) {
    return (
      <Box>
        <Button
          onClick={() => {
            formik.setFieldValue('secret', nanoid())
          }}
          variant="text"
          color="secondary"
          startIcon={<Box component={'img'} src={img_plus} sizes="16px" />}
        >
          Секретный ключ
        </Button>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <FormikFormWebhookSecretKey />
    </Box>
  )
}
