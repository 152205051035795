import { createAsyncThunk } from '@reduxjs/toolkit'
import { ESidebarDocumentTrackingViewMode } from 'features/document-tracking'
import { EWfPostmanRecipientSubjectType } from 'helper/wfPostman.types'
import { queryGetWorkflow, editWorkflow } from 'service'

const generateAttrs = (props) => {
  const { email, message, iin, _mustCheckSign, _subjectType, bin, role } = props
  const filter: any[] = []
  if (_mustCheckSign) {
    if (_subjectType === EWfPostmanRecipientSubjectType.person)
      filter.push({ iin: iin })
    else if (_subjectType === EWfPostmanRecipientSubjectType.company)
      filter.push({ bin: bin, role: bin ? role : '' })
  }
  const attrs = {
    email,
    filter: filter,
    message,
  }
  return attrs
}

export const updateWorkflowRecipients = createAsyncThunk(
  'workflow/updateRecipients',
  async ({
    id,
    origin_id = '',
    index = 1,
    values,
    isCopy,
    setMode,
  }: {
    id: string
    origin_id: string
    index: number
    values: any
    isCopy: boolean
    setMode: (mode: string) => any
  }) => {
    try {
      const res = await queryGetWorkflow({ id })
      const { workflow } = res.data
      const { steps, cc } = workflow
      const { email } = values

      let oldMail = ''
      if (isCopy) {
        cc[index] = { email: email }
        workflow.cc = cc
      } else {
        const recipients = steps[index - 1].recipients
        const recipient = recipients.filter(
          (recipient) => recipient.origin_id === origin_id
        )
        const findIndex = recipients.findIndex(
          (recipient) => recipient.origin_id === origin_id
        )
        if (recipient.length === 0)
          throw new Error('Пожалуйста, обновите страницу')
        oldMail = recipient[0].attrs.email
        if (oldMail !== email) delete recipient[0].origin_id
        const attrs = generateAttrs(values)
        recipient[0].attrs = attrs
        recipients[findIndex] = recipient[0]
      }

      await editWorkflow({ id, data: { workflow } })
      if (oldMail !== values.email)
        setMode(ESidebarDocumentTrackingViewMode.RecipientList)
    } catch (error: any) {
      throw new Error(error.message)
    }
  }
)
