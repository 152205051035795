import { Dialog, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { ModalNewFeatureTemplates } from 'components/modal/ModalNewFeatureTemplates'
import { ModalPlanDocumentCountLimitExhausted } from 'components/modal/ModalPlanDocumentCountLimitExhausted'
import { ModalPlanEnded } from 'components/modal/ModalPlanEnded'
import { ModalTrialStarted } from 'components/modal/ModalTrialStarted'
import { AppBarContext } from 'context'
import { IconButtonHelp } from 'features/help'
import { ETemplateFilter } from 'helper/consts'
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { nonPersonalTeamspace } from 'routes/teamspaceRoute/helpers/nonPersonalTeamspace'
import { useFetchWorkspaceAndTeamspaces } from 'routes/teamspaceRoute/hooks/useFetchWorkspaceAndTeamspaces'
import { updateNotificationsCount } from 'store/slices/inboxSlice'
import { selectTeamspace } from 'store/slices/teamspaceSlice'
import { updateTemplates } from 'store/slices/templatesSlice'
import { fetchWorkspaceMembers } from 'store/slices/workspace'
import { MainMenu } from './components/main-menu'
import { SettingsMenu } from './components/settings'
import { Drawer } from './drawer'

export const DASHBOARD_MODES = {
  NORMAL: 'normal',
  SETTINGS: 'settings',
}

const searchTsExists = (teamspaces, id) => {
  if (!teamspaces) return false
  return teamspaces.some((item) => item.id === id)
}

const getTeamspaseById = (id, teamspaces) => {
  return teamspaces.find((teamspace) => teamspace.id === id)
}

const useHTMLElementHeight = (element: 'header' | 'footer') => {
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    const header = document.getElementById(element)
    if (!header) return

    setHeight(header.offsetHeight)
  }, [])

  return { height }
}

export const DashboardLayout = ({
  children,
  header: Header,
  footer: Footer,
}) => {
  const dispatch = useDispatch()
  const { workspaces } = useSelector(
    (state: RootState) => state.workspace,
    shallowEqual
  )
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )
  const { teamspaces, selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )
  const navigate = useNavigate()
  const { openDrawer, mode, setOpenDrawer } = useContext(AppBarContext)
  const { tsId } = useParams()
  const { height: headerHeight } = useHTMLElementHeight('header')
  const { height: footerHeight } = useHTMLElementHeight('footer')

  useFetchWorkspaceAndTeamspaces([
    dispatch,
    selectedWorkspace,
    workspaces,
    tsId,
  ])

  useEffect(() => {
    document.title = `${selectedWorkspace?.name || ''}/${
      selectedTeamspace?.name || ''
    } - Doodocs`
  }, [selectedTeamspace, selectedWorkspace])

  useEffect(() => {
    if (!tsId) return
    if (!tsIsValid) return

    dispatch(updateNotificationsCount() as any)
    tsId &&
      dispatch(
        updateTemplates({ ts_id: tsId, filter: ETemplateFilter.ALL }) as any
      )
  }, [tsId])

  useEffect(() => {
    dispatch(updateNotificationsCount() as any)
    dispatch(fetchWorkspaceMembers({ id: selectedWorkspace?.id || '' }) as any)
  }, [selectedWorkspace])

  const tsIsValid = useMemo(() => {
    return searchTsExists(teamspaces, tsId)
  }, [tsId, teamspaces])

  useEffect(() => {
    if (!tsId) return
    if (tsIsValid) return

    if (selectedTeamspace?.id && selectedTeamspace?.id !== tsId) {
      navigate(`/${selectedTeamspace?.id}`)
    } else if (!selectedTeamspace?.id && teamspaces.length > 0)
      dispatch(selectTeamspace(nonPersonalTeamspace(teamspaces)) as any)
  }, [tsId, selectedTeamspace, teamspaces])

  useEffect(() => {
    if (teamspaces && teamspaces.length > 0 && tsId) {
      const ts = getTeamspaseById(tsId, teamspaces)
      dispatch(selectTeamspace(ts) as any)
    }
  }, [tsId, teamspaces])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flex: '1 1 0%',
        bgcolor: 'white',
      }}
    >
      <CssBaseline />
      <Drawer variant="permanent" open={openDrawer}>
        {mode === DASHBOARD_MODES.NORMAL ? <MainMenu /> : <SettingsMenu />}
        <Dialog
          sx={{
            zIndex: 100,
          }}
          open={isMobile && openDrawer}
          onClick={() => {
            setOpenDrawer(false)
          }}
        />
      </Drawer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {Header && <Header />}
        <MainComponent
          children={children}
          headerHeight={headerHeight}
          footerHeight={footerHeight}
        />
        {Footer && <Footer />}
      </Box>
      {mode !== DASHBOARD_MODES.NORMAL && (
        <Box
          sx={{
            p: '0',
            position: 'fixed',
            right: '16px',
            bottom: '16px',
            zIndex: 100,
          }}
        >
          <Grid container justifyContent="flex-end">
            <IconButtonHelp />
          </Grid>
        </Box>
      )}
    </Box>
  )
}

const MainComponent = ({ children, headerHeight, footerHeight }) => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 0,
        flexShrink: 1,
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'white',
        height: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
        maxHeight: '100%',
        position: 'relative',
        transition: (theme) =>
          theme.transitions.create('width', {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.easeInOut,
          }),
      }}
    >
      <ModalTrialStarted />
      <ModalPlanEnded />
      <ModalPlanDocumentCountLimitExhausted />
      <ModalNewFeatureTemplates />
      {children}
    </Box>
  )
}
