export enum ERecipientAttr {
  limit = 'limit',
  filter_bin = 'filter_bin',
  filter_iin = 'filter_iin',
  message = 'messsage',
}

export enum ERecipientAttrsFilterSubjectType {
  person = 'person',
  company = 'company',
}

export interface IRecipientAttrsFilter {
  _mustCheckSign?: boolean
  _subjectType?: ERecipientAttrsFilterSubjectType

  iin?: string
  bin?: string
  role?: string
}

export interface IRecipientAttrMessage {
  message?: string
}