import { Box, Button } from '@mui/material'
import { Empty, Loading } from 'components'
import { ButtonDownload } from 'components/button/ButtonDownload'
import { EIllustration } from 'components/empty'
import { useEffect } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { queryDownloadTemplateValuesCSV } from 'service/template'
import { EManageTemplateDataStatus } from 'store/slices/manageTemplateData'
import { initialLoadManageTemplateData } from 'store/slices/manageTemplateData/initialLoadManageTemplateData'
import { useAppSelector } from 'store/store'
import { TableVariables } from './TableVariables'

// TODO:
// 1. Определенным образом хранение пермеенных (относящмиеся к группе)
// 2. Удобный формат получение перменных variables + values

export const TabContentData = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<any>()

  const { id, loading, name } = useAppSelector(
    (state) => state.template,
    shallowEqual
  )

  const { template_id, records, status } = useAppSelector(
    (state) => state.templateData,
    shallowEqual
  )

  const { selectedTeamspace } = useAppSelector(
    (state) => state.teamspace,
    shallowEqual
  )

  useEffect(() => {
    if (!id) return
    if (template_id == id) return

    dispatch(initialLoadManageTemplateData({ templateId: id }))
  }, [id, template_id])

  if (loading.info || template_id != id) {
    return (
      <Box
        sx={{
          height: '500px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading color="inherit" />
      </Box>
    )
  }

  if (records.length == 0 && status == EManageTemplateDataStatus.success) {
    return (
      <Empty
        illustration={EIllustration.empty_box}
        title={'Нет данных'}
        subtitle={'Здесь будут хранится все заполненные данные из документов'}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={() =>
            navigate(
              `/teamspace/${selectedTeamspace?.id}/documents/draft?templateId=${id}`
            )
          }
        >
          Новый документ
        </Button>
      </Empty>
    )
  }

  return (
    <Box
      sx={{
        padding: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          gap: '4px',
        }}
      >
        <ButtonDownload
          title={'Скачать CSV'}
          downloadFunc={queryDownloadTemplateValuesCSV}
          props={{ templateId: id, filename: `${name}.csv` }}
        />
      </Box>
      <Box
        sx={{
          marginTop: '16px',
          marginX: '-20px',
          paddingX: '20px',
          overflow: 'auto',

          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <TableVariables />
      </Box>
    </Box>
  )
}
