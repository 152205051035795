import { Button, Typography } from '@mui/material'
import { MessageSnackbar } from 'components'
import { config } from 'config'
import { useDownloader } from 'hooks'
import { useModal } from 'hooks/useModal'
import { ModalPaymentInvoiceDownload } from 'pages/plan/components/PlanModals/PlanPaymentGenerateInvoiceModals/ModalPaymentInvoiceDownload'
import { shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'
import { planApi } from 'service/plan'
import { useAppSelector } from 'store/store'
import { isWorkspaceOwner } from 'utils/workspace/isWorkspaceOwner'

export const ButtonProceedSubscriptionPlan = ({
  size,
  fullWidth,
}: {
  size?: 'small' | 'medium' | 'large'
  fullWidth?: boolean
}) => {
  const [modalOpen, openModal, closeModal] = useModal()
  const { download, snackbar } = useDownloader(
    planApi.getPaymentInvoiceToProceedSubscriptionPlan
  )

  const { workspace_id, payment_notification } = useAppSelector(
    (state) => state.wsPlan,
    shallowEqual
  )

  const { selectedWorkspace } = useAppSelector(
    (state) => state.selectedWorkspace,
    shallowEqual
  )
  const isOwner = isWorkspaceOwner(selectedWorkspace?.group_name)

  if (!isOwner) return null
  if (selectedWorkspace?.id != workspace_id) return null
  if (
    !payment_notification.must_pay ||
    !payment_notification.payment_type ||
    !payment_notification.payment_id
  )
    return null
  if (payment_notification.payment_type == 'CARD')
    return (
      <Link
        to={`${config.API_BASE}/workspaces/${workspace_id}/subscriptions/payments/${payment_notification.payment_id}/card`}
      >
        <Button
          variant={'contained'}
          color="success"
          size={size}
          fullWidth={fullWidth}
        >
          <Typography variant="heading6">Перейти к оплате</Typography>
        </Button>
      </Link>
    )
  if (payment_notification.payment_type == 'INVOICE')
    return (
      <>
        <Button
          variant={'contained'}
          color="success"
          size={size}
          fullWidth={fullWidth}
          onClick={() => {
            openModal()
          }}
        >
          <Typography variant="heading6">Перейти к оплате</Typography>
        </Button>

        <ModalPaymentInvoiceDownload
          open={modalOpen}
          handleClose={closeModal}
          handleClickBack={closeModal}
          handleClickDownload={async () => {
            await download({
              workspace_id: workspace_id,
              payment_id: payment_notification.payment_id ?? '',
            })
            closeModal()
          }}
        />

        <MessageSnackbar
          message={snackbar.message}
          clearMessage={snackbar.close}
          severity={snackbar.severity}
        />
      </>
    )

  return null
}
