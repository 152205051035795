import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { MessageSnackbar } from 'components'
import {
  FormikFormClosingDocuments,
  IFormikFieldsClosingDocuments,
  getFormClosingDocumentsValidationSchema,
} from 'components/forms/FormikFormClosingDocuments'
import {
  FormikFormPaymentRequisites,
  IFormikFieldsPaymentRequisites,
  getFormPaymentRequisitesValidationSchema,
} from 'components/forms/FormikFormPaymentRequisites'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { shallowEqual, useDispatch } from 'react-redux'
import { planApi } from 'service/plan'
import { wsPlanSlice } from 'store/slices/wsPlan'
import { useAppSelector } from 'store/store'

export const ModalPaymentRequisitesChangeForm = ({
  open,
  handleClose,
}: {
  open: boolean
  handleClose: () => void
}) => {
  const [error, setError] = useState('')

  const { selectedWorkspace } = useAppSelector(
    (state) => state.selectedWorkspace,
    shallowEqual
  )
  const { requisites, require_closing_documents } = useAppSelector(
    (state) => state.wsPlan,
    shallowEqual
  )
  const dispatch = useDispatch<any>()

  const formik = useFormik<
    IFormikFieldsPaymentRequisites & IFormikFieldsClosingDocuments
  >({
    initialValues: {
      bin: requisites?.bin ?? '',
      company_name: requisites?.company_name ?? '',
      legal_address: requisites?.legal_address ?? '',
      require_closing_documents: require_closing_documents,
    },
    validationSchema: getFormPaymentRequisitesValidationSchema().concat(
      getFormClosingDocumentsValidationSchema()
    ),
    onSubmit: async (values) => {
      try {
        if (!selectedWorkspace?.id)
          throw new Error('Нет выбранного пространства')
        await planApi.patchChangePaymentRequisits({
          workspace_id: selectedWorkspace.id,
          data: {
            require_closing_documents: values.require_closing_documents,
            requisites: {
              bin: values.bin,
              company_name: values.company_name,
              legal_address: values.legal_address,
            },
          },
        })
        dispatch(wsPlanSlice.actions.setRequisites(values))
        handleClose()
      } catch (err: any) {
        console.error(err)
        setError(err.message)
      }
    },
    enableReinitialize: true,
  })

  return (
    <ModalCenterSelectableWithTitle
      open={open}
      handleClose={handleClose}
      modalType="small"
      title={'Реквизиты'}
      subtitle={'Они будут указаны в счете и закрывающем документе'}
      contentChild={
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <FormikFormPaymentRequisites />
              <FormikFormClosingDocuments />

              <MessageSnackbar
                severity={'error'}
                message={error}
                clearMessage={() => setError('')}
              />
            </Box>
          </form>
        </FormikProvider>
      }
      footerChild={
        <form onSubmit={formik.handleSubmit}>
          <Box
            display={'flex'}
            flexWrap={isMobile ? 'wrap' : 'nowrap'}
            justifyContent={'end'}
            alignItems={'center'}
            gap={'8px'}
          >
            <Button
              onClick={handleClose}
              color="secondary"
              fullWidth={isMobile}
            >
              Отмена
            </Button>
            <Button
              fullWidth={isMobile}
              type="submit"
              color="primary"
              variant="contained"
              disabled={formik.isSubmitting || !selectedWorkspace}
            >
              {formik.isSubmitting && (
                <CircularProgress color="inherit" sx={{ mr: '4px' }} />
              )}
              <Typography>Сохранить</Typography>
            </Button>
          </Box>
        </form>
      }
    />
  )
}
