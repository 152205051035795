import { Box } from '@mui/material'
import { FilterStatus } from './filter-status'
import { FilterAuthor } from './filter-author'
import { ResetFilter } from './reset-filter'
import { SearchField } from './search-field'
import { EDocumentStatus } from 'helper/consts'
import { useSearchBarContext } from 'context'

export const SearchBar = ({
  filter,
  showStatusFilter = true,
  showAuthorFilter = true,
}: {
  filter: EDocumentStatus
  showStatusFilter?: boolean
  showAuthorFilter?: boolean
}) => {
  const { authorQuery, statusQuery, queryString } = useSearchBarContext()

  return (
    <Box>
      <Box display={'flex'} flexDirection={{ xs: 'column', sm: 'row' }}>
        <Box mr={1}>
          <SearchField filter={filter} />
        </Box>
        <Box display={'flex'} mt={{ xs: 1, sm: 0 }}>
          {showStatusFilter && (
            <Box mr={1}>
              <FilterStatus />
            </Box>
          )}
          {showAuthorFilter && (
            <Box mr={1}>
              <FilterAuthor />
            </Box>
          )}
          {(authorQuery || statusQuery || queryString) && <ResetFilter />}
        </Box>
      </Box>
    </Box>
  )
}
