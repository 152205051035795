import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, IconButton, TableRow, Typography } from '@mui/material'
import { CustomTooltip, StatusBadge } from 'components'
import { TableCompound } from 'components/table/table-compound/TableCompound'
import { memo } from 'react'
import { canCreateDocFromTemplate, canUseTemplate } from 'utils'
import { TemplateActionsCell } from '../TemplateActionsCell'
import { TemplateTableCell } from '../TemplateTableCell'
import { СreateDocFromTemplateButton } from '../create-doc-from-template-button'
import { EditTemplateButton } from '../edit-template-button'

import img_template from 'assets/img/template.svg'

const TableCellName = ({ name, ...props }) => (
  <Typography
    maxWidth={'240px'}
    fontWeight={500}
    sx={{ textWrap: 'nowrap', wordBreak: 'keep-all' }}
    textOverflow="ellipsis"
    overflow="hidden"
    color="text.primary"
    {...props}
  >
    {name}
  </Typography>
)

export const TableRowComponent = memo(
  ({
    template,
    handleClickNavigateTemplate,
    open,
    setOpen,
    anchorEl,
    setAnchorEl,
  }: {
    template: ITemplate
    handleClickNavigateTemplate: (e: any, row: ITemplate) => void
    open: boolean
    setOpen: (open: boolean) => void
    anchorEl: null | HTMLElement
    setAnchorEl: (anchorEl: null | HTMLElement) => void
  }) => {
    const handleClickOptions = (event: any) => {
      event.preventDefault()
      event.stopPropagation()
      setAnchorEl(event.currentTarget)
      setOpen(true)
    }

    return (
      <TableRow
        className="template-row"
        sx={{
          cursor: canUseTemplate(template) ? 'pointer' : '',
          '&:hover': {
            background: canUseTemplate(template) ? '#F5F5F5' : '',
            '& .template-edit-button': {
              backgroundColor: 'white', // This will keep the button's background white
            },
          },
        }}
        onClick={(e) =>
          canUseTemplate(template) && handleClickNavigateTemplate(e, template)
        }
      >
        <TemplateTableCell sx={{ pl: 0 }} color="secondary">
          <Box display={'flex'}>
            <Box component={'img'} src={img_template} mr={1} />
            <TableCellName name={template.name} />
          </Box>
        </TemplateTableCell>
        <TemplateTableCell align="center">
          {template.total_documents}
        </TemplateTableCell>
        <TemplateTableCell>
          <StatusBadge status={template.status} />
        </TemplateTableCell>
        <TableCompound.BodyCellDocumentLastModified
          lastActionAt={template.updated_at}
        />
        <TemplateActionsCell>
          <EditTemplateButton row={template} />
        </TemplateActionsCell>
        <TemplateActionsCell>
          {canCreateDocFromTemplate(template) ? (
            <СreateDocFromTemplateButton row={template} />
          ) : (
            <Box height={'24px'} />
          )}
        </TemplateActionsCell>
        <TableCompound.BodyCellDocumentOptions>
          <Box display={'flex'}>
            <CustomTooltip title="Действия">
              <IconButton
                onMouseEnter={(e) => e.stopPropagation()}
                onMouseLeave={(e) => e.stopPropagation()}
                onClick={handleClickOptions}
                id={`${template?.id}`}
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{
                  p: '6px !important',
                  borderRadius: '8px',
                  background:
                    open && anchorEl?.id === `${template.id}`
                      ? 'rgba(107, 114, 128, 0.04)'
                      : 'none',
                }}
                children={
                  <MoreHorizIcon
                    sx={{
                      width: '20px',
                      height: '20px',
                      color: '#3C4149',
                    }}
                  />
                }
              />
            </CustomTooltip>
          </Box>
        </TableCompound.BodyCellDocumentOptions>
      </TableRow>
    )
  }
)
