import img_add from 'assets/img/add.svg'
import img_copy from 'assets/img/content_copy.svg'
import img_delete from 'assets/img/delete_outline_red.svg'
import img_move from 'assets/img/drive_file_move.svg'
import img_edit from 'assets/img/edit.svg'

import { ActionMenu } from 'components/action-menu/actionMenu'

export type TMenuTemplateActions =
  | 'use'
  | 'edit'
  | 'move'
  | 'dublicate'
  | 'delete'

export type TMenuTemplateActionsPropActions = {
  [K in TMenuTemplateActions]?: {
    hidden?: boolean
    disabled?: boolean
    action: () => any
  }
}

export interface IMenuTemplateActionsProps {
  anchorEl: any
  handleClose: () => void
  open: boolean
  actions: TMenuTemplateActionsPropActions
}

export const MenuTemplateActions = ({
  anchorEl,
  handleClose,
  open,
  actions,
}: IMenuTemplateActionsProps) => {
  return (
    <ActionMenu
      anchorEl={anchorEl}
      open={open}
      handleClose={handleClose}
      actions={[
        [
          !!actions.use &&
            !actions.use.hidden && {
              src: img_add,
              title: 'Использовать',
              disabled: actions.use.disabled,
              action: async () => {
                handleClose()
                await actions.use?.action()
              },
            },
          !!actions.edit &&
            !actions.edit.hidden && {
              src: img_edit,
              title: 'Изменить',
              disabled: actions.edit.disabled,
              action: async () => {
                handleClose()
                await actions.edit?.action()
              },
            },
          !!actions.move &&
            !actions.move.hidden && {
              src: img_move,
              title: 'Переместить',
              disabled: actions.move.disabled,
              action: async () => {
                handleClose()
                await actions.move?.action()
              },
            },
          !!actions.dublicate &&
            !actions.dublicate.hidden && {
              src: img_copy,
              title: 'Дубликат',
              disabled: actions.dublicate.disabled,
              action: async () => {
                handleClose()
                await actions.dublicate?.action()
              },
            },
        ],
        [
          !!actions.delete &&
            !actions.delete.hidden && {
              src: img_delete,
              title: 'Удалить',
              disabled: actions.delete.disabled,
              action: async () => {
                handleClose()
                await actions.delete?.action()
              },
            },
        ],
      ]}
    />
  )
}
