import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import ToolbarPlugin from './plugins/ToolbarPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin'
import { TRANSFORMERS } from '@lexical/markdown'
import TreeViewPlugin from './plugins/TreeViewPlugin'
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin'
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin'
import AutoLinkPlugin from './plugins/AutoLinkPlugin'
import ComponentPicker from './plugins/ComponentPickerPlugin'
import VariablePlugin from './plugins-custom/VariablePlugin'
import { HandleVariableDeletePlugin } from './plugins-custom/HandleVariableDeletePlugin'
import SaveAfterChangePlugin from './plugins-custom/SaveAfterChangePlugin'
import LoadHtmlContentPlugin from './plugins-custom/LoadHtmlContentPlugin'
import DraggableBlockPlugin from './plugins/DraggableBlockPlugin'
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin'
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'
import { TablePlugin } from '@lexical/react/LexicalTablePlugin'
import TableCellResizerPlugin from './plugins/TableCellResizer'
import ParameterPickerPlugin from './plugins-custom/ParameterPickerPlugin'
import { EditorNodes } from './nodes/collections/EditorNodes'
import { TableCellNodes } from './nodes/collections/TableCellNodes'
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin'

import 'custom-lexical/assets/styles/index.css'
import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin'
import PageBreakPlugin from './custom/plugins/PageBreakPlugin'
import { ColumnsListPlugin } from './custom/plugins/ColumnsListPlugin'
import { PlaceholderPlugin } from './custom/plugins/PlaceholderPlugin'
import { useState } from 'react'
import LinkPlugin from './plugins/LinkPlugin'

const editorConfig = {
  namespace: '',
  theme: PlaygroundEditorTheme,
  nodes: [...EditorNodes],
  onError(error: any) {
    throw error
  },
}

export default function EditorLexical({
  hasTemplateVariables,
}: {
  hasTemplateVariables?: boolean
}) {
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false)
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<
    HTMLElement | undefined
  >(undefined)

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (!_floatingAnchorElem) return
    setFloatingAnchorElem(_floatingAnchorElem)
  }

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container">
        <div id="editor-portal" ref={onRef}></div>
        <ToolbarPlugin />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={null}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <>
            <SaveAfterChangePlugin />
            <LoadHtmlContentPlugin />
            <ParameterPickerPlugin
              hasTemplateVariables={hasTemplateVariables}
            />
            {hasTemplateVariables && <VariablePlugin />}
            {hasTemplateVariables && <HandleVariableDeletePlugin />}
            {floatingAnchorElem && (
              <ColumnsListPlugin anchorElem={floatingAnchorElem} />
            )}
            {floatingAnchorElem && (
              <PlaceholderPlugin
                text={
                  hasTemplateVariables
                    ? 'Введите ‘/’ для команд, ‘=’ для переменных...'
                    : 'Введите ‘/’ для команд...'
                }
                anchorElem={floatingAnchorElem}
              />
            )}
          </>
          <PageBreakPlugin />
          {floatingAnchorElem && (
            <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
          )}
          {floatingAnchorElem && (
            <FloatingLinkEditorPlugin
              anchorElem={floatingAnchorElem}
              isLinkEditMode={isLinkEditMode}
              setIsLinkEditMode={setIsLinkEditMode}
            />
          )}
          <HistoryPlugin />
          {/* <TreeViewPlugin /> */}
          {/* <AutoFocusPlugin /> */}
          <CodeHighlightPlugin />
          {floatingAnchorElem && (
            <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
          )}
          <TabIndentationPlugin />
          <ListPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <LinkPlugin />
          <AutoLinkPlugin />
          <ComponentPicker />
          <HorizontalRulePlugin />
          <TablePlugin
            hasCellBackgroundColor={true}
            hasCellMerge={true}
            hasTabHandler={true}
          />
          <TableCellResizerPlugin />
          {floatingAnchorElem && (
            <TableCellActionMenuPlugin
              cellMerge={true}
              anchorElem={floatingAnchorElem}
            />
          )}
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        </div>
      </div>
    </LexicalComposer>
  )
}
