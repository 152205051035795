import img_close from 'assets/img/ic_close.svg'

import { Box, Typography, IconButton, Divider } from '@mui/material'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'

export const sidebarWidth = isMobile ? '100vw' : '370px'

export interface SidebarHeaderProps {
  titlePrefixBtn?: any
  title: string
  subtitle?: string
  handleClose?: (e?: any) => any
  titleSuffixBtns?: any[]
}

const SidebarHeader = ({
  titlePrefixBtn,
  title,
  subtitle,
  handleClose,
  titleSuffixBtns,
}: SidebarHeaderProps) => {
  const [headerLeftBtnsWidth, setHeaderLeftBtnsWidth] = useState(0)
  const [headerRightBtnsWidth, setHeaderRightBtnsWidth] = useState(0)

  return (
    <Box
      sx={{
        position: 'relative',
        p: '12px 12px 12px 16px',
      }}
    >
      {!!titlePrefixBtn && (
        <Box
          sx={{
            position: 'absolute',
            top: { sm: '12px', xs: '6px' },
            left: { sm: '16px', xs: '8px' },
          }}
          ref={(ref: any) => {
            const width = ref?.clientWidth ?? 0
            if (width != 0) {
              setHeaderLeftBtnsWidth(width + 4)
              return
            }
            setHeaderLeftBtnsWidth(0)
          }}
        >
          {titlePrefixBtn}
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',

          ml: `${headerLeftBtnsWidth}px`,
          maxWidth: `calc(100% - ${headerRightBtnsWidth}px - ${headerLeftBtnsWidth}px)`,
        }}
      >
        <Typography variant="heading6" mt={!subtitle ? '2px' : undefined}>
          {title}
        </Typography>
        {subtitle ? (
          <Typography
            color={'text.disabled'}
            variant="paragraph4"
            sx={{
              mt: '2px',
            }}
          >
            {subtitle}
          </Typography>
        ) : null}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          position: 'absolute',
          top: { sm: '12px', xs: '6px' },
          right: { sm: '12px', xs: '8px' },
        }}
        ref={(ref: any) => {
          setHeaderRightBtnsWidth(ref?.clientWidth ?? 0)
        }}
      >
        {titleSuffixBtns?.map((Btn, i) => {
          return <Box key={`sidebar-header-btn-${i}`}>{Btn}</Box>
        })}
        <IconButton
          sx={{
            p: '4px',
            borderRadius: '4px',
            height: 'fit-content',
          }}
          onClick={handleClose}
        >
          <Box
            component={'img'}
            src={img_close}
            sx={{
              width: '16px',
              height: '16px',
            }}
          />
        </IconButton>
      </Box>
    </Box>
  )
}

export const SidebarLayout = ({
  header,
  children,
}: {
  header: SidebarHeaderProps
  children: any
}) => {
  return (
    <>
      {!isMobile && (
        <Divider
          orientation="vertical"
          sx={{
            position: 'fixed',
            top: 0,
            right: sidebarWidth,
            zIndex: 1,
          }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'auto',

          background: 'white',
          width: sidebarWidth,
        }}
      >
        <SidebarHeader {...header} />
        <Box
          sx={{
            flex: 1,
            overflow: 'auto',
            maxWidth: '100%',
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  )
}
