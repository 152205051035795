import dayjs from 'dayjs'
import { useFormikContext } from 'formik'
import { UrlKeys } from 'helper/consts'
import { useDebounce } from 'hooks/useDebounce'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isObjectEmpty } from 'routes/teamspaceRoute/helpers/isObjectEmpty'
import { createNewDocument } from 'store/slices/applicationSlice'
import { updateDraftVariables } from 'store/slices/templateSlice'
import { removeEmptyFromObject } from 'utils/remove-empty'

export const useFillerFormFormik = () => {
  const { urlId } = useUrlIdParam(UrlKeys.documentId)
  const { tsId } = useParams()
  const { values, setFieldValue, errors } = useFormikContext<any>()

  const debounce = useDebounce()

  const dispatch = useDispatch()
  const {
    document: file,
    variables,
    template,
  } = useSelector((state: RootState) => state.application, shallowEqual)

  const createDocument = async () => {
    await dispatch(
      createNewDocument({
        name: file.name || template?.name,
        template_id: template?.id,
        tsId,
        urlId,
      }) as any
    )
  }

  const updateValue = ({ name, value }) => {
    setFieldValue(name, value)

    if (!file.id && !urlId.get() && !isObjectEmpty(errors)) return

    const valuesWithoutEmpty = removeEmptyFromObject({
      ...values,
      [name]: value,
    })
    if (isObjectEmpty(valuesWithoutEmpty)) return

    debounce(() => {
      dispatch(
        updateDraftVariables({
          values: valuesWithoutEmpty,
          document_id: file.id || urlId.get(),
        }) as any
      )
    }, 300)
  }

  const handleCommonLogic = (
    name: string,
    formikValue: string | number | Array<string>,
    htmlValue?: string | number
  ) => {
    updateValue({ name, value: formikValue })
    const nodes = document.getElementsByClassName(`form-variable ${name}`)
    for (const node of nodes) {
      ;(node as HTMLElement).innerText =
        htmlValue || (variables ? variables[name]?.name : '')
    }
  }

  const handleTouchStart = async (e: React.TouchEvent | any) => {
    if (!urlId.get()) {
      await createDocument()
    }

    const { name } = e.target as HTMLInputElement

    const nodes: HTMLCollection = document.getElementsByClassName(
      `form-variable ${name}`
    )
    const allNodes: HTMLCollection =
      document.getElementsByClassName(`form-variable`)

    Object.entries(allNodes).forEach(([, node]) => {
      ;(node as HTMLElement).removeAttribute('id')
    })

    Object.entries(nodes).forEach(([, node]) => {
      ;(node as HTMLElement).setAttribute('id', 'edit')
    })
  }

  const handleBlur = (e) => {
    e.preventDefault()

    const allNodes: HTMLCollection =
      document.getElementsByClassName(`form-variable`)
    Object.entries(allNodes).forEach(([, node]) => {
      ;(node as HTMLElement).removeAttribute('id')
    })
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target

    handleCommonLogic(name, value, value)
  }

  const handleChangeCheckbox = async (e: React.ChangeEvent<any>) => {
    const { name, checked } = e.target

    if (!urlId.get()) {
      await createDocument()
    }

    updateValue({ name, value: checked })

    const nodes: HTMLCollection = document.getElementsByClassName(
      `form-variable ${name}`
    )

    Object.entries(nodes).forEach(([, node]) => {
      if (checked) node?.setAttribute('checked', 'true')
      else node?.removeAttribute('checked')
    })
  }

  const handleChangeNumber = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target
    handleCommonLogic(name, parseInt(value) || 0, value)
  }

  const handleChangeSelect = async (e: any) => {
    if (!urlId.get()) {
      await createDocument()
    }

    const { name, value } = e.target
    const showInHtmlValue = (variables && variables[value]?.attrs.text) || ''

    handleCommonLogic(name, [value], showInHtmlValue)
    handleBlur(e)
  }

  const handleChangeDate = async ({
    id,
    value,
  }: {
    id: string
    value: any
  }) => {
    if (!urlId.get()) {
      await createDocument()
    }
    const formattedValue = dayjs(value).format('YYYY-MM-DD')
    handleCommonLogic(id, formattedValue, value)
  }

  return {
    handleTouchStart,
    handleBlur,
    handleChange,
    handleChangeSelect,
    handleChangeDate,
    handleChangeNumber,
    handleChangeCheckbox,
  }
}
