import { useState } from 'react'

export const usePasswordToggler = () => {
  const [type, setType] = useState('password')

  const toggleVisibility = () => {
    if (type === 'password') {
      setType('text')
    } else if (type === 'text') {
      setType('password')
    }
  }
  return {
    type,
    toggleVisibility,
  }
}
