import { DASHBOARD_MODES } from 'layout/dashboard-layout'
import { createContext, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router-dom'

type AppBarContextType = {
  openDrawer: boolean
  setOpenDrawer?: any
  mode: string
  setMode?: any
}

export const AppBarContext = createContext<AppBarContextType>({
  openDrawer: false,
  mode: '',
})

export const AppBarProvider = ({ children }) => {
  const [openDrawer, setOpenDrawer] = useState(isMobile ? false : true)
  const { pathname } = useLocation()

  const isSettings = useMemo(() => {
    const pathWords = pathname.split('/')
    const word = pathWords[pathWords.length - 1]

    return (
      word &&
      (word === 'members' ||
        word === 'settings' ||
        word === 'profile' ||
        word === 'payment' ||
        word === 'plan' ||
        word === 'api')
    )
  }, [pathname])

  const [mode, setMode] = useState(
    isSettings ? DASHBOARD_MODES.SETTINGS : DASHBOARD_MODES.NORMAL
  )
  return (
    <AppBarContext.Provider
      value={{
        openDrawer,
        setOpenDrawer,
        mode,
        setMode,
      }}
    >
      {children}
    </AppBarContext.Provider>
  )
}
