import img_download from 'assets/img/download.svg'

import { MenuItemButton } from 'components/menu-item/menu-item-button'
import { MessageSnackbar } from 'components/snackbar'
import { useDownloader } from 'hooks/useDownloader'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getDDCLink, getPdfFileByLink } from 'service'
import { setDocumentLinkDDC } from 'store/slices/applicationSlice'

const getDocumentDDCLinkLongPullingFuncitons = () => {
  let reject = false

  const startPulling = async ({
    documentId,
    onPullingSuccessFinished,
  }: {
    documentId: string
    onPullingSuccessFinished: (link: string) => any
  }) => {
    let loaded = false
    while (!loaded && !reject) {
      try {
        const resp = await getDDCLink({ documentId })
        loaded = true
        await onPullingSuccessFinished(resp.data.link)
      } catch {
        await new Promise((resolve) => setTimeout(resolve, 5000))
      }
    }
  }
  const cancelPulling = () => {
    reject = true
  }

  return { startPulling, cancelPulling }
}

export const MenuItemButtonDocumentDownloadDDCard = ({
  documentId,
  linkDDC,
  filename,
}: {
  documentId: string
  linkDDC: string
  filename: string
}) => {
  const { download, loading, error, setError } = useDownloader(getPdfFileByLink)
  const dispatch = useDispatch<any>()

  useEffect(() => {
    if (linkDDC) return

    const { startPulling, cancelPulling } =
      getDocumentDDCLinkLongPullingFuncitons()

    startPulling({
      documentId: documentId,
      onPullingSuccessFinished: (link: string) => {
        dispatch(setDocumentLinkDDC(link))
      },
    })

    return () => {
      cancelPulling()
    }
  }, [linkDDC, documentId])

  if (!documentId) return null
  return (
    <>
      <MessageSnackbar
        message={error}
        clearMessage={() => setError('')}
        severity="error"
      />
      <MenuItemButton
        srcImage={img_download}
        title={
          !linkDDC
            ? 'Готовится для скачивания...'
            : loading
            ? 'Идет скачивание...'
            : 'Скачать документ'
        }
        disabled={loading || !linkDDC}
        onClick={async () => {
          await download({ link: linkDDC }, filename)
        }}
      />
    </>
  )
}
