import { Box, Button, Typography } from '@mui/material'
import img_arrow from 'assets/img/id_arrow-right.svg'

export const ButtonQuestion = ({ ...props }) => {
  return (
    <Button
      color="secondary"
      variant="outlined"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#FFFFFF',
        textAlign: 'left',
        padding: '2px 4px',
        my: '4px',
        ...props.sx,
      }}
      {...props}
    >
      <Typography>{props.children}</Typography>
      <Box
        component={'img'}
        marginLeft={'4px'}
        src={img_arrow}
        alt="arrow-right"
        sx={{
          width: { sm: '16px', xs: '20px' },
          height: { sm: '16px', xs: '20px' },
          opacity: props.disabled ? '0.5' : '1',
        }}
      />
    </Button>
  )
}
