import { Box } from '@mui/material'
import { PlanModals } from './components/PlanModals'
import { PlanPeriodSelection } from './components/PlanPeriodSelection'
import { Plans } from './components/Plans'
import { SelectPlanProvider } from './components/SelectPlanProvider'
import { withDashboardLayout } from 'layout'
import { PlansAppbar } from './components/header/idnex'

const PlanContent = () => {
  return (
    <>
      <Box p={{ xs: '16px 16px 64px 16px', sm: '16px 0px 64px 40px' }}>
        <SelectPlanProvider>
          <PlanPeriodSelection />
          <Box mt={'16px'} sx={{ overflowX: 'scroll' }}>
            <Plans />
          </Box>
          <PlanModals />
        </SelectPlanProvider>
      </Box>
    </>
  )
}

export const Plan = () =>
  withDashboardLayout(PlanContent)({
    Header: PlansAppbar,
  })
