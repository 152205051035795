import { IOSSwitch } from 'components'
import { MenuItemButton, MenuItemButtonProps } from '.'

export const MenuItemButtonWithToggle = ({
  title,
  description,
  srcImage,
  onClick,
  imgSize,
  disabled,
  checked,
}: MenuItemButtonProps & {
  checked: boolean
}) => {
  return (
    <MenuItemButton
      title={title}
      description={description}
      renderTitleSuffix={<IOSSwitch checked={checked} disabled={disabled} />}
      srcImage={srcImage}
      imgSize={imgSize}
      onClick={onClick}
      disabled={disabled}
    />
  )
}
