import img_link from 'assets/img/menu-send/link.svg'
import img_email from 'assets/img/menu-send/email.svg'
import img_draw from 'assets/img/menu-send/draw.svg'

import { Typography, Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getWSPlanFeatureRestriction } from 'utils/plans'
import { EFeature } from 'helper/plan.types'
import { MenuItemButton } from 'components/menu-item/menu-item-button'
import { isMobile } from 'react-device-detect'
import { SignMobile } from '../sign-mobile'
import { ModalPlanLimit } from 'components/modal/ModalPlanLimit'
import { IWSPlan } from 'store/slices/wsPlan'

export const SendMenu = ({
  handleClose,
  setOnlyMe,
  setCreateLink,
  setOpenModal,
  driverObj,
}) => {
  const { plan_info }: IWSPlan = useSelector(
    (state: RootState) => state.wsPlan,
    shallowEqual
  )

  const [limitOn, setLimitOn] = useState<EFeature | undefined>()

  useEffect(() => {
    const { prohibited, exhausted } = getWSPlanFeatureRestriction(
      EFeature.documents_count_per_month,
      plan_info
    )

    setLimitOn(
      prohibited || exhausted ? EFeature.documents_count_per_month : undefined
    )
  }, [plan_info])

  if (limitOn) {
    return (
      <ModalPlanLimit
        limitOn={limitOn ?? EFeature.ai_chat}
        open={!!limitOn}
        handleClose={handleClose}
      />
    )
  }

  return (
    <>
      <Box
        sx={{
          p: '4px 8px',
        }}
      >
        <Typography variant="body2" color={'text.disabled'}>
          Отправить документ
        </Typography>
      </Box>
      <MenuItemButton
        onClick={() => {
          const { prohibited, exhausted } = getWSPlanFeatureRestriction(
            EFeature.document_send_by_email,
            plan_info
          )
          if (prohibited || exhausted) {
            setLimitOn(
              prohibited || exhausted
                ? EFeature.document_send_by_email
                : undefined
            )
            return
          }

          handleClose()
          setOpenModal(true)
        }}
        srcImage={img_email}
        title={'Отправить на электронную почту'}
        description={'Можно добавить подписантов, согласующих, отслеживающих'}
      />
      <div id={'create-link'}>
        <MenuItemButton
          onClick={() => {
            setCreateLink(true)
            driverObj &&
              setTimeout(() => {
                driverObj.moveNext()
              }, 200)
          }}
          srcImage={img_link}
          title={'Создать ссылку для подписания'}
          description={
            'Используйте если знаете количество подписантов и вам нужно только подписание'
          }
        />
      </div>
      <Typography
        variant="body2"
        color={'text.disabled'}
        key={'send-doc-short'}
        sx={{ p: '12px 8px 4px 8px' }}
      >
        Быстрое подписание
      </Typography>
      {isMobile ? (
        <SignMobile isMenu={true} disabled={false} />
      ) : (
        <MenuItemButton
          onClick={() => {
            setOnlyMe(true)
          }}
          srcImage={img_draw}
          title={'Подписываю только я'}
        />
      )}
    </>
  )
}
