import { useState } from 'react'

export const useModal = (AnalyticsEventTrigger) => {
  const [openModal, setOpenModal] = useState(false)

  const handleClose = () => setOpenModal(false)

  const handleClick = (e) => {
    AnalyticsEventTrigger && AnalyticsEventTrigger()
    e && e.stopPropagation && e.stopPropagation()
    setOpenModal(true)
  }

  return { openModal, handleClose, handleClick }
}
