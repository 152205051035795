import { TableCell } from '@mui/material'

export const PaymentsTableCell = ({
  children,
  alignRight = true,
  ...props
}) => (
  <TableCell
    sx={{ padding: '10px', ...(alignRight ? { textAlign: 'right' } : {}) }}
    {...props}
  >
    {children}
  </TableCell>
)
