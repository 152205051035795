export type WSRole = 'ws-owner' | 'ws-member'
export type TSRole = 'ts-owner' | 'ts-member' | 'ts-personal'

export const WS_ROLES: { [key: string]: WSRole } = {
  owner: 'ws-owner',
  member: 'ws-member',
}

export const TS_ROLES: { [key: string]: TSRole } = {
  owner: 'ts-owner',
  member: 'ts-member',
  personal: 'ts-personal',
}

export const WSRolePermissions: Record<WSRole, IWorkspacePermissions> = {
  'ws-owner': {
    manageWorkspaceInfo: true,
    inviteToWs: true,
    createTeamspace: true,
    manageDocuments: true,
    manageTemplates: true,
    manageMembers: true,
    managePlan: true,
    canAccessCampaigns: true,
  },
  'ws-member': {
    manageWorkspaceInfo: false,
    inviteToWs: false,
    createTeamspace: false,
    manageDocuments: false,
    manageTemplates: false,
    manageMembers: false,
    managePlan: false,
    canAccessCampaigns: false,
  },
}

export const TSRolePermissions: Record<TSRole, ITeamspacePermissions> = {
  'ts-owner': {
    manageTeamspaceInfo: true,
    deleteTeamspace: true,
    canManageMemberGroups: [TS_ROLES.member, TS_ROLES.owner],
    canManageDocs: true,
    canDeleteStrangerDocuments: true,
    canRevokeStrangerDocuments: true,
  },
  'ts-member': {
    manageTeamspaceInfo: true,
    deleteTeamspace: false,
    canManageMemberGroups: [TS_ROLES.member],
    canManageDocs: true,
    canDeleteStrangerDocuments: false,
    canRevokeStrangerDocuments: false,
  },
  'ts-personal': {
    manageTeamspaceInfo: true,
    deleteTeamspace: true,
    canManageMemberGroups: [TS_ROLES.member, TS_ROLES.owner],
    canManageDocs: true,
    canDeleteStrangerDocuments: true,
    canRevokeStrangerDocuments: true,
  },
}
