import img_account from 'assets/img/account.svg'
import img_link from 'assets/img/hyperlink.svg'
import img_email from 'assets/img/ic_email.svg'

import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from '@dnd-kit/sortable'
import { Box, Menu, Typography } from '@mui/material'
import { MenuItemButton } from 'components/menu-item/menu-item-button'
import { MenuItemButtonWithToggle } from 'components/menu-item/menu-item-button/MenuItemButtonWithToggle'
import { EmailFieldWithMenu } from 'components/text-fields/EmailFieldWithMenu'
import { ERecipientRole } from 'helper/consts'
import { EServiceWorkflowStepType } from 'helper/workflow.service.types'
import { EWorkflowMode } from 'helper/workflow/CWorkflow'
import { CWorkflowHelper } from 'helper/workflow/CWorkflowHelper'
import { IActor } from 'helper/workflow/IWorkflow'
import { CRecipientAnonymousSignerRK } from 'helper/workflow/recipients/CRecipientAnonymousSignerRK'
import { CRecipientSignerRK } from 'helper/workflow/recipients/CRecipientSignerRK'
import { IRecipient } from 'helper/workflow/recipients/IRecipient'
import { useContext, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getObjectClone } from 'utils/getObjectClone'
import { isValidEmail } from 'utils/isValidEmail'
import { getFillerSrcByActorId } from 'utils/workflow/getFillerSrcByActorId'
import { WorfklowBuilderUIContext } from '../../../../providers/WorkflowBuilderUIContext'
import { IconButtonCirclePlus } from '../IconButtonCirclePlus'
import { RecipientBadge } from '../RecipientBadge'
import { RecipientsPartition } from '../RecipientsPartition'
import { RecipientsSignerMenu } from '../RecipientsSignerMenu'
import { usePopupHelpers } from '../usePopupHelpers'

export const RecipientsSigners = () => {
  const workflowBuilderUI = useContext(WorfklowBuilderUIContext)
  const mhSettings = usePopupHelpers()
  const mhRecipient = usePopupHelpers()
  const [selectedRecipient, setSelectedRecipient] = useState<IRecipient>()
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const emails = useMemo(() => {
    const state = workflowBuilderUI.workflow

    const signers = CWorkflowHelper.GetRecipientsWithRole(state, [
      ERecipientRole.signer_rk,
      ERecipientRole.anonymous_signer_rk,
    ])

    signers.forEach((s, index) => {
      if (s.role == ERecipientRole.anonymous_signer_rk) {
        if (!s.attrs.link_name) s.attrs.link_name = `Ссылка ${index + 1}`
      }
    })
    return signers as (CRecipientSignerRK | CRecipientAnonymousSignerRK)[]
  }, [workflowBuilderUI])

  const getRecipientElement = (recipient: IRecipient, idx: number) => {
    const stepNumber = recipient._visible_step_number ?? -1

    if (!!recipient.actor_id) {
      return (
        <RecipientBadge
          key={`actor_id:${recipient.actor_id}`}
          imgSrc={getFillerSrcByActorId(recipient.actor_id)}
          text={
            workflowBuilderUI.workflow.actors?.find(
              (actor) => actor.id === recipient.actor_id
            )?.title ?? '???'
          }
          sortable={
            workflowBuilderUI.workflow._stepsMode.signers ===
            EWorkflowMode.ordered
              ? {
                  id: stepNumber,
                  order: stepNumber,
                }
              : undefined
          }
          onClick={(e) => {
            setSelectedRecipient(recipient)
            mhRecipient.handleOpen(e)
          }}
        />
      )
    }

    if (recipient.role === ERecipientRole.signer_rk)
      return (
        <RecipientBadge
          key={`email:${recipient.attrs.email}`}
          sortable={
            workflowBuilderUI.workflow._stepsMode.signers ===
            EWorkflowMode.ordered
              ? {
                  id: stepNumber,
                  order: stepNumber,
                }
              : undefined
          }
          text={recipient.attrs.email}
          imgSrc={img_account}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            // setAnchorEl(event.currentTarget)
            // selection.email = el.email
            mhRecipient.handleOpen(event)
            setSelectedRecipient(recipient)
          }}
        />
      )

    if (recipient.role === ERecipientRole.anonymous_signer_rk)
      return (
        <RecipientBadge
          key={`anonymous_${idx}`}
          sortable={
            workflowBuilderUI.workflow._stepsMode.signers ===
            EWorkflowMode.ordered
              ? {
                  id: stepNumber,
                  order: stepNumber,
                }
              : undefined
          }
          text={recipient.attrs.link_name ?? '-'}
          imgSrc={img_link}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            // setAnchorEl(event.currentTarget)
            // selection.email = el.email
            mhRecipient.handleOpen(event)
            setSelectedRecipient(recipient)
          }}
        />
      )

    return null
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (!over || active.id === over.id) return
    const activeIdx = (active.id as number) - 1
    const overIdx = (over.id as number) - 1

    const state = getObjectClone(workflowBuilderUI.workflow)
    state.steps = arrayMove(state.steps, activeIdx, overIdx)
    state.steps.forEach((step, idx) => {
      step.index = idx + 1
    })

    workflowBuilderUI.setWorkflow(state)
  }

  return (
    <>
      <RecipientsPartition
        title={{
          text: 'Подписание:',
        }}
        children={
          <>
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              sensors={sensors}
            >
              <SortableContext
                items={emails.map((el) => el._visible_step_number)}
                strategy={rectSortingStrategy}
              >
                {emails.map(getRecipientElement)}
              </SortableContext>
            </DndContext>

            <RecipientsSignerMenu
              anchorEl={mhRecipient.anchorEl}
              onClose={mhRecipient.onClose}
              recipient={selectedRecipient}
              open={mhRecipient.open}
            />

            <ButtonWithMenuAddNewRecipientSigner />
          </>
        }
        settings={
          emails.length > 1
            ? {
                onClick: mhSettings.handleOpen,
              }
            : undefined
        }
      />
      <RecipientsSignersSettingsMenu
        open={mhSettings.open}
        anchorEl={mhSettings.anchorEl}
        onClose={mhSettings.onClose}
      />
    </>
  )
}

const EmailFieldWithMenuForSigner = ({
  setVisible,
  setInputValue,
  inputValue,
}: {
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>
  setInputValue: React.Dispatch<React.SetStateAction<string>>
  inputValue: string
}) => {
  const workflowBuilderUI = useContext(WorfklowBuilderUIContext)
  const { members } = useSelector(
    (state: RootState) => state.workspace,
    shallowEqual
  )

  const optionEmails = useMemo(() => {
    const mEmails = members.map((member) => member.email)

    const state = workflowBuilderUI.workflow

    return mEmails.filter((addr) => {
      return !state.steps.find((step) =>
        step.recipients.find(
          (recipient) =>
            (recipient.role === ERecipientRole.anonymous_signer_rk ||
              recipient.role === ERecipientRole.signer_rk) &&
            recipient.attrs.email == addr
        )
      )
    })
  }, [workflowBuilderUI.workflow, members])

  const addEmailToList = (email: string) => {
    if (!email) return false
    if (!isValidEmail(email)) return false
    const state = workflowBuilderUI.workflow
    const inserted = state.insertRecipient(
      new CRecipientSignerRK({
        attrs: {
          email: email,
        },
      })
    )
    if (inserted) {
      workflowBuilderUI.setWorkflow(getObjectClone(state))
    }
    return inserted
  }

  return (
    <EmailFieldWithMenu
      input={{
        inputValue,
        setInputValue,
      }}
      placeholder="Введите email"
      addEmailToList={addEmailToList}
      options={optionEmails}
      onBlurCallback={() => setVisible && setVisible(false)}
    />
  )
}

const ButtonWithMenuAddNewRecipientSigner = () => {
  const mhAdd = usePopupHelpers()
  const workflowBuilderUI = useContext(WorfklowBuilderUIContext)
  const {
    user: { email },
  } = useSelector((state: RootState) => state.user, shallowEqual)

  const [showEmailInput, setShowEmailInput] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const recommendedActors = useMemo<IActor[]>(
    () =>
      CWorkflowHelper.GetRecomendedActors(workflowBuilderUI.workflow, [
        ERecipientRole.signer_rk,
        ERecipientRole.anonymous_signer_rk,
      ]),
    [workflowBuilderUI.workflow]
  )

  const handleClickCreateEmail = () => {
    setShowEmailInput(true)
    mhAdd.onClose()
  }

  if (showEmailInput)
    return (
      <Box>
        <EmailFieldWithMenuForSigner
          setVisible={setShowEmailInput}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      </Box>
    )

  return (
    <>
      <IconButtonCirclePlus
        className="hidden-element"
        onClick={mhAdd.handleOpen}
        tooltip="Добавить подписанта"
      />
      <Menu
        open={mhAdd.open}
        anchorEl={mhAdd.anchorEl}
        onClose={mhAdd.onClose}
        sx={{
          mt: '2px',
        }}
      >
        <Box
          sx={{
            p: '6px 16px 4px',
          }}
        >
          <Typography
            variant="paragraph4"
            color={'var(--light-grayscale-content-4, #90959D)'}
            fontWeight={500}
          >
            Добавить получателя
          </Typography>
        </Box>
        <MenuItemButton
          srcImage={img_email}
          title="Email"
          onClick={handleClickCreateEmail}
        />

        <MenuItemButton
          srcImage={img_link}
          title="Создать ссылку"
          onClick={() => {
            const state = workflowBuilderUI.workflow
            state.insertRecipient(
              new CRecipientAnonymousSignerRK({
                attrs: {
                  limit: 1,
                },
              })
            )
            workflowBuilderUI.setWorkflow(getObjectClone(state))
            mhAdd.onClose()
          }}
        />
        {recommendedActors.map((actor) => {
          const addSignerBasedOnActor = () => {
            const state = workflowBuilderUI.workflow

            const candidate = state.steps
              .find((st) => {
                return st.type === EServiceWorkflowStepType.form
              })
              ?.recipients.find((rec) => {
                return rec.actor_id === actor.id
              })

            if (!candidate) return

            let inserted = false
            if (candidate.attrs.must_send_email) {
              inserted = state.insertRecipient(
                new CRecipientSignerRK({
                  actor_id: actor.id,
                  attrs: {
                    email: candidate.attrs.email,
                  },
                })
              )
            } else {
              inserted = state.insertRecipient(
                new CRecipientAnonymousSignerRK({
                  actor_id: actor.id,
                  attrs: {
                    limit: 1,
                  },
                })
              )
            }

            if (inserted) {
              workflowBuilderUI.setWorkflow(getObjectClone(state))
            }
            mhAdd.onClose()
          }

          const addSignerMe = () => {
            const state = workflowBuilderUI.workflow
            const inserted = state.insertRecipient(
              new CRecipientSignerRK({
                actor_id: actor.id,
                attrs: {
                  email: email,
                },
              })
            )
            if (inserted) {
              workflowBuilderUI.setWorkflow(getObjectClone(state))
            }
            mhAdd.onClose()
          }

          return (
            <MenuItemButton
              key={actor.id}
              srcImage={getFillerSrcByActorId(actor.id)}
              title={actor.title ?? ''}
              onClick={actor.id == 1 ? addSignerMe : addSignerBasedOnActor}
            />
          )
        })}
      </Menu>
    </>
  )
}

const RecipientsSignersSettingsMenu = ({
  open,
  anchorEl,
  onClose,
}: {
  open: boolean
  anchorEl: any
  onClose: any
}) => {
  const workflowBuilderUI = useContext(WorfklowBuilderUIContext)

  const isOrdered =
    workflowBuilderUI.workflow._stepsMode.signers === EWorkflowMode.ordered

  const switchIsOrdered = () => {
    const state = workflowBuilderUI.workflow
    state.switchOrderedMode({
      signers:
        state._stepsMode.signers == EWorkflowMode.default
          ? EWorkflowMode.ordered
          : EWorkflowMode.default,
      approvers: state._stepsMode.approvers,
    })
    workflowBuilderUI.setWorkflow(getObjectClone(state))
  }

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        mt: '2px',
      }}
    >
      <MenuItemButtonWithToggle
        title="Настроить очередь"
        checked={isOrdered}
        onClick={() => {
          switchIsOrdered()
        }}
      />
    </Menu>
  )
}
