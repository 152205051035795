import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { getProfileInfo } from 'store/slices/userSlice'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { changeUserInfo, getAvatar } from 'service/user'
import { Loading, MessageSnackbar } from 'components'
import { useBeforeunload } from 'react-beforeunload'
import { ProfileForm } from './components/profile-form'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { AppBarSettings } from 'components/header/app-bar-common'
import { withDashboardLayout } from 'layout'

const Header = () => {
  return (
    <AppBarSettings
      headerTitle={<Typography variant="subtitle2">Аккаунт</Typography>}
      secondaryBlock={
        <Typography color={'text.secondary'}>
          Изменения в вашем аккаунте будут применяться ко всем пространствам где
          вы состоите
        </Typography>
      }
    />
  )
}

export const ProfileContent = () => {
  const { personal, profile, user }: IUserState = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  )
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState('')

  useBeforeunload((event) => {
    event.preventDefault()
  })

  const [avatarFile, setAvatarFile] = useState('')

  const downloadAvatar = useMemo(
    () => async () => {
      const res = await getAvatar({ id: user.id })
      !avatarFile && setAvatarFile(res || '')
    },
    [user.id]
  )

  useEffect(() => {
    dispatch(getProfileInfo() as any)
    user.id && downloadAvatar()
  }, [])

  const handleUnload = (e) => {
    e.preventDefault()
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload)

    return () => window.removeEventListener('beforeunload', handleUnload)
  }, [handleUnload])

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Некорректный Email')
      .required('Обязательное поле'),
    org_name: Yup.string().max(80, 'Максимум 80 символов'),
    description: Yup.string().max(140, 'Максимум 140 символов'),
  })

  if (profile.loading) return <Loading />

  return (
    <>
      <MessageSnackbar
        clearMessage={() => setOpen('')}
        severity={'success'}
        message={open}
      />

      <Box p={{ xs: '0 16px', sm: '0 40px 16px' }} maxWidth={'720px'}>
        <Box>
          <Formik
            initialValues={{
              firstName: personal.first_name || '',
              lastName: personal.last_name || '',
              email: user.email,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              setLoading(true)
              await changeUserInfo({
                personal: {
                  first_name: values.firstName,
                  last_name: values.lastName,
                },
              })
              dispatch(getProfileInfo() as any)
              setLoading(false)
              setOpen('Сохранено')
            }}
          >
            {({ dirty, resetForm, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <ProfileForm avatarFile={avatarFile} />
                <Box sx={{ mt: '24px' }}>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    color="primary"
                    sx={{ mr: '8px' }}
                    disabled={!dirty || loading}
                  >
                    {loading ? <CircularProgress /> : 'Сохранить'}
                  </Button>
                  {dirty && (
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() => resetForm()}
                    >
                      Отменить
                    </Button>
                  )}
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  )
}

export const Profile = () =>
  withDashboardLayout(ProfileContent)({ Header: Header })
