import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getInboxRegistry, getInboxRegistryCount } from 'service/inbox'

const initialState: IInboxState = {
  page: 1,
  filter: 'all',
  count: 0,
  loading: false,
  documents: [],
  error: '',
  completed: false,
  notifications_count: 0,
}

export const updateInboxDocuments = createAsyncThunk(
  'inbox/updateInboxDocuments',
  async (
    { filter, page, completed, queryString = '', author_id = '' }: any,
    { dispatch }
  ) => {
    dispatch(inboxSlice.actions.startLoading())
    try {
      const res = await getInboxRegistry({
        queryString,
        completed,
        filter,
        author_id,
        page,
      })
      const { documents, count } = res.data.list
      return {
        documents,
        filter,
        count,
        page,
        completed,
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch(inboxSlice.actions.setDocError({ message: error.message }))
      }
    }
  }
)

export const updateNotificationsCount = createAsyncThunk(
  'inbox/updateNotificationsCount',
  async () => {
    try {
      const res = await getInboxRegistryCount()
      const { count } = res.data
      return count
    } catch (error: any) {
      console.error(error.message)
    }
  }
)

const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true
    },
    setDocError(state, action: PayloadAction<{ message: string }>) {
      state.error = action.payload.message
      state.documents = []
      state.count = 0
      state.page = 0
      state.loading = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      updateInboxDocuments.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.documents = action.payload.documents
        state.filter = action.payload.filter
        state.count = action.payload.count
        state.page = action.payload.page
        state.loading = false
        state.completed = action.payload.completed
      }
    )
    builder.addCase(
      updateNotificationsCount.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.notifications_count = action.payload
      }
    )
  },
})

export const inboxReducer = inboxSlice.reducer
