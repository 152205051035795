import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  DELETE_VARIABLE_NODE,
  COPY_VARIABLE_NODE,
  UPDATE_VARIABLE_NODE,
  INSERT_VARIABLE_COMMAND,
} from '../../..'
import { LocalStorageReserve } from 'helper/consts'
import { getLocalStorageData } from 'utils/getLocalStorageData'
import { v4 as uuidv4 } from 'uuid'
import { ESystemVariableType } from 'features/text-editors/lexical/plugins-custom/ParameterPickerPlugin/helpers'
import dayjs from 'dayjs'

export const useVariableAction = (context, handleClose) => {
  const [editor] = useLexicalComposerContext()

  const insertNewVariable = (values) => {
    const g_id = uuidv4()
    const id = uuidv4()
    const props = {
      ...values,
      group_id: g_id,
      id,
    }
    editor.dispatchCommand(INSERT_VARIABLE_COMMAND, {
      props,
    } as any)
    return { id, propsWithId: props }
  }

  const saveToLocalStorageAndUpdateVariable = (id: string, data: any) => {
    let savedLocalStorageData = getLocalStorageData(
      LocalStorageReserve.variableProps
    )
    const isExists = savedLocalStorageData[id]
    savedLocalStorageData[id] = data

    if (isExists) {
      editor.dispatchCommand(UPDATE_VARIABLE_NODE, {
        id: id,
        name: data.name,
        additionalProps: data,
      })
    }
    try {
      localStorage.setItem(
        LocalStorageReserve.variableProps,
        JSON.stringify(savedLocalStorageData)
      )
    } catch (e) {
      console.error('Error setting JSON to localStorage:', e)
    }
  }

  const handleDeleteVariable = () => {
    editor.dispatchCommand(DELETE_VARIABLE_NODE, {
      node: context,
    } as any)
    handleClose && handleClose()
  }

  const handleCopyVariable = () => {
    editor.dispatchCommand(COPY_VARIABLE_NODE, {
      node: context,
    } as any)
    handleClose && handleClose()
  }

  return {
    handleDeleteVariable,
    handleCopyVariable,
    saveToLocalStorageAndUpdateVariable,
    insertNewVariable,
  }
}
