import img_hide_menu from 'assets/img/hide_menu.svg'
import { useContext } from 'react'
import { IconButton, Box } from '@mui/material'
import { AppBarContext } from 'context'
import { isMobile } from 'react-device-detect'
import { DASHBOARD_MODES } from 'layout/dashboard-layout'
import { useNavigate } from 'react-router-dom'
import img_back_nav from 'assets/img/back-nav.svg'

const IconNavigateBack = () => {
  const { setMode } = useContext(AppBarContext)
  const navigate = useNavigate()

  const handleClick = () => {
    setMode(DASHBOARD_MODES.NORMAL)
    navigate('/')
  }

  return (
    <IconButton
      sx={{
        borderRadius: '4px',
      }}
      onClick={handleClick}
    >
      <Box
        component={'img'}
        width={{ xs: '20px', sm: '16px' }}
        height={{ xs: '20px', sm: '16px' }}
        src={img_back_nav}
      />
    </IconButton>
  )
}

const IconShowMenu = () => {
  const { openDrawer, setOpenDrawer } = useContext(AppBarContext)

  return (
    <IconButton
      children={
        <Box
          component={'img'}
          src={img_hide_menu}
          alt=""
          sx={{
            width: { xs: '20px', sm: '16px' },
            height: { xs: '20px', sm: '16px' },
            transform: 'rotate(180deg)',
          }}
        />
      }
      aria-label="open drawer"
      onClick={() => setOpenDrawer(!openDrawer)}
      sx={{
        borderRadius: '4px',
      }}
    />
  )
}

export const AppBarSettings = ({
  headerTitle = <></>,
  secondaryBlock = null,
  children,
}: {
  headerTitle?: any
  secondaryBlock?: any
  children?: any
}) => {
  const { openDrawer } = useContext(AppBarContext)
  return (
    <>
      <Box
        component={'header'}
        id={'header'}
        zIndex={100}
        p={{ xs: '8px 16px', sm: '12px 40px' }}
      >
        <Box width={'100%'} display={'flex'} alignItems={'baseline'}>
          {!openDrawer && (
            <Box mr={{ sm: '12px' }}>
              <IconShowMenu />
            </Box>
          )}

          {isMobile && (
            <Box>
              <IconNavigateBack />
            </Box>
          )}
          <Box>
            {headerTitle}
            {secondaryBlock}
          </Box>
        </Box>

        {children}
      </Box>
    </>
  )
}

export const AppBarCommon = ({
  headerTitle = <></>,
  secondaryBlock = <></>,
  children,
}: {
  headerTitle?: any
  secondaryBlock?: any
  children?: any
}) => {
  const { openDrawer } = useContext(AppBarContext)
  return (
    <>
      <Box component={'header'} id={'header'} zIndex={100} p={'0px 20px'}>
        <Box display={'flex'} flexDirection={'column'}>
          <Box width={'100%'} display={'flex'} alignItems={'center'}>
            {!openDrawer && (
              <Box>
                <IconShowMenu />
              </Box>
            )}
            <Box width={'100%'}>{headerTitle}</Box>
          </Box>
          {secondaryBlock}
          {children}
        </Box>
      </Box>
    </>
  )
}
