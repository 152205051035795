import { Box, Menu, MenuItem, Typography, Button } from '@mui/material'
import { EDocumentStatus } from 'helper/consts'
import { useState } from 'react'
import img_arrow_down from 'assets/img/arrow_down.svg'

export const DocumentsToolbarTabOthers = ({
  select,
  selectedStatus,
  toolbarDocumentsStatusList,
}: {
  select: (value: EDocumentStatus) => void
  selectedStatus: EDocumentStatus | any
  toolbarDocumentsStatusList: any[]
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        onClick={handleClick}
        sx={{
          borderRadius: '4px',
          textTransform: 'none',
          color: '#3C4149',
          padding: '2px 8px',
          height: 'auto !important',
          background: open ? '#EDEDEC' : 'inherit',
          '&:hover': {
            background: '#F5F5F5',
          },

          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography fontWeight={'500'}>Еще</Typography>
        <Box
          component={'img'}
          src={img_arrow_down}
          alt=""
          width={'16px'}
          ml={'4px'}
        />
      </Button>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          mt: '4px',
        }}
      >
        {toolbarDocumentsStatusList.map((item) => (
          <MenuItem
            key={item.key}
            onClick={() => {
              select(item.key)
              handleClose()
            }}
            sx={{
              p: '6px 12px',
              minHeight: 'auto',
              backgroundColor:
                selectedStatus === item.key ? '#EDEDEC' : 'inherit',
            }}
            disableRipple
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                component={'img'}
                src={item.srcImg}
                mr={'8px'}
                width={'16px'}
              />
              <Typography>{item.value}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
