import { EManageTemplateTab } from 'pages/manage-template/contexts/ManageTemplateTabsContext'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useNavigateToManageTemplate = () => {
  const navigate = useNavigate()

  const navigateToManageTemplate = useCallback(
    (props: {
      teamspaceId: string
      templateId: string
      activeTab?: EManageTemplateTab
    }) => {
      let path = `/teamspace/${props.teamspaceId}/templates/${props.templateId}/manage`
      if (props.activeTab) path += `?tab=${props.activeTab}`
      navigate(path)
    },
    [navigate]
  )

  return {
    navigateToManageTemplate,
  }
}
