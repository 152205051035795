import { Box, Chip } from '@mui/material'
import img_global from 'assets/img/ic_global.svg'

export const ChipGlobal = () => {
  return (
    <Chip
      variant={'outlined'}
      size="small"
      sx={{
        height: '20px',
        fontSize: '14px',
        borderRadius: '4px',
        lineHeight: '20px',
        background: 'var(--secondary-aqua-100, #E8F7F0)',
        borderColor: 'var(--secondary-aqua-200, #BEE4DE)',
        '& .MuiChip-label': {
          display: 'none',
        },
        '& .MuiChip-icon': {
          mx: '3px',
        },
      }}
      icon={
        <Box
          component={'img'}
          src={img_global}
          width={'12px'}
          height={'12px'}
        />
      }
    />
  )
}
