import { Typography, Button, Box } from '@mui/material'
import { CustomTooltip } from 'components'

export const GhostButtonSmall = ({
  prefixImgSrc,
  text,
  tooltip,
  onClick,
}: {
  prefixImgSrc?: string
  text: string
  tooltip?: string
  onClick?: (() => void) | any
}) => {
  const getButton = () => {
    return (
      <Button
        color="secondary"
        onClick={onClick}
        sx={{
          p: '2px',
          width: 'auto',
          height: 'auto',
          minWidth: 'min-content',
          minHeight: 'min-content',
          '&:focus': {
            backgroundColor: '#F7F7F7',
          },
          gap: '2px',
        }}
      >
        {prefixImgSrc && (
          <Box
            component="img"
            src={prefixImgSrc}
            width={'16px'}
            height={'16px'}
          />
        )}
        <Typography variant="paragraph3">{text}</Typography>
      </Button>
    )
  }

  if (tooltip)
    return <CustomTooltip title={tooltip}>{getButton()}</CustomTooltip>

  return getButton()
}
