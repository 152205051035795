import {
  $getSelection,
  $isRangeSelection,
  FORMAT_ELEMENT_COMMAND,
  LexicalEditor,
} from 'lexical'
import { useEffect, useState } from 'react'
import { AlignmentCommand, TTextAlignment } from '../../custom/types'

export const FloatingMenuButtonsTextAlign = ({
  editor,
  aligns,
}: {
  editor: LexicalEditor
  aligns: TTextAlignment[]
}) => {
  const [alignMode, setAlignMode] = useState<TTextAlignment | null>(null)

  useEffect(() => {
    editor.getEditorState().read(() => {
      const selection = $getSelection()
      if (!$isRangeSelection(selection)) return

      const nodes = selection.getNodes()
      if (nodes.length > 1) return
      nodes.forEach((node) => {
        if (!node) return
        const pn = node.getParent()
        if (!pn || !pn.exportDOM) return
        const dm = pn.exportDOM(editor)
        if (!dm) return
        setAlignMode(dm.element.style.textAlign)
      })
    })
  }, [editor])

  return aligns.map((alignment) => {
    return (
      <button
        key={alignment}
        type="button"
        onClick={() => {
          editor.dispatchCommand(
            FORMAT_ELEMENT_COMMAND,
            AlignmentCommand[alignment] as any
          )
          setAlignMode(alignment)
        }}
        className={`popup-item ${alignMode == alignment ? 'active' : ''}`}
      >
        <i className={`icon ${alignment}`} />
      </button>
    )
  })
}
