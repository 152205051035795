import { IDefaultModalProps, ModalCenter } from './center'

export const ModalCenterLarge = ({
  open,
  handleClose,
  header,
  content,
  footer,
}: IDefaultModalProps) => {
  return (
    <ModalCenter
      width="990px"
      open={open}
      handleClose={handleClose}
      header={header}
      content={content}
      footer={footer}
    />
  )
}
