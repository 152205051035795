import { Box } from '@mui/material'
import { SettingItemWithToggle } from 'components/menu-item/SettingItemWithToggle'
import {
  WorkflowBuilder,
  useWorkflowBuilderUIContextDefaultValue,
} from 'features/workflow-builder'
import { useManageTemplateSettingsPreFilledWorkflow } from 'features/workflow-builder/providers/useManageTemplateSettingsPreFilledWorkflow'

export const SettingsPreFilledWorkflowForTemplate = () => {
  const workflowBuilderUI = useWorkflowBuilderUIContextDefaultValue({
    alwaysShowCopy: true,
    alwaysShowApprovers: true,
  })
  const settings = useManageTemplateSettingsPreFilledWorkflow({
    workflowBuilderUI,
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        paddingY: '20px',
      }}
    >
      <SettingItemWithToggle
        title={{
          text: 'Предзаполненный маршрут документа',
          isHeading: true,
        }}
        description="Предзаполните маршрут для документов из этого шаблона, получателей можно будет редактировать при отправки документа"
        onClick={settings.handleSwitch}
        checked={settings.isOn}
        disabled={settings.loading}
        loading={settings.isFetching}
      />

      {!settings.isFetching && settings.isOn && (
        <Box
          sx={{
            paddingX: '16px',
            border:
              '1px solid var(--light-grayscale-background-secondary, #F5F5F5)',
            borderRadius: '4px',
          }}
        >
          <WorkflowBuilder workflowBuilderUI={workflowBuilderUI} />
        </Box>
      )}
    </Box>
  )
}
