import { TableCell } from '@mui/material'

export const TemplateActionsCell = ({ children, ...props }: any) => (
  <TableCell
    sx={{
      height: '48px',
      textAlign: 'end',
      whiteSpace: 'nowrap',
      '.template-row:hover &': {
        '.edit-template-button, .create-doc-from-template-button': {
          opacity: 1,
        },
      },
      '.edit-template-button, .create-doc-from-template-button': {
        opacity: 0,
      },
    }}
    {...props}
  >
    {children}
  </TableCell>
)
