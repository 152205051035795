import { Button } from '@mui/material'
import { ModalPlanLimit } from 'components/modal/ModalPlanLimit'
import { ModalWorkflowLauncher } from 'features/workflow-builder'
import { UrlKeys } from 'helper/consts'
import { EFeature } from 'helper/plan.types'
import { useModal } from 'hooks/useModal'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getWSPlanFeatureRestriction } from 'utils/plans'

export const ButtonSendDocumentOnlyByWorkflow = ({
  onClickValidateCallback,
}: {
  onClickValidateCallback?: () => Promise<boolean | undefined>
}) => {
  const { urlId } = useUrlIdParam(UrlKeys.documentId)

  const { plan_info } = useSelector(
    (state: RootState) => state.wsPlan,
    shallowEqual
  )

  const [isModalOpen, openModal, closeModal] = useModal()
  const [limitOn, setLimitOn] = useState<EFeature>()

  const handleClick = async () => {
    const { prohibited, exhausted } = getWSPlanFeatureRestriction(
      EFeature.document_send_by_email,
      plan_info
    )

    if (prohibited || exhausted) {
      setLimitOn(
        prohibited || exhausted ? EFeature.document_send_by_email : undefined
      )
      return
    }

    const canOpenWorkflowModal = onClickValidateCallback
      ? await onClickValidateCallback()
      : true

    if (!canOpenWorkflowModal) return
    openModal()
  }

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleClick}>
        Отправить
      </Button>

      <ModalPlanLimit
        limitOn={limitOn ?? EFeature.document_send_by_email}
        open={!!limitOn}
        handleClose={() => setLimitOn(undefined)}
      />

      {!!urlId.get() && (
        <ModalWorkflowLauncher open={isModalOpen} handleClose={closeModal} />
      )}
    </>
  )
}
