import { NCADoodocs } from '../utils/NCADoodocs'
import { ncaDoodocsClient } from '../config'
import { NCALayerError } from 'utils/NCALayer'

const connectToNCA = async () => {
  let connection = ncaDoodocsClient.wsConnection

  if (!connection || connection.readyState > 1) {
    try {
      ncaDoodocsClient.closeConnection()
      connection = await ncaDoodocsClient.connect()
      return connection
    } catch (error: any) {
      throw new Error(error.message)
    }
  }
}

const showNCAWindow = async () => {
  let activeTokens
  try {
    activeTokens = await ncaDoodocsClient.getActiveTokens()
    return activeTokens
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const checkConnection = async () => {
  try {
    await connectToNCA()
  } catch (error: any) {
    throw new NCALayerError('Не можем подключиться к NCALayer')
  }
}

export const connectAndSign = async (file) => {
  try {
    await connectToNCA()
    const ncaWindow = await showNCAWindow()
    const storageType = ncaWindow[0] || NCADoodocs.fileStorageType
    const base64EncodedSignature = await ncaDoodocsClient.createCAdESFromBase64(
      storageType,
      file
    )
    return base64EncodedSignature
  } catch (error: any) {
    throw new NCALayerError(error.message)
  }
}

export const isAuthorized = async () => {
  try {
    await connectToNCA()
    const auth: any = await ncaDoodocsClient.isAuthorized()
    return auth
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const logout = async () => {
  try {
    await connectToNCA()
    const auth: any = await ncaDoodocsClient.logOut()
    return auth
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const loadModule = async () => {
  try {
    await connectToNCA()
    await ncaDoodocsClient.getDoodocsModule()
    return
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const massSignErrors = {
  ILLEGAL_KEY_TYPE:
    'Данный файл не подходит к подписи. Пожалуйста, подпишите через RSA ключ',
  WRONG_PASSWORD: 'Введен неправильный пароль',
}

export const signDoodocs = async ({ base64File }) => {
  try {
    await connectToNCA()
    const sign: any = await ncaDoodocsClient.sign(base64File)
    return sign
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const authWithNCADoodocs = async ({ storagePath, pass }) => {
  try {
    await connectToNCA()
    const auth: any = await ncaDoodocsClient.auth(pass, storagePath)
    return auth
  } catch (error: any) {
    throw new Error(massSignErrors[error.message] || error.message)
  }
}
