import img_download from 'assets/img/download.svg'

import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { MessageSnackbar } from 'components'
import { useDownloader } from 'hooks/useDownloader'

export function ButtonDownload<T>({
  title,
  downloadFunc,
  props,
}: {
  title: string
  downloadFunc: (props: T) => Promise<{ url: string; filename: string }>
  props: T
}) {
  const { download, error, loading, setError } = useDownloader(downloadFunc)

  return (
    <>
      <Button
        sx={{
          display: 'flex',
          gap: '4px',
        }}
        color={'secondary'}
        disabled={loading}
        onClick={async () => await download(props)}
      >
        {loading ? (
          <CircularProgress color={'inherit'} />
        ) : (
          <Box component={'img'} sizes="16px" src={img_download} />
        )}
        <Typography>{title}</Typography>
      </Button>
      <MessageSnackbar
        clearMessage={() => setError('')}
        message={error}
        severity={'error'}
      />
    </>
  )
}
