import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { EDocumentStatus, ETemplateFilter } from 'helper/consts'
import { getTemplates } from 'service/template'
import { uploadTemplate } from 'utils/upload'

const initialState: ITemplatesState = {
  private_templates: [],
  loading: { public: false, private: false, uploadNewTemplate: false },
  error: { public: '', private: '', uploadNewTemplate: '' },
  notification: {
    uploadNewTemplate: { severity: 'error', message: '' },
    public: { severity: 'error', message: '' },
    private: { severity: 'error', message: '' },
  },
  filter: ETemplateFilter.ALL,
}

export const uploadNewTemplate = createAsyncThunk<
  void,
  { file: any; name: string; ts_id: string; callback: any },
  { rejectValue: string }
>(
  'templates/uploadNewTemplate',
  async ({ file, name, ts_id, callback }, { rejectWithValue }) => {
    try {
      await uploadTemplate({ file, name, ts_id })
      callback()
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateTemplates = createAsyncThunk<
  { templates: Array<any>; filter: ETemplateFilter },
  {
    ts_id: string
    template_name?: string
    status?: EDocumentStatus | string
    filter: ETemplateFilter
  },
  { rejectValue: string }
>(
  'templates/updateTemplates',
  async ({ ts_id, template_name, status, filter }, { rejectWithValue }) => {
    try {
      const res = await getTemplates({ ts_id, template_name, status, filter })
      return { templates: res.data.templates, filter }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

interface ChangeFilterAction {
  type: string
  payload: ETemplateFilter
}

const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error.private = ''
      state.error.public = ''
      state.notification.private.message = ''
      state.notification.public.message = ''
      state.notification.uploadNewTemplate.message = ''
    },
    changeFilter: (state, action: ChangeFilterAction) => {
      state.filter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateTemplates.pending, (state) => {
        state.loading.private = true
      })
      .addCase(updateTemplates.fulfilled, (state, action) => {
        state.private_templates = action.payload.templates
        state.filter = action.payload.filter
        state.loading.private = false
      })
      .addCase(updateTemplates.rejected, (state, action) => {
        state.error.private = action.payload || ''
        state.loading.private = false
        state.notification.private.message = action.payload || ''
        state.notification.private.severity = 'error'
      })
      .addCase(uploadNewTemplate.pending, (state) => {
        state.loading.uploadNewTemplate = true
      })
      .addCase(uploadNewTemplate.fulfilled, (state) => {
        state.loading.uploadNewTemplate = false
        state.notification.uploadNewTemplate.message = 'Шаблон успешно загружен'
        state.notification.uploadNewTemplate.severity = 'success'
      })
      .addCase(uploadNewTemplate.rejected, (state, action) => {
        state.error.uploadNewTemplate = action.payload || ''
        state.loading.uploadNewTemplate = false
        state.notification.uploadNewTemplate.message =
          action.payload || 'Ошибка загрузки шаблона'
        state.notification.uploadNewTemplate.severity = 'error'
      })
  },
})

export const { clearErrors, changeFilter } = templateSlice.actions
export default templateSlice.reducer
