export const gridCenter = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '40%',
}

export const containerCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  height: '100vh',
}
