import { createAsyncThunk } from '@reduxjs/toolkit'
import { queryGetWorkflow, editWorkflow } from 'service'

// TODO: doesnt work with anonymous link
export const deleteWorkflowRecipient = createAsyncThunk(
  'workflow/deleteRecipient',
  async ({
    id,
    origin_id = '',
    index = 1,
    isCopy,
  }: {
    id: string
    origin_id: string
    index: number
    isCopy: boolean
  }) => {
    try {
      const res = await queryGetWorkflow({ id })
      const { workflow } = res.data
      const { steps, cc } = workflow

      if (isCopy) {
        cc.splice(index, 1)
        workflow.cc = cc
      } else {
        const recipients = steps[index - 1].recipients
        const findIndex = recipients.findIndex(
          (recipient) => recipient.origin_id === origin_id
        )
        recipients.splice(findIndex, 1)
        workflow.steps[index - 1].recipients = recipients
      }
      await editWorkflow({ id, data: { workflow } })
    } catch (error: any) {
      throw new Error(error.message)
    }
  }
)
