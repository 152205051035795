import img_ask from 'assets/img/ask.svg'

import { Box, Typography } from '@mui/material'
import { MenuItemGray } from 'layout/dashboard-layout/helper-menu'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { MenuHelp } from '../MenuHelp'

export const SidebarMenuItemHelp = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <MenuItemGray onClick={handleClick}>
        <Box
          component={'img'}
          src={img_ask}
          width={isMobile ? '20px' : '16px'}
          height={isMobile ? '20px' : '16px'}
          alt="help"
          sx={{
            marginRight: '8px',
          }}
        />
        <Typography>Помощь и поддержка</Typography>
      </MenuItemGray>
      <MenuHelp anchorEl={anchorEl} handleClose={handleClose} open={open} />
    </>
  )
}
