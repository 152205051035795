export enum EWfPostmanEmailsType {
  copy = 'copy',
  signers = 'signers',
  approvers = 'approvers',
}


export enum EWfPostmanRecipientSubjectType {
  person = 'person',
  company = 'company',
}

/**
 * @param address - email address пользователя `example@domain.com`
 * @param message - личное сообщение для этого email
 * @param iin - иин пользователя, если есть то будет проверка
 */
export interface IWfPostmanEmail {
  address: string // Email
  message?: string // Personal Message
  iin?: string // Check IIN
  bin?: string // Check BIN
  role?: string // Role
  _subjectType?: EWfPostmanRecipientSubjectType
  _mustCheckSign?: boolean
}

export interface IWfPostmanLetter {
  emails_copy: string[]
  emails_approvers: IWfPostmanEmail[]
  emails_signers: IWfPostmanEmail[]

  subject?: string
  content: string
}

export interface IWfPostmanOrder {
  letter: IWfPostmanLetter
  create: {
    created: boolean
    loading: boolean
    error: string
  }
  edit: {
    edited: boolean
    loading: boolean
    error: string
  }
}

export interface IWfPostman {
  selected_document_id: string
  selected_order: IWfPostmanOrder
  orders: { [document_id: string]: IWfPostmanOrder }
  loading: {
    sign: boolean
  }
  errors: {
    sign: string
  }
  sign: ISignState
}
