import { ERecipientRole } from 'helper/consts'
import { CWorkflow } from 'helper/workflow/CWorkflow'
import { CWorkflowHelper } from 'helper/workflow/CWorkflowHelper'
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useState,
} from 'react'

export interface IWorkflowBuilderUIContext {
  copy: {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    isInputShownByDefault?: boolean
  }
  approvers: {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
  }
  workflow: CWorkflow
  setWorkflow: (wf: CWorkflow) => void
  resetWorkflow: (wf: CWorkflow) => void
}

export const WorfklowBuilderUIContext =
  createContext<IWorkflowBuilderUIContext>({} as IWorkflowBuilderUIContext)

export const useWorkflowBuilderUIContextDefaultValue = (props?: {
  alwaysShowCopy?: boolean
  alwaysShowApprovers?: boolean
  isCopyInputShownByDefault?: boolean
}): IWorkflowBuilderUIContext => {
  // TODO: Оптимизировать написав observers для workflow с переносом основной логики сюда.
  const [workflow, setWorkflow] = useState<CWorkflow>(
    new CWorkflow({
      steps: [],
    })
  )

  const [copyOpen, setCopyOpen] = useState<boolean>(
    CWorkflowHelper.HasCopy(workflow)
  )
  const [approversOpen, setApproversOpen] = useState<boolean>(
    CWorkflowHelper.HasRecipientWithRole(workflow, [ERecipientRole.approver_rk])
  )

  const reset = useCallback(
    (wf: CWorkflow) => {
      setWorkflow(wf)
      setCopyOpen(CWorkflowHelper.HasCopy(wf))
      setApproversOpen(
        CWorkflowHelper.HasRecipientWithRole(wf, [
          ERecipientRole.approver_rk,
          ERecipientRole.anonymous_approver_rk,
        ])
      )
    },
    [setWorkflow, setCopyOpen, setApproversOpen]
  )

  return {
    copy: {
      open: props?.alwaysShowCopy ?? copyOpen,
      setOpen: setCopyOpen,
      isInputShownByDefault: props?.isCopyInputShownByDefault,
    },
    approvers: {
      open: props?.alwaysShowApprovers ?? approversOpen,
      setOpen: setApproversOpen,
    },
    workflow: workflow,
    setWorkflow: setWorkflow,
    resetWorkflow: reset,
  }
}
