import { Pagination } from 'components'
import { useChangePageContext } from 'context'
import { EListQueryParam } from 'helper/queryParams'
import { useSelector, shallowEqual } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

export const Footer = () => {
  const { changePage } = useChangePageContext()
  const [searchParams] = useSearchParams()

  const page = parseInt(searchParams.get(EListQueryParam.Page) || '1')

  const { filter, count }: IDocTableState = useSelector(
    (state: RootState) => state.documents,
    shallowEqual
  )

  return (
    <Pagination
      changePage={changePage}
      page={page}
      count={count}
      filter={filter}
      shouldChangePage={false}
    />
  )
}
