import { Typography, Box } from '@mui/material'
import { AppBarSettings } from 'components/header/app-bar-common'
export const PaymentLimitsAppBar = () => {
  return (
    <AppBarSettings
      headerTitle={
        <>
          <Box>
            <Typography variant="heading5">Оплата и лимиты</Typography>
            <Typography mt={'4px'} color={'text.disabled'}>
              По вопросам выставления счетов обращайтесь billing@doodocs.kz
            </Typography>
          </Box>
        </>
      }
    />
  )
}
