import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { templatesApi } from 'service/template'

export const useActionTemplateMove = () => {
  const [loading, setLoading] = useState(false)

  const run = useCallback(
    async ({
      templateIds,
      teamspaceId,
      setError,
      onSuccessCallback,
    }: {
      templateIds: string[]
      teamspaceId: string
      setError?: Dispatch<SetStateAction<string>>
      onSuccessCallback?: (movedTeamspaceId: string) => any
    }) => {
      try {
        setLoading(true)
        await templatesApi.putMoveTemplatesToTeamspace({
          templateIds: templateIds,
          teamspaceId: teamspaceId,
        })
        if (onSuccessCallback) onSuccessCallback(teamspaceId)
      } catch (err: any) {
        console.error(err)
        if (setError) setError(err.message)
      } finally {
        setLoading(false)
      }
    },
    [setLoading]
  )

  return { loading, run }
}
