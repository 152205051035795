import { useContext } from 'react'
import { getObjectClone } from 'utils/getObjectClone'
import { IRecipient } from 'helper/workflow/recipients/IRecipient'
import { WorfklowBuilderUIContext } from '../../../../../providers/WorkflowBuilderUIContext'
import { IRecipientAttrMessage } from 'helper/workflow/recipients/types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { SettingMessage } from 'components/using-formik-context/SettingMessage'

export const RecipientSettingMessage = ({
  recipient,
}: {
  recipient: IRecipient
}) => {
  const workflowBuilderUI = useContext(WorfklowBuilderUIContext)

  const validationSchema = Yup.object().shape({
    message: Yup.string(),
  })

  const getInitialValues = () => {
    return {
      message: recipient?.attrs?.message ?? '',
    } as IRecipientAttrMessage
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const state = workflowBuilderUI.workflow

        if (!recipient.attrs) recipient.attrs = {} as any
        recipient.attrs = {
          ...recipient.attrs,
          message: values.message,
        }
        workflowBuilderUI.setWorkflow(getObjectClone(state))
      }}
    >
      {<SettingMessage />}
    </Formik>
  )
}
