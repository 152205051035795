export enum EFieldType {
  SWITCH = 'switch',
  STRING = 'string',
  DATE = 'date',
  TEXT_AREA = 'text_area',
  CHECKBOX = 'checkbox',
}

export enum EFieldName {
  IS_REQUIRED = 'is_required',
  HAS_DEFAULT = 'has_default',
  DEFAULT_VALUE = 'default_value',
  HAS_HINT = 'has_hint',
  HINT = 'hint',
}
