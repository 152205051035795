import { Box } from '@mui/material'
import { HeaderWithLogo, LoadingFullHeight } from 'components'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const PageSetup = () => {
  const { authenticated } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  )
  const navigate = useNavigate()

  useEffect(() => {
    if (!authenticated) navigate('/setup/onboarding')
    else navigate('/')
  }, [authenticated])

  return (
    <Box minHeight="100%" display="flex">
      <HeaderWithLogo />
      <LoadingFullHeight />
    </Box>
  )
}
