import { ESystemVariableType } from 'features/text-editors/lexical/plugins-custom/ParameterPickerPlugin/helpers'
import {
  IInputTextSettings,
  ITextAreaSettings,
  IDropdownSettings,
  inputTextinitialValues,
  textAreaInitialValues,
  dropDownInitialValues,
  IEmailFieldSettings,
  emailFiledinitialValues,
  INumberFiledSettings,
  numberFieldinitialValues,
  dateFieldinitialValues,
  ICheckboxFiledAttrs,
  IDateFieldAttrs,
  checkboxFiledinitialValues,
} from '../../../../variable-form-fields'

interface IInitialValues {
  [ESystemVariableType.INPUT_TEXT]: IInputTextSettings
  [ESystemVariableType.TEXT_AREA]: ITextAreaSettings
  [ESystemVariableType.DROPDOWN]: IDropdownSettings
  [ESystemVariableType.INPUT_EMAIL]: IEmailFieldSettings
  [ESystemVariableType.INPUT_NUMBER]: INumberFiledSettings
  [ESystemVariableType.INPUT_DATE]: IDateFieldAttrs
  [ESystemVariableType.CHECKBOX]: ICheckboxFiledAttrs
}

export const initialValues: IInitialValues = {
  [ESystemVariableType.INPUT_TEXT]: inputTextinitialValues,
  [ESystemVariableType.TEXT_AREA]: textAreaInitialValues,
  [ESystemVariableType.DROPDOWN]: dropDownInitialValues,
  [ESystemVariableType.INPUT_EMAIL]: emailFiledinitialValues,
  [ESystemVariableType.INPUT_NUMBER]: numberFieldinitialValues,
  [ESystemVariableType.INPUT_DATE]: dateFieldinitialValues,
  [ESystemVariableType.CHECKBOX]: checkboxFiledinitialValues,
}
