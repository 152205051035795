import { IChangePageArgs } from 'components/pagination/types'
import { EDocumentStatus } from 'helper/consts'
import { TSRolePermissions } from 'helper/roles'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router'
import { updateCampaignDocuments } from 'store/slices/campaignDocuments'

export const useCampaignsDocsChangePage = () => {
  const dispatch = useDispatch()
  const { id, tsId } = useParams()
  const { selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )

  const changePage = ({ page = 1, params }: IChangePageArgs) => {
    const { filter } = params
    id &&
      tsId &&
      dispatch(
        updateCampaignDocuments({
          filter: filter ?? EDocumentStatus.ALL,
          page,
          campaignId: id,
          teamspaceId: tsId,
          onlyOwnDocuments: !(
            selectedTeamspace &&
            TSRolePermissions[selectedTeamspace?.group_name].canManageDocs
          ),
        }) as any
      )
  }

  return { changePage }
}
