import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material'
import checkboxIcon from 'assets/img/checkbox/checkbox.svg'
import { useContext, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { IOSSwitch, Loading } from 'components'
import { Field, useFormikContext } from 'formik'
import { updateCampaign } from 'store/slices/campaign'
import { FullwidthContext } from '../createCampaign'
import checkboxDisabled from 'assets/img/checkbox/checkbox-disabled.svg'

export const Settings = () => {
  const dispatch = useDispatch()

  const fullwidth = useContext(FullwidthContext)

  const { setFieldValue, values, dirty } = useFormikContext<{
    checked_variables: any
    iin: boolean
  }>()

  const { variables, checked_variables, iin, loading } = useSelector(
    (state: RootState) => state.campaign,
    shallowEqual
  )

  useEffect(() => {
    checked_variables && setFieldValue('checked_variables', checked_variables)
    setFieldValue('iin', iin)
  }, [checked_variables, iin])

  if (loading) return <Loading />

  return (
    <Box
      maxHeight={fullwidth ? 'calc(100vh - 160px)' : 'calc(90vh - 160px)'}
      overflow={'scroll'}
      pb={'56px'}
    >
      <Box p={'8px 16px 16px'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant="h6" mb="2px">
            Проверка подписи по ИИН/БИН
          </Typography>

          <FormControlLabel
            sx={{ m: 0 }}
            control={
              <IOSSwitch
                name="iin"
                checked={values.iin}
                onChange={() => {
                  setFieldValue('iin', !values.iin)
                  dispatch(
                    updateCampaign({
                      iin: !values.iin,
                    }) as any
                  )
                }}
              />
            }
            label={''}
          />
        </Box>
        <Box mt={'2px'}>
          <Typography variant="body1" color={'text.secondary'}>
            Получатель только с этим ИИН или БИН сможет подписать документ
          </Typography>
        </Box>
      </Box>
      <Box p={'8px 16px'}>
        <Box>
          <Typography variant="h6">Укажите обязательные поля</Typography>
          <Typography variant="body1" color={'text.secondary'}>
            Мы сгенерируем Excel-файл с данными из шаблона, проверка на
            обязательность заполнения полей требуется для создания документов
          </Typography>
        </Box>
        <Box mt={1} display={'flex'} flexWrap={'wrap'} gap={'8px 16px'}>
          {Object.entries(checked_variables).length !== 0 &&
            variables &&
            !loading && (
              <>
                {Object.entries({
                  required_email: true,
                  ...values.checked_variables,
                }).map(([item, value], index) => (
                  <VariableField
                    label={
                      item === 'required_email' ? 'Email' : variables[item].name
                    }
                    item={item}
                    disabled={item === 'required_email'}
                    key={`${item}-${value}-${index}`}
                  />
                ))}
              </>
            )}
        </Box>
      </Box>
      <Box
        p={'8px 16px 16px'}
        mt={'12px'}
        display={'flex'}
        justifyContent={'flex-end'}
        width={'100%'}
        sx={{
          position: 'absolute',
          background: 'white',
          right: 0,
          bottom: 0,
        }}
      >
        <Button disabled={!dirty} variant={'contained'} type="submit">
          Продолжить
        </Button>
      </Box>
    </Box>
  )
}

const VariableField = ({ label, item, disabled }) => {
  const dispatch = useDispatch()

  const { checked_variables } = useSelector(
    (state: RootState) => state.campaign,
    shallowEqual
  )
  const { setFieldValue } = useFormikContext<{
    checked_variables: any
  }>()

  const handleClick = ({ field }) => {
    if (disabled) return
    const newValue = !field.value
    setFieldValue(field.name, newValue)
    dispatch(
      updateCampaign({
        checked_variables: { ...checked_variables, [item]: newValue },
      }) as any
    )
  }

  return (
    <Box width={'calc(50% - 8px)'}>
      <Field name={`checked_variables.${item}`}>
        {({ field }) => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              cursor: disabled ? '' : 'pointer',
            }}
            onClick={() => handleClick({ field })}
          >
            <CustomCheckbox
              disabled={disabled}
              label={label}
              value={field.value}
            />
          </Box>
        )}
      </Field>
    </Box>
  )
}

const CustomCheckbox = ({
  disabled = false,
  label = '',
  value = false,
  ...props
}) => {
  return (
    <Box display={'flex'} alignItems={'center'}>
      <Checkbox
        checkedIcon={
          disabled ? (
            <img width={16} height={16} src={checkboxDisabled} alt="" />
          ) : (
            <img src={checkboxIcon} alt="" width={16} height={16} />
          )
        }
        sx={{
          '& .MuiSvgIcon-root': { fontSize: 20 },
          mr: 1,
        }}
        disabled={disabled}
        checked={disabled || value}
        {...props}
      />
      <Typography>{label}</Typography>
    </Box>
  )
}
