import { Box, Button, Typography } from '@mui/material'
import sendImg from 'assets/img/send.svg'
import { Loading, MessageSnackbar } from 'components'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { IChangePageArgs } from 'components/pagination/types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { sendCampaindDocs } from 'service'

export const MassSend = ({
  selectedDocsIds,
  changePage,
  setSelectedDocsIds,
  disabled,
}) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)

  const handleClick = () => {
    setOpen(true)
  }

  return (
    <>
      <Button
        disabled={disabled}
        onClick={handleClick}
        startIcon={<img src={sendImg} />}
        color="secondary"
        variant="outlined"
      >
        Отправить на подпись
      </Button>
      <ModalPrompt
        handleClose={handleClose}
        open={open}
        docs={selectedDocsIds}
        setSelectedDocsIds={setSelectedDocsIds}
        changePage={changePage}
      />
    </>
  )
}

export const ModalPrompt = ({
  handleClose,
  open,
  docs,
  setSelectedDocsIds,
  changePage,
}: {
  handleClose: () => void
  open: boolean
  setSelectedDocsIds: (args: any) => void
  changePage: (args: IChangePageArgs) => void
  docs: any
}) => {
  const { count, page, filter } = useSelector(
    (root: RootState) => root.campaignDocs
  )
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleClick = async () => {
    setLoading(true)
    docs = Array.from(docs.values())
    try {
      await sendCampaindDocs({ id, docs })
      setLoading(false)
      setSelectedDocsIds(new Map())
      changePage({ page, params: { filter } })
      handleClose()
    } catch (error: any) {
      setError(error.message)
      setLoading(false)
    }
  }

  return (
    <ModalCenterSelectableWithTitle
      open={open}
      handleClose={handleClose}
      modalType="small"
      title={'Вы уверены что хотите отправить рассылку?'}
      contentChild={
        <>
          {error && (
            <MessageSnackbar
              message={error}
              clearMessage={() => setError('')}
              severity="error"
            />
          )}

          {loading ? (
            <Loading />
          ) : (
            <Box display={'flex'}>
              <Typography color="text.disabled" mr={1}>
                Будет отправлено документов
              </Typography>
              <Typography variant="body1">
                {docs.size > 0 ? docs.size : count}
              </Typography>
            </Box>
          )}
        </>
      }
      footerChild={
        <Box display={'flex'} justifyContent={'end'}>
          <Button
            color="secondary"
            variant="outlined"
            sx={{ mr: '8px' }}
            onClick={handleClose}
          >
            Отменить
          </Button>
          <Button variant="contained" type="submit" onClick={handleClick}>
            Отправить документы
          </Button>
        </Box>
      }
    ></ModalCenterSelectableWithTitle>
  )
}
