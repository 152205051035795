import { CustomTabs } from 'components'
import { StyledTab } from 'components/tabs/tab'
import { ETemplateFilter } from 'helper/consts'
import { EListQueryParam } from 'helper/queryParams'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { a11yProps } from 'utils/a11yprops'

export const toolbarTemplatesStatusList = [
  { key: ETemplateFilter.ALL, value: 'Все', mobile: true },

  {
    key: ETemplateFilter.DELETED,
    value: 'Корзина',
    mobile: false,
  },
]

export const TemplatesToolbarTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const getActiveStatusIndex = () => {
    const idx = toolbarTemplatesStatusList.findIndex((object) => {
      return object.key === searchParams.get(EListQueryParam.Filter)
    })
    if (idx === -1) return 0
    return idx
  }

  const [status, setStatus] = useState(getActiveStatusIndex())

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setStatus(newValue)

    searchParams.set(
      EListQueryParam.Filter,
      toolbarTemplatesStatusList[newValue].key
    )
    setSearchParams(searchParams)
  }

  return (
    <CustomTabs value={status} handleChange={handleChange}>
      {toolbarTemplatesStatusList.map((item, key) => (
        <StyledTab key={key} label={item.value} {...a11yProps(key)} />
      ))}
    </CustomTabs>
  )
}
