import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useEffect, useState } from 'react'
import back from 'assets/img/back-nav.svg'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import {
  logoutUser,
  sendSurvey,
  updateAvatar,
  updateUserInfo,
} from 'store/slices/userSlice'
import { createWorkspace, loadWorkspaces } from 'store/slices/workspace'
import { Navigate, useNavigate } from 'react-router-dom'
import { Loading } from 'components'

import { AnalyticsEvent } from 'config/analytics/segment.com/events'
import {
  SetupAccountDetailsStep,
  SetupWsDetailsStep,
  SetupInvitesStep,
} from 'pages/login/components'
import { OnboardingContext, titlesAndDesc } from 'pages/login'

export const validationSchemasOnbording = [
  Yup.object().shape({
    name: Yup.string().required('Обязательное поле'),
    surname: Yup.string().required('Обязательное поле'),
    industry: Yup.string().required('Обязательное поле'),
    companyRole: Yup.string().required('Обязательное поле'),
    companyPeopleCount: Yup.string().required('Обязательное поле'),
  }),
  Yup.object().shape({
    w_name: Yup.string().required('Обязательное поле'),
  }),
  Yup.object().shape({
    invites: Yup.array().of(Yup.string().email('Неправильный email')),
  }),
]

export const CreateWorkspaceOnboarding = () => {
  const { workspaces, loading } = useSelector(
    (state: RootState) => state.workspace
  )
  const { personal } = useSelector((state: RootState) => state.user)

  const [step, setStep] = useState(1)
  const [withInvites, setWithInvites] = useState(true)

  const dispatch = useDispatch()

  useEffect(() => {
    AnalyticsEvent.setup_started()
  }, [])

  useEffect(() => {
    !workspaces && dispatch(loadWorkspaces({}) as any)
  }, [dispatch, workspaces])

  const handleClickBack = () => {
    step === 1 ? dispatch(logoutUser() as any) : setStep(step - 1)
  }
  const navigate = useNavigate()

  const handleSubmit = (values) => {
    if (step < validationSchemasOnbording.length) {
      setStep(step + 1)
      return
    }
    const { name, surname, avatar, industry, companyRole, companyPeopleCount } =
      values
    dispatch(
      updateUserInfo({
        first_name: name,
        last_name: surname,
      }) as any
    )
    avatar &&
      dispatch(
        updateAvatar({
          avatar,
        }) as any
      )
    dispatch(
      sendSurvey({
        survey: {
          industry,
          role: companyRole,
          employee_count: companyPeopleCount,
        },
      }) as any
    )
    AnalyticsEvent.setup('user', avatar ? 'yes' : 'no', 0)
    dispatch(createWorkspace({ values, withInvites, navigate }) as any)
  }

  if (loading.workspaces) return <Loading />
  if (workspaces && workspaces.length > 0) return <Navigate to="/" />

  return (
    <OnboardingContext.Provider
      value={{ step: step, setStep: setStep, setWithInvites, withInvites }}
    >
      <Container maxWidth="xs" sx={{ m: '88px auto' }}>
        <Box
          textAlign="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Button color={'secondary'} onClick={handleClickBack}>
            <img
              src={back}
              alt=""
              style={{ marginRight: '4px' }}
              width="16px"
              height="16px"
            />
            <Typography fontWeight={500}>Шаг {step} из 3</Typography>
          </Button>
          <Typography variant="subtitle1" mt={'10px'}>
            {titlesAndDesc[step - 1].title}
          </Typography>
          <Typography variant="body2" color={'text.secondary'} mt="8px">
            {titlesAndDesc[step - 1].desc}
          </Typography>
        </Box>
        <Formik
          initialValues={{
            name: personal.first_name || '',
            surname: personal.last_name || '',
            avatar: '',
            logo: '',
            avatar_string: '',
            logo_string: '',
            w_name: '',
            invites: [],
            industry: '',
            companyRole: '',
            companyPeopleCount: '',
          }}
          validationSchema={validationSchemasOnbording[step - 1]}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {step === 1 && <SetupAccountDetailsStep />}
              {step === 2 && <SetupWsDetailsStep />}
              {step === 3 && <SetupInvitesStep />}
              {step === 3 ? (
                <Box
                  mt="24px"
                  display={'flex'}
                  alignItems="center"
                  flexDirection={'column'}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={loading.create ? true : false}
                    onClick={() => {
                      setWithInvites(true)
                      handleSubmit()
                    }}
                  >
                    {loading.create ? (
                      <CircularProgress
                        thickness={5}
                        color="inherit"
                        sx={{ mr: '4px' }}
                      />
                    ) : null}
                    Пригласить участников
                  </Button>
                  <Button
                    sx={{ mt: '8px' }}
                    color="secondary"
                    onClick={() => {
                      setWithInvites(false)
                      handleSubmit()
                    }}
                  >
                    Сделать это позже
                  </Button>
                </Box>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: '24px' }}
                >
                  Продолжить
                </Button>
              )}
            </form>
          )}
        </Formik>
      </Container>
    </OnboardingContext.Provider>
  )
}
