import {
  EManageTemplateTab,
  ManageTemplateTabsContext,
} from 'pages/manage-template/contexts/ManageTemplateTabsContext'
import { useContext } from 'react'
import { TabContentData } from './TabContentData'
import { TabContentDocuments } from './TabContentDocuments'
import { TabContentIntegrations } from './TabContentIntegrations'
import { TabContentSettings } from './TabContentSettings'

const TabsContents = {
  [EManageTemplateTab.Documents]: <TabContentDocuments />,
  [EManageTemplateTab.Data]: <TabContentData />,
  [EManageTemplateTab.Integrations]: <TabContentIntegrations />,
  [EManageTemplateTab.Settings]: <TabContentSettings />,
}

export const ManageTemplateTabContent = () => {
  const { activeTab } = useContext(ManageTemplateTabsContext)

  return <>{TabsContents[activeTab]}</>
}
