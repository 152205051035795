import { createAsyncThunk } from "@reduxjs/toolkit";
import { queryTemplateManageUpdateSettings } from "service/template";
import { TRootState } from "store/store";

export const manageTemplateSettingsSwitchSignMethodEGovMobileBusiness = createAsyncThunk(
  "manageTemplateSettings/manageTemplateSettingsSwitchSignMethodEGovMobileBusiness",
  async ({
    value,
  }: {
    value: boolean
  }, thunkApi) => {
    const { template_id } = (thunkApi.getState() as (TRootState)).templateSettings
    try {
      await queryTemplateManageUpdateSettings({
        templateId: template_id, settings: {
          is_sign_egovbusiness_enabled: value,
        }
      })
      return thunkApi.fulfillWithValue(value)
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message)
    }
  }
)