import {
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  LexicalEditor,
} from 'lexical'
import { useEffect, useState } from 'react'
import { $findMatchingParent, $getNearestNodeOfType } from '@lexical/utils'
import { $isListNode, ListNode } from '@lexical/list'
import { $isHeadingNode } from '@lexical/rich-text'
import { EToolsElement, TTextType } from '../../custom/types'
import { formatSelectedTextTo } from '../../custom/utils/formatSelectedTextTo'

export const FloatingMenuButtonsTextType = ({
  editor,
  types,
}: {
  editor: LexicalEditor
  types: TTextType[]
}) => {
  const [blockType, setBlockType] = useState<TTextType | string>('')

  useEffect(() => {
    editor.getEditorState().read(() => {
      const selection = $getSelection()
      if (!$isRangeSelection(selection)) return

      const anchorNode = selection.anchor.getNode()
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent()
              return parent !== null && $isRootOrShadowRoot(parent)
            })

      if (!element) element = anchorNode.getTopLevelElementOrThrow()

      const elementKey = element.getKey()
      const elementDOM = editor.getElementByKey(elementKey)

      if (!elementDOM) return
      if ($isListNode(element)) {
        const parentList: any = $getNearestNodeOfType<ListNode>(
          anchorNode,
          ListNode
        )
        let type = parentList ? parentList.getListType() : element.getListType()
        if (type == 'number') type = EToolsElement.ListNum
        if (type == 'bullet') type = EToolsElement.ListBul
        setBlockType(type)
      } else {
        const type = $isHeadingNode(element)
          ? element.getTag()
          : element.getType()
        setBlockType(type)
      }
    })
  }, [editor])

  const formatSelectionToType = (type: TTextType) => {
    if (blockType === type) type = EToolsElement.P
    if (formatSelectedTextTo[type]) formatSelectedTextTo[type](editor)
    setBlockType(type)
  }

  return types.map((type) => {
    return (
      <button
        key={type}
        type="button"
        onClick={() => formatSelectionToType(type)}
        className={`popup-item ${type == blockType ? 'active' : ''}`}
      >
        <i className={`icon ${type}`} />
      </button>
    )
  })
}
