import { Box } from '@mui/material'
import { Error404, Loading, MessageSnackbar } from 'components'
import { useAiChatContext, useSidebarModeContext } from 'context'
import { SidebarDocumentTracking } from 'features'
import { UrlKeys, EDocumentStatus } from 'helper/consts'
import { IWfPostman } from 'helper/wfPostman.types'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { sidebarWidth } from 'layout/sidebar-layout'
import { AIChat } from 'layout/sidebar-layout/aichat'
import { useDocumentParams } from 'pages/document-preview/hooks/useDocumentId'
import { SidebarMode } from 'pages/document-preview/hooks/useSideBarState'
import { useMemo, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  createNewDocument,
  reset,
  fileDataFetch,
  fetchTemplateInfoApplication,
} from 'store/slices/applicationSlice'
import {
  actionUpdateDraftVariables,
  clearData,
  clearErrors as clearTemplateErrors,
} from 'store/slices/templateSlice'
import { wfPostmanActions } from 'store/slices/wfPostmanSlice'
import { Details, FormCreateDoc } from '..'
import { DocumentContainer } from '../DocumentContainer'
import { BlockCopyOneSignLink } from 'features/copy-one-link-to-sign'

const shouldRenderSignLink = (recipients?: IRecipients): boolean =>
  recipients?.attrs?.is_single_anonymous_link ?? false

export const PreviewContent = () => {
  const { template_id } = useDocumentParams()
  const { urlId } = useUrlIdParam(UrlKeys.documentId)
  const { urlId: templateId } = useUrlIdParam(UrlKeys.templateId)

  const { tsId } = useParams()
  // TODO: move inside
  const aiChatContextValue = useAiChatContext()
  const { sidebarMode, updateSidebar } = useSidebarModeContext()

  const dispatch = useDispatch<any>()
  const { loading, errors, document, recipients }: IApplicationState =
    useSelector((state: RootState) => state.application, shallowEqual)
  const { errors: wfErrors } = useSelector(
    (state: RootState) => state.wfPostman as IWfPostman,
    shallowEqual
  )
  const { errors: templateErrors }: ITemplateState = useSelector(
    (state: RootState) => state.template,
    shallowEqual
  )

  const sidebarIsOpen = () => {
    return sidebarMode != SidebarMode.close
  }
  const closeSidebar = () => {
    updateSidebar(SidebarMode.close)
  }

  const getSidebarContent = useMemo(() => {
    switch (sidebarMode) {
      case SidebarMode.chat:
        return <AIChat handleClose={closeSidebar} />
      case SidebarMode.info:
        return <Details handleClose={closeSidebar} />
      case SidebarMode.workflow:
        return (
          <>
            {shouldRenderSignLink(recipients) && (
              <BlockCopyOneSignLink
                link={`https://link.doodocs.kz/${recipients.attrs?.link}`}
              />
            )}

            <SidebarDocumentTracking handleClose={closeSidebar} />
          </>
        )
      case SidebarMode.edit:
        return <FormCreateDoc handleClose={closeSidebar} />
      default:
        return null
    }
  }, [sidebarMode, recipients])

  const createDocumentIfNotExistAndLoadChat = async () => {
    if (template_id && !urlId.get() && !document.id) {
      dispatch(
        createNewDocument({
          name: document.name,
          template_id: template_id,
          tsId,
        }) as any
      )
    }

    // ? Get Chat History If Not Inited
    if (
      !aiChatContextValue.state.id ||
      aiChatContextValue.state.messages.error
    ) {
      await aiChatContextValue.init(document.id)
    }
  }

  const cleanUp = () => {
    dispatch(actionUpdateDraftVariables({ variables: {} }) as any)
    dispatch(reset() as any)
    dispatch(wfPostmanActions.clearLinks() as any)
    dispatch(clearData() as any)
  }

  useEffect(() => {
    if (!loading.info && sidebarMode === SidebarMode.chat)
      createDocumentIfNotExistAndLoadChat()
  }, [sidebarMode, document.id, loading.info])

  useEffect(() => {
    if (urlId.get()) {
      dispatch(fileDataFetch({ id: urlId.get() }) as any)
    } else if (templateId.get()) {
      dispatch(fetchTemplateInfoApplication({ templateId: templateId.get() }))
    }

    return cleanUp
  }, [location.pathname])

  if (errors.source)
    return (
      <Error404
        message={errors.source}
        description={''}
        style={{ margin: '0 -24px', width: 'calc(100% + 48px)' }}
      />
    )

  if (loading.info || document.status === EDocumentStatus.PREPARING)
    return <Loading />

  return (
    <>
      <MessageSnackbar
        message={wfErrors.sign}
        clearMessage={() => dispatch(wfPostmanActions.clearSignError() as any)}
        severity="warning"
      />
      <MessageSnackbar
        message={templateErrors.updateDraftVariables}
        clearMessage={() => dispatch(clearTemplateErrors() as any)}
        severity="warning"
      />
      <Box display={'flex'} height={'100%'}>
        <Box
          sx={{
            width: sidebarIsOpen() ? `calc(100% - ${sidebarWidth})` : '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
          maxHeight={'100vh'}
        >
          <Box
            m={'40px'}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <DocumentContainer />
          </Box>
        </Box>
        <Box id={!isMobile ? 'workflow-view' : ''}>
          <Box
            sx={{
              width: sidebarIsOpen() ? `${sidebarWidth} !important` : 0,
            }}
            overflow="auto"
            height={'100%'}
            display={'flex'}
            flexDirection={'column'}
          >
            {getSidebarContent}
          </Box>
        </Box>
      </Box>
    </>
  )
}
