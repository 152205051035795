import { Box, Grid, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { HeaderWithLogo, Loading, MessageSnackbar } from 'components'
import { IconButtonHelp } from 'features/help'
import { LoginFormLayout } from 'layout/login-form'
import { VerificationCodeFormWithSendTally } from 'pages/login/components/verification-code-form'
import { useLogin } from 'pages/login/hooks/useLogin'
import { sendEventUserLogin } from 'pages/login/hooks/useSendCode'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { challengeEmail, postSignUpGoogle } from 'service/auth'
import { authError, clearErrorsLogin, logoutUser } from 'store/slices/userSlice'
import { getUtmParams } from 'utils/get-utm-params'

export const LoginOtp = () => {
  useLogin()

  const [searchParams] = useSearchParams()
  const { error } = useSelector((state: RootState) => state.user, shallowEqual)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const { authenticated } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  )
  const navigate = useNavigate()

  useEffect(() => {
    const email = searchParams.get('email')

    if (!email) {
      navigate('/login')
      return
    }
  }, [authenticated])

  const handleGetOTP = async () => {
    try {
      await challengeEmail(searchParams.get('email') || '')
    } catch (error: any) {
      if (error instanceof AxiosError) {
        dispatch(authError(error.message) as any)
      }
    }
  }

  useEffect(() => {
    if (authenticated) {
      ;(async () => {
        try {
          setLoading(true)
          const params = {
            email: searchParams.get('email') || '',
            full_name: searchParams.get('user_name') || '',
            phone: searchParams.get('phone') || '',
            company_name: searchParams.get('company_name') || '',
            ...getUtmParams(searchParams),
          }
          await postSignUpGoogle(params)
          sendEventUserLogin({
            ...params,
            login_source: 'google',
            new_user: true,
          })
          navigate('/')
        } catch {
          dispatch(logoutUser() as any)
        } finally {
          setLoading(false)
        }
      })()
    }

    !authenticated && handleGetOTP()
  }, [authenticated])

  if (loading) return <Loading />

  return (
    <Box minHeight="100%" display="flex">
      <MessageSnackbar
        message={error}
        clearMessage={() => dispatch(clearErrorsLogin() as any)}
        severity="error"
      />
      <HeaderWithLogo />
      <LoginFormLayout
        header={
          <>
            <Typography variant="subtitle1">
              Проверьте свой почтовый ящик
            </Typography>
            <Typography
              variant="body1"
              mt="8px"
              mb="16px"
              color={'text.secondary'}
            >
              Мы отправили код подтверждения на электронный адрес{' '}
              <span style={{ fontWeight: 500 }}>
                {searchParams.get('email')}
              </span>
              .{' '}
              <Link
                to={'/login'}
                style={{
                  color: '#3C4149',
                }}
              >
                Изменить
              </Link>
            </Typography>
          </>
        }
        content={
          <>
            <Grid item xs={12} textAlign="start">
              <Typography variant="body1" mb="8px" fontWeight={500}>
                Временный код
              </Typography>
              <VerificationCodeFormWithSendTally
                email={searchParams.get('email')}
              />
            </Grid>
          </>
        }
      />
      <Box sx={{ p: '0 24px', position: 'fixed', right: '0', bottom: '24px' }}>
        <Grid container justifyContent="flex-end">
          <IconButtonHelp />
        </Grid>
      </Box>
    </Box>
  )
}
