import { MenuItemButton, MenuItemButtonProps } from '../menu-item-button'
import { Radio } from 'components/input/Radio'

export const MenuItemButtonWithRadio = ({
  title,
  description,
  srcImage,
  onClick,
  imgSize,
  disabled,
  checked,
  renderTitlePrefix,
}: MenuItemButtonProps & {
  checked: boolean
}) => {
  return (
    <MenuItemButton
      disabled={disabled}
      onClick={onClick}
      imgSize={imgSize}
      srcImage={srcImage}
      renderTitlePrefix={renderTitlePrefix}
      title={title}
      description={description}
      renderTitleSuffix={<Radio checked={checked} disabled={disabled} />}
    />
  )
}
