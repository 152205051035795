import { Box, Button } from '@mui/material'
import plus_icon from 'assets/img/plus_white.svg'
import { ERegistryType } from 'components/breadcrumbs/BreadcrumbsBlock'
import { HeaderWithBreadcrumbs } from 'components/header/HeaderWithBreadcrumbs'
import { AppBarCommon } from 'components/header/app-bar-common'
import { useSearchBarContext } from 'context'
import { SearchBar } from 'features/search-bar'
import { TSRolePermissions } from 'helper/roles'
import { isMobile } from 'react-device-detect'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { TemplatesToolbarTabs } from './TemplatesToolbarTabs'

export const Toolbar = () => {
  const { tsId } = useParams()
  const navigate = useNavigate()
  const { statusQuery, queryString } = useSearchBarContext()

  const { filter, private_templates }: ITemplatesState = useSelector(
    (state: RootState) => state.templates,
    shallowEqual
  )
  const { selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )

  const handleClick = () => {
    navigate(`/teamspace/${tsId}/templates/new`)
  }

  return (
    <>
      <AppBarCommon
        headerTitle={
          <HeaderWithBreadcrumbs
            breadcrumb={{
              registry: {
                value: ERegistryType.Templates,
              },
            }}
            rightChildren={
              selectedTeamspace &&
              TSRolePermissions[selectedTeamspace?.group_name]
                .canManageDocs && (
                <Box>
                  <Button
                    startIcon={
                      !isMobile && (
                        <Box
                          component={'img'}
                          src={plus_icon}
                          width={'20px'}
                          height={'20px'}
                        />
                      )
                    }
                    onClick={handleClick}
                    variant={'contained'}
                    color={'primary'}
                    sx={{
                      minWidth: '40px',
                      width: { xs: '40px', sm: 'inherit' },
                      height: { xs: '40px', sm: 'inherit' },
                      p: isMobile ? '9px !important' : '',
                    }}
                  >
                    {isMobile ? (
                      <Box
                        component={'img'}
                        src={plus_icon}
                        width={'20px'}
                        height={'20px'}
                      />
                    ) : (
                      'Новый шаблон'
                    )}
                  </Button>
                </Box>
              )
            }
          />
        }
      >
        <TemplatesToolbarTabs />
        <Box padding={'12px 0px'}>
          {(private_templates.length > 0 || statusQuery || queryString) && (
            <SearchBar filter={filter} showAuthorFilter={false} />
          )}
        </Box>
      </AppBarCommon>
    </>
  )
}
