import img_email from 'assets/img/socials/email.svg'

import { Box, Button, Link as MuiLink } from '@mui/material'

export const ButtonEmail = ({
  email,
  message = '',
  fullWidth,
}: {
  email?: string
  message?: string
  fullWidth?: boolean
}) => {
  return (
    <MuiLink
      href={`mailto:${email ?? 'fedoskin@doodocs.kz'}?subject=${message}`}
      target="_blank"
      style={{
        width: fullWidth ? '100%' : 'auto',
      }}
    >
      <Button
        fullWidth={fullWidth}
        color="secondary"
        variant="outlined"
        startIcon={
          <Box
            component={'img'}
            src={img_email}
            width={'20px'}
            height={'20px'}
          />
        }
      >
        Email
      </Button>
    </MuiLink>
  )
}
