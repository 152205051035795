import { analytics } from 'config/analytics/segment.com/analytics'
import { AnalyticsEvent } from 'config/analytics/segment.com/events'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

const setAnonymousWithIdentify = () => {
  analytics.user().then((t) => {
    // ? Reset if already identified with id on logouted user
    if (t.id()) analytics.reset()
    if (!t.anonymousId()) analytics.identify()
  })
}

export const AnalyticsIdentifyUser = ({ children }) => {
  const { personal, user, authenticated } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  )

  useEffect(() => {
    if (!user.id || !authenticated) {
      setAnonymousWithIdentify()
      return
    }
    AnalyticsEvent.user_identify(user.id, {
      email: user.email,
      first_name: personal.first_name,
      last_name: personal.last_name,
    })
  }, [
    user.id,
    user.email,
    authenticated,
    personal.first_name,
    personal.last_name,
  ])

  return children
}
