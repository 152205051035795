import {
  SidebarMode,
  useSidebarState,
  useSidebarStateTemplate,
} from 'pages/document-preview/hooks/useSideBarState'
import { createContext, useContext } from 'react'

interface ISidebarModeContext {
  updateSidebar: (mode: SidebarMode) => void
  sidebarMode: SidebarMode
  sidebarIsOpen: () => boolean
  closeSidebar: () => void
}

const SidebarModeContext = createContext<ISidebarModeContext>(
  {} as ISidebarModeContext
)

export const useSidebarMode = () => useContext(SidebarModeContext)

export const SidebarModeProvider = ({ children }) => {
  const { sidebarMode, sidebarIsOpen, closeSidebar, updateSidebar } =
    useSidebarState()

  return (
    <SidebarModeContext.Provider
      value={{ updateSidebar, sidebarMode, sidebarIsOpen, closeSidebar }}
    >
      {children}
    </SidebarModeContext.Provider>
  )
}

export const SidebarModeTemplateProvider = ({ children }) => {
  const { sidebarMode, sidebarIsOpen, closeSidebar, updateSidebar } =
    useSidebarStateTemplate()

  return (
    <SidebarModeContext.Provider
      value={{ updateSidebar, sidebarMode, sidebarIsOpen, closeSidebar }}
    >
      {children}
    </SidebarModeContext.Provider>
  )
}

export const useSidebarModeContext = () => {
  const context = useContext(SidebarModeContext)
  if (context === undefined || context === null) {
    throw new Error(
      `useSidebarModeContext must be called within SidebarModeContext`
    )
  }
  return context
}
