import { Box, Container } from '@mui/material'
import { Error404, MessageSnackbar } from 'components'
import { TemplatesRegistryContext } from 'pages/templates/registry/hooks/TemplatesRegistryContext'
import { TemplatesTable } from 'pages/templates/registry/table'
import { useContext, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { clearErrors } from 'store/slices/templateSlice'
import { TemplateInfoBox } from '../template-info-box'
import { updateTemplates } from 'store/slices/templatesSlice'
import { useSearchBarContext, useSelectedDocsContext } from 'context'
import { useSearchQueryURLParams } from 'context/search-bar/hooks'
import { ETemplateFilter } from 'helper/consts'

export const TemplatesContent = () => {
  const dispatch = useDispatch()
  const { error, notification, private_templates }: ITemplatesState =
    useSelector((state: RootState) => state.templates, shallowEqual)

  const { actionDublicate } = useContext(TemplatesRegistryContext)
  const { statusQuery, queryString } = useSearchBarContext()
  const { filter } = useSearchQueryURLParams({
    totalDocumentsCount: private_templates.length,
  })
  const { setHiddenDocumentIds } = useSelectedDocsContext()

  const { tsId } = useParams()

  useEffect(() => {
    dispatch(
      updateTemplates({
        ts_id: tsId || '',
        template_name: queryString,
        status: statusQuery,
        filter: filter as ETemplateFilter,
      }) as any
    )
    actionDublicate.clearDublicates()
    setHiddenDocumentIds([])
  }, [tsId, queryString, statusQuery, filter])

  if (error.private)
    return <Error404 message={error.private} description={''} />

  return (
    <>
      <MessageSnackbar
        message={notification.private.message}
        clearMessage={() => dispatch(clearErrors() as any)}
        severity={notification.private.severity}
      />
      <MessageSnackbar
        message={notification.uploadNewTemplate.message}
        clearMessage={() => dispatch(clearErrors() as any)}
        severity={notification.uploadNewTemplate.severity}
      />

      <MessageSnackbar
        message={actionDublicate.error}
        clearMessage={() => actionDublicate.clearError()}
        severity={'error'}
      />
      <Container
        maxWidth={false}
        sx={{
          overflow: 'auto',
          pl: {
            xs: '20px',
          },
          pr: { xs: '20px' },
        }}
      >
        <TemplateInfoBox />
        <Box>
          <TemplatesTable />
        </Box>
      </Container>
    </>
  )
}
