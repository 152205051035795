import { Box, Container, Typography, Button } from '@mui/material'
import { Loading, MessageSnackbar } from 'components'
import { TallySearchParams } from 'helper/consts'
import { Login } from 'pages/login'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { workspaceApi } from 'service/workspaces'

export const AcceptInvite = () => {
  const { inviteId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const fetchInviteInfo = async () => {
    setLoading(true)
    try {
      const res = await workspaceApi.getInviteInfo({ id: inviteId || '' })
      const { public_data } = res.data
      const { workspace_name, invitee_email } = public_data

      setName(workspace_name)
      setEmail(invitee_email)
      searchParams.set('email', invitee_email)
      searchParams.set(TallySearchParams.hide_company_name, 'true')
      setSearchParams(searchParams)
    } catch (error: any) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchInviteInfo()
  }, [])

  if (!email || loading) return <Loading />
  return (
    <>
      <MessageSnackbar
        message={error}
        clearMessage={() => setError('')}
        severity={'error'}
      />

      <Login
        header={
          <>
            <Typography variant="subtitle1">
              Вас пригласили в «{name}»
            </Typography>{' '}
            <Typography variant="body1" mt="8px" mb="16px">
              Для принятия приглашения войдите или зарегистрируйтесь с аккаунтом{' '}
              {email}
            </Typography>
          </>
        }
        redirectToAfterAuth={`/`}
      />
    </>
  )
}

export const InviteError = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/')
  }

  return (
    <Container
      maxWidth={'xs'}
      sx={{
        mt: '64px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="subtitle1">Ошибка приглашения</Typography>
      <Typography mt={'8px'} color="text.secondary" textAlign={'center'}>
        Возможно, у вас нет прав для вступления или срок действия приглашения
        истек
      </Typography>
      <Box mt={'24px'} width="100%">
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={handleClick}
        >
          Открыть Doodocs
        </Button>
      </Box>
    </Container>
  )
}
