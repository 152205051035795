import { useSnackbar } from 'components/snackbar/hook'
import { useDocumentsRegistryDublicateDocumentContextDefaultValue } from 'features/copy-document/providers/DocumentsRegistryDublicateDocumentContext'
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'

interface SelectedDocsContextProps {
  selectedDocsIds: Map<string, DocumentState>
  setSelectedDocsIds: (docsIds: Map<string, DocumentState>) => void
  hiddenDocumentIds: string[]
  setHiddenDocumentIds?: Dispatch<SetStateAction<string[]>> | any
  showSnackbar?: any
  closeSnackbar?: any
  isCampaignDocs?: boolean
  dubls: ReturnType<
    typeof useDocumentsRegistryDublicateDocumentContextDefaultValue
  >
}

export const SelectedDocsContext = createContext<SelectedDocsContextProps>({
  selectedDocsIds: new Map(),
  setSelectedDocsIds: () => {
    return
  },
  hiddenDocumentIds: [],
} as any)

export const SelectedDocsIdsProvider = ({ children }) => {
  const contextValues = useSelectedDocsIdsValues()

  return (
    <SelectedDocsContext.Provider value={contextValues}>
      {children}
    </SelectedDocsContext.Provider>
  )
}

export const useSelectedDocsContext = () => {
  const context = useContext(SelectedDocsContext)
  if (context === undefined || context === null) {
    throw new Error(
      `useSelectedDocsProvider must be called within SelectedDocsContext`
    )
  }
  return context
}

export const useSelectedDocsIdsValues = () => {
  const [selectedDocsIds, setSelectedDocsIds] = useState<
    Map<string, DocumentState>
  >(new Map())
  const dubls = useDocumentsRegistryDublicateDocumentContextDefaultValue()
  const [hiddenDocumentIds, setHiddenDocumentIds] = useState<string[]>([])
  const { showSnackbar, closeSnackbar } = useSnackbar()

  return {
    selectedDocsIds,
    setSelectedDocsIds,
    hiddenDocumentIds,
    setHiddenDocumentIds,
    showSnackbar,
    closeSnackbar,
    dubls,
  }
}
