import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'
import localStorage from 'redux-persist/lib/storage' // defaults to localStorage for web
import workflowReducer from './slices/workflow'
import applicationReducer from './slices/applicationSlice'
import campaignReducer from './slices/campaign'
import campaignDocumentsReducer from './slices/campaignDocuments'
import teamspaceReducer from './slices/teamspaceSlice'
import templateReducer from './slices/templateSlice'
import templatesReducer from './slices/templatesSlice'
import userReducer from './slices/userSlice'
import workspaceReducer from './slices/workspace'

import { TypedUseSelectorHook } from 'react-redux'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { documentsReducer } from './slices/documentsSlice'
import { inboxReducer } from './slices/inboxSlice'
import { manageTemplateDataSlice } from './slices/manageTemplateData'
import { manageTemplateSettingsSlice } from './slices/manageTemplateSettings'
import { selectedWorkspaceSlice } from './slices/selectedWorkspace'
import { wfPostmanReducer } from './slices/wfPostmanSlice'
import { wsPlanSlice } from './slices/wsPlan'

const rootReducer = combineReducers({
  user: userReducer,
  documents: documentsReducer,
  application: applicationReducer,
  templates: templatesReducer,
  campaign: campaignReducer,
  campaignDocs: campaignDocumentsReducer,
  workflow: workflowReducer,
  workspace: workspaceReducer,
  selectedWorkspace: selectedWorkspaceSlice.reducer,
  teamspace: teamspaceReducer,
  [wsPlanSlice.name]: wsPlanSlice.reducer,
  wfPostman: wfPostmanReducer,
  inbox: inboxReducer,
  template: templateReducer,

  templateSettings: manageTemplateSettingsSlice.reducer,
  templateData: manageTemplateDataSlice.reducer,
})

const persistConfig = {
  key: 'root',
  storage: localStorage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['selectedWorkspace'],
} as any

const persistedReducer = persistReducer(persistConfig, rootReducer)

function middleware(getDefaultMiddleware) {
  return getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
}

/**
 * store - session storage
 */
const store = configureStore({
  reducer: persistedReducer,
  middleware: middleware,
})

export type TRootState = ReturnType<typeof store.getState>
export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector

export const persistor = persistStore(store)
export default store
