import { api } from 'config'
import { handleError } from 'service'

export async function getUserExists(email: string) {
  const resp = await api.get(`/users/exists`, {
    headers: {
      accept: 'application/json',
    },
    params: {
      email: email,
    },
    timeout: 10000,
  })
  return resp
}

export async function postSignUpEmail(body: {
  full_name: string
  email: string
  phone: string
  company_name: string
}) {
  try {
    const resp = await api.post(`/users/signup/email`, body, {
      headers: {
        accept: 'application/json',
      },
      timeout: 10000,
    })
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export async function postSignUpGoogle(body: {
  full_name: string
  email: string
  phone: string
  company_name: string
}) {
  try {
    const resp = await api.post(`/users/signup/google`, body, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        accept: 'application/json',
      },
      timeout: 10000,
    })
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export async function challengeEmail(email) {
  try {
    const resp = await api.post(
      `/challenge/email`,
      { email },
      {
        headers: {
          accept: 'application/json',
        },
        timeout: 10000,
      }
    )
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export async function getTokenEmail(body) {
  try {
    const resp = await api.post(`/token/email`, body, {
      headers: {
        accept: 'application/json',
      },
      timeout: 10000,
    })
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export async function getTokenGmail({ code }) {
  try {
    const resp = await api.post(
      `/token/google`,
      { code },
      {
        headers: {
          accept: 'application/json',
        },
        timeout: 10000,
      }
    )
    return resp
  } catch (error) {
    return handleError(error)
  }
}
