import { AppBarProvider } from 'context'
import { DashboardLayout } from 'layout'

export const withDashboardLayout =
  (Component) =>
  ({
    Header = null,
    Footer = null,
    providers = [],
  }: {
    Header?: any
    Footer?: any
    providers?: any[]
  }) => {
    const ProvidersWrapper = ({ children }) =>
      providers ? (
        providers.reduceRight(
          (children, ProviderComponent) => (
            <ProviderComponent>{children}</ProviderComponent>
          ),
          children
        )
      ) : (
        <></>
      )

    return (
      <ProvidersWrapper>
        <AppBarProvider>
          <DashboardLayout
            header={Header ? () => <Header /> : null}
            footer={Footer ? () => <Footer /> : null}
          >
            <Component />
          </DashboardLayout>
        </AppBarProvider>
      </ProvidersWrapper>
    )
  }
