import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: {
  selectedWorkspace?: Workspace
} = {}

export const selectedWorkspaceSlice = createSlice({
  name: 'selectedWorkspace',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setSelectedWorkspace: (
      state,
      action: PayloadAction<Workspace | undefined>
    ) => {
      state.selectedWorkspace = action.payload
    },
  },
})
