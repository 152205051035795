import { Box, Button, Typography } from '@mui/material'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { useState } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { leaveTs } from 'store/slices/teamspaceSlice'
import { useAppSelector } from 'store/store'

export const BlockTeamspaceLeave = () => {
  const { tsId } = useParams()
  const [open, setOpen] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const { loading, members } = useAppSelector(
    (state) => state.teamspace,
    shallowEqual
  )

  const handleLeaveWs = async () => {
    dispatch(leaveTs({ tsId, navigate }) as any)
  }

  if (!!members && members?.length > 1)
    return (
      <>
        <ModalCenterSelectableWithTitle
          open={open}
          handleClose={handleClose}
          title={'Вы уверены, что хотите покинуть эту группу?'}
          modalType="small"
          contentChild={
            <>
              <Typography variant="body1">
                Вы потеряете доступ к этому пространству и все ваши документы в
                приватной группе будут удалены
              </Typography>
              <Box justifyContent="flex-end" display={'flex'} mt={1}>
                <Button
                  sx={{ mr: '8px' }}
                  color="secondary"
                  onClick={() => setOpen(false)}
                  variant="outlined"
                >
                  Отменить
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  type="submit"
                  onClick={handleLeaveWs}
                  disabled={loading.delete_ts ? true : false}
                >
                  Покинуть группу
                </Button>
              </Box>
            </>
          }
        />
        <Typography variant="body1" fontWeight={500}>
          Покинуть группу
        </Typography>

        <Box mt="8px">
          <Button
            color="error"
            variant="outlined"
            size="small"
            onClick={handleOpen}
          >
            Покинуть группу
          </Button>
        </Box>
      </>
    )
  return null
}
