import { Box, Collapse, Typography } from '@mui/material'
import { VariableInput } from './variableInput'
import { useState } from 'react'
import ArrowDropRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { actorColors } from 'features/text-editors/lexical/plugins-custom/ParameterPickerPlugin/helpers/consts/actorsConfig'

export const ActorSection = ({
  actorId,
  variables,
  actors,
  ...props
}: {
  actorId: string
  variables: { [key: string]: IVariables }
  actors: any[]
}) => {
  const [open, setOpen] = useState(true)

  const actorTitle =
    actors.find((actor) => actor.id.toString() === actorId)?.title || ''

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <Box id={`filler-form-${actorId}`} p="20px 16px">
      <Box
        onClick={handleClick}
        sx={{ cursor: 'pointer' }}
        display="flex"
        alignItems={'center'}
      >
        <Typography
          variant="h6"
          mr="4px"
          pt={0}
          sx={{ backgroundColor: actorColors[actorId] || 'none' }}
        >
          {actorTitle}
        </Typography>
        {open ? (
          <ArrowDropDownIcon sx={{ color: '#90959D', width: '16px' }} />
        ) : (
          <ArrowDropRightIcon sx={{ color: '#90959D', width: '16px' }} />
        )}
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {actorId === '2' && (
          <Box m={'16px 0'}>
            <Typography
              color={'text.disabled'}
              variant="body2"
              sx={{
                mt: '2px',
              }}
            >
              Вы можете ускорить процесс заполнив данные получателя перед
              отправкой
            </Typography>
          </Box>
        )}
        {Object.values(variables).map((variable) => (
          <Box m={'16px 0'} key={variable.id}>
            <VariableInput variable={variable} {...props} />
          </Box>
        ))}
      </Collapse>
    </Box>
  )
}
