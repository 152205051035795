import {
  Box,
  CircularProgress,
  Skeleton,
  TableCell,
  TableRow,
} from '@mui/material'
import { IManageTemplateDocumentHeader } from 'store/slices/manageTemplateData'

export const TableVariablesBodyRowLoading = ({
  header,
  anchorRef,
}: {
  header: IManageTemplateDocumentHeader[]
  anchorRef?: any
}) => {
  return (
    <TableRow>
      <TableCell>
        <CircularProgress color="secondary" />
        {anchorRef && <Box ref={anchorRef} />}
      </TableCell>
      {header.length > 0 &&
        header.map((_, iKey) => (
          <TableCell key={iKey}>
            <Skeleton />
          </TableCell>
        ))}
    </TableRow>
  )
}
