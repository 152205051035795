import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { Box, Collapse, Typography } from '@mui/material'
import { ButtonCopy } from 'components/button/ButtonCopy'
import { IRecipient } from 'helper/recipients'
import { useContext, useState } from 'react'
import { convertDateToLocale } from 'utils/convertDateToLocale'
import {
  ESidebarDocumentTrackingViewMode,
  SidebarDocumentTrackingContext,
} from '../../provider/SidebarDocumentTrackingContext'
import { ERecipientStatus, Recipient } from '../Recipient'
import { RecipientViaLink } from '../RecipientViaLink'

const formatTextToCapitalized = (text: string) => {
  if (!text) return ''
  const arr = text.split(' ')
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].length == 0) continue

    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
  }
  return arr.join(' ')
}

const formatRecipientFIO = (fio: string) => {
  if (!fio) return ''
  return formatTextToCapitalized(fio.toLowerCase())
}

const isSignerBusiness = (r: IRecipient) => {
  if (!r) return false
  if (r.result != 'signed' && r.result != 'approved') return false
  if (r.signature?.bin) return true
  return false
}

const getRecipientStatus = (r: IRecipient, flowIndex: number) => {
  let status = ERecipientStatus.waiting
  if (r.index == flowIndex) {
    status = ERecipientStatus.actual
  } else if (r.index < flowIndex) {
    status = ERecipientStatus.finished
  }

  if (
    !!r?.result &&
    (r.result == 'signed' || r.result == 'approved' || r.result == 'filled')
  ) {
    status = ERecipientStatus.finished
  }
  return status
}

const ButtonCopyRecipientLink = ({ recipient }: { recipient: IRecipient }) => {
  if (recipient.result) return null // ? Recipient уже выполнил необходимые действия
  if (recipient.delivery_method != 'link') return null // ? Не ссылка
  if (!recipient.attrs?.link) return null // ? Хоть и ссылка, но ссылки нет
  return (
    <Box
      sx={{
        m: '0 12px 8px',
      }}
    >
      <ButtonCopy
        fullWidth
        size="small"
        title={{
          copy: 'Скопировать ссылку',
        }}
        copyTextValue={`https://link.doodocs.kz/${recipient.attrs.link}`}
      />
    </Box>
  )
}

export const RecipientsGroup = ({
  title,
  children,
}: {
  title: string
  children: any
}) => {
  const [open, setOpen] = useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <Box>
      <Box
        p="4px 16px"
        onClick={handleClick}
        sx={{ cursor: 'pointer' }}
        display="flex"
      >
        <Typography variant="heading5" mr="4px" pt={0}>
          {title}
        </Typography>
        {open ? (
          <ArrowDropDownIcon sx={{ color: '#90959D', width: '16px' }} />
        ) : (
          <ArrowDropUpIcon sx={{ color: '#90959D', width: '16px' }} />
        )}
      </Box>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{
          px: '2px',
        }}
      >
        {children}
      </Collapse>
    </Box>
  )
}

export const RecipientsFillers = ({
  flowIndex,
  recipients,
}: {
  flowIndex: number
  recipients?: IRecipient[]
}) => {
  if (!recipients || recipients.length == 0) return null
  return (
    <RecipientsGroup
      title={'Заполнение'}
      children={recipients.map((r) => {
        return (
          <Box key={r.origin_id}>
            <Recipient
              status={getRecipientStatus(r, flowIndex)}
              title={r.attrs?.email || `Ссылка "${r?.actor_name}"`}
              key={r.origin_id}
            />
            <ButtonCopyRecipientLink recipient={r} />
          </Box>
        )
      })}
    />
  )
}

export const RecipientsSigners = ({
  flowIndex,
  recipients,
  rType,
  isSingleAnonymousLink,
}: {
  flowIndex: number
  recipients?: IRecipient[]
  rType: 'signers' | 'approvers'
  isSingleAnonymousLink?: boolean
}) => {
  const { setCurrentRecipientProps, setMode } = useContext(
    SidebarDocumentTrackingContext
  )

  if (!recipients || recipients.length == 0) return null
  return (
    <RecipientsGroup
      title={rType == 'signers' ? 'Подписание' : 'Согласование'}
      children={recipients.map((r, i) => {
        const isBusiness = isSignerBusiness(r)
        const status = getRecipientStatus(r, flowIndex)
        const name =
          formatRecipientFIO(r?.signature?.name ?? '') ||
          r.attrs.email ||
          (r.delivery_method == 'link' &&
            r?.actor_name &&
            `Ссылка "${r?.actor_name}"`) ||
          `${rType == 'signers' ? 'Подписант' : 'Согласант'} ${i + 1}`
        const signedAt = r?.signature?.created_at
          ? `${
              rType == 'signers' ? 'Подписано' : 'Согласовано'
            } ${convertDateToLocale(r?.signature?.created_at)}`
          : ''

        const onClick = (e: any) => {
          e.preventDefault()
          if (status == ERecipientStatus.finished) {
            setMode(ESidebarDocumentTrackingViewMode.SignerInfo)
          } else {
            setMode(ESidebarDocumentTrackingViewMode.EditRecipient)
          }

          setCurrentRecipientProps({ ...r })
        }
        if (
          r.delivery_method == 'link' &&
          r.role == 'anonymous_signer_rk' &&
          r.result !== 'signed' &&
          !isSingleAnonymousLink
        )
          return (
            <RecipientViaLink
              key={r.origin_id}
              title={r.attrs.link_name ?? 'Ссылка #'}
              status={status}
              recipient={r}
              onClick={onClick}
            />
          )
        if (isBusiness)
          return (
            <Recipient
              key={r.origin_id}
              status={status}
              title={r.signature?.org_name ?? ''}
              subtitle1={name}
              subtitle2={signedAt}
              onClick={onClick}
            />
          )
        return (
          <Recipient
            key={r.origin_id}
            status={status}
            title={name}
            subtitle2={signedAt}
            onClick={onClick}
          />
        )
      })}
    />
  )
}

export const RecipientsCopy = ({
  isSent,
  ccs,
}: {
  isSent: boolean
  ccs?: {
    email: string
  }[]
}) => {
  if (!ccs || ccs.length == 0) return null
  return (
    <RecipientsGroup
      title={'Копия'}
      children={ccs.map((cc) => {
        return (
          <Recipient
            status={
              isSent ? ERecipientStatus.finished : ERecipientStatus.waiting
            }
            title={cc.email}
            key={cc.email}
          />
        )
      })}
    />
  )
}
