import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { EDocumentStatus, EDocumentType } from 'helper/consts'
import {
  createDocument,
  getDocumentInfo,
  getDocumentLedger,
  getDocumentVariables,
  getPdfFileByLink,
  getRecipientsInfo,
  getWorkflowActorsDocument,
  queryCreateDocumentEditorDraft,
  queryShareDocument,
  queryUnshareDocument,
  queryUpdateDocumentEditorContent,
} from 'service'
import {
  anonymousLinkFetch,
  mgovLinksUpdate,
  wfPostmanActions,
} from './wfPostmanSlice'
import {
  addOptionsField,
  arrVariablesToObject,
  splitVariablesByActors,
} from 'utils/templateVariables'
import {
  getTemplateInfo,
  getTemplateHtml,
  getTemplateVariables,
  getWorkflowTemplateActors,
} from 'service/template'

const MAX_ATTEMPTS = 5

const getDefaultSignSetttingsState = (): ISignSettings => {
  return {
    is_sign_biometric_enabled: false,
    is_sign_egov_enabled: true,
    is_sign_egovbusiness_enabled: true,
    is_sign_nca_enabled: true,
  }
}

const initialState: IApplicationState = {
  document: {
    id: '',
    name: '',
    status: EDocumentStatus.DRAFT,
    template_id: '',
    campaign_id: '',
    author_id: '',
    is_archived: false,
    payload: null,
    link_pdf: '',
    link_html: '',
    link_ddcard: '',
    pdf_size: 0,
    html_size: 0,
    ddcard_size: 0,
    approver_rk_ddcard_size: 0,
    author_email: '',
    author_avatar_link: '',
    last_action_at: '',
    is_shared: false,
    share_link: '',
    content_html: '',
    history: [],
    type: EDocumentType.TEMPLATE,
    content_pdf: '',
    role: '',
  },
  template: {
    version: '',
    updated_at: '',
    name: '',
    author_id: '',
    status: '',
    is_private: false,
    draftVariables: null,
    document_id: null,
    variables: null,
    total_documents: 0,
    id: '',
    content_html: '',
  },
  workspace: {
    id: '',
    public_name: '',
    public_description: '',
    picture_link: '',
  },
  recipients: {
    recipients: [],
    ccs: [],
    state: {
      index: 0,
      status: '',
    },
  },
  sign_settings: getDefaultSignSetttingsState(),
  errors: {
    info: '',
    source: '',
    recipients: '',
    getVariables: '',
    getActors: '',
  },
  loading: {
    info: true,
    source: false,
    recipients: false,
    getVariables: false,
    getActors: false,
  },
  variables: null,
  prefillFormVariables: null,
  actors: null,
}

export const fetchDocumentVariables = createAsyncThunk(
  'application/fetchDocumentVariables',
  async ({
    documentId,
    templateId,
  }: {
    documentId: string
    templateId?: string
  }) => {
    try {
      const res = !documentId
        ? await getTemplateVariables({ templateId })
        : await getDocumentVariables({ documentId })

      const { variables } = res.data

      if (!variables) throw new Error('Нет переменных')

      const objVariables = arrVariablesToObject(variables)
      const groupedData = splitVariablesByActors(variables, objVariables)
      const result = addOptionsField(groupedData, variables)

      return {
        objVariables,
        prefillFormVariables: result,
      }
    } catch (error: any) {
      throw new Error(error.message)
    }
  }
)

export const fetchActorsDocuments = createAsyncThunk(
  'application/fetchActorsDocuments',
  async (
    { documentId, templateId }: { documentId: string; templateId?: string },
    { rejectWithValue }
  ) => {
    try {
      const res = !documentId
        ? await getWorkflowTemplateActors({ templateId: templateId || '' })
        : await getWorkflowActorsDocument({ documentId })
      return res.data.actors || []
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateDocumentStatusAfterSign = createAsyncThunk<
  string,
  { id: string; logEvent: any },
  { rejectValue: string }
>(
  'application/updateStatusAfterSign',
  async ({ id, logEvent }, { rejectWithValue }) => {
    try {
      const res = await getDocumentInfo({ id })
      const { document } = res.data
      const { status } = document

      logEvent(status === 'completed')

      return status
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchTemplateInfoApplication = createAsyncThunk(
  'application/fetchTemplateInfoApplication',
  async ({ templateId }: { templateId: string }, { rejectWithValue }) => {
    try {
      const res = await getTemplateInfo({ id: templateId })
      const res_content = await getTemplateHtml({ id: templateId })
      const content_html = res_content.data

      return { ...res.data.template, content_html }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const fileDataFetch = createAsyncThunk(
  'application/fileDataFetch',
  async ({ id }: { id: string }, { dispatch }) => {
    let result = await getDocumentInfo({ id })

    for (let i = 0; i < MAX_ATTEMPTS; i++) {
      const status = result?.data?.document

      if (!status || status === EDocumentStatus.PREPARING)
        await new Promise((r) => setTimeout(r, 1500))
      else break

      result = await getDocumentInfo({ id })
    }

    const { document } = result.data
    const { status, type: document_type, link_pdf, link_html } = document

    if (status === EDocumentStatus.PREPARING)
      throw new Error('Ваш документ готовится, пожалуйста, обновите страницу')

    const ledger = await getDocumentLedger({ id })

    if (
      document_type === EDocumentType.TEMPLATE ||
      document_type === EDocumentType.EDITOR
    )
      await dispatch(fileHtmlUpdate(link_html))
    else if (document_type === EDocumentType.PDF)
      dispatch(filePdfUpdate({ link_pdf }))

    if (status === EDocumentStatus.SENT) {
      await dispatch(mgovLinksUpdate({ id }))
      await dispatch(anonymousLinkFetch({ id, i: 0, update: false }))
    }

    return {
      ...result.data,
      document: {
        ...result.data.document,
        history: ledger.data,
      },
    }
  }
)

export const fileHtmlUpdate = createAsyncThunk(
  'application/updateFileHtml',
  async (link_html: string) => {
    const resp = await axios(link_html, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return {
      content_html: resp.data,
    }
  }
)

export const filePdfUpdate = createAsyncThunk(
  'application/updateFilePdf',
  async ({ link_pdf }: { link_pdf: string }) => {
    const { url } = await getPdfFileByLink({ link: link_pdf })
    return {
      content_pdf: url,
    }
  }
)

export const updateInfo = createAsyncThunk(
  'application/updateInfo',
  async ({ id }: any, { dispatch, rejectWithValue }) => {
    try {
      const res = await getDocumentInfo({ id })
      const ledger = await getDocumentLedger({ id })
      const { data } = ledger
      const { status, name, is_shared, share_link } = res.data.document

      if (status !== EDocumentStatus.SENT)
        dispatch(wfPostmanActions.clearLinks())

      return {
        history: data,
        status,
        name,
        is_shared,
        share_link,
      }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const resetDocumentEditor = createAsyncThunk(
  'application/resetDocumentEditor',
  async ({ id }: any) => {
    return { id }
  }
)

export const shareDocument = createAsyncThunk(
  'application/shareDocument',
  async ({ id }: any) => {
    try {
      const resp = await queryShareDocument({ id })
      return { share_link: resp.data.link, is_shared: true }
    } catch (error: any) {
      //   return rejectWithValue(error.message)
      throw new Error(error.message)
    }
  }
)

export const unshareDocument = createAsyncThunk(
  'application/unshareDocument',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      await queryUnshareDocument({ id })
      return { is_shared: false, share_link: '' }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

interface SavePayload {
  teamspace_id: string
  id: string
  name: string
  content_html: string
}

interface CreatePayload {
  teamspace_id: string
  name: string
  content_html: string
}

interface UpdateContentPayload {
  document_id: string
  content_html: string
}

export const saveDocumentEditor = createAsyncThunk(
  'application/saveDocumentEditor',
  async (
    { teamspace_id, id, name, content_html }: SavePayload,
    { rejectWithValue }
  ) => {
    try {
      if (!content_html) content_html = '<p></p>'

      if (!id) {
        const resp = await queryCreateDocumentEditorDraft({
          teamspace_id: teamspace_id,
          document_name: name,
          content: content_html,
        })

        return {
          id: resp.data.document_id,
          name: name,
          content_html: content_html,
        }
      }

      await queryUpdateDocumentEditorContent({
        document_id: id,
        content: content_html,
      })

      return {
        id: id,
        content_html: content_html,
      }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const createDocumentEditor = createAsyncThunk(
  'application/createDocumentEditor',
  async (
    { teamspace_id, name, content_html }: CreatePayload,
    { rejectWithValue }
  ) => {
    try {
      if (!content_html) content_html = '<p></p>'

      const resp = await queryCreateDocumentEditorDraft({
        teamspace_id: teamspace_id,
        document_name: name,
        content: content_html,
      })

      return {
        id: resp.data.document_id,
        name: name,
        content_html: content_html,
      }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateDocumentEditorContent = createAsyncThunk(
  'application/updateDocumentEditorContent',
  async (
    { document_id, content_html }: UpdateContentPayload,
    { rejectWithValue }
  ) => {
    try {
      await queryUpdateDocumentEditorContent({
        document_id: document_id,
        content: content_html,
      })

      return {
        content_html: content_html,
      }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const getRecipients = createAsyncThunk(
  'application/getRecipients',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      const res = await getRecipientsInfo({ id })
      const data = res.data
      return {
        recipients: {
          recipients: data.recipients,
          state: data.state,
          ccs: data.ccs,
          attrs: data.attrs,
        },
      }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

const findFirstNonEmptyString = (arr: string[]): string[] => {
  for (const str of arr) {
    if (str.trim() !== '') {
      return [str]
    }
  }
  return []
}

export const mergeTemplateAndDocumentVariables = createAsyncThunk(
  'application/mergeTemplateAndDocumentVariables',
  async (
    { templateVariables, documentVariables }: any,
    { rejectWithValue }
  ) => {
    try {
      const result = {}

      Object.keys(templateVariables).forEach((key) => {
        const { has_default, attrs } = templateVariables[key]
        let value = has_default
          ? attrs?.default_value
          : documentVariables[key] || ''

        if (Array.isArray(value)) {
          value = findFirstNonEmptyString(value)
        }

        result[key] = value
      })

      return { variables: result }
    } catch (error: any) {
      rejectWithValue(error.message)
    }
  }
)

export const createNewDocument = createAsyncThunk(
  'application/createNewDocument',
  async ({ name, template_id, tsId, urlId }: any) => {
    const res = await createDocument({
      body: {
        document_name: name,
        template_id,
      },
      tsId,
    })
    urlId.setIfNotExists(res.data.document_id)
    return { document_id: res.data.document_id }
  }
)

export const ErrorPublish = {
  ['missing-field-for-template']: 'Заполните поля в шаблоне',
}

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    reset: () => initialState,
    clearErrors: (state) => {
      state.errors.source = ''
    },
    setFileLoadError: (state, action) => {
      state.errors.source = action.payload
    },
    setDocumentInfo(state, action) {
      state.document = {
        ...state.document,
        ...action.payload,
      }
    },
    setDocumentLinkDDC(state, action: PayloadAction<string>) {
      state.document.link_ddcard = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateDocumentStatusAfterSign.fulfilled, (state, action) => {
        state.document.status = action.payload
      })
      .addCase(updateDocumentStatusAfterSign.rejected, (state, action) => {
        state.errors.source = action.error.message || ''
      })
      .addCase(fileDataFetch.pending, (state) => {
        state.loading.info = true
      })
      // TODO: add tests
      .addCase(fileDataFetch.fulfilled, (state, action) => {
        state.document = {
          ...state.document,
          ...action.payload.document,
        }
        state.sign_settings = {
          ...getDefaultSignSetttingsState(),
          ...action.payload?.sign_settings,
        }
        state.template = action.payload.template
        state.loading.info = false
        state.loading.source = false
      })
      .addCase(fileDataFetch.rejected, (state) => {
        state.loading.info = false
      })
      .addCase(fileHtmlUpdate.pending, (state) => {
        state.loading.source = true
      })
      .addCase(
        fileHtmlUpdate.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.document.content_html = action.payload.content_html
          state.loading.source = false
        }
      )
      .addCase(filePdfUpdate.pending, (state) => {
        state.loading.source = true
      })
      .addCase(filePdfUpdate.fulfilled, (state, action: PayloadAction<any>) => {
        state.document.content_pdf = action.payload.content_pdf
        state.loading.source = false
      })
      .addCase(resetDocumentEditor.fulfilled, (state, action) => {
        state.document.id = action.payload.id
        state.document = {
          ...state.document,
          name: 'Новый документ',
          content_html: '<p></p>',
          status: EDocumentStatus.DRAFT,
          template_id: '',
          history: [],
          is_shared: false,
          share_link: '',
          type: EDocumentType.EDITOR,
        }
        state.loading.info = false
        state.loading.source = false
        state.sign_settings = getDefaultSignSetttingsState()
      })
      .addCase(shareDocument.fulfilled, (state, action) => {
        state.document.is_shared = action.payload.is_shared
        state.document.share_link = action.payload.share_link
      })
      .addCase(createNewDocument.fulfilled, (state, action) => {
        state.document.id = action.payload.document_id
      })
      .addCase(unshareDocument.fulfilled, (state, action) => {
        state.document.is_shared = action.payload.is_shared
        state.document.share_link = action.payload.share_link
      })
      .addCase(updateInfo.pending, (state) => {
        state.loading.info = true
      })
      .addCase(updateInfo.fulfilled, (state, action) => {
        state.loading.info = false
        state.document.history = action.payload.history
        state.document.status = action.payload.status
        state.document.name = action.payload.name
        state.document.is_shared = action.payload.is_shared
        state.document.share_link = action.payload.share_link
      })
      .addCase(updateInfo.rejected, (state, action) => {
        state.loading.info = false
        state.errors.source = action.error.message || 'Произошла ошибка'
      })
      .addCase(getRecipients.fulfilled, (state, action) => {
        state.loading.recipients = false
        state.recipients = action.payload.recipients
      })
      .addCase(getRecipients.rejected, (state) => {
        state.loading.recipients = false
      })
      .addCase(mergeTemplateAndDocumentVariables.pending, (state) => {
        state.loading.getVariables = true
      })
      .addCase(mergeTemplateAndDocumentVariables.fulfilled, (state, action) => {
        state.document.payload = action.payload as any
        state.loading.getVariables = false
      })
      .addCase(mergeTemplateAndDocumentVariables.rejected, (state) => {
        state.loading.getVariables = false
      })
      .addCase(saveDocumentEditor.fulfilled, (state, action) => {
        state.loading.source = false
        state.document.content_html = action.payload.content_html
        state.document.id = action.payload.id
        state.document.name = action.payload.name ?? state.document.name
      })
      .addCase(fetchDocumentVariables.pending, (state) => {
        state.loading.getVariables = true
      })
      .addCase(fetchDocumentVariables.fulfilled, (state, action) => {
        state.variables = action.payload.objVariables
        state.prefillFormVariables = action.payload.prefillFormVariables
        state.loading.getVariables = false
      })
      .addCase(fetchDocumentVariables.rejected, (state, action) => {
        state.loading.getVariables = false
        state.errors.getVariables = action.error.message || 'Произошла ошибка'
      })
      .addCase(fetchActorsDocuments.pending, (state) => {
        state.loading.getActors = true
      })
      .addCase(fetchActorsDocuments.fulfilled, (state, action) => {
        state.actors = action.payload
        state.loading.getActors = false
      })
      .addCase(fetchActorsDocuments.rejected, (state) => {
        state.loading.getActors = false
      })
      .addCase(fetchTemplateInfoApplication.pending, (state) => {
        state.loading.info = true
      })
      .addCase(fetchTemplateInfoApplication.fulfilled, (state, action) => {
        state.template = action.payload as any
        state.loading.info = false
      })
      .addCase(fetchTemplateInfoApplication.rejected, (state, action) => {
        state.loading.info = false
        state.errors.info = action.error.message || 'Произошла ошибка'
      })
  },
})

export const {
  clearErrors,
  setDocumentInfo,
  setFileLoadError,
  reset,
  setDocumentLinkDDC,
} = applicationSlice.actions

export default applicationSlice.reducer
