import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { wsPlanGetPlan } from './wsPlanGetPlan'
import { wsPlanStartTrial } from './wsPlanStartTrial'
import { wsPlanGetPaymentsHistory } from './wsPlanGetPaymentsHistory'

export interface IRequisites {
  company_name: string
  bin: string
  legal_address: string
}

export interface ICard {
  card_mask: string
}

export type TPaymentMethodType = 'CARD' | 'INVOICE'

export interface IPaymentNotification {
  must_pay: boolean,
  payment_id?: string,
  payment_type?: TPaymentMethodType
}

export interface IPayment {
  id: string
  no: number
  created_at: string
  updated_at: string
  status: 'AWAITING_PAYMENT' | 'PAID' | 'ISSUED' | 'FAILED'
  total_months: number
  price: number
  total_price: number
  subscription_name: 'trial:v2' | 'start:v2' | 'lite:v2' | 'pro:v2'
  document_url?: string
  payment_url?: string
  payment_method_type: TPaymentMethodType
}

export interface IWSPlan {
  loading: { plan: boolean; payments_history: boolean }
  error: { plan: string; payments_history: string }
  workspace_id: string
  plan_name: string
  plan_info: IPlanInfo
  switch_plan: {
    loading: boolean
    error: string
  }
  require_closing_documents: boolean

  requisites?: IRequisites
  card?: ICard
  payments?: IPayment[]
  payment_notification: IPaymentNotification
}

const getWsPlanDefaultState = (): IWSPlan => ({
  workspace_id: '',
  loading: { plan: false, payments_history: false },
  error: { plan: '', payments_history: '' },
  plan_name: '',
  plan_info: {
    workspace_limits: {
      documents_count: 0,
      documents_size: 0,
      templates_count: 0,
      campaigns_count: 0,
      teamspaces_count: 0,
      workspace_users_count: 0,
      current_subscription_end_at: '',
      current_subscription_ends_in_secs: 0,
    },
    plan_limits: {
      name: '',
      documents_count: 0,
      documents_size: 0,
      templates_count: 0,
      campaigns_count: 0,
      teamspaces_count: 0,
      workspace_users_count: 0,
      workflow_email_send_access: false,
      document_ai_access: false,
    },
    is_trial_available: false,
  },
  switch_plan: {
    loading: false,
    error: '',
  },
  require_closing_documents: false,
  payment_notification: {
    must_pay: false
  }
})

export const wsPlanSlice = createSlice({
  name: 'wsPlan',
  initialState: getWsPlanDefaultState(),
  reducers: {
    clearPlanErrors: (state) => {
      state.switch_plan.error = ''
      state.error.plan = ''
    },
    setRequisites: (state, action: PayloadAction<IRequisites | undefined>) => {
      state.requisites = action.payload
    },
    setRequireClosingDocuments: (state, action: PayloadAction<boolean>) => {
      state.require_closing_documents = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(wsPlanStartTrial.fulfilled, (state) => {
      state.loading.plan = false
    })
    builder.addCase(wsPlanStartTrial.pending, (state) => {
      state.loading.plan = true
    })

    builder.addCase(wsPlanStartTrial.rejected, (state, action) => {
      state.loading.plan = false
      state.switch_plan.error = action.error.message || 'Error plan switch'
    })

    builder
      .addCase(wsPlanGetPlan.rejected, (state, action) => {
        state.error.plan = action.error.message || 'Error getting plan'
        state.loading.plan = false
      })
      .addCase(wsPlanGetPlan.pending, (state) => {
        state.loading.plan = true
      })
      .addCase(wsPlanGetPlan.fulfilled, (state, action) => {
        const { data, planName, wsId } = action.payload
        state.workspace_id = wsId
        state.plan_name = planName
        state.card = data.card
        state.requisites = data.requisites
        state.require_closing_documents = data.require_closing_documents
        state.payment_notification = data.payment_notification

        state.plan_info.is_trial_available = data.is_trial_available
        state.plan_info.workspace_limits = data.workspace_limits
        state.plan_info.plan_limits = {
          ...data.plan_limits,
          name: planName,
        }
        state.plan_info.previous_plan = data.previous_plan

        state.loading.plan = false
      })
      .addCase(wsPlanGetPaymentsHistory.pending, (state) => {
        state.loading.payments_history = true
      })
      .addCase(wsPlanGetPaymentsHistory.fulfilled, (state, action) => {
        state.loading.payments_history = false
        state.payments = action.payload
      })
      .addCase(wsPlanGetPaymentsHistory.rejected, (state, action) => {
        state.loading.payments_history = false
        state.error.payments_history =
          action.error.message || 'Error getting payments history'
      })
  },
})

export const { clearPlanErrors } = wsPlanSlice.actions
