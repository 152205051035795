import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { MenuItemButtonSelectTeamspace } from 'components/menu-item/MenuItemButtonSelectTeamspace'
import { ModalCenterSmall } from 'components/modal'
import { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

const getTemplatesCountText = (count: number) => {
  if (count === 1) return 'шаблон'
  if (count < 5) {
    return `${count} шаблона`
  }
  if (count % 100 > 20) {
    return getTemplatesCountText(count % 10)
  }
  return `${count} шаблонов`
}

export const ModalMoveTemplatesBetweenTeamspaces = ({
  open,
  handleClose,
  templateIds,
  actionMoveTemplates,
}: {
  open: boolean
  handleClose?: () => Promise<any> | any
  templateIds: string[]
  actionMoveTemplates: (props: {
    templateIds: string[]
    teamspaceId: string
  }) => any
}) => {
  const { teamspaces, selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )
  const [selectedId, setSelectedId] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    setLoading(true)
    await actionMoveTemplates({
      teamspaceId: selectedId,
      templateIds: templateIds,
    })
    setLoading(false)
  }

  return (
    <ModalCenterSmall
      open={open}
      handleClose={handleClose}
      header={{
        children: (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="heading5">
              Переместить {getTemplatesCountText(templateIds.length)}
            </Typography>
            <Typography variant="paragraph4">
              Выберите группу в которую хотите переместить шаблон
            </Typography>
          </Box>
        ),
      }}
      content={{
        noPaddings: true,
        children: (
          <Box my={'8px'}>
            {teamspaces.map(
              (el) =>
                el.id != selectedTeamspace?.id && (
                  <MenuItemButtonSelectTeamspace
                    key={el.id}
                    teamspace={el}
                    onClick={() => setSelectedId(el.id)}
                    checked={el.id == selectedId}
                    disabled={loading}
                  />
                )
            )}
          </Box>
        ),
      }}
      footer={{
        children: (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
            }}
          >
            <Button
              variant="contained"
              disabled={
                !selectedId || selectedId == selectedTeamspace?.id || loading
              }
              sx={{
                gap: '4px',
              }}
              onClick={handleClick}
            >
              {loading && (
                <CircularProgress
                  sx={{
                    color: 'text.disabled',
                  }}
                />
              )}
              Переместить
            </Button>
          </Box>
        ),
      }}
    />
  )
}
