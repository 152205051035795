import { Box } from '@mui/material'
import { StyledChip } from 'components/chip'
import img_close from 'assets/img/close.svg'

export const TextFieldEmailsTag = ({
  label,
  onDelete,
  disabled,
}: {
  label?: string
  onDelete?: (event: any) => void
  disabled?: boolean
}) => {
  return (
    <StyledChip
      variant="filled"
      label={label}
      disabled={disabled}
      sx={{
        m: '2px',
      }}
      onDelete={onDelete}
      deleteIcon={
        <Box
          sx={{ width: '20px', height: '20px' }}
          display="flex"
          justifyContent={'center'}
          alignItems="center"
        >
          <Box component={'img'} src={img_close} width="12px" height={'12px'} />
        </Box>
      }
    />
  )
}
