import { CircularProgress, Container } from '@mui/material'
import { Error404 } from 'pages'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { checkLogin } from 'store/slices/userSlice'
import { auth } from 'utils/auth'

const PrivateRoute = () => {
  const { authenticated, loading, error } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkLogin() as any)
  }, [dispatch])

  if (loading) {
    return (
      <Container
        sx={{
          display: 'flex',
          height: '100vh',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Container>
    )
  }
  if (error) {
    return <Error404 message={error} description={''} />
  }
  if (!authenticated) {
    auth.savePathRedirectAfterLogin(
      window.location.pathname + window.location.search
    )
    return <Navigate to="/login" />
  }
  return <Outlet />
}

export default PrivateRoute
