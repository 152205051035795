import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  ToggleButtonGroupProps,
  Select,
  MenuItem,
} from '@mui/material'
import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { TextInput } from 'components'
import { MenuItemButtonWithToggle } from 'components/menu-item/menu-item-button/MenuItemButtonWithToggle'
import {
  IRecipientAttrsFilter,
  ERecipientAttrsFilterSubjectType,
} from 'helper/workflow/recipients/types'

const ToggleButtonGroupCompound = (props: ToggleButtonGroupProps) => {
  return <ToggleButtonGroup {...props} />
}

ToggleButtonGroupCompound.Button = ({ value, ...props }) => {
  return (
    <ToggleButton
      value={value}
      {...props}
      sx={{
        p: '1px 8px',
        textTransform: 'none',
        fontWeight: 400,
        color: 'secondary.main',
      }}
    />
  )
}

export const CompanyRoles = [
  {
    label: 'Первый руководитель юридического лица',
    value: 'ceo',
  },
  {
    label: 'Лицо, наделенное правом подписи',
    value: 'employee-can-sign',
  },
  {
    label: 'Лицо, наделенное правом подписи финансовых документов',
    value: 'employee-can-sign-finance',
  },
  {
    label: 'Сотрудник отдела кадров',
    value: 'hr',
  },
  {
    label: 'Сотрудник организации',
    value: 'employee',
  },
]

export const CompanyRoleValuesLabels = (() => {
  const result = {}
  CompanyRoles.forEach((item) => {
    result[item.value] = item.label
  })
  return result
})()

export const SettingCheckSign = ({
  mustSubmitOnUnmout,
}: {
  mustSubmitOnUnmout?: boolean
}) => {
  const formikCtx = useFormikContext<IRecipientAttrsFilter>()

  useEffect(() => {
    if (mustSubmitOnUnmout) return formikCtx.handleSubmit
  }, [])

  useEffect(() => {
    if (!formikCtx.values?._subjectType)
      formikCtx.setFieldValue(
        '_subjectType',
        ERecipientAttrsFilterSubjectType.person
      )
  }, [formikCtx.values?._mustCheckSign])

  const getRestrictionTypeSelect = () => {
    return (
      <Box
        p={'4px 12px'}
        m={'2px 4px'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography>Получатель</Typography>
        <ToggleButtonGroupCompound
          exclusive
          size="small"
          onChange={(e, value) => {
            if (!value) return
            formikCtx.setFieldValue('_subjectType', value)
          }}
          value={formikCtx.values._subjectType}
        >
          <ToggleButtonGroupCompound.Button
            value={ERecipientAttrsFilterSubjectType.person}
          >
            Физ лицо
          </ToggleButtonGroupCompound.Button>
          <ToggleButtonGroupCompound.Button
            value={ERecipientAttrsFilterSubjectType.company}
          >
            Юр лицо
          </ToggleButtonGroupCompound.Button>
        </ToggleButtonGroupCompound>
      </Box>
    )
  }

  const getSelectedRestriction = () => {
    return (
      <Box p={'4px 12px 8px'} m={'2px 4px'}>
        {formikCtx.values._subjectType ===
          ERecipientAttrsFilterSubjectType.person && (
          <>
            <Typography variant="body1">ИИН</Typography>
            <TextInput
              size="small"
              fullWidth
              placeholder="Введите ИИН..."
              name="iin"
              value={formikCtx.values.iin}
              onChange={formikCtx.handleChange}
            />
            <Typography variant="body2" color={'text.disabled'}>
              Получатель только с этим ИИН сможет подписать документ
            </Typography>
          </>
        )}
        {formikCtx.values._subjectType ===
          ERecipientAttrsFilterSubjectType.company && (
          <>
            <Box>
              <Typography variant="body1">БИН</Typography>
              <TextInput
                size="small"
                fullWidth
                placeholder="Введите БИН..."
                name="bin"
                value={formikCtx.values.bin}
                onChange={formikCtx.handleChange}
              />
            </Box>
            {formikCtx.values.bin && !formikCtx.errors.bin && (
              <Box mt={1}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '4px',
                  }}
                >
                  <Typography variant="body1">Роль подписанта</Typography>
                  <Typography variant="body1" color={'text.disabled'}>
                    (Не Обязательно)
                  </Typography>
                </Box>
                <Select
                  sx={{
                    mt: '4px',
                  }}
                  name="role"
                  fullWidth
                  displayEmpty
                  defaultValue=""
                  value={formikCtx.values.role}
                  onChange={formikCtx.handleChange}
                >
                  <MenuItem value="">Не выбрано</MenuItem>
                  {CompanyRoles.map((role) => (
                    <MenuItem value={role.value} key={role.value}>
                      {role.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
            <Typography variant="body2" color={'text.disabled'} mt={'2px'}>
              Получатель только с этим БИН и ролью сможет подписать документ
            </Typography>
          </>
        )}
      </Box>
    )
  }

  return (
    <>
      <MenuItemButtonWithToggle
        title={'Проверка подписи'}
        checked={formikCtx.values?._mustCheckSign ?? false}
        onClick={() =>
          formikCtx.setFieldValue(
            '_mustCheckSign',
            !formikCtx.values?._mustCheckSign
          )
        }
      />
      {formikCtx.values?._mustCheckSign && (
        <Box>
          {getRestrictionTypeSelect()}
          {getSelectedRestriction()}
        </Box>
      )}
    </>
  )
}
