import plus_icon from 'assets/img/plus_white.svg'

import { Box, Button, Typography } from '@mui/material'
import { ERegistryType } from 'components/breadcrumbs/BreadcrumbsBlock'
import { HeaderWithBreadcrumbs } from 'components/header/HeaderWithBreadcrumbs'
import { AppBarCommon } from 'components/header/app-bar-common'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { ModalPlanLimit } from 'components/modal/ModalPlanLimit'
import { AnalyticsEvent } from 'config/analytics/segment.com/events'
import { ModalCreateCampaign } from 'features/bulkSend/createCampaign'
import { EFeature } from 'helper/plan.types'
import { TSRolePermissions } from 'helper/roles'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { shallowEqual, useSelector } from 'react-redux'
import { getWSPlanFeatureRestriction } from 'utils/plans'

export const Header = () => {
  const { selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )

  return (
    <>
      <AppBarCommon
        headerTitle={
          <HeaderWithBreadcrumbs
            breadcrumb={{
              registry: { value: ERegistryType.MassSend },
            }}
            rightChildren={
              selectedTeamspace &&
              TSRolePermissions[selectedTeamspace?.group_name]
                .canManageDocs && <CreateCampaignButton />
            }
          />
        }
      />
    </>
  )
}

export const CreateCampaignButton = () => {
  const [open, setOpen] = useState(false)
  const [openModalLimit, setOpenModalLimit] = useState(false)
  const [openModalRestrictedOnMobile, setOpenModalRestrictedOnMobile] =
    useState(false)

  const { plan_info } = useSelector(
    (state: RootState) => state.wsPlan,
    shallowEqual
  )

  const { selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )

  const handleOpen = () => {
    const { prohibited, exhausted } = getWSPlanFeatureRestriction(
      EFeature.mass_send,
      plan_info
    )
    if (prohibited || exhausted) {
      setOpenModalLimit(true)
      return
    }
    if (isMobile) {
      setOpenModalRestrictedOnMobile(true)
      return
    }

    AnalyticsEvent.send_doc_start('yes')
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  return (
    <>
      {selectedTeamspace &&
        TSRolePermissions[selectedTeamspace?.group_name].canManageDocs && (
          <Box>
            <Button
              variant={'contained'}
              onClick={handleOpen}
              sx={{
                minWidth: { xs: '40px' },
                p: isMobile ? '9px' : '',
              }}
              startIcon={
                !isMobile && (
                  <Box
                    component={'img'}
                    src={plus_icon}
                    width={'20px'}
                    height={'20px'}
                  />
                )
              }
            >
              {isMobile && (
                <Box
                  component={'img'}
                  src={plus_icon}
                  width={'20px'}
                  height={'20px'}
                />
              )}
              {isMobile ? '' : 'Новая рассылка'}
            </Button>
          </Box>
        )}
      <ModalCreateCampaign handleClose={handleClose} open={open} />
      <ModalPlanLimit
        handleClose={() => {
          setOpenModalLimit(false)
        }}
        open={openModalLimit}
        limitOn={EFeature.mass_send}
      />
      <ModalCenterSelectableWithTitle
        handleClose={() => {
          setOpenModalRestrictedOnMobile(false)
        }}
        modalType="small"
        open={openModalRestrictedOnMobile}
        title={'Рассылка документов'}
        contentChild={
          <Typography>
            Рассылка документов не поддерживается на мобильной версии
          </Typography>
        }
      />
    </>
  )
}
