import { EServiceWorkflowStepType } from "helper/workflow.service.types"
import { CRecipientApproverRK } from "../recipients/CRecipientApproverRK"
import { CRecipientSignerRK } from "../recipients/CRecipientSignerRK"
import { CRecipientAnonymousSignerRK } from "../recipients/CRecipientAnonymousSignerRK"
import { CAbstractStep } from "./CAbstractStep"
import { CRecipientAnonymousApproverRK } from "../recipients/CRecipientAnonymousApproverRK"

type TRecipient = CRecipientSignerRK | CRecipientApproverRK | CRecipientAnonymousSignerRK | CRecipientAnonymousApproverRK

export class CStepSign extends CAbstractStep {
  index: number
  type: EServiceWorkflowStepType.sign = EServiceWorkflowStepType.sign
  recipients: TRecipient[]

  constructor(obj: {
    index: number,
    recipients: TRecipient[]
  }) {
    super()

    this.index = obj.index
    this.recipients = obj.recipients
  }
}
