import img_email from 'assets/img/ic_email.svg'
import img_link from 'assets/img/hyperlink.svg'

import { Box, Typography } from '@mui/material'
import { ButtonSmall } from '../../ButtonSmall'
import { useContext, useMemo, useState } from 'react'
import { WorfklowBuilderUIContext } from '../../../../../providers/WorkflowBuilderUIContext'
import { CRecipientFiller } from 'helper/workflow/recipients/CRecipientFiller'
import { getObjectClone } from 'utils/getObjectClone'
import { shallowEqual, useSelector } from 'react-redux'
import { EmailFieldWithMenu } from 'components/text-fields/EmailFieldWithMenu'
import { CRecipientSignerRK } from 'helper/workflow/recipients/CRecipientSignerRK'
import { CRecipientAnonymousSignerRK } from 'helper/workflow/recipients/CRecipientAnonymousSignerRK'
import { isValidEmail } from 'utils/isValidEmail'

// ! Create Context for RecipientFiller
// const RecipientFillerFieldsContext = createContext({})

export const ButtonsOnHoverAddNewRecipientFiller = ({
  actorId,
}: {
  actorId: number
}) => {
  const workflowBuilderUI = useContext(WorfklowBuilderUIContext)
  const [showEmailInput, setShowEmailInput] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const handleClickCreateLink = () => {
    const state = workflowBuilderUI.workflow
    state.insertRecipient(
      new CRecipientFiller({
        actor_id: actorId,
        attrs: {
          must_send_email: false,
        },
      })
    )
    state.insertRecipient(
      new CRecipientAnonymousSignerRK({
        actor_id: actorId,
        attrs: {
          limit: 1,
        },
      })
    )
    workflowBuilderUI.setWorkflow(getObjectClone(state))
  }

  const handleClickCreateEmail = () => {
    setShowEmailInput(true)
  }

  if (showEmailInput)
    return (
      <Box>
        <EmailFieldWithMenuForFiller
          actorId={actorId}
          setVisible={setShowEmailInput}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      </Box>
    )

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        gap: '8px',
      }}
    >
      <ButtonSmall
        className="hidden-element"
        prefixImgSrc={img_email}
        text="Email"
        tooltip="Введите электронный адрес получателя, он получит письмо с ссылкой на документ"
        onClick={handleClickCreateEmail}
      />
      <ButtonSmall
        className="hidden-element"
        prefixImgSrc={img_link}
        text="Создать ссылку"
        tooltip="Создайте ссылку, которой можно легко поделиться с кем угодно"
        onClick={handleClickCreateLink}
      />
      <Box
        sx={{
          p: '2px 0',
          position: 'absolute',
          top: '0',
          left: '0',
          pointerEvents: 'none',
        }}
        className="hide-element"
      >
        <Typography variant="paragraph3" color={'#90959D'}>
          Добавить получателя
        </Typography>
      </Box>
    </Box>
  )
}

const EmailFieldWithMenuForFiller = ({
  setVisible,
  setInputValue,
  inputValue,
  actorId,
}: {
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>
  setInputValue: React.Dispatch<React.SetStateAction<string>>
  inputValue: string
  actorId: number
}) => {
  const workflowLauncherUIDefaultValue = useContext(WorfklowBuilderUIContext)
  const { members } = useSelector(
    (state: RootState) => state.workspace,
    shallowEqual
  )

  const optionEmails = useMemo(() => {
    return members.map((member) => member.email)
  }, [workflowLauncherUIDefaultValue.workflow, members])

  const addEmailToList = (email: string) => {
    if (!email) return false
    if (!isValidEmail(email)) return false

    const state = workflowLauncherUIDefaultValue.workflow
    const inserted = state.insertRecipient(
      new CRecipientFiller({
        actor_id: actorId,
        attrs: {
          email: email,
          must_send_email: true,
        },
      })
    )

    if (inserted) {
      // ! Maybe replace?
      state.insertRecipient(
        new CRecipientSignerRK({
          actor_id: actorId,
          attrs: {
            email: email,
          },
        })
      )
      workflowLauncherUIDefaultValue.setWorkflow(getObjectClone(state))
      !!setVisible && setVisible(false)
    }
    return inserted
  }

  return (
    <EmailFieldWithMenu
      input={{
        inputValue,
        setInputValue,
      }}
      placeholder="Введите email"
      addEmailToList={addEmailToList}
      options={optionEmails}
      onBlurCallback={() => setVisible && setVisible(false)}
    />
  )
}
