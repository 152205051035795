import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { deleteInvitee, deleteMember } from 'service/teampspaces'
import { workspaceApi } from 'service/workspaces'
import { TRootState } from 'store/store'
import { selectedWorkspaceSlice } from '../selectedWorkspace'
import { clearTeamspaces, selectTeamspace } from '../teamspaceSlice'

const initialWorkspaceState: IWorkspaceState = {
  loading: {
    create: false,
    workspaces: true,
    update: false,
    deleteWs: false,
    leaveWs: false,
    members: false,
    groups: false,
    invites: false,
    delete_member: false,
  },
  error: {
    create: null,
    workspaces: null,
    update: null,
    deleteWs: null,
    leaveWs: null,
    members: null,
    groups: null,
    invites: null,
    delete_member: null,
  },
  workspaces: null,
  notification: {
    create: null,
    update: null,
    deleteWs: null,
    leaveWs: null,
    delete_member: null,
    create_invite: null,
  },
  members: [],
  groups: [],
  invites: [],
}

interface CreateWorkspaceInvitesArgs {
  wsId: string
  invites: string[]
  option?: string
  callback?: () => void
}

export const createWorkspaceInvites = createAsyncThunk(
  'workspace/createWorkspaceInvites',
  async (
    { wsId, invites, option, callback }: CreateWorkspaceInvitesArgs,
    { rejectWithValue }
  ) => {
    try {
      const res = await workspaceApi.fetchWorkspaceGroups({ id: wsId })
      const { groups } = res.data
      const group_id =
        groups.find((group) => group.name === option)?.id || groups[0].id

      const requests = invites.map((invite) =>
        workspaceApi.createInvite({
          invite: {
            email: invite,
            group_id,
          },
        })
      )

      await Promise.all(requests)
      if (callback) {
        callback()
      }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const fetchWorkspaceMembers = createAsyncThunk(
  'workspace/fetchWorkspaceMembers',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await workspaceApi.getWorkspaceMembers({ id })
      return response.data.members
    } catch (error: any) {
      return rejectWithValue(
        error.message ?? 'Error fetching workspace members'
      )
    }
  }
)

export const fetchGroupsWs = createAsyncThunk(
  'workspace/fetchGroupsWs',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await workspaceApi.getWorkspaceGroups({ id })
      return response.data.groups
    } catch (error: any) {
      return rejectWithValue(error.message ?? 'Error fetching workspace groups')
    }
  }
)

export const fetchWorkspaceInvites = createAsyncThunk(
  'workspace/fetchWorkspaceInvites',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await workspaceApi.getWorkspaceInvites({ id })
      return response.data.invites
    } catch (error: any) {
      return rejectWithValue(
        error.message || 'Error fetching workspace invites'
      )
    }
  }
)

export const deleteWorkspaceMember = createAsyncThunk(
  'workspace/deleteWorkspaceMember',
  async (
    {
      group_id,
      user_id,
      wsId,
    }: { wsId: string; group_id: string; user_id: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await deleteMember({ group_id, user_id })
      dispatch(fetchWorkspaceMembers({ id: wsId }))
      return { id: wsId }
    } catch (error: any) {
      return rejectWithValue(error.message ?? 'Error deleting member')
    }
  }
)

export const deleteWorkspaceInvitee = createAsyncThunk(
  'workspace/deleteWorkspaceInvitee',
  async (
    { wsId, id }: { wsId: string; id: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await deleteInvitee({ id })
      dispatch(fetchWorkspaceInvites({ id: wsId }))
      return { id: wsId }
    } catch (error: any) {
      return rejectWithValue(error.message ?? 'Error deleting invitee')
    }
  }
)

export const deleteWs = createAsyncThunk(
  'workspace/deleteWs',
  async (
    { wsId, navigate }: { wsId: string; navigate: any },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await workspaceApi.deleteWorkspace({ id: wsId })
      dispatch(selectedWorkspaceSlice.actions.setSelectedWorkspace(undefined))
      dispatch(selectTeamspace(null))
      await dispatch(loadWorkspaces({}))
      dispatch(clearTeamspaces())
      navigate('/')
      return wsId
    } catch (error: any) {
      return rejectWithValue(error.message ?? 'Error deleting workspace')
    }
  }
)

export const leaveWs = createAsyncThunk(
  'workspace/leaveWs',
  async (
    { wsId, navigate }: { wsId: string; navigate: any },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await workspaceApi.leaveWorkspace({ id: wsId })
      dispatch(selectedWorkspaceSlice.actions.setSelectedWorkspace(undefined))
      dispatch(selectTeamspace(null))
      dispatch(clearTeamspaces())
      await dispatch(loadWorkspaces({}))
      navigate('/')
      return wsId
    } catch (error: any) {
      return rejectWithValue(error.message ?? 'Error leaving workspace')
    }
  }
)

interface CreateWorkspaceArgs {
  values: {
    w_name: string
    logo: any
    invites: string[]
  }
  withInvites: boolean
  navigate: any
}

export const createWorkspace = createAsyncThunk<any[], CreateWorkspaceArgs>(
  'workspace/createWorkspace',
  async ({ values, withInvites, navigate }, { dispatch, rejectWithValue }) => {
    try {
      const { w_name, logo, invites } = values
      const formData = new FormData()

      formData.append(
        'workspace',
        JSON.stringify({
          name: w_name,
          public_name: w_name,
          public_description: '',
        })
      )

      formData.append('logo', logo)

      const res = await workspaceApi.postWorkspace({ data: formData })
      const { workspace_id } = res.data

      if (withInvites) {
        await dispatch(
          createWorkspaceInvites({
            wsId: workspace_id,
            invites,
          })
        )
      }

      const response = await workspaceApi.getWorkspaces()
      const { data } = response

      navigate('/')
      return data.workspaces
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const loadWorkspaces = createAsyncThunk(
  'workspace/loadWorkspaces',
  async ({
    refreshSelectedWorkspaceInfo,
  }: {
    refreshSelectedWorkspaceInfo?: boolean
  }, { dispatch, getState }) => {
    try {
      const workspaces = (await workspaceApi.getWorkspaces()).data.workspaces

      if (!refreshSelectedWorkspaceInfo || !workspaces) return workspaces

      const state = getState() as TRootState
      const selectedWS = state.selectedWorkspace.selectedWorkspace
      if (!selectedWS) return workspaces

      const workspace = workspaces.find((w) => w.id == selectedWS.id)
      if (workspace) dispatch(selectedWorkspaceSlice.actions.setSelectedWorkspace(workspace))

      return workspaces
    } catch (error: any) {
      throw new Error(error.message ?? 'Error fetching workspaces')
    }
  }
)

export const fetchWorkspaceInfo = createAsyncThunk(
  'workspace/fetchWorkspaceInfo',
  async ({ wsId }: { wsId: string }, { rejectWithValue }) => {
    try {
      const response = await workspaceApi.getWorkspaceInfo({ id: wsId })
      return response.data.workspace
    } catch (error: any) {
      return rejectWithValue(error.message ?? 'Error fetching workspace info')
    }
  }
)

export const updateWorkspace = createAsyncThunk(
  'workspace/updateWorkspace',
  async (
    { wsId, workspace }: { wsId: string; workspace: any },
    { dispatch }
  ) => {
    try {
      const response = await workspaceApi.updateWorkspaceInfo({
        id: wsId,
        workspace,
      })
      dispatch(selectedWorkspaceSlice.actions.setSelectedWorkspace(workspace))
      return response.data.workspaces
    } catch (error: any) {
      throw new Error(error.message ?? 'Error updating workspace')
    }
  }
)

// Reducer
const workspaceSlice = createSlice({
  name: 'workspace',
  initialState: initialWorkspaceState,
  reducers: {
    clearNotifications: (state) => {
      state.notification.create = null
      state.notification.update = null
      state.notification.deleteWs = null
      state.notification.create_invite = null
      state.notification.leaveWs = null
    },
    clearWorkspaces: (state) => {
      state.workspaces = null
    },
    createInviteActionWs: (state) => {
      state.notification.create_invite = {
        message: 'Приглашения отправлены',
        severity: 'success',
      }
    },
    updateWorkspaces: (state, action) => {
      state.workspaces = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadWorkspaces.pending, (state) => {
        state.loading.workspaces = true
        state.error.workspaces = null
      })
      .addCase(loadWorkspaces.fulfilled, (state, action) => {
        state.workspaces = action.payload
        state.loading.workspaces = false
        state.error.workspaces = null
      })
      .addCase(loadWorkspaces.rejected, (state, action) => {
        state.loading.workspaces = false
        state.error.workspaces =
          action.error.message ?? 'Error fetching workspaces'
      })
      .addCase(createWorkspace.pending, (state) => {
        state.loading.create = true
      })
      .addCase(createWorkspace.fulfilled, (state, action) => {
        state.loading.create = false
        state.workspaces = action.payload
      })
      .addCase(createWorkspace.rejected, (state, action) => {
        state.loading.create = false
        state.error.create = action.error.message || 'Ошибка'
      })
      .addCase(updateWorkspace.pending, (state) => {
        state.loading.update = true
        state.error.update = null
      })
      .addCase(updateWorkspace.fulfilled, (state, action) => {
        state.loading.update = false
        state.workspaces = action.payload
      })
      .addCase(updateWorkspace.rejected, (state, action) => {
        state.loading.update = false
        state.error.update = action.error.message || 'Ошибка'
      })
      .addCase(fetchWorkspaceMembers.pending, (state) => {
        state.loading.members = true
        state.error.members = null
      })
      .addCase(fetchWorkspaceMembers.fulfilled, (state, action) => {
        state.loading.members = false
        state.members = action.payload
      })
      .addCase(fetchWorkspaceInvites.pending, (state) => {
        state.loading.invites = true
        state.error.invites = null
      })
      .addCase(fetchWorkspaceInvites.fulfilled, (state, action) => {
        state.loading.invites = false
        state.invites = action.payload
      })
      .addCase(fetchWorkspaceInvites.rejected, (state, action) => {
        state.loading.invites = false
        state.error.invites =
          (action.payload as string) || (action.error.message as string)
      })
  },
})

export const {
  clearNotifications,
  clearWorkspaces,
  createInviteActionWs,
  updateWorkspaces,
} = workspaceSlice.actions
export default workspaceSlice.reducer
