import { MessageSnackbar } from 'components'
import { MenuTemplateActions } from 'components/menu/MenuTemplateActions'
import { ModalActionDocument } from 'components/modal-actions/modalActionDocument'
import { ModalMoveTemplatesBetweenTeamspaces } from 'features/move-templates-between-teamspaces/components/ModalMoveTemplatesBetweenTeamspaces'
import { useActionTemplateMove } from 'features/move-templates-between-teamspaces/hooks/useActionTemplateMove'
import { useActionTemplateDelete } from 'hooks/useActionTemplateDelete'
import { useActionsTemplateFromTemplate } from 'hooks/useActionsTemplateFromTemplate'
import { useModal } from 'hooks/useModal'
import { useContext } from 'react'
import { createPortal } from 'react-dom'
import { restrictionsTemplate } from 'utils/restrictions/template'
import {
  TemplatesRegistryContext,
  TemplatesBucketRegistryContext,
} from '../../hooks'

interface IMenutemplateActionsFromTemplateProps {
  open: boolean
  handleClose: () => any
  anchorEl: any
  teamspaceId: string
  template: ITemplate
}

export const MenuTemplateActionsFromRegistry = ({
  open,
  handleClose,
  anchorEl,
  teamspaceId,
  template,
}: IMenutemplateActionsFromTemplateProps) => {
  const { actionDublicate, hideTemplateFromRegistry } = useContext(
    TemplatesRegistryContext
  )
  const { unhideTemplateFromRegistry: showInBucket } = useContext(
    TemplatesBucketRegistryContext
  )
  const { error, setError, handleClickEdit, handleClickUse } =
    useActionsTemplateFromTemplate({
      teamspaceId,
      template,
    })

  const actionDelete = useActionTemplateDelete()
  const actionMove = useActionTemplateMove()

  const [modalDeleteOpen, openModalDelete, closeModalDelete] = useModal()
  const [modalEditOpen, openModalEdit, closeModalEdit] = useModal()
  const [modalMoveOpen, openModalMove, closeModalMove] = useModal()

  const handleClickDelete = async () => {
    await actionDelete.run({
      templateId: template.id,
      onSuccessCallback: () => {
        hideTemplateFromRegistry(template.id)
        showInBucket(template.id)
      },
      setError,
    })
  }

  const handleClickDublicate = async () => {
    handleClose()
    await actionDublicate.createDublicates({
      templateIds: [template.id],
    })
  }

  const modalConfigs = [
    {
      key: 'edit-template-modal',
      handleClose: closeModalEdit,
      open: modalEditOpen,
      action: handleClickEdit,
      title: 'Изменить шаблон',
      description: `Вы уверены? Если вы это сделаете: \n\n Любые внесенные изменения в шаблоне будут применены у всех последующих документов, созданных с использованием этого шаблона.`,
      btnTitle: 'Изменить шаблон',
    },
    {
      key: 'delete-template-modal',
      handleClose: closeModalDelete,
      open: modalDeleteOpen,
      action: handleClickDelete,
      title: 'Удалить шаблон?',
      description: `Шаблон можно будет восстановить или удалить безвозвратно в “Корзине”.

      Документы, созданные из этого шаблона, сохранятся и будут доступны в этом пространстве.`,
      btnTitle: 'Удалить',
    },
  ]

  return (
    <>
      <MenuTemplateActions
        open={open}
        handleClose={handleClose}
        anchorEl={anchorEl}
        actions={{
          dublicate: {
            action: handleClickDublicate,
            hidden: !restrictionsTemplate.canDublicate(template),
          },
          use: {
            action: handleClickUse,
            hidden: !restrictionsTemplate.canUse(template),
          },
          edit: {
            action: openModalEdit,
            hidden: !restrictionsTemplate.canEdit(template),
          },
          move: {
            action: openModalMove,
            disabled: actionMove.loading,
          },
          delete: {
            action: openModalDelete,
            disabled: actionDelete.loading,
          },
        }}
      />

      {modalConfigs.map((config) => (
        <ModalActionDocument
          key={config.key}
          handleClose={config.handleClose}
          open={config.open}
          action={config.action}
          title={config.title}
          description={config.description}
          btnTitle={config.btnTitle}
          btnColor={
            config.key === 'delete-template-modal' ? 'error' : 'primary'
          }
          btnVariant={
            config.key === 'delete-template-modal' ? 'outlined' : 'contained'
          }
        />
      ))}

      <ModalMoveTemplatesBetweenTeamspaces
        open={modalMoveOpen}
        handleClose={closeModalMove}
        templateIds={[template.id]}
        actionMoveTemplates={async (props) => {
          await actionMove.run({
            teamspaceId: props.teamspaceId,
            templateIds: props.templateIds,
            setError,
            onSuccessCallback: () => {
              props.templateIds.forEach((id) => {
                hideTemplateFromRegistry(id)
              })
              closeModalMove()
            },
          })
        }}
      />

      {createPortal(
        <MessageSnackbar
          clearMessage={() => setError('')}
          message={error}
          severity={'error'}
        />,
        document.body
      )}
    </>
  )
}
