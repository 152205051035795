import { EFeature } from 'helper/plan.types'
import { EWorkspacePlan } from 'helper/plan.types'

const isProhibited = (v: number) => {
  return v == 0
}
const areExhausted = (plan: number, current: number) => {
  return plan != -1 && current >= plan
}

const wsPlanFeatureRestriction: {
  [key: number | string]: (plan_info: IPlanInfo) => { prohibited: boolean, exhausted: boolean }
} = {
  [EFeature.documents_count_per_month]: (plan_info: IPlanInfo) => {
    const prohibited = isProhibited(plan_info.plan_limits.documents_count)
    const exhausted = areExhausted(
      plan_info.plan_limits.documents_count,
      plan_info.workspace_limits.documents_count
    )

    return {
      prohibited,
      exhausted,
    }
  },

  [EFeature.users_count]: (plan_info: IPlanInfo) => {
    const prohibited = isProhibited(plan_info.plan_limits.workspace_users_count) || plan_info.plan_limits.workspace_users_count == 1
    const exhausted = areExhausted(
      plan_info.plan_limits.workspace_users_count,
      plan_info.workspace_limits.workspace_users_count
    )

    return {
      prohibited,
      exhausted,
    }
  },

  [EFeature.teamspaces_count]: (plan_info: IPlanInfo) => {
    const prohibited = isProhibited(plan_info.plan_limits.teamspaces_count) || plan_info.plan_limits.teamspaces_count == 1
    const exhausted = areExhausted(
      plan_info.plan_limits.teamspaces_count,
      plan_info.workspace_limits.teamspaces_count
    )

    return {
      prohibited,
      exhausted,
    }
  },

  [EFeature.template_creation_count]: (plan_info: IPlanInfo) => {
    const prohibited = isProhibited(plan_info.plan_limits.templates_count)
    const exhausted = areExhausted(
      plan_info.plan_limits.templates_count,
      plan_info.workspace_limits.templates_count
    )

    return {
      prohibited,
      exhausted,
    }
  },

  [EFeature.document_send_by_email]: (plan_info: IPlanInfo) => {
    const prohibited = !plan_info.plan_limits.workflow_email_send_access
    return {
      prohibited,
      exhausted: prohibited,
    }
  },

  [EFeature.ai_chat]: (plan_limits: IPlanInfo) => {
    const prohibited = !plan_limits.plan_limits.document_ai_access
    return {
      prohibited,
      exhausted: prohibited,
    }
  },

  [EFeature.mass_send]: (plan_info: IPlanInfo) => {
    const prohibited = isProhibited(plan_info.plan_limits.campaigns_count)
    const exhausted = areExhausted(
      plan_info.plan_limits.campaigns_count,
      plan_info.workspace_limits.campaigns_count
    )

    return {
      prohibited,
      exhausted,
    }
  },
}

export const getWSPlanFeatureRestriction = (feature: EFeature, plan_info: IPlanInfo) => {
  return wsPlanFeatureRestriction[feature](plan_info) ?? { prohibited: false, exhausted: false }
}

export const isMaxAvailablePlan = (planName: EWorkspacePlan | string) =>
  planName == EWorkspacePlan.Pro
