import { Alert, Button, Snackbar } from '@mui/material'
import info from 'assets/img/info-alert.svg'

export const BottomCenterSnackbar = ({ open, handleClose, title }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      onClose={handleClose}
      key={'top center'}
    >
      <Alert
        sx={{
          background: 'white',
          alignItems: 'center',
          display: 'flex',
        }}
        icon={<img src={info} width="20px" height="20px" />}
        severity="info"
        variant="outlined"
        action={
          <Button
            size="small"
            color={'info'}
            variant={'outlined'}
            onClick={handleClose}
          >
            Спасибо
          </Button>
        }
      >
        {title}
      </Alert>
    </Snackbar>
  )
}
