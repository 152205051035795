import img_inbox from 'assets/img/inbox.svg'

import { TSRolePermissions } from 'helper/roles'
import { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getWSPlanFeatureRestriction } from 'utils/plans'
import { CreateCampaign } from './createCampaign'
import { AnalyticsEvent } from 'config/analytics/segment.com/events'
import { EFeature } from 'helper/plan.types'
import { Empty } from 'components'
import { isMobile } from 'react-device-detect'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { Typography } from '@mui/material'
import { ModalPlanLimit } from 'components/modal/ModalPlanLimit'
import { IWSPlan } from 'store/slices/wsPlan'

export const EmptyBulkPage = () => {
  const [open, setOpen] = useState(false)

  const { selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )
  const [hasLimits, setHasLimits] = useState(false)
  const [openModalRestrictedOnMobile, setOpenModalRestrictedOnMobile] =
    useState(false)
  const { plan_info }: IWSPlan = useSelector((state: RootState) => state.wsPlan)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    const { prohibited, exhausted } = getWSPlanFeatureRestriction(
      EFeature.mass_send,
      plan_info
    )
    if (prohibited || exhausted) {
      setHasLimits(prohibited || exhausted)
      return
    }

    if (isMobile) {
      setOpenModalRestrictedOnMobile(true)
      return
    }

    AnalyticsEvent.send_doc_start('yes')
    setOpen(true)
  }

  return (
    <>
      <Empty
        src={img_inbox}
        title={'Массовая рассылка документов'}
        subtitle={
          'Массовая рассылка документов автоматически создает и отправляет множество документов на подпись одновременно с использованием заранее подготовленного шаблона.'
        }
      >
        {selectedTeamspace &&
          TSRolePermissions[selectedTeamspace?.group_name].canManageDocs && (
            <CreateCampaign
              title={'Новая рассылка'}
              open={open}
              handleClose={handleClose}
              handleOpen={handleOpen}
            />
          )}
      </Empty>

      <ModalPlanLimit
        limitOn={EFeature.mass_send}
        open={hasLimits}
        handleClose={() => setHasLimits(false)}
      />

      <ModalCenterSelectableWithTitle
        handleClose={() => {
          setOpenModalRestrictedOnMobile(false)
        }}
        modalType="small"
        open={openModalRestrictedOnMobile}
        title={'Рассылка документов'}
        contentChild={
          <Typography>
            Рассылка документов не поддерживается на мобильной версии
          </Typography>
        }
      />
    </>
  )
}
