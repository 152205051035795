import { Box, Typography, Button, Container } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createCampaign } from 'service'
import { MessageSnackbar, Loading } from 'components'
import { Error404 } from 'pages/errors'
import { useNavigate, useParams } from 'react-router-dom'
import { updateCampaign, updateCampaigns } from 'store/slices/campaign'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { DocumentHtml } from 'components/document'
import { getTemplateInfo } from 'service/template'
import { FullwidthContext } from '../createCampaign'

export const Check = ({ handleCloseContext, setTab }) => {
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [error, setError] = useState('')
  const fullWidth = useContext(FullwidthContext)
  const { tsId } = useParams()

  const { success_count, campaign_id, campaign_name, page } = useSelector(
    (state: RootState) => state.campaign
  )

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      await createCampaign({ id: campaign_id })
      navigate(`/teamspace/${tsId}/campaigns`)
      handleCloseContext()
      dispatch(
        updateCampaign({
          is_draft_created: false,
          campaign_id: '',
          current_step: 0,
          campaign_name: '',
          variables: '',
          iin: true,
          id: '',
        }) as any
      )
      setTab(0)
      dispatch(
        updateCampaigns({
          page: page,
          ts_id: tsId || '',
        }) as any
      )
    } catch (error) {
      setError((error as Error).message)
    }
  }

  return (
    <>
      <MessageSnackbar
        message={error}
        clearMessage={() => setError('')}
        severity="error"
      />

      <Box p="8px 16px">
        <Box>
          <Typography variant="h6" color={'text.secondary'}>
            Пример созданного документа
          </Typography>
          <Box
            mt={'4px'}
            border={'1px solid #EDEDEC'}
            p="4px 4px 4px 12px"
            display="flex"
            borderRadius="4px"
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography color={'text.secondary'}>{campaign_name}</Typography>
            <Box position={'absolute'} right={'16px'}>
              <Button color={'secondary'} onClick={handleOpen} size={'small'}>
                Посмотреть
              </Button>
            </Box>
          </Box>
          <Box mt={2}>
            <Typography color={'text.disabled'}>
              Будет создано документов
              <Box component={'span'} ml={1} color="text.primary">
                {success_count}
              </Box>
            </Typography>
          </Box>
        </Box>
        <PreviewDocument open={open} handleClose={handleClose} />
      </Box>

      <Box
        p={'8px 16px 16px'}
        mt={'12px'}
        display={'flex'}
        justifyContent={'flex-end'}
        width={'100%'}
        sx={{
          position: fullWidth ? 'absolute' : '',
          right: 0,
          bottom: 0,
        }}
      >
        <Button variant={'contained'} onClick={handleSubmit}>
          Продолжить
        </Button>
      </Box>
    </>
  )
}

export const PreviewDocument = ({ open, handleClose }) => {
  const [error, setError] = useState('')
  const [state, setState] = useState<ITemplate>()
  const [loading, setLoading] = useState(false)

  const { id, draftVariables, campaign_name } = useSelector(
    (state: RootState) => state.campaign
  )

  const getTemplate = async ({ id }) => {
    setLoading(true)
    try {
      const res = await getTemplateInfo({ id })
      const newDraft = {}
      Object.entries(draftVariables).forEach(
        ([key, value]) => (newDraft[key] = (value as any).value)
      )
      setState({
        ...(res.data.template as any),
        draftVariables: newDraft ? newDraft : {},
      })
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      setError(error.message)
    }
  }

  useEffect(() => {
    getTemplate({ id })
  }, [id])

  if (loading)
    return (
      <Container
        sx={{
          height: '100vh',
        }}
      >
        <Loading />
      </Container>
    )
  if (error) return <Error404 message={error} description={''} />

  return (
    <ModalCenterSelectableWithTitle
      open={open}
      modalType="medium"
      handleClose={handleClose}
      title={`Массовая рассылка ${campaign_name}`}
      contentChild={
        <Box border={'1px solid #EDEDEC'} borderRadius={'4px'}>
          <DocumentHtml
            template={state?.id}
            stateVariables={state?.draftVariables}
          />
        </Box>
      }
    />
  )
}
