import { useFormikContext } from 'formik'
import { DeselectPluginCalendar } from '../plugins/deselectPluginCalendar'
// import { SelectPluginCalendar } from '../plugins/selectPluginCalendar'
import { FormikDatePicker } from '../datePicker'
import dayjs from 'dayjs'
import { formatDefaultDate } from 'utils/formatDate'

export const MultiDatePicker = ({ name }: { name: string }) => {
  const { values, setFieldValue } = useFormikContext<{ date_range: any[] }>()

  const handleDateChange = (dates: string[] | Date[]) => {
    setFieldValue(
      name,
      dates.map((date) => formatDefaultDate(date))
    )
  }

  const deselectDates = () => {
    setFieldValue(name, [])
  }

  return (
    <FormikDatePicker
      name={name}
      format={values['format']}
      value={values[name].map((date) => dayjs(date).toDate())}
      onChange={handleDateChange}
      multiple
      plugins={[
        <DeselectPluginCalendar
          position="bottom"
          deselectDates={deselectDates}
          text="Очистить"
        />,
        // <SelectPluginCalendar position="bottom" selectDates={deselectDates} />,
      ]}
    />
  )
}
