export const stringAvatar = (name: string, props: any = {}) => {
  return {
    sx: {
      bgcolor: '#E7E7E6',
      width: '24px',
      height: '24px',
      fontSize: '16px',
      border: '0.6px solid rgba(0, 0, 0, 0.1)',
      color: '#282A2F',
      ...props,
    },
    children: `${name[0]?.toUpperCase() || 'П'}`,
  }
}
