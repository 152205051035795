import { Box, IconButton, TextField } from '@mui/material'
import calendarImg from 'assets/img/variables/calendar_today.svg'
import { weekDays, months } from '../../utils'
import img_left from 'assets/img/variables/left.svg'
import img_right from 'assets/img/variables/right.svg'
import DatePicker from 'react-multi-date-picker'
import { useEffect, useState } from 'react'
import '../../style/index.css'

export const FormikDatePicker = ({
  onChange,
  renderInput = undefined,
  ...props
}: { onChange: (value: string) => void; renderInput?: any } & any) => {
  const [portalTarget, setPortalTarget] = useState<HTMLElement>()

  useEffect(() => {
    const portalDiv = document.createElement('div')
    portalDiv.id = 'myPortalDiv'
    portalDiv.style.zIndex = '10000'
    document.body.appendChild(portalDiv)
    setPortalTarget(portalDiv)
    return () => {
      document.body.removeChild(portalDiv)
    }
  }, [])

  const renderButton = (direction, handleClick) => (
    <IconButton
      onClick={handleClick}
      sx={{ p: '6px' }}
      children={
        <Box
          component={'img'}
          src={direction === 'right' ? img_right : img_left}
          width={'20px'}
          height={'20px'}
        />
      }
    />
  )

  const defaultRenderInput = (value: string, openCalendar) => (
    <TextField
      value={value}
      onFocus={openCalendar}
      fullWidth
      InputProps={{
        endAdornment: (
          <Box
            component={'img'}
            src={calendarImg}
            width={'16px'}
            height={'16px'}
          />
        ),
      }}
    />
  )

  return (
    <DatePicker
      portal
      portalTarget={portalTarget}
      renderButton={renderButton}
      weekDays={weekDays}
      months={months}
      style={{ width: '100%' }}
      zIndex={10000}
      weekStartDayIndex={1}
      render={renderInput ? renderInput : defaultRenderInput}
      onChange={onChange}
      {...props}
    />
  )
}
