import { EWorkspacePlan } from 'helper/plan.types'
import { analytics } from './analytics'
import { AnalyticsEventNames } from './consts'
import { EPlanPeriod } from 'pages/plan/types'
import { EDocumentType } from 'helper/consts'

export interface IUTMParams {
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_term?: string
  utm_content?: string
}

export const PlanPeriodNamesForAnalytics = {
  [EPlanPeriod.month]: 'monthly',
  [EPlanPeriod.quarter]: 'quarterly',
  [EPlanPeriod.half_year]: 'half-year',
  [EPlanPeriod.year]: 'yearly',
}

interface IAnalyticsEventCtx {
  groupId?: string
}

export class AnalyticsEvent {
  static ctx: IAnalyticsEventCtx

  /**
   *
   * @returns context value for track events (mixpanel)
   */
  private static getCtx() {
    return this.ctx
  }

  private static setCtx(ctx: IAnalyticsEventCtx, merge = false) {
    if (merge) {
      this.ctx = {
        ...this.getCtx(),
        ...ctx,
      }
      return
    }

    this.ctx = ctx
  }

  public static async page(path) {
    await analytics.page(path, undefined, null, this.getCtx())
  }

  /**
   * user_identify - идентификация пользователя
   */
  public static async user_identify(
    userId?: string,
    options?: {
      email?: string
      first_name?: string
      last_name?: string
    } & IUTMParams
  ) {
    await analytics.identify(userId ?? undefined, options ?? undefined)
  }

  /**
   *
   * @param workspaceId
   * @param options {
   *  name: string, // ? Название компании видимая только в админке
   *  company_name: string // ? Публичное название компании
   * }
   */
  public static async workspace_identify(
    workspace_id: string,
    options: {
      name?: string
      company_name?: string
      teamspaces?: number
      users?: number
      avatar?: 'yes' | 'no'
      industry?: string
    }
  ) {
    this.setCtx(
      {
        groupId: workspace_id,
      },
      true
    )

    await analytics.group(workspace_id, options, this.getCtx())
  }

  /**
   * Пользователь попал на первый этап сетапа после успешного signup'а
   */
  public static async setup_started() {
    await analytics.track(
      AnalyticsEventNames.setup_started,
      undefined,
      this.getCtx()
    )
  }

  /**
   * @param profile - Сетапит ли воркспейс или регается как приглашенный юзер воркспейса (???)
   * @param avatar - Загрузил ли аватар в свой профиль (???)
   * @param number_of_invites - Сколько ивентов выслал (???)"
   */
  public static async setup(
    profile: 'company' | 'user',
    avatar: 'yes' | 'no',
    number_of_invites: number
  ) {
    await analytics.track(
      AnalyticsEventNames.setup,
      {
        profile,
        avatar,
        number_of_invites,
      },
      this.getCtx()
    )
  }

  /**
   * @param bulk_send – (yes/no) пытаемся ли создать bulk send кампанию
   */
  public static async new_doc_start(bulk_send: 'yes' | 'no') {
    await analytics.track(
      AnalyticsEventNames.new_doc_start,
      {
        bulk_send,
      },
      this.getCtx()
    )
  }

  public static async send_doc_start(bulk_send: 'yes' | 'no') {
    await analytics.track(
      AnalyticsEventNames.send_doc_start,
      {
        bulk_send,
      },
      this.getCtx()
    )
  }

  /**
   * @param mass_sign - (yes/no)
   * @param source - (default)workspace/clientportal – откуда
   */
  public static async sign_doc_start(mass_sign: 'yes' | 'no') {
    await analytics.track(AnalyticsEventNames.sign_doc_start, {
      mass_sign,
      source: 'workspace',
    })
  }

  /**
   * @param number_of_invites_sent – количество инвайтов, которые послал пользователь
   * @param source - откуда пользователь послал инвайты. (setup - при создании воркспейса и его сетапе), (settings – из настроек воркспейса)
   */
  public static async invites_sent(
    number_of_invites_sent: number,
    source: 'setup' | 'settings'
  ) {
    await analytics.track(
      AnalyticsEventNames.invites_sent,
      {
        number_of_invites_sent,
        source,
      },
      this.getCtx()
    )
  }

  /**
   * chat_doc
   * @param type - send_message/receive_message/copy_message
   * @param first_message - yes/no
   * @param stage - editor/pdf/template
   */
  public static async chat_doc(
    type: 'send_message' | 'receive_message' | 'copy_message',
    first_message: 'yes' | 'no',
    stage: EDocumentType
  ) {
    await analytics.track(
      AnalyticsEventNames.chat_doc,
      {
        type,
        first_message,
        source: 'workspace',
        stage,
      },
      this.getCtx()
    )
  }

  /**
   * request_invoice_started - начал заполнять форму счета на оплату
   */
  public static async request_invoice_started(
    plan: EWorkspacePlan,
    duration: EPlanPeriod
  ) {
    await analytics.track(
      AnalyticsEventNames.request_invoice_started,
      {
        plan,
        duration: PlanPeriodNamesForAnalytics[duration],
      },
      this.getCtx()
    )
  }
}
