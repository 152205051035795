import { IChangePageArgs } from 'components/pagination/types'
import { useDispatch } from 'react-redux'
import { updateInboxDocuments } from 'store/slices/inboxSlice'

export const useInboxChangePage = () => {
  const dispatch = useDispatch()

  const changePage = ({ page, params }: IChangePageArgs) => {
    const { query, filter, author_id, completed } = params

    dispatch(
      updateInboxDocuments({
        page,
        queryString: query,
        completed,
        filter,
        author_id,
      }) as any
    )
  }

  return { changePage }
}
