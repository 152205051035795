import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { Formik } from 'formik'
import { useState } from 'react'
import { MessageSnackbar } from 'components'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  fetchWorkspaceInvites,
  fetchWorkspaceMembers,
  createWorkspaceInvites,
} from 'store/slices/workspace'
import { AnalyticsEvent } from 'config/analytics/segment.com/events'
import { wsRoleOptions } from './inviteToHelpers'
import { TextFieldEmails } from 'components/text-fields'

export const InviteToWSForm = ({ handleClose, cancel = true }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [isEmailsFieldValid, setIsEmailsFieldValid] = useState(true)

  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const dispatch = useDispatch()

  const handleSubmit = async (values) => {
    const { invites, option } = values
    setLoading(true)
    await dispatch(
      createWorkspaceInvites({
        wsId: selectedWorkspace?.id || '',
        invites,
        option,
        callback: () => {
          if (invites.length)
            AnalyticsEvent.invites_sent(invites.length, 'settings')
          handleClose()
          dispatch(
            fetchWorkspaceInvites({ id: selectedWorkspace?.id || '' }) as any
          )
          dispatch(
            fetchWorkspaceMembers({ id: selectedWorkspace?.id || '' }) as any
          )
        },
      }) as any
    )
    setLoading(false)
  }

  const formikContent = ({
    handleSubmit,
    setFieldValue,
    values,
    handleChange,
    handleBlur,
  }: any) => {
    return (
      <form onSubmit={handleSubmit}>
        <Box p="0 0 8px 0">
          <Typography variant="body1" mb="4px" fontWeight={500}>
            Отправить приглашение на email
          </Typography>
          <TextFieldEmails
            emails={values.invites}
            setEmails={(emails) => {
              setFieldValue('invites', emails)
            }}
            placeholder="email@example.com email2@example.com..."
            setIsValuesValid={setIsEmailsFieldValid}
          />
          <Typography variant="body1" mb="4px" fontWeight={500} mt={'16px'}>
            Роль
          </Typography>
          <FormControl fullWidth>
            <Select
              MenuProps={{
                PaperProps: {
                  style: {
                    width: '480px',
                    maxHeight: '100%',
                    marginTop: '2px',
                  },
                },
              }}
              fullWidth
              name="option"
              value={values.option}
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ p: 0 }}
              renderValue={(value) => {
                const option = wsRoleOptions.find((o) => o.value === value)
                return option ? option.label : ''
              }}
            >
              {wsRoleOptions.map((option) => {
                return (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{ m: '2px 4px' }}
                  >
                    <Box
                      p={'4px 12px'}
                      display={'flex'}
                      alignItems="flex-start"
                      flexDirection={'column'}
                    >
                      <Typography variant="body1">{option.label}</Typography>
                      <Typography
                        color={'text.secondary'}
                        whiteSpace="break-spaces"
                      >
                        {option.desc}
                      </Typography>
                    </Box>
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
        <Box
          p={'8px 0 16px'}
          id="footer"
          display={'flex'}
          justifyContent={'flex-end'}
        >
          {cancel && (
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              Сделать это позже
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading || !isEmailsFieldValid ? true : false}
          >
            Пригласить участников
          </Button>
        </Box>
      </form>
    )
  }

  return (
    <>
      <MessageSnackbar
        message={error}
        clearMessage={() => setError('')}
        severity={'error'}
      />

      <Formik
        initialValues={{
          invites: [],
          option: wsRoleOptions[0].value,
        }}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {formikContent}
      </Formik>
    </>
  )
}
