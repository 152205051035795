import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { selectedWorkspaceSlice } from 'store/slices/selectedWorkspace'
import { fetchTeamspaces, selectTeamspace } from 'store/slices/teamspaceSlice'
import { loadWorkspaces } from 'store/slices/workspace'
import { isObjectEmpty } from '../helpers/isObjectEmpty'
import { nonPersonalTeamspace } from '../helpers/nonPersonalTeamspace'
import { searchWSExists } from '../helpers/searchWSExists'

export const useFetchWorkspaceAndTeamspaces = (deps) => {
  const { workspaces } = useSelector((state: RootState) => state.workspace)

  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const { selectedTeamspace, teamspaces } = useSelector(
    (state: RootState) => state.teamspace
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      selectedWorkspace &&
      workspaces &&
      !searchWSExists(workspaces, selectedWorkspace?.id)
    ) {
      dispatch(selectedWorkspaceSlice.actions.setSelectedWorkspace(undefined) as any)
    }

    if (
      (!selectedWorkspace || isObjectEmpty(selectedWorkspace)) &&
      workspaces &&
      workspaces[0]
    ) {
      dispatch(selectedWorkspaceSlice.actions.setSelectedWorkspace(workspaces[0]) as any)
    } else if (!selectedTeamspace && teamspaces && teamspaces[0])
      dispatch(selectTeamspace(nonPersonalTeamspace(teamspaces)) as any)
    else if (!workspaces) {
      dispatch(loadWorkspaces({ refreshSelectedWorkspaceInfo: true }) as any)
    } else if (
      selectedWorkspace &&
      selectedWorkspace.id &&
      teamspaces !== null
    ) {
      dispatch(fetchTeamspaces({ id: selectedWorkspace.id }) as any)
    }
  }, deps)
}
