import img_ask from 'assets/img/ic_ask.svg'

import { Box, IconButton } from '@mui/material'
import { CustomTooltip } from 'components/tooltip'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { MenuHelp } from '../MenuHelp'

export const IconButtonHelp = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <CustomTooltip title={'Помощь и поддержка'} placement={'left'}>
        <IconButton
          sx={{
            background: 'white',
            border: '1px solid #EDEDEC',
          }}
          onClick={handleClick}
          id="positioned-button"
          aria-controls={open ? 'positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Box
            component={'img'}
            src={img_ask}
            width={isMobile ? '20px' : '16px'}
            height={isMobile ? '20px' : '16px'}
            alt=""
          />
        </IconButton>
      </CustomTooltip>
      <MenuHelp anchorEl={anchorEl} handleClose={handleClose} open={open} />
    </>
  )
}
