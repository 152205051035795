import { Box, Typography } from '@mui/material'
import { useActiveIntegrations } from '../../hooks/useActiveIntegrations'
import { IIntegration } from '../../types'
import { ActiveIntegrationRow } from '../ActiveIntegrationRow'
import { ActiveIntegrationRowBase } from '../ActiveIntegrationRow/ActiveIntegrationRowBase'

export const ActiveIntegrations = ({
  integrations,
}: {
  integrations: ReturnType<typeof useActiveIntegrations>
}) => {
  if (!integrations.loading && integrations.activeIntegrations.length == 0)
    return null

  return (
    <Box display={'flex'} flexDirection={'column'} pt="20px" pb="16px">
      <Box p="6px 0">
        <Typography variant="h6" color={'text.primary'}>
          Активные интеграции
        </Typography>
      </Box>
      {integrations.loading && integrations.activeIntegrations.length == 0 ? (
        <ActiveIntegrationRowBase
          title=""
          description=""
          icon=""
          integrationId=""
          isEnabled
          loading
        />
      ) : (
        <>
          {integrations.activeIntegrations.map((integration: IIntegration) => (
            <ActiveIntegrationRow
              integration={integration}
              onDeleteCallback={integrations.fetchIntegrations}
              key={integration.id}
              onEditSuccessCallback={integrations.fetchIntegrations}
              loading={integrations.loading}
            />
          ))}
        </>
      )}
    </Box>
  )
}
