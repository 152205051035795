import { Box, Button, CircularProgress, Typography } from '@mui/material'
import archive_svg from 'assets/img/archive.svg'
import { MenuItemButton } from 'components/menu-item/menu-item-button'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { MessageSnackbar } from 'components/snackbar'
import { useArchive } from 'hooks/useArchive'
import { useState } from 'react'

export const Archive = ({ id }) => {
  const { loading, archive, error, setError } = useArchive(id)

  if (loading) return <CircularProgress />
  return (
    <>
      <MessageSnackbar
        message={error}
        clearMessage={() => setError('')}
        severity="error"
      />
      <Button
        fullWidth
        color={'inherit'}
        variant={'outlined'}
        onClick={() => archive()}
      >
        <img
          src={archive_svg}
          alt=""
          width="20px"
          height="20px"
          style={{ marginRight: '5px' }}
        />
        Архивировать
      </Button>
    </>
  )
}

export const ArchiveMenu = ({ id }) => {
  const { loading, archive, error, setError } = useArchive(id)
  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)

  if (loading) return <CircularProgress />
  return (
    <>
      <ModalCenterSelectableWithTitle
        open={open}
        handleClose={handleClose}
        modalType="small"
        title={'Архивировать'}
        contentChild={
          <>
            <Typography variant="body1">
              Вы уверены? Если вы это сделаете: <br /> <br /> &#x2022; у всех у
              кого есть доступ к документу не смогут посмотреть документ;
            </Typography>
            <Typography variant="body1">
              &#x2022; документ будет хранится в разделе “Архив”;
            </Typography>
            <Typography variant="body1">
              &#x2022; для того чтобы вернуть доступ нужно “Разархивировать”
              документ{' '}
            </Typography>
          </>
        }
        footerChild={
          <Box justifyContent="flex-end" display={'flex'}>
            <Button size="small" color="secondary" onClick={handleClose}>
              Отменить
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="error"
              onClick={() => {
                archive()
                handleClose()
              }}
            >
              Архивировать
            </Button>
          </Box>
        }
      />
      <MessageSnackbar
        message={error}
        clearMessage={() => setError('')}
        severity="error"
      />
      <MenuItemButton
        title={'Архивировать'}
        srcImage={archive_svg}
        onClick={() => setOpen(true)}
      />
    </>
  )
}
