import { Skeleton } from '@mui/material'

export const DocumentSkeleton = () => (
  <Skeleton
    animation="wave"
    variant="rectangular"
    width={'100%'}
    height={'90vh'}
    sx={{
      bgcolor: '#F7F7F7',
      borderRadius: '8px',
      '::after': {
        background: 'linear-gradient(90deg, transparent, #ffffff, transparent)',
      },
    }}
  />
)
