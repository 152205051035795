import { launchWorkflow } from 'features/workflow-builder/model/launchWorkflow'
import { EDocumentStatus, ERecipientRole } from 'helper/consts'
import { CWorkflow } from 'helper/workflow/CWorkflow'
import { CWorkflowBuilder } from 'helper/workflow/CWorkflowBuilder'
import { CWorkflowHelper } from 'helper/workflow/CWorkflowHelper'
import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { queryGetWorkflow } from 'service'
import { IWorkflowBuilderUIContext } from '../WorkflowBuilderUIContext'

export interface IWorkflowLauncherContext {
  workflowBuilderUI: IWorkflowBuilderUIContext

  launch: () => void
  loading: boolean
  error: string
  setError: Dispatch<SetStateAction<string>>
  launched: boolean
  canLaunch: () => boolean
}

export const WorfklowLauncherContext = createContext<IWorkflowLauncherContext>(
  {} as IWorkflowLauncherContext
)

const isDocumentLaunched = (status: EDocumentStatus) => {
  return (
    status === EDocumentStatus.SENT ||
    status === EDocumentStatus.PREPARING ||
    status === EDocumentStatus.COMPLETED
  )
}

export const useWorkflowLauncherContextDefaultValue = (
  workflowBuilderUI: IWorkflowBuilderUIContext
): IWorkflowLauncherContext => {
  const { document } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [launched, setLaunched] = useState<boolean>(false)

  const workflowLaunch = async () => {
    try {
      setLoading(true)

      const workflow = workflowBuilderUI.workflow

      await launchWorkflow({
        workflow: workflow,
        doc: {
          id: document.id,
          type: document.type,
          status: document.status,
        },
      })
      setLaunched(true)
    } catch (error: any) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const loadWorkflow = async () => {
    try {
      setLoading(true)
      const wf = CWorkflowBuilder.GetWorkflowFromLocalStorage(document.id)
      if (wf) {
        workflowBuilderUI.resetWorkflow(wf)
        setLoading(false)
        return
      }

      const resp = await queryGetWorkflow({ id: document.id })
      const buildedWf = CWorkflowBuilder.BuildWorkflow(resp.data.workflow, true)
      workflowBuilderUI.resetWorkflow(buildedWf)
    } catch (err: any) {
      if (err.message === "workflow doesn't exist") {
        workflowBuilderUI.setWorkflow(new CWorkflow({ steps: [] }))
      } else {
        setError(err.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const canLaunch = () => {
    const workflow = workflowBuilderUI.workflow

    const hasSigner = CWorkflowHelper.HasRecipientWithRole(workflow, [
      ERecipientRole.signer_rk,
      ERecipientRole.approver_rk,
      ERecipientRole.anonymous_signer_rk,
    ])
    if (!hasSigner) return false
    if (!workflow.actors) return hasSigner

    const fillersFilled =
      workflow.actors.length === 0
        ? true
        : CWorkflowHelper.GetRecipientsWithRole(workflow, [
            ERecipientRole.filler,
          ]).length >=
          workflow.actors.length - 1

    return hasSigner && fillersFilled
  }

  useEffect(() => {
    if (isDocumentLaunched(document.status)) {
      CWorkflowBuilder.RemoveWorkflowFromLocalStorage(document.id)
      return
    }
    loadWorkflow()
  }, [document.id])

  useEffect(() => {
    if (isDocumentLaunched(document.status)) return

    CWorkflowBuilder.SaveWorkflowToLocalStorage(
      document.id,
      workflowBuilderUI.workflow
    )
  }, [workflowBuilderUI.workflow])

  return {
    workflowBuilderUI,
    launch: workflowLaunch,
    loading,
    error: error,
    setError: setError,
    launched,
    canLaunch,
  }
}
