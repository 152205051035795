import { Button, Box } from '@mui/material'
import { memo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import img_add from 'assets/img/add.svg'

export const СreateDocFromTemplateButton = memo(
  ({ row }: { row: ITemplate }) => {
    const { tsId } = useParams()
    const navigate = useNavigate()

    const handleClick = (e) => {
      e.preventDefault()
      e.stopPropagation()
      navigate(`/teamspace/${tsId}/documents/draft?templateId=${row.id}`)
    }

    return (
      <Button
        className="create-doc-from-template-button"
        size="small"
        variant={'outlined'}
        color="secondary"
        onClick={handleClick}
        startIcon={
          <Box
            component={'img'}
            src={img_add}
            width={'16px'}
            height={'16px'}
          ></Box>
        }
      >
        Использовать
      </Button>
    )
  }
)
