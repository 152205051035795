import { Box } from '@mui/material'
import { RenderPDF } from 'features/renderDocument'
import { EDocumentType } from 'helper/consts'
import { shallowEqual } from 'react-redux'
import { useAppSelector } from 'store/store'
import { DocumentTemplateHTML, EditDocumentName } from '..'
import { DocumentSkeleton } from '../DocumentSkeleton'

export const DocumentContainer = () => {
  return (
    <Box maxWidth="720px" alignSelf={'center'} width="100%">
      <EditDocumentName />
      <Box border="1px solid #E8E8E8" mt="20px">
        <DocumentContainerContent />
      </Box>
    </Box>
  )
}

const DocumentContainerContent = () => {
  const { loading, document } = useAppSelector(
    (state) => state.application,
    shallowEqual
  )

  if (loading.source) return <DocumentSkeleton />
  switch (document.type) {
    case EDocumentType.PDF:
      return <RenderPDF src={document.content_pdf} />
    case EDocumentType.TEMPLATE:
    case EDocumentType.EDITOR:
      return <DocumentTemplateHTML />
    default:
      return <></>
  }
}
