import {
  IAiChatContext,
  useAiChatContextDefaultValue,
} from './hooks/useAiChatContextDefaultValue'
import { createContext, useContext } from 'react'

const AiChatContext = createContext<IAiChatContext>({} as IAiChatContext)

export const AiChatProvider = ({ children }) => {
  const aiChatContextValue = useAiChatContextDefaultValue()

  return (
    <AiChatContext.Provider value={aiChatContextValue}>
      {children}
    </AiChatContext.Provider>
  )
}

export const useAiChatContext = () => {
  const context = useContext(AiChatContext)
  if (context === undefined || context === null) {
    throw new Error(`useSearchBarContext must be called within AiChatContext`)
  }
  return context
}
