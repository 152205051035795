import {
  AiChatProvider,
  FillerFormikContextProvider,
  SidebarModeProvider,
} from 'context'
import { withDashboardLayout } from 'layout'
import { DocumentPreviewHeader } from 'pages/document-preview/components'
import { PreviewContent } from './components/PreviewContent'

export const Preview = () =>
  withDashboardLayout(PreviewContent)({
    Header: DocumentPreviewHeader,
    providers: [
      AiChatProvider,
      SidebarModeProvider,
      FillerFormikContextProvider,
    ],
  })
