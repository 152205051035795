import { ECustomVariableType, ESystemVariableType } from '../variableTypes'

export const VariableTypeTitles = {
  [ESystemVariableType.INPUT_TEXT]: 'Текст',
  [ESystemVariableType.TEXT_AREA]: 'Длинный текст',
  [ESystemVariableType.DROPDOWN]: 'Раскрывающийся список',
  [ESystemVariableType.INPUT_EMAIL]: 'Электронный адрес',
  [ESystemVariableType.INPUT_NUMBER]: 'Числовое поле',
  [ESystemVariableType.INPUT_PHONE_NUMBER]: 'Номер телефона',
  [ESystemVariableType.INPUT_DATE]: 'Дата',
  [ESystemVariableType.CHECKBOX]: 'Чекбокс',

  [ECustomVariableType.INPUT_TEXT_ADDRESS]: 'Адрес',
  [ECustomVariableType.INPUT_TEXT_NAME]: 'Имя',
  [ECustomVariableType.INPUT_TEXT_LAST_NAME]: 'Фамилия',
  [ECustomVariableType.INPUT_TEXT_FATHER_NAME]: 'Отчество',
  [ECustomVariableType.INPUT_TEXT_IIN]: 'ИИН',
  [ECustomVariableType.INPUT_TEXT_BIN]: 'БИН',
}

export const InitialVariableTypeTitles = {
  [ESystemVariableType.INPUT_TEXT]: '',
  [ESystemVariableType.TEXT_AREA]: '',
  [ESystemVariableType.DROPDOWN]: '',
  [ESystemVariableType.INPUT_EMAIL]: 'Электронный адрес',
  [ESystemVariableType.INPUT_NUMBER]: '',
  [ESystemVariableType.INPUT_PHONE_NUMBER]: 'Номер телефона',
  [ESystemVariableType.INPUT_DATE]: '',
  [ESystemVariableType.CHECKBOX]: '',
  [ECustomVariableType.INPUT_TEXT_ADDRESS]: 'Адрес',
  [ECustomVariableType.INPUT_TEXT_NAME]: 'Имя',
  [ECustomVariableType.INPUT_TEXT_LAST_NAME]: 'Фамилия',
  [ECustomVariableType.INPUT_TEXT_FATHER_NAME]: 'Отчество',
  [ECustomVariableType.INPUT_TEXT_IIN]: 'ИИН',
  [ECustomVariableType.INPUT_TEXT_BIN]: 'БИН',
}
