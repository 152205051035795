import { Box, Grid } from '@mui/material'
import { Loading, MessageSnackbar } from 'components'
import {
  AiChatProvider,
  SidebarModeTemplateProvider,
  useAiChatContext,
  useSidebarModeContext,
} from 'context'
import EditorLexical from 'features/text-editors/lexical'
import { EditorModes, UrlKeys } from 'helper/consts'
import {
  DocumentEditorContext,
  useDocumentEditorContextDefaultValue,
} from 'hooks/useDocumentEditorContextDefaultValue'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { withDashboardLayout } from 'layout'
import { sidebarWidth } from 'layout/sidebar-layout'
import { AIChat } from 'layout/sidebar-layout/aichat'
import { SidebarMode } from 'pages/document-preview/hooks/useSideBarState'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { clearErrors } from 'store/slices/templateSlice'
import { EditTemplateName } from './components/edit-template-name'
import { TemplatePreviewHeader } from './components/header'
import { useTemplateEditorContentLoad } from './hooks/useTemplateEditorContentLoad'

export const TemplateEditorPage = () =>
  withDashboardLayout(TemplateEditor)({
    Header: TemplatePreviewHeader,
    providers: [AiChatProvider, SidebarModeTemplateProvider],
  })

export const TemplateEditor = () => {
  const dispatch = useDispatch()

  const { errors } = useSelector(
    (state: RootState) => state.template,
    shallowEqual
  )

  return (
    <>
      <MessageSnackbar
        message={errors.content_html}
        clearMessage={() => dispatch(clearErrors())}
        severity={'error'}
      />

      <TemplateEditorContent />
    </>
  )
}

const TemplateEditorContent = () => {
  const { sidebarIsOpen, sidebarMode, closeSidebar } = useSidebarModeContext()
  const { urlId } = useUrlIdParam(UrlKeys.templateId)
  const aiChatContextValue = useAiChatContext()

  const { loading } = useSelector(
    (state: RootState) => state.template,
    shallowEqual
  )

  const { isTemplateLoaded } = useTemplateEditorContentLoad()

  const createDocumentIfNotExistAndLoadChat = async () => {
    const id = urlId.get()
    if (!id) documentEditorContextValue.saveToServer()
    if (!aiChatContextValue.state.id || aiChatContextValue.state.messages.error)
      aiChatContextValue.init(id)
  }

  useEffect(() => {
    if (sidebarMode === SidebarMode.chat) createDocumentIfNotExistAndLoadChat()
  }, [sidebarMode, urlId.get()])

  const documentEditorContextValue = useDocumentEditorContextDefaultValue(
    EditorModes.template
  )

  const getSidebarContent = () => {
    switch (sidebarMode) {
      case SidebarMode.chat:
        return <AIChat handleClose={closeSidebar} />
      default:
        return null
    }
  }

  if (loading.info) return <Loading />

  return (
    <Grid container>
      <Box
        sx={{
          pt: '62px',
          pb: '20px',
          width: sidebarIsOpen() ? `calc(100% - ${sidebarWidth})` : '100%',
          maxWidth: '100vw',
          maxHeight: 'calc(100vh - 50px)',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            p: '0 20px',
            m: '0 auto',
            maxWidth: '840px',
          }}
        >
          <Box mb={'20px'}>
            <EditTemplateName fullWidth />
          </Box>
          {isTemplateLoaded ? (
            <DocumentEditorContext.Provider value={documentEditorContextValue}>
              <EditorLexical hasTemplateVariables={true} />
            </DocumentEditorContext.Provider>
          ) : (
            <Loading />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: sidebarIsOpen() ? sidebarWidth : '0',
          maxWidth: sidebarWidth,
        }}
      >
        {getSidebarContent()}
      </Box>
    </Grid>
  )
}
