export const typography: any = {
  button: {
    fontWeight: 500,
    lineHeight: '20px',
    fontSize: '0.875rem',
    '@media (max-width:600px)': {
      fontSize: '1rem',
      lineHeight: '24px',
    },
  },
  fontFamily: [
    '"Inter"',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Helvetica',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
  ].join(', '),
  body1: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '20px',
    '@media (max-width:600px)': {
      fontSize: '1rem',
      lineHeight: '24px',
    },
  },
  body2: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '16px',
    '@media (max-width:600px)': {
      fontSize: '0.875rem',
    },
  },
  subtitle1: {
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: '2rem',
    paddingTop: 0,
  },
  subtitle2: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '24px',
    padding: 0,
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1rem',
  },
  overline: {
    fontSize: '10px',
    fontWeight: 600,
    letterSpacing: '4%',
    lineHeight: 1,
    textTransform: 'uppercase',
  },
  h1: {
    fontWeight: 700,
    fontSize: '3.5rem',
    lineHeight: 1.375,
  },
  h2: {
    fontWeight: 700,
    fontSize: '3rem',
    lineHeight: 1.375,
  },
  h3: {
    fontWeight: 700,
    fontSize: '2.25rem',
    lineHeight: 1.375,
  },
  h4: {
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '36px',
  },
  h5: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.375,
  },
  h6: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '20px',
  },

  // Figma
  heading3: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
  },
  heading4: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
  },
  heading5: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  heading6: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    '@media (max-width:600px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  paragraph2: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  paragraph3: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',

    '@media (max-width:600px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  paragraph4: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',

    '@media (max-width:600px)': {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    heading3: React.CSSProperties
    heading4: React.CSSProperties
    heading5: React.CSSProperties
    heading6: React.CSSProperties

    paragraph2: React.CSSProperties
    paragraph3: React.CSSProperties
    paragraph4: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    heading3?: React.CSSProperties
    heading4?: React.CSSProperties
    heading5?: React.CSSProperties
    heading6?: React.CSSProperties

    paragraph2?: React.CSSProperties
    paragraph3?: React.CSSProperties
    paragraph4?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    heading3: true
    heading4: true
    heading5: true
    heading6: true

    paragraph2: true
    paragraph3: true
    paragraph4: true
  }
}
