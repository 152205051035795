import {
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Avatar,
} from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { fetchWorkspaceMembers } from 'store/slices/workspace'
import { stringAvatar } from 'utils/stringAvatar'
import img_arrow_down from 'assets/img/keyboard_arr_down.svg'
import close_img from 'assets/img/close-btn.svg'
import { useSearchBarContext } from 'context'
import { useSearchParams } from 'react-router-dom'
import { EListQueryParam } from 'helper/queryParams'

export const FilterAuthor = () => {
  const { setAuthorQuery, authorQuery } = useSearchBarContext()
  const [searchParams, setSearchParams] = useSearchParams()

  const dispatch = useDispatch()
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )
  const { members } = useSelector(
    (state: RootState) => state.workspace,
    shallowEqual
  )

  const handleChange = (event) => {
    setAuthorQuery(event.target.value)
    searchParams.set(EListQueryParam.Author, event.target.value)
    setSearchParams(searchParams)
  }

  const clearAuthor = () => {
    setAuthorQuery('')

    searchParams.delete(EListQueryParam.Author)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    dispatch(fetchWorkspaceMembers({ id: selectedWorkspace?.id || '' }) as any)
  }, [selectedWorkspace?.id])

  const renderAuthorValue = (value) => {
    if (value === '') {
      return <Box component={'span'}>Автор</Box>
    }
    const option = members.find((o) => o.user_id === value)
    return option ? (
      <Box display={'flex'} alignItems={'center'} height={'20px'}>
        <Typography variant="body1">Автор </Typography>
        <Box
          ml={'4px'}
          borderRadius={'4px'}
          border={'1px solid #EDEDEC'}
          padding={'0 4px'}
          display={'flex'}
          alignItems={'center'}
        >
          <Typography variant="body1"> {option.email}</Typography>
          <IconButton
            onMouseDown={(e) => {
              e.stopPropagation()
              e.preventDefault()
              clearAuthor()
            }}
            sx={{
              ml: '4px',
              borderRadius: '4px',
              padding: '0!important',
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
            }}
          >
            <Box
              component={'img'}
              src={close_img}
              width={'12px'}
              height={'12px'}
            />
          </IconButton>
        </Box>
      </Box>
    ) : (
      ''
    )
  }

  return (
    <Box>
      <Select
        variant="outlined"
        IconComponent={() => (
          <Box
            component={'img'}
            src={img_arrow_down}
            width={'15px'}
            height={'15px'}
            display={'inline-block'}
            position={'absolute'}
            right={'8px'}
            top={'calc(50% - 0.5em)'}
            sx={{ pointerEvents: 'none' }}
          />
        )}
        MenuProps={{
          PaperProps: {
            style: {
              width: '100%',
              marginTop: '2px',
              maxHeight: '320px',
            },
          },
        }}
        fullWidth
        name="author"
        value={authorQuery}
        onChange={handleChange}
        sx={{ p: 0, maxWidth: 'fit-content' }}
        displayEmpty
        renderValue={renderAuthorValue}
      >
        {members.map((option) => (
          <MenuItem
            key={option.user_id}
            value={option.user_id}
            sx={{ m: '2px 4px' }}
          >
            <Box display={'flex'} alignItems="center" flexDirection={'row'}>
              <Box mr="8px">
                <Avatar
                  variant="circular"
                  alt={'email name'}
                  {...stringAvatar(option.email || 'e', {
                    width: '16px',
                    height: '16px',
                    fontSize: '12px',
                    lineHeight: '20px',
                  })}
                />
              </Box>
              <Typography variant="body1">{option.email}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
