import img_share from 'assets/img/ic_share.svg'

import { Box, IconButton, Menu } from '@mui/material'
import { CustomTooltip } from 'components'
import { useState } from 'react'
import { MenuItemShareDocument } from '../MenuItemShareDocument'

export const IconButtonShareDocument = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <CustomTooltip title={`Поделиться документом`}>
        <IconButton onClick={handleClick}>
          <Box
            component={'img'}
            src={img_share}
            width={'20px'}
            height={'20px'}
          />
        </IconButton>
      </CustomTooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          mt: '2px',
        }}
      >
        <MenuItemShareDocument />
      </Menu>
    </>
  )
}
