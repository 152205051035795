export const convertDateToLocale = (date = '', withHours = true): string => {
  if (!date) return ''
  return withHours
    ? new Date(date).toLocaleString('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    : new Date(date).toLocaleString('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })
}
