import { Button, Box } from '@mui/material'
import { Empty } from 'components'
import { useSearchBarContext } from 'context'
import { EDocumentStatus } from 'helper/consts'
import { useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { ArchivedDocsTable } from './archivedDocsTable'
import { DeletedDocumentsTable } from './deletedDocsTable'
import { StandardDocumentsTable } from './standardDocsTable'

export const RegistryTable = () => {
  const { filter, count }: IDocTableState = useSelector(
    (state: RootState) => state.documents,
    shallowEqual
  )
  const {
    statusQuery,
    authorQuery,
    queryString,
    setAuthorQuery,
    setStatusQuery,
    setQueryString,
  } = useSearchBarContext()

  const handleClick = () => {
    setAuthorQuery('')
    setQueryString('')
    setStatusQuery('')
  }

  const renderedTable = useMemo(() => {
    switch (filter) {
      case EDocumentStatus.DELETED:
        return <DeletedDocumentsTable />
      case EDocumentStatus.ARCHIVED:
        return <ArchivedDocsTable />
      default:
        return <StandardDocumentsTable />
    }
  }, [filter])

  const showEmptyPage = useMemo(() => {
    return (
      <Empty
        title={'По вашему запросу ничего не найденов'}
        subtitle={
          'Попробуйте изменить параметры поиска или выбрать другой фильтр'
        }
      >
        <Button color={'secondary'} variant={'outlined'} onClick={handleClick}>
          Сбросить фильтр
        </Button>
      </Empty>
    )
  }, [filter])

  return (
    <Box>
      {count === 0 && (authorQuery || statusQuery || queryString)
        ? showEmptyPage
        : renderedTable}
    </Box>
  )
}
