import { UrlKeys } from 'helper/consts'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { useState, useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import {
  fileDataFetch,
  resetDocumentEditor,
} from 'store/slices/applicationSlice'

export const useDocumentEditorContentLoad = () => {
  const { urlId } = useUrlIdParam(UrlKeys.documentId)
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false)

  const dispatch = useDispatch<any>()
  const { document, loading } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )

  useEffect(() => {
    const documentId = urlId.get()
    !documentId && document.id && urlId.setIfNotExists(document.id)
  }, [document.id])

  const loadDocumentInfo = async () => {
    const id = urlId.get()
    if (id) await dispatch(fileDataFetch({ id }) as any)
    else dispatch(resetDocumentEditor({ id: id }))
  }

  useEffect(() => {
    loadDocumentInfo()
  }, [])

  useEffect(() => {
    if (isDocumentLoaded) return
    setIsDocumentLoaded(!loading.source && !loading.info)
  }, [loading.source, loading.info])

  return {
    isDocumentLoaded,
  }
}
