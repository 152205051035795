import { Typography, TextField, FormHelperText, Box } from '@mui/material'
import { useField, useFormikContext } from 'formik'
import errorIcon from 'assets/img/error-icon.svg'
import blue_teamspace from 'assets/img/group_icon/blue.svg'

export const TextInput = ({
  label,
  disabled,
  required,
  helperText,
  isFillerInput = false,
  ...props
}: any) => {
  const req =
    required || !isFillerInput ? null : (
      <span
        style={{
          color: '#90959D',
          fontWeight: 400,
        }}
      >
        (Не обязательно)
      </span>
    )
  const [field, meta] = useField(props)
  const { dirty } = useFormikContext()

  const showError = () =>
    isFillerInput ? meta.error : dirty && meta.error && meta.touched

  return (
    <>
      <Typography variant="body1" mb="2px" fontWeight={500}>
        {label} {req}
      </Typography>
      <TextField
        {...field}
        {...props}
        disabled={disabled}
        error={showError() ? true : false}
      />
      {showError() && (
        <FormHelperText
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#E92C2C',
          }}
        >
          <img
            src={errorIcon}
            width="16px"
            height="16px"
            style={{ marginRight: '4px' }}
          />
          {meta.error}
        </FormHelperText>
      )}
      <Typography variant="body2" color={'text.disabled'} mt="4px">
        {helperText}
      </Typography>
    </>
  )
}

export const ErrorHelper = ({ error }) => {
  return (
    <FormHelperText
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: '#E92C2C',
      }}
    >
      <img
        src={errorIcon}
        width="16px"
        height="16px"
        style={{ marginRight: '4px' }}
      />
      {error}
    </FormHelperText>
  )
}

export const TextInputIcon = ({
  label,
  disabled,
  required,
  helperText,
  ...props
}: any) => {
  const req = required ? <span style={{ color: 'red' }}>*</span> : null
  const [field, meta] = useField(props)

  return (
    <>
      <Typography variant="h6" mb="2px">
        {label} {req}
      </Typography>
      <Box display={'flex'}>
        <Box
          p="5px"
          border={'1px solid #EDEDEC'}
          mr="4px"
          height={'32px'}
          borderRadius="4px"
        >
          <Box
            component={'img'}
            src={blue_teamspace}
            alt=""
            width={'20px'}
            height={'20px'}
            sx={{ filter: props.filter }}
          />
        </Box>
        <Box width={'100%'}>
          <TextField
            {...field}
            {...props}
            disabled={disabled}
            error={meta.error ? true : false}
          />
          {meta.touched && meta.error && (
            <FormHelperText
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: '#E92C2C',
              }}
            >
              <img
                src={errorIcon}
                width="16px"
                height="16px"
                style={{ marginRight: '4px' }}
              />
              {meta.error}
            </FormHelperText>
          )}
          <Typography variant="body1" color={'text.disabled'} mt="4px">
            {helperText}
          </Typography>
        </Box>
      </Box>
    </>
  )
}
