import {
  Button,
  CircularProgress,
  Typography,
  Box,
  FormLabel,
} from '@mui/material'
import { useDropzone } from 'react-dropzone'
import info from 'assets/img/error_outline.svg'
import file_upload from 'assets/img/file_upload.svg'

interface IProps {
  onDrop: any
  open?: boolean
  onDropRejected?: any
  accept: any
  loading: boolean
  description?: string
  error?: any
  clearError?: any
  multiple?: boolean
  maxSizeInBytes?: number // 15_000_000 b
}

export const DropZone = ({
  onDrop,
  onDropRejected,
  accept,
  loading,
  description,
  error,
  clearError,
  multiple,
  maxSizeInBytes,
}: IProps) => {
  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      accept,
      onDrop,
      multiple: multiple ?? false,
      onDropRejected,
      maxSize: maxSizeInBytes,
      disabled: loading,
    })

  return (
    <Box
      {...getRootProps({ role: 'button' })}
      className={
        isDragAccept
          ? 'dropzone active'
          : isDragReject || error
          ? 'dropzone reject'
          : 'dropzone'
      }
      sx={{
        cursor: loading ? 'no-drop' : 'pointer',
        borderRadius: '4px',
        border: '1px dashed var(--light-grayscale-content-4, #90959D)',
        background: 'var(--light-grayscale-background-primary, #FFF)',

        padding: '32px 24px',
        textAlign: 'center',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '&:hover': {
          background: '#F5F5F5',
          borderColor: '#282A2F',
        },
        '&:hover .label': {
          textDecoration: 'underline',
        },
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Box
            component={'img'}
            src={file_upload}
            alt=""
            width={'24px'}
            height={'24px'}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              gap: '8px',
            }}
          >
            <CircularProgress
              size={'16px !important'}
              sx={{
                width: '16px !important',
                height: '16px !important',
                color: 'secondary.main',
              }}
            />
            <Typography>Файл загружается</Typography>
          </Box>
        </Box>
      ) : (
        <>
          {error ? (
            <>
              <img src={info} alt="" style={{ marginBottom: '10px' }} />
              <Typography mb={1}>{error}</Typography>
              <Button
                size="small"
                variant="outlined"
                color={'info'}
                onClick={clearError}
              >
                Попробовать снова
              </Button>
            </>
          ) : (
            <>
              <Box>
                <img src={file_upload} alt="" width={'24px'} height={'24px'} />
                <Typography
                  variant={'body1'}
                  className="dropzone-content"
                  mb={1}
                >
                  <input
                    className="upload-file"
                    {...getInputProps({
                      type: 'file',
                      multiple: multiple ?? false,
                    })}
                  />
                  <FormLabel
                    htmlFor="upload-file"
                    className="label"
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    Выберите файл
                  </FormLabel>{' '}
                  или перенесите его сюда
                </Typography>

                <Typography
                  color={'text.disabled'}
                  variant={'caption'}
                  fontWeight={400}
                >
                  {description
                    ? description
                    : 'Формат: pdf, doc, docx максимальный размер файла 5MB'}
                </Typography>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  )
}

export const DropZonePublic = ({
  onDrop,
  onDropRejected,
  accept,
  loading,
  description,
  error,
  clearError,
}: IProps) => {
  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      accept,
      onDrop,
      maxFiles: 1,
      multiple: false,
      onDropRejected,
      maxSize: 5000000,
    })

  return (
    <Box
      id="public"
      {...getRootProps({ role: 'button' })}
      className={
        isDragAccept
          ? 'dropzone active'
          : isDragReject || error
          ? 'dropzone reject'
          : 'dropzone'
      }
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {error && (
            <>
              <img src={info} alt="" style={{ marginBottom: '10px' }} />
              <Typography mb={'20px'}>{error}</Typography>
              <Button
                size="small"
                variant="outlined"
                color={'info'}
                onClick={clearError}
              >
                Попробовать снова
              </Button>
            </>
          )}
          {!error && (
            <>
              <Box className="text-center">
                <img src={file_upload} alt="" />

                <Typography variant={'body1'} className="dropzone-content">
                  Выберите файл или перенесите его сюда
                </Typography>

                <Typography color={'text.disabled'} variant={'body1'}>
                  {description ? description : 'Формат: PDF'}
                </Typography>
              </Box>
              <input
                className="upload-file"
                {...getInputProps({ type: 'file', multiple: false })}
              />
              <label id="upload-file-label-public" htmlFor="upload-file">
                {!loading ? (
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '20px',
                      lineHeight: '28px',
                    }}
                  >
                    Выбрать файл
                  </Typography>
                ) : (
                  <CircularProgress />
                )}
              </label>
            </>
          )}
        </>
      )}
    </Box>
  )
}
