import { Typography, Button, Box } from '@mui/material'
import { AppBarSettings } from 'components/header/app-bar-common'
import { ModalPlanLimit } from 'components/modal/ModalPlanLimit'
import { EFeature } from 'helper/plan.types'
import { WSRolePermissions } from 'helper/roles'
import { InviteModalWS } from 'layout/dashboard-layout/components/create-group'
import { useState, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { getWSPlanFeatureRestriction } from 'utils/plans'

export const WSMembersHeader = () => {
  const [open, setOpen] = useState(false)
  const [hasLimit, setHasLimit] = useState(false)

  const {
    plan_info,
    loading: { plan: loadingPlan },
  } = useSelector((state: RootState) => state.wsPlan, shallowEqual)

  const { members } = useSelector(
    (state: RootState) => state.workspace,
    shallowEqual
  )

  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  useEffect(() => {
    const { prohibited, exhausted } = getWSPlanFeatureRestriction(
      EFeature.users_count,
      plan_info
    )
    setHasLimit(prohibited || exhausted)
  }, [plan_info])

  return (
    <>
      {hasLimit ? (
        <ModalPlanLimit
          open={open}
          handleClose={handleClose}
          limitOn={EFeature.users_count}
        />
      ) : (
        <InviteModalWS
          open={open}
          handleClose={handleClose}
          name={selectedWorkspace?.name}
        />
      )}
      <AppBarSettings
        headerTitle={
          <>
            <Box display={'flex'}>
              <Typography variant="subtitle2">Участники</Typography>
              <Typography variant="subtitle2" color={'text.secondary'}>
                &nbsp;{members.length}
              </Typography>
            </Box>
            {selectedWorkspace &&
              WSRolePermissions[selectedWorkspace?.group_name]
                .manageMembers && (
                <Box mt="12px">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loadingPlan}
                    onClick={handleOpen}
                  >
                    Пригласить участников
                  </Button>
                </Box>
              )}
          </>
        }
      />
    </>
  )
}
