import { ESystemVariableType } from 'features/text-editors/lexical/plugins-custom/ParameterPickerPlugin/helpers'
import * as yup from 'yup'

const buildStringDateValidation = (variable: IVariable) => {
  let validation = yup.string()

  if (variable.is_required) {
    validation = validation.required('Обязательное поле')
  }

  return validation
}

const buildNumberValidation = (variable: IVariable) => {
  const attrs = variable.attrs as IVariableInputTextAttrs

  let validation = yup.number()

  if (variable.is_required) {
    validation = validation.required('Обязательное поле')
  }

  if (attrs?.has_max) {
    validation = validation.max(attrs.max, `Максимальное число ${attrs.max}`)
  }

  if (attrs?.has_min) {
    validation = validation.min(attrs.min, `Минимальное число ${attrs.min}`)
  }

  return validation
}

const buildStringValidation = (variable: IVariable) => {
  const attrs = variable.attrs as IVariableInputTextAttrs

  let validation = yup.string()

  if (variable.is_required) {
    validation = validation.required('Обязательное поле')
  }

  if (variable.type === ESystemVariableType.INPUT_EMAIL) {
    validation = validation.email('Некорректный Email')
  }

  if (attrs?.has_max) {
    validation = validation.max(attrs.max, `Максимальная длинна ${attrs.max}`)
  }

  if (attrs?.has_min) {
    validation = validation.min(attrs.min, `Минимальная длинна ${attrs.min}`)
  }

  if (attrs?.numeric) {
    validation = validation.matches(/^\d*$/, 'Только цифры')
  }

  if (attrs?.alpha) {
    validation = validation.matches(
      /^[a-zA-Z]*$/,
      'Только буквы'
    )
  }

  return validation
}

const buildDropdownValidation = (variable: IVariable) => {
  let validation = yup.array()

  if (variable.is_required) {
    validation = validation.required('Обязательное поле')
  }

  return validation
}

export const buildValidationSchema = (data) => {
  if (!data) return yup.object().shape({})

  const schema = {}

  Object.keys(data).forEach((key) => {
    if (data[key].actor_id > 1) return

    switch (data[key].type) {
      case ESystemVariableType.DROPDOWN:
        schema[key] = buildDropdownValidation(data[key])
        break
      case ESystemVariableType.INPUT_TEXT:
        schema[key] = buildStringValidation(data[key])
        break
      case ESystemVariableType.INPUT_EMAIL:
        schema[key] = buildStringValidation(data[key])
        break
      case ESystemVariableType.INPUT_NUMBER:
        schema[key] = buildNumberValidation(data[key])
        break
      case ESystemVariableType.INPUT_DATE:
        schema[key] = buildStringDateValidation(data[key])
        break
      default:
        break
    }
  })

  return yup.object().shape(schema)
}
