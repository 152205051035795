import { HeadingNode } from '@lexical/rich-text'
import { $isColumnsListNode, ColumnsListNode } from "features/text-editors/lexical/custom/nodes/ColumnsListNode"
import { ColumnsListItemNode } from "features/text-editors/lexical/custom/nodes/ColumnsListNode/ColumnsListItemNode"
import { LexicalEditor, LexicalNode, ParagraphNode } from "lexical"
import { TARGET_LINE_DEFAULT_WIDTH } from "../private.conts"
import { setTargetLine } from "./setTargetLine"


const setTargetLineOnDragoverToTextNodeForCreatingColumnsList = (editor: LexicalEditor, targetNode: LexicalNode, mouse: MouseEvent, targetLineElem: HTMLElement, anchorElem: HTMLElement): boolean => {
  const targetEl = editor.getElementByKey(targetNode.getKey())
  if (!targetEl) return false

  const targetRect = targetEl.getBoundingClientRect()
  if (mouse.pageX < targetRect.left + targetRect.width - 10) return false

  const anchorRect = anchorElem.getBoundingClientRect()

  setTargetLine(targetLineElem, {
    height: targetRect.height,
    top: targetRect.top - anchorRect.top,
    left: targetRect.left + targetRect.width - TARGET_LINE_DEFAULT_WIDTH / 2 - anchorRect.left
  })
  return true
}

const dragoverToParent: {
  [key: string]: (editor: LexicalEditor, parentNode: LexicalNode, mouse: MouseEvent, targetLineElem: HTMLElement, anchorElem: HTMLElement) => boolean
} = {
  [ColumnsListNode.getType()]: (editor, parentNode, mouse, targetLineElem, anchorElem) => {
    if (!$isColumnsListNode(parentNode)) return false

    const children = parentNode.getChildren<ColumnsListItemNode>()
    if (children.length == 0) return false

    const anchorRect = anchorElem.getBoundingClientRect()

    let prevColumnsListItemRect: DOMRect | null = null
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      const columnsListItemEl = editor.getElementByKey(child.getKey())
      if (!columnsListItemEl) continue

      const columnsListItemRect = columnsListItemEl.getBoundingClientRect()
      if (mouse.pageX < columnsListItemRect.left + columnsListItemRect.width / 2) {
        let left = columnsListItemRect.left - TARGET_LINE_DEFAULT_WIDTH
        if (!!prevColumnsListItemRect) {
          left = prevColumnsListItemRect.right + (columnsListItemRect.left - prevColumnsListItemRect.right) / 2 - (TARGET_LINE_DEFAULT_WIDTH / 2)
        }
        setTargetLine(targetLineElem, {
          height: columnsListItemRect.height,
          top: columnsListItemRect.top - anchorRect.top,
          left: left - anchorRect.left
        })
        return true
      }
      prevColumnsListItemRect = columnsListItemRect
    }

    if (!prevColumnsListItemRect) return false
    setTargetLine(targetLineElem, {
      height: prevColumnsListItemRect.height - anchorRect.height,
      top: prevColumnsListItemRect.top - anchorRect.top,
      left: prevColumnsListItemRect.right - anchorRect.left
    })
    return true
  },
  [ParagraphNode.getType()]: setTargetLineOnDragoverToTextNodeForCreatingColumnsList,
  [HeadingNode.getType()]: setTargetLineOnDragoverToTextNodeForCreatingColumnsList,
}

/**
 * setTargetLineOnDragoverToParent - Using for show correct `targetLineElement` depend on dragover to specific parent node.
 * @param editor 
 * @param parentNode is targetNode 
 * @param mouse is using for get mouse position depend on `parentNode`
 * @param targetLineElem is using for show targetLineElement correct for `parentNode`
 * @returns  If `targetLineElement` has setted returns true, otherwise false
 */
export function setTargetLineOnDragoverToParent(editor: LexicalEditor, parentNode: LexicalNode, mouse: MouseEvent, targetLineElem: HTMLElement, anchorElem: HTMLElement): boolean {
  const onDragoverToParent = dragoverToParent[parentNode.getType()]
  if (!onDragoverToParent) return false
  return onDragoverToParent(editor, parentNode, mouse, targetLineElem, anchorElem)
}
