import { createAsyncThunk } from '@reduxjs/toolkit'
import { planApi } from 'service/plan'
import { IPayment } from '..'

export const wsPlanGetPaymentsHistory = createAsyncThunk<
  IPayment[],
  { wsId: string },
  { rejectValue: string }
>(
  'wsPlan/getPaymentsHistory',
  async ({ wsId }: { wsId: string }, { rejectWithValue }) => {
    try {
      const { data } = await planApi.getSubscriptionsPayments({
        workspace_id: wsId,
      })
      return data.payments
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)
