import { Box } from '@mui/material'
import { FormikTextField } from 'components/using-formik-context/FormikTextField'
import { FormikFormWebhookAddSecretKey } from '../FormikFormWebhookAddSecretKey'
import { FormikFormWebhookAddHeaders } from '../FormikFormWebhookAddHeaders'

export interface IFormikFieldWebhookURL {
  url: string
}

export const FormikFormWebhook = () => {
  // Form created with reading post https://dev.to/pauldumebi/dynamic-forms-with-validation-react-formik-22dc
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <Box>
        <FormikTextField
          name="url"
          label="URL адрес"
          placeholder="https://..."
        />
      </Box>
      <FormikFormWebhookAddSecretKey />
      <FormikFormWebhookAddHeaders />
    </Box>
  )
}
