import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { SettingItemWithToggle } from 'components/menu-item/SettingItemWithToggle'
import { ModalCenterSmall } from 'components/modal'
import { EWorkspacePlan } from 'helper/plan.types'
import { formatDatePlan } from 'pages/plan/lib/formatDatePlan'
import { formatPrice } from 'pages/plan/lib/formatPrice'
import { getNextPaymentDate } from 'pages/plan/lib/getNextPaymentDate'
import { getTextMonths } from 'pages/plan/lib/getTextMonths'
import { EPlanPeriod, planPeriodNames, plansInfoMap } from 'pages/plan/types'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { shallowEqual } from 'react-redux'
import { useAppSelector } from 'store/store'

interface IModalPlanPaymentMethodPropContent {
  planName: EWorkspacePlan
  monthCount: EPlanPeriod
  handleClickGenerateInvoice: () => void
  handleClickPayWithCard: () => Promise<void> | void
  requireClosingDocuments: boolean
  setRequireClosingDocuments: (value: boolean) => void
}

export const ModalPlanPaymentMethod = ({
  open,
  handleClose,
  content,
}: {
  open: boolean
  handleClose: () => void
  content: IModalPlanPaymentMethodPropContent
}) => {
  const {
    loading,

    costPerMonth,
    costPerMonthWithDiscount,
    resultCost,
    nextPaymentDate,

    requireClosingDocuments,
    setRequireClosingDocuments,

    handleClickPayWithCard,
  } = useModalPlanPaymentMethod(content)

  return (
    <ModalCenterSmall
      open={open}
      handleClose={handleClose}
      header={{
        children: (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 'calc(100% - 24px)',
              }}
            >
              <Typography variant={'heading5'}>
                Тариф «{content.planName}»
              </Typography>
              <Typography variant={'paragraph3'}>
                Оплата раз в {planPeriodNames[content.monthCount]} · Следующий
                платеж:{' ~ '}
                {formatDatePlan(nextPaymentDate)}
              </Typography>
            </Box>
          </>
        ),
      }}
      content={{
        noPaddings: true,
        children: (
          <Box
            sx={{
              borderTop: '1px solid var(--light-grayscale-border, #EDEDEC)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: '8px 16px',
              }}
            >
              {!isMobile && (
                <Typography variant={'paragraph3'}>
                  Тариф «{content.planName}»
                </Typography>
              )}
              <Box
                display={'flex'}
                gap={'8px'}
                flexDirection={isMobile ? 'column' : 'row'}
              >
                {costPerMonth != costPerMonthWithDiscount && (
                  <Typography
                    variant={'paragraph3'}
                    sx={{
                      color: 'var(--light-grayscale-content-4, #90959D)',
                      textDecoration: 'line-through',
                    }}
                  >
                    {costPerMonth} /в месяц
                  </Typography>
                )}

                <Box display={'flex'} gap={'8px'}>
                  <Typography variant={'paragraph3'}>
                    {costPerMonthWithDiscount}₸ /в месяц
                  </Typography>
                  <Typography
                    variant="paragraph3"
                    style={{
                      color: 'var(--light-grayscale-content-4, #90959D)',
                    }}
                  >
                    ×
                  </Typography>
                  <Typography variant="paragraph3">
                    {getTextMonths(content.monthCount)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: '8px 16px',
                bgcolor: '#F5F5F5',
              }}
            >
              <Typography variant={'paragraph2'}>Итого:</Typography>
              <Typography variant={isMobile ? 'heading4' : 'heading5'}>
                {resultCost}₸
              </Typography>
            </Box>

            <Box
              sx={{
                p: '8px 16px',
              }}
            >
              <SettingItemWithToggle
                title={{
                  isHeading: false,
                  text: 'Мне нужны закрывающие документы',
                }}
                checked={requireClosingDocuments}
                disabled={loading}
                onClick={() =>
                  setRequireClosingDocuments(!requireClosingDocuments)
                }
              />
            </Box>
          </Box>
        ),
      }}
      footer={{
        noPaddings: true,
        children: (
          <Box
            sx={{
              p: '16px',
              display: 'flex',
              justifyContent: 'flex-end',
              flexWrap: isMobile ? 'wrap' : 'nowrap',
              gap: '8px',
            }}
          >
            {plansInfoMap[content.planName]
              ?.isBuildPaymentInvoiceDisabled ? null : (
              <Button
                variant="outlined"
                color="secondary"
                onClick={content.handleClickGenerateInvoice}
                fullWidth={isMobile}
                disabled={loading}
              >
                Сформировать счёт
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickPayWithCard}
              disabled={loading}
              fullWidth={isMobile}
              sx={{
                gap: '4px',
              }}
            >
              {loading && <CircularProgress color="inherit" />}
              <Typography>Оплатить картой</Typography>
            </Button>
          </Box>
        ),
      }}
    />
  )
}

const useModalPlanPaymentMethod = (
  content: IModalPlanPaymentMethodPropContent
) => {
  const { plan_name, plan_info } = useAppSelector(
    (state) => state.wsPlan,
    shallowEqual
  )

  const [loading, setLoading] = useState(false)

  const costPerMonth = formatPrice(
    plansInfoMap[content.planName]?.price[EPlanPeriod.month] ?? '???'
  )

  const costPerMonthWithDiscount = formatPrice(
    Math.ceil(
      plansInfoMap[content.planName]?.price[content.monthCount] /
        content.monthCount
    )
  )

  const resultCost = formatPrice(
    plansInfoMap[content.planName]?.price[content.monthCount] ?? '???'
  )

  const nextPaymentDate = getNextPaymentDate(
    content.monthCount,
    plan_name == content.planName
      ? new Date(plan_info.workspace_limits?.end_at ?? new Date())
      : new Date()
  )

  const handleClickPayWithCard = async () => {
    setLoading(true)
    await content.handleClickPayWithCard()
    setLoading(false)
  }

  return {
    requireClosingDocuments: content.requireClosingDocuments,
    setRequireClosingDocuments: content.setRequireClosingDocuments,

    loading,
    setLoading,

    costPerMonth,
    costPerMonthWithDiscount,
    resultCost,
    nextPaymentDate,

    handleClickPayWithCard,
  }
}
