import { EWorkspacePlan } from 'helper/plan.types'
import { EPlanPeriod } from 'pages/plan/types'
import { createContext, useEffect, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { useAppSelector } from 'store/store'

interface ISelectPlanContext {
  selectedPeriod: EPlanPeriod
  setPeriod: (period: EPlanPeriod | any) => void
  selectedPlan?: EWorkspacePlan
  setPlan: (plan: EWorkspacePlan | undefined) => void
  requireClosingDocuments: boolean
  setRequireClosingDocuments: (value: boolean) => void
}

export const SelectPlanContext = createContext<ISelectPlanContext>({
  selectedPeriod: EPlanPeriod.month,
} as ISelectPlanContext)

export const SelectPlanProvider = ({ children }) => {
  const { require_closing_documents } = useAppSelector(
    (state) => state.wsPlan,
    shallowEqual
  )

  const [selectedPeriod, setPeriod] = useState(EPlanPeriod.month)
  const [selectedPlan, setPlan] = useState<EWorkspacePlan | undefined>()
  const [requireClosingDocuments, setRequireClosingDocuments] = useState(
    require_closing_documents
  )

  useEffect(() => {
    setRequireClosingDocuments(require_closing_documents)
  }, [require_closing_documents])

  return (
    <SelectPlanContext.Provider
      value={{
        selectedPeriod,
        setPeriod,
        selectedPlan,
        setPlan,
        requireClosingDocuments,
        setRequireClosingDocuments,
      }}
    >
      {children}
    </SelectPlanContext.Provider>
  )
}
