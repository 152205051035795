import { ERecipientRole } from "helper/consts"
import { RecipientPrirority } from "./const"
import { IStep } from "../steps/IStep"
import { CStepSign } from "../steps/CStepSign"
import { CAbstractRecipient } from "./CAbstractRecipient"
import { ERecipientAttr, IRecipientAttrsFilter } from "./types"
import { IRecipient } from "./IRecipient"
import { RecipientHelperNS } from "./RecipientHelperNS"

export interface IRecipientAnonymousApproverRK {
  actor_id?: number
  attrs?: {
    filter?: IRecipientAttrsFilter[]
    limit?: number
  }
}

export class CRecipientAnonymousApproverRK extends CAbstractRecipient {
  actor_id?: number
  role: ERecipientRole.anonymous_approver_rk = ERecipientRole.anonymous_approver_rk
  attrs?: {
    filter?: IRecipientAttrsFilter[]
    limit?: number
  }

  constructor(obj: IRecipientAnonymousApproverRK) {
    super()

    if (obj?.attrs?.filter) {
      obj.attrs.filter = obj.attrs.filter?.map(filter => {
        return RecipientHelperNS.BuildIRecipientAttrsFilter(filter)
      })
    }

    this.actor_id = obj.actor_id
    this.attrs = obj.attrs
  }

  isSame(recipient: IRecipient): boolean {
    return RecipientPrirority[recipient.role] === RecipientPrirority[this.role]
      && !!this.actor_id && recipient?.actor_id === this.actor_id
  }

  attachOnModeDefault(steps: IStep[]): boolean {
    const stepIndex = steps
      .findIndex(step => RecipientPrirority[step.recipients[0].role] === RecipientPrirority[this.role])

    if (stepIndex === -1) {
      const newStep = new CStepSign({
        index: 1,
        recipients: [this],
      })
      RecipientHelperNS.InsertStepWithRecipientToSteps(steps, newStep)
    } else {
      if (steps[stepIndex].recipients.findIndex(recipient => this.isSame(recipient)) !== -1) return false

      steps[stepIndex].recipients.push(this)
    }
    return true
  }

  attachOnModeOrdered(steps: IStep[]): boolean {
    const newStep = new CStepSign({ index: steps.length + 1, recipients: [this] })
    return RecipientHelperNS.InsertStepWithRecipientToSteps(steps, newStep)
  }

  mayContainAttr(field: ERecipientAttr): boolean {
    return {
      [ERecipientAttr.limit]: true,
      [ERecipientAttr.filter_bin]: true,
      [ERecipientAttr.filter_iin]: true,
    }[field] ?? false
  }
}
