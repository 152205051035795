import { Box, TextField } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ButtonPurple } from 'pages/document-preview/components/send-sign-button/button-purple'
import { shallowEqual, useSelector } from 'react-redux'
import { ChatMessage } from './chatMessage'
import { ChatQuestionsBlock } from './chatQuestionsBlock'
import { scrollToBottom } from 'utils/scrollToBottom'
import { ChatLoading } from './chatLoading'
import { ChatLoadHistoryError } from './chatLoadHistoryError'
import { EAiChatMessageAuthor } from 'helper/aiChat.types'
import {
  getQuestionsFromAiChatMessage,
  getRenderedMessageResponseTextWithQuestionTags,
  isChatMessageAuthorAI,
  isRecomendedQuestionByText,
} from 'utils/aichat'
import { isMobile } from 'react-device-detect'
import { EDocumentType } from 'helper/consts'
import { useAiChatContext } from 'context'

export const Chat = () => {
  const aiChat = useAiChatContext()

  const { document } = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )
  const rdxUser = useSelector((state: RootState) => state.user, shallowEqual)

  const funcAsk = async ({ text }) => {
    if (isAskDisabled) return
    aiChat.sendMessage(text)
    scrollToBottom(bottomRef)
  }

  const formik = useFormik({
    initialValues: {
      messageText: '',
    },
    validationSchema: Yup.object().shape({
      messageText: Yup.string().required('Текст не должен быть пустым'),
    }),
    onSubmit: async (values, helpers) => {
      if (values.messageText.trim().length == 0) return
      if (aiChat.state.response_message.loading) return
      funcAsk({ text: values.messageText })
      helpers.resetForm()
    },
  })

  const chatContentRef = useRef<null | HTMLDivElement>(null)
  const bottomRef = useRef<null | HTMLDivElement>(null)
  const inputBlockRef = useRef<null | HTMLDivElement>(null)

  const heightPx = `calc(100dvh - ${isMobile ? '166px' : '122px'})`
  const [inputBlockHeightPx, setInputBlockHeightPx] = useState(`136px`)
  const chatBlockHeightPx = `calc(${heightPx} - ${inputBlockHeightPx})`
  const [isAtBottom, setIsAtBottom] = useState(true)

  const isAskDisabled = useMemo(() => {
    return (
      aiChat.state.messages.loading ||
      !!aiChat.state.messages.error ||
      aiChat.state.response_message.loading
    )
  }, [
    aiChat.state.messages.loading,
    aiChat.state.messages.error,
    aiChat.state.response_message.loading,
  ])

  const chatContent = useMemo(() => {
    return (
      <>
        {aiChat.state.messages.value.map((el, i) => {
          if (
            i === 0 &&
            isChatMessageAuthorAI(el.author) &&
            isRecomendedQuestionByText(el.text)
          ) {
            return (
              <ChatQuestionsBlock
                questions={getQuestionsFromAiChatMessage(el.text)}
                disabled={isAskDisabled}
                ask={funcAsk}
                key={`chat-message-${i}`}
              />
            )
          }

          return (
            <ChatMessage
              msg={el}
              name={
                el.author == EAiChatMessageAuthor.user ? rdxUser.user.email : ''
              }
              funcAsk={funcAsk}
              isAskDisabled={isAskDisabled}
              key={`chat-message-${i}`}
              document_type={document.type as EDocumentType}
            />
          )
        })}
      </>
    )
  }, [aiChat.state.messages.value])

  const handleScroll = () => {
    if (chatContentRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = chatContentRef.current
      const isBottom = scrollTop + clientHeight >= scrollHeight
      setIsAtBottom(isBottom)
    }
  }

  useEffect(() => {
    setInputBlockHeightPx(`${inputBlockRef.current?.clientHeight ?? 136}px`)
  }, [document.id, inputBlockRef.current])

  useEffect(() => {
    if (isAtBottom) scrollToBottom(bottomRef, 0)
    if (isAtBottom) scrollToBottom(inputBlockRef, 0)
  }, [aiChat.state.messages, aiChat.state.response_message])

  return (
    <Box sx={{ height: heightPx, position: 'relative' }}>
      <Box
        sx={{
          background: '#fff',
          minHeight: chatBlockHeightPx,
          maxHeight: chatBlockHeightPx,
          overflowY: 'auto',
          scrollSnapType: 'y mandatory',
        }}
        onScroll={handleScroll}
        ref={chatContentRef}
      >
        {aiChat.state.messages.error ? (
          <ChatLoadHistoryError />
        ) : aiChat.state.id && !aiChat.state.messages.loading ? (
          <Box
            sx={{
              height: '100%',
              minHeight: chatBlockHeightPx,
            }}
            ref={bottomRef}
          >
            {chatContent}
            {aiChat.state.response_message.value ? (
              <ChatMessage
                msg={{
                  author: aiChat.state.response_message.value.author,
                  text: getRenderedMessageResponseTextWithQuestionTags(
                    aiChat.state.response_message.value.text
                  ),
                  status: aiChat.state.response_message.value.status,
                }}
                name={aiChat.state.response_message.value.author}
                funcAsk={funcAsk}
                isAskDisabled={isAskDisabled}
                document_type={document.type as EDocumentType}
              />
            ) : null}
          </Box>
        ) : (
          <ChatLoading />
        )}
      </Box>
      <Box
        sx={{
          p: '12px 16px 24px',
          width: '100%',
          position: 'absolute',
          bottom: '0',
          background: '#fff',
          zIndex: '1',
        }}
        ref={inputBlockRef}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <ChatTextField
              value={formik.values.messageText || ''}
              onChange={formik.handleChange}
              onKeyDown={(e) => {
                if (e.key == 'Enter') {
                  formik.handleSubmit(e)
                }
              }}
            />
            <ButtonPurple
              variant="contained"
              disabled={isAskDisabled}
              type="submit"
              sx={{
                position: 'absolute',
                bottom: '12px',
                right: '12px',
              }}
            >
              Спросить
            </ButtonPurple>
          </Box>
        </form>
      </Box>
    </Box>
  )
}

const ChatTextField = ({ ...props }) => {
  return (
    <TextField
      {...props}
      multiline
      fullWidth
      minRows={2}
      maxRows={12}
      placeholder="Введите свой вопрос..."
      name="messageText"
      sx={{
        p: 0,
      }}
      inputProps={{
        sx: {
          p: '12px 12px 4px',
        },
      }}
      InputProps={{
        sx: {
          p: '0 0 44px 0',
        },
      }}
    />
  )
}
