import { IApiKey } from 'entity/api-key/types'
import { LocalStorageReserve } from 'helper/consts'
import { handleError } from 'service'
import { api } from 'config'

export const postUsersTokens = async () => {
  try {
    return (
      await api.post<IApiKey>(
        `/users/tokens`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              LocalStorageReserve.token
            )}`,
          },
        }
      )
    ).data
  } catch (e) {
    return handleError(e)
  }
}
