import { DropZone } from 'components/input'

export const DropZoneBlock = ({ onDrop, onDropRejected, loading }) => (
  <>
    <DropZone
      onDrop={onDrop}
      onDropRejected={onDropRejected}
      accept={{
        'application/rtf': ['.rtf'],
        'application/pdf': ['.pdf'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          ['.docx'],
      }}
      maxSizeInBytes={5_000_000}
      description={
        'Формат: .pdf, .doc, .docx, .rtf, максимальный размер файла 5 МБ'
      }
      loading={loading}
    />
  </>
)

export const DropZoneBlockCreateDocument = ({
  onDrop,
  onDropRejected,
  loading,
  error,
}: {
  onDrop: (files: File[]) => void
  onDropRejected: (files: File[]) => void
  loading: boolean
  error?: string
}) => {
  return (
    <DropZone
      onDrop={onDrop}
      onDropRejected={onDropRejected}
      accept={{
        'application/rtf': ['.rtf'],
        'application/pdf': ['.pdf'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          ['.docx'],
      }}
      description={'Можно выбрать несколько файлов: pdf, doc, docx, rtf'}
      loading={loading}
      multiple={true}
      error={error}
    />
  )
}
