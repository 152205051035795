import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputBase,
  MenuItem,
  Box,
  Typography,
} from '@mui/material'
import { WSRolePermissions, WS_ROLES } from 'helper/roles'
import { CustomSelect } from 'pages/members'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { changeRole } from 'store/slices/teamspaceSlice'
import { InvitesTableWs } from '../invitee/inviteeTable'
import { DeleteMember } from './deleteMember'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { fetchWorkspaceMembers } from 'store/slices/workspace'

export const workspaceRoleOptions = {
  [WS_ROLES.owner]: {
    value: WS_ROLES.owner,
    label: 'Владелец пространства',
    desc: 'Может менять настройки пространства, управлять участниками, имеет доступ ко всем документам пространства',
  },
  [WS_ROLES.member]: {
    value: WS_ROLES.member,
    label: 'Участник пространства',
    desc: 'Имеет доступ только к группам в которые приглашен и к своей приватной группе',
  },
}

export const MembersTable = () => {
  const { members, groups } = useSelector(
    (state: RootState) => state.workspace,
    shallowEqual
  )
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const dispatch = useDispatch()

  const handleChange = async (event, row) => {
    await dispatch(
      changeRole({
        group_id: row.groups[0].id,
        user_id: row.user_id,
        to_group_id: groups.find((g) => g.name === event.target.value)?.id,
      }) as any
    )
    await dispatch(
      fetchWorkspaceMembers({ id: selectedWorkspace?.id || '' }) as any
    )
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Участник</TableCell>
            <TableCell>Роль</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((member) => (
            <TableRow key={member.user_id}>
              <TableCell>{member.email}</TableCell>
              <TableCell></TableCell>
              {selectedWorkspace &&
              WSRolePermissions[selectedWorkspace?.group_name].manageMembers ? (
                <TableCell>
                  <CustomSelect
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxWidth: '320px',
                          maxHeight: '100%',
                          marginTop: '2px',
                        },
                      },
                    }}
                    input={<InputBase />}
                    inputProps={{
                      sx: {
                        fontWeight: 500,
                        pr: '4px !important',
                      },
                    }}
                    value={member.groups[0].name}
                    onChange={(event) => handleChange(event, member)}
                    renderValue={(value: any) => {
                      return workspaceRoleOptions[value]
                        ? (workspaceRoleOptions[value].label as any)
                        : 'Удалить из группы'
                    }}
                    IconComponent={() => (
                      <KeyboardArrowDownIcon
                        sx={{ width: '16px', height: '16px' }}
                      />
                    )}
                  >
                    {groups.map((group) => (
                      <MenuItem value={group.name} key={group.name}>
                        <Box
                          display={'flex'}
                          alignItems="flex-start"
                          flexDirection={'column'}
                        >
                          <Typography variant="body1">
                            {workspaceRoleOptions[group.name].label}
                          </Typography>
                          <Typography
                            color={'text.secondary'}
                            whiteSpace="break-spaces"
                          >
                            {workspaceRoleOptions[group.name].desc}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                    <DeleteMember
                      user_id={member.user_id}
                      group_id={member.groups[0].id}
                    />
                  </CustomSelect>
                </TableCell>
              ) : (
                <TableCell></TableCell>
              )}
            </TableRow>
          ))}
          <InvitesTableWs />
        </TableBody>
      </Table>
    </TableContainer>
  )
}
