import { useFormikContext } from 'formik'
import { shallowEqual } from 'react-redux'
import { ActorSection } from './actorSection'
import { useAppSelector } from 'store/store'

export const FillerFormFields = () => {
  const { prefillFormVariables, actors } = useAppSelector(
    (state) => state.application,
    shallowEqual
  )

  const { handleSubmit } = useFormikContext()

  return (
    <form onSubmit={handleSubmit}>
      {prefillFormVariables &&
        Array.isArray(actors) &&
        Object.entries(prefillFormVariables).map(([key, variables]) => (
          <ActorSection
            key={key}
            actorId={key}
            variables={variables}
            actors={actors}
          />
        ))}
    </form>
  )
}
