import { Button, CircularProgress, Grid, TextField } from '@mui/material'
import { ChangeEventHandler, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

const isEmailFieldDisabled = (searchParams) => !!searchParams.get('email')

export const EmailInputForm = ({ formik, showCode, loadingEmail }) => {
  const [searchParams] = useSearchParams()

  const handleChangeEmail: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (e) => {
      const value = e.currentTarget.value
      const trimmed = value.trim()
      formik.setFieldValue('email', trimmed)
    },
    [formik]
  )

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid item xs={12}>
        <TextField
          defaultValue={formik.values.email}
          onChange={handleChangeEmail}
          name="email"
          placeholder="Введите свой почтовый адрес..."
          variant="outlined"
          fullWidth
          disabled={isEmailFieldDisabled(searchParams)}
          error={
            formik.errors['email'] && formik.touched['email'] ? true : false
          }
          value={formik.values.email}
        />
      </Grid>
      {!showCode && (
        <Grid item xs={12} mt="16px">
          <Button
            type="submit"
            fullWidth
            color="secondary"
            variant="outlined"
            disabled={loadingEmail ? true : false}
          >
            {loadingEmail ? (
              <CircularProgress
                thickness={5}
                color="inherit"
                sx={{ mr: '4px' }}
              />
            ) : null}
            Продолжить c email
          </Button>
        </Grid>
      )}
    </form>
  )
}
