import { Box, Typography, Collapse } from '@mui/material'
import { CustomTooltip } from 'components'
import { MenuNavigation } from 'layout/dashboard-layout/menuNavigation'
import { useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import private_svg from 'assets/img/private.svg'

export const CollapsablePrivateMenu = ({
  teamspace,
}: {
  teamspace: ITeamspace
}) => {
  const [collapsed, setCollapsed] = useState(false)

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed)
  }

  return (
    <Box p="8px 0" mt="8px">
      <Box
        p="4px 16px"
        display={'flex'}
        alignItems="center"
        onClick={handleToggleCollapse}
        sx={{ cursor: 'pointer' }}
      >
        <Typography variant="caption" color={'text.disabled'}>
          Приватное
        </Typography>
        {collapsed ? (
          <ArrowRightIcon
            color="disabled"
            sx={{ width: '16px', height: '16px' }}
          />
        ) : (
          <ArrowDropDownIcon
            color="disabled"
            sx={{ width: '16px', height: '16px' }}
          />
        )}

        <CustomTooltip title="Скрыть меню" placement="right-start">
          <Box display={'flex'}>
            <Box
              component={'img'}
              src={private_svg}
              width={'12px'}
              height="12px"
            />
          </Box>
        </CustomTooltip>
      </Box>
      <Collapse in={!collapsed}>
        <MenuNavigation isNested={false} teamspace={teamspace} />
      </Collapse>
    </Box>
  )
}
