import { MenuItemButton } from 'components/menu-item/menu-item-button'

// TODO: Убрать возвращение null если disabled
export const ActionButtons = ({ items }: { items: any[] }) => {
  return items.map((item) => {
    if (!item || item.disabled || item.hidden) return null
    return item?.title ? (
      <ActionButton item={item} key={`${item.title}`} />
    ) : (
      item
    )
  })
}

export const ActionButton = ({ item }) => {
  const handleClick = (e) => {
    e.preventDefault()
    item.action()
  }

  return (
    <MenuItemButton
      srcImage={item.src}
      title={item.title}
      onClick={handleClick}
      disabled={item.disabled}
    />
  )
}
