import { Box, Checkbox, styled } from '@mui/material'
import checkboxIcon from 'assets/img/checkbox/checkbox.svg'
import checkboxIconInter from 'assets/img/checkbox/checkbox-inter.svg'
import checkboxDisabled from 'assets/img/checkbox/checkbox-disabled.svg'
import checkboxDefault from 'assets/img/checkbox/checkbox-default.svg'

export const BpIcon = styled('span')(() => ({
  display: 'none',
}))

export const TableCheckbox = (props) => {
  return (
    <Checkbox
      indeterminateIcon={
        <Box component={'img'} src={checkboxIconInter} width={'100%'} alt="" />
      }
      checkedIcon={
        <Box component={'img'} src={checkboxIcon} width={'100%'} alt="" />
      }
      icon={
        props.disabled ? (
          <Box component={'img'} src={checkboxDisabled} width={'100%'} alt="" />
        ) : (
          <Box component={'img'} src={checkboxDefault} width={'100%'} alt="" />
        )
      }
      {...props}
      sx={{
        margin: { xs: '4px', sm: '0' },
        width: {
          xs: '20px',
          sm: '16px',
        },
        height: {
          xs: '20px',
          sm: '16px',
        },
      }}
    />
  )
}
