import { Typography, Box } from '@mui/material'
import { EWorkspacePlan } from 'helper/plan.types'
import { shallowEqual, useSelector } from 'react-redux'
import { WhiteBoxProgress } from '../WhiteBoxProgress'
import { useWorkspacePlanLimits } from 'pages/payment/hooks/useWorkspacePlanLimits'

export const SubscriptionPlanLimits = () => {
  const { workspacePlanLimits } = useWorkspacePlanLimits()

  const { plan_name } = useSelector(
    (state: RootState) => state.wsPlan,
    shallowEqual
  )

  if (plan_name == EWorkspacePlan.Free) return null

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <Typography variant="heading6">Лимиты</Typography>
      {workspacePlanLimits.map((item, i) =>
        !item || item.to == -1 || item.to == 0 ? null : (
          <WhiteBoxProgress
            key={`whitebox-progress-${i}`}
            title={item.title}
            from={item.from}
            to={item.to}
            limitMsg={item.limitMsg}
          />
        )
      )}
    </Box>
  )
}
