import { Box, Typography, FormControlLabel } from '@mui/material'
import { useField } from 'formik'
import { IOSSwitch } from '.'

export const Switcher = ({
  label = '',
  name = '',
  onChange,
  disabled,
  ...props
}: {
  label?: string
  name?: string
  onChange?: (name: string, value: boolean) => void
  disabled?: boolean
}) => {
  const [field] = useField({ name, ...props })

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked

    field.onChange(event)
    if (onChange) {
      onChange(name, newValue)
    }
  }

  return (
    <Box display={'flex'} justifyContent={'space-between'}>
      <Typography variant="h6" mb="2px" fontWeight={400}>
        {label}
      </Typography>
      <FormControlLabel
        {...field}
        sx={{ ml: 0, mr: 0 }}
        control={
          <IOSSwitch
            name={field.name}
            checked={field.value}
            onChange={handleSwitchChange}
            onBlur={field.onBlur}
            disabled={disabled}
          />
        }
        label={''}
      />
    </Box>
  )
}
