import { ColumnsListItemNode } from "features/text-editors/lexical/custom/nodes/ColumnsListNode/ColumnsListItemNode"
import { TextNode } from "lexical"

const excludedToDrag: { [key: string]: boolean } = {
  [ColumnsListItemNode.getType()]: true,
  [TextNode.getType()]: true,
  // TODO: NumericListItemNode, BulletListItemNode
}

/**
 * isExcludedToDrag - using for determining whether the can node be dragged.
 * @param nodeType - node.getType()
 * @returns `true` if node can be dragged, otherwise `false`
 */
export function isExcludedToDrag(nodeType: string) {
  return !!excludedToDrag[nodeType]
}
