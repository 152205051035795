import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { EDocumentStatus } from 'helper/consts'
import { getCampaign, getRegistry } from 'service'

const initialState: ICamapignDocsTableState = {
  page: 1,
  id: '',
  filter: 'all',
  documents: [],
  count: 0,
  loading: {
    info: true,
    registry: true,
  },
  error: {
    info: '',
    registry: '',
  },
  name: '',
  status: '',
  count_all: 0,
  stats: {
    preparing: 0,
    ready_to_send: 0,
    'ready to sign': 0,
    waiting_user: 0,
    waiting_signers: 0,
    signed: 0,
    revoked: 0,
  },
}

// Async thunks
export const updateCampaignInfo = createAsyncThunk(
  'campaignDocs/updateCampaignInfo',
  async ({ id }: { id: string }) => {
    try {
      const response = await getCampaign({ id })
      return response.data
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      }
    }
  }
)

export const updateCampaignDocuments = createAsyncThunk(
  'campaignDocs/updateCampaignDocuments',
  async ({
    filter,
    page,
    campaignId,
    teamspaceId,
    onlyOwnDocuments,
  }: {
    filter: EDocumentStatus | string
    page: number
    teamspaceId: string
    campaignId: string
    onlyOwnDocuments: boolean
  }) => {
    try {
      const res = await getRegistry({
        page,
        filter,
        campaignId,
        teamspaceId,
        onlyOwnDocuments,
      })
      const { documents, count } = res.data.list

      return { documents, count, filter, page }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      }
    }
  }
)

const campaignDocumentsSlice = createSlice({
  name: 'campaignDocs',
  initialState,
  reducers: {
    clearErrors(state) {
      state.error.info = ''
      state.error.registry = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCampaignInfo.pending, (state) => {
        state.loading.info = true
      })
      .addCase(updateCampaignInfo.fulfilled, (state, action) => {
        state.name = action.payload?.name || ''
        state.stats = action.payload?.stats
        state.status = action.payload?.status || ''
        state.loading.info = false
      })
      .addCase(updateCampaignInfo.rejected, (state, action) => {
        state.error.info = action.error.message || 'Ошибка'
        state.loading.info = false
      })
      .addCase(updateCampaignDocuments.pending, (state) => {
        state.loading.registry = true
      })
      .addCase(updateCampaignDocuments.fulfilled, (state, action) => {
        state.documents = action.payload?.documents
        state.count = action.payload?.count
        state.filter = action.payload?.filter || EDocumentStatus.ALL
        state.page = action.payload?.page || 1
        state.loading.registry = false
      })
      .addCase(updateCampaignDocuments.rejected, (state, action) => {
        state.error.registry = action.error.message || 'Ошибка'
        state.loading.registry = false
      })
  },
})

export const { clearErrors } = campaignDocumentsSlice.actions
export default campaignDocumentsSlice.reducer
