export const FloatingMenuButtonsGroup = ({ children }: { children: any }) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: '2px',
      }}
    >
      {children}
    </div>
  )
}
