import { Typography, Link, Box, Button } from '@mui/material'
import { IIntegrationCard } from '../../types'

export const IntegrationCard = ({
  icon,
  title,
  description,
  isAvailable,
  onClickCallback,
}: IIntegrationCard) => {
  return (
    <Box
      minWidth={'245px'}
      flex={1}
      p={'16px 12px'}
      sx={{
        borderRadius: '4px',
        border: '1px solid #EDEDEC',
        boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.04)',
      }}
    >
      <Box p={'4px 0'}>
        <Box component={'img'} src={icon} width={'auto'} height={'24px'} />
      </Box>
      <Box p={'8px 0'}>
        <Typography color={'text.primary'}>{title}</Typography>
        <Typography mt={'2px'} color={'#90959D'}>
          {description}
        </Typography>
      </Box>
      <Box>
        {isAvailable ? (
          <Link
            component={Button}
            onClick={() => onClickCallback && onClickCallback()}
            sx={{
              p: '0 !important',
              textDecoration: 'none',
              ':hover': {
                background: 'none',
              },
            }}
          >
            Подключить
          </Link>
        ) : (
          <Typography color={'#90959D'}>Скоро</Typography>
        )}
      </Box>
    </Box>
  )
}
