import { AnalyticsLayout, LoadWorkspacePlanLayout } from 'layout'
import {
  PageAPI,
  AcceptInvite,
  CampaignDocuments,
  Campaigns,
  CreateWorkspace,
  DocumentEditor,
  Error404,
  Inbox,
  Login,
  LoginOtp,
  ManageTemplatePage,
  PageSetup,
  PageSetupOnboarding,
  Payment,
  Plan,
  Preview,
  Profile,
  Registry,
  TSMembers,
  TSSettings,
  TemplateEditorPage,
  Templates,
  WSMembers,
  WSSettings,
} from 'pages'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import PrivateRoute from './privateRoute'
import TeamspaceRoute from './teamspaceRoute'

export default function AppRoutes() {
  const { t } = useTranslation()

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <AnalyticsLayout>
              <LoadWorkspacePlanLayout>
                <PrivateRoute />
              </LoadWorkspacePlanLayout>
            </AnalyticsLayout>
          }
        >
          <Route path="/" element={<TeamspaceRoute />} />
          <Route path="/workspace/new" element={<CreateWorkspace />} />

          <Route path="/settings" element={<WSSettings />} />
          <Route path="/inbox" element={<Inbox />} />
          <Route path="/members" element={<WSMembers />} />
          <Route path="/teamspace/:tsId/documents" element={<Registry />} />
          <Route path="/teamspace/:tsId/members" element={<TSMembers />} />
          <Route path="/teamspace/:tsId/settings" element={<TSSettings />} />
          {/* TODO: add layout to preview header */}
          <Route path="/api" element={<PageAPI />} />
          <Route
            path="/teamspace/:tsId/documents/:documentId"
            element={<Preview />}
          />
          <Route path="/teamspace/:tsId/templates" element={<Templates />} />
          <Route
            path="/teamspace/:tsId/templates/:templateId/manage"
            element={<ManageTemplatePage />}
          />
          <Route
            path="/teamspace/:tsId/templates/:templateId"
            element={<TemplateEditorPage />}
          />
          <Route path="/teamspace/:tsId/campaigns" element={<Campaigns />} />
          <Route
            path="/teamspace/:tsId/campaigns/:id"
            element={<CampaignDocuments />}
          />
          <Route
            path="/teamspace/:tsId/document-editor/:documentId"
            element={<DocumentEditor />}
          />
          <Route path="/plan" element={<Plan />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route path="/setup" element={<PageSetup />} />
        <Route path="/setup/onboarding" element={<PageSetupOnboarding />} />
        <Route
          path="/login"
          element={
            <AnalyticsLayout>
              <Login />
            </AnalyticsLayout>
          }
        />
        <Route
          path="/login/otp"
          element={
            <AnalyticsLayout>
              <LoginOtp />
            </AnalyticsLayout>
          }
        />
        <Route
          path="/invites/:inviteId"
          element={
            <AnalyticsLayout>
              <AcceptInvite />
            </AnalyticsLayout>
          }
        />
        <Route
          path="404"
          element={
            <AnalyticsLayout>
              <Error404 message={t('Ошибка 404')} description={t('')} />
            </AnalyticsLayout>
          }
        />
        <Route path="*" element={<Navigate to={`/`} />} />
      </Routes>
    </BrowserRouter>
  )
}
