import { Box, TextField } from '@mui/material'
import { WorfklowBuilderUIContext } from 'features/workflow-builder/providers/WorkflowBuilderUIContext'
import { useContext } from 'react'

export const BlockMessage = () => {
  const workflowBuilderUI = useContext(WorfklowBuilderUIContext)

  const getMessage = () => {
    return workflowBuilderUI.workflow.meta?.message ?? ''
  }

  return (
    <Box mt={'12px'}>
      <TextField
        rows={14}
        fullWidth
        multiline
        InputProps={{
          disableUnderline: true,
          sx: {
            p: '1px',
          },
        }}
        placeholder="Текст письма..."
        variant="standard"
        defaultValue={getMessage()}
        onChange={(e) => {
          const text = e.target.value

          workflowBuilderUI.workflow.meta = {
            ...workflowBuilderUI.workflow.meta,
            message: !!text ? text : undefined,
          }
        }}
      />
    </Box>
  )
}
