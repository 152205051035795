import { Box, Typography } from '@mui/material'
import { CardPaymentCardChange } from 'features/change-payment-requisits/components/CardPaymentCardChange'
import { CardPaymentRequisitChange } from 'features/change-payment-requisits/components/CardPaymentRequisitChange'
import { shallowEqual } from 'react-redux'
import { useAppSelector } from 'store/store'

export const PaymentRequisites = () => {
  const { requisites, card } = useAppSelector(
    (state) => state.wsPlan,
    shallowEqual
  )

  if (!requisites && !card) return null
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <Typography variant="heading6">Информация для оплаты</Typography>
      <CardPaymentRequisitChange />
      <CardPaymentCardChange />
    </Box>
  )
}
