import { Box, Button } from '@mui/material'

export const DeselectPluginCalendar = ({
  deselectDates,
  text = 'Очистить',
  position = 'bottom',
}: {
  deselectDates: any
  text?: string
  position?: string
}) => {
  return (
    <Box p={'6px'}>
      <Button
        color="secondary"
        fullWidth
        onClick={deselectDates}
        sx={{
          justifyContent: 'start',
          fontWeight: 400,
          p: '4px 12px',
          color: 'text.primary',
        }}
      >
        {text}
      </Button>
    </Box>
  )
}
