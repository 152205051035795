import { ERegistryType } from 'components/breadcrumbs/BreadcrumbsBlock'
import { HeaderWithBreadcrumbs } from 'components/header/HeaderWithBreadcrumbs'
import { AppBarCommon } from 'components/header/app-bar-common'
import { shallowEqual, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

export const ManageTemplateHeader = () => {
  const { tsId } = useParams()
  const template = useSelector(
    (state: RootState) => state.template,
    shallowEqual
  )

  return (
    <AppBarCommon
      headerTitle={
        <HeaderWithBreadcrumbs
          breadcrumb={{
            registry: {
              value: ERegistryType.Templates,
              linkTo: `/teamspace/${tsId}/templates`,
            },
            document: {
              name: template.name,
              status: template.status,
            },
          }}
        />
      }
    ></AppBarCommon>
  )
}
