import { useFormikContext } from 'formik'
import { FormikDatePicker } from '../datePicker'
import { DeselectPluginCalendar } from '../plugins/deselectPluginCalendar'
import dayjs from 'dayjs'
import { formatDefaultDate } from 'utils/formatDate'
// import { SelectPluginCalendar } from '../plugins/selectPluginCalendar'

export const RangeDatePicker = ({ name }: { name: string }) => {
  const { values, setFieldValue } = useFormikContext<{ date_range: any[] }>()

  const handleDateChange = (dates: string[] | Date[]) => {
    setFieldValue(
      name,
      dates.map((date) => formatDefaultDate(date))
    )
  }

  const deselectDates = () => {
    setFieldValue(name, [])
  }

  return (
    <FormikDatePicker
      dateSeparator=" - "
      name={name}
      value={values[name].map((date) => dayjs(date).toDate())}
      onChange={handleDateChange}
      format={values['format']}
      range
      plugins={[
        <DeselectPluginCalendar
          position="bottom"
          deselectDates={deselectDates}
        />,
        // <SelectPluginCalendar position="bottom" selectDates={deselectDates} />,
      ]}
    />
  )
}
