import { createAsyncThunk } from "@reduxjs/toolkit"
import { workspaceApi } from "service/workspaces"
import { wsPlanGetPlan } from "../wsPlanGetPlan"

export const wsPlanStartTrial = createAsyncThunk(
  'wsPlan/switchPlan',
  async ({ wsId, handleClose }: {
    wsId: string,
    handleClose: () => void
  }, { rejectWithValue, dispatch }) => {
    try {
      await workspaceApi.startTrialPlan({ wsId })
      dispatch(wsPlanGetPlan({ wsId }))
      handleClose()
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)
