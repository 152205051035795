import { ERecipientRole } from 'helper/consts'
import { IServiceWorkflow } from 'helper/workflow.service.types'

export const getServiceWorkflowForOnlyMeSign = (email: string) => {
  const workflow: IServiceWorkflow = {
    steps: [
      {
        index: 1,
        recipients: [
          {
            role: ERecipientRole.signer_rk,
            attrs: {
              email: email,
            },
          },
        ],
      },
    ],
  }
  return { workflow }
}
