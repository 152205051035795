import img_sheets from 'assets/img/integrations/Logo=Sheets.svg'

import { EIntegrationType, IIntegrationGoogleSheets } from '../../../types'
import { ActiveIntegrationRowBase } from '../ActiveIntegrationRowBase'
import { IActiveIntegrationRowProps } from '../types'

export interface IActiveIntegrationRowGoogleSheetsProps
  extends IActiveIntegrationRowProps {
  integration: IIntegrationGoogleSheets
}

export const isIActiveIntegrationGoogleSheetsProps = (
  prop: IActiveIntegrationRowProps
): prop is IActiveIntegrationRowGoogleSheetsProps => {
  return prop.integration.integration_type == EIntegrationType.google_sheets
}

export const ActiveIntegrationRowGoogleSheets = ({
  integration,
  onDeleteCallback,
  loading,
}: IActiveIntegrationRowGoogleSheetsProps) => {
  return (
    <ActiveIntegrationRowBase
      integrationId={integration.id}
      icon={img_sheets}
      title="Google Таблицы"
      description="Данные из заполненных документов синхронизируются с этой таблицей"
      link={`https://docs.google.com/spreadsheets/d/${integration.attrs.spreadsheet_id}`}
      isEnabled={integration.enabled}
      onDeleteCallback={onDeleteCallback}
      loading={loading}
    />
  )
}
