import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Box,
  Button,
  InputBase,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material'
import { Loading, MessageSnackbar } from 'components'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { TSRolePermissions } from 'helper/roles'
import { withDashboardLayout } from 'layout'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  changeRole,
  clearNotificationsTs,
  deleteTeamspaceInvite,
  deleteTeamspaceMember,
  fetchGroups,
  fetchTeamspaceInvites,
  fetchTeamspacesMembers,
} from 'store/slices/teamspaceSlice'
import { TSMembersHeader } from './ts-members/header'

export const DeleteMember = ({ user_id, group_id }) => {
  const [open, setOpen] = useState(false)
  const { tsId } = useParams()

  const { loading, notification } = useSelector(
    (state: RootState) => state.teamspace
  )
  const handleClose = () => setOpen(false)

  const handleClick = () => {
    setOpen(true)
  }

  const dispatch = useDispatch()

  const handleDelete = async () => {
    await dispatch(deleteTeamspaceMember({ user_id, group_id, tsId }) as any)
  }

  return (
    <>
      <MessageSnackbar
        message={notification.delete_member?.message}
        clearMessage={() => dispatch(clearNotificationsTs() as any)}
        severity={'error'}
      />
      <ModalCenterSelectableWithTitle
        open={open}
        handleClose={handleClose}
        title={'Вы уверены, что хотите удалить участника?'}
        modalType="small"
        contentChild={
          <Typography variant="body1">
            Участник потеряет доступ ко всем документам в этой группе
          </Typography>
        }
        footerChild={
          <Box justifyContent="flex-end" display={'flex'}>
            <Button
              sx={{ mr: '8px' }}
              color="secondary"
              onClick={() => setOpen(false)}
              variant="outlined"
            >
              Отменить
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={handleDelete}
              disabled={loading.delete_member ? true : false}
            >
              Удалить
            </Button>
          </Box>
        }
      />
      <MenuItem
        value={'Удалить из группы'}
        key={'Удалить из группы'}
        onClick={handleClick}
      >
        <Typography color={'#E92C2C'}>Удалить из группы</Typography>
      </MenuItem>
    </>
  )
}

export const DeleteInvite = ({ id }) => {
  const [open, setOpen] = useState(false)
  const { tsId } = useParams()

  const { loading, notification } = useSelector(
    (state: RootState) => state.teamspace
  )
  const handleClose = () => setOpen(false)

  const handleClick = () => {
    setOpen(true)
  }

  const dispatch = useDispatch()

  const handleDelete = async () => {
    await dispatch(deleteTeamspaceInvite({ id, tsId }) as any)
  }

  return (
    <>
      <MessageSnackbar
        message={notification.delete_member?.message}
        clearMessage={() => dispatch(clearNotificationsTs() as any)}
        severity={'error'}
      />
      <ModalCenterSelectableWithTitle
        open={open}
        handleClose={handleClose}
        title={'Вы уверены, что хотите удалить приглашение?'}
        modalType="small"
        contentChild={
          <Typography variant="body1">
            Участник потеряет доступ ко всем документам в этой группе
          </Typography>
        }
        footerChild={
          <Box justifyContent="flex-end" display={'flex'}>
            <Button
              sx={{ mr: '8px' }}
              color="secondary"
              onClick={() => setOpen(false)}
              variant="outlined"
            >
              Отменить
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={handleDelete}
              disabled={loading.delete_member ? true : false}
            >
              Удалить
            </Button>
          </Box>
        }
      />

      <MenuItem
        value={'Удалить приглашение'}
        key={'Удалить приглашение'}
        onClick={handleClick}
      >
        <Typography color={'#E92C2C'}>Удалить приглашение</Typography>
      </MenuItem>
    </>
  )
}

const TSMembersContent = () => {
  const { loading, notification } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )
  const { tsId } = useParams()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTeamspacesMembers({ id: tsId }) as any)
    dispatch(fetchGroups({ id: tsId }) as any)
    dispatch(fetchTeamspaceInvites({ id: tsId }) as any)
  }, [tsId])

  return (
    <>
      <MessageSnackbar
        message={notification.change_role?.message}
        clearMessage={() => dispatch(clearNotificationsTs() as any)}
        severity={notification.change_role?.severity}
      />
      <MessageSnackbar
        message={notification.delete_member?.message}
        clearMessage={() => dispatch(clearNotificationsTs() as any)}
        severity={notification.delete_member?.severity}
      />
      <MessageSnackbar
        message={notification.invites?.message}
        clearMessage={() => dispatch(clearNotificationsTs() as any)}
        severity={notification.invites?.severity}
      />
      <MessageSnackbar
        message={notification.members?.message}
        clearMessage={() => dispatch(clearNotificationsTs() as any)}
        severity={notification.members?.severity}
      />

      <Box p={{ xs: '16px', sm: '16px 40px' }} overflow={'auto'}>
        <Box>
          <Box>
            {loading.members || loading.groups || loading.invites ? (
              <Loading />
            ) : (
              <TSMembersList />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export const TSMembers = () =>
  withDashboardLayout(TSMembersContent)({ Header: TSMembersHeader })

const TS_ROLES = {
  owner: 'ts-owner',
  member: 'ts-member',
}

const roles = {
  [TS_ROLES.owner]: {
    value: TS_ROLES.owner,
    label: 'Владелец группы',
    desc: 'Может менять настройки, управлять участниками, имеет доступ ко всем документам этой группы',
  },
  [TS_ROLES.member]: {
    value: TS_ROLES.member,
    label: 'Участник группы',
    desc: 'Может приглашать новых участников, имеет доступ ко всем документам этой группы',
  },
}

export const generateAvailableListTs = (group_name) => {
  const availableList: any = []

  TSRolePermissions[group_name].canManageMemberGroups.forEach((group) => {
    const item = roles[group]
    item && availableList.push(item)
  })

  return availableList
}

const TSMembersList = () => {
  const { members, groups, invites, selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )

  const { tsId } = useParams()
  const dispatch = useDispatch()

  const [availableList, setAvailableList] = useState<any>([])

  useEffect(() => {
    selectedTeamspace &&
      setAvailableList(generateAvailableListTs(selectedTeamspace?.group_name))
  }, [selectedTeamspace])

  const handleChange = async (event, row) => {
    await dispatch(
      changeRole({
        group_id: row.groups[0].id,
        user_id: row.user_id,
        to_group_id: groups.find((g) => g.name === event.target.value)?.id,
      }) as any
    )
    dispatch(fetchTeamspacesMembers({ id: tsId }) as any)
    dispatch(fetchTeamspaceInvites({ id: tsId }) as any)
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Участник</TableCell>
            <TableCell>Роль</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((member) => (
            <TableRow key={member.user_id}>
              <TableCell>{member.email}</TableCell>
              <TableCell></TableCell>
              <TableCell>
                {selectedTeamspace &&
                TSRolePermissions[selectedTeamspace.group_name]
                  .canManageMemberGroups.length > 0 ? (
                  <CustomSelect
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxWidth: '320px',
                          maxHeight: '100%',
                          marginTop: '2px',
                        },
                      },
                    }}
                    input={<InputBase />}
                    inputProps={{
                      sx: {
                        fontWeight: 500,
                        pr: '4px !important',
                      },
                    }}
                    value={member.groups[0].name}
                    onChange={(event) => handleChange(event, member)}
                    renderValue={(value: any) => {
                      return roles[value]
                        ? (roles[value].label as any)
                        : 'Удалить из группы'
                    }}
                    IconComponent={() => (
                      <KeyboardArrowDownIcon
                        sx={{ width: '16px', height: '16px' }}
                      />
                    )}
                  >
                    {TSRolePermissions[
                      selectedTeamspace.group_name
                    ].canManageMemberGroups.find(
                      (role) => role === member.groups[0].name
                    ) &&
                      availableList &&
                      availableList.map((group) => {
                        const canChange = TSRolePermissions[
                          selectedTeamspace.group_name
                        ].canManageMemberGroups.find(
                          (role) => role === group.value
                        )
                        if (!canChange) return
                        return (
                          <MenuItem value={group.value} key={group.value}>
                            <Box
                              display={'flex'}
                              alignItems="flex-start"
                              flexDirection={'column'}
                            >
                              <Typography variant="body1">
                                {roles[group.value].label}
                              </Typography>
                              <Typography
                                color={'text.secondary'}
                                whiteSpace="break-spaces"
                              >
                                {roles[group.value].desc}
                              </Typography>
                            </Box>
                          </MenuItem>
                        )
                      })}
                    <DeleteMember
                      user_id={member.user_id}
                      group_id={member.groups[0].id}
                    />
                  </CustomSelect>
                ) : (
                  <Typography fontWeight={500}>
                    {roles[member.groups[0].name].label}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          ))}
          {selectedTeamspace &&
            TSRolePermissions[selectedTeamspace.group_name]
              .canManageMemberGroups.length > 0 &&
            invites &&
            invites.map((member) => (
              <TableRow key={member.id}>
                <TableCell>{member.email}</TableCell>
                <TableCell>
                  <Box
                    p={'0 4px'}
                    border={'1px solid #EDEDEC'}
                    width="fit-content"
                    borderRadius={'4px'}
                  >
                    Приглашен
                  </Box>
                </TableCell>
                <TableCell>
                  <CustomSelect
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxWidth: '320px',
                          maxHeight: '100%',
                          marginTop: '2px',
                        },
                      },
                    }}
                    input={<InputBase />}
                    inputProps={{
                      sx: {
                        fontWeight: 500,
                        pr: '4px !important',
                      },
                    }}
                    value={member.group_name}
                    onChange={(event) => handleChange(event, member)}
                    renderValue={(value: any) => {
                      return roles[value]
                        ? (roles[value].label as any)
                        : 'Удалить из группы'
                    }}
                    IconComponent={() => (
                      <KeyboardArrowDownIcon
                        sx={{ width: '16px', height: '16px' }}
                      />
                    )}
                  >
                    <DeleteInvite id={member.id} />
                  </CustomSelect>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const CustomSelect = styled(Select)(() => ({
  '& .MuiOutlinedInput-root': {
    border: 'none',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  '& .MuiMenuItem-root': {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
}))
