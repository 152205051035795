import { Box, Button } from '@mui/material'
import plus_icon from 'assets/img/plus_white.svg'
import { ERegistryType } from 'components/breadcrumbs/BreadcrumbsBlock'
import { HeaderWithBreadcrumbs } from 'components/header/HeaderWithBreadcrumbs'
import { AppBarCommon } from 'components/header/app-bar-common'
import { AnalyticsEvent } from 'config/analytics/segment.com/events'
import { useSearchBarContext, useSelectedDocsContext } from 'context'
import { useModal } from 'features/load-doc-or-template/hooks/useModal'
import { ModalCreateDocument } from 'features/load-doc-or-template/modals/ModalCreateDocument'
import { SearchBar } from 'features/search-bar'
import { EDocumentStatus } from 'helper/consts'
import { TSRolePermissions } from 'helper/roles'
import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { shallowEqual } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { useOnboardingCreateDoc } from '../../hooks/useOnboardingCreateDoc'
import { DocumentsToolbarTabs } from './DocumentsToolbarTabs'
import { MassActions } from '../mass-actions'
import { EListQueryParam } from 'helper/queryParams'
import { useAppSelector } from 'store/store'

export const ToolbarDocs = () => {
  const { tsId } = useParams()
  const [searchParams] = useSearchParams()
  const { authorQuery, statusQuery, queryString } = useSearchBarContext()

  const filter =
    (searchParams.get(EListQueryParam.Filter) as EDocumentStatus) ||
    EDocumentStatus.ALL

  const { selectedDocsIds } = useSelectedDocsContext()

  const { count, loading } = useAppSelector(
    (state) => state.documents,
    shallowEqual
  )

  const { selectedTeamspace } = useAppSelector(
    (state) => state.teamspace,
    shallowEqual
  )
  const { onboarding } = useAppSelector((state) => state.user, shallowEqual)
  const { selectedWorkspace } = useAppSelector(
    (state) => state.selectedWorkspace,
    shallowEqual
  )
  const { plan_info, workspace_id } = useAppSelector(
    (state) => state.wsPlan,
    shallowEqual
  )
  const btnRef = useRef(null)

  const [withPulse, setWithPulse] = useState(false)
  const { driverObj } = useOnboardingCreateDoc({ btnRef, setWithPulse })

  useEffect(() => {
    // ? Check Is User Activated Trial
    if (plan_info.is_trial_available || workspace_id != selectedWorkspace?.id)
      return
    if (onboarding.document_create?.finished || onboarding.skip) return
    if (driverObj) {
      setTimeout(() => {
        driverObj.drive()
        setWithPulse(driverObj.isFirstStep())
      }, 500)
    }
    return () => {
      driverObj.destroy()
    }
  }, [driverObj, onboarding.document_create, btnRef, plan_info])

  const { openModal, handleClose, handleClick } = useModal(() =>
    AnalyticsEvent.new_doc_start('no')
  )

  const handleLoadDocClick = (e) => {
    handleClick(e)
    setWithPulse(false)
    setTimeout(() => {
      driverObj.moveNext()
    }, 200)
  }

  return (
    <>
      <ModalCreateDocument
        open={openModal}
        handleClose={handleClose}
        tsId={tsId}
        driverObj={driverObj}
      />
      <AppBarCommon
        headerTitle={
          <HeaderWithBreadcrumbs
            breadcrumb={{
              registry: { value: ERegistryType.Documents },
            }}
            rightChildren={
              selectedTeamspace &&
              TSRolePermissions[selectedTeamspace?.group_name]
                .canManageDocs && (
                <Box>
                  <Button
                    ref={btnRef}
                    id="load-doc-item"
                    variant="contained"
                    color="primary"
                    onClick={handleLoadDocClick}
                    startIcon={
                      !isMobile && (
                        <Box
                          component={'img'}
                          src={plus_icon}
                          width={'20px'}
                          height={'20px'}
                        />
                      )
                    }
                    sx={{
                      minWidth: { xs: '40px' },
                      p: isMobile ? '9px !important' : '',
                    }}
                  >
                    {withPulse && <span className="onboarding__pulse"></span>}
                    {isMobile ? '' : 'Новый документ'}
                    {isMobile && (
                      <Box
                        component={'img'}
                        src={plus_icon}
                        width={'20px'}
                        height={'20px'}
                      />
                    )}
                  </Button>
                </Box>
              )
            }
          />
        }
        secondaryBlock={
          <>
            <DocumentsToolbarTabs />

            <Box padding={'12px 0'}>
              {selectedDocsIds.size > 0 ? (
                <MassActions filter={filter} />
              ) : (
                (count > 0 ||
                  loading ||
                  authorQuery ||
                  statusQuery ||
                  queryString) && (
                  <SearchBar
                    filter={filter}
                    showStatusFilter={filter === EDocumentStatus.ALL}
                  />
                )
              )}
            </Box>
          </>
        }
      ></AppBarCommon>
    </>
  )
}
