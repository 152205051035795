import { $isColumnsListNode } from "features/text-editors/lexical/custom/nodes/ColumnsListNode"
import { $isColumnsListItemNode, ColumnsListItemNode } from "features/text-editors/lexical/custom/nodes/ColumnsListNode/ColumnsListItemNode"
import { DELETE_COLUMNS_LIST_ITEM_COMMAND } from "features/text-editors/lexical/custom/plugins/ColumnsListPlugin/commands"
import { LexicalEditor, LexicalNode } from "lexical"

const droppingFromParent: {
  [key: string]: (editor: LexicalEditor, parentNode: LexicalNode) => void
} = {
  [ColumnsListItemNode.getType()]: (editor, parentNode) => {
    if (!$isColumnsListItemNode(parentNode)) return

    const childNodes = parentNode.getChildren()
    if (childNodes.length > 0) return

    const columnsListNode: LexicalNode | null = parentNode.getParent()
    if (!columnsListNode || !$isColumnsListNode(columnsListNode)) return
    editor.dispatchCommand(DELETE_COLUMNS_LIST_ITEM_COMMAND, {
      key: parentNode.getKey(),
      unwrapSingleColumnsListItem: true,
    })
  }
}

/**
 * handleDroppingFromParent - using when dropping element, to handle correct dropping from previous `parentNode`
 * @param editor 
 * @param parentNode 
 * @returns 
 */
export function handleDroppingFromParent(
  editor: LexicalEditor,
  parentNode: LexicalNode,
) {
  const onDropFromParent = droppingFromParent[parentNode.getType()]
  if (!onDropFromParent) return
  onDropFromParent(editor, parentNode)
}
