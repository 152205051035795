import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  changeMemberRole,
  changeTeamspaceAttrs,
  changeTeamspaceInfo,
  crateTeamspace,
  deleteInvitee,
  deleteMember,
  deleteTeamspace,
  getTeamspaceGroups,
  getTeamspaceInfo,
  getTeamspaceInvites,
  getTeamspaceMembers,
  leaveTeamspace,
  getWorkspaceTeamspaces,
} from 'service/teampspaces'

const initialState: ITeamspaceState = {
  loading: {
    teamspaces: false,
    create: false,
    members: false,
    groups: false,
    invites: false,
    delete_member: false,
    change_role: false,
    update_ts: false,
    delete_ts: false,
    leave_ts: false,
  },
  error: {
    teamspaces: null,
    create: null,
    members: null,
    groups: null,
    invites: null,
    delete_member: null,
    change_role: null,
    update_ts: null,
    delete_ts: null,
    leave_ts: null,
  },
  teamspaces: [],
  members: [],
  groups: [],
  invites: [],
  selectedTeamspace: null,
  notification: {
    create: null,
    teamspaces: null,
    members: null,
    groups: null,
    invites: null,
    delete_member: null,
    change_role: null,
    update_ts: null,
    delete_ts: null,
    leave_ts: null,
    create_invite: { message: '', severity: 'success' },
  },
}

export const fetchTeamspaces = createAsyncThunk(
  'teamspaces/fetchTeamspaces',
  async ({ id }: { id: string }) => {
    const response = await getWorkspaceTeamspaces({ id })
    return response.data.teamspaces.length ? response.data.teamspaces : null
  }
)

export const createNewTeamspace = createAsyncThunk(
  'teamspaces/createNewTeamspace',
  async (
    {
      id,
      name,
      color,
      setStep,
    }: { id: string | undefined; name: string; color: any; setStep: any },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await crateTeamspace({
        id,
        body: {
          teamspace: {
            name,
            attrs: {
              color: {
                hex: color,
              },
            },
          },
        },
      })

      const { teamspace_id } = response.data

      const res = await getTeamspaceInfo({ id: teamspace_id })
      const { teamspace } = res.data

      dispatch(selectTeamspace(teamspace)) // Assuming selectTeamspace is your action to update the state
      setStep(1)

      return response.data
    } catch (error: any) {
      return rejectWithValue({
        message: createTsError[error.message] || error.message,
        notification: {
          message: createTsError[error.message] || error.message || 'Ошибка',
          severity: 'error',
        },
      })
    }
  }
)

const createTsError = {
  'teamspaces count exceeded': 'Превышен лимит на создание групп',
}

export const changeRole = createAsyncThunk(
  'teamspaces/changeRole',
  async (
    {
      group_id,
      user_id,
      to_group_id,
    }: { group_id: string; user_id: string; to_group_id: string | undefined },
    { rejectWithValue }
  ) => {
    try {
      await changeMemberRole({ group_id, user_id, body: { to_group_id } })
      return { message: 'Роль участника изменена', severity: 'success' }
    } catch (error: any) {
      return rejectWithValue(error.message ?? 'Error change role')
    }
  }
)

export const fetchTeamspacesMembers = createAsyncThunk(
  'teamspaces/fetchTeamspacesMembers',
  async ({ id }: { id: string | undefined }, { rejectWithValue }) => {
    try {
      const response = await getTeamspaceMembers({ id })
      return response.data.members
    } catch (error: any) {
      return rejectWithValue(error.message ?? 'Error members')
    }
  }
)

export const fetchGroups = createAsyncThunk(
  'teamspaces/fetchGroups',
  async ({ id }: { id: string | undefined }, { rejectWithValue }) => {
    try {
      const response = await getTeamspaceGroups({ id })
      return response.data.groups
    } catch (error: any) {
      return rejectWithValue(error.message ?? 'Error fetching groups')
    }
  }
)
export const fetchTeamspaceInvites = createAsyncThunk(
  'teamspaces/fetchTeamspaceInvites',
  async ({ id }: { id: string | undefined }, { rejectWithValue }) => {
    try {
      const response = await getTeamspaceInvites({ id })
      return response.data.invites
    } catch (error: any) {
      return rejectWithValue(error.message ?? 'Error fetching groups')
    }
  }
)

export const deleteTeamspaceMember = createAsyncThunk(
  'teamspaces/deleteTeamspaceMember',
  async (
    {
      group_id,
      user_id,
      tsId,
    }: { group_id: string; user_id: string; tsId: string | undefined },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await deleteMember({ group_id, user_id })
      dispatch(fetchTeamspacesMembers({ id: tsId }))
      return {
        message: 'Участник успешно удален из группы',
        severity: 'success',
      }
    } catch (error: any) {
      return rejectWithValue(error.message ?? 'Error delete member')
    }
  }
)

export const deleteTeamspaceInvite = createAsyncThunk(
  'teamspaces/deleteTeamspaceInvite',
  async (
    { id, tsId }: { id: string; tsId: string | undefined },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await deleteInvitee({ id })
      dispatch(fetchTeamspaceInvites({ id: tsId }))
      return { message: 'Приглашение успешно удалено', severity: 'success' }
    } catch (error: any) {
      return rejectWithValue(error.message ?? 'Error delete invite')
    }
  }
)

export const updateTeamspaceInfo = createAsyncThunk(
  'teamspaces/updateTeamspaceInfo',
  async (
    {
      tsId,
      wsId,
      name,
      color,
    }: {
      tsId: string | undefined
      wsId: string
      name: string
      color: any
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await changeTeamspaceInfo({ id: tsId, teamspace: { name } })
      await changeTeamspaceAttrs({ id: tsId, attrs: { color: { hex: color } } })
      dispatch(fetchTeamspaces({ id: wsId }))
      return { message: 'Информация успешно изменена', severity: 'success' }
    } catch (error: any) {
      return rejectWithValue(error.message ?? 'Error updating teamspace info')
    }
  }
)

export const deleteTs = createAsyncThunk(
  'teamspaces/deleteTs',
  async (
    {
      tsId,
      callback,
      wsId,
    }: { tsId: string | undefined; callback: any; wsId: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await deleteTeamspace({ id: tsId })
      dispatch(selectTeamspace(null))
      dispatch(fetchTeamspaces({ id: wsId }))
      callback()
      return { message: 'Группа удалена', severity: 'success' }
    } catch (error: any) {
      return rejectWithValue(error.message ?? 'Error delete teamspace')
    }
  }
)

export const leaveTs = createAsyncThunk(
  'teamspaces/leaveTs',
  async (
    { tsId, navigate }: { tsId: string | undefined; navigate: any },
    { rejectWithValue }
  ) => {
    try {
      await leaveTeamspace({ id: tsId })
      navigate('/')
      return { message: 'Вы покинули группу', severity: 'success' }
    } catch (error: any) {
      return rejectWithValue(error.message ?? 'Error leave teamspace')
    }
  }
)

const teamspaceSlice = createSlice({
  name: 'teamspaces',
  initialState,
  reducers: {
    clearNotificationsTs: (state) => {
      state.notification = {
        create: null,
        teamspaces: null,
        members: null,
        groups: null,
        invites: null,
        delete_member: null,
        change_role: null,
        update_ts: null,
        delete_ts: null,
        leave_ts: null,
        create_invite: { message: '', severity: 'success' },
      }
    },
    selectTeamspace: (state, action: PayloadAction<ITeamspace | null>) => {
      state.selectedTeamspace = action.payload
    },

    clearTeamspaces: (state) => {
      state.teamspaces = []
      state.selectedTeamspace = null
    },
    clearDeleteTeamspaceMember: (state) => {
      state.error.delete_member = null
    },
    createInviteAction: (state) => {
      state.notification.create_invite.message = 'Приглашения отправлены'
      state.notification.create_invite.severity = 'success'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamspaces.pending, (state) => {
        state.loading.teamspaces = true
        state.error.teamspaces = null
      })
      .addCase(fetchTeamspaces.fulfilled, (state, action) => {
        state.loading.teamspaces = false
        state.teamspaces = action.payload
      })
      .addCase(fetchTeamspaces.rejected, (state, action) => {
        state.loading.teamspaces = false
        state.error.teamspaces =
          action.error.message ?? 'Error fetching teamspaces'
      })
      .addCase(createNewTeamspace.pending, (state) => {
        state.loading.create = true
        state.error.create = null
      })
      .addCase(createNewTeamspace.fulfilled, (state) => {
        state.loading.create = false
        state.error.create = null
      })
      .addCase(createNewTeamspace.rejected, (state, action: any) => {
        state.loading.create = false
        state.error.create = action.payload?.message || 'Error'
        state.notification.create = action.payload?.notification
      })
      .addCase(changeRole.fulfilled, (state, action: any) => {
        state.notification.change_role = action.payload
      })
      .addCase(changeRole.rejected, (state, action: any) => {
        state.error.change_role = action.payload
      })
      .addCase(fetchTeamspacesMembers.fulfilled, (state, action: any) => {
        state.members = action.payload
      })
      .addCase(fetchTeamspacesMembers.rejected, (state, action: any) => {
        state.error.members = action.payload
      })
      .addCase(fetchGroups.fulfilled, (state, action: any) => {
        state.groups = action.payload
      })
      .addCase(fetchGroups.rejected, (state, action: any) => {
        state.error.groups = action.payload
      })
      .addCase(fetchTeamspaceInvites.fulfilled, (state, action: any) => {
        state.invites = action.payload
      })
      .addCase(fetchTeamspaceInvites.rejected, (state, action: any) => {
        state.error.invites = action.payload
      })
      .addCase(deleteTeamspaceMember.fulfilled, (state, action: any) => {
        state.notification.delete_member = action.payload
      })
      .addCase(deleteTeamspaceMember.rejected, (state, action: any) => {
        state.error.delete_member = action.payload
      })
      //   todo: change to delete invite
      .addCase(deleteTeamspaceInvite.fulfilled, (state, action: any) => {
        state.notification.delete_member = action.payload
      })
      .addCase(deleteTeamspaceInvite.rejected, (state, action: any) => {
        state.error.delete_member = action.payload
      })
      .addCase(updateTeamspaceInfo.fulfilled, (state, action: any) => {
        state.notification.update_ts = action.payload
      })
      .addCase(updateTeamspaceInfo.rejected, (state, action: any) => {
        state.error.update_ts = action.payload
      })
      .addCase(deleteTs.fulfilled, (state, action: any) => {
        state.notification.delete_ts = action.payload
      })
      .addCase(deleteTs.rejected, (state, action: any) => {
        state.error.delete_ts = action.payload
      })
      .addCase(leaveTs.fulfilled, (state, action: any) => {
        state.notification.leave_ts = action.payload
      })
      .addCase(leaveTs.rejected, (state, action: any) => {
        state.error.leave_ts = action.payload
      })
  },
})

export const {
  clearNotificationsTs,
  selectTeamspace,
  clearTeamspaces,
  clearDeleteTeamspaceMember,
  createInviteAction,
} = teamspaceSlice.actions

export default teamspaceSlice.reducer
