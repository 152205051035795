import { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { TableCell, TableContainer } from '@mui/material'
import { Empty } from 'components'
import { useCheckbox } from 'hooks/useCheckbox'
import img_inbox from 'assets/img/inbox.svg'
import { updateNotificationsCount } from 'store/slices/inboxSlice'
import { config } from 'config'
import { TableCompound } from 'components/table/table-compound/TableCompound'
import { useSelectedDocsContext } from 'context/selected-docs'

export const InboxRegistryTable = () => {
  const { hiddenDocumentIds } = useSelectedDocsContext()

  const { count, documents, loading, filter, page, completed }: IInboxState =
    useSelector((state: RootState) => state.inbox, shallowEqual)

  const { checkboxHeader, checkboxTable } = useCheckbox({
    page,
    rows: documents,
  })
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateNotificationsCount() as any)
  }, [completed])

  const showEmptyPage = useMemo(() => {
    return <Empty src={img_inbox} title={'Пока тут нет документов'} />
  }, [filter])

  if (count === 0) return showEmptyPage

  return (
    <>
      <TableContainer>
        <TableCompound>
          <TableCompound.Head>
            <TableCompound.Row>
              <TableCell padding="checkbox">{checkboxHeader()}</TableCell>
              <TableCompound.HeadCellDocumentName />
              <TableCompound.HeadCellDocumentStatus />
              <TableCompound.HeadCellDocumentAuthor />
              <TableCompound.HeadCellDocumentLastModified />
            </TableCompound.Row>
          </TableCompound.Head>
          {!loading && (
            <TableCompound.Body>
              {documents.map((row) => {
                if (hiddenDocumentIds.includes(row.id)) return null
                const link = `${config.DOMAIN_SIGN_DOODOCS}/${row.id}?token=${row.token}`
                return (
                  <TableCompound.RowHoverable key={row.id}>
                    <TableCell padding="checkbox">
                      {checkboxTable({ row })}
                    </TableCell>
                    <TableCompound.BodyCellDocumentName
                      title={row.name}
                      to={link}
                      blank={true}
                    />
                    <TableCompound.BodyCellDocumentStatus
                      status={row.status}
                      to={link}
                      isShared={row.is_shared}
                      blank={true}
                    />
                    <TableCompound.BodyCellDocumentAuthorEmail
                      email={row.author_email}
                      to={link}
                      blank={true}
                      image={row.author_avatar_link || ''}
                    />
                    <TableCompound.BodyCellDocumentLastModified
                      lastActionAt={row.last_action_at}
                      to={link}
                      blank={true}
                    />
                  </TableCompound.RowHoverable>
                )
              })}
            </TableCompound.Body>
          )}
        </TableCompound>
      </TableContainer>
    </>
  )
}
