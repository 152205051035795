import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { Loading } from 'components'
import { Error404 } from 'pages'
import { useFetchWorkspaceAndTeamspaces } from './hooks/useFetchWorkspaceAndTeamspaces'
import { auth } from 'utils/auth'

const TeamspaceRoute = () => {
  const {
    teamspaces,
    loading: loadingTs,
    error,
    selectedTeamspace,
  } = useSelector((state: RootState) => state.teamspace)
  const { authenticated } = useSelector((state: RootState) => state.user)
  const { loading, error: wsError } = useSelector(
    (state: RootState) => state.workspace
  )

  const dispatch = useDispatch()
  const { workspaces } = useSelector(
    (state: RootState) => state.workspace,
    shallowEqual
  )

  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  useFetchWorkspaceAndTeamspaces([
    dispatch,
    selectedWorkspace,
    teamspaces,
    workspaces,
  ])

  if (loading.workspaces || loadingTs.teamspaces) return <Loading />

  if (error.teamspaces || wsError.workspaces) {
    return (
      <Error404
        message={'Произошла ошибка'}
        description={'Попробуйте перезагрузить страницу'}
      ></Error404>
    )
  }

  if (authenticated && workspaces?.length === 0) {
    auth.savePathRedirectAfterLogin(
      window.location.pathname + window.location.search
    )
    return <Navigate to={`/login`} />
  }

  if (selectedTeamspace && selectedTeamspace?.id && selectedWorkspace?.id)
    return <Navigate to={`/teamspace/${selectedTeamspace?.id}/documents`} />
  if (teamspaces === null) return <Navigate to={`/settings`} />
  return <Loading />
}

export default TeamspaceRoute
