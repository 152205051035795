import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Container } from '@mui/material'
import { Loading, MessageSnackbar } from 'components'
import { useChangePageContext, useSelectedDocsContext } from 'context'
import { useSnackbar } from 'components/snackbar/hook'
import { useDocumentsRegistryDublicateDocumentContextDefaultValue } from 'features/copy-document/providers/DocumentsRegistryDublicateDocumentContext'
import { useParams } from 'react-router-dom'
import { clearErrors } from 'store/slices/templateSlice'
import { RegistryTable } from './components/table/RegistryTable'
import { useSearchQueryURLParams } from 'context/search-bar/hooks'

export const RegistryContent = () => {
  const dispatch = useDispatch()
  const { tsId } = useParams()

  const { loading, error, count }: IDocTableState = useSelector(
    (state: RootState) => state.documents,
    shallowEqual
  )

  const { page, filter, query, status, author } = useSearchQueryURLParams({
    totalDocumentsCount: count,
  })

  const { selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )

  const { snackbar } = useSnackbar()
  const { setSelectedDocsIds, setHiddenDocumentIds } = useSelectedDocsContext()
  const { changePage } = useChangePageContext()
  const dubls = useDocumentsRegistryDublicateDocumentContextDefaultValue()

  useEffect(() => {
    tsId === selectedTeamspace?.id &&
      changePage({
        page,
        params: {
          filter,
          query,
          author_id: author,
          status,
        },
      })
    dubls.clearDublicates()
  }, [author, page, query, status, filter, selectedTeamspace?.id, tsId])

  useEffect(() => {
    setSelectedDocsIds(new Map())
    setHiddenDocumentIds([])
    dubls.clearDublicates()
  }, [filter])

  useEffect(() => {
    dubls.clearDublicates()
  }, [page])

  useEffect(() => {
    setHiddenDocumentIds([])
    return () => {
      dispatch(clearErrors() as any)
    }
  }, [])

  return (
    <>
      <MessageSnackbar
        message={snackbar.message}
        clearMessage={snackbar.close}
        severity={snackbar.severity}
        buttons={snackbar.buttons}
      />
      <MessageSnackbar
        message={error}
        clearMessage={() => dispatch(clearErrors() as any)}
        severity={'error'}
      />
      <Container
        maxWidth={false}
        sx={{
          overflow: 'auto',
          pr: {
            xs: '20px',
          },
          pl: { xs: '20px' },
          position: 'relative',
          height: '100%',
        }}
      >
        {loading ? <Loading /> : <RegistryTable />}

        <MessageSnackbar
          message={dubls.error}
          clearMessage={() => dubls.clearError()}
          severity={'error'}
        />
      </Container>
    </>
  )
}
