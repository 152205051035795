import { createTheme } from '@mui/material'
import { light } from './lightTheme'
import { typography } from './typography'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true
  }
}

export const theme = ({ mode }) =>
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 601,
        md: 960,
        lg: 1280,
        xl: 1536,
        xxl: 2600,
      },
    },

    components: {
      MuiButton: {
        styleOverrides: {
          startIcon: {
            marginRight: '4px',
          },
          root: {
            textTransform: 'none',
            '&:disabled': {
              border: '1px solid #EDEDEC',
              background: '#F5F5F5',
              color: '#90959D',
            },
          },
          sizeSmall: {
            padding: '1px 7px',
            '@media (max-width:600px)': {
              padding: '3px 7px',
            },
          },
          sizeMedium: {
            padding: '5px 11px',
            '@media (max-width:600px)': {
              padding: '7px 11px',
            },
          },
          sizeLarge: {
            padding: '11px 24px',
          },
          textSizeLarge: {
            padding: '12px 16px',
          },
          containedPrimary: {
            border: '1px solid #1272CC',
            '&:hover': {
              background: '#339DFF',
            },
            '&:active': {
              background: '#1272CC',
            },
          },
          containedSuccess: {
            background: '#039F00',
            border: '1px solid #009200',
            '&:hover': {
              background: '#4FBC4D',
            },
            '&:active': {
              background: '#009200',
            },
          },
          outlinedSecondary: {
            border: '1px solid #EDEDEC',
            background: '#FFFFFF',
            boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.06)',
            '&:hover': {
              border: '1px solid #E7E7E6',
              background: '#F5F5F5',
            },
            '&:active': {
              border: '1px solid #E7E7E6',
              background: '#EDEDEC',
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            padding: 0,
            transition: '0.2s ease-out',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: '6px',
            borderRadius: '4px',

            '&:hover, &:focus': {
              background:
                'var(--light-grayscale-background-secondary, #F5F5F5)',
            },
            '&:active': {
              background: 'var(--light-grayscale-border, #EDEDEC)',
            },

            '@media (max-width:600px)': {
              padding: '10px',
            },
          },
          sizeSmall: {
            padding: '4px',
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            '&.MuiIconButton-root > img': {
              filter: mode === 'dark' && 'invert()',
            },
            '&.MuiMenuItem-root': {
              '@media (max-width:600px)': {
                minHeight: '40px',
              },
            },

            '&.MuiPickersDay-root': {
              borderRadius: '4px',
              border: '1px solid #EDEDEC',
            },
            '&.MuiPickersDay-root.Mui-selected': {
              borderRadius: '4px',
              backgroundColor: '#0085FF',
              border: '1px solid #1272CC',
            },
            '&.MuiPickersDay-root.Mui-selected:hover': {
              borderRadius: '4px',
              backgroundColor: '#0085FF',
              border: '1px solid #1272CC',
              willChange: 'unset',
            },
            '&.MuiPickersDay-root:focus.Mui-selected': {
              borderRadius: '4px',
              backgroundColor: '#0085FF',
              border: '1px solid #1272CC',
              willChange: 'unset',
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            // color: 'white',
          },
        },
      },

      MuiStepLabel: {
        styleOverrides: {
          root: {
            // alignItems: 'flex-start',
            // color: 'white',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '16px 12px',
            '&:last-child': {
              paddingBottom: '16px',
            },
          },
        },
      },
      MuiCardHeader: {
        defaultProps: {
          titleTypographyProps: {
            variant: 'h6',
          },
          subheaderTypographyProps: {
            variant: 'body2',
          },
        },
        styleOverrides: {
          root: {
            padding: '16px 12px',
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
          },
          html: {
            MozOsxFontSmoothing: 'grayscale',
            WebkitFontSmoothing: 'antialiased',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%',
            width: '100%',
          },
          body: {
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            minHeight: '100%',
            width: '100%',
          },
          '#__next': {
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input':
              {
                padding: '0 6px',
              },
            '& .MuiAutocomplete-tag': {
              margin: '0 3px',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.MuiInputBase-root:hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#A1DAFF',
              },
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #A1DAFF',
            },
            '&.Mui-focused fieldset': {
              boxShadow: '0px 0px 0px 2px #DCF1FF',
            },
          },
          notchedOutline: {
            borderColor: '#EDEDEC',
          },
          input: {
            padding: '6px 12px',
            '@media (max-width:600px)': {
              padding: '8px 12px',
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#EDEDEC',
              },
              '&:hover': {
                borderColor: '#A1DAFF',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #E92C2C',
              },
              '&:hover fieldset': {
                border: '1px solid #A1DAFF',
              },
              '&:focus fieldset': {
                borderColor: '#A1DAFF',
                boxShadow: '0px 0px 0px 2px #DCF1FF',
              },
              '&:active fieldset': {
                borderColor: '#A1DAFF',
                boxShadow: '0px 0px 0px 2px #DCF1FF',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#A1DAFF',
                boxShadow: '0px 0px 0px 2px #DCF1FF',
              },
            },
            input: {
              padding: '6px 12px',
              '@media (max-width:600px)': {
                padding: '8px 12px',
              },
            },
            '& .MuiFormHelperText-root': {
              margin: '4px 0px 0px',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '20px',
            },
          },
        },
      },

      MuiFormControl: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#EDEDEC',
              },
              '&:hover': {
                borderColor: '#A1DAFF',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #E92C2C',
              },
              '&:hover fieldset': {
                border: '1px solid #A1DAFF',
              },
              '&:focus fieldset': {
                borderColor: '#A1DAFF',
                boxShadow: '0px 0px 0px 2px #DCF1FF',
              },
              '&:active fieldset': {
                borderColor: '#A1DAFF',
                boxShadow: '0px 0px 0px 2px #DCF1FF',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#A1DAFF',
                boxShadow: '0px 0px 0px 2px #DCF1FF',
              },
            },
            input: {
              padding: '6px 12px',
              '@media (max-width:600px)': {
                padding: '8px 12px',
              },
            },
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          colorPrimary: {
            border: ' none',
            boxShadow: 'none',
            background: 'none',
            // boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06)',
            padding: 0,
            height: '18px',
            width: '18px',
            color: '#E8E8E8',
            borderRadius: '4px',
            // border: '1px solid #E8E8E8',
            '&.Mui-checked': {
              borderColor: 'transparent',
            },
            '&:disabled': {
              // border: '1px solid red',
            },
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            '.MuiTableCell-root': {
              fontSize: '0.875rem',
              ...(mode === 'light'
                ? { borderBottom: '1px solid #E8E8E8' }
                : { borderBottom: '1px solid #313131' }),
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              padding: '6px 8px 5px',
              fontWeight: 400,
              color: '#6B6F76',
            },
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            '.MuiTableCell-root': {
              padding: '10px 8px',
              fontSize: '0.85rem',
              fontWeight: 400,
              ...(mode === 'light'
                ? { color: '#282A2F', borderBottom: '1px solid #E8E8E8' }
                : { color: '#969696', borderBottom: '1px solid #313131' }),
            },
            '.MuiTableCell-root > div > a': {
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            },
          },
        },
      },

      MuiTabs: {
        styleOverrides: {
          root: {
            overflow: 'visible',
          },
        },
      },

      MuiStepIcon: {
        styleOverrides: {
          root: {
            color: '#FFFFFF',
            border: '1px solid #E8E8E8',
            borderRadius: '32px',
            '&.Mui-completed': {
              color: '#EAF6E3',
              borderColor: '#D3DDCC',
              '& text': {
                fill: '#039F00',
              },
            },
            '&.Mui-active': {
              color: '#EAF6E3',
              borderColor: '#D3DDCC',
              '& text': {
                fill: '#039F00',
              },
            },
          },
          text: {
            fill: '#1C1C1C',
          },
        },
      },

      MuiMenu: {
        styleOverrides: {
          paper: {
            width: '280px',
            minWidth: '280px !important',
            maxHeight: '100%',
            maxWidth: '280px',
          },
          list: {
            paddingTop: '6px',
            paddingBottom: '6px',
          },
        },
      },

      MuiMenuItem: {
        styleOverrides: {
          root: {
            whiteSpace: 'break-spaces',
            borderRadius: 4,
            padding: '4px 12px',
            margin: '2px 4px',
            '&:hover': {
              backgroundColor: '#F7F7F7',
            },
            '&:focus': {
              backgroundColor: '#F7F7F7',
            },
            '&.Mui-selected:hover': {
              backgroundColor: '#EDEDEC',
            },
            '&.Mui-selected': {
              backgroundColor: '#EDEDEC',
            },
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: '52px',
            '@media (min-width:600px)': {
              minHeight: '52px',
            },
          },
        },
      },

      MuiPaper: {
        styleOverrides: {
          root: {
            '&.MuiPopover-paper': {
              maxHeight: '200px',
              background: 'white',
            },
            '&.MuiPickersPopper-paper': {
              background: 'white',
            },
          },
        },
      },

      MuiTypography: {
        defaultProps: {
          // ? Reason Bug in page Template
          // variantMapping: {
          //   heading3: 'h3',
          //   heading4: 'h4',
          //   heading5: 'h5',
          //   heading6: 'h6',
          //   paragraph2: 'p',
          //   paragraph3: 'p',
          //   paragraph4: 'p',
          // }
        },
      },

      // MuiAlert: {
      //   styleOverrides: {
      //     standardSuccess: {
      //       backgroundColor: 'white',
      //       color: 'white',
      //     },
      //     standardError: {
      //       backgroundColor: 'white',
      //       color: 'white',
      //     },
      //     standardWarning: {
      //       backgroundColor: 'white',
      //       color: 'white',
      //     },
      //     standardInfo: {
      //       backgroundColor: 'grey',
      //       color: 'black',
      //     },
      //   },
      // },
    },

    palette: {
      mode,
      ...light,
      // ...(mode === 'light' ? light : dark),
    },
    shape: {
      borderRadius: 4,
    },
    shadows: [
      'none',
      '0px 1px 1px rgba(0, 0, 0, 0.06)',
      '0px 1px 1px rgba(0, 0, 0, 0.06)',
      '0px 1px 4px rgba(100, 116, 139, 0.12)',
      '0px 1px 5px rgba(100, 116, 139, 0.12)',
      '0px 1px 6px rgba(100, 116, 139, 0.12)',
      '0px 2px 6px rgba(100, 116, 139, 0.12)',
      '0px 3px 6px rgba(100, 116, 139, 0.12)',
      '0px 0px 1px rgba(28, 28, 28, 0.32), 0px 12px 24px rgba(150, 150, 150, 0.24);',
      '0px 5px 12px rgba(100, 116, 139, 0.12)',
      '0px 5px 14px rgba(100, 116, 139, 0.12)',
      '0px 5px 15px rgba(100, 116, 139, 0.12)',
      '0px 6px 15px rgba(100, 116, 139, 0.12)',
      '0px 7px 15px rgba(100, 116, 139, 0.12)',
      '0px 8px 15px rgba(100, 116, 139, 0.12)',
      '0px 9px 15px rgba(100, 116, 139, 0.12)',
      '0px 10px 15px rgba(100, 116, 139, 0.12)',
      '0px 12px 22px -8px rgba(100, 116, 139, 0.25)',
      '0px 13px 22px -8px rgba(100, 116, 139, 0.25)',
      '0px 14px 24px -8px rgba(100, 116, 139, 0.25)',
      '0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)',
      '0px 25px 50px rgba(100, 116, 139, 0.25)',
      '0px 25px 50px rgba(100, 116, 139, 0.25)',
      '0px 25px 50px rgba(100, 116, 139, 0.25)',
      '0px 25px 50px rgba(100, 116, 139, 0.25)',
    ],
    typography: { ...typography },
  })
