import { IChangePageArgs } from 'components/pagination/types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { updateCampaigns } from 'store/slices/campaign'

export const useCampaignsChangePage = () => {
  const dispatch = useDispatch()
  const { tsId } = useParams()

  const changePage = useCallback(
    ({ page }: IChangePageArgs) => {
      dispatch(
        updateCampaigns({
          page,
          ts_id: tsId || '',
        }) as any
      )
    },
    [tsId, dispatch]
  )

  return { changePage }
}
