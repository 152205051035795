import { ToggleButton, Box } from '@mui/material'
import { CustomTooltip } from 'components'
import { SidebarMode } from 'pages/document-preview/hooks/useSideBarState'
import { isMobile } from 'react-device-detect'

export const SidebarToggleBtn = ({
  mode,
  src_img,
  tooltip,
}: {
  mode: SidebarMode
  src_img: string
  tooltip: string
}) => {
  return (
    <ToggleButton value={mode} sx={{ p: 0 }}>
      <CustomTooltip title={tooltip}>
        <Box
          sx={{ p: { sm: '5px 11px', xs: '9px 11px' }, display: 'flex' }}
          id={isMobile && mode === SidebarMode.workflow ? 'workflow-view' : ''}
        >
          <Box
            id={mode === SidebarMode.workflow ? 'workflow-view-button' : ''}
            component={'img'}
            src={src_img}
            alt={tooltip}
            width={{ xs: '40px', sm: '20px' }}
            height="20px"
          />
        </Box>
      </CustomTooltip>
    </ToggleButton>
  )
}
