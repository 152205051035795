import { Box } from '@mui/material'
import {
  IWorkflowBuilderUIContext,
  WorfklowBuilderUIContext,
} from '../../providers/WorkflowBuilderUIContext'
import { BlockButtons } from './BlockButtons'
import { BlockMessage } from './BlockMessage'
import { BlockRecipients } from './BlockRecipients'

export const WorkflowBuilder = ({
  workflowBuilderUI,
}: {
  workflowBuilderUI: IWorkflowBuilderUIContext
}) => {
  return (
    <Box>
      <WorfklowBuilderUIContext.Provider value={workflowBuilderUI}>
        <BlockButtons />
        <BlockRecipients />
        <BlockMessage />
      </WorfklowBuilderUIContext.Provider>
    </Box>
  )
}
