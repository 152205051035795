import { Typography, Container, Box } from '@mui/material'
import React from 'react'

import img_empty_box from 'assets/img/illustrations/empty-box.svg'

export enum EIllustration {
  empty_box = 'empty_box',
}

const illustrations: {
  [key in EIllustration]: string
} = {
  [EIllustration.empty_box]: img_empty_box,
}

// TODO: Rename, and move to components
export const Empty = ({
  illustration,
  src,
  title,
  subtitle,
  children,
}: {
  illustration?: EIllustration
  src?: string
  title: string
  subtitle?: string
  children?: React.ReactNode
}) => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        py: (!!illustration && '64px') || '128px',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
      }}
    >
      {(!!illustration && (
        <Box
          component={'img'}
          src={illustrations[illustration]}
          width={'100px'}
        />
      )) ||
        (!!src && <Box component={'img'} src={src} width={'24px'} />)}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <Typography variant="heading5" sx={{ mb: '8px' }}>
          {title}
        </Typography>
        {!!subtitle && (
          <Typography variant="paragraph3" color="text.disabled">
            {subtitle}
          </Typography>
        )}
      </Box>
      <Box>{children}</Box>
    </Container>
  )
}
