import { styled, Theme, CSSObject } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import { isMobile } from 'react-device-detect'

export const mainSidebarWidth = isMobile ? 280 : 240

export const openedMixin = (theme: Theme): CSSObject => ({
  width: mainSidebarWidth,

  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
    delay: 0,
  }),
  overflowX: 'hidden',
  background: theme.palette.background.paper,
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  borderRight: 'none',
})

export const closedMixin = (theme: Theme): CSSObject => ({
  width: 0,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
    delay: 0,
  }),
})

export const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: mainSidebarWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  display: 'flex',
  height: '100%',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))
