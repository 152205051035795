import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { manageTemplateSettingsSwitchSignMethodEGovMobile } from "./manageTemplateSettingsSwitchSignMethodEGovMobile";
import { manageTemplateSettingsSwitchSignMethodEGovMobileBusiness } from "./manageTemplateSettingsSwitchSignMethodEGovMobileBusiness";
import { manageTemplateSettingsSwitchSignMethodNCALayer } from "./manageTemplateSettingsSwitchSignMethodNCALayer";
import { manageTemplateSettingsSwitchWorkflowEnabled } from "./manageTemplateSettingsSwitchWorkflowEnabled";
import { manageTemplateSettingsUpdateWorkflow } from "./manageTemplateSettingsUpdateWorkflow";

export type TManageTemplateSettingsStatus = "idle" | "loading" | "failed" | "success"

export interface IManageTemplateSettingsState {
  template_id: string
  status: TManageTemplateSettingsStatus;
  error: string

  signMethods: {
    eGovMobile: {
      isOn: boolean,
      loading: boolean,
    }
    eGovMobileBusiness: {
      isOn: boolean,
      loading: boolean,
    }
    ncaLayer: {
      isOn: boolean,
      loading: boolean,
    }
  }
  preFilledWorkflow: {
    isOn: boolean,
    loading: boolean,
    workflow: any
  }
}

const getInitialState = (): IManageTemplateSettingsState => {
  return {
    template_id: "",
    status: "idle",
    error: "",
    signMethods: {
      eGovMobile: {
        isOn: false,
        loading: false,
      },
      eGovMobileBusiness: {
        isOn: false,
        loading: false,
      },
      ncaLayer: {
        isOn: false,
        loading: false,
      },
    },
    preFilledWorkflow: {
      isOn: false,
      loading: false,
      workflow: {}
    }
  }
}

export const manageTemplateSettingsSlice = createSlice({
  name: "templateSettings",
  initialState: getInitialState(),
  reducers: {
    resetState: () => getInitialState(),
    setTemplateId: (state, action: PayloadAction<string>) => {
      state.template_id = action.payload
    },
    setStatus: (state, action: PayloadAction<TManageTemplateSettingsStatus>) => {
      state.status = action.payload
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
    },
    setSettings: (state, action: PayloadAction<{
      eGovMobile: boolean,
      eGovMobileBusiness: boolean,
      ncaLayer: boolean,
      preFilledWorkflow: {
        isOn: boolean,
        workflow: any
      }
    }>) => {
      state.signMethods.eGovMobile.isOn = action.payload.eGovMobile
      state.signMethods.eGovMobile.loading = false

      state.signMethods.eGovMobileBusiness.isOn = action.payload.eGovMobileBusiness
      state.signMethods.eGovMobileBusiness.loading = false

      state.signMethods.ncaLayer.isOn = action.payload.ncaLayer
      state.signMethods.ncaLayer.loading = false

      state.preFilledWorkflow.isOn = action.payload.preFilledWorkflow.isOn
      state.preFilledWorkflow.workflow = action.payload.preFilledWorkflow.workflow
      state.preFilledWorkflow.loading = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(manageTemplateSettingsSwitchSignMethodEGovMobile.pending, (state) => {
      state.signMethods.eGovMobile.loading = true
    })
    builder.addCase(manageTemplateSettingsSwitchSignMethodEGovMobile.fulfilled, (state, action) => {
      state.signMethods.eGovMobile.isOn = action.payload
      state.signMethods.eGovMobile.loading = false
    })
    builder.addCase(manageTemplateSettingsSwitchSignMethodEGovMobile.rejected, (state, action) => {
      state.error = action.payload as string
      state.signMethods.eGovMobile.loading = false
    })

    builder.addCase(manageTemplateSettingsSwitchSignMethodEGovMobileBusiness.pending, (state) => {
      state.signMethods.eGovMobileBusiness.loading = true
    })
    builder.addCase(manageTemplateSettingsSwitchSignMethodEGovMobileBusiness.fulfilled, (state, action) => {
      state.signMethods.eGovMobileBusiness.isOn = action.payload
      state.signMethods.eGovMobileBusiness.loading = false
    })
    builder.addCase(manageTemplateSettingsSwitchSignMethodEGovMobileBusiness.rejected, (state, action) => {
      state.error = action.payload as string
      state.signMethods.eGovMobileBusiness.loading = false
    })

    builder.addCase(manageTemplateSettingsSwitchSignMethodNCALayer.pending, (state) => {
      state.signMethods.ncaLayer.loading = true
    })
    builder.addCase(manageTemplateSettingsSwitchSignMethodNCALayer.fulfilled, (state, action) => {
      state.signMethods.ncaLayer.isOn = action.payload
      state.signMethods.ncaLayer.loading = false
    })
    builder.addCase(manageTemplateSettingsSwitchSignMethodNCALayer.rejected, (state, action) => {
      state.error = action.payload as string
      state.signMethods.ncaLayer.loading = false
    })

    builder.addCase(manageTemplateSettingsSwitchWorkflowEnabled.pending, (state) => {
      state.preFilledWorkflow.loading = true
    })
    builder.addCase(manageTemplateSettingsSwitchWorkflowEnabled.fulfilled, (state, action) => {
      state.preFilledWorkflow.isOn = action.payload
      state.preFilledWorkflow.loading = false
    })
    builder.addCase(manageTemplateSettingsSwitchWorkflowEnabled.rejected, (state, action) => {
      state.error = action.payload as string
      state.preFilledWorkflow.loading = false
    })

    builder.addCase(manageTemplateSettingsUpdateWorkflow.pending, (state) => {
      state.preFilledWorkflow.loading = true
    })
    builder.addCase(manageTemplateSettingsUpdateWorkflow.fulfilled, (state, action) => {
      state.preFilledWorkflow.workflow = action.payload
      state.preFilledWorkflow.loading = false
    })
    builder.addCase(manageTemplateSettingsUpdateWorkflow.rejected, (state, action) => {
      state.error = action.payload as string
      state.preFilledWorkflow.loading = false
    })
  }
})
