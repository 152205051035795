import img_pencil from 'assets/img/ic_pencil.svg'
import img_view from 'assets/img/view.svg'

import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material'
import { MessageSnackbar, Switcher } from 'components'
import { IconButtonImage } from 'components/button/IconButtonImage'
import { ModalActionDocument } from 'components/modal-actions/modalActionDocument'
import { useSnackbar } from 'components/snackbar/hook'
import { Formik } from 'formik'
import { useModal } from 'hooks/useModal'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import { integrationsApi } from 'service/integrations'
import { RowActionsButton } from '../RowActionsButton'

export const ActiveIntegrationRowBase = ({
  integrationId,
  icon,
  title,
  description,
  isEnabled,
  link,
  onClickEdit,
  onDeleteCallback,
  loading,
}: {
  integrationId: string
  icon: string
  title: string
  description: string
  isEnabled: boolean
  link?: string
  onClickEdit?: () => any
  onDeleteCallback?: () => any
  loading?: boolean
}) => {
  const [modalDeleteOpen, openModalDelete, closeModalDelete] = useModal()

  const { snackbar, showSnackbar, closeSnackbar } = useSnackbar()

  const handleSwitchChange = async (_: string, value: boolean) => {
    try {
      if (value)
        await integrationsApi.postIntegrationsEnable({
          integration_id: integrationId,
        })
      else
        await integrationsApi.deleteIntegrationsEnable({
          integration_id: integrationId,
        })
    } catch (error: any) {
      showSnackbar({
        message: error.message || 'Не смогли выполнить действие',
        severity: 'error',
      })
    }
  }

  const handleDeleteIntegration = async () => {
    try {
      await integrationsApi.deleteIntegration({
        integration_id: integrationId,
      })
      onDeleteCallback && onDeleteCallback()
    } catch (error: any) {
      showSnackbar({
        message: error.message || 'Не смогли удалить интеграцию',
        severity: 'error',
      })
    }
  }

  const handleDeleteConfirmation = () => {
    openModalDelete()
  }

  const modalConfigs = [
    {
      key: 'delete-modal',
      handleClose: closeModalDelete,
      open: modalDeleteOpen,
      action: handleDeleteIntegration,
      title: 'Удалить интеграцию?',
      description:
        'Вы уверены, что хотите удалить эту интеграцию? Это действие не может быть отменено.',
      btnTitle: 'Удалить',
    },
  ]

  return (
    <>
      {modalConfigs.map((config) => (
        <ModalActionDocument
          key={config.key}
          handleClose={config.handleClose}
          open={config.open}
          action={config.action}
          title={config.title}
          description={config.description}
          btnTitle={config.btnTitle}
        />
      ))}
      <MessageSnackbar
        message={snackbar.message}
        clearMessage={closeSnackbar}
        severity={snackbar.severity}
      />
      <Box
        flexDirection={'row'}
        display={'flex'}
        width={'100%'}
        alignItems={'center'}
      >
        <Box
          p={1}
          width={'42px'}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {loading && !icon ? (
            <CircularProgress />
          ) : (
            <Box component={'img'} src={icon} height={'24px'} width={'auto'} />
          )}
        </Box>
        <Box padding={'10px 8px'} flex={1}>
          <Typography color={'text.primary'}>
            {loading ? <Skeleton /> : title}
          </Typography>
          {!isMobile && (
            <Typography mt={'2px'} color={'text.disabled'}>
              {loading ? <Skeleton /> : description}
            </Typography>
          )}
        </Box>
        {loading ? null : (
          <Box
            p={'4px 8px'}
            display={'flex'}
            alignContent={'flex-start'}
            gap={'4px'}
          >
            {link && (
              <IconButton
                sx={{ p: '6px' }}
                component={Link}
                to={link}
                target="_blank"
              >
                <Box
                  component={'img'}
                  height={'20px'}
                  width={'auto'}
                  src={img_view}
                />
              </IconButton>
            )}
            {onClickEdit && (
              <IconButtonImage
                icon={{
                  src: img_pencil,
                  size: '20px',
                }}
                size="medium"
                onClick={onClickEdit}
              />
            )}
          </Box>
        )}
        <Formik
          initialValues={{
            [integrationId]: isEnabled,
          }}
          onSubmit={() => {}}
        >
          <Switcher
            name={integrationId}
            onChange={handleSwitchChange}
            disabled={loading}
          />
        </Formik>
        <RowActionsButton
          loading={loading}
          handleDeleteIntegration={handleDeleteConfirmation}
        />
      </Box>
      <Divider></Divider>
    </>
  )
}
