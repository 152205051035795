import { createAsyncThunk } from "@reduxjs/toolkit";
import { queryTemplateManageSettingsUpdateWorkflow } from "service/template";
import { TRootState } from "store/store";

export const manageTemplateSettingsUpdateWorkflow = createAsyncThunk(
  "manageTemplateSettings/manageTemplateSettingsUpdateWorkflow",
  async ({
    workflow,
  }: {
    workflow: any
  }, thunkApi) => {
    const { template_id } = (thunkApi.getState() as (TRootState)).templateSettings
    try {
      await queryTemplateManageSettingsUpdateWorkflow({
        templateId: template_id, workflow
      })
      return thunkApi.fulfillWithValue(workflow)
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message)
    }
  }
)