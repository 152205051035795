import { Box, Typography } from '@mui/material'
import { StyledTab, StyledTabs } from '../tab'

export const CustomTabs = ({
  activeValue,
  tabs,
  setActiveValue,
  disabled,
}: {
  activeValue: string
  setActiveValue: (value: any) => any
  tabs: {
    labelTitle: string
    labelSuffixText?: string
    value: any
    disabled?: boolean
  }[]
  disabled?: boolean
}) => {
  return (
    <StyledTabs
      value={activeValue}
      onChange={async (_e: Event, value: any) => await setActiveValue(value)}
    >
      {tabs.map((tab) => (
        <StyledTab
          disabled={disabled || tab.disabled}
          value={tab.value}
          key={tab.value}
          label={
            <Box
              sx={{
                display: 'flex',
                gap: '4px',
              }}
            >
              <Typography variant="heading6">{tab.labelTitle}</Typography>
              {tab.labelSuffixText && (
                <Typography variant="paragraph3">
                  {tab.labelSuffixText}
                </Typography>
              )}
            </Box>
          }
        />
      ))}
    </StyledTabs>
  )
}
