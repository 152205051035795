import { Avatar } from '@mui/material'
import { useMemo } from 'react'
import { getWorkspaceAvatar } from 'utils/workspace/workspaceAvatar'

const useAvatarWorkspace = () => {
  return useMemo(() => {
    const cacheAvatars: {
      [key: string]: string
    } = {}

    return {
      getUrl: (name) => {
        if (cacheAvatars[name]) {
          return cacheAvatars[name]
        }

        const blob = getWorkspaceAvatar(name)
        const url = URL.createObjectURL(blob)
        cacheAvatars[name] = url
        return url
      },
    }
  }, [])
}

export const AvatarWorkspace = ({
  src,
  name,
  size,
}: {
  src?: string
  name: string
  size?: number
}) => {
  const { getUrl } = useAvatarWorkspace()

  return (
    <Avatar
      src={src}
      variant="rounded"
      alt={name}
      sx={{
        bgcolor: '#E7E7E6',
        width: size != undefined ? size : '24px',
        height: size != undefined ? size : '24px',
        fontSize: size != undefined ? `${size - 4}` : '16px',
        border: '1px solid rgba(0, 0, 0, 0.1);',
        color: 'text.primary',
      }}
      children={
        <Avatar
          variant="square"
          src={getUrl(name)}
          sx={{
            width: 'inherit',
            height: 'inherit',
          }}
          children={`${(!!name && name[0].toUpperCase()) || 'W'}`}
        />
      }
    />
  )
}
