import { isMobile } from 'react-device-detect'

/**
 * HOC for rendering different components depending on the screen resolution
 * @returns
 */
export const WithDisplayResolution = ({
  mobile,
  desktop,
}: {
  mobile: React.ReactNode
  desktop: React.ReactNode
}) => {
  if (isMobile) {
    return mobile
  }

  return desktop
}
