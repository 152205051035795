import { Button, IconButton } from '@mui/material'
import { AnalyticsEvent } from 'config/analytics/segment.com/events'
import { useModal } from './hooks/useModal'
import { ModalCreateDocument } from './modals/ModalCreateDocument'

const CreateButton = ({
  ButtonComponent,
  title,
  tsId,
  ModalComponent,
  AnalyticsEventTrigger,
  withPulse = false,
  ...props
}) => {
  const {
    openModal,
    handleClose,
    handleClick: originalHandleClick,
  } = useModal(AnalyticsEventTrigger)

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    originalHandleClick(event)
  }

  return (
    <>
      <ModalComponent open={openModal} handleClose={handleClose} tsId={tsId} />

      <ButtonComponent
        variant="outlined"
        color="secondary"
        onClick={handleClick}
        id="positioned-button"
        {...props}
      >
        {withPulse && <span className="onboarding__pulse"></span>}
        {title}
        {props.children}
      </ButtonComponent>
    </>
  )
}

export const LoadDocument = (props) => (
  <CreateButton
    ButtonComponent={Button}
    ModalComponent={ModalCreateDocument}
    AnalyticsEventTrigger={() => AnalyticsEvent.new_doc_start('no')}
    {...props}
  />
)

export const LoadDocumentIcon = ({ src, ...props }) => (
  <CreateButton
    tsId={props.tsId}
    ButtonComponent={IconButton}
    ModalComponent={ModalCreateDocument}
    AnalyticsEventTrigger={() => AnalyticsEvent.new_doc_start('no')}
    title={src}
    {...props}
  />
)
