import img_close from 'assets/img/cancel.svg'
import down from 'assets/img/down.svg'
import down_second from 'assets/img/down_second.svg'

import { Box, Button, Divider, Menu } from '@mui/material'
import { useDocumentSendDriver } from 'components/button/hooks/useDocumentSendDriver'
import { useDocumentSignDriver } from 'components/button/hooks/useDocumentSignDriver'
import { MenuItemButton } from 'components/menu-item/menu-item-button'
import { AnalyticsEvent } from 'config/analytics/segment.com/events'
import { ModalWorkflowLauncher } from 'features/workflow-builder'
import { useFormik } from 'formik'
import {
  EDocumentStatus,
  ERecipientRole,
  ESignOptionType,
  EOnboardingAction,
} from 'helper/consts'
import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateOnboardingAction } from 'store/slices/userSlice'
import { startWorkflow } from 'store/slices/wfPostmanSlice'
import * as Yup from 'yup'
import { SignOptions } from './SignOptions'
import { DocumentSentMenu } from './components/document-sent-menu'
import { SendMenu } from './components/send-menu'
import { ShowOrGenerateLink } from './send-link'
import { SignMobile } from './sign-mobile'

export const IconImage = ({ src, ...props }) => {
  return (
    <Box
      component={'img'}
      width={'20px'}
      height={'20px'}
      style={{ marginRight: '8px' }}
      src={src}
      alt=""
      {...props}
    />
  )
}

const isDocumentSentOrCompleted = (status) =>
  status === EDocumentStatus.SENT || status === EDocumentStatus.COMPLETED

const getButtonVariant = ({ sign, status }) => {
  if (!sign.isMeSign && status !== EDocumentStatus.SENT) {
    return isDocumentSentOrCompleted(status) ? 'outlined' : 'contained'
  }
  return 'outlined'
}

const getButtonText = ({ sign, status }) => {
  if (!sign.isMeSign && status !== EDocumentStatus.SENT) {
    return 'Отправить'
  }
  if (sign.isMeSign === ERecipientRole.signer_rk) return 'Подписать'
  if (sign.isMeSign === ERecipientRole.approver_rk) return 'Согласовать'
  return 'Отправлен'
}

const getButtonIcon = ({ sign, status }) => {
  if (!sign.isMeSign && status !== EDocumentStatus.SENT) {
    return isMobile ? null : (
      <img src={down} alt="" width={'20px'} height={'20px'} />
    )
  }
  return <img src={down_second} alt="" width={'20px'} height={'20px'} />
}

const getButtonColor = ({ sign, status }) => {
  if (!sign.isMeSign && status !== EDocumentStatus.SENT) {
    return isDocumentSentOrCompleted(status) ? 'secondary' : 'primary'
  }
  return 'secondary'
}

export const SendButtonDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const sendDocButtonRef = useRef(null)
  const { user } = useSelector((state: RootState) => state.user, shallowEqual)
  const dispatch = useDispatch()

  const { document }: IApplicationState = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )
  const { sign, loading } = useSelector(
    (state: RootState) => state.wfPostman,
    shallowEqual
  )
  const [createLink, setCreateLink] = useState(false)

  const open = Boolean(anchorEl)
  const [openModal, setOpenModal] = useState(false)

  const [onlyMe, setOnlyMe] = useState(false)

  const [option, setOption] = useState('')
  const [withPulse, setWithPulse] = useState(false)

  const formik = useFormik({
    initialValues: {
      number: 1,
    },
    validationSchema: Yup.object().shape({
      number: Yup.number()
        .max(1000, 'Максимум 1000')
        .min(1, 'Минимум 1')
        .required('Обязательное поле'),
    }),
    onSubmit: async (values) => {
      dispatch(
        startWorkflow({
          id: document.id,
          documentType: document.type,
          email: user.email,
          status: document.status as EDocumentStatus,
          limit: values.number,
          option: ESignOptionType.LINK,
        }) as any
      )

      if (driverObj) {
        dispatch(
          updateOnboardingAction({
            name: EOnboardingAction.DOCUMENT_SEND,
          }) as any
        )
        setTimeout(() => {
          driverObj.moveNext()
        }, 200)
      }
    },
  })

  const { driverObj } = useDocumentSendDriver({
    sendDocButtonRef,
    formik,
    setCreateLink,
    setWithPulse,
  })

  const { driverObj: driverSignObject } = useDocumentSignDriver({
    sendDocButtonRef,
  })

  useEffect(() => {
    if (driverObj) {
      driverObj.drive()
      setWithPulse(true)
    }
    return () => {
      driverObj?.destroy()
    }
  }, [driverObj])

  useEffect(() => {
    if (driverSignObject) {
      setTimeout(() => {
        driverSignObject.drive()
      }, 200)
    }
    return () => {
      driverSignObject?.destroy()
    }
  }, [driverSignObject])

  const handleClick = async (event: any) => {
    const currentTarget = event.currentTarget

    if (
      document.status === EDocumentStatus.DRAFT ||
      document.status === EDocumentStatus.REVOKED ||
      document.status === EDocumentStatus.ARCHIVED
    ) {
      AnalyticsEvent.send_doc_start('no')
      if (driverObj) {
        setWithPulse(false)
        setTimeout(() => {
          driverObj.moveNext()
        }, 200)
      }
    }

    if (document.status === EDocumentStatus.SENT) {
      AnalyticsEvent.sign_doc_start('no')
      driverSignObject &&
        setTimeout(() => {
          driverSignObject.moveNext()
        }, 200)
    }
    setAnchorEl(currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOnlyMe(false)
    setOption('')
    setCreateLink(false)
  }

  return (
    <>
      <Box>
        {sign.isMeSign && isMobile ? (
          <SignMobile disabled={false} />
        ) : (
          <Button
            disabled={!document.id || loading.info}
            ref={sendDocButtonRef}
            id={'send-doc-button'}
            variant={getButtonVariant({ sign, status: document.status })}
            color={getButtonColor({ sign, status: document.status })}
            onClick={handleClick}
            sx={{
              zIndex: 20000,
            }}
            endIcon={getButtonIcon({ sign, status: document.status })}
          >
            {getButtonText({ sign, status: document.status })}
            {!sign.isMeSign &&
              document.status !== EDocumentStatus.SENT &&
              withPulse && <span className="onboarding__pulse"></span>}
          </Button>
        )}
      </Box>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            style: {
              marginTop: '4px',
              maxHeight: '100%',
            },
            sx: {
              minWidth: '280px',
              width: { xs: 'calc(100vw - 32px)', sm: 'auto' },
              maxWidth: { xs: '360px', sm: '320px' },
            },
          },
        }}
      >
        <Box>
          {isDocumentSentOrCompleted(document.status) ? (
            <DocumentSentMenu
              handleClose={handleClose}
              setOption={setOption}
              option={option}
              formik={formik}
              driverObj={driverSignObject}
            />
          ) : (
            <>
              {!onlyMe && !createLink && (
                <>
                  <SendMenu
                    handleClose={handleClose}
                    setOnlyMe={setOnlyMe}
                    setCreateLink={setCreateLink}
                    setOpenModal={setOpenModal}
                    driverObj={driverObj}
                  />
                </>
              )}
              {onlyMe && <SignOptions setOption={setOption} option={option} />}
              {document.status !== EDocumentStatus.DRAFT &&
                document.status !== EDocumentStatus.REVOKED && (
                  <>
                    <Divider />
                    <MenuItemButton
                      onClick={handleClose}
                      srcImage={img_close}
                      title={'Отозвать документ'}
                    />
                  </>
                )}
            </>
          )}
        </Box>

        {createLink && document.status !== EDocumentStatus.SENT && (
          <ShowOrGenerateLink formik={formik} driverObj={driverSignObject} />
        )}
      </Menu>
      {!!document.id && (
        <ModalWorkflowLauncher
          open={openModal}
          handleClose={() => setOpenModal(false)}
        />
      )}
    </>
  )
}
