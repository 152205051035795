import { ROWS_PER_PAGE } from 'helper/consts'
import { useCallback, useRef, useState } from 'react'
import { useInView } from 'react-cool-inview'

interface UseInfiniteScrollArgs {
  getItems: (offset: number) => Promise<any>
  parseResponse: (response: any) => { documents: any[]; count: number }
}

export const useInfiniteScroll = ({
  getItems,
  parseResponse,
}: UseInfiniteScrollArgs) => {
  const [items, setItems] = useState<any[]>([])
  const [hasMore, setHasMore] = useState(false)
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)

  const pageToLoad = useRef(1)

  const { observe } = useInView({
    onEnter: () => {
      if (hasMore) loadItems()
    },
  })

  const loadItems = useCallback(async () => {
    setLoading(true)
    try {
      const res = await getItems(pageToLoad.current)
      const { documents, count } = parseResponse(res)

      setCount(count)
      setItems((prev) => [...prev, ...documents])
      setHasMore(count > Number(pageToLoad.current) * ROWS_PER_PAGE)
      pageToLoad.current += 1
    } finally {
      setLoading(false)
    }
  }, [getItems])

  const setPage = useCallback((page: number) => {
    pageToLoad.current = page
  }, [])

  return {
    items,
    hasMore,
    loadItems,
    page: pageToLoad.current,
    setPage,
    loadMoreRef: observe,
    loading,
    setItems,
    count,
  }
}
