import { EDocumentStatus } from "helper/consts"

// [K in TMenuTemplateActions as `can${Capitalize<string & K>}`]: (template: ITemplate) => boolean
export const restrictionsTemplate: {
  canEdit: (template: ITemplate) => boolean,
  canDublicate: (template: ITemplate) => boolean,
  canUse: (template: ITemplate) => boolean,
} = {
  canEdit: (template: ITemplate) => {
    if (template.status !== EDocumentStatus.READY) {
      return false
    }
    if (template.version == '1') {
      return false
    }
    return true
  },
  canDublicate: (template: ITemplate) => {
    if (template.version == '1') {
      return false
    }
    return true
  },
  canUse: (template: ITemplate) => {
    if (template.status !== EDocumentStatus.READY) {
      return false
    }
    return true
  },
}