import { Box } from '@mui/material'

export interface IBoxImgProps {
  src: string
  size?: string
}

export const BoxImg = ({ src, size }: IBoxImgProps) => {
  return <Box component={'img'} src={src} width={size} height={size} />
}
