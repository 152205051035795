import { Tab, Tabs, styled } from '@mui/material'
import { isMobile } from 'react-device-detect'

interface StyledTabProps {
  label: any
  disabled?: boolean
  icon?: any
  value?: any
}

export const StyledTabs = styled((props: any) => (
  <Tabs
    TabIndicatorProps={{
      sx: {
        background: '#1C1C1C',
      },
    }}
    {...props}
  />
))(() => ({
  position: 'relative',
  minHeight: 'auto',
  '&::before': {
    display: 'block',
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '1px',
    backgroundColor: 'var(--light-grayscale-border, #EDEDEC)',
  },
  '& .MuiTabs-scroller': {
    display: 'flex',
    alignItems: 'center',
    overflow: 'scroll !important',
    overflowX: isMobile ? 'scroll' : 'visible',
    overflowY: isMobile ? 'hidden' : 'visible',
    msOverflowStyle: 'none',
    height: '32px',
    minHeight: '32px',
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
}))

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }: any) => ({
  position: 'relative',
  borderRadius: '4px',
  textTransform: 'none',
  minHeight: '24px',
  minWidth: 0,
  color: '#3C4149',
  marginRight: theme.spacing(1),
  padding: '2px 8px',
  overflow: 'visible',
  '&.Mui-selected': {
    color: theme.palette.text.primary,
  },
  '&:hover': {
    background: '#F5F5F5',
  },
}))
