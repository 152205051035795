import img_document from 'assets/img/ic_document.svg'

import { Button, Box, Typography } from '@mui/material'
import { MessageSnackbar } from 'components'
import { SystemTemplatesUpload } from 'features/templates-list'
import { useState, useCallback, useContext, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getDocumentNameWithoutExtension, uploadFile } from 'utils/upload'
import { DropZoneBlockCreateDocument } from 'components/dropzone'
import { EOnboardingAction, ETemplateFilter } from 'helper/consts'
import { updateOnboardingAction } from 'store/slices/userSlice'
import { ModalCreateDocumentContext } from '../../hooks/useModalCreateDocumentContext'
import { queryDocumentsJoinToPDF } from 'service/documents'
import { isFilesTotalSizeValid } from '../../lib'
import { updateTemplates } from 'store/slices/templatesSlice'

const ButtonCreateDocumentEditor = ({
  tsId,
  handleClose,
}: {
  tsId: string
  handleClose?: () => any
}) => {
  const navigate = useNavigate()

  return (
    <Button
      onClick={(e) => {
        e.stopPropagation()
        handleClose && handleClose()
        navigate(`/teamspace/${tsId}/document-editor/new`)
      }}
      fullWidth
      color="secondary"
      variant="outlined"
      sx={{
        justifyContent: 'start',
      }}
    >
      <Box
        component={'img'}
        src={img_document}
        width={20}
        height={20}
        mr="4px"
      />
      Создать новый
    </Button>
  )
}

export const ModalContentBlockCreateDocumentDefault = ({
  tsId,
  handleClose,
  driverObj,
}: {
  tsId: string
  handleClose: () => any
  driverObj: any
}) => {
  const { switchToStepJoinFiles } = useContext(ModalCreateDocumentContext)
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { onboarding } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState('')

  useEffect(() => {
    dispatch(
      updateTemplates({ ts_id: tsId, filter: ETemplateFilter.ALL }) as any
    )
  }, [])

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!acceptedFiles[0]) return

      if (acceptedFiles.length > 1) {
        if (!isFilesTotalSizeValid(acceptedFiles)) {
          setError('Общий размер файлов превышает ограничение в 15 МБ')
          return
        }

        switchToStepJoinFiles(acceptedFiles)
      } else {
        try {
          if (!isFilesTotalSizeValid(acceptedFiles)) {
            setError('Общий размер файла превышает ограничение в 15 МБ')
            return
          }

          setLoading(true)
          let file = acceptedFiles[0]
          const fname = getDocumentNameWithoutExtension(file.name)

          if (acceptedFiles[0].type !== 'application/pdf') {
            const res = await queryDocumentsJoinToPDF([file])
            file = new File([res.data], fname, {
              type: 'application/pdf',
            })
          }

          const id = await uploadFile({
            file: file,
            name: fname,
            ts_id: tsId,
          })
          setLoading(false)
          handleClose()

          if (!onboarding.document_create?.finished || driverObj) {
            dispatch(
              updateOnboardingAction({
                name: EOnboardingAction.DOCUMENT_CREATE,
              }) as any
            )
            driverObj && driverObj.destroy()
          }

          navigate(`/teamspace/${tsId}/documents/${id}`)
        } catch (err) {
          setError((err as Error).message)
          setLoading(false)
        }
      }
    },
    [
      tsId,
      onboarding.document_create,
      onboarding.document_create?.finished,
      driverObj,
    ]
  )

  const onDropRejected = useCallback(() => {
    setError(
      'Принимаются файлы только определенных форматов и весом не более 15MB'
    )
  }, [])

  return (
    <>
      {error && (
        <MessageSnackbar
          message={error}
          clearMessage={() => setError('')}
          severity="error"
        />
      )}
      <Box>
        <Box>
          <ButtonCreateDocumentEditor tsId={tsId} handleClose={handleClose} />
        </Box>

        <Box mt={3}>
          <div id="dropzone">
            <Box>
              <Typography color="text.disabled">Загрузить файл</Typography>
            </Box>

            <Box mt={1}>
              <DropZoneBlockCreateDocument
                onDrop={onDrop}
                onDropRejected={onDropRejected}
                loading={loading}
              />
            </Box>
          </div>
        </Box>

        <Box mt={3}>
          <Box>
            <Typography color={'text.secondary'}>Создать из шаблона</Typography>
          </Box>

          <Box mt={1}>
            <SystemTemplatesUpload handleClose={handleClose} tsId={tsId} />
          </Box>
        </Box>
      </Box>
    </>
  )
}
