import { Box } from '@mui/material'
import { SettingsPreFilledWorkflowForTemplate } from 'features/workflow-builder/components/SettingsPreFilledWorkflowForTemplate'
import { useEffect } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import { loadManageTemplateSettings } from 'store/slices/manageTemplateSettings/loadManageTemplateSettings'
import { useAppSelector } from 'store/store'
import { TabContentSettingsSignMethods } from './TabContentSettingsSignMethods'

export const TabContentSettings = () => {
  const dispatch = useDispatch<any>()
  const { id } = useAppSelector((state) => state.template, shallowEqual)
  const { template_id } = useAppSelector(
    (state) => state.templateSettings,
    shallowEqual
  )

  useEffect(() => {
    if (template_id == id) return
    dispatch(loadManageTemplateSettings({ templateId: id }))
  }, [id])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '768px',
          margin: 'auto',
          width: 'calc(100% - 20px * 2)',
        }}
      >
        <TabContentSettingsSignMethods />
        <SettingsPreFilledWorkflowForTemplate />
      </Box>
    </>
  )
}
