import {
  Avatar,
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { CustomTooltip, StatusBadge } from 'components'
import { ChipGlobal } from 'components/chip/ChipGlobal'
import { Link as RouterLink } from 'react-router-dom'
import { convertDateToLocale } from 'utils/convertDateToLocale'
import { stringAvatar } from 'utils/stringAvatar'
import styles from './styles.module.css'

export const TableCompound = ({ children, ...props }: { children?: any }) => {
  return (
    <Table color="secondary" {...props}>
      {children}
    </Table>
  )
}

TableCompound.Head = ({ children, ...props }: { children?: any }) => {
  return <TableHead {...props}>{children}</TableHead>
}

TableCompound.Body = ({ children, ...props }: { children?: any }) => {
  return <TableBody {...props}>{children}</TableBody>
}

TableCompound.Row = ({ children, ...props }: { children?: any }) => {
  return <TableRow {...props}>{children}</TableRow>
}

TableCompound.RowHoverable = ({ children, ...props }: { children?: any }) => {
  return (
    <TableRow
      sx={{
        '&:hover': {
          background: '#F5F5F5',
        },
      }}
      {...props}
    >
      {children}
    </TableRow>
  )
}

TableCompound.Cell = ({
  children,
  className,
  ...props
}: {
  children?: any
  className?: any
}) => {
  return (
    <TableCell className={className} {...props}>
      {children}
    </TableCell>
  )
}

TableCompound.CellClickable = ({
  children,
  to,
  blank,
  className,
  ...props
}: {
  children?: any
  to?: string
  blank?: boolean
  className?: any
}) => {
  if (!to)
    return (
      <TableCell {...props} className={className}>
        {children}
      </TableCell>
    )

  return (
    <TableCell {...props} className={className}>
      <Link
        component={RouterLink}
        to={to}
        underline="none"
        color="inherit"
        sx={{ display: 'block', height: '100%' }}
        target={blank ? '_blank' : ''}
      >
        {children}
      </Link>
    </TableCell>
  )
}

TableCompound.HeadCellDocumentName = ({ ...props }) => {
  return (
    <TableCell className={styles.cell__document_name} {...props}>
      <Typography>Документ</Typography>
    </TableCell>
  )
}

TableCompound.HeadCellTemplateName = ({ ...props }) => {
  return (
    <TableCell className={styles.cell__document_name} {...props}>
      <Typography>Шаблон</Typography>
    </TableCell>
  )
}

TableCompound.HeadCellDocumentStatus = ({ ...props }) => {
  return (
    <TableCell className={styles.cell__document_status} {...props}>
      <Typography>Статус</Typography>
    </TableCell>
  )
}

TableCompound.HeadCellDocumentAuthor = ({ ...props }) => {
  return (
    <TableCell className={styles.cell__document_author} {...props}>
      <Typography>Автор</Typography>
    </TableCell>
  )
}

TableCompound.HeadCellDocumentLastModified = ({ ...props }) => {
  return (
    <TableCell className={styles.cell__document_status} {...props}>
      <Typography>Последнее изменение</Typography>
    </TableCell>
  )
}

TableCompound.HeadCellDocumentOptions = ({ ...props }) => {
  return (
    <TableCell className={styles.cell__options_button} {...props}></TableCell>
  )
}

TableCompound.BodyCellDocumentName = ({
  title,
  to,
  blank,
  ...props
}: {
  title: string
  to?: string
  blank?: boolean
}) => {
  return (
    <TableCompound.CellClickable
      to={to}
      blank={blank}
      className={styles.cell__document_name}
      {...props}
    >
      <Typography
        fontWeight={500}
        color={'text.primary'}
        sx={{
          wordBreak: 'keep-all',
          textWrap: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {title}
      </Typography>
    </TableCompound.CellClickable>
  )
}

TableCompound.BodyCellDocumentStatus = ({
  status,
  to,
  isShared,
  blank,
  ...props
}: {
  status: string
  to?: string
  isShared?: boolean
  blank?: boolean
}) => {
  return (
    <TableCompound.CellClickable
      to={to}
      blank={blank}
      className={styles.cell__document_status}
      {...props}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <StatusBadge status={status} />
        {isShared && (
          <CustomTooltip title="Документ опубликован">
            <Box sx={{ ml: '4px' }}>
              <ChipGlobal />
            </Box>
          </CustomTooltip>
        )}
      </Box>
    </TableCompound.CellClickable>
  )
}

TableCompound.BodyCellDocumentAuthorEmail = ({
  email,
  to,
  blank,
  image = '',
  ...props
}: {
  email: string
  to?: string
  blank?: boolean
  image?: string
}) => {
  return (
    <TableCompound.CellClickable
      to={to}
      blank={blank}
      className={styles.cell__document_author}
      {...props}
    >
      <Box display={'flex'} alignItems={'center'}>
        <Avatar
          src={image}
          {...stringAvatar(email)}
          sx={{
            width: 20,
            height: 20,
            backgroundColor: '#E7E7E6',
            border: '0.6px solid rgba(0, 0, 0, 0.1)',
            color: 'text.primary',
            fontSize: '14px',
            mr: 1,
          }}
        />

        <Typography
          whiteSpace={'nowrap'}
          overflow={'hidden'}
          textOverflow={'ellipsis'}
        >
          {email}
        </Typography>
      </Box>
    </TableCompound.CellClickable>
  )
}

TableCompound.BodyCellDocumentLastModified = ({
  lastActionAt,
  to,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  blank,
  ...props
}: {
  lastActionAt: string
  to?: string
  blank?: boolean
}) => {
  return (
    <TableCompound.CellClickable
      to={to}
      className={styles.cell__last_modified}
      {...{ sx: { color: '#3C4149 !important' } }}
      {...props}
    >
      {convertDateToLocale(lastActionAt)}
    </TableCompound.CellClickable>
  )
}

TableCompound.BodyCellDocumentOptions = ({ children, ...props }: any) => {
  return (
    <TableCompound.Cell
      className={styles.cell__options_button}
      {...{ sx: { textAlign: 'end', p: '4px 8px !important' } }}
      {...props}
    >
      {children}
    </TableCompound.Cell>
  )
}
