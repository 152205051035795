import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import { ListItemNode, ListNode } from '@lexical/list'
import { CodeHighlightNode, CodeNode } from '@lexical/code'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { VariableNode } from '../VariableNode'
import { TableNode as NewTableNode } from '../TableNode'
import { Klass, LexicalNode } from 'lexical'
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { PageBreakNode } from '../../custom/nodes/PageBreakNode'
import { ColumnsListNode } from '../../custom/nodes/ColumnsListNode'
import { ColumnsListItemNode } from '../../custom/nodes/ColumnsListNode/ColumnsListItemNode'

export const EditorNodes: Array<Klass<LexicalNode>> = [
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  CodeNode,
  CodeHighlightNode,
  NewTableNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  AutoLinkNode,
  LinkNode,
  VariableNode,
  HorizontalRuleNode,
  PageBreakNode,
  ColumnsListNode,
  ColumnsListItemNode,
]