import { createContext, useContext } from 'react'
import { useDocumentsChangePage } from './hooks/useDocumentsChangePage'
import { useInboxChangePage } from './hooks/useInboxChangePage'
import { useCampaignsChangePage } from './hooks/useCampaignsChangePage'
import { useCampaignsDocsChangePage } from './hooks/useCampaignsDocsChangePage'
import { IChangePageArgs } from 'components/pagination/types'

interface IChangePageContext {
  changePage: (args: IChangePageArgs) => void
}

interface ChangePageProviderProps {
  children: any
}

const ChangePageContext = createContext<IChangePageContext>(
  {} as IChangePageContext
)

export const DocumentsChangePageProvider: React.FC<ChangePageProviderProps> = ({
  children,
}) => {
  const value = useDocumentsChangePage()

  return (
    <ChangePageContext.Provider value={value}>
      {children}
    </ChangePageContext.Provider>
  )
}

export const CampaignDocumentsChangePageProvider: React.FC<
  ChangePageProviderProps
> = ({ children }) => {
  const value = useCampaignsDocsChangePage()

  return (
    <ChangePageContext.Provider value={value}>
      {children}
    </ChangePageContext.Provider>
  )
}

export const CampaignsChangePageProvider: React.FC<ChangePageProviderProps> = ({
  children,
}) => {
  const value = useCampaignsChangePage()

  return (
    <ChangePageContext.Provider value={value}>
      {children}
    </ChangePageContext.Provider>
  )
}

export const InboxChangePageProvider: React.FC<ChangePageProviderProps> = ({
  children,
}) => {
  const value = useInboxChangePage()

  return (
    <ChangePageContext.Provider value={value}>
      {children}
    </ChangePageContext.Provider>
  )
}

export const useChangePageContext = () => {
  const context = useContext(ChangePageContext)
  if (context === undefined || context === null) {
    throw new Error(
      `useChangePageContext must be called within ChangePageContext`
    )
  }
  return context
}
