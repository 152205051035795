import * as Yup from 'yup'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const validationSchemaPhoneNumber = Yup.object().shape({
  name: Yup.string().required('Обязательное поле'),
  actor_id: Yup.number().required('Обязательное поле'),
  default_value: Yup.string().matches(
    phoneRegExp,
    'Неверный формат номера телефона'
  ),
})
