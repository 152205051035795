import img_telegram from 'assets/img/socials/telegram.svg'

import { Box, Button, Link as MuiLink } from '@mui/material'

export const ButtonTelegram = ({
  username,
  fullWidth,
}: {
  username?: string
  fullWidth?: boolean
}) => {
  return (
    <MuiLink
      href={`https://t.me/${username ?? 'doodocskz'}`}
      target="_blank"
      style={{
        width: fullWidth ? '100%' : 'auto',
      }}
    >
      <Button
        fullWidth={fullWidth}
        color="secondary"
        variant="outlined"
        startIcon={
          <Box
            component={'img'}
            src={img_telegram}
            width={'20px'}
            height={'20px'}
          />
        }
      >
        Telegram
      </Button>
    </MuiLink>
  )
}
