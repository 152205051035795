import { Box, TableCell, Typography } from '@mui/material'
import img_address_gray from 'assets/img/variables/address_gray.svg'
import img_arrow_dropdown_circle_gray from 'assets/img/variables/arrow_dropdown_circle_gray.svg'
import img_calendar_gray from 'assets/img/variables/calendar_gray.svg'
import img_checkbox_gray from 'assets/img/variables/checkbox_gray.svg'
import img_email_gray from 'assets/img/variables/email_gray.svg'
import img_long_text_gray from 'assets/img/variables/long_text_gray.svg'
import img_numbers_gray from 'assets/img/variables/numbers_gray.svg'
import img_phone_gray from 'assets/img/variables/phone_gray.svg'
import img_profile_gray from 'assets/img/variables/profile_gray.svg'
import img_sharp_gray from 'assets/img/variables/sharp_gray.svg'
import img_short_text_gray from 'assets/img/variables/short_text_gray.svg'

import {
  ECustomVariableType,
  ESystemVariableType,
} from 'features/text-editors/lexical/plugins-custom/ParameterPickerPlugin/helpers'

const srcVariableIcons = {
  [ESystemVariableType.INPUT_TEXT]: img_short_text_gray,
  [ESystemVariableType.TEXT_AREA]: img_long_text_gray,
  [ECustomVariableType.INPUT_TEXT_ADDRESS]: img_address_gray,
  [ESystemVariableType.DROPDOWN]: img_arrow_dropdown_circle_gray,
  [ESystemVariableType.INPUT_DATE]: img_calendar_gray,
  [ESystemVariableType.CHECKBOX]: img_checkbox_gray,
  [ESystemVariableType.INPUT_EMAIL]: img_email_gray,
  [ESystemVariableType.INPUT_NUMBER]: img_numbers_gray,
  [ESystemVariableType.INPUT_PHONE_NUMBER]: img_phone_gray,

  [ECustomVariableType.INPUT_TEXT_BIN]: img_sharp_gray,
  [ECustomVariableType.INPUT_TEXT_IIN]: img_sharp_gray,

  [ECustomVariableType.INPUT_TEXT_FATHER_NAME]: img_profile_gray,
  [ECustomVariableType.INPUT_TEXT_LAST_NAME]: img_profile_gray,
  [ECustomVariableType.INPUT_TEXT_NAME]: img_profile_gray,
}

export const TableHeaderCellVariable = ({
  name,
  variableType,
}: {
  name: string
  variableType: ESystemVariableType | ECustomVariableType | string
}) => {
  return (
    <TableCell>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '4px',
        }}
      >
        <Box
          component={'img'}
          sizes="16px"
          src={srcVariableIcons[variableType] || img_short_text_gray}
        />
        <Typography variant="paragraph3">{name}</Typography>
      </Box>
    </TableCell>
  )
}
