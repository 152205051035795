import { ErrorHelper } from 'components/input/text-input'
import { CustomAvatar } from 'pages/profile/components/profile-form'
import { Box, Button, Typography, TextField } from '@mui/material'
import { useFormikContext } from 'formik'
import { useImageUploader } from 'hooks/useImageUploader'

export const SetupWsDetailsStep = () => {
  return (
    <>
      <Box mt="32px">
        <UploadLogo />
      </Box>
      <Box mt="24px">
        <WorkspaceNameForm />
      </Box>
    </>
  )
}

export const UploadLogo = () => {
  const { values } = useFormikContext<{
    logo_string: string
  }>()

  const {
    image,
    loading,
    errorMessage,
    inputRef,
    handleImageChange,
    handleClick,
  } = useImageUploader(values.logo_string)

  return (
    <Box display={'flex'} flexDirection="column" alignItems={'center'}>
      <CustomAvatar
        loading={loading}
        src={image}
        handleClick={handleClick}
        editable={true}
        rounded={true}
      />
      {errorMessage && <ErrorHelper error={errorMessage} />}

      <Button
        size="small"
        color="secondary"
        variant="outlined"
        sx={{ mt: '8px' }}
      >
        <label htmlFor="avatar-upload"> Загрузить логотип</label>
        <input
          ref={inputRef}
          id="avatar-upload"
          name="avatar"
          type="file"
          accept=".png,.jpg,.jpeg"
          style={{ display: 'none' }}
          onChange={(e) => handleImageChange(e, 'logo')}
        />
      </Button>
    </Box>
  )
}

export const WorkspaceNameForm = () => {
  const { values, touched, handleChange, errors } = useFormikContext<{
    w_name: string
  }>()

  return (
    <>
      <Typography variant="body1" fontWeight={500} mt="24px">
        Название пространства
      </Typography>
      <Box pb={'2px'} mb={'2px'}>
        <Typography variant="body2" color={'text.secondary'}>
          Что-то, что поможет вам отличить это пространство от других. Видно
          только членам вашей команды
        </Typography>
      </Box>
      <TextField
        defaultValue={values.w_name}
        onChange={handleChange}
        name="w_name"
        placeholder="Введите название пространства..."
        variant="outlined"
        fullWidth
        error={errors.w_name && touched.w_name ? true : false}
      />
    </>
  )
}
