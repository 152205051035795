// import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './localization/i18n'
import './assets/style/index.css'
import './assets/style/onboarding.css'
import 'driver.js/dist/driver.css'

import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://2d76b7dd2f8dec15e1e72039240461c6@o4507536640704512.ingest.us.sentry.io/4507536643850240',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: import.meta.env.MODE,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/api.doodocs\.kz\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)
