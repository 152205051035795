import { CustomTooltip } from '..'

export interface ITooltipProp {
  text: string
  placement?: string
}

export const TooltipLayout = ({
  tooltip,
  children,
}: {
  tooltip?: ITooltipProp
  children: React.ReactNode
}) => {
  if (tooltip) {
    return (
      <CustomTooltip title={tooltip.text} placement={tooltip.placement}>
        {children}
      </CustomTooltip>
    )
  }

  return children
}
