import { Table, TableBody } from '@mui/material'
import { useAppSelector } from 'store/store'
import { PaymentsTableRow } from './components/PaymentsTableRow'

export const PaymentHistoryTable = () => {
  const { payments } = useAppSelector((state) => state.wsPlan)

  return (
    <>
      <Table>
        <TableBody>
          {payments?.map((payment, index) => (
            <PaymentsTableRow key={`payment-row-${index}`} payment={payment} />
          ))}
        </TableBody>
      </Table>
    </>
  )
}
