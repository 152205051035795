import { ETemplateFilter } from 'helper/consts'
import { useMemo } from 'react'
import { StandardTemplatesTable } from './components/StandardTemplatesTable'
import { shallowEqual } from 'react-redux'
import { useAppSelector } from 'store/store'
import { DeletedTemplatesTable } from './components/DeletedTemplatesTable'

export const TemplatesTable = () => {
  const { filter } = useAppSelector((state) => state.templates, shallowEqual)

  const renderedTable = useMemo(() => {
    switch (filter) {
      case ETemplateFilter.DELETED:
        return <DeletedTemplatesTable />
      default:
        return <StandardTemplatesTable />
    }
  }, [filter])

  return <>{renderedTable}</>
}
