import { Box, Typography } from '@mui/material'
import { StatusBadge } from 'components'
import { useSelector, shallowEqual } from 'react-redux'
import { HistoryDict, HistoryNames } from 'helper/consts'
import account from 'assets/img/account.svg'
import { IconImage } from 'pages/document-preview/components/send-sign-button/button-dropdown'
import { SidebarLayout } from 'layout/sidebar-layout'
import { convertDateToLocale } from 'utils/convertDateToLocale'

const ProvidersDict = {
  nca: 'NCALayer',
  did: 'DID',
}

export const Details = ({ handleClose }) => {
  const { user }: IUserState = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  )

  const { document }: IApplicationState = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )

  const { name: name_dr, status: status_dr } = useSelector(
    (state: RootState) => state.template,
    shallowEqual
  )
  const sentEmailAuthorOrClient = ({ name, attrs }) => {
    switch (name) {
      case HistoryNames.EMAIL_DOCUMENT_WITH_SIGNATURES:
        return attrs.email === user.email
          ? 'Автору отправлен email с подписями и документом'
          : 'Клиенту отправлен email с подписями и документом'
      case HistoryNames.DOCUMENT_CREATE:
        return (
          <>
            <span style={{ textTransform: 'lowercase' }}>
              {attrs && attrs['email']} {HistoryDict[name]}
            </span>
          </>
        )

      default:
        return HistoryDict[name]
    }
  }

  const showHistoryAttrs = ({ name, attrs }) => {
    switch (name) {
      case HistoryNames.SIGN_USER:
      case HistoryNames.SIGN_CLIENT:
        return (
          <>
            <span style={{ fontWeight: 600 }}>
              {!ProvidersDict[attrs['provider']]
                ? attrs['provider']
                : ProvidersDict[attrs['provider']]}
            </span>
          </>
        )
      case HistoryNames.EMAIL_SENT:
      case HistoryNames.EMAIL_DOCUMENT_WITH_SIGNATURES:
      case HistoryNames.EMAIL_DOCUMENT_REVOKED:
        return (
          <>
            на{' '}
            <span style={{ fontWeight: 600, textTransform: 'lowercase' }}>
              {attrs['email']}
            </span>
          </>
        )

      default:
        return ''
    }
  }

  return (
    <SidebarLayout
      header={{
        handleClose: handleClose,
        title: 'Информация о документе',
      }}
    >
      <Box display={'flex'} p="6px 16px" mt="12px">
        <Typography color={'text.disabled'} mr="8px">
          Документ
        </Typography>
        <Typography sx={{ wordBreak: 'break-word' }}>
          {document.name || name_dr}
        </Typography>
      </Box>
      <Box display={'flex'} p="6px 16px">
        <Typography color={'text.disabled'} mr="8px">
          Cтатус
        </Typography>
        <StatusBadge status={document.status || status_dr} />
      </Box>
      <Box display={'flex'} p="6px 16px">
        <Typography color={'text.disabled'} mr="8px">
          Дата создания
        </Typography>
        <Typography variant="body1">
          {document.history &&
            document.history[0] &&
            document.history[0].created_at &&
            convertDateToLocale(document.history[0].created_at)}
        </Typography>
      </Box>
      <Box p="6px 16px" mt={'24px'}>
        <Typography variant="body1" fontWeight={500}>
          История действий
        </Typography>
      </Box>

      {document.history &&
        document.history.map((item, i) => (
          <Box display={'flex'} key={i} p="4px 16px">
            <IconImage
              src={account}
              sx={{
                width: {
                  xs: '16px',
                  sm: '20px',
                },
                height: {
                  xs: '16px',
                  sm: '20px',
                },
                mt: '4px',
              }}
            />
            <Box>
              <Typography variant="body1">
                {sentEmailAuthorOrClient(item)}{' '}
                {item.attrs && showHistoryAttrs(item)}{' '}
              </Typography>
              <Typography
                key={i}
                variant="body2"
                color={'text.disabled'}
                sx={{
                  fontSize: {
                    xs: '12px',
                    sm: '14px',
                  },
                  lineHeight: { xs: '16px', sm: '20px' },
                }}
              >
                {convertDateToLocale(item.created_at)}
              </Typography>
            </Box>
          </Box>
        ))}
    </SidebarLayout>
  )
}
