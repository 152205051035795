import { AxiosError } from 'axios'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { challengeEmail, getUserExists } from 'service/auth'
import { authError } from 'store/slices/userSlice'

export const useEmailSubmit = () => {
  const [searchParams] = useSearchParams()
  const [loadingEmail, setLoadingEmail] = useState(false)
  const [showCode, setShowCode] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleEmailSubmit = async (values: { email: string }) => {
    searchParams.set('email', values.email)

    try {
      setLoadingEmail(true)
      await getUserExists(values.email)
      await challengeEmail(values.email)
      setShowCode(true)
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 404)
          navigate(`/setup/onboarding?${searchParams.toString()}`)
        else dispatch(authError(error.message) as any)
      }
    } finally {
      setLoadingEmail(false)
    }
  }

  return { handleEmailSubmit, showCode, loadingEmail }
}
