import { Box } from '@mui/material'
import { plansInfo } from 'pages/plan/types'
import { PlanCard } from '../PlanCard'

export const PlanCards = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        paddingBottom: '24px',
      }}
    >
      {plansInfo.map((plan) => (
        <PlanCard plan={plan} key={plan.name} />
      ))}
    </Box>
  )
}
