import { Box } from '@mui/material'
import { TextInput } from 'components'

export interface ICheckboxFiledAttrs {
  default_value: boolean
  text: string
}

type FieldName = keyof ICheckboxFiledAttrs

const configArray: Array<{
  name: FieldName
  label?: string
  type: string
  dependsOn?: FieldName
  description?: string
  options?: Array<{ label: string; description?: string }>
}> = [{ name: 'text', label: 'Текст чекбокса', type: 'text' }]

export const checkboxFiledinitialValues: ICheckboxFiledAttrs = {
  default_value: false,
  text: '',
}

export const CheckboxForm = () => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      {configArray.map((field) => (
        <FieldRenderer key={field.name} field={field} />
      ))}
    </Box>
  )
}

const FieldRenderer = ({ field }) => {
  if (field.type === 'text') {
    return (
      <Box p="6px 16px" key={field.name}>
        <TextInput
          name={field.name}
          label={field.label}
          type={field.type}
          fullWidth
        />
      </Box>
    )
  }

  return null
}
