import { TextField, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'

interface EditableInputProps {
  defaultValue?: string
  value?: string
  onBlur?: (value: string) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onChange?: (value: string) => void
  showClose?: boolean
  handleClose?: (event: React.MouseEvent<HTMLButtonElement>) => void
  autoFocus?: boolean
}

export const EditableInput: React.FC<EditableInputProps> = ({
  defaultValue = '',
  value,
  onBlur,
  onKeyDown,
  onChange,
  showClose = true,
  handleClose,
  autoFocus = false,
}) => {
  const [inputValue] = useState<string>(defaultValue)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value)
    }
  }

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e.target.value)
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        value={value || inputValue}
        fullWidth
        onBlur={handleInputBlur}
        onKeyDown={onKeyDown}
        onChange={handleInputChange}
        autoFocus={autoFocus}
      />
      {showClose && (
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ ml: '10px', p: '4px' }}
        >
          <CloseIcon sx={{ width: '16px', height: '16px' }} />
        </IconButton>
      )}
    </div>
  )
}
