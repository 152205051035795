import { Box, Menu } from '@mui/material'
import { LocalStorageReserve } from 'helper/consts'
import {
  $applyNodeReplacement,
  COMMAND_PRIORITY_HIGH,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  DecoratorNode,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical'
import cloneDeep from 'lodash/cloneDeep'
import { useRef, useState } from 'react'
import { getLocalStorageData } from 'utils/getLocalStorageData'
import { ESystemVariableType } from '../plugins-custom/ParameterPickerPlugin/helpers'
import { actorColors } from '../plugins-custom/ParameterPickerPlugin/helpers/consts/actorsConfig'
import { InsertNewVariableMenu } from '../plugins-custom/VariablePlugin/components/insert-new-variable-menu'
import {
  IMenuFormRef,
  InsertVariableMenuMode,
} from '../plugins-custom/VariablePlugin/components/insert-new-variable-menu/helpers/types'

export type SerializedVariableNode = Spread<
  {
    type: 'variable'
    version: 1
    name: string
    id: string
    additionalProps: any
  },
  SerializedLexicalNode
>

function convertVariableElement(
  domNode: HTMLElement
): DOMConversionOutput | null {
  const textContent = domNode.textContent
  const id = domNode.classList.length > 0 ? domNode.classList[1] : ''

  let props = {}

  try {
    props = JSON.parse(
      domNode.getAttribute('data-lexical-variable-meta') || '{}'
    )
  } catch (e) {
    console.error('Error parsing JSON from attribute:', e)
  }

  let savedLocalStorageData = getLocalStorageData(
    LocalStorageReserve.variableProps
  )

  try {
    savedLocalStorageData[id] = { ...props, ...savedLocalStorageData[id] }

    localStorage.setItem(
      LocalStorageReserve.variableProps,
      JSON.stringify(savedLocalStorageData)
    )
  } catch (e) {
    console.error('Error setting JSON to localStorage:', e)
  }

  if (textContent !== null) {
    const node = $createVariableNode({
      name: savedLocalStorageData[id]?.name,
      id,
      props: savedLocalStorageData[id],
    })

    return {
      node,
    }
  }

  return null
}
export class VariableNode extends DecoratorNode<null> {
  __additionalProps: any
  __name: string
  __id: string

  static getType(): string {
    return 'variable'
  }

  setAdditionalProps(value: any): void {
    this.__additionalProps = value
  }

  setName(value: any): void {
    this.__name = value
  }

  static clone(node: VariableNode): VariableNode {
    const clonedAdditionalProps = cloneDeep(node.__additionalProps)
    return new VariableNode(
      node.__id,
      node.__name,
      node.__key,
      clonedAdditionalProps
    )
  }

  constructor(id: string, name: string, key?: NodeKey, props: Object = {}) {
    super(key)
    this.__name = name
    this.__id = id
    this.__additionalProps = props
  }

  exportJSON(): SerializedVariableNode {
    const savedLocalStorageData = getLocalStorageData(
      LocalStorageReserve.variableProps
    )

    return {
      type: 'variable',
      version: 1,
      name: this.__name,
      id: this.__id,
      additionalProps:
        savedLocalStorageData[this.__id] || this.__additionalProps,
    }
  }

  exportDOM(): DOMExportOutput {
    const savedLocalStorageData = getLocalStorageData(
      LocalStorageReserve.variableProps
    )
    const props = {
      ...savedLocalStorageData[this.__id],
      ...this.__additionalProps,
    }

    localStorage.setItem(
      LocalStorageReserve.variableProps,
      JSON.stringify({
        ...savedLocalStorageData,
        [props.id]: props,
      })
    )

    const createSpanElement = () => {
      const span = document.createElement('span')
      span.setAttribute('data-lexical-variable', 'true')
      span.setAttribute('data-lexical-variable-meta', JSON.stringify(props))
      return span
    }

    if (props.type === ESystemVariableType.CHECKBOX) {
      const wrapper = createSpanElement()
      const input = document.createElement('input')
      const label = document.createElement('label')

      const classes = ['form-variable']
      if (props.id) classes.push(props.id)
      if (props.actor_id) classes.push(`actorId-${props.actor_id}`)

      input.classList.add(...classes)

      input.type = 'checkbox'
      input.id = props.id
      input.disabled = true
      input.style.marginRight = '4px'

      label.htmlFor = props.id
      label.textContent = props.text

      wrapper.append(input, label)
      return { element: wrapper }
    } else {
      const span = createSpanElement()
      span.textContent = `{{ input '${this.__id}' }}`
      span.classList.add(
        'form-variable',
        `${this.__id}`,
        `actorId-${props.actor_id}`
      )
      return { element: span }
    }
  }
  static importDOM(): DOMConversionMap | null {
    return {
      input: (domNode: HTMLElement) => {
        if (
          !domNode.hasAttribute('data-lexical-variable') ||
          !domNode.hasAttribute('data-lexical-variable-meta')
        ) {
          return null
        }
        return {
          conversion: convertVariableElement,
          priority: COMMAND_PRIORITY_HIGH,
        }
      },
      span: (domNode: HTMLElement) => {
        if (
          !domNode.hasAttribute('data-lexical-variable') ||
          !domNode.hasAttribute('data-lexical-variable-meta')
        ) {
          return null
        }
        return {
          conversion: convertVariableElement,
          priority: COMMAND_PRIORITY_HIGH,
        }
      },
      label: (domNode: HTMLElement) => {
        if (
          !domNode.hasAttribute('data-lexical-variable') ||
          !domNode.hasAttribute('data-lexical-variable-meta')
        ) {
          return null
        }
        return {
          conversion: convertVariableElement,
          priority: COMMAND_PRIORITY_HIGH,
        }
      },
    }
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('div')
    dom.style.display = '-webkit-inline-box'
    return dom
  }

  updateDOM(): false {
    return false
  }

  static importJSON(serializedNode: SerializedVariableNode): VariableNode {
    const node = $createVariableNode({
      name: serializedNode.name,
      id: serializedNode.id,
      props: serializedNode.additionalProps,
    })
    return node
  }

  decorate(): any {
    // TODO: fix context (it has old value)
    return <ButtonWithMenu context={this} />
  }
}

export const ButtonWithMenu = ({ context }) => {
  const menuFormRef = useRef<IMenuFormRef | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
    if (menuFormRef.current) {
      menuFormRef.current.submitForm()
    }
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <Menu
        sx={{ p: '4px 20px', positon: 'absolute' }}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {
            sx: {
              minWidth: '164px',
              maxHeight: '400px !important',
            },
          },
        }}
      >
        <InsertNewVariableMenu
          ref={menuFormRef}
          mode={InsertVariableMenuMode.edit}
          variable={{
            data: {
              ...context.__additionalProps,
            },
          }}
          handleClose={() => setAnchorEl(null)}
          context={context}
        />
      </Menu>
      <Box
        component={'span'}
        onClick={handleClick}
        sx={{
          fontWeight: 'inherit',
          lineHeight: 'inherit',
          fontSize: 'inherit',

          cursor: 'pointer',
          minWidth: '10px',
          display: 'inline',
          padding: 0,
          backgroundColor: actorColors[context.__additionalProps.actor_id],
          '&:hover': {
            backgroundColor: actorColors[context.__additionalProps.actor_id],
          },
        }}
      >
        {context.__name}
      </Box>
    </>
  )
}

export function $createVariableNode({
  id,
  name,
  props = {},
}: {
  name: string
  id: string
  props: Object
}): VariableNode {
  const variableNode = new VariableNode(id, name, undefined, props)
  return $applyNodeReplacement(variableNode)
}

export function $isVariableNode(
  node: LexicalNode | null | undefined
): node is VariableNode {
  return node instanceof VariableNode
}
