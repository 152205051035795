import {
  Snackbar,
  Alert,
  styled,
  Typography,
  Box,
  IconButton,
} from '@mui/material'
import img_warning from 'assets/img/warning.svg'
import img_success from 'assets/img/check_circle.svg'
import img_error from 'assets/img/error.svg'
import img_info from 'assets/img/info-out.svg'
import img_close from 'assets/img/close-btn.svg'

const getImgSrcBySeverity = (severity: string) => {
  switch (severity) {
    case 'warning':
      return img_warning
    case 'success':
      return img_success
    case 'info':
      return img_info
    case 'error':
      return img_error
    default:
      return img_error
  }
}

export const AlertCustom = styled(Alert)(() => ({
  background: 'white',
  boxShadow:
    '0px 0px 1px rgba(28, 28, 28, 0.32), 0px 12px 24px rgba(150, 150, 150, 0.24)',
  padding: '8px',
  '& .MuiAlert-message': {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  width: '320px',
  alignItems: 'start',
  justifyContent: 'space-between',
  '& .MuiAlert-icon': {
    padding: '2px',
  },
}))

export const MessageSnackbar = ({
  message,
  clearMessage,
  severity,
  description = '',
  buttons = [] as JSX.Element[],
}) => {
  return (
    <Snackbar
      autoHideDuration={2000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={message ? true : false}
      onClose={() => clearMessage()}
      key={'bottom center'}
    >
      <AlertCustom
        sx={{ background: 'white' }}
        severity={severity}
        icon={
          <Box
            component={'img'}
            width={'16'}
            height={'16'}
            src={getImgSrcBySeverity(severity)}
          />
        }
      >
        <Box display={'flex'} flexDirection="column" alignItems={'baseline'}>
          <Typography fontWeight={500} variant="body1" color={'text.primary'}>
            {message}
          </Typography>
          {description ? (
            <Typography color={'text.secondary'} mt="2px">
              {description}
            </Typography>
          ) : null}
          {buttons.length ? (
            <Box mt={'4px'}>
              {buttons.map((btn, i) => (
                <Box key={`sneakbar-btn-${i}`} mt={'4px'} mr={'4px'}>
                  {btn}
                </Box>
              ))}
            </Box>
          ) : null}
        </Box>
        <IconButton
          children={<img src={img_close} height={'16px'} width="16px" alt="" />}
          onClick={() => clearMessage()}
          sx={{ alignSelf: 'baseline', p: '2px !important', ml: '4px' }}
        />
      </AlertCustom>
    </Snackbar>
  )
}
