import { Button, Typography } from '@mui/material'
import { MessageSnackbar } from 'components'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { useChangePageContext, useSelectedDocsContext } from 'context'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { shallowEqual, useSelector } from 'react-redux'
import { getPdfFileByLink } from 'service'
import { signDoodocs } from 'service/ncaService'
import { convertPdfToBase64 } from 'utils/pdfToBase64'
import { checkCmsSignature } from 'utils/urlToFIle'
import FormDialog from './formDialog'
import { useLocation } from 'react-router-dom'

const withTimeout = async (millis, promise) => {
  const timeout = new Promise((resolve, reject) =>
    setTimeout(() => reject(`Timed out after ${millis} ms.`), millis)
  )
  return Promise.race([promise, timeout])
}

export const MassSign = ({ disabled, title = '', ...props }) => {
  // todo: remove page dependency
  const location = useLocation()
  const isInbox = location.pathname === '/inbox'

  const [snackbar, setSnackbar] = useState({
    message: '',
    open: false,
    severity: 'error',
  })
  const { changePage } = useChangePageContext()
  const { selectedDocsIds, setSelectedDocsIds, isCampaignDocs } =
    useSelectedDocsContext()

  const [open, setOpen] = useState(false)
  const [openMobile, setOpenMobile] = useState(false)
  const [loading, setLoading] = useState(false)

  const { page, filter } = useSelector(
    (state: RootState) => state.documents,
    shallowEqual
  )

  const { filter: campaignFilter } = useSelector(
    (state: RootState) => state.campaignDocs,
    shallowEqual
  )

  const { completed, filter: inboxFilter } = useSelector(
    (state: RootState) => state.inbox,
    shallowEqual
  )

  const signOne = async (value) => {
    try {
      const { url } = await getPdfFileByLink({ link: value.link_pdf })
      const base64File = await convertPdfToBase64(url)

      const signPromise = signDoodocs({ base64File })
      const res = await withTimeout(5000, signPromise)

      const cmsResultPromise = checkCmsSignature({
        base64EncodedSignature: res.body,
        id: value.id,
        token: value.token,
        role: 'sign',
        isMassSign: true,
      })
      await withTimeout(5000, cmsResultPromise)
      return 1
    } catch (err) {
      return 0
    }
  }

  const signAll = async () => {
    setLoading(true)
    try {
      let count = 0
      let countErr = 0

      for (const value of selectedDocsIds.values()) {
        const result = await signOne(value)

        if (result === 1) {
          count++
        } else {
          countErr++
        }
      }

      successSign(count, countErr)
    } catch (err: any) {
      showSnackbar(
        err.message || `Мы не смогли подписать некоторые документы 🙁`,
        'warning'
      )
      setSelectedDocsIds(new Map())
      setOpen(false)
    } finally {
      setLoading(false)
    }
  }

  const successSign = (count, countErr) => {
    showSnackbar(
      `Документов подписано успешно: ${count} из ${countErr + count}`,
      'success'
    )
    setSelectedDocsIds(new Map())

    isInbox
      ? changePage({
          page: 0,
          params: {
            filter: inboxFilter,
            completed,
            query: '',
          },
        })
      : isCampaignDocs
      ? changePage({ page: 0, params: { filter: campaignFilter } })
      : changePage({ page, params: { filter } })
    setOpen(false)
  }

  const showSnackbar = (message, severity) => {
    setSnackbar({ message, open: true, severity })
  }

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false, message: '' })
  }

  const handleOpen = () => {
    if (isMobile) {
      setOpenMobile(true)
      return
    }
    setOpen(true)
  }

  return (
    <>
      <MessageSnackbar
        message={snackbar.message}
        clearMessage={closeSnackbar}
        severity={snackbar.severity}
      />
      <ModalCenterSelectableWithTitle
        open={openMobile}
        handleClose={() => setOpenMobile(false)}
        modalType="small"
        title={'Массовое подписание'}
        contentChild={
          <Typography variant="body1">
            Массовое подписание не поддерживается на мобильной версии
          </Typography>
        }
      />

      <FormDialog
        open={open}
        setOpen={setOpen}
        setLoading={setLoading}
        loading={loading}
        massSign={signAll}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
      <Button
        disabled={disabled || loading ? true : false}
        variant="contained"
        color="primary"
        onClick={handleOpen}
        {...props}
      >
        {title ? title : 'Подписать выбранные'}
      </Button>
    </>
  )
}
