import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { Grid, Typography, Divider } from '@mui/material'
import { Loading, MessageSnackbar } from 'components'
import { checkLogin, clearErrorsLogin } from 'store/slices/userSlice'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { config } from 'config'
import { parseCodeFromUrl } from 'utils/parseCodeFromUrl'
import { useSendCode } from '../hooks/useSendCode'
import { useEmailSubmit } from '../hooks/useEmailSubmit'
import { TermsAndPrivacyLinks } from './terms-and-privacy-links'
import { GoogleLoginButton, EmailInputForm, VerificationCodeForm } from '.'
import { LoginFormLayout } from 'layout/login-form'
import { getTokenGmail } from 'service/auth'

export const validationSchemas = [
  Yup.object().shape({
    email: Yup.string()
      .email('Некорректный Email')
      .required('Обязательное поле'),
  }),
  Yup.object().shape({
    code: Yup.string().required('Обязательное поле'),
  }),
]

export const LoginForm = ({ header }) => {
  const dispatch = useDispatch()
  const { inviteId } = useParams()
  const [searchParams] = useSearchParams()

  const { handleSendCode } = useSendCode()
  const { handleEmailSubmit, showCode, loadingEmail } = useEmailSubmit()

  const { authenticated, loading, user, error } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  )

  const handleClickGoogle = (event) => {
    event.preventDefault()
    const win: Window = window
    const link = config.GOOGLE_REDIRECT_URI
    win.location = link
  }

  const formik = useFormik({
    initialValues: {
      email: searchParams.get('email') || user.email || '',
    },
    validationSchema: validationSchemas[0],
    onSubmit: handleEmailSubmit,
  })

  const handleGmailCodeUrlAndSubmit = async (code) => {
    code &&
      !authenticated &&
      (await handleSendCode(getTokenGmail, { code, login_source: 'email' }))
  }

  useEffect(() => {
    const code = parseCodeFromUrl(location)
    handleGmailCodeUrlAndSubmit(code)

    if (!code) dispatch(checkLogin() as any)
  }, [authenticated])

  if (loading) return <Loading />
  return (
    <>
      <MessageSnackbar
        message={error}
        clearMessage={() => dispatch(clearErrorsLogin() as any)}
        severity="error"
      />
      <LoginFormLayout
        header={header}
        content={
          <>
            {!inviteId && (
              <>
                <GoogleLoginButton handleClick={handleClickGoogle} />
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            )}

            <Grid item xs={12} textAlign="start">
              <Typography variant="body1" mb="8px" fontWeight={500}>
                Email
              </Typography>
              <EmailInputForm
                formik={formik}
                showCode={showCode}
                loadingEmail={loadingEmail}
              />
            </Grid>

            {showCode && (
              <Grid item xs={12} textAlign="start">
                <Typography variant="body1" mb="8px" fontWeight={500}>
                  Временный код
                </Typography>
                <VerificationCodeForm email={formik.values.email} />
              </Grid>
            )}

            <TermsAndPrivacyLinks />
          </>
        }
      />
    </>
  )
}
