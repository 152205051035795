import { createSlice } from '@reduxjs/toolkit'
import { updateWorkflowRecipients } from './updateWorkflowRecipients'
import { deleteWorkflowRecipient } from './deleteWorkflowRecipient'

const initialState: IWorkflow = {
  loading: {
    editWorkflow: false,
  },
  errors: {
    editWorkflow: '',
  },
  resultAction: {
    editWorkflow: false,
  },
}

const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateWorkflowRecipients.pending, (state) => {
        state.loading.editWorkflow = true
        state.resultAction.editWorkflow = false
      })
      .addCase(updateWorkflowRecipients.fulfilled, (state) => {
        state.loading.editWorkflow = false
        state.resultAction.editWorkflow = true
      })
      .addCase(updateWorkflowRecipients.rejected, (state, action) => {
        state.loading.editWorkflow = false
        state.errors.editWorkflow =
          action.error.message || 'Ошибка обновления участников'
      })

      .addCase(deleteWorkflowRecipient.pending, (state) => {
        state.loading.editWorkflow = true
      })
      .addCase(deleteWorkflowRecipient.fulfilled, (state) => {
        state.loading.editWorkflow = false
      })
      .addCase(deleteWorkflowRecipient.rejected, (state, action) => {
        state.loading.editWorkflow = true
        state.errors.editWorkflow =
          action.error.message || 'Ошибка удаления участника'
      })
  },
})

export const { reset } = workflowSlice.actions

export default workflowSlice.reducer
