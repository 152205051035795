import { styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'

export const StyledChip = styled(Chip)(() => ({
  padding: '0',
  background: '#E9F5FF',
  borderRadius: '4px',
  height: '20px',
  fontSize: '14px',
  '& .MuiChip-label': {
    padding: '0 0 0 4px',
  },
  '.MuiChip-deleteIcon': {
    margin: 0,
  },
}))
