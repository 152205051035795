import img_variable_1 from 'assets/img/variable/1.svg'
import img_variable_2 from 'assets/img/variable/2.svg'
import img_variable_3 from 'assets/img/variable/3.svg'
import img_variable_4 from 'assets/img/variable/4.svg'
import img_variable_5 from 'assets/img/variable/5.svg'
import img_variable_6 from 'assets/img/variable/6.svg'
import img_variable_7 from 'assets/img/variable/7.svg'
import img_variable_8 from 'assets/img/variable/8.svg'

const img_variable = [
  img_variable_1,
  img_variable_2,
  img_variable_3,
  img_variable_4,
  img_variable_5,
  img_variable_6,
  img_variable_7,
  img_variable_8,
]

export const getFillerSrcByActorId = (actorId: number) => {
  if (actorId > 0) actorId = actorId - 1
  if (actorId >= img_variable.length) actorId = actorId % img_variable.length
  return img_variable[actorId]
}