import img_add from 'assets/img/add_white.svg'
import img_pencil from 'assets/img/ic_pencil.svg'

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material'
import { MessageSnackbar } from 'components'
import { useSnackbar } from 'components/snackbar/hook'
import { EDocumentStatus } from 'helper/consts'
import { useTemplateActions } from 'hooks/useTemplateActions'
import { EditTemplateName } from 'pages/template-editor/components/edit-template-name'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  fetchTemplateInfo,
  publishTemplateAction,
} from 'store/slices/templateSlice'
import { useAppSelector } from 'store/store'
import { IconButtonTemplateActions } from '../IconButtonTemplateActions'
import { restrictionsTemplate } from 'utils/restrictions/template'

export const ManageTemplateNameWithActions = () => {
  const template = useAppSelector((state) => state.template, shallowEqual)
  const { id, status, loading, is_private: isPrivate, name } = template
  const { selectedTeamspace } = useAppSelector(
    (state) => state.teamspace,
    shallowEqual
  )

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '8px',
          overflow: 'hidden',
        }}
      >
        <EditableTemplateName loading={loading.info} />
        <IconButtonTemplateActions
          disabled={loading.info}
          teamspaceId={selectedTeamspace?.id ?? ''}
          template={
            {
              name: name,
              id: id,
              is_private: isPrivate,
              status: template.status,
              version: template.version,
            } as ITemplate
          }
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
        }}
      >
        {restrictionsTemplate.canEdit({
          status: template.status,
          version: template.version,
        } as ITemplate) && (
          <ButtonEditTemplate templateId={id} disabled={loading.info} />
        )}
        {status == EDocumentStatus.DRAFT && (
          <ButtonPublishTemplate templateId={id} disabled={loading.info} />
        )}
        {status == EDocumentStatus.READY && (
          <ButtonUseTemplate templateId={id} disabled={loading.info} />
        )}
      </Box>
    </Box>
  )
}

const EditableTemplateName = ({ loading }: { loading?: boolean }) => {
  const template = useSelector(
    (state: RootState) => state.template,
    shallowEqual
  )
  const [isNameEditing, setIsNameEditing] = useState<boolean>(false)

  return (
    <Box
      sx={{
        overflow: 'hidden',
      }}
      onClick={() => {
        setIsNameEditing(true)
      }}
    >
      {!isNameEditing ? (
        <Typography
          variant="heading3"
          sx={{
            display: 'block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {loading ? <Skeleton width={200} height={24} /> : template.name}
        </Typography>
      ) : (
        <EditTemplateName
          fullWidth
          autoFocus
          onBlurCallback={() => setIsNameEditing(false)}
        />
      )}
    </Box>
  )
}

const ButtonEditTemplate = ({
  templateId,
  disabled,
}: {
  templateId: string
  disabled?: boolean
}) => {
  const { snackbar, showSnackbar } = useSnackbar()

  const { actionEditTemplate } = useTemplateActions({
    showSnackbar,
    templateId: templateId,
  })

  return (
    <>
      <Button
        color="secondary"
        sx={{
          gap: '4px',
        }}
        onClick={actionEditTemplate}
        disabled={disabled}
      >
        <Box component={'img'} src={img_pencil} sizes={'20'} />
        <Typography variant="h6" padding={0}>
          Изменить
        </Typography>
      </Button>
      <MessageSnackbar
        message={snackbar.message}
        clearMessage={snackbar.close}
        severity={snackbar.severity}
        buttons={snackbar.buttons}
      />
    </>
  )
}

const ButtonUseTemplate = ({
  templateId,
  disabled,
}: {
  templateId: string
  disabled?: boolean
}) => {
  const { tsId } = useParams()
  const navigate = useNavigate()

  const handleCLick = () => {
    navigate(`/teamspace/${tsId}/documents/draft?templateId=${templateId}`)
  }

  return (
    <>
      {isMobile ? (
        <IconButton onClick={handleCLick} sx={{ background: '#0085FF' }}>
          <Box component={'img'} src={img_add} width={'20px'} height={'20px'} />
        </IconButton>
      ) : (
        <Button
          color="primary"
          variant="contained"
          sx={{
            gap: '4px',
          }}
          onClick={handleCLick}
          disabled={disabled}
        >
          <Box component={'img'} src={img_add} sizes={'20'} />
          <Typography variant="h6" padding={0}>
            Использовать
          </Typography>
        </Button>
      )}
    </>
  )
}

const ButtonPublishTemplate = ({
  templateId,
  disabled,
}: {
  templateId: string
  disabled?: boolean
}) => {
  const dispatch = useDispatch<any>()
  const { loading } = useSelector((state: RootState) => state.template)

  const handleClick = async () => {
    await dispatch(
      publishTemplateAction({
        templateId,
      })
    )

    await dispatch(fetchTemplateInfo({ templateId }))
  }

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={handleClick}
        sx={{
          gap: '4px',
        }}
        disabled={disabled || loading.publish}
      >
        {loading.publish && <CircularProgress color="inherit" size={20} />}
        <Typography variant="h6" padding={0}>
          Опубликовать
        </Typography>
      </Button>
    </>
  )
}
