import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { Switcher, TextInput } from 'components'
import { DropdownWithChips } from './components'
import { useFormikContext } from 'formik'

export interface IDropdownOptionAttrs {
  index: number
  text: string
}

export interface IDropdownOption {
  id: string
  attrs: IDropdownOptionAttrs
}

export interface IDropdownSettings {
  multiple_choice: boolean
  options: IDropdownOptionAttrs[]
  default_value: string[]
}

type FieldName = keyof IDropdownSettings | 'has_default' | 'default_value'

const configArray: Array<{
  name: FieldName
  label?: string
  type: string
  dependsOn?: FieldName
  description?: string
}> = [
  {
    name: 'options',
    label: 'Список меню',
    type: 'select',
  },
  {
    name: 'has_default',
    label: 'Предварительно заполнено',
    type: 'switch',
    description: 'При создании документа поле будет заполнено этими данными',
  },
  {
    name: 'default_value',
    type: 'select',
    dependsOn: 'has_default',
  },
]

export const dropDownInitialValues: IDropdownSettings = {
  multiple_choice: false,
  options: [],
  default_value: [''],
}

export const DropdownForm = () => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      {configArray.map((field) => (
        <FieldRenderer key={field.name} field={field} />
      ))}
    </Box>
  )
}

const FieldRenderer = ({ field }) => {
  const { values } = useFormikContext<IDropdownSettings>()

  if (field.type === 'switch') {
    return (
      <Box p="6px 16px">
        <Switcher name={field.name} label={field.label} />
        {field.description && (
          <Typography variant={'body2'} color={'text.disabled'}>
            {field.description}
          </Typography>
        )}
      </Box>
    )
  }
  if (field.type === 'select' && field.name === 'options') {
    return (
      <Box p="6px 16px">
        <Typography variant="h6" color={'text.primary'} mb="4px">
          {field.label}
        </Typography>
        <DropdownWithChips />
      </Box>
    )
  }

  if (field.dependsOn && values[field.dependsOn]) {
    if (field.name === 'default_value') {
      return (
        <Box p="6px 16px" key={field.name}>
          <Typography variant="h6" color={'text.primary'} mb="4px">
            {field.label}
          </Typography>
          <SelectDefaultValue />
        </Box>
      )
    }
    return (
      <Box p="6px 16px">
        <TextInput
          name={field.name}
          label={field.label}
          type={field.type}
          fullWidth
        />
      </Box>
    )
  }
  return null
}

export const SelectDefaultValue = () => {
  const { values, setFieldValue } = useFormikContext<IDropdownSettings>()

  const handleDropdownChange = (event: SelectChangeEvent<string>) => {
    setFieldValue('default_value', [event.target.value])
  }

  return (
    <Select
      fullWidth
      name="default_value"
      value={values.default_value[0]}
      onChange={handleDropdownChange}
      sx={{ p: 0 }}
    >
      {values.options.map((option, i) => (
        <MenuItem key={i} value={option.text} sx={{ m: '2px 4px' }}>
          <Typography variant="body1">{option.text}</Typography>
        </MenuItem>
      ))}
    </Select>
  )
}
