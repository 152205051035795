import { signNCASignature } from 'service'

const urlToFile = async (url, filename, mimeType) => {
  const res = await fetch(url)
  const buf = await res.arrayBuffer()
  return new File([buf], filename, { type: mimeType })
}

export const checkCmsSignature = async ({
  base64EncodedSignature,
  id,
  token,
  role,
  isMassSign = false,
}) => {
  try {
    const file = await urlToFile(
      'data:text/plain;base64,' + base64EncodedSignature,
      'signature.pdf',
      'application/pdf'
    )

    const formData = new FormData()
    formData.append('signature', file)

    const res = await signNCASignature({
      data: formData,
      id,
      token,
      role,
      isMassSign,
    })
    return res
  } catch (err) {
    if (err instanceof Error) throw new Error(err.message)
  }
}
