import { Box, Typography } from '@mui/material'
import { AppBarContext } from 'context'
import { WSRolePermissions } from 'helper/roles'
import { useContext, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { SidebarBottomMenu } from '../helper-menu'
import { CreateGroup } from './create-group'
import { SwitchWorkspace } from './switchWorkspace'
import inbox from 'assets/img/inbox.svg'
import { MenuItemGrayNested } from '../menuNavigation'
import { isMobile } from 'react-device-detect'
import { Link, useLocation } from 'react-router-dom'
import { mainSidebarWidth } from '../drawer'
import { BannerSubscriptionPlanEnding } from './BannerSubscriptionPlanEnding'
import { CollapsableMenu } from './collapsable-menu'
import { CollapsablePrivateMenu } from './collapsable-private-menu'

export const InboxMenu = () => {
  const { pathname } = useLocation()
  const { user } = useSelector((state: RootState) => state.user, shallowEqual)
  const { setOpenDrawer } = useContext(AppBarContext)
  const { notifications_count } = useSelector(
    (state: RootState) => state.inbox,
    shallowEqual
  )

  const handleClick = () => {
    isMobile && setOpenDrawer(false)
  }

  return (
    <Box p="8px 0">
      <Box p="4px 16px">
        <Typography variant="caption" color={'text.disabled'}>
          {user.email}
        </Typography>
      </Box>
      <MenuItemGrayNested
        padding={'6px 8px 6px 16px'}
        key={`MenuItem-inbox`}
        value={'inbox'}
        onClick={handleClick}
        selected={pathname === '/inbox'}
        component={Link}
        to={'/inbox'}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Box display={'flex'} alignItems={'center'}>
            <img
              src={inbox}
              alt=""
              style={{ marginRight: '8px' }}
              width={'16px'}
              height={'16px'}
            />
            Входящие
          </Box>

          {notifications_count > 0 && (
            <Box
              p="0 4px"
              borderRadius={'4px'}
              sx={{ backgroundColor: '#6FBAFF' }}
            >
              <Typography
                variant="body1"
                fontSize={'14px!important'}
                display={'flex'}
              >
                {notifications_count}
              </Typography>
            </Box>
          )}
        </Box>
      </MenuItemGrayNested>
    </Box>
  )
}

export const MainMenu = () => {
  const [collapsed, setCollapsed] = useState(localStorage.getItem('collapsed'))

  const { teamspaces } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )

  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const handleToggleCollapse = (teamspace) => {
    localStorage.setItem(
      'collapsed',
      collapsed === teamspace.id ? null : teamspace.id
    )
    setCollapsed(collapsed === teamspace.id ? null : teamspace.id)
  }
  return (
    <>
      <Box
        display={'flex'}
        p="6px 0"
        flexDirection={'column'}
        id="drawer-header"
      >
        <SwitchWorkspace />
      </Box>

      <Box
        display={'flex'}
        height={'100%'}
        flexGrow={0}
        flexShrink={1}
        justifyContent="space-between"
        flexDirection={'column'}
        // TODO: make it dynamic
        overflow={'auto'}
        minWidth={`${mainSidebarWidth}px`}
      >
        <Box
          sx={{
            height: 'calc(100vh - 48px - 112px)',
            '@supports (height: 100dvh)': {
              height: 'calc(100dvh - 48px - 112px)',
            },
          }}
        >
          <BannerSubscriptionPlanEnding />
          <InboxMenu />
          <Box
            p="4px 8px 4px 16px"
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography variant="caption" color={'text.disabled'}>
              Группы
            </Typography>
            {selectedWorkspace &&
              WSRolePermissions[selectedWorkspace.group_name]
                ?.createTeamspace && <CreateGroup />}
          </Box>
          {teamspaces &&
            teamspaces.map(
              (teamspace) =>
                !teamspace.is_personal && (
                  <CollapsableMenu
                    teamspace={teamspace}
                    collapsed={collapsed === teamspace.id}
                    handleToggleCollapse={() => handleToggleCollapse(teamspace)}
                    key={`teamspaces-CollapsableMenu-${teamspace.id}`}
                  />
                )
            )}

          {teamspaces &&
            teamspaces.map(
              (teamspace) =>
                teamspace.is_personal && (
                  <CollapsablePrivateMenu
                    teamspace={teamspace}
                    key={`teamspaces-CollapsablePrivateMenu-${teamspace.id}`}
                  />
                )
            )}
        </Box>
      </Box>
      <Box p="8px 0" id={'drawer-footer'}>
        <SidebarBottomMenu />
      </Box>
    </>
  )
}
