import { Button, IconButton, Typography, Modal, Box } from '@mui/material'
import { createContext, useEffect, useState } from 'react'
import close from 'assets/img/close-btn.svg'
import fullwidthImg from 'assets/img/fullwidth.svg'
import { MessageSnackbar } from 'components/snackbar'
import { Begin } from './modalSteps/begin'
import { Settings } from './modalSteps/settings'
import { Load } from './modalSteps/load'
import { Check } from './modalSteps/check'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  getCampaignTemplateVariables,
  updateCampaign,
} from 'store/slices/campaign'
import TabButton from 'components/tab-button'
import { useParams } from 'react-router-dom'
import { updateTemplates } from 'store/slices/templatesSlice'
import { ETemplateFilter } from 'helper/consts'

export const CreateCampaign = ({
  title,
  open,
  handleClose,
  handleOpen,
  ...props
}) => {
  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleOpen}
        id="positioned-button"
        aria-controls={open ? 'positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        {...props}
      >
        {title}
      </Button>
      <ModalCreateCampaign open={open} handleClose={handleClose} />
    </>
  )
}

export const listOfTabs = [
  { key: 0, value: 'Начало', code: 'start' },
  { key: 1, value: 'Настройки', code: 'settings' },
  { key: 2, value: 'Загрузка', code: 'upload_file' },
  { key: 3, value: 'Проверка', code: 'check' },
]

export const FullwidthContext = createContext(false)

export const ModalCreateCampaign = ({ open = true, handleClose }) => {
  const [error, setError] = useState('')
  const [tab, setTab] = useState(0)
  const [fullWidth, setFullWidth] = useState(false)

  const { current_step, campaign_name } = useSelector(
    (state: RootState) => state.campaign
  )
  const { tsId } = useParams()

  useEffect(() => {
    dispatch(
      updateTemplates({ ts_id: tsId || '', filter: ETemplateFilter.ALL }) as any
    )
  }, [tsId])

  const validationSchemas = [
    Yup.object().shape({
      id: Yup.string().required('Обязательное поле'),
      campaign_name: Yup.string().required('Обязательное поле'),
    }),
    Yup.object().shape({
      iin: Yup.boolean(),
    }),
    Yup.object().shape({
      invites: Yup.array().of(Yup.string().email('Неправильный email')),
    }),
    {},
  ]

  const dispatch = useDispatch()

  const handleSubmit = (values) => {
    if (tab === 0) {
      dispatch(getCampaignTemplateVariables({ id: values.id }) as any)
    }

    if (tab < validationSchemas.length) {
      setTab(tab + 1)
      dispatch(
        updateCampaign({
          current_step: tab + 1,
        }) as any
      )
    }
  }

  return (
    <FullwidthContext.Provider value={fullWidth}>
      <Modal open={open} sx={{ outline: 'none' }} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: fullWidth ? '100%' : { xs: '100%', md: '716px' },
            width: fullWidth ? 'calc(100% - 24px)' : { xs: '90%', md: '100%' },
            background: 'white',
            borderRadius: '4px',
            overflow: 'hidden',
            height: fullWidth ? 'calc(100% - 24px)' : 'fit-content',
            maxHeight: fullWidth ? 'calc(100% - 24px)' : '80vh',
          }}
        >
          {error && (
            <MessageSnackbar
              message={error}
              clearMessage={() => setError('')}
              severity="error"
            />
          )}

          <IconButton
            children={
              <Box
                component={'img'}
                src={fullwidthImg}
                width={'16px'}
                height={'16px'}
              />
            }
            sx={{
              position: 'absolute',
              borderRadius: '4px',
              right: '48px',
              top: '16px',
            }}
            onClick={() => {
              setFullWidth(!fullWidth)
            }}
          />

          <IconButton
            children={
              <Box
                component={'img'}
                src={close}
                width={'16px'}
                height={'16px'}
              />
            }
            sx={{
              position: 'absolute',
              borderRadius: '4px',
              right: '16px',
              top: '16px',
              padding: '4px',
            }}
            onClick={() => {
              setError('')
              handleClose()
            }}
          />
          <Box p="12px 16px" id="header">
            <Typography variant={'subtitle2'}>
              Массовая рассылка {campaign_name}
            </Typography>
          </Box>

          <Box
            id="content"
            sx={{
              height: '100%',
              maxHeight: 'calc(100% - 48px)',
              overflow: 'auto',
            }}
          >
            <Box padding={'8px 16px 16px'}>
              <Box
                border={'1px solid #E7E7E6'}
                borderRadius={'4px'}
                width={'fit-content'}
              >
                {listOfTabs.map((item, key) => [
                  <TabButton
                    index={key}
                    key={`tabs-${item}-${key}`}
                    tab={tab}
                    onClick={() => setTab(key)}
                    label={item.value}
                    isDisabled={key > current_step}
                  />,
                ])}
              </Box>
            </Box>
            <Formik
              initialValues={{
                id: '',
                campaign_name: '',
                iin: true,
                checked_variables: {},
              }}
              validationSchema={validationSchemas[tab - 1]}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Box height={'100%'}>
                    {tab === 0 && <Begin />}
                    {tab === 1 && <Settings />}
                    {tab === 2 && <Load setTab={setTab} />}
                    {tab === 3 && (
                      <Check handleCloseContext={handleClose} setTab={setTab} />
                    )}
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </FullwidthContext.Provider>
  )
}
