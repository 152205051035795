/**
 * Возвращает обьект, который можно экспортировать в JSON
 * Все поля которые начинаются с нижнего подчеркивания игнорируются
 * @param instance 
 * @returns 
 */
export function getExportableObject(instance: any): any {
  if (!instance || typeof instance !== "object") return instance;

  if (Array.isArray(instance)) {
    return instance.map((item) => getExportableObject(item));
  }

  const result = {};
  Object.getOwnPropertyNames(instance).forEach((key) => {
    if (key.startsWith("_")) return
    result[key] = getExportableObject(instance[key]);
  });

  return result;
}