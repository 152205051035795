import img_close from 'assets/img/close-btn.svg'
import img_step_1 from 'assets/img/onboarding/1.svg'
import img_step_2 from 'assets/img/onboarding/2.svg'
import img_step_3 from 'assets/img/onboarding/3.svg'
import img_book from 'assets/img/onboarding/book-open.svg'
import img_rocket from 'assets/img/onboarding/rocket_launch.svg'

import { Box, Button, IconButton, Typography } from '@mui/material'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  resetOnboardingAction,
  skipOnboardingAction,
} from 'store/slices/userSlice'
import { mainSidebarWidth } from '../drawer'
import { MenuItemGray } from '../helper-menu'

const BadgeRocket = ({ text }: { text: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '4px',
        alignItems: 'center',
        px: '4px',
        background: '#FFCC77',
        borderRadius: '4px',
      }}
    >
      <Box component={'img'} width={'12px'} height={'12px'} src={img_rocket} />
      <Typography>{text}</Typography>
    </Box>
  )
}

const imageSrcs = {
  1: img_step_1,
  2: img_step_2,
  3: img_step_3,
}

export const StepText = ({
  step,
  text,
  isDone,
}: {
  step: number
  text: string
  isDone?: boolean
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        flexDirection: 'row',
      }}
    >
      <Box
        component={'img'}
        src={imageSrcs[step]}
        width={'20px'}
        height={'20px'}
        sx={{
          filter: isDone ? 'grayscale(100%)' : 'none',
        }}
      />
      <Typography
        variant="body1"
        fontWeight={'500'}
        color={isDone ? 'text.disabled' : 'inherit'}
        sx={{
          textDecoration: isDone ? 'line-through' : 'none',
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}

export const OnboardingPopup = ({ handleClose }: { handleClose: any }) => {
  const { onboarding } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  )

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleClickStart = () => {
    navigate('/')
  }

  const handleClickReset = () => {
    dispatch(resetOnboardingAction() as any)
    navigate('/')
  }

  const handleSkipOnboarding = () => {
    dispatch(skipOnboardingAction() as any)
  }

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          zIndex: 10000,
          bottom: '16px',
          left: `calc(16px + ${mainSidebarWidth}px)`,
          border: '1px solid #E5E5E5',
          borderRadius: '4px',
          overflow: 'hidden',
          width: '400px',
        }}
      >
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '12px',
              right: '12px',
              p: { xs: '4px' },
            }}
          >
            <Box component={'img'} src={img_close} alt="close" width={'16px'} />
          </IconButton>
          <Box
            sx={{
              padding: '16px',
              background: '#fff',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  mr: '4px',
                }}
              >
                Начало работы
              </Typography>
              <BadgeRocket text={`Выполнено ${onboarding.completed_steps}/3`} />
            </Box>
            <Box>
              <Typography variant="body1" color={'text.disabled'}>
                Это краткое руководство поможет вам получить максимальную выгоду
                от Doodocs
              </Typography>
            </Box>
            <Box>
              {onboarding.completed_steps > 0 ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClickReset}
                >
                  Начать заново
                </Button>
              ) : (
                <Button variant="contained" onClick={handleClickStart}>
                  Начать обучение
                </Button>
              )}
              <Button
                sx={{ ml: 1, p: '7px 16px' }}
                color="secondary"
                onClick={handleSkipOnboarding}
              >
                Пропустить обучение
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              background:
                'var(--light-grayscale-background-secondary, #F5F5F5)',
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
            }}
          >
            <StepText
              step={1}
              text={'Создайте новый документ'}
              isDone={onboarding.completed_steps >= 1}
              // isDone={isStarted}
            />
            <StepText
              step={2}
              isDone={onboarding.completed_steps >= 2}
              text={'Отправьте документ на подписание'}
            />
            <StepText
              step={3}
              isDone={onboarding.completed_steps >= 3}
              text={'Подпишите документ'}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export const MenuItemOnboarding = () => {
  const [open, setOpen] = useState(false)
  const { onboarding } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  )

  // ! TODO: Get State from redux

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <MenuItemGray onClick={handleClick}>
        <Box
          component={'img'}
          src={img_book}
          width={'16px'}
          height={'16px'}
          style={{
            marginRight: '8px',
          }}
        />
        <Typography>Начать обучение</Typography>
        <Box ml={'auto'}>
          <BadgeRocket text={`${onboarding.completed_steps}/3`} />
        </Box>
      </MenuItemGray>
      {open &&
        createPortal(
          <OnboardingPopup handleClose={() => setOpen(false)} />,
          document.body
        )}
    </>
  )
}
