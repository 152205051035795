import { Tooltip, styled, tooltipClasses } from '@mui/material'

export const CustomTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#1C1C1C',
    fontSize: '12px',
    borderRadius: '4px',
    padding: '8px 12px',
    textAlign: 'center',
  },
}))
