export const convertPdfToBase64 = (file: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const oReq = new XMLHttpRequest()

    oReq.open('GET', file, true)
    oReq.responseType = 'blob'

    oReq.onload = function () {
      const blob = oReq.response
      const reader = new FileReader()

      reader.onloadend = function () {
        const base64data = reader.result as string
        resolve(base64data.split(',')[1])
      }

      reader.onerror = function (err) {
        reject(err)
      }

      reader.readAsDataURL(blob)
    }

    oReq.onerror = function (err) {
      reject(err)
    }

    oReq.send()
  })
}
