import { TableRow, Typography } from '@mui/material'
import { convertDateToLocale } from 'utils/convertDateToLocale'
import { PaymentsTableCell } from '../PaymentsTableCell'
import { IPayment } from 'store/slices/wsPlan'
import { PaymentsTableCellContent } from '../PaymentsTableCellContent'
import { formatPrice } from 'utils'

type SubscriptionName = IPayment['subscription_name']
type StatusName = IPayment['status']

type IPlanNameDictionary = {
  [K in SubscriptionName]: string
}

type IStatusDictionary = {
  [K in StatusName]: string
}

const PlanNames: IPlanNameDictionary = {
  'trial:v2': 'Trial',
  'start:v2': 'Start',
  'lite:v2': 'Lite',
  'pro:v2': 'Pro',
}

const StatusNames: IStatusDictionary = {
  AWAITING_PAYMENT: 'Ожидает оплаты',
  PAID: 'Оплачено',
  ISSUED: 'Выставлен счет',
  FAILED: 'Ошибка',
}

export const PaymentsTableRow = ({ payment }: { payment: IPayment }) => {
  return (
    <TableRow>
      <PaymentsTableCell alignRight={false}>
        <Typography>{`Тариф Doodocs ${
          PlanNames[payment.subscription_name]
        }`}</Typography>
        <Typography color={'text.disabled'}>
          {convertDateToLocale(payment.created_at, false)}
        </Typography>
      </PaymentsTableCell>
      <PaymentsTableCell>{`₸${formatPrice(
        payment.total_price
      )}`}</PaymentsTableCell>
      <PaymentsTableCell>{StatusNames[payment.status]}</PaymentsTableCell>
      <PaymentsTableCell>
        <PaymentsTableCellContent payment={payment} />
      </PaymentsTableCell>
    </TableRow>
  )
}
