import { useMemo } from 'react'
import { config } from 'config'
import { EDocumentStatus } from 'helper/consts'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { skipOnboardingAction } from 'store/slices/userSlice'
import { driver } from 'driver.js'
import { setOnboardingCookie } from 'utils/setOnboardingCookie'

export const useDocumentSignDriver = ({ sendDocButtonRef }) => {
  const dispatch = useDispatch()

  const { status } = useSelector(
    (state: RootState) => state.application.document,
    shallowEqual
  )
  const { sign } = useSelector(
    (state: RootState) => state.wfPostman,
    shallowEqual
  )
  const { onboarding } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  )

  const finishSignDocStep = () => {
    dispatch(skipOnboardingAction() as any)
    driverObj?.destroy()
  }

  const removePulsedSpan = () => {
    const pulsedSpan = document.querySelector('.onboarding__pulse')
    pulsedSpan?.remove()
  }

  const appendPulsedSpan = () => {
    const pulsedSpan = document.querySelector('.onboarding__pulse')
    if (pulsedSpan || driverObj?.getActiveIndex() !== 0) return

    const btn = document.getElementById('workflow-view-button') as HTMLElement

    const span = document.createElement('span')
    span.className = 'onboarding__pulse'

    btn?.parentNode?.appendChild(span)
  }

  const driverObj = useMemo(() => {
    if (
      onboarding.skip ||
      onboarding.document_sign?.finished ||
      !onboarding.document_send?.finished ||
      status !== EDocumentStatus.SENT
    )
      return null

    return driver({
      showProgress: true,
      popoverClass: 'onboarding popover',
      progressText: '{{current}} из {{total}}',
      nextBtnText: 'Далее →',
      doneBtnText: 'Далее →',
      showButtons: ['next', 'close'],
      onCloseClick: finishSignDocStep,
      onDestroyed: removePulsedSpan,
      onPopoverRender: appendPulsedSpan,
      steps: [
        {
          element: '#workflow-view',
          popover: {
            title: 'Отслеживайте статус документа',
            description:
              'Будьте в курсе, что происходит с вашими документами на каждом этапе',
            onNextClick: () => {
              removePulsedSpan()
              driverObj?.moveNext()
            },
          },
        },
        {
          element: '#send-doc-button',
          popover: {
            title: 'Скопируйте тут ссылку для подписания',
            description: '',
            onNextClick: () => {
              sendDocButtonRef.current.click()
            },
          },
        },
        {
          element: '#copy-link',
          popover: {
            title: 'Скопируйте ссылку и откройте ее в новом окне',
            description: '',

            onNextClick: () => {
              const link =
                sign.origin?.recipient.link === '/'
                  ? `${config.DOMAIN_SIGN_DOODOCS}${sign.origin?.recipient.link}`
                  : `${'https://link.doodocs.kz/'}${
                      sign.origin?.recipient.link
                    }`
              window.open(link, '_blank')
              setOnboardingCookie()
              driverObj?.moveNext()
            },
          },
        },
      ],
    })
  }, [
    sendDocButtonRef.current,
    status,
    onboarding.document_sign,
    onboarding.document_send,
    sign.origin?.recipient.link,
  ])

  return { driverObj }
}
