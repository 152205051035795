import { Box, Typography } from '@mui/material'
import { ButtonCopy } from 'components/button/ButtonCopy'

export const FieldWithButtonCopy = ({
  value,
  title,
}: {
  value: string
  title: string
}) => {
  return (
    <Box
      display={'flex'}
      gap={'2px'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      flexShrink={'0'}
      flexGrow={'1'}
      width={'100%'}
    >
      <Typography variant="heading6">{title}</Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '8px',
          width: '100%',
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          width={'100%'}
          borderRadius={'4px'}
          border={'1px solid #EDEDEC'}
        >
          <Typography
            variant="paragraph3"
            alignSelf={'stretch'}
            flex={'1'}
            padding={'6px 4px 6px 12px'}
            noWrap
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {value}
          </Typography>
          <ButtonCopy copyTextValue={value} size="small" variant="text" />
        </Box>
      </Box>
    </Box>
  )
}
