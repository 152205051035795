import {
  Container,
  Box,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material'
import { Formik } from 'formik'
import { SetupWsDetailsStep, SetupInvitesStep } from 'pages/login/components'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createWorkspace } from 'store/slices/workspace'
import back from 'assets/img/back-nav.svg'
import { HeaderWithLogo } from 'components'
import { useNavigate } from 'react-router-dom'
import { OnboardingContext, titlesAndDesc } from 'pages/login'
import { validationSchemasOnbording } from 'pages/setup/onboarding/components/CreateWorkspaceOnboarding'

const steps = [<SetupWsDetailsStep />, <SetupInvitesStep />]

export const CreateWorkspace = () => {
  const [step, setStep] = useState(1)
  const [withInvites, setWithInvites] = useState(true)

  const { loading } = useSelector((state: RootState) => state.workspace)

  const dispatch = useDispatch()

  const handleClickBack = () => {
    step === 1 ? navigate('/') : setStep(step - 1)
  }

  const handleSubmit = (values) => {
    if (step < steps.length) {
      setStep(step + 1)
      return
    }
    dispatch(createWorkspace({ values, withInvites, navigate }) as any)
  }

  const navigate = useNavigate()

  return (
    <>
      <HeaderWithLogo />
      <OnboardingContext.Provider
        value={{ step: step, setStep: setStep, setWithInvites, withInvites }}
      >
        <Container maxWidth="xs" sx={{ mt: '88px' }}>
          <Box
            textAlign={'center'}
            display="flex"
            flexDirection={'column'}
            alignItems="center"
          >
            <Button color={'secondary'} onClick={handleClickBack}>
              <img
                src={back}
                alt=""
                style={{ marginRight: '4px' }}
                width="16px"
                height="16px"
              />
              <Typography fontWeight={500}>
                Шаг {step} из {steps.length}
              </Typography>
            </Button>
            <Typography variant="subtitle1" mt={'10px'}>
              {titlesAndDesc[step].title}
            </Typography>
            <Typography variant="body2" color={'text.secondary'} mt="8px">
              {titlesAndDesc[step].desc}
            </Typography>
          </Box>
          <Formik
            initialValues={{
              w_name: '',
              invites: [],
              avatar: '',
              logo: '',
            }}
            validationSchema={validationSchemasOnbording[step]}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {steps[step - 1]}

                {step === steps.length ? (
                  <Box
                    mt="24px"
                    display={'flex'}
                    alignItems="center"
                    flexDirection={'column'}
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={loading.create ? true : false}
                      onClick={() => {
                        setWithInvites(true)
                        handleSubmit()
                      }}
                    >
                      {loading.create ? (
                        <CircularProgress
                          thickness={5}
                          color="inherit"
                          sx={{ mr: '4px' }}
                        />
                      ) : null}
                      Пригласить участников
                    </Button>
                    <Button
                      sx={{ mt: '8px' }}
                      color="secondary"
                      onClick={() => {
                        setWithInvites(false)
                        handleSubmit()
                      }}
                    >
                      Сделать это позже
                    </Button>
                  </Box>
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: '24px' }}
                  >
                    Продолжить
                  </Button>
                )}
              </form>
            )}
          </Formik>
        </Container>
      </OnboardingContext.Provider>
    </>
  )
}
