import { Box, Button, CircularProgress } from '@mui/material'
import imageCompression from 'browser-image-compression'
import { ErrorHelper } from 'components/input/text-input'
import { useState, useRef, useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { workspaceApi } from 'service/workspaces'
import { WSPreviewContext } from '.'
import { AvatarWorkspace } from 'components/avatars/AvatarWorkspace'

export const UploadLogo = () => {
  const [loadingAvatar, setLoadingAvatar] = useState(false)
  const [avatarError, setAvatarError] = useState('')

  const { avatarFile, setAvatarFile } = useContext(WSPreviewContext)

  const inputRef = useRef<HTMLInputElement>(null)

  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const updateLogo = async (logo, wsId) => {
    try {
      const formData = new FormData()
      formData.append('logo', logo)
      await workspaceApi.updateWorkspaceLogo({
        data: formData,
        wsId,
      })
    } catch (error: any) {
      throw new Error(error.message)
    }
  }

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }
    setAvatarError('')

    event.target.value = null
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    setLoadingAvatar(true)
    try {
      const compressedFile = await imageCompression(fileObj, options)
      const objectUrl = URL.createObjectURL(compressedFile)
      setAvatarFile(objectUrl)
      updateLogo(compressedFile, selectedWorkspace?.id)
    } catch {
      setAvatarError('Ошибка')
    } finally {
      setLoadingAvatar(false)
    }
  }

  // const removeAvatar = () => {
  //   console.error('! TODO: Here is no route to remove photo')
  // }

  const handleClick = () => {
    inputRef?.current?.click()
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ mr: '8px' }}>
        <AvatarWorkspace
          src={avatarFile}
          name={selectedWorkspace?.public_name ?? ''}
          size={40}
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection="row"
        alignItems={'center'}
        justifyContent="start"
      >
        <input
          style={{ display: 'none' }}
          ref={inputRef}
          type="file"
          onChange={handleFileChange}
          accept=".png,.jpg,.jpeg"
        />
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          disabled={loadingAvatar ? true : false}
          onClick={handleClick}
          sx={{
            mr: '8px',
          }}
        >
          {loadingAvatar ? <CircularProgress /> : 'Загрузить фото'}
        </Button>
        {/* {avatarFile ? (
          <CustomTooltip title={'Удалить'} placement="right">
            <IconButton
              sx={{
                p: '4px',
                borderRadius: '4px',
              }}
              onClick={removeAvatar}
            >
              <Box
                sx={{ width: '16px', height: '16px' }}
                component={'img'}
                src={img_delete}
              />
            </IconButton>
          </CustomTooltip>
        ) : null} */}
        {avatarError && <ErrorHelper error={avatarError} />}
      </Box>
    </Box>
  )
}
