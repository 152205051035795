export const auth = {
  savePathRedirectAfterLogin: (path: string) => {
    if (!path || path == "/") return
    if (path == "/login") return
    sessionStorage.setItem('redirect_after_login', path)
  },
  popPathRedirectAfterLogin: () => {
    const res = sessionStorage.getItem('redirect_after_login')
    sessionStorage.removeItem('redirect_after_login')
    return res
  }
}