import { Box, Typography } from '@mui/material'
import { formatPrice } from 'pages/plan/lib/formatPrice'
import { EPlanPeriod, IPlanInfo } from 'pages/plan/types'

export const PriceBlock = ({
  plan,
  selectedPeriod,
}: {
  plan: IPlanInfo
  selectedPeriod: EPlanPeriod
}) => {
  const priceWithDiscount = formatPrice(
    plan.price[selectedPeriod] / selectedPeriod
  )
  const pricePerMonth = formatPrice(plan.price[EPlanPeriod.month])

  return (
    <Box mt={'10px'} py={'10px'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          gap: '8px',
        }}
      >
        <Typography variant="heading4">{priceWithDiscount}₸</Typography>
        {priceWithDiscount !== pricePerMonth && (
          <Box
            sx={{
              borderRadius: '4px',
              border: '1px solid var(--light-grayscale-border, #EDEDEC)',
              background: 'var(--light-grayscale-background-primary, #FFF)',
              px: '4px',
            }}
          >
            <Typography
              sx={{
                textDecoration: 'line-through',
              }}
            >
              {pricePerMonth}₸
            </Typography>
          </Box>
        )}
      </Box>

      <Typography
        variant="paragraph4"
        color={'var(--light-grayscale-content-3, #6B6F76)'}
        mt={'2px'}
      >
        /в месяц
      </Typography>
    </Box>
  )
}
