import { Box, Button, Typography } from '@mui/material'
import { CustomTooltip } from 'components'

export const ButtonSmall = ({
  className,
  prefixImgSrc,
  text,
  tooltip,
  onClick,
}: {
  className?: string
  prefixImgSrc?: string
  text: string
  tooltip?: string
  onClick?: (() => void) | any
}) => {
  const getButton = () => {
    return (
      <Button
        className={className}
        color="secondary"
        variant="outlined"
        sx={{
          display: 'flex',
          background: '#FFFFFF',
          padding: '2px 6px',
          gap: '4px',

          width: 'auto',
          height: 'auto',
          minWidth: 'min-content',
          minHeight: 'min-content',
        }}
        onClick={onClick}
      >
        {prefixImgSrc && (
          <Box
            component="img"
            src={prefixImgSrc}
            width={'16px'}
            height={'16px'}
          />
        )}
        <Typography variant="paragraph3">{text}</Typography>
      </Button>
    )
  }

  if (tooltip)
    return <CustomTooltip title={tooltip}>{getButton()}</CustomTooltip>

  return getButton()
}
