import { Skeleton, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useRef } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { updateDocument } from 'service'
import {
  createNewDocument,
  setDocumentInfo,
} from 'store/slices/applicationSlice'
import { useAppSelector } from 'store/store'
import * as Yup from 'yup'

export const EditDocumentName = () => {
  const { document, template, loading } = useAppSelector(
    (state) => state.application,
    shallowEqual
  )

  const { tsId } = useParams()
  const nameRef = useRef<HTMLInputElement>(null)

  const dispatch = useDispatch()

  const handleSubmit = async (values) => {
    document.id &&
      (await updateDocument({
        body: {
          document_name: values.name,
        },
        id: document.id,
      }))
    dispatch(setDocumentInfo({ name: values.name }) as any)
  }

  useEffect(() => {
    formik.setFieldValue('name', document.name || template?.name)
  }, [document.name, template?.name])

  const formik = useFormik({
    initialValues: {
      name: document.name || template?.name,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Обязательное поле'),
    }),
    onSubmit: async () => {
      nameRef?.current?.blur()
    },
  })

  const handleTouchStart = (e: any) => {
    e.preventDefault()
    if (!document.id) {
      dispatch(
        createNewDocument({
          name: document.name || template?.name,
          template_id: document.template_id || template?.id,
          tsId,
        }) as any
      )
    }
  }

  if (loading.info) return <Skeleton width={200} height={24} />
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          inputRef={nameRef}
          onFocus={handleTouchStart}
          variant="standard"
          inputProps={{
            style: {
              padding: 0,
              height: 'auto',
            },
          }}
          InputProps={{
            disableUnderline: true,
            sx: {
              fontSize: '1.25rem',
              lineHeight: '1.375',
              fontWeight: 500,
            },
          }}
          onBlur={(e) => {
            e.preventDefault()
            handleSubmit(formik.values)
          }}
          onChange={formik.handleChange}
          placeholder={document.name || template?.name}
          value={formik.values.name}
          fullWidth
          name="name"
        />
      </form>
    </>
  )
}
