import { createContext } from "react"

export enum ESidebarDocumentTrackingViewMode {
  RecipientList = 'RECIPIENT_LIST',
  EditRecipient = 'EDIT_RECIPIENT',
  SignerInfo = 'SIGNER_INFO',
}

export const SidebarDocumentTrackingContext = createContext<{
  mode: ESidebarDocumentTrackingViewMode
  setMode?: any
  currentRecipientProps: any
  setCurrentRecipientProps?: any
}>({
  mode: ESidebarDocumentTrackingViewMode.RecipientList,
  currentRecipientProps: {},
})