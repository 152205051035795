import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import {
  Grid,
  Typography,
  Divider,
  InputLabel,
  IconButton,
  Box,
  CircularProgress,
} from '@mui/material'
import { authWithNCADoodocs, loadModule, logout } from 'service/ncaService'
import close from 'assets/img/close-btn.svg'
import key from 'assets/img/massSign/key.svg'
import selector from 'assets/img/massSign/selector.svg'
import password from 'assets/img/massSign/pass.svg'
import { useState, useEffect } from 'react'
import { usePasswordToggler } from 'hooks/usePasswordToggler'
import { AnalyticsEvent } from 'config/analytics/segment.com/events'

const InputCSS = {
  width: '100%',
  fontSize: '14px',
  borderRadius: '4px',
  height: '32px',
  border: '1px solid #339dff',
  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06)',
  display: 'flex',
  alignItems: 'center',
  padding: '12px',
  '&:hover': {
    boxShadow: '0px 0px 2px 2px rgba(3, 159, 0, 0.15)',
  },
}

export default function FormDialog({
  setOpen,
  open,
  setLoading,
  massSign,
  showSnackbar,
  loading,
  closeSnackbar,
}) {
  const [pass, setPass] = useState('')
  const [fileBase64, setFileBase64] = useState<string>('')
  const [fileName, setFileName] = useState<string>('')
  const { type, toggleVisibility } = usePasswordToggler()
  const [dowloadModule, setDownloadModule] = useState(false)
  const [waitingLoad, setWaitingLoad] = useState(false)

  const clearFileds = () => {
    setFileName('')
    setFileBase64('')
    setPass('')
  }

  const handleClose = () => {
    clearFileds()
    closeSnackbar()
    setOpen(false)
    setLoading(false)
    setDownloadModule(false)
    setWaitingLoad(false)
  }

  const handleChange = (e) => {
    setPass(e.target.value)
  }

  const sign = async ({ storagePath, pass }) => {
    if (!storagePath || !pass) {
      showSnackbar('Заполните пустые поля', 'warning')
      return
    }

    try {
      const auth = await authWithNCADoodocs({
        storagePath,
        pass,
      })
      if (auth.body === 'success') {
        await massSign()
        clearFileds()
      }
      await logout()
    } catch (error: any) {
      if (error.message === 'MODULE_NOT_FOUND') {
        setDownloadModule(true)
        return
      }
      showSnackbar(error.message, 'warning')
      setLoading(false)
    }
  }

  const onClickLoadModule = async () => {
    setWaitingLoad(true)
    try {
      await loadModule()
      setWaitingLoad(false)
    } catch (error: any) {
      setLoading(false)
    }
  }

  const submit = async (e) => {
    e.preventDefault()
    sign({ storagePath: fileBase64, pass })
  }

  function readFile(file) {
    const reader = new FileReader()

    setFileName(file.name)
    reader.readAsDataURL(file)
    reader.onload = function () {
      setFileBase64((reader.result as string).split(',')[1])
    }
  }

  useEffect(() => {
    if (open) AnalyticsEvent.sign_doc_start('yes')
  }, [open])

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <IconButton
          children={<img src={close} alt="" />}
          sx={{
            height: '28px',
            width: '28px',
            position: 'absolute',
            right: '16px',
            top: '16px',
            border: '1px solid #E8E8E8;',
            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06)',
          }}
          onClick={handleClose}
        />

        <form onSubmit={submit}>
          <Grid
            container
            spacing={'24px'}
            columns={12}
            p={'24px'}
            maxWidth={'400px'}
          >
            <Grid item xs={12}>
              <Typography variant="body1" display={'flex'}>
                <Typography color={'text.disabled'}>Подписать</Typography>
                &nbsp;
                <Typography color="text.secondary">
                  {' '}
                  / Массовое подписание
                </Typography>
              </Typography>
            </Grid>

            {dowloadModule &&
              (waitingLoad ? (
                <>
                  <Grid item xs={12}>
                    <Box>
                      <Typography
                        variant="h5"
                        color="text.secondary"
                        textAlign={'center'}
                        mb="24px"
                      >
                        Откройте окно NCALayer нажмите установить и далее
                        следуйте инструкции
                      </Typography>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          setDownloadModule(false)
                          setWaitingLoad(false)
                        }}
                      >
                        Установил(а)
                      </Button>
                    </Box>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      color="text.secondary"
                      textAlign={'center'}
                    >
                      Требуется установка модуля 'ИС Doodocs.kz' для массового
                      подписания
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display={'flex'}
                      justifyContent="center"
                      flexDirection={'column'}
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={onClickLoadModule}
                      >
                        Установить
                      </Button>
                    </Box>
                  </Grid>
                </>
              ))}
            {!dowloadModule && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    color={'text.secondary'}
                    variant={'body1'}
                    mb={'8px'}
                  >
                    Выберите ключ ЭЦП <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    id="files"
                    inputProps={{ accept: '.p12' }}
                    sx={{ display: 'none' }}
                    fullWidth
                    type={'file'}
                    onChange={(event: any) => {
                      event.preventDefault()
                      const file = event.target.files[0]
                      readFile(file)
                    }}
                  ></TextField>

                  <InputLabel
                    className={'labelFile'}
                    htmlFor="files"
                    style={InputCSS}
                    disabled={loading ? true : false}
                  >
                    <img src={key} style={{ marginRight: 8 }} />
                    <Typography sx={{ overflow: 'hidden', width: '100%' }}>
                      {fileName ? fileName : 'Файл не выбран'}
                    </Typography>
                    <img src={selector} style={{ right: 14, marginLeft: 10 }} />
                  </InputLabel>
                  <Typography
                    color={'text.disabled'}
                    variant={'body1'}
                    mt={'8px'}
                  >
                    Воспользуйтесь RSA или GOST ключом для подписания
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    color={'text.secondary'}
                    variant={'body1'}
                    mb={'8px'}
                  >
                    Пароль <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    disabled={loading ? true : false}
                    id="password"
                    type={type}
                    fullWidth
                    onChange={handleChange}
                    value={pass}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          sx={{ p: 0 }}
                          onMouseDown={toggleVisibility}
                          onMouseUp={toggleVisibility}
                          children={<img src={password} style={{}} />}
                        />
                      ),
                    }}
                  ></TextField>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={loading ? true : false}
                  >
                    {loading ? (
                      <CircularProgress
                        thickness={5}
                        color="inherit"
                        sx={{ mr: '4px' }}
                      />
                    ) : null}
                    Подписать
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </Dialog>
    </>
  )
}
