import { Box, Collapse } from '@mui/material'
import { MenuNavigation } from 'layout/dashboard-layout/menuNavigation'
import { useState } from 'react'
import { InviteModal } from '../create-group'
import { TeamSpaceInfo } from './components/teamspace-info'

export const CollapsableMenu = ({
  teamspace,
  handleToggleCollapse,
  collapsed,
}: {
  teamspace: ITeamspace
  handleToggleCollapse: () => void
  collapsed: boolean
}) => {
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  return (
    <Box p="2px 0">
      <InviteModal
        open={open}
        handleClose={handleClose}
        name={teamspace.name}
      />
      <TeamSpaceInfo
        teamspace={teamspace}
        handleToggleCollapse={handleToggleCollapse}
        collapsed={collapsed}
        setOpen={setOpen}
      />

      <Collapse in={collapsed}>
        <MenuNavigation isNested={true} teamspace={teamspace} />
      </Collapse>
    </Box>
  )
}
