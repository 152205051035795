import axios, { AxiosError, AxiosResponse } from 'axios'
import { api } from 'config'
import {
  EDocumentStatus,
  LocalStorageReserve,
  ROWS_PER_PAGE,
  slugErrors,
} from 'helper/consts'

export async function getWorkflowActorsDocument({
  documentId,
}: {
  documentId: string
}) {
  try {
    const response = await api.get(`/documents/${documentId}/workflow/actors`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function getDocumentVariables({
  documentId,
}: {
  documentId: string
}) {
  try {
    const response = await api.get<{
      variables: IVariable[]
    }>(`/documents/${documentId}/variables`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function createDocument({ body, tsId }) {
  try {
    const response = await api.post(`teamspaces/${tsId}/documents`, body, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const getPdfFileByLink = async ({
  link,
  filename,
}: {
  link: string
  filename?: string
}) => {
  try {
    const resp = await axios(link, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    })

    const blob = new Blob([resp.data], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    return { url, filename: filename ?? '' }
  } catch (error: any) {
    return handleError(error)
  }
}

export const getListOfApprovers = async ({ id }) => {
  try {
    const resp = await api.get(`/documents/${id}/download/approval`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
      timeout: 10000,
      responseType: 'blob',
    })

    const blob = new Blob([resp.data], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    return url
  } catch (error) {
    return handleError(error)
  }
}

export const handleError = (error: any) => {
  if (error instanceof AxiosError && error.response && error.response.data) {
    if (!error.response.data.message) throw new Error('Произошла ошибка')
    throw new Error(
      slugErrors[error.response.data.message] || error.response.data.message
    )
  }
  throw new Error('Ошибка подключения к серверу')
}

export async function signNCASignature({
  data,
  id,
  token,
  role = 'sign',
  isMassSign = false,
}) {
  try {
    const response = await api.post(
      `/documents/${id}/${role}/nca` + (isMassSign ? '?mass_sign=t' : ''),
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `Bearer ${token}`,
          accept: 'application/json',
        },
      }
    )
    return response
  } catch (error) {
    if (error instanceof AxiosError && error.response)
      if (error.response.status !== 0)
        throw new Error(
          slugErrors[error.response.data.message] || error.response.data.message
        )
      else throw new Error(error.message)
    throw new Error('Ошибка подключения к серверу')
  }
}

export async function revokeFile({ id }) {
  try {
    const resp = await api.put(
      `/documents/${id}/revoke`,
      {},
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
          accept: 'application/json',
        },
      }
    )
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export async function archiveFile({ id }) {
  try {
    const resp = await api.put(
      `/documents/${id}/archive`,
      {},
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
          accept: 'application/json',
        },
      }
    )
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export const unarchiveDocument = async ({ id }) => {
  try {
    const resp = await api.put(
      `/documents/${id}/unarchive`,
      {},
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
          accept: 'application/json',
        },
      }
    )
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export async function getDDCLink({ documentId }: { documentId: string }) {
  try {
    const resp = await api.get<{
      link: string
    }>(`/documents/${documentId}/ddcard`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
      timeout: 10_000,
    })
    return resp
  } catch (error) {
    if (error instanceof AxiosError && error.code == '423') {
      throw new Error('Цифровая карта документа все еще не готова')
    }
    return handleError(error)
  }
}

export async function downloadDDC({ documentId }: { documentId: string }) {
  try {
    const resp = await api.get(`/documents/${documentId}/download`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
      timeout: 60_000,
      responseType: 'blob',
    })

    const filename = 'DDC.pdf'

    const blob = new Blob([resp.data], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    return { url, filename }
  } catch (error) {
    return handleError(error)
  }
}

export async function downloadZip({ tsId, list }) {
  try {
    const resp = await api.post(
      `/teamspaces/${tsId}/documents/download`,
      list,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
        timeout: 10000,
        responseType: 'blob',
      }
    )

    const filename = 'documents.zip'
    const blob = new Blob([resp.data], { type: 'application/zip' })
    const url = window.URL.createObjectURL(blob)
    return { url, filename }
  } catch (error) {
    return handleError(error)
  }
}

export async function downloadEzSigner({
  id,
  filename,
}: {
  id: string
  filename?: string
}) {
  try {
    const resp = await api.get(`/documents/${id}/ezsigner`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
      timeout: 10000,
      responseType: 'blob',
    })

    const fname = filename ? filename : `${id}.cms`

    const blob = new Blob([resp.data])
    const url = window.URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fname)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    return handleError(error)
  }
}

export async function getRegistry({
  filter = EDocumentStatus.ALL,
  campaignId = '',
  teamspaceId,
  onlyOwnDocuments = true,
  query = '',
  author_id = '',
  template_id = '',
  page = 1,
  limit,
}: {
  filter?: string
  campaignId?: string
  teamspaceId: string
  onlyOwnDocuments?: boolean
  query?: string
  author_id?: string
  template_id?: string
  page: number
  limit?: number
}) {
  const _limit = limit ?? ROWS_PER_PAGE
  const offset = (page - 1) * _limit

  try {
    const resp = await api.get(
      `teamspaces/${teamspaceId}/documents?filter=${filter}&limit=${_limit}&offset=${offset}&my=${onlyOwnDocuments}&campaign_id=${campaignId}&q=${query}&author_id=${author_id}&template_id=${template_id}`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
          accept: 'application/json',
        },
      }
    )
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export async function getDocumentLedger({ id }) {
  try {
    const response = await api.get(`/documents/${id}/ledger`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const queryCreateDocumentEditorDraft = async ({
  teamspace_id,
  document_name,
  content,
}: {
  teamspace_id: string
  document_name: string
  content: string
}) => {
  try {
    const body = {
      document_name: document_name,
      content: content,
    }
    const headers = {
      authorization: `Bearer ${localStorage.getItem(
        LocalStorageReserve.token
      )}`,
    }
    const response = await api.post(
      `/teamspaces/${teamspace_id}/documents/editor`,
      body,
      {
        headers: headers,
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const queryUpdateDocumentEditorContent = async ({
  document_id,
  content,
}: {
  document_id: string
  content: string
}) => {
  try {
    const body = {
      content: content,
    }

    const headers = {
      authorization: `Bearer ${localStorage.getItem(
        LocalStorageReserve.token
      )}`,
    }

    const response = await api.patch(`/documents/${document_id}/editor`, body, {
      headers: headers,
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const queryUpdateDocumentEditorName = async ({
  document_id,
  name,
}: {
  document_id: string
  name: string
}) => {
  try {
    const body = {
      document_name: name,
    }

    const headers = {
      authorization: `Bearer ${localStorage.getItem('token')}`,
    }

    const response = await api.patch(`/documents/${document_id}`, body, {
      headers: headers,
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

/**
 * queryPublishDocumentEditor - Запуск workflow только для editor
 * @returns
 */
export const queryPublishDocumentEditor = async ({
  document_id,
  eventType,
}: {
  document_id: string
  eventType: 'copylink' | 'workflow' | 'onlyme'
}) => {
  try {
    const body = {}

    const headers = {
      authorization: `Bearer ${localStorage.getItem('token')}`,
    }

    const response = await api.post(
      `/documents/${document_id}/editor/publish?event_type=${eventType}`,
      body,
      {
        headers: headers,
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function updateDocument({ body, id }) {
  try {
    const response = await api.patch(`/documents/${id}`, body, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function postDraftVariables({ body, id }) {
  try {
    const response = await api.post(`/documents/${id}/values`, body, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function getDocumentInfo({ id }) {
  try {
    const response = await api.get(`/documents/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

/**
 * deleteDraft - deletes document forever
 */
export const deleteDraft = async ({ id }) => {
  try {
    const resp = await api.delete(`/documents/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return resp
  } catch (error) {
    return handleError(error)
  }
}

/**
 * deleteDocument - on first delete, moves to trash, on second delete, deletes forever
 */
export const deleteDocument = async ({ id }) => {
  try {
    const resp = await api.delete(`/documents/${id}/delete`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return resp
  } catch (error) {
    return handleError(error)
  }
}

/**
 * restoreDocument - restore document from recycle bin
 */
export const restoreDocument = async ({ id }) => {
  try {
    const resp = await api.patch(
      `/documents/${id}/restore`,
      {},
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export const getCampaignInfo = async ({ id }) => {
  try {
    const response = await api.get(`/campaigns/${id}/send`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const sendCampaindDocs = async ({ id, docs }) => {
  try {
    const response = await api.post(
      `/campaigns/${id}/send`,
      { documents: docs },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const createCampaign = async ({ id }) => {
  try {
    const response = await api.post(
      `/campaigns/${id}`,
      {},
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const getCampaign = async ({ id }) => {
  try {
    const response: AxiosResponse<{
      name: string
      status: string
      stats: any
    }> = await api.get(`/campaigns/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const getCampaigns = async ({ ts_id, page }) => {
  try {
    const offset = (page - 1) * ROWS_PER_PAGE
    const response = await api.get(
      `/teamspaces/${ts_id}/campaigns?limit=${ROWS_PER_PAGE}&offset=${offset}`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const updateCampaignName = async ({ id, name }) => {
  try {
    const response = await api.patch(
      `/campaigns/${id}`,
      { name: name },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export async function uploadPdf({ formData }) {
  try {
    const resp = await api.post(`/documents/pdf`, formData, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    })
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export const convert = async ({ formData }) => {
  try {
    const resp = await api.post(`convert`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
      timeout: 10000,
    })

    return resp.data
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const obj = JSON.parse(await error.response.data.text())
      throw new Error(slugErrors[obj.message] || obj.message)
    }
    throw new Error('Ошибка подключения к серверу')
  }
}

export const getMgovLinks = async ({ id, token }) => {
  try {
    const response = await api.get(`/documents/${id}/mgov/links`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const getCurrentRecipientInfo = async ({ id }) => {
  try {
    const response = await api.get(`/documents/${id}/recipient`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export type TLaunchWorkflowEventType = 'copylink' | 'workflow' | 'onlyme'

export const launchWorkflow = async ({
  id,
  eventType,
}: {
  id: string
  eventType: TLaunchWorkflowEventType
}) => {
  try {
    const response = await api.post(
      `/documents/${id}/workflow/launch?event_type=${eventType}`,
      {},
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const createWorkflowAnonymous = async ({ id, data }) => {
  try {
    const response = await api.post(
      `/documents/${id}/workflow/anonymous`,
      data,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const createWorkflowEmails = async ({ id, data }) => {
  try {
    const response = await api.post(`/documents/${id}/workflow/email`, data, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const editWorkflow = async ({ id, data }) => {
  try {
    const response = await api.patch(`/documents/${id}/workflow`, data, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const patchRecipientsId = async ({
  id,
  data,
}: {
  id: string
  data: { link_name: string }
}) => {
  try {
    const response = await api.patch(`/recipients/${id}`, data, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const getRecipientsInfo = async ({ id }) => {
  try {
    const response = await api.get(`/documents/${id}/recipients/structured`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const getRecipientByOrigin = async ({ id, origin_id }) => {
  try {
    const response = await api.get(`/documents/${id}/recipient/${origin_id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const queryGetWorkflow = async ({ id }) => {
  try {
    const response = await api.get(`/documents/${id}/workflow`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const restoreWorkflow = async ({ id }) => {
  try {
    const response = await api.post(
      `/documents/${id}/workflow/restore`,
      {},
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const queryShareDocument = async ({ id }) => {
  try {
    const resp = await api.post(
      `/documents/${id}/share`,
      {},
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
          accept: 'application/json',
        },
      }
    )
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export const queryUnshareDocument = async ({ id }) => {
  try {
    const resp = await api.delete(`/documents/${id}/share`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
        accept: 'application/json',
      },
    })
    return resp
  } catch (error) {
    return handleError(error)
  }
}
