import { api } from 'config'
import { LocalStorageReserve } from 'helper/consts'
import { handleError } from 'service'

export const getWorkspaceTeamspaces = async ({ id }:{ id: string }) => {
  try {
    const response = await api.get(`/workspaces/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const crateTeamspace = async ({ body, id }) => {
  try {
    const response = await api.post(`/workspaces/${id}/teamspaces`, body, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const getTeamspaceInfo = async ({ id }) => {
  try {
    const response = await api.get(`/teamspaces/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const deleteTeamspace = async ({ id }) => {
  try {
    const response = await api.delete(`/teamspaces/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const changeTeamspaceInfo = async ({ id, teamspace }) => {
  try {
    const response = await api.patch(
      `/teamspaces/${id}`,
      { teamspace },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const changeTeamspaceAttrs = async ({ id, attrs }) => {
  try {
    const response = await api.patch(
      `/teamspaces/${id}/attrs`,
      { attrs },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const changeMemberRole = async ({ group_id, user_id, body }) => {
  try {
    const response = await api.post(
      `/groups/${group_id}/members/${user_id}/switch`,
      body,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const deleteMember = async ({ group_id, user_id }) => {
  try {
    const response = await api.delete(
      `/groups/${group_id}/members/${user_id}`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(
            LocalStorageReserve.token
          )}`,
        },
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const deleteInvitee = async ({ id }) => {
  try {
    const response = await api.delete(`/invites/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const getTeamspaceMembers = async ({ id }) => {
  try {
    const response = await api.get(`/teamspaces/${id}/members`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const getTeamspaceGroups = async ({ id }) => {
  try {
    const response = await api.get(`/teamspaces/${id}/groups`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const getTeamspaceInvites = async ({ id }) => {
  try {
    const response = await api.get(`/teamspaces/${id}/invites`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const fetchTeamspaceGroups = async ({ id }) => {
  try {
    const response = await api.get(`/teamspaces/${id}/groups`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const fetchTeamspaceUserGroups = async ({ id }) => {
  try {
    const response = await api.get(`/teamspaces/${id}/users/groups`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const leaveTeamspace = async ({ id }) => {
  try {
    const response = await api.delete(`/teamspaces/${id}/users/membership`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(
          LocalStorageReserve.token
        )}`,
      },
    })
    return response
  } catch (error) {
    return handleError(error)
  }
}
