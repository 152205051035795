import { createContext } from 'react'

interface documentSignListenerContextValue {
  listenDocumentSignStatus: ({
    fields,
    onResponseData,
    onResponseError,
  }: {
    fields: {
      documentId: string
      originId: string
      isRunning: boolean
    }
    onResponseData: (data: string) => void
    onResponseError?: (errMsg: string) => void
  }) => Promise<any>
}

export const DocumentSignListenerContext =
  createContext<documentSignListenerContextValue>(
    {} as documentSignListenerContextValue
  )
