import { Box, Button, CircularProgress } from '@mui/material'
import {
  FormikFormPaymentRequisites,
  IFormikFieldsPaymentRequisites,
} from 'components/forms/FormikFormPaymentRequisites'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { AnalyticsEvent } from 'config/analytics/segment.com/events'
import { FormikContextType, FormikProvider } from 'formik'
import { SelectPlanContext } from 'pages/plan/components/SelectPlanProvider'
import { useCallback, useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

export enum EModalPaymentRequisitesFormType {
  RequestInvoice = 'request_invoice',
  InputInvoiceToPay = 'input_invoice_to_pay',
}

interface IModalValue {
  title: string
  subtitle: string
  btnTextCancel: string
  btnTextSubmit: string
}

const modalValues: {
  [key in EModalPaymentRequisitesFormType]: IModalValue
} = {
  [EModalPaymentRequisitesFormType.RequestInvoice]: {
    title: 'Введите данные вашей компании',
    subtitle: 'Они будут указаны в счете на оплату',
    btnTextCancel: 'Назад',
    btnTextSubmit: 'Сохранить и сформировать PDF',
  },
  [EModalPaymentRequisitesFormType.InputInvoiceToPay]: {
    title: 'Введите данные вашей компании',
    subtitle: 'Они будут указаны в счете и закрывающем документе',
    btnTextCancel: 'Назад',
    btnTextSubmit: 'Перейти к оплате',
  },
}

export const ModalPaymentRequisitesForm = ({
  open,
  handleClose,
  handleClickBack,
  formik,
  formType,
}: {
  open: boolean
  handleClose: () => void
  handleClickBack: () => void
  formik: FormikContextType<IFormikFieldsPaymentRequisites>
  formType: EModalPaymentRequisitesFormType
}) => {
  const { selectedPlan, selectedPeriod } = useContext(SelectPlanContext)
  const [, setStartedFilling] = useState(false)

  const onChange = useCallback(
    (e) => {
      formik.handleChange(e)

      if (formType != EModalPaymentRequisitesFormType.RequestInvoice) return
      setStartedFilling((s) => {
        if (s) return s
        if (!selectedPlan) return s
        AnalyticsEvent.request_invoice_started(selectedPlan, selectedPeriod)
        return true
      })
    },
    [formik, selectedPlan, setStartedFilling]
  )

  useEffect(() => {
    if (open) setStartedFilling(false)
  }, [open])

  return (
    <ModalCenterSelectableWithTitle
      open={open}
      handleClose={handleClose}
      modalType="small"
      title={modalValues[formType].title}
      subtitle={modalValues[formType].subtitle}
      contentChild={
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <FormikFormPaymentRequisites onChange={onChange} />
          </form>
        </FormikProvider>
      }
      footerChild={
        <form onSubmit={formik.handleSubmit}>
          <Box
            display={'flex'}
            flexWrap={isMobile ? 'wrap' : 'nowrap'}
            justifyContent={'end'}
            alignItems={'center'}
            gap={'8px'}
          >
            <Button
              onClick={handleClickBack}
              color="secondary"
              fullWidth={isMobile}
            >
              {modalValues[formType].btnTextCancel}
            </Button>
            <Button
              fullWidth={isMobile}
              type="submit"
              color="primary"
              variant="contained"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting && (
                <CircularProgress color="inherit" sx={{ mr: '4px' }} />
              )}
              {modalValues[formType].btnTextSubmit}
            </Button>
          </Box>
        </form>
      }
    />
  )
}
