import { TableContainer } from '@mui/material'
import { Empty, Loading, MessageSnackbar } from 'components'
import { DeletedRecordsTable } from 'components/deleted-records-table'
import { useSnackbar } from 'components/snackbar/hook'
import { useSelector, shallowEqual } from 'react-redux'
import { restoreTemplate, deleteTemplate } from 'service/template'
import img_delete_gray from 'assets/img/delete.svg'
import { useContext } from 'react'
import {
  TemplatesBucketRegistryContext,
  TemplatesRegistryContext,
} from '../../hooks'

export const DeletedTemplatesTable = () => {
  const { hideTemplateFromRegistry: hideFromBucket, rows } = useContext(
    TemplatesBucketRegistryContext
  )
  const { unhideTemplateFromRegistry } = useContext(TemplatesRegistryContext)

  const { snackbar, showSnackbar, closeSnackbar } = useSnackbar()

  const { loading } = useSelector(
    (state: RootState) => state.templates,
    shallowEqual
  )

  const actionRestore = async (templateId: string) => {
    try {
      const res = await restoreTemplate({ templateId })
      hideFromBucket(templateId)
      unhideTemplateFromRegistry(templateId)
      showSnackbar({
        message: 'Шаблон восстановлен',
        severity: 'info',
        close: closeSnackbar,
      })
      return res
    } catch (error: any) {
      if (error instanceof Error) {
        showSnackbar({
          message: error.message,
          severity: 'error',
          close: closeSnackbar,
        })
      }
    }
  }

  const actionDelete = async (templateId: string) => {
    try {
      const res = await deleteTemplate({ templateId })
      hideFromBucket(templateId)
      showSnackbar({
        message: 'Шаблон удален безвозвратно',
        severity: 'info',
        close: closeSnackbar,
      })
      return res
    } catch (error: any) {
      if (error instanceof Error) {
        showSnackbar({
          message: error.message,
          severity: 'error',
          close: closeSnackbar,
        })
      }
    }
  }

  if (loading.private) return <Loading />
  if (rows.length === 0)
    return (
      <Empty
        src={img_delete_gray}
        title={'В корзине еще нет шаблонов'}
        subtitle={'Здесь будут храниться удаленные шаблоны'}
      />
    )

  return (
    <>
      <MessageSnackbar
        message={snackbar.message}
        clearMessage={snackbar.close}
        severity={snackbar.severity}
        buttons={snackbar.buttons}
      />
      <TableContainer>
        <DeletedRecordsTable
          loading={loading.private}
          rows={rows}
          page={0}
          actionDelete={actionDelete}
          actionRestore={actionRestore}
          hasCheckbox={false}
          tableType="templates"
        />
      </TableContainer>
    </>
  )
}
