import { Button, Box } from '@mui/material'
import { memo } from 'react'
import { useModal } from 'hooks/useModal'
import { useTemplateActions } from 'hooks/useTemplateActions'
import { ModalActionDocument } from 'components/modal-actions/modalActionDocument'

import img_edit from 'assets/img/edit.svg'
import { restrictionsTemplate } from 'utils/restrictions/template'

export const EditTemplateButton = memo(({ row }: { row: ITemplate }) => {
  const [modalEditTemplateOpen, openModalEditTemplate, closeModalEditTemplate] =
    useModal()

  const { actionEditTemplate } = useTemplateActions({
    status: row?.status,
    templateId: row?.id ?? '',
  })

  const handleButtonClick = (e) => {
    e.stopPropagation()
    openModalEditTemplate()
  }

  const modalConfigs = [
    {
      key: 'edit-template-modal',
      handleClose: closeModalEditTemplate,
      open: modalEditTemplateOpen,
      action: actionEditTemplate,
      title: 'Изменить шаблон',
      description: `Вы уверены? Если вы это сделаете: \n\n Любые внесенные изменения в шаблоне будут применены у всех последующих документов, созданных с использованием этого шаблона.`,
      btnTitle: 'Изменить шаблон',
    },
  ]

  if (!restrictionsTemplate.canEdit(row)) return null
  return (
    <>
      <Button
        className="edit-template-button"
        size="small"
        sx={{ height: '24px' }}
        color="secondary"
        onClick={handleButtonClick}
        startIcon={
          <Box
            component={'img'}
            src={img_edit}
            width={'16px'}
            height={'16px'}
          ></Box>
        }
      >
        Изменить
      </Button>

      {modalConfigs.map((config) => (
        <ModalActionDocument
          key={config.key}
          handleClose={config.handleClose}
          open={config.open}
          action={config.action}
          title={config.title}
          description={config.description}
          btnTitle={config.btnTitle}
          btnColor={
            config.key === 'delete-template-modal' ? 'error' : 'primary'
          }
          btnVariant={
            config.key === 'delete-template-modal' ? 'outlined' : 'contained'
          }
        />
      ))}
    </>
  )
})
