import { ERecipientRole } from 'helper/consts'
import { IServiceWorkflow } from 'helper/workflow.service.types'

export const getServiceWorkflowForLink = (limit: number) => {
  const workflow: IServiceWorkflow = {
    steps: [
      {
        index: 1,
        recipients: [
          {
            role: ERecipientRole.anonymous_signer_rk,
            attrs: { limit: limit },
          },
        ],
      },
    ],
  }
  return { workflow }
}
