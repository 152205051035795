import React from 'react'

export const usePopupHelpers = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget
    setAnchorEl(target)
    if (target?.classList.contains('hidden-element')) target.style.opacity = '1'
  }

  const onClose = () => {
    if (anchorEl?.classList.contains('hidden-element'))
      anchorEl.style.opacity = ''
    setAnchorEl(null)
  }

  return {
    open,
    anchorEl,
    handleOpen,
    onClose,
  }
}
