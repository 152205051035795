import { Button } from '@mui/material'
import { useState } from 'react'
import { ModalPaymentRequisitesChangeForm } from '../ModalPaymentRequisitesChangeForm'
import { Card } from '../Card'
import { useAppSelector } from 'store/store'
import { shallowEqual } from 'react-redux'

export const CardPaymentRequisitChange = () => {
  const [open, setOpen] = useState(false)
  const { requisites } = useAppSelector((state) => state.wsPlan, shallowEqual)

  if (!requisites || !requisites?.company_name) return null
  return (
    <>
      <Card
        label="Реквизиты:"
        text={[
          requisites.company_name,
          requisites.bin,
          requisites.legal_address,
        ].join(', ')}
        buttons={[
          <Button
            key={'change'}
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => setOpen(true)}
          >
            Изменить
          </Button>,
        ]}
      />

      <ModalPaymentRequisitesChangeForm
        open={open}
        handleClose={() => setOpen(false)}
      />
    </>
  )
}
