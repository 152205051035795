import { AnalyticsEvent } from 'config/analytics/segment.com/events'
import { useEffect } from 'react'
import { Params, useLocation, useParams, Location } from 'react-router-dom'

// https://stackoverflow.com/questions/66265608/react-router-v6-get-path-pattern-for-current-route
/**
 * Возвращает паттер путя который проставили в Routes. Пример пути: `/teamspase/123/document` -> `/teamspace/:tsId/documents`;
 * @param location
 * @param params
 * @returns {string} паттерн пути
 */
const getPathPattern = (location: Location, params: Params): string => {
  const { pathname } = location

  if (!Object.keys(params).length) {
    return pathname // we don't need to replace anything
  }

  let path = pathname
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`)
    }
  })
  return path
}

export const AnalyticsPathLayout = ({ children }) => {
  const location = useLocation()
  const params = useParams()

  useEffect(() => {
    AnalyticsEvent.page(getPathPattern(location, params))
  }, [location.pathname])

  return children
}
