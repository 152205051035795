import { useMemo, useState } from 'react'
import { toolbarDocumentsStatusList } from '../../helper/consts'
import { StyledTab } from 'components/tabs/tab'
import { DocumentsToolbarTabOthers } from './DocumentsToolbarTabOthers'
import { a11yProps } from 'utils/a11yprops'
import { isMobile } from 'react-device-detect'
import { useSearchParams } from 'react-router-dom'
import { EListQueryParam } from 'helper/queryParams'
import { CustomTabs } from 'components'

export const DocumentsToolbarTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const documentsStatusList = useMemo(() => {
    return !isMobile
      ? toolbarDocumentsStatusList
      : toolbarDocumentsStatusList.filter((tab) => tab.mobile)
  }, [isMobile])

  const getActiveStatusIndex = () => {
    const idx = documentsStatusList.findIndex((object) => {
      return object.key === searchParams.get(EListQueryParam.Filter)
    })
    if (idx === -1) return 0
    return idx
  }

  const [status, setStatus] = useState(getActiveStatusIndex())

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setStatus(newValue)

    searchParams.set(EListQueryParam.Filter, documentsStatusList[newValue].key)
    setSearchParams(searchParams)
  }

  return (
    <CustomTabs value={status} handleChange={handleChange}>
      {documentsStatusList.map((item, key) => (
        <StyledTab key={key} label={item.value} {...a11yProps(key)} />
      ))}
      {isMobile && (
        <DocumentsToolbarTabOthers
          select={(value) => {
            setStatus(documentsStatusList.length)

            searchParams.set(EListQueryParam.Filter, value)
            setSearchParams(searchParams)
          }}
          selectedStatus={searchParams.get(EListQueryParam.Filter)}
          toolbarDocumentsStatusList={toolbarDocumentsStatusList.filter(
            (tab) => !documentsStatusList.find((t) => t.key === tab.key)
          )}
        />
      )}
    </CustomTabs>
  )
}
