import img_close from 'assets/img/close.svg'
import img_copy from 'assets/img/content_copy.svg'
import img_download from 'assets/img/download.svg'
import img_archive from 'assets/img/menu-actions/archive.svg'
import img_delete from 'assets/img/menu-actions/delete_red.svg'
import img_mvfolder from 'assets/img/move_folder.svg'
import img_sign from 'assets/img/sign.svg'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, Button, CircularProgress, IconButton, Menu } from '@mui/material'
import { MessageSnackbar } from 'components'
import { MenuItemButton } from 'components/menu-item/menu-item-button'
import { ModalActionDocument } from 'components/modal-actions/modalActionDocument'
import { useSnackbar } from 'components/snackbar/hook'
import { useSelectedDocsContext } from 'context'
import { MassSign } from 'features'
import { ModalMoveDocumentsBetweenTeamspaces } from 'features/move-documents-between-teamspaces/components/ModalMoveDocumentsBetweenTeamspaces'
import { useDownloader } from 'hooks/useDownloader'
import { usePermissionDocumentDeleteInTeamspace } from 'pages/documents/hooks/usePermissionDocumentDeleteInTeamspace'
import { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import {
  archiveFile,
  deleteDocument,
  restoreDocument,
  unarchiveDocument,
} from 'service'
import { queryDocumentsMoveDocumentsToTeamspace } from 'service/documents'
import { downloadZip } from 'service/index'
import {
  canArchiveDocuments,
  canDeleteDocuments,
  canSignDocuments,
} from 'utils/mass-actions'
import { canDownloadDocuments } from 'utils/mass-actions/index'

export const MassActionsStandard = () => {
  const location = useLocation()
  const isInbox = location.pathname === '/inbox'

  const { tsId } = useParams()
  const { download, loading } = useDownloader(downloadZip)
  const { selectedDocsIds, setSelectedDocsIds } = useSelectedDocsContext()

  const handleDeselectAll = () => {
    setSelectedDocsIds(new Map())
  }

  const handleClickDownload = () => {
    const list = Array.from(selectedDocsIds.keys())
    download({
      tsId,
      list: {
        document_ids: list,
      },
    })
  }

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box mr={1}>
        <MassSign
          disabled={!canSignDocuments(selectedDocsIds)}
          title={'Подписать'}
          color="secondary"
          variant="outlined"
          startIcon={<Box component={'img'} src={img_sign} width={'20px'} />}
        />
      </Box>
      {!isInbox && (
        <>
          <Box mr={1}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleClickDownload}
              disabled={!canDownloadDocuments(selectedDocsIds)}
              startIcon={
                loading ? (
                  <CircularProgress thickness={5} color="inherit" />
                ) : (
                  <Box component={'img'} src={img_download} width={'20px'} />
                )
              }
            >
              Скачать
            </Button>
          </Box>

          <Box mr={1}>
            <MassDocumentActionsMenu />
          </Box>
        </>
      )}
      <Box mr={1}>
        <IconButton sx={{ borderRadius: '4px' }} onClick={handleDeselectAll}>
          <Box
            component={'img'}
            src={img_close}
            width={'20px'}
            height={'20px'}
          />
        </IconButton>
      </Box>
    </Box>
  )
}

export const MassDocumentActionsMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const { selectedDocsIds, setSelectedDocsIds, setHiddenDocumentIds, dubls } =
    useSelectedDocsContext()

  const { selectedTeamspace } = useSelector(
    (state: RootState) => state.teamspace,
    shallowEqual
  )
  const permDelete = usePermissionDocumentDeleteInTeamspace()

  const { snackbar, showSnackbar, closeSnackbar } = useSnackbar()
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false)
  const [modalArchiveOpen, setModalArchiveOpen] = useState(false)
  const [modalMoveOpen, setModalMoveOpen] = useState(false)

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const onSuccessMoveDocument = async () => {
    const documentIds = Array.from(selectedDocsIds.keys())
    const cancelMove = async () => {
      closeSnackbar()

      try {
        await queryDocumentsMoveDocumentsToTeamspace({
          documentIds: documentIds,
          teamspaceId: selectedTeamspace?.id ?? '',
        })
        documentIds.forEach((docId) => {
          setHiddenDocumentIds((state) => state.filter((_id) => _id !== docId))
        })
        showSnackbar({ message: 'Восстановлено', severity: 'info' })
      } catch (error) {
        console.error(error)
        if (error instanceof Error) {
          showSnackbar({
            message: error.message,
            severity: 'error',
            close: () => {
              setSelectedDocsIds(new Map<string, DocumentState>())
              closeSnackbar()
            },
          })
        }
      }
    }

    const btns = [
      <Button
        size="small"
        color="secondary"
        variant="outlined"
        onClick={cancelMove}
      >
        Отменить
      </Button>,
    ]
    documentIds.forEach((docId) => {
      setHiddenDocumentIds((state) => [...state, docId])
    })
    showSnackbar({
      message: 'Документы перемещены',
      severity: 'info',
      buttons: btns,
      close: () => {
        setSelectedDocsIds(new Map<string, DocumentState>())
        closeSnackbar()
      },
    })
    handleMenuClose()
  }

  return (
    <>
      <IconButton sx={{ borderRadius: '4px' }} onClick={handleClick}>
        <MoreHorizIcon
          width={'20px'}
          height={'20px'}
          fontSize="small"
          color={'secondary'}
        />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            marginTop: '2px',
            width: '240px',
            maxHeight: '100%',
          },
        }}
      >
        <MenuItemButton
          title="Дубликат"
          srcImage={img_copy}
          onClick={(e) => {
            e.stopPropagation()
            handleMenuClose()
            dubls.createDublicates({
              documentIds: Array.from(selectedDocsIds.keys()),
            })
          }}
        />
        <MenuItemButton
          title="Переместить"
          srcImage={img_mvfolder}
          onClick={(e) => {
            e.stopPropagation()
            handleMenuClose()
            setModalMoveOpen(true)
          }}
        />

        <MenuItemButton
          title="Архивировать"
          srcImage={img_archive}
          onClick={(e) => {
            e.stopPropagation()
            setModalArchiveOpen(true)
          }}
          disabled={!canArchiveDocuments()}
        />
        <MenuItemButton
          title="Удалить"
          srcImage={img_delete}
          onClick={(e) => {
            e.stopPropagation()
            setModalDeleteOpen(true)
          }}
          disabled={
            !canDeleteDocuments(selectedDocsIds) ||
            !permDelete.canDoAll(Array.from(selectedDocsIds.values()))
          }
        />
      </Menu>
      <MassModalActions
        handleClose={handleMenuClose}
        setModalDeleteOpen={setModalDeleteOpen}
        setModalArchiveOpen={setModalArchiveOpen}
        modalDeleteOpen={modalDeleteOpen}
        modalArchiveOpen={modalArchiveOpen}
      />

      <ModalMoveDocumentsBetweenTeamspaces
        open={modalMoveOpen}
        handleClose={() => setModalMoveOpen(false)}
        onSuccessCallback={onSuccessMoveDocument}
        documentIds={Array.from(selectedDocsIds.keys())}
      />

      <MessageSnackbar
        message={snackbar.message}
        severity={snackbar.severity}
        buttons={snackbar.buttons}
        clearMessage={snackbar.close}
        description={snackbar.description}
      />
    </>
  )
}

export const MassModalActions = (props) => {
  const {
    handleClose,
    setModalDeleteOpen,
    setModalArchiveOpen,
    modalDeleteOpen,
    modalArchiveOpen,
  } = props

  const closeModalDelete = () => setModalDeleteOpen(false)
  const closeModalArchive = () => setModalArchiveOpen(false)
  const {
    selectedDocsIds,
    setSelectedDocsIds,
    setHiddenDocumentIds,
    showSnackbar,
    closeSnackbar,
  } = useSelectedDocsContext()

  const deleteDoc = async (value) => {
    try {
      await deleteDocument({ id: value.id })
      return 1
    } catch (err) {
      return 0
    }
  }

  const unarchive = async (docs) => {
    try {
      for (const value of docs.values()) {
        await unarchiveDocument({ id: value.id })
        setHiddenDocumentIds((state) => state.filter((_id) => _id !== value.id))
      }
      showSnackbar({ message: 'Отменено', severity: 'info' })
    } catch (error: any) {
      if (error instanceof Error) {
        showSnackbar({
          message: error.message,
          severity: 'error',
          buttons: [],
          close: closeSnackbar,
        })
      }
    }
  }

  const restore = async (docs) => {
    try {
      for (const value of docs.values()) {
        await restoreDocument({ id: value.id })
        setHiddenDocumentIds((state) => state.filter((_id) => _id !== value.id))
      }
      showSnackbar({ message: 'Отменено', severity: 'info' })
    } catch (error: any) {
      if (error instanceof Error) {
        showSnackbar({
          message: error.message,
          severity: 'error',
          buttons: [],
          close: closeSnackbar,
        })
      }
    }
  }

  const actionDeleteDocuments = async () => {
    const docs = selectedDocsIds
    const btns = [
      <Button
        size="small"
        color="secondary"
        variant="outlined"
        sx={{ height: '24px' }}
        onClick={async () => {
          await restore(docs)
        }}
      >
        Отменить
      </Button>,
    ]
    try {
      let count = 0
      let countErr = 0

      for (const value of docs.values()) {
        const result = await deleteDoc(value)

        if (result === 1) {
          count++
          setHiddenDocumentIds((state) => [...state, value.id])
        } else {
          countErr++
        }
      }
      setSelectedDocsIds(new Map())
      showSnackbar({
        message:
          count > 0
            ? 'Документы удалены'
            : `Не удалось удалить ${countErr} документов`,
        severity: 'info',
        buttons: btns,
        close: closeSnackbar,
      })
      handleClose()
    } catch (error) {
      if (error instanceof Error) {
        showSnackbar({ message: error.message, severity: 'error' })
      }
    }
  }

  const archive = async (value) => {
    try {
      await archiveFile({ id: value.id })
      return 1
    } catch (err) {
      return 0
    }
  }

  const actionArchiveDocuments = async () => {
    const docs = selectedDocsIds
    const btns = [
      <Button
        size="small"
        color="secondary"
        variant="outlined"
        sx={{ height: '24px' }}
        onClick={() => unarchive(docs)}
      >
        Отменить
      </Button>,
    ]

    try {
      let count = 0
      let countErr = 0

      for (const value of docs.values()) {
        const result = await archive(value)
        if (result === 1) {
          count++
          setHiddenDocumentIds((state) => [...state, value.id])
        } else {
          countErr++
        }
      }
      showSnackbar({
        message:
          count > 0
            ? 'Документы архивированы'
            : `Не удалось архивировать ${countErr} документов`,
        severity: 'info',
        close: closeSnackbar,
        buttons: btns,
      })
      handleClose()
      setSelectedDocsIds(new Map())
    } catch (error) {
      if (error instanceof Error) {
        showSnackbar({ message: error.message, severity: 'error' })
      }
    }
  }

  const modalConfigs = [
    {
      key: 'delete-modal',
      handleClose: closeModalDelete,
      open: modalDeleteOpen,
      action: actionDeleteDocuments,
      title: 'Удалить документы?',
      description:
        'Документы можно будет восстановить или удалить безвозвратно в “Корзине”',
      btnTitle: `Удалить (${selectedDocsIds.size})`,
    },
    {
      key: 'archive-modal',
      handleClose: closeModalArchive,
      open: modalArchiveOpen,
      action: actionArchiveDocuments,
      title: 'Архивировать документы?',
      description:
        'Доступ к документам закроется. Документы можно разархивировать на странице “Архив”.',
      btnTitle: `Архивировать (${selectedDocsIds.size})`,
    },
  ]

  return (
    <>
      {modalConfigs.map((config) => (
        <ModalActionDocument
          key={config.key}
          handleClose={config.handleClose}
          open={config.open}
          action={config.action}
          title={config.title}
          description={config.description}
          btnTitle={config.btnTitle}
        />
      ))}
    </>
  )
}
