import { Chip } from '@mui/material'
import { StatusDict, EDocumentStatus } from '../helper/consts'

export const StatusBadge = ({ status }) => {
  return (
    <>
      <Chip
        variant={'outlined'}
        size="small"
        label={StatusDict[status] || status}
        sx={{
          height: '20px',
          fontSize: '14px',
          borderRadius: '4px',
          lineHeight: '20px',
          ...StatusBadgeStyles[status],
          '& .MuiChip-label': {
            p: '0 4px',
          },
        }}
      />
    </>
  )
}

export const StatusBadgeStyles = {
  [EDocumentStatus.REVOKED]: {
    borderColor: '#FFC4B0',
    color: '#282A2F',
    background: '#FFF0EA',
  },
  [EDocumentStatus.READY_TO_SEND]: {
    borderColor: '#BDDFFF',
    color: '#282A2F',
    background: '#E9F5FF',
  },
  [EDocumentStatus.SENT]: {
    borderColor: '#FFE2B8',
    background: '#FFF2DF',
    color: '#282A2F',
  },
  [EDocumentStatus.COMPLETED]: {
    borderColor: '#BEE4DE',
    color: '#282A2F',
    background: '#E8F7F0',
  },
  [EDocumentStatus.READY]: {
    borderColor: '#BEE4DE',
    color: '#282A2F',
    background: '#E8F7F0',
  },
}
