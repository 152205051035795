import { useState } from 'react'

export const useSnackbar = () => {
  const closeSnackbar = () => {
    setSnackbar((state) => ({
      ...state,
      message: '',
      buttons: [],
      close: closeSnackbar,
    }))
  }
  const [snackbar, setSnackbar] = useState({
    message: '',
    description: '',
    severity: '',
    buttons: [] as JSX.Element[],
    close: closeSnackbar,
  })

  const showSnackbar = ({
    message,
    description = '',
    severity,
    buttons = [] as JSX.Element[],
    close = closeSnackbar,
  }) => {
    setSnackbar((state) => ({
      ...state,
      message: message,
      description: description,
      severity: severity,
      buttons: buttons,
      close: close,
    }))
  }

  return {
    snackbar,
    setSnackbar,
    showSnackbar,
    closeSnackbar,
  }
}
