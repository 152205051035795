
export enum EServiceWorkflowStepType {
  sign = 'sign', // default
  form = 'form',
}



export interface IServiceWorkflowStepRecipients {
  role: string
  attrs?: {
    email?: string
    message?: string
    filter?: { iin?: string; bin?: string }[]
    limit?: number
  }
}

export interface IServiceWorkflowStep {
  index: number
  recipients: IServiceWorkflowStepRecipients[]
}

export interface IServiceWorkflow {
  steps: IServiceWorkflowStep[]
  cc?: { email: string }[]
  meta?: { message?: string }
}
