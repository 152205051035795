import { AnalyticsEvent } from 'config/analytics/segment.com/events'
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

export const AnalyticsIdentifyWorkspace = ({ children }) => {
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )
  const {
    teamspaces,
    loading: tsLoading,
    selectedTeamspace,
  } = useSelector((state: RootState) => state.teamspace, shallowEqual)
  const [state, setState] = useState({})

  useEffect(() => {
    if (!selectedWorkspace || !selectedTeamspace || tsLoading.teamspaces) return

    const options = {
      name: selectedWorkspace.name,
      company_name: selectedWorkspace.public_name,
      users: selectedWorkspace.members_count,
      teamspaces: teamspaces.length,
    }
    if (JSON.stringify(options) == JSON.stringify(state)) return

    setState(options)
    AnalyticsEvent.workspace_identify(selectedWorkspace.id, options)
  }, [selectedWorkspace, teamspaces, tsLoading, selectedTeamspace])

  return children
}
