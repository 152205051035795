import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ru from './locales/ru/translation.json'
import kz from './locales/kz/translation.json'
import Cache from 'i18next-localstorage-cache'
import LanguageDetector from 'i18next-browser-languagedetector'

const resources = {
  ru: {
    translation: ru,
  },
  kz: {
    translation: kz,
  },
}
i18n
  .use(initReactI18next)
  .use(Cache)
  .use(LanguageDetector)
  .init({
    resources,
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'ru',
  })

export default i18n
