import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import QRCode from 'react-qr-code'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { useContext, useEffect } from 'react'
import { fileDataFetch } from 'store/slices/applicationSlice'
import { Loading } from 'components/loading'
import { EDocumentStatus, ESignOptionType } from 'helper/consts'
import { DocumentSignListenerContext } from 'components/contexts/documentSignListener'
import { startWorkflow } from 'store/slices/wfPostmanSlice'

export const EgovQR = ({ handleClose, type = '' }) => {
  const { document }: IApplicationState = useSelector(
    (state: any) => state.application,
    shallowEqual
  )

  const { user }: IUserState = useSelector(
    (state: any) => state.user,
    shallowEqual
  )
  const { sign, loading } = useSelector(
    (state: RootState) => state.wfPostman,
    shallowEqual
  )
  const dispatch = useDispatch<any>()
  const { listenDocumentSignStatus } = useContext(DocumentSignListenerContext)

  const listenSign = () => {
    const fields = {
      documentId: document.id,
      originId: sign.origin?.recipient.origin_id ?? '',
      isRunning: true,
    }
    listenDocumentSignStatus({
      fields,
      onResponseData: (data: string) => {
        if (data === 'signed') {
          dispatch(fileDataFetch({ id: document.id }))
        }
      },
    })
  }

  useEffect(() => {
    if (sign.origin?.recipient.origin_id && sign.mgov?.qr) listenSign()
  }, [sign.origin?.recipient.origin_id])

  useEffect(() => {
    if (document.status === EDocumentStatus.SENT) return
    if (document.status === EDocumentStatus.SENT) return
    dispatch(
      startWorkflow({
        id: document.id,
        email: user.email,
        status: document.status as EDocumentStatus,
        documentType: document.type,
        option: ESignOptionType.ME,
      }) as any
    )
  }, [document.id])

  if (loading.sign) return <Loading />
  if (!sign.mgov?.qr || document.status === EDocumentStatus.COMPLETED)
    return <></>
  if (!sign.mgov?.qr || document.status === EDocumentStatus.COMPLETED)
    return <></>
  return (
    <>
      <Box p="0 16px">
        <Box display="flex" alignItems={'center'} mb="12px">
          <IconButton
            onClick={handleClose}
            children={<KeyboardBackspaceIcon />}
            sx={{ borderRadius: '4px', p: 0, mr: '8px' }}
          />
          <Typography variant="body2" fontSize={'14px'}>
            {type}
          </Typography>
        </Box>
        <Grid
          item
          xs={12}
          textAlign={'center'}
          sx={{
            background: '#F7F7F7',
            p: '10px!important',
            borderRadius: '4px',
          }}
        >
          {sign.mgov?.qr && <QRCode size={200} value={sign.mgov.qr} />}
        </Grid>
        <Box mt={'12px'}>
          <Typography variant="body1">Отсканируйте QR-код</Typography>
        </Box>
        <Box>
          <Typography
            color={'text.disabled'}
            fontSize={'12px'}
            lineHeight={'16px'}
          >
            1. Откройте мобильное приложение {type} <br />
            2. Перейдите в eGov QR <br />
            3. Сканируйте этот QR-код для подписания
          </Typography>
        </Box>
      </Box>
    </>
  )
}
