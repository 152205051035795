import { SidebarLayout } from 'layout/sidebar-layout'
import { Chat } from './components/chat'

export const AIChat = ({ handleClose }) => {
  return (
    <SidebarLayout
      header={{
        title: 'Чат Doodocs AI',
        subtitle: 'Попросите AI написать',
        handleClose: handleClose,
      }}
    >
      <Chat />
    </SidebarLayout>
  )
}
