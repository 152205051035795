import { MutableRefObject } from 'react'

export const scrollToBottom = (
  ref: MutableRefObject<HTMLDivElement | null>,
  delayMs = 10
) => {
  if (delayMs == 0) {
    ref.current?.scrollIntoView({ block: 'end' })
    return
  }

  setTimeout(async () => {
    ref.current?.scrollIntoView({ block: 'end' })
  }, delayMs)
}
