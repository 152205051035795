const isPersonal = (teamspace) => {
  return teamspace.is_personal
}

export const nonPersonalTeamspace = (teamspaces) => {
  const lenght = teamspaces.length
  if (lenght === 1) return teamspaces[0]
  if (lenght > 1)
    return isPersonal(teamspaces[0]) ? teamspaces[1] : teamspaces[0]
  return null
}
