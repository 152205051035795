import img_close from 'assets/img/close.svg'
import img_up from 'assets/img/up_circle_dark.svg'

import { Box, Button, IconButton, Typography } from '@mui/material'
import { AppBarContext } from 'context'
import { ButtonProceedSubscriptionPlan } from 'features/proceed-subscription-plan/components/ButtonProceedSubscriptionPlan'
import { EFeature, EWorkspacePlan } from 'helper/plan.types'
import { DASHBOARD_MODES } from 'layout/dashboard-layout'
import { useContext, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'store/store'
import { getWSPlanFeatureRestriction } from 'utils/plans'
import { isWorkspaceOwner } from 'utils/workspace/isWorkspaceOwner'
import { sessionUtils } from 'utils/session'

export const BannerSubscriptionPlanEnding = () => {
  const [, setVisible] = useState(true)

  const { plan_name, plan_info, workspace_id, payment_notification } =
    useAppSelector((state) => state.wsPlan, shallowEqual)
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const getPlanEndAtDaysText = () => {
    let days = 0
    days = Math.ceil(
      plan_info.workspace_limits.current_subscription_ends_in_secs /
        (60 * 60 * 24)
    )

    if (days % 10 == 1) return `${days} день`
    else if (days % 10 < 5) return `${days} дня`
    return `${days} дней`
  }

  const docsLimitExhausted = getWSPlanFeatureRestriction(
    EFeature.documents_count_per_month,
    plan_info
  ).exhausted

  const getTitle = () => {
    if (
      plan_info?.previous_plan?.name == EWorkspacePlan.Trial &&
      plan_name == EWorkspacePlan.Free
    )
      return 'Бесплатный период закончился'
    if (plan_name == EWorkspacePlan.Trial && docsLimitExhausted)
      return `Использовано ${plan_info.workspace_limits.documents_count} из ${plan_info.plan_limits.documents_count} бесплатных документов`
    if (plan_name == EWorkspacePlan.Trial)
      return `${getPlanEndAtDaysText()} до окончания бесплатного периода`
    if (
      plan_name == EWorkspacePlan.Free &&
      plan_info?.previous_plan?.name != EWorkspacePlan.Free
    )
      return 'Срок действия вашей подписки истек'
    return `Срок действия вашей подписки истекает через ${getPlanEndAtDaysText()}`
  }
  const isOwner = isWorkspaceOwner(selectedWorkspace?.group_name)

  if (workspace_id != selectedWorkspace?.id) return null
  if (plan_info.is_trial_available) return null
  if (sessionUtils.getBannerSubscriptionPlanEndingHasShownValue(workspace_id))
    return null
  if (
    plan_name == EWorkspacePlan.Free ||
    plan_name == EWorkspacePlan.Trial ||
    payment_notification.must_pay
  )
    return (
      <Box
        sx={{
          m: '6px 8px',
          background: '#F7CF51',
          padding: '8px',
          borderRadius: '4px',
          whiteSpace: 'normal',

          display: 'flex',
          flexDirection: 'column',
          gap: '8px',

          position: 'relative',
        }}
      >
        <IconButton
          size="small"
          sx={{
            cursor: 'pointer',

            position: 'absolute',
            top: 0,
            right: 0,
            '&:hover,&:focus,&:active': {
              background: 'inherit',
            },
          }}
          onClick={() => {
            sessionUtils.setBannerSubscriptionPlanEndingHasShown(workspace_id)
            setVisible(false)
          }}
        >
          <Box
            component={'img'}
            src={img_close}
            width={'16px'}
            height={'16px'}
          />
        </IconButton>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'start',
          }}
        >
          <Box
            component={'img'}
            width={'16px'}
            mt={'2px'}
            mr={'4px'}
            src={img_up}
          />
          <Box
            sx={{
              // ? Calculate close button width
              width: 'calc(100% - 16px - 20px)',
            }}
          >
            <Box>
              <Typography variant="heading6">{getTitle()}</Typography>
            </Box>
            {isOwner &&
              plan_name == EWorkspacePlan.Trial &&
              docsLimitExhausted && (
                <Box>
                  <Typography variant="paragraph4" mt={'4px'}>
                    Чтобы продолжить подписывать документы выберите тариф.
                  </Typography>
                </Box>
              )}
            {!isOwner && (
              <Box>
                <Typography variant="paragraph4" mt={'4px'}>
                  Пожалуйста, свяжитесь с владельцем пространства для выбора и
                  оплаты тарифа
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <ButtonSubscriptionPlanEnding />
      </Box>
    )

  return null
}

const ButtonSubscriptionPlanEnding = () => {
  const { setMode } = useContext(AppBarContext)
  const { workspace_id, payment_notification } = useAppSelector(
    (state) => state.wsPlan,
    shallowEqual
  )
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )
  const isOwner = isWorkspaceOwner(selectedWorkspace?.group_name)

  if (workspace_id != selectedWorkspace?.id) return null
  if (!isOwner) return
  if (payment_notification.must_pay)
    return <ButtonProceedSubscriptionPlan size="small" fullWidth />

  return (
    <Link to={'/plan'}>
      <Button
        variant={'contained'}
        color="success"
        size="small"
        fullWidth
        onClick={() => {
          if (!isOwner) return
          setMode(DASHBOARD_MODES.SETTINGS)
        }}
      >
        <Typography variant="heading6">Выбрать тариф</Typography>
      </Button>
    </Link>
  )
}
