import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ESystemVariableType } from 'features/text-editors/lexical/plugins-custom/ParameterPickerPlugin/helpers/consts/variableTypes'

export enum EManageTemplateDataStatus {
  idle = 'idle',
  initial_loading = 'initial_loading',
  infinite_loading = 'infinite_loading',
  failed = 'failed',
  success = 'success',
}

export interface IManageTemplateDocumentRecord {
  document_id: string
  created_at: string
  responses: {
    variable_id: string
    value: any
    attrs?: any
  }[]
}

export interface IManageTemplateDocumentHeader {
  variable_id: string
  variable_type: ESystemVariableType
  variable_name: string
}

export interface IManageTemplateDataState {
  template_id: string
  status: EManageTemplateDataStatus
  error: string
  records: IManageTemplateDocumentRecord[]
  header: IManageTemplateDocumentHeader[]
  isFullyLoaded: boolean
}

const getInitialState = (): IManageTemplateDataState => ({
  template_id: '',
  status: EManageTemplateDataStatus.idle,
  error: '',
  records: [],
  header: [],
  isFullyLoaded: false,
})

export const manageTemplateDataSlice = createSlice({
  name: 'templateData',
  initialState: getInitialState(),
  reducers: {
    resetState: () => getInitialState(),
    setTemplateId: (state, action: PayloadAction<string>) => {
      state.template_id = action.payload
    },
    setStatus: (state, action: PayloadAction<EManageTemplateDataStatus>) => {
      state.status = action.payload
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
    },
    setData: (
      state,
      action: PayloadAction<{
        records: IManageTemplateDocumentRecord[]
        header: IManageTemplateDocumentHeader[]
      }>
    ) => {
      state.records = action.payload.records
      state.header = action.payload.header
    },
    appendRecords: (
      state,
      action: PayloadAction<IManageTemplateDocumentRecord[]>
    ) => {
      state.records.push(...action.payload)
    },
    setIsFullyLoaded: (state, action: PayloadAction<boolean>) => {
      state.isFullyLoaded = action.payload
    },
  },
})
