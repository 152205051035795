import { createContext, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import { UrlKeys } from 'helper/consts'
import { DocumentHtml } from 'components/document'
import {
  fetchDocumentVariables,
  mergeTemplateAndDocumentVariables,
} from 'store/slices/applicationSlice'
import { useAppSelector } from 'store/store'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { DocumentSkeleton } from '../DocumentSkeleton'

type HideContextType = {
  hide: boolean
  setHide?: any
}

export const HideContext = createContext<HideContextType>({
  hide: false,
  setHide: false,
})

export const DocumentTemplateHTML = () => {
  const dispatch = useDispatch()
  const { urlId } = useUrlIdParam(UrlKeys.documentId)
  const { urlId: templateId } = useUrlIdParam(UrlKeys.templateId)

  const { loading, variables, document } = useAppSelector(
    (state) => state.application,
    shallowEqual
  )

  useEffect(() => {
    dispatch(
      fetchDocumentVariables({
        documentId: urlId.get(),
        templateId: urlId.get() ? undefined : templateId.get(),
      }) as any
    )
  }, [])

  useEffect(() => {
    variables &&
      dispatch(
        mergeTemplateAndDocumentVariables({
          templateVariables: variables,
          documentVariables: document.payload?.variables || {},
        }) as any
      )
  }, [variables])

  const showDocument = useMemo(
    () => <DocumentHtml stateVariables={document.payload?.variables} />,
    [document.payload, document.payload?.variables, loading.getVariables]
  )

  if (loading.info || loading.getVariables) return <DocumentSkeleton />

  return (
    <>
      <Box>{showDocument}</Box>
    </>
  )
}
