import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useEffect } from 'react'
import lexical from 'lexical'
import { getLocalStorageData } from 'utils/getLocalStorageData'
import { LocalStorageReserve } from 'helper/consts'

const SYMBOLS = Object.freeze({
  ancestorHasNextSibling: '|',
  ancestorIsLastChild: ' ',
  hasNextSibling: '├',
  isLastChild: '└',
  selectedChar: '^',
  selectedLine: '>',
})

export const HandleVariableDeletePlugin = () => {
  const [editor] = useLexicalComposerContext()

  function visitTree(currentNode, visitor, indent = []) {
    const childNodes = currentNode.getChildren()
    const childNodesLength = childNodes.length
    childNodes.forEach((childNode, i) => {
      visitor(
        childNode,
        indent.concat(
          i === childNodesLength - 1
            ? SYMBOLS.isLastChild
            : (SYMBOLS.hasNextSibling as any)
        )
      )

      if (lexical.$isElementNode(childNode)) {
        visitTree(
          childNode,
          visitor,
          indent.concat(
            i === childNodesLength - 1
              ? SYMBOLS.ancestorIsLastChild
              : (SYMBOLS.ancestorHasNextSibling as any)
          )
        )
      }
    })
  }

  function generateContent() {
    let variables: any[] = []
    lexical.$getSelection()
    visitTree(lexical.$getRoot(), (node) => {
      if (node.getType() == 'variable') {
        variables.push(node.__id)
      }
    })
    const savedVariables = getLocalStorageData(
      LocalStorageReserve.variableProps
    )
    let updated = false
    for (const id in savedVariables) {
      if (!variables.some((node) => node === id)) {
        delete savedVariables[id]
        updated = true
      }
    }
    updated &&
      localStorage.setItem(
        LocalStorageReserve.variableProps,
        JSON.stringify(savedVariables)
      )
  }

  useEffect(() => {
    editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        generateContent()
      })
    })
  }, [editor])

  return null
}
