import { Box, Typography } from '@mui/material'
import { IActiveIntegrationRowProps } from './types'
import {
  ActiveIntegrationRowGoogleSheets,
  IActiveIntegrationRowGoogleSheetsProps,
  isIActiveIntegrationGoogleSheetsProps,
} from './ActiveIntegrationRowGoogleSheets'
import {
  ActiveIntegrationRowWebhook,
  IActiveIntegrationRowWebhookProps,
  isIActiveIntegrationRowWebhookProps,
} from './ActiveIntegrationRowWebhook'
import { ActiveIntegrationRowBase } from './ActiveIntegrationRowBase'

export const ActiveIntegrationRow = (
  props:
    | IActiveIntegrationRowProps
    | IActiveIntegrationRowGoogleSheetsProps
    | IActiveIntegrationRowWebhookProps
) => {
  if (isIActiveIntegrationGoogleSheetsProps(props)) {
    return (
      <ActiveIntegrationRowGoogleSheets
        integration={props.integration}
        onDeleteCallback={props.onDeleteCallback}
        loading={props.loading}
      />
    )
  }

  if (isIActiveIntegrationRowWebhookProps(props))
    return (
      <ActiveIntegrationRowWebhook
        integration={props.integration}
        onDeleteCallback={props.onDeleteCallback}
        onEditSuccessCallback={props.onEditSuccessCallback}
        loading={props.loading}
      />
    )

  if (props.loading) {
    return (
      <ActiveIntegrationRowBase
        title=""
        description=""
        icon=""
        integrationId=""
        isEnabled
        loading
      />
    )
  }

  return (
    <Box>
      <Typography color={'error'}>
        Интеграция "{props.integration.integration_type}" не реализована
      </Typography>
    </Box>
  )
}
