import img_close from 'assets/img/close.svg'
import img_drag from 'assets/img/drag_indicator_black.svg'
import img_file from 'assets/img/file.svg'

import {
  Button,
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material'
import { CustomTooltip, MessageSnackbar } from 'components'
import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { getDocumentNameWithoutExtension, uploadFile } from 'utils/upload'
import { DropZoneBlockCreateDocument } from 'components/dropzone'
import {
  FileSortable,
  ModalCreateDocumentContext,
} from '../../hooks/useModalCreateDocumentContext'
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  verticalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { queryDocumentsJoinToPDF } from 'service/documents'
import { isFilesTotalSizeValid } from '../../lib'

const FileElement = ({ f }: { f: FileSortable }) => {
  const { joinFiles } = useContext(ModalCreateDocumentContext)
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: f.id })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '4px',
        padding: '6px',

        borderRadius: '4px',
        border: '1px solid var(--light-grayscale-border, #EDEDEC)',
        background: 'var(--light-grayscale-background-primary, #FFF)',

        boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.06)',

        opacity: isDragging ? 0.54 : 1,
        zIndex: isDragging ? '1' : 'auto',
        transition,
        transform: CSS.Transform.toString(transform),
      }}
      ref={setNodeRef}
      {...attributes}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <IconButton
          sx={{
            cursor: 'grab',
            '&:active': {
              cursor: 'grabbing',
            },
          }}
          {...listeners}
        >
          <Box component={'img'} src={img_drag} width={16} height={16} />
        </IconButton>
        <CustomTooltip title={f.name}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '4px',
              overflow: 'hidden',
            }}
          >
            <Box component={'img'} src={img_file} width={20} />
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {f.name}
            </Typography>
          </Box>
        </CustomTooltip>
      </Box>
      <IconButton
        sx={{
          marginLeft: 'auto',
        }}
        onClick={() => {
          joinFiles.removeFile(f.id)
        }}
      >
        <Box
          component={'img'}
          src={img_close}
          width={16}
          height={16}
          sx={{
            opacity: 0.6,
          }}
        />
      </IconButton>
    </Box>
  )
}

export const ModalContentBlockCreateDocumentJoinFiles = ({
  tsId,
  handleClose,
}: {
  tsId: string
  handleClose: () => any
}) => {
  const { joinFiles } = useContext(ModalCreateDocumentContext)

  const mouseSensor = useSensor(MouseSensor)
  const touchSensor = useSensor(TouchSensor)
  const keyboardSensor = useSensor(KeyboardSensor)

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor)

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState('')
  const [progress, setProgress] = useState(0)

  const navigate = useNavigate()

  const onDrop = async (files: File[]) => {
    try {
      setLoading(true)
      if (!isFilesTotalSizeValid([...joinFiles.files, ...files])) {
        setError('Общий размер файлов превышает ограничение в 15 МБ')
        return
      }
      joinFiles.addFiles(files)
    } catch (err: any) {
      setError(err?.message || 'Не удалось загрузить файлы')
    } finally {
      setLoading(false)
    }
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (!over || active.id === over.id) return
    const activeIndex = joinFiles.files.findIndex(
      (item) => item.id == active.id
    )
    const overIndex = joinFiles.files.findIndex((item) => item.id == over.id)
    const reorderedItems = arrayMove<any>(
      joinFiles.files,
      activeIndex,
      overIndex
    )
    joinFiles.resetFiles(reorderedItems)
  }

  const handleClick = async () => {
    try {
      setLoading(true)
      const res = await queryDocumentsJoinToPDF(joinFiles.files, setProgress)
      const file = new File(
        [res.data],
        getDocumentNameWithoutExtension(joinFiles.files[0].name),
        { type: 'application/pdf' }
      )

      const id = await uploadFile({
        file: res.data,
        name: file.name,
        ts_id: tsId,
      })
      setLoading(false)
      handleClose()

      navigate(`/teamspace/${tsId}/documents/${id}`)
    } catch (err: any) {
      setError(err?.message || 'Не удалось объединить файлы')
    } finally {
      setLoading(false)
    }
  }

  const onDropRejected = () => {
    setError(
      'Принимаются файлы только определенных форматов и весом не более 15MB'
    )
  }

  return (
    <>
      {error && (
        <MessageSnackbar
          message={error}
          clearMessage={() => setError('')}
          severity="error"
        />
      )}
      <Box>
        <Box>
          <Typography color="text.disabled">Загрузить файл</Typography>
        </Box>

        <Box mt={1}>
          <DropZoneBlockCreateDocument
            onDrop={onDrop}
            onDropRejected={onDropRejected}
            loading={loading}
          />
        </Box>
      </Box>
      {joinFiles.files && joinFiles.files.length > 0 && (
        <Box
          mt={1}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            sensors={sensors}
          >
            <SortableContext
              items={joinFiles.files.map((f) => f.id)}
              strategy={verticalListSortingStrategy}
            >
              {joinFiles.files.map((f) => {
                return <FileElement f={f} key={f.id} />
              })}
            </SortableContext>
          </DndContext>
        </Box>
      )}
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleClick}
          disabled={joinFiles.files.length == 0 || loading}
          sx={{
            gap: '8px',
          }}
        >
          {loading && (
            <CircularProgress
              sx={{ color: 'text.disabled' }}
              variant={progress == 100 ? 'indeterminate' : 'determinate'}
              value={progress}
            />
          )}
          Объединить файлы
        </Button>
      </Box>
    </>
  )
}
