import {
  Box,
  Button,
  IconButton,
  TableCell,
  TableContainer,
} from '@mui/material'
import boxDownload from 'assets/img/box-download.svg'
import img_unzip from 'assets/img/unzip.svg'
import { CustomTooltip, Loading, MessageSnackbar } from 'components'
import { Empty } from 'components/empty'
import { useSnackbar } from 'components/snackbar/hook'
import { TableCompound } from 'components/table/table-compound/TableCompound'
import { useSelectedDocsContext } from 'context'
import { useCheckbox } from 'hooks/useCheckbox'
import { shallowEqual, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { archiveFile, unarchiveDocument } from 'service'

export const ArchivedDocsTable = () => {
  const { tsId } = useParams()
  const { setHiddenDocumentIds, hiddenDocumentIds } = useSelectedDocsContext()
  const { count, documents, loading, page }: IDocTableState = useSelector(
    (state: RootState) => state.documents,
    shallowEqual
  )

  const { snackbar, showSnackbar } = useSnackbar()

  const { checkboxHeader, checkboxTable } = useCheckbox({
    page,
    rows: documents,
  })

  const cancelActionUnarchive = async (id) => {
    try {
      const res = await archiveFile({ id })
      showSnackbar({ message: 'Отменено', severity: 'info' })
      setHiddenDocumentIds((state) => state.filter((_id) => _id !== id))
      return res
    } catch (error: any) {
      if (error instanceof Error) {
        showSnackbar({
          message: error.message,
          severity: 'error',
        })
      }
    }
  }

  const actionUnarchive = async (id: string) => {
    const btns = [
      <Button
        size="small"
        color="secondary"
        variant="outlined"
        sx={{ height: '24px' }}
        onClick={() => {
          cancelActionUnarchive(id)
        }}
      >
        Отмена
      </Button>,
    ]

    try {
      const res = await unarchiveDocument({ id })
      showSnackbar({
        message: 'Документ восстановлен',
        severity: 'info',
        buttons: btns,
      })
      setHiddenDocumentIds((state) => [...state, id])
      return res
    } catch (err) {
      if (err instanceof Error) {
        showSnackbar({
          message: err.message,
          severity: 'error',
        })
      }
    }
  }

  if (loading) return <Loading />
  if (count === 0)
    return (
      <Empty
        src={boxDownload}
        title={'Архив документов пуст'}
        subtitle={'Здесь будут храниться документы, которые отправили в архив'}
      />
    )
  return (
    <>
      <MessageSnackbar
        message={snackbar.message}
        clearMessage={snackbar.close}
        severity={snackbar.severity}
        buttons={snackbar.buttons}
      />
      <TableContainer>
        <TableCompound>
          <TableCompound.Head>
            <TableCompound.Row>
              <TableCell padding="checkbox">{checkboxHeader()}</TableCell>
              <TableCompound.HeadCellDocumentName />
              <TableCompound.Cell />
            </TableCompound.Row>
          </TableCompound.Head>
          {!loading && (
            <TableCompound.Body>
              {documents.map((row, i) => {
                if (hiddenDocumentIds.includes(row.id)) return null

                return (
                  <TableCompound.RowHoverable key={row?.id || i}>
                    <TableCell padding="checkbox">
                      {checkboxTable({ row })}
                    </TableCell>
                    <TableCompound.BodyCellDocumentName
                      title={row.name}
                      to={`/teamspace/${tsId}/documents/${row.id}`}
                    />
                    <TableCompound.BodyCellDocumentOptions>
                      <Box display={'flex'} justifyContent={'end'}>
                        <CustomTooltip title="Восстановить" placement="left">
                          <IconButton
                            sx={{
                              p: '6px',
                              borderRadius: '4px',
                              '&:hover, &:focus': {
                                backgroundColor: '#EDEDEC',
                              },
                            }}
                            onClick={() => {
                              actionUnarchive(row.id)
                            }}
                          >
                            <Box
                              component={'img'}
                              src={img_unzip}
                              alt="unzip"
                              width="20px"
                            />
                          </IconButton>
                        </CustomTooltip>
                      </Box>
                    </TableCompound.BodyCellDocumentOptions>
                  </TableCompound.RowHoverable>
                )
              })}
            </TableCompound.Body>
          )}
        </TableCompound>
        {loading && <Loading />}
      </TableContainer>
    </>
  )
}
