import { Box, Button, IconButton } from '@mui/material'
import close from 'assets/img/close.svg'
import img_unzip from 'assets/img/unzip.svg'
import { useSelectedDocsContext } from 'context'
import { unarchiveDocument } from 'service'

export const MassActionsArchive = () => {
  const {
    selectedDocsIds,
    setSelectedDocsIds,
    setHiddenDocumentIds,
    showSnackbar,
    closeSnackbar,
  } = useSelectedDocsContext()

  const handleDeselectAll = () => {
    setSelectedDocsIds(new Map())
  }

  const restore = async () => {
    try {
      for (const value of selectedDocsIds.values()) {
        await unarchiveDocument({ id: value.id })
        setHiddenDocumentIds((state) => [...state, value.id])
      }
      setSelectedDocsIds(new Map())
      showSnackbar({
        message: 'Документы восстановлены',
        severity: 'info',
        close: closeSnackbar,
        buttons: [],
      })
    } catch (error: any) {
      if (error instanceof Error) {
        showSnackbar({
          message: error.message,
          severity: 'error',
          buttons: [],
          close: closeSnackbar,
        })
      }
    }
  }

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box mr={1}>
        <Button
          color="secondary"
          variant="outlined"
          startIcon={<Box component={'img'} src={img_unzip} width={'20px'} />}
          onClick={restore}
        >
          Восстановить
        </Button>
      </Box>

      <Box mr={1}>
        <IconButton sx={{ borderRadius: '4px' }} onClick={handleDeselectAll}>
          <Box component={'img'} src={close} width={'20px'} height={'20px'} />
        </IconButton>
      </Box>
    </Box>
  )
}
