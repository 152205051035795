import { ModalCenterSelectableWithTitle } from 'components/modal'
import { useEffect } from 'react'
import {
  EModalCreateDocumentStep,
  ModalCreateDocumentContext,
  useModalCreateDocumentContextDefaultValue,
} from './hooks/useModalCreateDocumentContext'
import { ModalContentBlockCreateDocumentDefault } from './components/ModalContentBlockCreateDocumentDefault'
import { ModalContentBlockCreateDocumentJoinFiles } from './components/ModalContentBlockCreateDocumentJoinFiles'

export const ModalCreateDocument = ({
  open = true,
  handleClose,
  tsId,
  driverObj,
}) => {
  const modalCreateDocumentContextDefaultValue =
    useModalCreateDocumentContextDefaultValue()

  const components = {
    [EModalCreateDocumentStep.default]: (
      <ModalContentBlockCreateDocumentDefault
        tsId={tsId}
        handleClose={handleClose}
        driverObj={driverObj}
      />
    ),
    [EModalCreateDocumentStep.join_files]: (
      <ModalContentBlockCreateDocumentJoinFiles
        tsId={tsId}
        handleClose={handleClose}
      />
    ),
  }

  useEffect(() => {
    if (!open) {
      modalCreateDocumentContextDefaultValue.switchToStepDefault()
    }
  }, [open])

  return (
    <>
      <ModalCenterSelectableWithTitle
        open={open}
        handleClose={handleClose}
        modalType="small"
        title={'Создать документ'}
        contentChild={
          <ModalCreateDocumentContext.Provider
            value={modalCreateDocumentContextDefaultValue}
          >
            {components[modalCreateDocumentContextDefaultValue.step]}
          </ModalCreateDocumentContext.Provider>
        }
      />
    </>
  )
}
