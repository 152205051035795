import { CustomTabs } from 'components/tabs/CustomTabs'
import {
  EManageTemplateTab,
  ManageTemplateTabsContext,
} from 'pages/manage-template/contexts/ManageTemplateTabsContext'
import { useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

export const ManageTemplateTabs = () => {
  const { activeTab, setActiveTab } = useContext(ManageTemplateTabsContext)

  const { loading, total_documents } = useSelector(
    (state: RootState) => state.template,
    shallowEqual
  )

  return (
    <CustomTabs
      setActiveValue={setActiveTab}
      activeValue={activeTab}
      disabled={loading.info}
      tabs={[
        {
          labelTitle: 'Документы',
          value: EManageTemplateTab.Documents,
          labelSuffixText: `${total_documents}`,
        },
        {
          labelTitle: 'Данные',
          value: EManageTemplateTab.Data,
        },
        {
          labelTitle: 'Интеграции',
          value: EManageTemplateTab.Integrations,
        },
        {
          labelTitle: 'Настройки',
          value: EManageTemplateTab.Settings,
        },
      ]}
    />
  )
}
