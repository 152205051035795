import img_code from 'assets/img/code_blank.svg'

import { Box, Button, Container, Typography } from '@mui/material'
import { MessageSnackbar } from 'components'
import { useEffect } from 'react'
import { ActiveIntegrations } from './components/ActiveIntegrations'
import { IntegrationCard } from './components/IntegrationCard'
import { IntegrationCardWebhook } from './components/IntegrationCardWebhook'
import { useActiveIntegrations } from './hooks/useActiveIntegrations'
import { useIntegrations } from './hooks/useIntegrations'

export const TabContentIntegrations = () => {
  // TODO: useActiveIntegrations - Сделать Хуком и обернуть все, так как неудобно работать
  const activeIntegrations = useActiveIntegrations()

  const { integrations } = useIntegrations(activeIntegrations.fetchIntegrations)

  useEffect(() => {
    activeIntegrations.fetchIntegrations()
  }, [])

  return (
    <Container
      sx={{
        maxWidth: '808px !important',
        p: '0 20px 48px!important',
      }}
    >
      <MessageSnackbar
        message={activeIntegrations.snackbar.message}
        clearMessage={activeIntegrations.snackbar.close}
        severity={activeIntegrations.snackbar.severity}
        buttons={activeIntegrations.snackbar.buttons}
      />
      <ActiveIntegrations integrations={activeIntegrations} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'start',
          pt: '20px',
          pb: '16px',
        }}
      >
        <Box display={'flex'} flexDirection={'column'}>
          <Typography variant="heading6">Интеграции</Typography>
          <Typography variant="paragraph4" color={'text.disabled'}>
            Сделайте шаблон еще более мощным, используя эти инструменты
          </Typography>
        </Box>

        <Button
          startIcon={<Box component={'img'} src={img_code} sizes="16px" />}
          variant="text"
          href="#tally-open=w8ZYbo&tally-layout=modal&tally-width=374"
        >
          <Typography variant="paragraph3">Предложить интеграцию</Typography>
        </Button>
      </Box>

      <Box display={'flex'} flexWrap={'wrap'} gap={2}>
        {integrations.map((props) => {
          if (props.title == 'Webhook')
            return (
              <IntegrationCardWebhook
                key={props.title}
                onCreateIntegrationCallback={
                  activeIntegrations.fetchIntegrations
                }
              />
            )
          return <IntegrationCard {...props} key={props.title} />
        })}
      </Box>
    </Container>
  )
}
