import { Box, Typography, IconButton, Select, MenuItem } from '@mui/material'
import { StatusBadge } from 'components'
import { StatusBadgeStyles } from 'components/status-badge'
import close_img from 'assets/img/close-btn.svg'
import img_arrow_down from 'assets/img/keyboard_arr_down.svg'
import { useSearchBarContext } from 'context'
import { useSearchParams } from 'react-router-dom'
import { EListQueryParam } from 'helper/queryParams'

export const FilterStatus = () => {
  const { setStatusQuery, statusQuery, availableListOfStatus } =
    useSearchBarContext()

  const [searchParams, setSearchParams] = useSearchParams()

  const handleChange = (event) => {
    const newStatus = event.target.value
    setStatusQuery(newStatus)

    searchParams.set(EListQueryParam.Status, newStatus)
    setSearchParams(searchParams)
  }

  const clearStatus = () => {
    setStatusQuery('')

    searchParams.delete(EListQueryParam.Status)
    setSearchParams(searchParams)
  }

  const renderStatusValue = (value) => {
    if (value === '') {
      return <Box component={'span'}>Статус</Box>
    }
    const option = availableListOfStatus?.find((o) => o.key === value)
    return option ? (
      <Box display={'flex'} alignItems={'center'} height={'20px'}>
        <Typography variant="body1">Статус </Typography>
        <Box
          ml={'4px'}
          borderRadius={'4px'}
          border={'1px solid #EDEDEC'}
          padding={'0 4px'}
          display={'flex'}
          alignItems={'center'}
          sx={StatusBadgeStyles[option.key]}
        >
          <Typography variant="body1"> {option.value}</Typography>
          <IconButton
            onMouseDown={(e) => {
              e.stopPropagation()
              e.preventDefault()
              clearStatus()
            }}
            sx={{
              ml: '4px',
              borderRadius: '4px',
              padding: '0!important',

              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
            }}
          >
            <Box
              component={'img'}
              src={close_img}
              width={'12px'}
              height={'12px'}
            />
          </IconButton>
        </Box>
      </Box>
    ) : (
      ''
    )
  }

  return (
    <Box>
      <Select
        IconComponent={() => (
          <Box
            component={'img'}
            src={img_arrow_down}
            width={'15px'}
            height={'15px'}
            display={'inline-block'}
            position={'absolute'}
            right={'8px'}
            top={'calc(50% - 0.5em)'}
            sx={{ pointerEvents: 'none' }}
          />
        )}
        MenuProps={{
          PaperProps: {
            style: {
              width: '100%',
              marginTop: '2px',
              maxHeight: '320px',
            },
          },
        }}
        fullWidth
        name="filter"
        value={statusQuery}
        onChange={handleChange}
        sx={{ p: 0, maxWidth: 'fit-content' }}
        displayEmpty
        renderValue={renderStatusValue}
      >
        {availableListOfStatus?.map((option) => (
          <MenuItem key={option.key} value={option.key} sx={{ m: '2px 4px' }}>
            <Box display={'flex'} alignItems="center" flexDirection={'row'}>
              <StatusBadge status={option.key} />
            </Box>
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
