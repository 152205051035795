export enum ECustomVariableType {
  INPUT_TEXT_ADDRESS = 'INPUT_TEXT_ADDRESS',
  INPUT_TEXT_NAME = 'INPUT_TEXT_NAME',
  INPUT_TEXT_LAST_NAME = 'INPUT_TEXT_LAST_NAME',
  INPUT_TEXT_FATHER_NAME = 'INPUT_TEXT_FATHER_NAME',
  INPUT_TEXT_IIN = 'INPUT_TEXT_IIN',
  INPUT_TEXT_BIN = 'INPUT_TEXT_BIN',
}

export enum ESystemVariableType {
  INPUT_TEXT = 'INPUT_TEXT',
  TEXT_AREA = 'TEXT_AREA',
  DROPDOWN = 'DROPDOWN',
  DROPDOWN_OPTION = 'DROPDOWN_OPTION',
  INPUT_EMAIL = 'INPUT_EMAIL',
  INPUT_NUMBER = 'INPUT_NUMBER',
  INPUT_PHONE_NUMBER = 'INPUT_PHONE_NUMBER',
  INPUT_DATE = 'INPUT_DATE',
  CHECKBOX = 'CHECKBOX',
}
