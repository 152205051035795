import img_trash from 'assets/img/ic_delete.svg'

import { Box, Divider, Menu, TextField, Typography } from '@mui/material'
import { MenuItemButton } from 'components/menu-item/menu-item-button'
import { ERecipientRole } from 'helper/consts'
import { IRecipient } from 'helper/workflow/recipients/IRecipient'
import { ERecipientAttr } from 'helper/workflow/recipients/types'
import { useContext } from 'react'
import { getObjectClone } from 'utils/getObjectClone'
import { WorfklowBuilderUIContext } from '../../../../providers/WorkflowBuilderUIContext'
import { RecipientSettingCheckSign } from '../RecipientMenuSettings/RecipientSettingCheckSign'
import { RecipientSettingMessage } from '../RecipientMenuSettings/RecipientSettingMessage'

export const RecipientsSignerMenu = ({
  open,
  anchorEl,
  onClose,
  recipient,
}: {
  open: boolean
  anchorEl: HTMLElement | null
  onClose: (() => void) | any
  recipient?: IRecipient
}) => {
  const workflowBuilderUI = useContext(WorfklowBuilderUIContext)
  const handleDelete = () => {
    if (!recipient) return

    const state = workflowBuilderUI.workflow
    state.removeRecipient(recipient)
    workflowBuilderUI.setWorkflow(getObjectClone(state))
    onClose()
  }

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      sx={{
        mt: '2px',
      }}
      PaperProps={{
        style: {
          maxHeight: '100%',
        },
      }}
    >
      {recipient?.role == ERecipientRole.anonymous_signer_rk && (
        <Box p={'6px 16px'}>
          <TextField
            fullWidth
            onChange={(e) => {
              if (recipient) recipient.attrs.link_name = e.target.value
            }}
          />
          <Typography variant="paragraph4" color={'text.disabled'}>
            Название ссылки, чтобы отличить эту ссылку от других в списке
            отслеживания
          </Typography>
        </Box>
      )}
      {recipient?.mayContainAttr(ERecipientAttr.filter_iin) &&
        recipient?.mayContainAttr(ERecipientAttr.filter_bin) && (
          <RecipientSettingCheckSign recipient={recipient} />
        )}
      {recipient?.mayContainAttr(ERecipientAttr.message) && (
        <RecipientSettingMessage recipient={recipient} />
      )}
      <Divider />
      <MenuItemButton
        srcImage={img_trash}
        title="Удалить"
        onClick={() => {
          handleDelete()
        }}
      />
    </Menu>
  )
}
