import { StyledTabs } from 'components/tabs/tab'

export const CustomTabs = ({
  children,
  handleChange,
  value,
}: {
  children: any
  handleChange: (event: React.SyntheticEvent, newValue: number) => void
  value: number
}) => {
  return (
    <StyledTabs
      value={value}
      onChange={handleChange}
      sx={{
        borderBottom: '1px solid #EDEDEC',
        minHeight: '32px',
        maxHeight: '32px',
      }}
      TabIndicatorProps={{
        sx: {
          background: '#1C1C1C',
        },
      }}
    >
      {children}
    </StyledTabs>
  )
}
