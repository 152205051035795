import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { StatusBadge } from 'components/status-badge'
import editImg from 'assets/img/edit.svg'
import folder from 'assets/img/folder_open.svg'
import { useState } from 'react'
import { updateCampaignName } from 'service'
import { EDocumentStatus } from 'helper/consts'

export const CampaignsTable = () => {
  const { campaigns }: ICampaignState = useSelector(
    (state: RootState) => state.campaign,
    shallowEqual
  )
  const [state, setState] = useState(
    campaigns.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.id]: { ...item, error: '', edit: false } }),
      {}
    )
  )
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      Object.keys(state).forEach(async (item) => {
        if (state[item].edit) {
          state[item].name &&
            (await updateCampaignName({ id: item, name: state[item].name }))

          if (state[item].name) {
            setState((state) => ({
              ...state,
              [item]: {
                ...state[item],
                edit: false,
                error: false,
              },
            }))
          } else {
            setState((state) => ({
              ...state,
              [item]: {
                ...state[item],
                error: true,
              },
            }))
          }
        }
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table" color="secondary">
          <TableHead>
            <TableRow>
              <TableCell color="text.secondary" sx={{ pl: 0, width: '60%' }}>
                Рассылка
              </TableCell>
              <TableCell color="text.secondary" sx={{ pl: 0 }}>
                Статус
              </TableCell>
              <TableCell color="text.secondary" sx={{ pl: 0 }}>
                Количество документов
              </TableCell>
              <TableCell sx={{ textAlign: 'end', width: 0 }}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.keys(state).map((row, i) => (
              <TableRow key={i}>
                <TableCell
                  sx={{ pl: 0, cursor: 'pointer' }}
                  color="secondary"
                  onClick={() => navigate(state[row].id)}
                >
                  <div style={{ display: 'flex', width: '100%' }}>
                    <img src={folder} style={{ marginRight: '8px' }} alt="" />

                    {!state[row].edit &&
                      state[row].status !== EDocumentStatus.DRAFT && (
                        <Typography
                          fontWeight={500}
                          color={'text.primary'}
                          textOverflow={'ellipsis'}
                          overflow={'hidden'}
                          maxWidth={'240px'}
                          sx={{ textWrap: 'nowrap', wordBreak: 'keep-all' }}
                        >
                          {state[row].name}
                        </Typography>
                      )}

                    {!state[row].edit &&
                      state[row].status === EDocumentStatus.DRAFT &&
                      state[row].name}

                    {state[row].edit && (
                      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <TextField
                          fullWidth
                          onBlur={handleSubmit}
                          autoFocus={true}
                          name={row}
                          tabIndex={0}
                          variant={'outlined'}
                          defaultValue={state[row].name}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            setState((state) => ({
                              ...state,
                              [row]: {
                                ...state[row],
                                name: e.target.value,
                                error: false,
                              },
                            }))
                          }
                          inputProps={{
                            style: {
                              padding: 5,
                            },
                          }}
                          error={state[row].error ? true : false}
                        />
                      </form>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <StatusBadge status={state[row].status} />
                </TableCell>
                <TableCell>{state[row].documents}</TableCell>
                <TableCell
                  sx={{ textAlign: 'end', width: 0, p: '4px 8px!important' }}
                >
                  <IconButton
                    sx={{
                      borderRadius: '4px',
                      background:
                        state[row].edit && state[row].id === state[row].id
                          ? '#E8E8E8'
                          : '',
                    }}
                    onClick={() => {
                      setState((state) => ({
                        ...state,
                        [row]: {
                          ...state[row],
                          edit: !state[row].edit,
                        },
                      }))
                    }}
                    children={
                      <img
                        src={editImg}
                        alt=""
                        width="20px"
                        height="20px"
                        style={{ marginRight: '5px' }}
                      />
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
