import { Box, Typography } from '@mui/material'
import { IPlanInfo } from 'pages/plan/types'
import { useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { ButtonPlan } from '../ButtonPlan'
import { SelectPlanContext } from '../SelectPlanProvider'
import { TextActivePlanUntilDate } from '../TextActivePlanUntilDate'
import { PriceBlock } from './PriceBlock'
import { FeatureLimit } from './FeatureLimit'

export const PlanCard = ({ plan }: { plan: IPlanInfo }) => {
  const { plan_name } = useSelector(
    (state: RootState) => state.wsPlan,
    shallowEqual
  )
  const { setPlan, selectedPeriod } = useContext(SelectPlanContext)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',

        padding: '24px',

        borderRadius: '4px',
        border: '1px solid var(--light-grayscale-border, #EDEDEC)',
        background: 'var(--Color, #FFF)',
        boxShadow:
          '0px 6px 10px 0px rgba(0, 0, 0, 0.08), 0px 1px 18px 0px rgba(0, 0, 0, 0.06)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <Typography variant="heading5">{plan.name}</Typography>
        <PriceBlock plan={plan} selectedPeriod={selectedPeriod} />
        <Box width={'100%'}>
          <ButtonPlan
            planType={plan.name}
            isSelected={plan_name === plan.name}
            onClick={() => {
              setPlan(plan.name)
            }}
          />
        </Box>
        {plan_name === plan.name && (
          <Box
            sx={{
              mx: 'auto',
              textAlign: 'center',
            }}
          >
            <TextActivePlanUntilDate />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        {Object.keys(plan.features).map((feature) => {
          return <FeatureLimit plan={plan} feature={feature} key={feature} />
        })}
      </Box>
    </Box>
  )
}
