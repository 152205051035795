import { Grid, Button, Box } from '@mui/material'
import google_img from 'assets/img/google.svg'

export const GoogleLoginButton = ({ handleClick }) => {
  return (
    <Grid item xs={12}>
      <Button
        fullWidth
        color="secondary"
        variant="outlined"
        onClick={handleClick}
      >
        <Box component={'img'} src={google_img} m={'0 4px'} />С аккаунтом Google
      </Button>
    </Grid>
  )
}
