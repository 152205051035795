import { SettingItemWithToggle } from 'components/menu-item/SettingItemWithToggle'
import { FormikContextType, useFormikContext } from 'formik'
import * as Yup from 'yup'

export interface IFormikFieldsClosingDocuments {
  require_closing_documents: boolean
}

export interface IFormikFormClosingDocumentsProps {
  formik: FormikContextType<IFormikFieldsClosingDocuments>
}

export const getFormClosingDocumentsValidationSchema = () => {
  return Yup.object().shape({
    require_closing_documents: Yup.boolean(),
  })
}

export const FormikFormClosingDocuments = () => {
  const formik = useFormikContext<IFormikFieldsClosingDocuments>()

  return (
    <SettingItemWithToggle
      checked={formik.values.require_closing_documents}
      title={{ text: 'Мне нужны закрывающие документы', isHeading: false }}
      onClick={() => {
        formik.setFieldValue(
          'require_closing_documents',
          !formik.values.require_closing_documents
        )
      }}
      disabled={formik.isSubmitting}
    />
  )
}
