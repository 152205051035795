import { LexicalNode } from "lexical";

/**
 * $findNodeByTypeFromParents - returns first found node moving from `startNode` to `parents`. If `startNode` isThisNode, returns startNode
 * @param startNode - start point node
 * @param isThisNode - instance of function
 * @returns 
 */
export function $findNodeByTypeFromParents<T extends LexicalNode>(startNode: LexicalNode, isThisNode: (n: LexicalNode | null | undefined) => n is T): (T | null | undefined) {
  let nd: LexicalNode | null | undefined = startNode;
  while (nd != null) {
    if (isThisNode(nd)) return nd;
    nd = nd.getParent();
  }
  return nd;
}
