import { Box, Grid } from '@mui/material'
import { Loading } from 'components'
import EditorLexical from 'features/text-editors/lexical'
import { EDocumentStatus, EditorModes, UrlKeys } from 'helper/consts'
import {
  DocumentEditorContext,
  useDocumentEditorContextDefaultValue,
} from 'hooks/useDocumentEditorContextDefaultValue'
import { useUrlIdParam } from 'hooks/useUrlIdParam'
import { AIChat } from 'layout/sidebar-layout/aichat'

import {
  AiChatProvider,
  FillerFormikContextProvider,
  SidebarModeProvider,
  useAiChatContext,
  useSidebarModeContext,
} from 'context'
import { withDashboardLayout } from 'layout'
import { sidebarWidth } from 'layout/sidebar-layout'
import {
  Details,
  DocumentPreviewHeader,
  EditDocumentName,
} from 'pages/document-preview/components'
import { SidebarMode } from 'pages/document-preview/hooks/useSideBarState'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { reset } from 'store/slices/applicationSlice'
import { wfPostmanActions } from 'store/slices/wfPostmanSlice'
import { useDocumentEditorContentLoad } from './hooks/useDocumentEditorContentLoad'

const DocumentEditorContent = () => {
  return <DocumentContent />
}

const DocumentContent = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { urlId } = useUrlIdParam(UrlKeys.documentId)
  const { sidebarMode, sidebarIsOpen, closeSidebar } = useSidebarModeContext()
  const aiChatContextValue = useAiChatContext()
  const { document, loading }: IApplicationState = useSelector(
    (state: RootState) => state.application,
    shallowEqual
  )
  const { tsId } = useParams()

  useEffect(() => {
    const id = urlId.get()
    if (id && document.status !== EDocumentStatus.DRAFT)
      if (tsId) navigate(`/teamspace/${tsId}/documents/${id}`)
      else navigate(`/documents/${id}`)
  }, [urlId.get(), document.status])

  useEffect(() => {
    return () => {
      dispatch(reset())
      dispatch(wfPostmanActions.clearLinks())
    }
  }, [])

  const documentEditorContextValue = useDocumentEditorContextDefaultValue(
    EditorModes.document
  )
  const { isDocumentLoaded } = useDocumentEditorContentLoad()

  const createDocumentIfNotExistAndLoadChat = async () => {
    const doc_id = urlId.get()
    if (!doc_id) documentEditorContextValue.saveToServer()

    // ? Get Chat History If Not Inited
    if (!aiChatContextValue.state.id || aiChatContextValue.state.messages.error)
      aiChatContextValue.init(doc_id)
  }

  useEffect(() => {
    if (sidebarMode === SidebarMode.chat) createDocumentIfNotExistAndLoadChat()
  }, [sidebarMode, urlId.get()])

  const getSidebarContent = () => {
    switch (sidebarMode) {
      case SidebarMode.chat:
        return <AIChat handleClose={closeSidebar} />
      case SidebarMode.info:
        return <Details handleClose={closeSidebar} />
      default:
        return null
    }
  }

  if (document.status !== EDocumentStatus.DRAFT) return <Loading />
  if (loading.info || loading.source) return <Loading />

  return (
    <Grid container>
      <Box
        sx={{
          pt: '62px',
          pb: '20px',
          width: sidebarIsOpen() ? `calc(100% - ${sidebarWidth})` : '100%',
          maxWidth: '100vw',
          maxHeight: 'calc(100vh - 48px)',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            p: '0 20px',
            m: '0 auto',
            maxWidth: '840px',
          }}
        >
          <Box mb={'20px'}>
            <EditDocumentName />
          </Box>

          {isDocumentLoaded ? (
            <DocumentEditorContext.Provider value={documentEditorContextValue}>
              <EditorLexical />
            </DocumentEditorContext.Provider>
          ) : null}
        </Box>
      </Box>
      <Box
        sx={{
          width: sidebarIsOpen() ? sidebarWidth : '0',
          maxWidth: sidebarWidth,
        }}
      >
        {getSidebarContent()}
      </Box>
    </Grid>
  )
}

export const DocumentEditor = () =>
  withDashboardLayout(DocumentEditorContent)({
    Header: DocumentPreviewHeader,
    providers: [
      AiChatProvider,
      SidebarModeProvider,
      FillerFormikContextProvider,
    ],
  })
