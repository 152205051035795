import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  DEPRECATED_$isGridSelection,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  LexicalEditor,
  OUTDENT_CONTENT_COMMAND,
  TextFormatType,
} from 'lexical'
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
} from '@lexical/list'
import { $createHeadingNode, HeadingTagType } from '@lexical/rich-text'
import { $setBlocksType } from '@lexical/selection'
import { EToolsElement } from '../types'
import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode'
import { $isHeadingNode, $isQuoteNode } from '@lexical/rich-text'
import { $getNearestBlockElementAncestorOrThrow } from '@lexical/utils'

const formatParagraph = (editor: LexicalEditor) => {
  editor.update(() => {
    const selection = $getSelection()
    if (
      $isRangeSelection(selection) ||
      DEPRECATED_$isGridSelection(selection)
    ) {
      $setBlocksType(selection, () => $createParagraphNode())
    }
  })
}

const formatHeading = (editor: LexicalEditor, headingSize: HeadingTagType) => {
  editor.update(() => {
    const selection = $getSelection()
    if (
      $isRangeSelection(selection) ||
      DEPRECATED_$isGridSelection(selection)
    ) {
      $setBlocksType(selection, () => $createHeadingNode(headingSize))
    }
  })
}

const formatBulletList = (editor: LexicalEditor) => {
  editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
}

const formatNumberedList = (editor: LexicalEditor) => {
  editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
}

const formatTextClear = (editor: LexicalEditor) => {
  editor.update(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchor = selection.anchor
      const focus = selection.focus
      const nodes = selection.getNodes()

      if (anchor.key === focus.key && anchor.offset === focus.offset) {
        return
      }

      nodes.forEach((node, idx) => {
        // We split the first and last node by the selection
        // So that we don't format unselected text inside those nodes
        if ($isTextNode(node)) {
          if (idx === 0 && anchor.offset !== 0) {
            node = node.splitText(anchor.offset)[1] || node
          }
          if (idx === nodes.length - 1) {
            node = node.splitText(focus.offset)[0] || node
          }

          if (node.__style !== '') {
            node.setStyle('')
          }
          if (node.__format !== 0) {
            node.setFormat(0)
            $getNearestBlockElementAncestorOrThrow(node).setFormat('')
          }
        } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
          node.replace($createParagraphNode(), true)
        } else if ($isDecoratorBlockNode(node)) {
          node.setFormat('')
        }
      })
    }
  })
}


export const formatSelectedTextTo = {
  [EToolsElement.P]: (editor: LexicalEditor) => formatParagraph(editor),

  [EToolsElement.H1]: (editor: LexicalEditor) => formatHeading(editor, 'h1'),
  [EToolsElement.H2]: (editor: LexicalEditor) => formatHeading(editor, 'h2'),
  [EToolsElement.H3]: (editor: LexicalEditor) => formatHeading(editor, 'h3'),
  [EToolsElement.H4]: (editor: LexicalEditor) => formatHeading(editor, 'h4'),
  [EToolsElement.H5]: (editor: LexicalEditor) => formatHeading(editor, 'h5'),
  [EToolsElement.H6]: (editor: LexicalEditor) => formatHeading(editor, 'h6'),

  [EToolsElement.ListBul]: (editor: LexicalEditor) => formatBulletList(editor),
  [EToolsElement.ListNum]: (editor: LexicalEditor) => formatNumberedList(editor),

  [EToolsElement.Bold]: (editor: LexicalEditor) => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold'),
  [EToolsElement.Italic]: (editor: LexicalEditor) => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic'),
  [EToolsElement.Underline]: (editor: LexicalEditor) => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline'),
  [EToolsElement.Strikethrough]: (editor: LexicalEditor) => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough'),
  [EToolsElement.Code]: (editor: LexicalEditor) => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code'),
  [EToolsElement.Subscript]: (editor: LexicalEditor) => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'subscript'),
  [EToolsElement.Superscript]: (editor: LexicalEditor) => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'subscript'),
  [EToolsElement.TextFormatClear]: (editor: LexicalEditor) => formatTextClear(editor),

  [EToolsElement.AlignLeft]: (editor: LexicalEditor) => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left'),
  [EToolsElement.AlignCenter]: (editor: LexicalEditor) => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center'),
  [EToolsElement.AlignRight]: (editor: LexicalEditor) => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right'),
  [EToolsElement.AlignJustify]: (editor: LexicalEditor) => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify'),

  [EToolsElement.Outdent]: (editor: LexicalEditor) => editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined),
  [EToolsElement.Indent]: (editor: LexicalEditor) => editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined),
}
