import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getTemplateManageGetDocumentsHeader,
  queryTemplateManageGetDocumentsRecords,
} from 'service/template'
import { EManageTemplateDataStatus, manageTemplateDataSlice } from '..'

export const initialLoadManageTemplateData = createAsyncThunk(
  'manageTemplateData/initialLoadManageTemplateData',
  async (
    {
      templateId,
      limit,
    }: {
      templateId: string
      limit?: number
    },
    thunkApi
  ) => {
    if (!templateId) return
    const limitValue = limit ? limit : 50
    const { dispatch } = thunkApi

    dispatch(manageTemplateDataSlice.actions.resetState())
    dispatch(manageTemplateDataSlice.actions.setTemplateId(templateId))
    dispatch(
      manageTemplateDataSlice.actions.setStatus(
        EManageTemplateDataStatus.initial_loading
      )
    )
    try {
      const rValues = await queryTemplateManageGetDocumentsRecords({
        templateId,
        offset: 0,
        limit: limitValue,
      })
      const rHeaders = await getTemplateManageGetDocumentsHeader({
        templateId,
      })

      dispatch(
        manageTemplateDataSlice.actions.setData({
          records: rValues.data.records,
          header: rHeaders.data.header,
        })
      )
      if (rValues.data.records.length < limitValue) {
        dispatch(manageTemplateDataSlice.actions.setIsFullyLoaded(true))
      }

      dispatch(
        manageTemplateDataSlice.actions.setStatus(
          EManageTemplateDataStatus.success
        )
      )
    } catch (error: any) {
      dispatch(manageTemplateDataSlice.actions.setError(error.message))
      dispatch(
        manageTemplateDataSlice.actions.setStatus(
          EManageTemplateDataStatus.failed
        )
      )
    }
  }
)
