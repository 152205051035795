import { Box, Typography, Button, MenuItem } from '@mui/material'
import { MessageSnackbar } from 'components'
import { ModalCenterSelectableWithTitle } from 'components/modal'
import { useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import {
  clearNotifications,
  deleteWorkspaceInvitee,
} from 'store/slices/workspace'

export const DeleteInvitee = ({ id }) => {
  const [open, setOpen] = useState(false)

  const { loading, notification } = useSelector(
    (state: RootState) => state.workspace,
    shallowEqual
  )
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.selectedWorkspace,
    shallowEqual
  )

  const handleClose = () => setOpen(false)

  const handleClick = () => {
    setOpen(true)
  }

  const dispatch = useDispatch()

  const handleDelete = async () => {
    await dispatch(
      deleteWorkspaceInvitee({
        id,
        wsId: selectedWorkspace?.id || '',
      }) as any
    )
  }

  return (
    <>
      <MessageSnackbar
        message={notification.delete_member}
        clearMessage={() => dispatch(clearNotifications() as any)}
        severity={notification.deleteWs}
      />
      <ModalCenterSelectableWithTitle
        open={open}
        handleClose={handleClose}
        title={'Вы уверены, что хотите удалить приглашение?'}
        modalType="small"
        contentChild={
          <Typography variant="body1">
            Участник не сможет получить доступ к пространству, его приглашение
            будет деактивировано
          </Typography>
        }
        footerChild={
          <Box justifyContent="flex-end" display={'flex'}>
            <Button
              sx={{ mr: '8px' }}
              color="secondary"
              onClick={() => setOpen(false)}
              variant="outlined"
            >
              Отменить
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={handleDelete}
              disabled={loading.delete_member ? true : false}
            >
              Удалить
            </Button>
          </Box>
        }
      />

      <MenuItem
        value={'Удалить из группы'}
        key={'Удалить из группы'}
        onClick={handleClick}
      >
        <Typography color={'#E92C2C'}>Удалить приглашение</Typography>
      </MenuItem>
    </>
  )
}
