import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $insertNodeToNearestRoot, mergeRegister } from '@lexical/utils'
import {
  $createParagraphNode,
  $getNodeByKey,
  COMMAND_PRIORITY_NORMAL,
  LexicalNode,
  NodeKey,
  ParagraphNode,
} from 'lexical'
import { useEffect } from 'react'

import {
  $isColumnsListNode,
  ColumnsListNode,
} from '../../nodes/ColumnsListNode'
import {
  $createColumnsListItemNode,
  $isColumnsListItemNode,
  ColumnsListItemNode,
} from '../../nodes/ColumnsListNode/ColumnsListItemNode'
import { $createColumnsListNodeWithNodes } from '../../nodes/ColumnsListNode/utils'
import { $findNodeByTypeFromParents } from '../../utils/findNodeByTypeFromParents'
import { ColumnsListItemsResizerPlugin } from './ColumnsListItemsResizerPlugin'
import {
  CREATE_SIBLING_COLUMNS_LIST_ITEM_COMMAND,
  CREATE_SIBLING_COLUMNS_LIST_ITEM_WITH_CHILD_NODE_COMMAND,
  DELETE_COLUMNS_LIST_ITEM_COMMAND,
  INSERT_COLUMNS_LIST_COMMAND,
  TColumnsListItemInsertProp,
} from './commands'

export const createSiblingColumnsListItemWithChildNode = (params: {
  key: NodeKey
  insert: TColumnsListItemInsertProp
  childNode: LexicalNode
}): boolean => {
  const columnsListItemNode = $getNodeByKey(params.key)
  if (!columnsListItemNode || !$isColumnsListItemNode(columnsListItemNode))
    return false

  const columnsListNode = $findNodeByTypeFromParents(
    columnsListItemNode,
    $isColumnsListNode
  )
  if (!columnsListNode) return false

  const childs = columnsListNode.getChildren<ColumnsListItemNode>()
  const nextWidthPrecent = 100 / (childs.length + 1)
  const sumRemainPrecent =
    100 - childs.reduce((pSum, ch) => pSum + ch.__widthPrecent, 0)
  const chngPrec = (100 - nextWidthPrecent) / 100

  for (let i = 0; i < childs.length; i++) {
    const child = childs[i]
    child.updateWidthPrecent(
      (child.__widthPrecent + sumRemainPrecent / childs.length) * chngPrec
    )
  }

  const newNode = $createColumnsListItemNode(nextWidthPrecent).append(
    params.childNode
  )

  if (params.insert === 'after') {
    columnsListItemNode.insertAfter(newNode)
  } else if (params.insert === 'before') {
    columnsListItemNode.insertBefore(newNode)
  } else if (params.insert === 'end') {
    columnsListNode.append(newNode)
  }
  newNode.selectEnd()
  return true
}

export function ColumnsListPlugin({
  anchorElem = document.body,
}: {
  anchorElem?: HTMLElement
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([ColumnsListNode, ColumnsListItemNode])) {
      throw new Error(
        'ColumnsListPlugin: [ColumnsListNode, ColumnsListItemNode] not registered on editor'
      )
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_COLUMNS_LIST_COMMAND,
        (columns = 2) => {
          if (columns < 1) return false

          const paragraphNodes: ParagraphNode[] = Array.from(
            Array(columns),
            () => $createParagraphNode()
          )

          const container = $createColumnsListNodeWithNodes(paragraphNodes)
          $insertNodeToNearestRoot(container)
          const columnsListItemNodes = container.getChildren()
          if (columnsListItemNodes.length < 1) return false

          if (!$isColumnsListItemNode(columnsListItemNodes[0])) return false
          columnsListItemNodes[0].selectEnd()

          return true
        },
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand(
        CREATE_SIBLING_COLUMNS_LIST_ITEM_COMMAND,
        (params) => {
          return createSiblingColumnsListItemWithChildNode({
            key: params.key,
            insert: params.insert,
            childNode: $createParagraphNode(),
          })
        },
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand(
        CREATE_SIBLING_COLUMNS_LIST_ITEM_WITH_CHILD_NODE_COMMAND,
        (props) => {
          return createSiblingColumnsListItemWithChildNode({
            key: props.key,
            insert: props.insert,
            childNode: props.childNode,
          })
        },
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand(
        DELETE_COLUMNS_LIST_ITEM_COMMAND,
        ({
          key,
          unwrapSingleColumnsListItem,
        }: {
          key: NodeKey
          unwrapSingleColumnsListItem?: boolean
        }) => {
          const node = $getNodeByKey(key)
          if (!node || !$isColumnsListItemNode(node)) return false

          const columnsListNode = $findNodeByTypeFromParents(
            node,
            $isColumnsListNode
          )
          if (!columnsListNode) return false
          const childs = columnsListNode.getChildren<ColumnsListItemNode>()

          if (childs.length === 1) {
            columnsListNode.remove()
            return true
          } else if (childs.length === 2 && unwrapSingleColumnsListItem) {
            const childNode = childs.find((ch) => ch.getKey() !== node.getKey())
            if (!childNode) return false

            childNode.getChildren().forEach((child) => {
              columnsListNode.insertAfter(child)
            })
            columnsListNode.remove()
            return true
          }

          const remainPrecent = 100 - node.__widthPrecent
          let totalPrecent = 0
          for (let i = 0; i < childs.length; i++) {
            const child = childs[i]
            if (child.getKey() === node.getKey()) continue
            const newWidthPrecent = (child.__widthPrecent / remainPrecent) * 100
            totalPrecent += newWidthPrecent
            child.updateWidthPrecent(newWidthPrecent)
          }

          const normalizePrecent = 100 - totalPrecent
          for (let i = 0; i < childs.length; i++) {
            const child = childs[i]
            if (child.getKey() === node.getKey()) continue
            const newWidthPrecent =
              ((child.__widthPrecent + normalizePrecent / childs.length) /
                remainPrecent) *
              100

            child.updateWidthPrecent(newWidthPrecent)
          }

          node.remove()
          return true
        },
        COMMAND_PRIORITY_NORMAL
      )
    )
  }, [editor])

  return (
    <>
      <ColumnsListItemsResizerPlugin editor={editor} anchorElem={anchorElem} />
    </>
  )
}
